import React, { useState, useEffect } from "react";
import styles from "./NumberInput.module.scss";

/**
 * An input component for choosing a number
 * @param {Int} startNumber startingNumber for the input
 * @param {type} handleNumberChange is called when Number is changed
 * @returns {React.ReactElement} a number input component
 */

export const NumberInput = ({
  startNumber,
  handleNumberChange,
  min,
  max,
  onSave,
  borderColor,
  disable,
  step,
  style,
  placeholder,
}) => {
  const [number, setNumber] = useState(startNumber);

  useEffect(() => {
    if (startNumber || startNumber === 0) {
      setNumber(startNumber);
    } else {
      setNumber("");
    }
  }, [startNumber]);

  const handleChange = (value) => {
    if (typeof value === "string") {
      value = parseInt(value);
    }
    setNumber(value);
    if (handleNumberChange) {
      handleNumberChange(value);
    }
  };

  const onBlur = () => {
    onSave(parseInt(number));
  };

  function handleKeyDown(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      onSave(parseInt(number));
    }
  }

  return (
    <div className={styles.numberbox}>
      <input
        type={"number"}
        value={number}
        onChange={(e) => handleChange(e.target.value)}
        min={min ? min : 0}
        max={max}
        onBlur={onSave ? onBlur : null}
        onKeyDown={onSave ? handleKeyDown : null}
        placeholder={placeholder ? placeholder : "auto"}
        style={{ ...style, borderColor: borderColor ? borderColor : "" }}
        disabled={disable}
        step={step}
      />
    </div>
  );
};
