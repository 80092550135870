// External
import React, { useEffect, useRef, useState } from "react";

//Internal
import styles from "./AddFields.module.scss";
import { Label } from "components/layouts/Label/Label";

export const AddFields = ({ handleOnDrag, fields, editor }) => {
  //eslint-disable-next-line no-unused-vars
  const [showFields, setShowFields] = useState(false);


  const ref = useRef();

  // const handleClickOutside = (event) => {
  //   if (ref.current && !ref.current.contains(event.target)) {
  //     setShowFields(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // }, []);

  return (
    <div
      className={styles.fieldsContainer}
      onClick={() => setShowFields(!showFields)}
    >
      <div
        className={styles.headingContainer}
        onClick={() => setShowFields(!showFields)}
      >
        <Label
          // tooltipText={
          //   "Drag and drop desired field into editor, or type them in on your own"
          // }
          // labelIcon={<i className="bi bi-question-circle"></i>}
          style={{ padding: "0em" }}
        >
          Add Participant Fields
        </Label>
      </div>
      {/* {!showFields && ( <span  onDragStart={(e) => handleOnDrag(e)}
            draggable={true}>{`{participant firstName}`}</span>)} */}


        <div className={`${styles.fields} se-fields-open`} ref={ref}>
          {fields.map((field, i) => (
            <span
              id={field.id}
              draggable={true}
              onDragStart={(e) => handleOnDrag(e, field, "se-addfields")}
              key={i}
              // contentEditable={false}
              // onDragStart={(e) => {
              //   // console.log(e);
              //   handleOnDrag(e, surveyButtonText);
              // }}
              // ref={ref2}
              className={styles.field}
            >
              {"{participant " + field.name + "}"}
            </span>
          ))}
        </div>
     
    </div>
  );
};
