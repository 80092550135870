/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson

*/
import styles from "./SurveyComponent.module.scss";
import { QuestionLogic } from "components/QuestionConstruction/QuestionLogic/QuestionLogic";
import Section from "components/QuestionConstruction/Section";
import Triggers from "components/QuestionConstruction/Triggers/Triggers";
import Question from "components/QuestionConstruction/Question";
import React, { useState, useEffect, useRef } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useHasQuestionBeenAnswered } from "api/resources/projects/questions";
import EditTags from "../EditTags/EditTags";
import { Label } from "components/layouts/Label/Label";
import Buckets from "../Buckets/Buckets";
import { Loading } from "components/Loading/Loading";
import { Add } from "../QuestionConstruction";

export default function SurveyComponent({
  component,
  active,
  setActive,
  pageInd,
  moveTo,
  status,
  canEdit,
  onEditQ,
  deleteQ,
  allQuestions,
  designSettings,
  roles,
  onCopy,
  onAnswer,
  onRetract,
  answers,
  onSaveSomething,
  dependents,
  setUpLogicResolver,
  survey,
  calculateQuestionHealth,
  questionNumber,
  close,
  onAddQ,
  onAddSection,
  onPaste,
  beginningOrEnd,
}) {
  // TODO - set up a ref for whatever segment is active ?? Then do the event listener on that ref
  // Might be tricky with the overInd stuff

  const [showLogic, setShowLogic] = useState(false);
  const [showTriggers, setShowTriggers] = useState(false);
  const [makeACopy, setMakeACopy] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showBuckets, setShowBuckets] = useState(false);
  const [health, setHealth] = useState(calculateQuestionHealth(component));

  const conditional =
    component?.conditionGroups && component?.conditionGroups.length > 0
      ? true
      : false;

  const triggered =
    component?.triggers && component?.triggers.length > 0 ? true : false;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: component.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.4 : undefined,
  };

  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      setShowItems(false);
      setMakeACopy(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef();

  function onClick(e) {
    if (!active) {
      setActive();
    }
  }

  function onSaveQuestion(copy) {
    onEditQ(copy);
    setTimeout(() => onSaveSomething(), 100);
  }

  function onEditQuestion(copy) {
    onEditQ(copy);
    setHealth({ ...calculateQuestionHealth(copy) });
  }

  const [showItems, setShowItems] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const isASection = component.type === "Description";

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column" }}
        id={"survey component: " + component.id}
      >
        {canEdit && (
          <div
            style={{ position: "relative" }}
            onMouseEnter={() => setShowDelete(true)}
            onMouseLeave={() => setShowDelete(false)}
          >
            <Add
              show={active}
              close={close}
              onAddQ={() => onAddQ(-1)}
              onAddSection={onAddSection}
              onPaste={onPaste}
              beginningOrEnd={beginningOrEnd}
            />
          </div>
        )}
        {component.new && <Loading height={80} width={80}></Loading>}
        {!component.new && (
          <div
            className={`${styles.questionContainer} ${
              active ? styles.activeQuestionContainer : ""
            } ${isDragging ? styles.dragging : ""}`}
            ref={setNodeRef}
            style={{
              ...style,
              backgroundColor:
                "sectionSettings" in component &&
                component.sectionSettings?.hasBackgroundColor
                  ? component.sectionSettings?.backgroundColor
                  : "questionText" in component
                  ? ""
                  : "transparent",
              cursor: active ? "default" : "pointer",

              boxShadow:
                "sectionSettings" in component
                  ? component.sectionSettings?.sectionShadow
                    ? component.sectionSettings?.sectionShadow
                    : ""
                  : "boxShadow" in designSettings
                  ? designSettings?.boxShadow
                  : "",
            }}
            {...attributes}
            onClick={onClick}
            onMouseEnter={() => setShowDelete(true)}
            onMouseLeave={() => setShowDelete(false)}
          >
            {/* {active || show && (
        <div className={styles.deleteQ} onClick={() => deleteQ(component)}>
          <i className="bi bi-x-circle"></i>
        </div>
      )} */}
            <div className={styles.tags}>
              {/* {!survey.isProgram &&
              status !== "Draft" &&
              component?.answer &&
              active && (
                <div className={`${styles.tag} ${styles.seafoam}`}>
                  Responses: {component?.answer?.length}
                </div>
              )} */}
              {/* {component.isComment && active && (
              <div className={styles.nps}>
                {" "}
                NPS Comment <i className="bi bi-tag-fill"></i>
              </div>
            )}
            {component.isScore && active && (
              <div className={styles.nps}>
                {" "}
                NPS <i className="bi bi-tag-fill"></i>
              </div>
            )} */}
            </div>
            {canEdit && (showDelete || active) && (
              <>
                <div className={`${styles.grip} ${styles.item}`} {...listeners}>
                  {" "}
                  <i className={`bi-grip-horizontal`}></i>
                </div>

                <div
                  className={`${styles.dots}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    // setActive();
                    if (showItems) {
                      setShowItems(false);
                    } else {
                      setShowItems(true);
                    }
                  }}
                >
                  <i
                    className={`bi-three-dots ${styles.item} ${
                      showItems && styles.activeItem
                    }`}
                  ></i>
                  {showItems && (
                    <div className={styles.settingsBox} ref={ref}>
                      {/* {!isASection && (
                  <div
                    className={`${styles.menuItem} ${
                      triggered ? styles.triggered : ""
                    } ${makeACopy && styles.disabled}`}
                    onClick={(e) => {
                      setShowTriggers(true);
                      setShowItems(false);
                    }}
                    style={{ opacity: active ? 1 : "" }}
                  >
                    {!triggered && (
                      <i
                        className="bi bi-bell"
                        style={{ color: !makeACopy ? "#15BCC7" : "" }}
                      ></i>
                    )}
                    {triggered && (
                      <i
                        className="bi bi-bell-fill"
                        style={{ color: !makeACopy ? "#15BCC7" : "" }}
                      ></i>
                    )}
                    Alerts
                  </div>
                )} */}
                      {/* <div
                  className={`${styles.menuItem} ${
                    conditional ? styles.conditional : ""
                  } ${makeACopy && styles.disabled}`}
                  onClick={() => {
                    setShowLogic(true);
                    setShowItems(false);
                  }}
                  style={{
                    opacity: active ? 1 : "",
                    visibility:
                      status === "Open" && !conditional ? "hidden" : "visible",
                  }}
                >
                  {!conditional && (
                    <i
                      className="bi bi-signpost-2"
                      style={{ color: !makeACopy ? "#A7D5C5" : "" }}
                    ></i>
                  )}
                  {conditional && (
                    <i
                      className="bi bi-signpost-2-fill"
                      style={{ color: !makeACopy ? "#A7D5C5" : "" }}
                    ></i>
                  )}
                  Edit Logic
                </div> */}

                      {!component.new && !isASection && (
                        <>
                          {/* <div
                      className={`${styles.menuItem} ${
                        makeACopy && styles.disabled
                      }`}
                      onClick={() => {
                        setShowTags(true);
                        setShowItems(false);
                      }}
                    >
                      <i
                        className="bi bi-tags"
                        style={{ color: !makeACopy ? "#edb57e" : "" }}
                      ></i>
                      Edit Tags
                    </div> */}

                          {/* {component.type === "Text" && component?.textQuestion?.id && (
                  <div
                    className={`${styles.menuItem} ${
                      makeACopy && styles.disabled
                    }`}
                    onClick={() => {
                      setShowBuckets(true);
                      setShowItems(false);
                    }}
                  >
                    <i
                      className="bi bi-bucket"
                      style={{ color: !makeACopy ? "#d6c1e3" : "" }} // OR 9ED2C0
                    ></i>
                    Buckets
                  </div>
                )} */}
                        </>
                      )}

                      {!component.new && (
                        <div
                          className={`${styles.menuItem} ${
                            makeACopy && styles.copyView
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setMakeACopy(!makeACopy);
                          }}
                        >
                          {" "}
                          <i
                            className="bi-layers"
                            style={{ color: "#F2C85C" }}
                          ></i>
                          Make a Copy
                        </div>
                      )}

                      {makeACopy && (
                        <>
                          {" "}
                          <div
                            className={styles.menuItem}
                            onClick={(e) => {
                              e.stopPropagation();
                              onCopy(component, e, "abovepage");
                            }}
                          >
                            {" "}
                            <i
                              className="bi-align-top"
                              style={{ color: "#2A627C" }}
                            ></i>
                            Above Page
                          </div>
                          <div
                            className={styles.menuItem}
                            onClick={(e) => {
                              e.stopPropagation();
                              onCopy(component, e, "above");
                            }}
                          >
                            {" "}
                            <i
                              className="bi-caret-up"
                              style={{ color: "#2A627C" }}
                            ></i>
                            Above
                          </div>
                          <div
                            className={styles.menuItem}
                            onClick={(e) => {
                              e.stopPropagation();
                              onCopy(component, e, "below");
                            }}
                          >
                            {" "}
                            <i
                              className="bi-caret-down"
                              style={{ color: "#2A627C" }}
                            ></i>
                            Below
                          </div>
                          <div
                            className={styles.menuItem}
                            onClick={(e) => {
                              e.stopPropagation();
                              onCopy(component, e, "belowpage");
                            }}
                          >
                            {" "}
                            <i
                              className="bi-align-bottom"
                              style={{ color: "#2A627C" }}
                            ></i>
                            Below Page
                          </div>
                        </>
                      )}

                      {status !== "Closed" && (
                        <div
                          className={`${styles.menuItem}  ${
                            makeACopy && styles.disabled
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteQ(component, e);
                          }}
                        >
                          <i
                            className="bi bi-trash3"
                            style={{ color: !makeACopy ? "#FF8878" : "" }}
                          ></i>
                          Delete
                        </div>
                      )}
                      {status === "Closed" && (
                        <DeleteClosed
                          onDelete={(e) => deleteQ(component, e)}
                          question={component}
                          makeACopy={makeACopy}
                        />
                      )}
                    </div>
                  )}
                </div>
              </>
            )}

            {/* {"questionText" in component && (
                <>
                  {survey?.useGPS && health?.health <= 60 && (
                    <Label
                      labelIcon={<i className={`bi-exclamation-triangle `}></i>}
                      tooltipText={
                        health?.tips?.length > 0 ? (
                          <div>
                            {health?.tips.map((item, ind) => {
                              return (
                                <React.Fragment key={ind}>
                                  {ind + 1}. {item} <br />
                                  <br />
                                </React.Fragment>
                              );
                            })}
                          </div>
                        ) : null
                      }
                      className={`${styles.red} ${styles.item}`}
                      style={{
                        paddingBottom: "10px",
                        borderBottomRightRadius: ".5rem",
                        borderBottomLeftRadius: ".5rem",
                      }}
                      tooltipStyle={{
                        color: "gray",
                        right: "-10px",
                        top: "30px",
                      }}
                    ></Label>
                  )}
                  {survey?.useGPS && health?.health > 75 && (
                    <Label
                      labelIcon={<i className={`bi-check-circle`}></i>}
                      tooltipText={
                        health?.tips?.length > 0 ? (
                          <div>
                            {health?.tips.map((item, ind) => {
                              return (
                                <React.Fragment key={ind}>
                                  {ind + 1}. {item} <br />
                                  <br />
                                </React.Fragment>
                              );
                            })}
                          </div>
                        ) : null
                      }
                      className={`${styles.green} ${styles.item}`}
                      style={{
                        paddingBottom: "10px",
                        borderBottomRightRadius: ".5rem",
                        borderBottomLeftRadius: ".5rem",
                      }}
                      tooltipStyle={{
                        color: "gray",
                        right: "-10px",
                        top: "30px",
                      }}
                    ></Label>
                  )}
                  {survey?.useGPS &&
                    health?.health <= 75 &&
                    health?.health > 60 && (
                      <Label
                        labelIcon={
                          <i className={`bi-exclamation-triangle `}></i>
                        }
                        tooltipText={
                          health?.tips?.length > 0 ? (
                            <div>
                              {health?.tips.map((item, ind) => {
                                return (
                                  <React.Fragment key={ind}>
                                    {ind + 1}. {item} <br />
                                    <br />
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          ) : null
                        }
                        className={`${styles.yellow} ${styles.item}`}
                        style={{
                          paddingBottom: "10px",
                          borderBottomRightRadius: ".5rem",
                          borderBottomLeftRadius: ".5rem",
                        }}
                        tooltipStyle={{
                          color: "gray",
                          right: "-10px",
                          top: "30px",
                        }}
                      ></Label>
                    )}
                </>
              )} */}

            {(component?.questionText || component?.questionText === "") && (
              <div onMouseEnter={() => moveTo(pageInd)}>
                <Question
                  key={component.id}
                  question={component}
                  active={canEdit && active}
                  saveQuestion={onEditQuestion}
                  designSettings={designSettings}
                  roles={roles}
                  onAnswer={onAnswer}
                  onRetract={onRetract}
                  answerMap={answers}
                  status={status}
                  dependents={dependents}
                  setUpLogicResolver={setUpLogicResolver}
                  questionNumber={questionNumber}
                  audienceType={survey?.audienceType}
                  checkHealth={survey?.useGPS}
                ></Question>
              </div>
            )}
            {!component.questionText && component?.questionText != "" && (
              <div onMouseEnter={() => moveTo(pageInd)}>
                <Section
                  key={component.id}
                  section={component}
                  active={canEdit && active}
                  saveSection={onEditQ}
                  designSettings={designSettings}
                  roles={roles}
                  status={status}
                ></Section>
              </div>
            )}
            {canEdit && (
              <>
                {showLogic && (
                  <QuestionLogic
                    question={component}
                    saveQuestion={onSaveQuestion}
                    close={() => setShowLogic(false)}
                    allQuestions={allQuestions}
                    open={false}
                  ></QuestionLogic>
                )}
                {showTriggers && (
                  <Triggers
                    question={component}
                    saveQuestion={onSaveQuestion}
                    close={() => setShowTriggers(false)}
                  />
                )}
                {showTags && (
                  <EditTags
                    question={component}
                    saveQuestion={onSaveQuestion}
                    close={() => setShowTags(false)}
                    allQuestions={allQuestions}
                  />
                )}
                {showBuckets && (
                  <Buckets
                    question={component}
                    close={() => setShowBuckets(false)}
                    status={status}
                  />
                )}
              </>
            )}
          </div>
        )}
        {canEdit && (
          <div
            style={{ position: "relative" }}
            onMouseEnter={() => setShowDelete(true)}
            onMouseLeave={() => setShowDelete(false)}
          >
            <Add
              show={active}
              close={close}
              onAddQ={() => onAddQ(+1)}
              onAddSection={onAddSection}
              onPaste={onPaste}
              // beginningOrEnd={beginningOrEnd}
            />
          </div>
        )}
      </div>
    </>
  );
}

function DeleteClosed({ question, onDelete, makeACopy }) {
  const hasAnswers = useHasQuestionBeenAnswered(question.id);
  function onWantToDelete(e) {
    if (hasAnswers.data.doesQuestionHaveAnswers) {
      window.alert("People have answered this question. It cannot be deleted");
    } else {
      onDelete(e);
    }
  }

  return (
    <>
      <div
        className={`${styles.menuItem} ${
          !hasAnswers.isSuccess && styles.disabled
        } ${makeACopy && styles.disabled}`}
        onClick={hasAnswers.isSuccess ? onWantToDelete : null}
      >
        {" "}
        <i
          className="bi bi-trash3"
          style={{ color: !makeACopy ? "#FF8878" : "" }}
        ></i>
        Delete
      </div>

      {/* <Modal
            show={show}
            onClose={() => setShow(false)}
            modalStyle={{
              padding: "1em",
              borderRadius: "1em",
              overflow: "visible",
            }}
          >
            <div className={styles.deleteModalPrompt}>
              People have answered this question. Deleting this question will
              delete all answers to this question.
            </div>
            <div className={styles.confirmDelete}>
              Would you still like to delete this question?
            </div>
          </Modal> */}
    </>
  );
}
