import {
  AnswerCount,
  AvgScore,
  NpsScore,
  ParticipationCount,
} from "../QuestionChart";
import { combinedQs } from "../Visualization";

//This class is so the Table fields can be created, both in the table settings component and also the component that changes the chart style, when there are pivots and or splits.
export class PivotTableFieldsCreator {
  constructor(viz, projects, getSurveyTags) {
    this.viz = viz;
    this.projects = projects;
    this.getSurveyTags = getSurveyTags;
    this.allQs = combinedQs(viz);
  }

  couldBeNPS() {
    for (let q of this.allQs) {
      if (
        !q.scaleQuestion ||
        q.scaleQuestion.min !== 0 ||
        q.scaleQuestion.max !== 10
      ) {
        return false;
      }
    }
    return true;
  }

  createNPSOverallColumns() {
    let columns = {
      nps: {
        name: "Category",
        show: true,
      },
      count: {
        name: "count",
        show: true,
      },
    };
    let order = ["nps", "count"];
    return [columns, order];
  }

  combinedOrderAndFields() {
    if (this.viz.pivotString === "nps") {
      return this.createNPSOverallColumns();
    }

    let columns = {};
    columns[this.viz.pivotString] = {
      name:
        this.viz.pivotString === "survey quarter"
          ? "Quarter"
          : this.viz.pivotString,
      show: true,
    };

    if (this.couldBeNPS()) {
      columns.nps = {
        name: "NPS Score",
        show: true,
      };
    }

    columns.avgScore = {
      name: "Avg Score",
      show: true,
    };

    columns.participationCount = {
      name: "Participation Count",
      show: true,
    };

    let order = [];

    for (let field in columns) {
      order.push(field);
    }

    return [columns, order];
  }

  findAnswerType(type) {
    if (type === AvgScore || type === ParticipationCount || type === NpsScore) {
      return type;
    }
    if (this.couldBeNPS()) {
      return NpsScore;
    }
    return AvgScore;
  }

  getProjectsInOrder() {
    let surveys = [...this.projects];

    const filters = JSON.parse(this.viz.filters);
    if ("survey" in filters) {
      let activeProjects = [];
      let inactiveProjects = [];
      for (let name in filters.survey) {
        if (filters.survey[name].active) {
          activeProjects.push(name);
        } else {
          inactiveProjects.push(name);
        }
      }

      if (activeProjects.length > 0) {
        let keeping = [];
        for (let proj of surveys) {
          if (activeProjects.includes(proj.name)) {
            keeping.push(proj);
          }
        }
        surveys = keeping;
      }
    }

    surveys.sort((a, b) => {
      //Sort the projects by survey date
      if (a.startedAt === null || b.startedAt === null) {
        if (a.startedAt) return -1;
        if (b.startedAt) return 1;
        return 0;
      }
      let aDate = new Date(a.startedAt);
      let bDate = new Date(b.startedAt);
      let results = aDate - bDate;
      return results;
    });
    return surveys;
  }

  findTagFromSurvey(proj) {
    if (this.getSurveyTags.isSuccess) {
      for (let tag of this.getSurveyTags.data.tags) {
        let found = tag.project.some((p) => p.id === proj.id);
        if (found) {
          return tag.label;
        }
      }
    }
    return "Undefined";
  }

  getSurveyLabel(val, survey) {
    if (val === "survey") {
      return survey.name;
    }
    if (val === "survey tag") {
      return this.findTagFromSurvey(survey);
    }
    if (val === "survey date" || val === "survey quarter") {
      let label = survey.name;
      if (survey.startedAt) {
        let date = new Date(survey.startedAt);
        let month = date.toDateString().substring(4, 7);
        let year = date.toDateString().substring(11, 15);
        label = month + " " + year;
        if (val === "survey quarter") {
          if (month === "Jan" || month === "Feb" || month === "Mar") {
            label = "Q1 " + year;
          } else if (month === "Apr" || month === "May" || month === "Jun") {
            label = "Q2 " + year;
          } else if (month === "Jul" || month === "Aug" || month === "Sep") {
            label = "Q3 " + year;
          } else if (month === "Oct" || month === "Nov" || month === "Dec") {
            label = "Q4 " + year;
          }
        }
      }
      return label;
    }
  }

  surveyLabelOptionsBy(split) {
    let labels = [];

    let projects = this.getProjectsInOrder();
    for (let survey of projects) {
      let label = this.getSurveyLabel(split, survey);
      if (!labels.includes(label)) {
        labels.push(label);
      }
    }

    return labels;
  }

  combineFields() {
    let newViz = { ...this.viz };
    let [fields, order] = this.combinedOrderAndFields();

    newViz.designSettings.tablePivotFields = fields;
    newViz.designSettings.tablePivotOrder = order;
    newViz.designSettings.split = "";
    if (newViz.designSettings?.tableSort) {
      newViz.designSettings.tableSort = [];
    }
    // newViz.designSettings.answerType = initAnswerType();
    return newViz;
  }

  splitFields(split, custom_fields) {
    let newViz = { ...this.viz };
    let columns = {};
    let pivot = custom_fields.find((f) => f.name === newViz.pivotString);
    let pivotString = newViz.pivotString;
    if (pivot) {
      pivotString = pivot.displayName;
    }
    columns[newViz.pivotString] = {
      show: true,
      name: newViz.pivotString === "nps" ? "NPS" : pivotString,
    };

    let order = [newViz.pivotString];

    if (split === "question") {
      for (let q of this.allQs) {
        columns[q.id] = {
          name: q.questionText,
          show: true,
          project: q.projectId,
        };
        order.push(q.id);
      }
    } else {
      let options = [];
      if (split.includes("survey")) {
        options = this.surveyLabelOptionsBy(split);
      } else if (split === "nps") {
        options = ["passives", "promoters", "detractors"];
      } else {
        //splitting by contact field
        //use custom_fields

        let field = custom_fields.find((f) => f.name === split);
        // options = Object.keys(filters[split]);
        if (field && field?.properties) {
          if (field.properties.length < 1) {
            options = [];
          } else {
            options = field?.properties;
          }
        } else {
          // options = [];
          const filters = JSON.parse(this.viz.filters);
          options = Object.keys(filters[split]);
        }
      }
      for (let label of options) {
        if (label) {
          columns[label] = {
            name: label,
            show: true,
          };
          order.push(label);
        }
      }
    }

    newViz.designSettings.tablePivotFields = columns;
    newViz.designSettings.tablePivotOrder = order;
    newViz.designSettings.split = split;
    newViz.designSettings.answerType = this.findAnswerType(
      newViz.designSettings.answerType
    ); // In case this is for initializing and type is AnswerCount for example
    if (newViz.designSettings?.tableSort) {
      newViz.designSettings.tableSort = [];
    }
    return newViz;
  }
}
