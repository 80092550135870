import styles from "../MetricSettings.module.scss";
import { useEffect, useRef, useState } from "react";
import {
  useFetchStartedSurveys,
  useSearchPrograms,
} from "api/resources/projects/projects";
import { useSearchSurveyTags } from "api/resources/organization/surveytags";
import { useCheckQMetricOptions } from "api/resources/account/widget";
import { useIsHealthCatalyst } from "api/resources/organization/organization";
import { surveyOnes } from "../MetricSettings";
import { DynamicOption } from "../../SurveyData/DynamicOption/DynamicOption";

export function AddMetric({ onClickOut, addMetric, viz, tiedIds }) {
  const getAllProjects = useFetchStartedSurveys();

  const [showProjects, setShowProjects] = useState(true);
  const [searchString, setSearchString] = useState("");
  const searchPrograms = useSearchPrograms(searchString);
  const searchTags = useSearchSurveyTags(searchString);

  const [active, setActive] = useState(false);

  const [metric, setMetric] = useState({
    projects: [],
    dynamic: [],
    title: "",
    code: "",
  });

  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      onClickOut(e);
      document.removeEventListener("click", clickOutside, true);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef();

  function getProjectOptions() {
    let included = [];
    let tied = [];
    let notIncluded = [];
    for (let survey of getAllProjects.data.surveys) {
      if (!metric.projects.some((p) => p.id === survey.id)) {
        if (viz.projectIdsArray.includes(survey.id)) {
          included.push(survey);
        } else if (tiedIds && tiedIds.includes(survey.id)) {
          tied.push(survey);
        } else {
          notIncluded.push(survey);
        }
      }
    }

    return [...included, ...tied, ...notIncluded];
  }

  function addProject(survey) {
    let copy = { ...metric };
    copy.projects.push({
      id: survey.id,
      name: survey.name,
    });
    setMetric(copy);
    setShowProjects(false);
  }

  function addProgram(program) {
    let copy = { ...metric };

    copy.dynamic.push({
      id: program.id,
      name: program.mainProject.name,
      timeFrame: "All",
      type: "program",
    });

    setMetric(copy);
    setShowProjects(false);
    setSearchString("");
  }

  function addTag(tag) {
    let copy = { ...metric };

    copy.dynamic.push({
      id: tag.id,
      name: tag.label,
      timeFrame: "All",
      type: "surveyTag",
    });

    setMetric(copy);
    setShowProjects(false);
    setSearchString("");
  }

  function removeDynamic(index) {
    let copy = { ...metric };
    copy.dynamic.splice(index, 1);
    setMetric(copy);
    if (!showProjects) {
      setShowProjects(!(copy.projects.length || copy.dynamic.length));
    }
  }

  function removeSurvey(index) {
    let copy = { ...metric };
    copy.projects.splice(index, 1);
    setMetric(copy);
    if (!showProjects) {
      setShowProjects(!(copy.projects.length || copy.dynamic.length));
    }
  }

  function changeTimeFrame(i, tf) {
    let copy = { ...metric };
    if (copy.dynamic[i].timeFrame === "selection") {
      delete copy.dynamic[i].selection;
    }
    copy.dynamic[i].timeFrame = tf;
    if (copy.dynamic[i].timeFrame === "selection") {
      copy.dynamic[i].selection = [];
    }
    setMetric(copy);
  }

  function changeSelection(i, project) {
    let copy = { ...metric };
    let index = copy.dynamic[i].selection.indexOf(project.id);
    if (index > -1) {
      copy.dynamic[i].selection.splice(index, 1);
    } else {
      copy.dynamic[i].selection.push(project.id);
    }

    setMetric(copy);
  }

  function addCustom() {
    onChosenCode({
      code: "custom",
      value: "Custom",
      icon: "brush",
      title: "Custom",
    });
  }

  const onChosenCode = (m) => {
    let copy = { ...metric, ...m };

    if (!copy.dynamic.length) {
      delete copy.dynamic;
    }

    addMetric(copy);
  };

  const hasData =
    metric.projects.length || metric.dynamic.length ? true : false;

  return (
    <div ref={ref} className={styles.customDropDown}>
      {hasData && (
        <>
          <div className={styles.for}>For:</div>
          <div className={styles.included}>
            {metric.dynamic.map((dynamic, i) => (
              <DynamicOption
                dynamic={dynamic}
                onRemove={() => removeDynamic(i)}
                changeTimeFrame={(tf) => changeTimeFrame(i, tf)}
                changeSelection={(p) => changeSelection(i, p)}
              />
            ))}

            {metric.projects.map((s, i) => (
              <div className={styles.includedSurvey}>
                {s.name}
                <div
                  className={styles.removeSurvey}
                  onClick={() => removeSurvey(i)}
                >
                  <i className="bi bi-x"></i>
                </div>
              </div>
            ))}
            {!showProjects && (
              <div
                className={styles.addToIncluded}
                onClick={() => setShowProjects("projects")}
              >
                {metric.dynamic.length ? "+ Add" : "+ Survey"}
              </div>
            )}
          </div>
        </>
      )}
      {showProjects && (
        <>
          {getAllProjects.isSuccess && (
            <>
              {hasData ? (
                <div
                  className={`${styles.dropDownListTitle} ${styles.addSurvey}`}
                >
                  Add
                  <div
                    className={styles.cancelFor}
                    onClick={() => setShowProjects(false)}
                  >
                    <i className="bi bi-arrow-return-left"></i>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={`${styles.dropDownListTitle} ${styles.forSurveys}`}
                  >
                    For
                  </div>
                </>
              )}

              <div className={styles.addDynamic}>
                <div className={styles.searchFor}>
                  <div className={styles.whatSearchingFor}>
                    Survey Tag / Program
                  </div>
                  <input
                    type="text"
                    onChange={(e) => setSearchString(e.target.value)}
                    className={styles.search}
                    value={searchString}
                    placeholder=""
                    onFocus={() => setActive(true)}
                    onBlur={() => setTimeout(() => setActive(false), 250)}
                  ></input>
                </div>

                {active && (
                  <>
                    {searchPrograms.isSuccess &&
                      searchPrograms.data.programs
                        .filter(
                          (program) =>
                            !metric.dynamic.some(
                              (d) => d.id === program.id && d.type === "program"
                            )
                        )
                        .map((program) => (
                          <div
                            className={styles.programOption}
                            onClick={() => addProgram(program)}
                          >
                            {program.mainProject.name}{" "}
                            <div
                              className={`${styles.indicator} ${styles.programIndicator}`}
                            >
                              Program
                            </div>
                          </div>
                        ))}

                    {searchTags.isSuccess &&
                      searchTags.data.tags
                        .filter(
                          (tag) =>
                            !metric.dynamic.some(
                              (d) => d.id === tag.id && d.type === "surveyTag"
                            )
                        )
                        .map((tag) => (
                          <div
                            className={styles.programOption}
                            onClick={() => addTag(tag)}
                          >
                            {tag.label}{" "}
                            <div
                              className={`${styles.indicator} ${styles.tagIndicator}`}
                            >
                              Tag
                            </div>
                          </div>
                        ))}
                  </>
                )}
              </div>

              <div
                className={styles.whatSearchingFor}
                style={{
                  fontSize: ".9em",
                  borderBottom: "2px solid #a4c6d055",
                }}
              >
                Survey
              </div>

              {getProjectOptions().map((survey) => (
                <div
                  className={styles.surveyOption}
                  onClick={() => addProject(survey)}
                >
                  {survey.name}
                </div>
              ))}
              <div className={styles.smallMetric} onClick={addCustom}>
                <div className={styles.icon}>
                  <i className={`bi bi-brush`}></i>
                </div>
                <div className={styles.text}>
                  <div className={styles.mTitle}>Custom</div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {!showProjects && <PickMetric onClick={onChosenCode} metric={metric} />}
    </div>
  );
}

export function PickMetric({ onClick, metric }) {
  function getQuery() {
    let query = {
      projectIds: metric.projects.map((p) => p.id),
    };

    let dynamics = [];
    for (let d of metric.dynamic) {
      if (d.selection) {
        for (let id of d.selection) {
          query.projectIds.push(id);
        }
      } else {
        dynamics.push(d);
      }
    }
    if (dynamics.length) {
      query.dynamics = dynamics;
    }

    return query;
  }

  const getQOptions = useCheckQMetricOptions(getQuery());

  const isHC = useIsHealthCatalyst();

  function showOptions() {
    let array = [];
    for (let m of surveyOnes) {
      array.push({ ...m });
    }

    if (getQOptions.isSuccess) {
      let options = getQOptions.data.checkForQOptions;
      if (options.nps) {
        array.push({
          code: "nps",
          icon: "bookmark-check",
          title: "NPS",
          questions: options.nps.map((q) => {
            return {
              id: q.id,
              name: q.questionText,
              projectId: q.projectId,
            };
          }),
        });
      }
      if (options.comments) {
        array.push({
          code: "numberOfComments",
          icon: "chat-text",
          title: "Number of Comments",
          questions: options.comments.map((q) => {
            return {
              id: q.id,
              name: q.questionText,
              projectId: q.projectId,
            };
          }),
        });
      }
      if (options.flywheel) {
        array.push({
          code: "flywheel",
          icon: "bookmark-check",
          title: "Flywheel Score",
          questions: options.flywheel.map((q) => {
            return {
              id: q.id,
              name: q.questionText,
              projectId: q.projectId,
            };
          }),
        });
      }
    }

    array.push({
      code: "custom",
      value: "Custom",
      icon: "brush",
      title: "Custom",
    });

    return array;
  }

  return (
    <>
      <div className={`${styles.dropDownListTitle}`}>Metric</div>
      {getQOptions.isSuccess &&
        isHC.isSuccess &&
        showOptions().map((m, i) => (
          <div
            className={styles.smallMetric}
            key={i}
            onClick={() => onClick(m)}
          >
            <div className={styles.icon}>
              <i className={`bi bi-${m.icon}`}></i>
            </div>
            <div className={styles.text}>
              <div className={styles.mTitle}>{m.title}</div>
            </div>
          </div>
        ))}
    </>
  );
}
