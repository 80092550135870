import FlexRow from "components/layouts/FlexRow/FlexRow";
import styles from "./SurveyLogic.module.scss";
import { useState, useEffect } from "react";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { getSortedArray } from "assets/functions/ArrayFunctions";
import { SurveyLogicQ } from "./SurveyLogicQ/SurveyLogicQ";
import { conditionDescription } from "assets/functions/StringFunctions";
import { isConditionComplete } from "assets/functions/ObjectFunctions";
import { SurveyLogicSection } from "./SurveyLogicQ/SurveyLogicSection";

export default function SurveyLogic({
  pages,
  designSettings,
  allQuestions,
  activeQ,
  setActiveQ,
}) {
  const [answerMap, setAnswerMap] = useState({});
  const [numAnswered, setNumAnswered] = useState(0);
  const [passing, setPassing] = useState({});

  function onAnswer(questionID, answer) {
    // console.log("answered", answer)
    if (!(questionID in answerMap)) {
      setNumAnswered(numAnswered + 1);
    }

    let copy = { ...answerMap };
    copy[questionID] = answer;
    setAnswerMap(copy);
  }

  function onRetract(questionID) {
    // console.log("retracted");
    let copy = { ...answerMap };
    delete copy[questionID];
    setAnswerMap(copy);

    setNumAnswered(numAnswered - 1);
  }

  const onlyQs = [];
  for (let q of allQuestions) {
    if ("questionText" in q) {
      onlyQs.push(q);
    }
  }

  function clearsConditions(question) {
    if (question?.conditionGroups) {
      for (let group of question.conditionGroups) {
        if (!checkGroup(group)) {
          return false;
        }
      }
    }
    return true;
  }

  function checkGroup(group) {
    if (!group?.operator) {
      return true;
    }
    let op = group.operator;
    if (op === "When any condition matches") {
      for (let condition of group.conditions) {
        if (checkCondition(condition)) {
          return true;
        }
      }
      return false;
    } else if (op === "When any condition does not match") {
      for (let condition of group.conditions) {
        if (!checkCondition(condition)) {
          return true;
        }
      }
      return false;
    } else if (op === "When all conditions match") {
      for (let condition of group.conditions) {
        if (!checkCondition(condition)) {
          return false;
        }
      }
      return true;
    } else if (op === "When no condition matches") {
      for (let condition of group.conditions) {
        if (checkCondition(condition)) {
          return false;
        }
      }
      return true;
    }
  }

  function checkCondition(condition) {
    if (!isConditionComplete(condition)) {
      return true; // don't apply if not complete
    }

    let op = condition.operator;
    if (op === "have answered") {
      if (condition.questionId in answerMap) {
        if (condition.option) {
          return answerMap[condition.questionId][condition.option]
            ? true
            : false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
    if (op === "have not answered") {
      if (condition.questionId in answerMap) {
        if (condition.option) {
          return answerMap[condition.questionId][condition.option]
            ? false
            : true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }

    if (!(condition.questionId in answerMap)) {
      return false;
    }
    let answer = answerMap[condition.questionId];

    let qInQuestion = allQuestions.find((q) => q.id === condition.questionId);
    if (qInQuestion) {
      if (qInQuestion.matrixQuestion) {
        if (!(condition.option in answer)) {
          return false;
        }
        answer = answer[condition.option];
      }
      if (qInQuestion.choiceQuestion) {
        if (op === "answer") {
          // An 'or' on the condition.answer
          for (let option of condition.answer) {
            if (answer.includes(option)) {
              return true;
            }
          }

          // Check for other option
          if (
            qInQuestion.choiceQuestion.hasOtherOption &&
            condition.answer.includes(
              qInQuestion.choiceQuestion.otherOptionLabel
            )
          ) {
            let answerIndeces = findOptionIndeces(qInQuestion.id, answer);
            let otherInd = qInQuestion.choiceQuestion.choices.length;
            if (answerIndeces.includes(otherInd)) {
              return true;
            }
          }

          return false;
        } else if (op === "do not answer") {
          for (let option of condition.answer) {
            if (answer.includes(option)) {
              return false;
            }
          }

          // Check for other option
          if (
            qInQuestion.choiceQuestion.hasOtherOption &&
            condition.answer.includes(
              qInQuestion.choiceQuestion.otherOptionLabel
            )
          ) {
            let answerIndeces = findOptionIndeces(qInQuestion.id, answer);
            let otherInd = qInQuestion.choiceQuestion.choices.length;
            if (answerIndeces.includes(otherInd)) {
              return false;
            }
          }
        }
      }
      if (qInQuestion.scaleQuestion) {
        if (op === "answer") {
          for (let option of condition.answer) {
            // An 'or' on the condition.answer
            if (option == answer) {
              // Not strongly equal
              return true;
            }
          }
          return false;
        } else if (op === "do not answer") {
          for (let option of condition.answer) {
            if (option == answer) {
              // Not strongly equal
              return false;
            }
          }
        } else if (op === "between") {
          let order = getSortedArray(condition.answer, (a, b) => a - b);
          if (answer < order[0] || answer > order[1]) {
            return false;
          }
        } else if (op === "outside") {
          let order = getSortedArray(condition.answer, (a, b) => a - b);
          if (answer >= order[0] && answer <= order[1]) {
            return false;
          }
        } else if (op === "below") {
          return answer < condition.answer;
        } else if (op === "above") {
          return answer > condition.answer;
        }
      }
      if (qInQuestion.textQuestion) {
        if (op === "contains") {
          let lowerAnswer = answer.toLowerCase();
          let lowerCondition = condition.answer.toLowerCase();
          return lowerAnswer.includes(lowerCondition);
        } else if (op === "does not contain") {
          let lowerAnswer = answer.toLowerCase();
          let lowerCondition = condition.answer.toLowerCase();
          return !lowerAnswer.includes(lowerCondition);
        } else if (op === "is longer than") {
          return answer.length > condition.answer;
        } else if (op === "is shorter than") {
          return answer.length < condition.answer;
        }
      }
    }

    return true;
  }

  function setUpPassing() {
    let state = {};
    for (let q of allQuestions) {
      state[q.id] = clearsConditions(q);
    }
    setPassing(state);

    // go through answer map and delete any not passing.
    let invalidated = [];
    for (let id in state) {
      if (!state[id] && answerMap[id]) {
        invalidated.push(id);
      }
    }

    if (invalidated.length) {
      let copy = { ...answerMap };
      for (let invalidId of invalidated) {
        delete copy[invalidId];
      }

      setNumAnswered((num) => num - invalidated.length);
      setAnswerMap(copy);
    }
  }

  useEffect(() => {
    if (passing) {
      setUpPassing();
    }
  }, [answerMap, pages]);

  function onClickOutside(e) {
    if (activeQ) {
      for (let q of allQuestions) {
        let container = document.getElementById("survey logic q: " + q.id);
        if (container && container.contains(e.target)) {
          return;
        }
      }

      setActiveQ(null);
    }
  }

  return (
    <>
      <div className={styles.logic} onClick={onClickOutside}>
        <div className={styles.survey}>
          {pages.map((pageQuestions, i) => (
            <>
              <FlexRow
                start
                gap={"0px"}
                style={{ width: "fit-content", alignSelf: "flex-start" }}
              >
                {" "}
                <div
                  className={styles.section_label6}
                  style={{ alignSelf: "flex-start", width: "fit-content" }}
                >
                  Page {i + 1}
                </div>
              </FlexRow>
              <div
                className={`${styles.apage}`}
                style={{
                  gap: designSettings?.questionGap
                    ? `${designSettings?.questionGap}px`
                    : "20px",
                  backgroundColor: designSettings.backgroundColor
                    ? designSettings.backgroundColor
                    : "transparent",

                  // backgroundImage: designSettings?.backgroundImage
                  //   ? designSettings?.backgroundImage
                  //   : "",
                }}
              >
                <div className={`${styles.progBar} `}>
                  <ProgressBar
                    settings={designSettings}
                    numAnswered={numAnswered}
                    numQuestions={onlyQs.length}
                  ></ProgressBar>
                </div>

                {pageQuestions.map((q) => (
                  <div className={styles.qCover}>
                    {q.conditionGroups && q.conditionGroups.length > 0 && (
                      <div
                        className={styles.conditionPreview}
                        // style={
                        //   "questionText" in q
                        //     ? undefined
                        //     : { padding: "0px 1rem" }
                        // }
                      >
                        <div className={styles.logicIcon}>
                          <i className="bi-signpost-split"></i>
                        </div>
                        <div className={styles.conditions}>
                          {q.conditionGroups.map((group, gi) => (
                            <div className={styles.group} id={group.id}>
                              {group.conditions?.map((condition, ci) => {
                                const depInd = onlyQs.findIndex(
                                  (qst) => qst.id === condition.questionId
                                );

                                const needsParenths =
                                  q.conditionGroups.length > 1 &&
                                  group.conditions.length > 1;

                                const cleared = checkCondition(condition);

                                return depInd > -1 ? (
                                  <>
                                    <div
                                      className={styles.condition}
                                      key={condition.id}
                                    >
                                      {needsParenths && (
                                        <span
                                          style={
                                            ci > 0
                                              ? { visibility: "hidden" }
                                              : undefined
                                          }
                                        >
                                          {`(`}
                                        </span>
                                      )}

                                      <span
                                        className={styles.conditionDesc}
                                        // style={
                                        //   cleared
                                        //     ? { color: "#616565" }
                                        //     : undefined
                                        // }
                                      >
                                        {conditionDescription(
                                          condition,
                                          onlyQs[depInd],
                                          depInd,
                                          group.operator
                                        )}
                                      </span>

                                      {needsParenths &&
                                        ci == group.conditions.length - 1 && (
                                          <span>{")"}</span>
                                        )}
                                      <span
                                        className={styles.checkmark}
                                        style={{ opacity: cleared ? "1" : "0" }}
                                      >
                                        <i className="bi-check-lg"></i>
                                      </span>
                                    </div>

                                    {group.conditions.length > 1 &&
                                      ci < group.conditions.length - 1 && (
                                        <div className={styles.symbol}>
                                          {" "}
                                          {group.operator.includes("When any")
                                            ? "or"
                                            : "&"}{" "}
                                        </div>
                                      )}
                                  </>
                                ) : (
                                  <></>
                                );
                              })}

                              {q.conditionGroups.length > 1 &&
                                gi < q.conditionGroups.length - 1 && (
                                  <div
                                    className={styles.symbol}
                                    // style={{ paddingLeft: "20px" }}
                                  >
                                    {"&"}
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {"questionText" in q ? (
                      <SurveyLogicQ
                        key={q.id}
                        question={q}
                        onAnswer={onAnswer}
                        onRetract={onRetract}
                        answerMap={answerMap}
                        settings={designSettings}
                        questionNumber={
                          onlyQs.findIndex((qst) => qst.id === q.id) + 1
                        }
                        active={activeQ ? activeQ.id === q.id : false}
                        setActiveQ={setActiveQ}
                        faded={!passing[q.id]}
                      />
                    ) : (
                      <SurveyLogicSection
                        key={q.id}
                        section={q}
                        active={activeQ ? activeQ.id === q.id : false}
                        setActiveQ={setActiveQ}
                        faded={!passing[q.id]}
                      />
                    )}
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
}
