// External
import React, { useEffect, useRef, useState } from "react";

//Internal
import styles from "./Distributions.module.scss";
import { EmailContainer } from "./email/Email";
import {
  useBuildNewEmail,
  useCreateDistribution,
  useDeleteDistribution,
  useSearchDistributions,
  useSearchDistributionsCount,
} from "api/resources/projects/distributions";
import { useParams } from "react-router-dom";
import { Loading } from "components/Loading/Loading";
import { Link } from "./link/Link";
import {
  useFetchProjectGql,
  useFetchProjectStatus,
} from "api/resources/projects/projects";
import InteractiveDistributionTable from "components/tables/BasicTable/InteractiveDistributionTable";
import { useFetchOrganization } from "api/resources/organization/organization";

export const Distributions = ({ roles, user, survey, onBuildEmail }) => {
  const { id, distId } = useParams();
  const [copying, setCopying] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [compose, setCompose] = useState(false);
  const [showEmail, setShowEmail] = useState(distId ? true : false);
  const [active, setActive] = useState(distId ? { id: distId } : null);
  const [schedule, setSchedule] = useState(null);
  const [preview, setPreview] = useState(null);
  const [projectStatus, setProjectStatus] = useState();

  const [pageSort, setPageSort] = useState({
    item: "index",
    descend: false,
  });
  const [perPage, setPerpage] = useState(15);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [composing, setComposing] = useState(false);

  const fetchProjectQuery = useFetchProjectGql(id);
  const getDistributions = useSearchDistributions(
    id,
    pageSort,
    pageSkip,
    perPage,
    searchString,
    "email",
    "allemails"
  );
  const getDistributionsCount = useSearchDistributionsCount(
    id,
    pageSort,
    pageSkip,
    perPage,
    searchString,
    "email",
    "count"
  );
  const createDistribution = useCreateDistribution();
  const getProjectStatus = useFetchProjectStatus();
  const fetchOrg = useFetchOrganization(survey?.organizationId);
  const deleteDist = useDeleteDistribution();
  const composeNew = useBuildNewEmail();

  const distributions = [
    {
      id: 0,
      project_id: 0,
      method: "Email",
      subject: "Opinion",
      audienceId: 0,
      from: "jbikman@reaction.com",
      status: "Delivered",
      date: "Aug 15, 2022, 08:02 pm",
      strength: "Bad",
      htmlContent: <p>hey this is a survey</p>,
      textContent: "hey this is a survey",
      scheduleDate: null,
      audienceNames: [
        "Doctors",
        "Patients",
        "Executives",
        "Staff",
        "Employees",
      ],
      audienceName: "Doctors",
    },
    {
      id: 1,
      project_id: 0,
      method: "Email",
      subject: "Opinion",
      audienceId: 0,
      from: "mwagner@reaction.com",
      status: "Draft",
      date: null,
      strength: "Bad",
      htmlContent: <p>hey this is a survey</p>,
      textContent: "hey this is a survey",
      scheduleDate: null,
      audienceNames: [
        "Doctors",
        "Patients",
        "Executives",
        "Staff",
        "Employees",
      ],
      audienceName: "Doctors",
    },
    {
      id: 2,
      project_id: 0,
      method: "Link",
      subject: "More Opinion",
      audienceId: 0,
      from: "jbikman@reaction.com",
      status: null,
      date: null,
      strength: "Good",
      htmlContent: null,
      textContent: null,
      scheduleDate: null,
      audienceNames: [
        "Doctors",
        "Patients",
        "Executives",
        "Staff",
        "Employees",
      ],
      audienceName: "Doctors",
    },
    {
      id: 3,
      project_id: 0,
      method: "SMS",
      subject: "More Opinion",
      audienceId: 0,
      from: "jbikman@reaction.com",
      status: "Scheduled",
      date: "Aug 12, 2022, 05:01 pm",
      strength: "Good",
      htmlContent: <p>hey this is a survey</p>,
      textContent: "hey this is a survey",
      scheduleDate: null,
      audienceNames: [
        "Doctors",
        "Patients",
        "Executives",
        "Staff",
        "Employees",
      ],
      audienceName: "Doctors",
    },
  ];

  const headers = [
    {
      id: 0,
      index: 0,
      name: "#",
      accessor: "index",
      enabled: true,
      sort: false,
      canSort: true,
    },
    {
      id: 0,
      index: 0,
      name: "Name",
      // accessor: "name",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: (value) => (
        <div style={{ display: "flex", gap: ".5em", alignItems: "center" }}>
          <i className={`bi bi-${value?.icon}`}></i> {value?.name}
        </div>
      ),
    },
    {
      id: 1,
      index: 1,
      name: "Subject",
      accessor: "subject",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },
    {
      id: 2,
      index: 2,
      name: "From",
      accessor: "sender",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },
    {
      id: 3,
      index: 3,
      name: "Status",
      accessor: "status",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: (value) => (
        <span
          className={`${styles.status} 
          ${value === "Sent" ? `${styles.delivered}` : ""} 
          ${value === "Draft" ? `${styles.draft}` : ""}
          ${value === "Scheduled" ? `${styles.scheduled}` : ""}
          ${value === "Active" ? `${styles.active}` : ""}
          ${value === "Inactive" ? `${styles.inactive}` : ""}`}
        >
          {value}{" "}
        </span>
      ),
    },
    {
      id: 4,
      index: 4,
      name: "Date",
      accessor: "scheduledDate",
      enabled: true,
      sort: true,
      canSort: true,
      cell_style: null,
    },
    {
      id: 5,
      index: 5,
      name: "Copy",
      // accessor: "id",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: (value) => (
        <>
          {value && copying != value?.id && (
            <a className={styles.copySurvey}>
              <i
                className="bi bi-clipboard"
                onClick={() => {
                  let temp = {
                    ...value,
                    name: value?.name + " Copy",
                    scheduledDate: undefined,
                    sentDate: undefined,
                    status: "Draft",
                    audience: [],
                    distConfig: {},
                  };
                  delete temp?.id;
                  setActive(temp);
                }}
              ></i>
            </a>
          )}
          {copying === value && <Loading height={60} width={60}></Loading>}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (active) {
      setShowEmail(true);
    }
  }, [active]);

  useEffect(() => {
    getDistributions.refetch();
  }, [showEmail]);

  useEffect(() => {
    getDistributions.refetch();
  }, [pageNumber, pageSort, perPage, searchString]);

  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setCompose(false);
    }
  };

  useEffect(() => {
    getProjectStatus.mutate(
      {
        id: id,
      },
      {
        onSuccess: (data) => {
          setProjectStatus(data.getProject.status);
        },
      }
    );
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function deleteEmail(email) {
    deleteDist.mutate({
      id: email.id
    }, {
      onSuccess: (data) => {
        getDistributions.refetch();
        console.log("deleted");
      }
    })
  }

  function handleRowClick(row, type) {
    if (type && type === "schedule") {
      setSchedule(true);
      setActive({ ...row });
    } else if (type && type === "preview") {
      setPreview(true);
      setActive({ ...row });
    } else if (type && type === "event") {
      row.id = row?.distId;
      setActive({ ...row });
    } else if (type && type === "delete") {
      deleteEmail(row);
    } else {
      // if (row.messageBody) {
      //   setActive({ ...row });
      // } else {
      //   onBuildEmail(row.id);
      // }
      onBuildEmail(row.id);
    }
  }

  function handleEmailUpdate(email) {
    //this will be a call to the database
    for (let i = 0; i < distributions.length; i++) {
      if (email.id == distributions[i].id) {
        distributions[i] = email;
      }
    }
  }

  function handleCompose(type) {
    //create link
    const data = {
      projectId: id,
      messageType: type,
      emailStrength: 0,
      sender: "",
      status: "Draft",
      link: "",
      messageBody: "",
      subject: "",
      conditions: "",
      // distConfigId: "",
    };
    createDistribution.mutate({
      data: data,
    });
    setCompose(false);
  }

  function getName(index) {
    switch (index) {
      case 0:
        return "Survey Notification";
      case 1:
        return "Survey Link";
      case 2:
        return "Survey Reminder";
      case 3:
        return "Final Call";
      default:
        return "Survey Link " + (index - 1);
    }
  }

  function onComposeNewEmail() {
    // setActive({
    //   messageType: "email",
    //   subject: "",
    //   messageBody:
    //     "<p>Take Survey Here: {Survey Link} or copy and paste url into browser: {Survey URL}</p>",
    //   sender: "",
    //   status: "Draft",
    //   scheduledDate: undefined,
    //   sentDate: undefined,
    //   emailStrength: 0,
    //   link: "",
    //   opens: 0,
    //   unsubscribes: 0,
    //   bounces: 0,
    //   reportedSpam: 0,
    //   name: getName(getDistributionsCount?.data?.count),
    //   icon: "envelope",
    //   audience: [],
    //   distConfig: {},
    //   copy: true,
    //   linkStyle: JSON.stringify({
    //     color: "#15bcc7",
    //     cursor: "pointer",
    //   }),
    //   linkName: "Take Survey",
    //   distConfig: {
    //     signature: "",
    //   },
    // });
    composeNew.mutate(
      {
        projectId: survey.id,
      },
      {
        onSuccess: (data) => {
          if (data.email.id) {
            onBuildEmail(data.email.id);
          }
        },
        onError: (data) => {
          console.log(data);
          setComposing(false);
        },
      }
    );
    setComposing(true);
  }

  return (
    <div className={styles.page}>
      {fetchProjectQuery.isSuccess && (
        <InteractiveDistributionTable
          headers={headers}
          user={user}
          // data={searchProjects.}
          queryData={getDistributions}
          queryDataAccessor={"distributions"}
          maxDataQuery={getDistributionsCount}
          maxDataQueryAccessor={"count"}
          currPage={pageNumber}
          setCurrPage={setPageNumber}
          maxItems={perPage}
          // maxData={getProjectCount}
          onRowClick={handleRowClick}
          sort={pageSort}
          setSort={setPageSort}
          setPageSkip={setPageSkip}
          perPage={perPage}
          setPerPage={setPerpage}
          searchString={searchString}
          setSearchString={setSearchString}
          actionButtonText={
            composing ? (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                Composing <Loading height={30} width={30} />
              </div>
            ) : (
              <div>
                Compose <i className="bi bi-pencil"></i>
              </div>
            )
          }
          tableTitle={survey.isProgram ? "Program Messages" : ""}
          tableDescription={
            survey.isProgram
              ? "Each program iteration will be initiated with these messages"
              : ""
          }
          widgetType="email"
          searchPlaceholder={"Search Messages..."}
          onClickActionButton={onComposeNewEmail}
          id={id}
          copyClick={(value) => {
            let temp = {
              ...value,
              name: value?.name + " Copy",
              scheduledDate: undefined,
              sentDate: undefined,
              status: "Draft",
              copy: true,
            };
            delete temp?.id;
            setActive(temp);
          }}
          survey={fetchProjectQuery?.data?.survey}
        />
      )}

      {compose && (
        <div className={styles.composeContainer} ref={ref}>
          <span
            onClick={() =>
              setActive({
                messageType: "email",
                subject: "",
                messageBody:
                  "<p>Take Survey Here: {Survey Link} or copy and paste url into browser: {Survey URL}</p>",
                sender: "",
                status: "Draft",
                scheduledDate: undefined,
                sentDate: undefined,
                emailStrength: 0,
                link: "",
                opens: 0,
                unsubscribes: 0,
                bounces: 0,
                reportedSpam: 0,
                name: getName(getDistributionsCount?.data?.count),
                icon: "envelope",
                audience: [],
                distConfig: {},
                linkStyle: JSON.stringify({
                  color: "#15bcc7",
                  cursor: "pointer",
                }),
                linkName: "Take Survey",
                distConfig: {
                  signature: "",
                },
              })
            }
          >
            Email
          </span>
        </div>
      )}
      {showEmail && fetchProjectQuery.isSuccess && fetchOrg.isSuccess && (
        <EmailContainer
          show={showEmail}
          setShow={setShowEmail}
          email={active}
          initschedule={schedule}
          initpreview={preview}
          updateEmail={handleEmailUpdate}
          createEmail={handleCompose}
          roles={roles}
          user={user}
          projectStatus={projectStatus}
          survey={fetchProjectQuery?.data?.survey}
          organization={fetchOrg.data.organization}
          copy={active?.copy}
        ></EmailContainer>
      )}
    </div>
  );
};
