import React from "react";
import styles from "./SurveyProjects.module.scss";
import SurveyProject from "./SurveyProject";
import { Sorting } from "../../InteractiveSurveysTable";
import { PaginationContainer } from "../../PaginationContainer";
import { Pagination } from "../../Pagination";

function SurveyProjects({
  surveys,
  onClick,
  roles,
  refetch,
  iterations,
  sort,
  setSort,
  pageNum,
  setPageNum,
  perPage,
  setPerPage,
  setPageSkip,
  count,
  length,
  searchString,
  isCreate,
  onClickChoose,
  chosen
}) {
  return (
    <div
      className={styles.surveys}
      style={{
        fontSize: window.innerWidth < 560 ? ".8em" : "",
        gap: window.innerWidth < 560 ? "1em" : "",
        padding: window.innerWidth < 560 ? "1em 1em" : "",
        gridTemplateColumns:
          window.innerWidth < 560 ? "repeat(auto-fill, 170px)" : "",
      }}
    >
      <div
        className={styles.sortBox}
      >
        <Sorting sort={sort} setSort={setSort}></Sorting>

        <Pagination
          pageNum={pageNum}
          setPageNum={setPageNum}
          maxPage={count / perPage < 1 ? 1 : Math.ceil(count / perPage)}
          length={length}
          searchString={searchString}
          perPage={perPage}
          setPerPage={setPerPage}
          setPageSkip={setPageSkip}
        ></Pagination>
      </div>
      {surveys.map((survey) => (
        <SurveyProject
          key={survey.id}
          survey={survey}
          onClick={onClick}
          roles={roles}
          refetch={refetch}
          iterations={iterations}
          isCreate={isCreate}
          onClickChoose={onClickChoose}
          chosen={chosen}
        />
      ))}{" "}
      <div className={styles.sortBox} style={{justifyContent: "flex-end"}}>
        <Pagination
          pageNum={pageNum}
          setPageNum={setPageNum}
          maxPage={count / perPage < 1 ? 1 : Math.ceil(count / perPage)}
          length={length}
          searchString={searchString}
          perPage={perPage}
          setPerPage={setPerPage}
          setPageSkip={setPageSkip}
        ></Pagination>
      </div>
    </div>
  );
}

export default SurveyProjects;
