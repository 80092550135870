import styles from "./SurveyCompleted.module.scss";
import Confetti from "react-confetti";
import { useEffect, useState } from "react";
import { SurveyButton } from "./SurveyButton/SurveyButton";
import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";

export default function SurveyCompleted({ message, returnToSurvey, settings }) {
  function getCustomColors() {
    if (settings.confettiColors && settings.confettiColors.length)
      return settings.confettiColors;
    return undefined;
  }

  return (
    <div
      className={styles.completedContainer}
      style={{
        boxShadow: settings?.finishedBorderShadow
          ? settings?.finishedBorderShadow
          : "",
        borderRadius:
          settings?.finishedBorderRadius || settings?.finishedBorderRadius === 0
            ? settings?.finishedBorderRadius + "rem"
            : "",
        backgroundColor: settings?.finishedBackgroundColor
          ? settings?.finishedBackgroundColor
          : "",
      }}
    >
      <div className={styles.textContainer}>
        <TextEditorTry
          encoding={
            message
              ? message
              : "Thanks so much for your input."
          }
          defaultStyle={{
            textAlign: "center",
            color: "#616565",
            fontSize: "10pt",
          }}
          linkable
        />
      </div>
      {settings.confetti && (
        <Confetti
          numberOfPieces={150}
          initialVelocityY={{ min: 1, max: 10 }}
          gravity={0.3}
          colors={settings.useOwnColors ? getCustomColors() : undefined}
          recycle={false}
        />
      )}

      {settings.returnable && (
        <SurveyButton
          styleType={settings.buttonStyle}
          color={
            settings.buttonColor ? settings.buttonColor : settings.baseColor
          }
          onClick={returnToSurvey}
          shadow={settings?.buttonShadow}
        >
          <div className={styles.insideButton}>
            <div className={styles.backArrow}>
              <i className="bi bi-arrow-return-left"></i>
            </div>{" "}
            Back to Survey
          </div>
        </SurveyButton>
      )}
    </div>
  );
}

// Thank you for participating in this survey! <br></br>
//       Go to <a href="https://theresearchcloud.com/">theresearchcloud.com</a> to
//       see the results of the survey you participated in!
