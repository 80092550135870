import {
  useGetEmailPreview,
  useGetHTMLEmailPreview,
  useSendEmailPreview,
} from "api/resources/projects/distributions";
import styles from "./Preview.module.scss";
import Modal from "components/ReactModal/ReactModal";
import parse from "html-react-parser";
import { Loading } from "components/Loading/Loading";
import { useEffect, useState, useRef } from "react";
import Button from "components/Button/Button";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { useSearchContact } from "api/resources/contacts/contacts";
import {
  useGetCurrentUser,
  useSearchUser,
} from "api/resources/organization/users";
import {
  SelectField,
  SelectFieldCustom,
  TextFieldSimple,
} from "components/inputs";

export function Preview({ onClose, email, customFields, projectId }) {
  const buildPreview = useGetEmailPreview();
  const buildHTMLPreview = useGetHTMLEmailPreview();
  const sendPreview = useSendEmailPreview();
  const getUser = useGetCurrentUser();

  const [fields, setFields] = useState(buildInitialFields());
  const [toFrom, setToFrom] = useState({
    to: "jdoe@reactiondata.com",
    from: "",
  });
  const [content, setContent] = useState(<Loading />);

  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  function buildInitialFields() {
    let init = {
      firstName: "John",
      lastName: "Doe",
      email: "jdoe@reactiondata.com",
      prefix: "",
    };

    let necessary = getFields();

    for (let field of necessary) {
      if (field.customField) {
        let customField = field.customField;

        if (customField.delimiter) {
          init[customField.name] = [];
          for (let i = 0; i < 3 && i < customField.properties.length; i++) {
            init[customField.name].push(customField.properties[i]);
          }
        } else {
          init[customField.name] = customField.properties[0];
        }
      }
    }

    return init;
  }

  function onSuccess(data) {
    let html = data.preview.html;

    if (html) {
      setContent(parse(html));
    } else {
      setContent("Error");
    }

    if (email.dynamicFrom) {
      setToFrom((old) => {
        return { ...old, from: data.preview.from.email };
      });
    }
  }

  useEffect(() => {
    const dynmcConditions = email.dynamicFrom
      ? JSON.stringify(email.dynamicConditions)
      : "";
    const conditions = email.conditions ? JSON.stringify(email.conditions) : "";

    if (email.useHTML) {
      buildHTMLPreview.mutate(
        {
          html: email.html ? email.html : "",
          fields: JSON.stringify(fields),
          projectId: projectId,
          dynamicConditions: dynmcConditions,
          distConfigId: email.distConfigId ? email.distConfigId : "",
        },
        {
          onSuccess: onSuccess,
        }
      );
    } else {
      buildPreview.mutate(
        {
          encoding: email.encoding ? email.encoding : "",
          fields: JSON.stringify(fields),
          projectId: projectId,
          dynamicConditions: dynmcConditions,
          distConfigId: email.distConfigId ? email.distConfigId : "",
          conditions: conditions,
        },
        {
          onSuccess: onSuccess,
        }
      );
    }
  }, [fields]);

  function getFields() {
    let fields = [];

    if (!email.useHTML && email.encoding) {
      let data = JSON.parse(email.encoding);
      for (let div of data) {
        if (div.text) {
          for (let key of ["{recipient", "{condition"]) {
            let ind = div.text.indexOf(key);
            while (ind > -1) {
              if (div.text.includes("}", ind)) {
                let closeInd = div.text.indexOf("}", ind);
                let nextOpenInd = div.text.indexOf("{", ind + 1);
                if (nextOpenInd === -1 || closeInd < nextOpenInd) {
                  // It closed the one that is open

                  const from = ind + "recipient".length + 2; // account for " " space;
                  const to = closeInd - 1;
                  let text = div.text.slice(from, to + 1);

                  if (replaceable(key, text)) {
                    if (key === "{recipient") {
                      if (!fields.includes(text)) fields.push(text);
                    } else {
                      let conditionFields = getConditionFields(text);
                      for (let field of conditionFields) {
                        if (!fields.includes(field)) fields.push(field);
                      }
                    }
                  }
                }
              }
              ind = div.text.indexOf(key, ind + 1);
            }
          }
        }
      }
    }

    if (email.dynamicFrom && email.dynamicConditions) {
      if (!fields.includes(email.dynamicConditions.fieldName)) {
        fields.push(email.dynamicConditions.fieldName);
      }
    }

    let editables = [];
    if (fields.length) {
      for (let field of fields) {
        let lowered = field.toLowerCase();
        if (lowered === "full name" || lowered === "doctor name") {
          let them = [
            { value: "prefix", label: "Prefix" },
            {
              value: "firstName",
              label: "First Name",
            },
            {
              value: "lastName",
              label: "Last Name",
            },
          ];
          for (let one of them) {
            if (!editables.some((o) => o.value === one.value)) {
              editables.push(one);
            }
          }
        } else if (
          lowered === "first name" &&
          !editables.some((e) => e.value === "firstName")
        ) {
          editables.push({
            value: "firstName",
            label: "First Name",
          });
        } else if (
          lowered === "last name" &&
          !editables.some((e) => e.value === "lastName")
        ) {
          editables.push({
            value: "lastName",
            label: "Last Name",
          });
        } else {
          let customField = customFields.find((f) => f.name === field);
          if (customField) {
            editables.push({
              label: customField.displayName,
              value: customField.name,
              customField: customField,
            });
          }
        }
      }
    }

    return editables;
  }

  function replaceable(key, text) {
    if (key === "{recipient") {
      let lowered = text.toLowerCase();

      return (
        lowered === "full name" ||
        lowered === "last name" ||
        lowered === "first name" ||
        lowered === "doctor name" ||
        customFields.some((f) => f.name === text)
      );
    }
    if (email.conditions) {
      return email.conditions.some((c) => c.name === text);
    }
    return false;
  }

  function getConditionFields(text) {
    let condition = email.conditions.find((c) => c.name === text);
    if (condition) {
      let fields = [condition.field];
      for (let showElse of ["show", "else"]) {
        if (Array.isArray(condition[showElse])) {
          fields = [...fields, ...condition[showElse]];
        }
      }
      return fields;
    }
    return [];
  }

  function handleFieldChange(field, val) {
    let copy = { ...fields };

    if (field.customField) {
      if (field.customField.multiple) {
        copy[field.value] = val.map((a) => a.value);
      } else {
        copy[field.value] = val.value;
      }
    } else {
      copy[field.value] = val;
    }

    setFields(copy);
  }

  function onSeeForContact(contact) {
    let needed = getFields();

    let copy = {};

    for (let field of needed) {
      if (field.value in contact) {
        copy[field.value] = contact[field.value];
      } else if (field.customField && contact.attribute) {
        if (field.customField.delimiter) {
          let attributes = contact.attribute.filter(
            (a) => a.customFieldId === field.customField.id
          );
          copy[field.value] = attributes.map((a) => a.name);
        } else {
          let attribute = contact.attribute.find(
            (a) => a.customFieldId === field.customField.id
          );
          if (attribute) {
            copy[field.value] = attribute.name;
          }
        }
      }
    }

    setToFrom((old) => {
      return { ...old, to: contact.email };
    });

    setFields(copy);
  }

  function getValue(field) {
    if (!fields[field.value]) {
      return null;
    }

    let obj = fields[field.value];
    if (Array.isArray(obj)) {
      return obj.map((val) => {
        return {
          value: val,
          label: val,
        };
      });
    } else {
      return {
        value: obj,
        label: obj,
      };
    }
  }

  function onSend(to) {
    const dynmcConditions = email.dynamicFrom
      ? JSON.stringify(email.dynamicConditions)
      : "";
    const conditions =
      email?.conditions && email?.conditions?.length > 0
        ? JSON.stringify(email.conditions)
        : "";

    sendPreview.mutate(
      {
        to: to,
        subject: email.subject,
        encoding: email.useHTML ? "" : email.encoding ? email.encoding : "",
        html: email.useHTML ? email.html : "",
        fields: JSON.stringify(fields),
        dynamicConditions: dynmcConditions,
        projectId: projectId,
        distConfigId: email.distConfigId,
        conditions: conditions,
      },
      {
        onSuccess: (data) => {
          setSending(false);
          setSent(true);
          setTimeout(() => setSent(false), 1000);
        },
        onError: (data) => {
          console.log("Couldn't Send");
          setSending(false);
        },
      }
    );
    setSending(true);
  }

  function isGoogle() {
    var ua = navigator.userAgent;
    if (ua.indexOf("Chrome") > -1) {
      return true;
    }
    return false;
  }

  return (
    <Modal
      onClose={onClose}
      show={true}
      modalStyle={{
        borderRadius: "1rem",
        width: "95%",
        maxHeight: "95%",
        height: "100%",
      }}
      dark
    >
      <div
        className={`${styles.container} ${
          isGoogle() ? styles.separateScroll : ""
        }`}
      >
        <div className={styles.controls}>
          {getFields().length > 0 && (
            <>
              <div className={styles.fields}>
                <div className={styles.title} style={{ marginBottom: "5px" }}>
                  {" "}
                  Adjust Hypothetical Fields <i className="bi bi-gear"></i>
                </div>
                {getFields().map((field) => (
                  // <CombinedInput
                  //   style={{ width: "250px" }}
                  //   label={field.label}
                  //   value={getValue(field)}
                  //   selectMultiple={
                  //     field.customField && field.customField.delimiter
                  //   }
                  //   select={field.customField && !field.customField.delimiter}
                  //   options={
                  //     field.customField
                  //       ? field.customField.properties
                  //       : undefined
                  //   }
                  //   icon={field.customField ? "bi bi-chevron-compact-down" : ""}
                  //   onChange={(val) => handleFieldChange(field, val)}
                  // />

                  <>
                    {field.customField ? (
                      <SelectField
                        label={field.label}
                        selectMultiple={field.customField.multiple}
                        options={field.customField.attribute.map((a) => {
                          return {
                            value: a.name,
                            label: a.name,
                          };
                        })}
                        value={getValue(field)}
                        onChange={(val) => handleFieldChange(field, val)}
                        placeholder=""
                      />
                    ) : (
                      <TextFieldSimple
                        label={field.label}
                        onChange={(val) => handleFieldChange(field, val)}
                        value={fields[field.value]}
                        style={{ fontSize: ".85rem" }}
                      />
                    )}
                  </>
                ))}
              </div>

              <ContactSearch onPick={onSeeForContact} />
            </>
          )}

          {getUser.isSuccess && (
            <SendTo
              currUser={getUser.data.me}
              onSend={onSend}
              sending={sending}
              sent={sent}
              toFrom={toFrom}
            />
          )}
        </div>

        <div className={styles.emailPreview}>
          <div className={styles.toFromContainer}>
            <div className={styles.toFrom}>
              To:
              <div className={styles.to}>{toFrom.to}</div>
            </div>
            <div className={styles.toFrom}>
              From:
              <div className={styles.from}>{toFrom.from}</div>
            </div>
            <div className={styles.toFrom}>
              {email.subject ? (
                <div className={styles.subject}>{email.subject}</div>
              ) : (
                "No Subject"
              )}
            </div>
          </div>

          <div className={styles.preview}>{content}</div>
        </div>
      </div>
    </Modal>
  );
}

function ContactSearch({ onPick }) {
  const [search, setSearch] = useState("");

  const searchContacts = useSearchContact(search, 15, {
    item: "firstName",
    descend: false,
  });

  const [contact, setContact] = useState(null);

  function onPickContact(contact) {
    setContact(contact);
    onPick(contact);
    setSearch("");
  }

  return (
    <div className={`${styles.fields} ${styles.pickContact}`}>
      <div className={styles.title}>As a contact would receive it</div>
      {contact && (
        <div className={styles.contact}>
          {`${contact.firstName} ${contact.lastName}`}
        </div>
      )}

      <SearchSomething
        search={search}
        setSearch={setSearch}
        data={searchContacts.isSuccess ? searchContacts.data.contacts : null}
        onPick={onPickContact}
        placeholder={"Contact"}
      />
    </div>
  );
}

function SendTo({ onSend, currUser, sending, sent, toFrom }) {
  const [to, setTo] = useState(currUser?.email);
  const [search, setSearch] = useState("");
  const [confirm, setConfirm] = useState(false);

  const [user, setUser] = useState("");

  const searchUser = useSearchUser(search);

  function onConfirm() {
    onSend(to);
    setConfirm(false);
  }

  function confirmFirst() {
    if (!sent && !sending) {
      setConfirm(true);
    }
  }

  function onChangeTo(val) {
    if (confirm) {
      setConfirm(false);
    }
    setUser(null);
    setTo(val);
  }

  function onPickUser(user) {
    setTo(user.email);
    setUser(user);
  }

  return (
    <div className={`${styles.fields} ${styles.sendContainer}`}>
      <div className={styles.title} style={{ marginBottom: "5px" }}>
        {" "}
        Send Preview To <i className="bi bi-mailbox"></i>
      </div>

      <div className={styles.configTo}>
        <CombinedInput
          style={{ width: "250px" }}
          label={"To"}
          value={to}
          onChange={onChangeTo}
        />

        {user && (
          <div className={styles.user}>
            {" "}
            {user.firstName} {user.lastName}{" "}
          </div>
        )}

        <SearchSomething
          search={search}
          setSearch={setSearch}
          data={searchUser.isSuccess ? searchUser.data.searchUser : null}
          placeholder={"Users"}
          onPick={onPickUser}
        />
      </div>

      <CombinedInput
        style={{ width: "250px", marginTop: "10px", fontSize: ".85rem" }}
        label={"From"}
        value={toFrom?.from}
        disable
        // onChange={(val) => setTo(val)}
      />

      <div className={styles.buttons}>
        {!confirm && (
          <Button
            shadow
            onClick={confirmFirst}
            disable={!to}
            blue={sending || sent}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {sending && (
                <>
                  <Loading height={30} width={30} /> Sending
                </>
              )}
              {sent && (
                <>
                  <i className="bi bi-check2-circle"></i>Sent
                </>
              )}
              {!sending && !sent && (
                <>
                  {" "}
                  <i className="bi bi-send"></i>Send
                </>
              )}
            </div>
          </Button>
        )}
        {confirm && (
          <>
            <Button shadow onClick={() => setConfirm(false)}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <i className="bi bi-x-lg"></i> Cancel
              </div>
            </Button>

            <Button shadow blue onClick={onConfirm}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <i className="bi bi-check2"></i> Confirm
              </div>
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

function SearchSomething({ search, setSearch, data, placeholder, onPick }) {
  const [active, setActive] = useState(false);

  const ref = useRef(null);

  function setUp() {
    if (!active) {
      setActive(true);
      setTimeout(() => document.addEventListener("click", listener), 300);
    }
  }

  function listener(e) {
    if (!ref.current || !ref.current.contains(e.target)) {
      setActive(false);
      document.removeEventListener("click", listener);
      setSearch("");
    }
  }

  return (
    <div className={styles.searchField}>
      <div className={styles.label}>Search</div>
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        type="text"
        placeholder={placeholder}
        className={styles.search}
        onFocus={setUp}
        // autoComplete={false}
        ref={ref}
      />
      {active && search && (
        <div className={styles.anchor}>
          <div className={styles.results}>
            {data && (
              <>
                {data.length > 0 ? (
                  data.map((d, i) => (
                    <div
                      className={styles.option}
                      onClick={() => onPick(d)}
                      key={i}
                    >
                      {d.firstName} {d.lastName}
                    </div>
                  ))
                ) : (
                  <div className={`${styles.noData}`}>No data</div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
