/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";

export const useFetchRolesGql = (id) => {
  const query = gql`
    query Query {
      rolesByOrgId(orgId: "${id}") {
        id
        name
        description
        isAdmin
        organizationId
        dashboardId
        showDash

        #Dashboard
        canViewDashboardTab
        canEditPersonalDashboard
        canViewPersonalDashboard
        canEditOrgDashboard
        canViewOrgDashboard
        canEditOthersDashboards
        canEditRoleDashboards
    
        #Surveys
        canViewSurveysTab
        canCreateEditPersonalSurveys
        canCreateEditOrgSurveys
        canSendOrgSurveyEmails
        canViewRawResultsForOrg
        canViewOrgSurveys
    
        #Analyze
        canViewAnalyzeTab
        canCreateEditPersonalReports
        canCreateAnalyzeFolders
        canShareReports
        canViewOrgReports
        canCreateEditOrgReports
        canShareOrgReports
    
        #Contacts
        canViewContactsTab
        canCreateEditPersonalContacts
        canViewOrgContacts
        canCreateEditOrgContacts
        canSeeContactInfo
    
        #Audiences
        canViewAudiencesTab
        canCreateEditPersonalAudiences
        canViewOrgAudiences
        canCreateEditOrgAudiences
    
        #Organization
        canViewOrgTab
        canEditOrgDetails
        canViewOrgDetails
        canEditOrgUsers
        canViewOrgUsers
        canEditOrgRoles
        canViewOrgRoles
        canEditOrgImages
        canViewOrgImages




        seeAllSurveys
        seeSurveyDetailsPage
        editSurveyDetails
        seeSurveyBuildPage
        editSurveyDesign
        seeSurveyDesignPage
        seeSurveySendPage
        canComposeSurveyDistribution
        seeSurveyDistributionStats
        seeSurveyDeliveries
        seeSurveyResultsPage
        canCreatePersonalReport
        canCreateOrganizationReport
        canEditOrganizationReport
        canDeleteReport
        canDownloadReport
        canCreateReportTemplate
        canEditChart
        canDeleteChart
        createSurvey
        copySurvey
        sendSurvey
        canDeleteParticipation
        canEditParticipation
        sendFromOrgEmail
        canSeeAnswers
        seeSurveyQuestions
        editSurveyQuestions
        seeUnPublishedReports
        canEditOrgReports
        seePublishedReports
        seeOrganization
        seeDashboard
        editDashboard
        seeAnalyze
        canRecieveNotifications
        editAnalyze
        editVisualization
        saveVisualization
        canDownload
        createContactforTeam
        editContact
        createAudience
        canEditUserGroup
        canEditUser
        canSeeUser
        canSeeDistributionSetting
        canEditOrgDetails
        seeAllContacts
        canEditContacts
        canSeeAllAudience
        canEditDistributionSetting
        canCreateDistribution
        canUpdateOrganization
        
        createdAt
        updatedAt
        deletedAt
        canUpdateRoles
        seeIndividualResults
        canEditOrgResults
        seeOrganizationResults
        canUseDataUploader
        addSurveyGroup
        editSurveyGroup
        canShareReport
      }
    }
  `;

  return useGqlQuery(["roles"], query, {});
};

export const useFetchRole = () => {
  const query = gql`
    query Query {
      role: getRole {
        id
        name
        description
        isAdmin
        organizationId
        dashboardId
        showDash

        #Dashboard
        canViewDashboardTab
        canEditPersonalDashboard
        canViewPersonalDashboard
        canEditOrgDashboard
        canViewOrgDashboard
        canEditOthersDashboards
        canEditRoleDashboards

        #Surveys
        canViewSurveysTab
        canCreateEditPersonalSurveys
        canCreateEditOrgSurveys
        canSendOrgSurveyEmails
        canViewRawResultsForOrg
        canViewOrgSurveys

        #Analyze
        canViewAnalyzeTab
        canCreateEditPersonalReports
        canCreateAnalyzeFolders
        canShareReports
        canViewOrgReports
        canCreateEditOrgReports
        canShareOrgReports

        #Contacts
        canViewContactsTab
        canCreateEditPersonalContacts
        canViewOrgContacts
        canCreateEditOrgContacts
        canSeeContactInfo

        #Audiences
        canViewAudiencesTab
        canCreateEditPersonalAudiences
        canViewOrgAudiences
        canCreateEditOrgAudiences

        #Organization
        canViewOrgTab
        canEditOrgDetails
        canViewOrgDetails
        canEditOrgUsers
        canViewOrgUsers
        canEditOrgRoles
        canViewOrgRoles
        canEditOrgImages
        canViewOrgImages

        seeAllSurveys
        seeSurveyDetailsPage
        editSurveyDetails
        seeSurveyBuildPage
        editSurveyDesign
        seeSurveyDesignPage
        seeSurveySendPage
        canComposeSurveyDistribution
        seeSurveyDistributionStats
        seeSurveyDeliveries
        seeSurveyResultsPage
        canCreatePersonalReport
        canCreateOrganizationReport
        canEditOrganizationReport
        canDeleteReport
        canDownloadReport
        canCreateReportTemplate
        canEditChart
        canDeleteChart
        createSurvey
        copySurvey
        sendSurvey
        canDeleteParticipation
        canEditParticipation
        sendFromOrgEmail
        canSeeAnswers
        seeSurveyQuestions
        editSurveyQuestions
        seeUnPublishedReports
        canEditOrgReports
        seePublishedReports
        seeOrganization
        seeDashboard
        editDashboard
        seeAnalyze
        canRecieveNotifications
        editAnalyze
        editVisualization
        saveVisualization
        canDownload
        createContactforTeam
        editContact
        createAudience
        canEditUserGroup
        canEditUser
        canSeeUser
        canSeeDistributionSetting
        canEditOrgDetails
        seeAllContacts
        canEditContacts
        canSeeAllAudience
        canEditDistributionSetting
        canCreateDistribution
        canUpdateOrganization

        canUpdateRoles
        seeIndividualResults
        canEditOrgResults
        seeOrganizationResults
        canUseDataUploader
        addSurveyGroup
        editSurveyGroup
        canShareReport
      }
    }
  `;

  return useGqlQuery(["currentrolesfetch"], query, {});
};

export const useFetchQChartRoles = () => {
  const query = gql`
    query Query {
      role: getRole {
        name
        canSeeContactInfo
        organizationId
        canViewDashboardTab
        canEditPersonalDashboard
        canEditOrgDashboard
        canEditRoleDashboards
        canEditOthersDashboards
        saveVisualization
        editVisualization
        canViewSurveysTab
        canViewAnalyzeTab
      }
    }
  `;

  return useGqlQuery(["qChartRoles"], query, {});
};

export const useFetchOrgRolesList = (id) => {
  const query = gql`
    query Query {
      roles: rolesByOrgId(orgId: "${id}") {
        id
        name
        description
        isAdmin
        organizationId
        dashboardId
        showDash
      }
    }
  `;

  return useGqlQuery(["roles list in org:" + id], query, {});
};

export const useCreateRolesGql = () => {
  const mutation = gql`
    mutation CreateRole($data: roleInput!) {
      createRole(data: $data) {
        id
        organizationId
        name
        description
        createSurvey
        seeAllSurveys
        sendFromOrgEmail
        sendSurvey
        createContactforTeam
        editContact
        createAudience
        canEditUserGroup
        canEditUser
        canSeeUser
        canSeeDistributionSetting
        canEditOrgDetails
        canEditContacts
        canSeeAllAudience
        canEditDistributionSetting
        canDeleteParticipation
        canEditParticipation
        canCreateDistribution
        canUpdateOrganization
        createdAt
        updatedAt
        canSeeAnswers
        canUpdateRoles
        canSeeContactInfo
        editSurveyDetails
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("roles");
    },
  };

  return useGqlMutation(mutation, [], options);
};

export const useToggleRoleDash = () => {
  const mutation = gql`
    mutation ToggleRoleDash($roleId: String!, $val: Boolean!) {
      toggleRoleDash(roleId: $roleId, val: $val)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("roles");
      queryClient.invalidateQueries("currentrolesfetch");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateRolesGql = () => {
  const mutation = gql`
    mutation Mutation($data: roleInput!, $updateRoleId: String!) {
      updateRole(data: $data, id: $updateRoleId) {
        id
        organizationId
        name
        description
        createSurvey
        seeAllSurveys
        sendFromOrgEmail
        sendSurvey

        createContactforTeam
        editContact
        createAudience
        canEditUserGroup
        canEditUser
        canSeeUser
        canSeeDistributionSetting
        canEditOrgDetails
        canEditContacts
        canSeeAllAudience
        canEditDistributionSetting
        canDeleteParticipation
        canEditParticipation
        canCreateDistribution
        canUpdateOrganization
        createdAt
        updatedAt
        canSeeAnswers
        canUpdateRoles
        canSeeContactInfo
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteRoleGql = () => {
  const mutation = gql`
    mutation Mutation($deleteRoleId: String!) {
      deleteRole(id: $deleteRoleId)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);
    },
  };

  return useGqlMutation(mutation, options);
};

export const useChangeUserRole = () => {
  const mutation = gql`
    mutation Mutation($orgId: String!, $roleId: String!, $userId: String!) {
      success: changeUserRole(orgId: $orgId, roleId: $roleId, userId: $userId)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);
    },
  };

  return useGqlMutation(mutation, options);
};
