import React, { useState } from "react";

import styles from "./TextArea.module.scss";

/**
 * An text area input designed for text questions
 * @param {Int} value something
 * @param {Int} placeholder something
 * @param {Int} onSave something
 * @param {Int} autosave something
 * @param {Function} active something
 * @param {Function} disabled something
 * @param {string} color the color for the border when focused
 * @returns {React.ReactElement} a customized text area input
 */

export const TextArea = ({
  value,
  placeholder,
  onSave,
  active,
  disabled,
  color,
  className,
  style,
}) => {
  const [val, setVal] = useState(value);
  const [border, setBorder] = useState({});

  // const saveTimer = null;

  // TODO: add a save timer while focusing on the input
  const handleChange = (event) => {
    setVal(event.target.value);
    // clearTimeout(saveTimer);
    // setTimeout(onSave(event.target.value), 7000);
  };

  const handleFocusOut = (event) => {
    if (onSave) {
      // console.log(`saving... val = ${event.target.value}`);
      onSave(event.target.value);
    }
    setBorder({});
  };

  const handleFocusIn = () => {
    if (color) {
      setBorder({ borderColor: color });
    }
  };

  return (
    <textarea
      className={`${className} ${styles.textarea} ${active && styles.active} ${
        disabled && styles.disabled
      }`}
      type="text"
      value={val}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={handleFocusOut}
      onFocus={handleFocusIn}
      disabled={disabled}
      style={{ ...border, ...style}}
    />
  );
};
