import styles from "./Menu.module.scss";
import { useState, useRef, useEffect } from "react";
import { defaultLink, Link } from "./Link/Link";
import { useFetchDistConfigsByMutation } from "api/resources/projects/distributions";
import { Image } from "./Image/Image";
import { outputEncoding_noSubs } from "../EncodingOutputer";
import { Conditions } from "./Conditions/Conditions";

export const Menu = ({
  onClose,
  menu,
  onFieldPrep,
  onPickField,
  onLinkEdit,
  onSurveyUrl,
  onSigPick,
  onImagePick,
  onAddDivider,
  conditions,
  setConditions,
  onConditionPrep,
  onConditionSwap,
  customFields,
}) => {
  const [hover, setHover] = useState(0);
  const menuRef = useRef();
  const hoverRef = useRef(0);
  const [show, setShow] = useState(menu.pre);
  const linkBuilding = useRef(
    menu.pre ? menu.pre === "Button" || menu.pre === "Link" : false
  );
  const conditionBuilding = useRef(false);
  const colorRef = useRef();

  useEffect(() => {
    document.addEventListener("click", clickListener, true);
    document.addEventListener("keydown", keyListener, true);

    return () => {
      document.removeEventListener("click", clickListener, true);
      document.removeEventListener("keydown", keyListener, true);
    };
  }, []);

  function clickListener(e) {
    if (!menuRef.current || !menuRef.current.contains(e.target)) {
      if (!colorRef.current || !colorRef.current.contains(e.target)) {
        onClose();
        document.removeEventListener("click", clickListener, true);
      }
    }
  }

  function keyListener(e) {
    if (linkBuilding.current || conditionBuilding.current) {
      // e.preventDefault();
      return;
    }

    if (e.key === "ArrowDown") {
      e.preventDefault();
      incMenuHover(1);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      incMenuHover(-1);
    } else if (e.key === "Escape") {
      onClose();
    } else if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();

      let item = items.current[hoverRef.current];
      if (item.onClick) {
        item.onClick();
      }
    } else {
      onClose();
    }
  }

  function incMenuHover(val) {
    if (items.current && items.current.length) {
      let newInd = hoverRef.current + val;
      if (newInd < 0) {
        newInd = items.current.length - 1;
      } else if (newInd > items.current.length - 1) {
        newInd = 0;
      }

      setHover(newInd);
      hoverRef.current = newInd;
    }
  }

  function goTo(name) {
    setHover(0);
    hoverRef.current = 0;
    setShow(name);
  }

  function setUpLink() {
    goTo("Link");
    linkBuilding.current = true;
    onLinkEdit(defaultLink);
  }

  const menuItems = [
    {
      title: "Fields",
      description:
        "Add a dynamic contact field that changes according to each recipient",
      icon: (
        <i
          className={`bi bi-person-bounding-box ${styles.menuIcon} ${styles.fieldIcon}`}
        ></i>
      ),
      onClick: () => {
        goTo("Fields");
        onFieldPrep();
      },
    },
    {
      title: "Link",
      description: "Add a survey link or custom button",

      icon: (
        <i
          className={`bi bi-link-45deg ${styles.menuIcon} ${styles.linkIcon}`}
        ></i>
      ),
      // icon: (
      //   <div className={styles.buttonIcon}>
      //     <div className={styles.mockButton}></div>
      //     <i className={`bi bi-hand-index-fill ${styles.cursorIcon} `}></i>{" "}
      //   </div>
      // ),
      onClick: setUpLink,
    },
    {
      title: "Image",
      description: "Add an image",
      icon: (
        <i
          className={`bi bi-image-alt ${styles.menuIcon} ${styles.imageIcon}`}
        ></i>
      ),
      onClick: () => goTo("Image"),
    },
    {
      title: "Signature",
      description: "Add a signature to the email",
      icon: (
        <i className={`bi bi-pen ${styles.menuIcon} ${styles.sigIcon}`}></i>
      ),
      onClick: () => goTo("Signature"),
    },
    // {
    //   title: "Divider",
    //   description: "Add a line break",
    //   icon: (
    //     <i
    //       className={`bi bi-view-list ${styles.menuIcon} ${styles.divIcon}`}
    //     ></i>
    //   ),
    //   onClick: () => onAddDivider(),
    // },
  ];

  const items = useRef(menuItems);

  function onHover(i) {
    setHover(i);
    hoverRef.current = i;
  }

  function handleFieldPick(field) {
    if (field.name === "*condition*") {
      setShow("Conditions");
      onConditionPrep();
      conditionBuilding.current = true;
    } else {
      onPickField(field);
    }
  }

  return (
    <div className={styles.menu} style={menu.style} ref={menuRef}>
      {!show &&
        menuItems.map((m, i) => (
          <div
            className={`${styles.menuItem} ${
              hover == i ? styles.menuHover : ""
            }`}
            onMouseEnter={() => onHover(i)}
            onClick={m.onClick ? m.onClick : undefined}
          >
            <div className={styles.menuIconHolder}>{m.icon}</div>
            <div className={styles.menuInfo}>
              <div className={styles.menuTitle}>{m.title}</div>
              <div className={styles.menuDescription}>{m.description}</div>
            </div>
          </div>
        ))}

      {show === "Fields" && (
        <Fields
          hover={hover}
          onHover={onHover}
          itemsRef={items}
          onPick={handleFieldPick}
          customFields={customFields}
        />
      )}
      {show === "Link" && (
        <Link
          colorRef={colorRef}
          onLinkEdit={onLinkEdit}
          onSurveyUrl={onSurveyUrl}
          init={menu.init}
        />
      )}
      {show === "Button" && (
        <Link colorRef={colorRef} onLinkEdit={onLinkEdit} button />
      )}
      {show === "Unsub Link" && (
        <Link colorRef={colorRef} onLinkEdit={onLinkEdit} unsub />
      )}

      {show === "Signature" && (
        <Signature
          onPick={onSigPick}
          hover={hover}
          onHover={onHover}
          itemsRef={items}
        />
      )}
      {show === "Image" && <Image onImagePick={onImagePick} />}
      {show === "Conditions" && (
        <Conditions
          conditions={conditions}
          setConditions={setConditions}
          onPick={onPickField}
          customFields={customFields}
          init={menu.init}
          onConditionSwap={onConditionSwap}
        />
      )}
    </div>
  );
};

function Fields({ hover, onHover, itemsRef, onPick, customFields }) {
  const nameItems = [
    {
      displayName: "First name",
      name: "first name",
    },
    {
      displayName: "Last name",
      name: "last name",
    },
    {
      displayName: "Full name",
      name: "full name",
      desc: "(prefix) [first] [last]",
    },
    {
      displayName: "Doctor name",
      name: "doctor name",
      desc: "Dr. [last] / Or [first]",
    },
    {
      displayName: "Condition",
      name: "*condition*",
      desc: "if _ else _",
    },
  ];

  useEffect(() => {
    itemsRef.current = [...nameItems, ...customFields].map((f) => {
      return { ...f, onClick: () => onPick(f) };
    });
  }, []);

  return (
    <>
      {[...nameItems, ...customFields].map((field, i) => (
        <div
          className={`${styles.customField} ${
            hover == i ? styles.hoverField : ""
          } `}
          onMouseEnter={() => onHover(i)}
          onClick={() => onPick(field)}
        >
          {field.displayName}
          {field.desc && <div className={styles.fieldDesc}>{field.desc}</div>}
        </div>
      ))}
    </>
  );
}

function Signature({ onPick, itemsRef, hover, onHover }) {
  const [preview, setPreview] = useState("");

  const fetchSignatures = useFetchDistConfigsByMutation();

  useEffect(() => {
    fetchSignatures.mutate(
      {},
      {
        onSuccess: (data) => {
          let items = [
            ...data.signatures
              .filter((s) => s.encoding)
              .map((s, i) => {
                return {
                  ...s,
                  onClick: () => setUpPreview(i),
                };
              }),
          ];
          itemsRef.current = [
            ...items,
            ...[
              {
                dynamic: true,
                id: "dynamic",
                onClick: () => setUpPreview(items.length),
              },
              // {
              //   html: true,
              //   id: "html',

              // }
            ],
          ];
        },
      }
    );
  }, []);

  useEffect(() => {
    if (preview && preview !== "dynamic") {
      let sig = fetchSignatures.data.signatures.find((s) => s.id === preview);

      let div = document.getElementById(preview);
      if (div) {
        let entry = outputEncoding_noSubs(sig.encoding);

        if (entry) {
          div.innerHTML = "";
          div.appendChild(entry);
        }
      }
    }
  }, [preview]);

  function setUpPreview(ind) {
    let sig = itemsRef.current[ind];
    setPreview((old) => (old === sig.id ? "" : sig.id));

    for (let i = 0; i < itemsRef.current.length; i++) {
      itemsRef.current[i].onClick = () => setUpPreview(i);
    }

    itemsRef.current[ind].onClick = () => onPick(sig);
  }

  return (
    <div className={styles.sigMenu}>
      {fetchSignatures.isSuccess && (
        <>
          {fetchSignatures.data.signatures
            .filter((s) => s.encoding)
            .map((s, i) => (
              <div
                className={`${styles.sigOption} 
              ${hover === i && preview !== s.id ? styles.hoverSig : ""}
              `}
                onClick={() => setUpPreview(i)}
                onMouseEnter={() => onHover(i)}
              >
                <div className={styles.sigName}>{s.aliasName}</div>
                {preview === s.id && (
                  <div
                    className={`${styles.sigPreview} ${
                      hover === i ? styles.hoverSigPreview : ""
                    } `}
                    id={s.id}
                    onClick={() => onPick(s)}
                  ></div>
                )}
              </div>
            ))}
          <div
            className={`${styles.sigOption} 
            ${
              hover === itemsRef.current.length - 1 && preview !== "dynamic"
                ? styles.hoverSig
                : ""
            }
            `}
            onClick={() => setUpPreview(itemsRef.current.length - 1)}
            onMouseEnter={() => onHover(itemsRef.current.length - 1)}
          >
            <div className={styles.sigName}>Dynamic Or HTML Signature</div>

            {preview === "dynamic" && (
              <div
                className={`${styles.sigPreview} ${
                  hover === itemsRef.current.length - 1
                    ? styles.hoverSigPreview
                    : ""
                } 
                ${styles.dynamicSigPreview}
                `}
                onClick={() =>
                  onPick({
                    dynamic: true,
                  })
                }
              >
                <span className={styles.dynamicBar}>{"{"}</span>
                Signature
                <span className={styles.dynamicBar}>{"}"}</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
