import React, { useEffect, useRef, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import styles from "./SurveyCheckList.module.scss";
import { useNavigate } from "react-router-dom";

function SurveyCheckList({ survey, questionCount, distributionCount }) {
  const player1 = useRef(null);
  const player2 = useRef(null);
  const player3 = useRef(null);

  const [play1] = useState(true);
  const [play2, setPlay2] = useState(false);
  const [play3, setPlay3] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPlay2(true);
    }, 500);
    setTimeout(() => {
      setPlay3(true);
    }, 1000);
  }, []);

  const navigate = useNavigate();

  return (
    <div className={styles.checkList}>
      <div className={styles.checkListItem}>
        <span
          onClick={() => {
            localStorage.setItem("activeprojecttab", 1);

            if (survey.isProgram) {
              navigate(`/program/${survey.id}/survey-build`);
            } else {
              navigate(`/project/${survey.id}/survey-build`);
            }
          }}
        >
          Questions{" "}
        </span>
        {play1 && questionCount > 0 && (
          <Player
            autoplay
            onEvent={(e) => {
              if (e === "play") {
                setTimeout(() => {
                  player1?.current?.pause();
                }, 2000);
              }
            }}
            ref={player1}
            src={require("assets/animations/checkmarkgreen.json")}
            style={{
              height: "30px",
              width: "30px",
            }}
          />
        )}
        {questionCount === 0 && <i className="bi-circle"></i>}
      </div>
      <div className={styles.checkListItem}>
        <span
          onClick={() => {
            localStorage.setItem("activeprojecttab", 2);
            localStorage.setItem("activedelivertab", 1);
            if (survey.isProgram) {
              navigate(`/program/${survey.id}/delivery/messages`);
            } else {
              navigate(`/project/${survey.id}/delivery/messages`);
            }
          }}
        >
          {survey.isProgram ? "Messages" : "Distribute"}
        </span>
        {play2 && (survey.activeLink || distributionCount > 0) && (
          <Player
            autoplay
            onEvent={(e) => {
              if (e === "play") {
                setTimeout(() => {
                  player2?.current?.pause();
                }, 2000);
              }
            }}
            ref={player2}
            src={require("assets/animations/checkmarkgreen.json")}
            style={{
              height: "30px",
              width: "30px",
            }}
          />
        )}
        {!survey.activeLink && distributionCount === 0 && (
          <i className="bi-circle"></i>
        )}
      </div>
      {!survey.isProgram && (
        <div className={styles.checkListItem}>
          <span
            onClick={() => {
              localStorage.setItem("activeprojecttab", 3);
              if (survey.isProgram) {
                navigate(`/program/${survey.id}/surveyresults/${survey.rawReportId}`);
              } else {
                navigate(`/project/${survey.id}/surveyresults/${survey.rawReportId}`);
              }
            }}
          >
            Analyze
          </span>
          {play3 && survey?.responseCount > 0 && (
            <Player
              autoplay
              onEvent={(e) => {
                if (e === "play") {
                  setTimeout(() => {
                    player3?.current?.pause();
                  }, 2000);
                }
              }}
              ref={player3}
              src={require("assets/animations/checkmarkgreen.json")}
              style={{
                height: "30px",
                width: "30px",
              }}
            />
          )}
          {survey?.responseCount === 0 && <i className="bi-circle"></i>}
        </div>
      )}
      {survey.isProgram && (
        <div className={styles.checkListItem}>
          <span>Launch</span>
          {play3 && survey?.iterationCount > 0 && (
            <Player
              autoplay
              onEvent={(e) => {
                if (e === "play") {
                  setTimeout(() => {
                    player3?.current?.pause();
                  }, 2000);
                }
              }}
              ref={player3}
              src={require("assets/animations/checkmarkgreen.json")}
              style={{
                height: "30px",
                width: "30px",
              }}
            />
          )}
          {survey?.responseCount === 0 && <i className="bi-circle"></i>}
        </div>
      )}
    </div>
  );
}

export default SurveyCheckList;
