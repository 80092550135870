//External
import React from "react";
import { Link } from "react-router-dom";

//Internal
import styles from "./Header.module.scss";

/**
 * A header component that has a back button option
 * @param {String} title string to be displayed
 * @param {String} backPath the path to go back to
 * @returns {React.ReactElement} a header component
 */


export function Header ({ title, backPath, pathText, backFunction, style, titleStyle }) {
  return (
    <div className={styles.header} style={style}>
      {backPath && (
        <Link to={backPath}>
          {pathText ? <div className={styles.pathText}>{pathText} / </div> : <i className="bi bi-arrow-left"></i>}
        </Link>
      )}
      {backFunction && !backPath && <i className="bi bi-arrow-left" onClick={backFunction}></i>}
      <span style={titleStyle}>{title}</span>
    </div>
  );
};
