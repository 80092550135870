import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./SalesforceMapping.module.scss";
import ColumnMapping from "components/ContactCleaner2.0/ColumnMapping";
import { checkMap } from "components/ContactCleaner2.0/ContactCleaner";
import Button from "components/Button/Button";

function SalesforceMapping({ user, initheads, organization, customFields, showMapping, setShowMapping }) {
  const [custom_fields, setCustomFields] = useState();
  const [customFieldsMap, setCustomFieldsMap] = useState({});
  const [headerMap, setHeaderMap] = useState({});
  const [headers, setHeaders] = useState([]);
  const [externalColumns, setExternalColumns] = useState();
  const [sessionExpired, setSessionExpired] = useState(false);
  const [loading, setLoading] = useState();
  //   const [importing, setImporting] = useState(false);
  //   const [importType, setImportType] = useState(
  //     startImportType ? startImportType : false
  //   );
  //   const [page, setPage] = useState(0);
  //   const [data, setData] = useState([]);
  //   const [maxData, setMaxData] = useState(0);
  //   const [currPage, setCurrPage] = useState(1);
  //   const [dataLoading, setDataLoading] = useState();

  const handleLoginSalesforce = async () => {
    try {
      const url = `${process.env.REACT_APP_SALESFORCE_KEY2}&userID=${user?.id}`;
      const response = await axios.get(url, { withCredentials: true });
      window.location.href = response.data.loginUrl;
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  useEffect(() => {
    let contactColumns = [];

    let url = process.env.REACT_APP_SALESFORCE_COLUMNS;
    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
      params: {
        organization_id: organization.id,
      },
    };
    let externalCols = [];
    let headMap = {};
    setLoading(true);
    //contact columns
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data && response?.data?.length > 0) {
          for (let head of response.data) {
            let custom = false;
            let og = `${head}`;
            let label = `${head}`;
            if (
              head != "Email" &&
              head != "FirstName" &&
              head != "LastName" &&
              head != "Id"
            ) {
              if (head.includes("__c")) {
                label = head.replace("__c", "");
                custom = true;
              }
              let name = head.toLowerCase();
              // if (head === "FirstName" || head === "LastName") {
              //   name = head[0].toLowerCase() + head.slice(1);
              // }
              let header = {
                id: head,
                name: label,
                accessor: head,
                sfName: og,
                canSort: false,
                cell_style: null,
                value: name,
                label: label,
                enabled: false,
                sort: false,
                canSort: true,
                fullName: true,
                custom: false,
                account: false,
                isReaction: false,
              };
              contactColumns.push(header);
              // checkMap(header, headMap, customFields);
            }
          }
          // for (let h of initheads) {
          //   checkMap(h, headMap, customFields);
          // }
          externalCols = response.data;
          let fields = [];
          let fieldsMap = {};
          for (let f of customFields) {
            fields.push({
              id: f.id,
              name: f.name,
              accessor: f.name,
              enabled: true,
              canSort: false,
              cell_style: null,
              sfName: f.name,
              value: f.name,
              label: f.name,
              sort: false,
              canSort: true,
              fullName: true,
              custom: false,
              account: false,
              isReaction: true,
            });
            fieldsMap[f.name] = false;
          }
          setCustomFieldsMap(fieldsMap);
          setCustomFields(fields);
          let finalheaders = [...headers, ...contactColumns];
          let i = 0;
          for (let h of finalheaders) {
            checkMap(h, headMap, customFields, i, finalheaders);
            if (headMap[h?.accessor]?.columns?.length > 0) {
              finalheaders[i].enabled = true;
              fieldsMap[headMap[h?.accessor]?.columns[0]?.value] = true;
            }
            i++;
          }
          setHeaders(finalheaders);
          setExternalColumns([]);
          setHeaderMap(headMap);
          setLoading(false);
          // getAccountCols(url, config, externalCols, contactColumns);
          // }
          //  else {
          //   setHeaders([...headers, ...contactColumns]);
          //   setExternalColumns(externalCols);
          //   // getAccountCols(url, config, externalCols, contactColumns);
          // }
        }
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 401) {
          setSessionExpired(true);
          setTimeout(() => {
            handleLoginSalesforce();
          }, 1000);
        }
      });
  }, []);

  return (
    <div>
      {headers && !loading && (
        
        <ColumnMapping
          // data={data}
          headers={headers}
          setHeaders={setHeaders}
          headerMap={headerMap}
          setHeaderMap={setHeaderMap}
          orgId={organization?.id}
          importType={"Salesforce"}
          sync
          customFieldsMap={customFieldsMap}
          setCustomFieldsMap={setCustomFieldsMap}
          useModal
          showMapping={showMapping}
          setShowMapping={setShowMapping}
          saveModal
        ></ColumnMapping>
      )}

    </div>
  );
}

export default SalesforceMapping;
