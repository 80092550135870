//External
import React from "react";

//Internal
// import styles from "./HorizontalBar.module.scss"

/**
 * A customized Label
 * @param props any children contained in the <Label></Label>
 * @returns {React.ReactElement} a Label
 */
export function HorizontalBar({ width, height, backgroundColor, rotate, style }) {
  const styles = !rotate
    ? {
        width: width ? width : "100%",
        height: height ? height : "10px",
        backgroundColor: backgroundColor ? backgroundColor : "#E9E9E9",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: "5px",
      }
    : {
        width: width ? width : "10px",
        height: height ? height : "100%",
        backgroundColor: backgroundColor ? backgroundColor : "#E9E9E9",
        borderRadius: "5px",
      };

  return <div style={{...styles, ...style}}></div>;
}
