import { useState } from "react";

//Internal
import styles from "./CreateJoinCode.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { useCreateUserJoinCode } from "api/resources/authentication/register";
import { Loading } from "components/Loading/Loading";
import Button from "components/Button/Button";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import { useFetchRolesGql } from "api/resources/organization/roles";

export default function CreateJoinCode({ close, show, orgid }) {
  const fetchRoles = useFetchRolesGql(orgid);
  const createJoinCode = useCreateUserJoinCode();
  const [role, setRole] = useState({
    value: "",
    id: "",
  });
  const [missingInput, setMissingInput] = useState(false);
  const [codeCreated, setCodeCreated] = useState(false);
  const [code, setCode] = useState();
  const [email, setEmail] = useState("");
  const [emailed, setEmailed] = useState({
    isError: false,
    isLoading: false,
    isSuccess: false,
  });
  const [copied, setCopied] = useState(false);

  const handleCreateCode = () => {
    if (role.value != "") {
      setMissingInput(false);
      createJoinCode.mutate(
        {
          data: {
            forOrganization: true,
            forUser: true,
            roleId: role.id,
          },
        },
        {
          onSuccess: (data) => {
            setTimeout(() => {
              setCode(data.createCode.code);
              setCodeCreated(true);
            }, 500);
          },
        }
      );
    } else {
      setMissingInput(true);
    }
  };

  function getOptions() {
    let roles = fetchRoles.data.rolesByOrgId.map((role, index) => ({
      id: role.id,
      value: role.name,
      name: role.name,
    }));
    return roles;
  }

  const updateEmailedStatus = (field) => {
    let status = {
      isError: false,
      isLoading: false,
      isSuccess: false,
    };
    status[field] = true;
    setEmailed(status);
  };

  const handleEmail = () => {
    if (email != "") {
      setMissingInput(false);
      updateEmailedStatus("isLoading");

      setTimeout(() => {
        updateEmailedStatus("isSuccess");
      }, 1000);
    } else {
      setMissingInput(true);
    }
  };

  return (
    <>
      {fetchRoles.isLoading && <p>Loading...</p>}
      {fetchRoles.isError && <p>Error</p>}
      {fetchRoles.isSuccess && (
        <ReactModal
          show={show}
          onClose={close}
          modalStyle={{ borderRadius: "1em", padding: "1rem", paddingTop: "2rem", width: "500px", overflow: "visible" }}
          dark
        >
          <div className={styles.createUserContent}>
            <div className={styles.header_3} style={{margin: "0"}}>
              {codeCreated ? "Join Code Created!" : "Create a new join code"}
            </div>

            {!codeCreated && (
              <div className={styles.fields}>
                {missingInput && (
                  <span className={styles.inputRequired}>* Role Required</span>
                )}
                <div className={styles.roleHolder}>
                  <SelectFieldCustom
                    options={getOptions()}
                    value={role.value}
                    onChange={(val) => setRole(val)}
                    label="For Role"
                  ></SelectFieldCustom>
                </div>
                <div className={styles.saveBtn}>
                  <Button onClick={handleCreateCode} shadow>
                    {!createJoinCode.isLoading &&
                      !createJoinCode.isSuccess &&
                      !createJoinCode.isError &&
                      "Create Code"}
                    {createJoinCode.isLoading && (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Loading height="20" width="20"></Loading>Working
                      </div>
                    )}
                    {createJoinCode.isSuccess && (
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <i className="bi bi-check-lg"></i>Created
                      </div>
                    )}
                    {createJoinCode.isError && "Error creating one"}
                  </Button>
                </div>
              </div>
            )}
            {codeCreated && (
              <>
                <div className={styles.codeInstructions}>
                  <span>
                    Copy this code for an intended user, or email it to them.
                  </span>
                  <span>
                    This is what they will use to register to use the system.
                  </span>
                </div>
                <div className={styles.codeCreatedDiv}>
                  <div className={styles.code}>
                    {code}{" "}
                    <i
                      className={`${"bi bi-clipboard"} ${styles.clipboard}`}
                      onClick={() => {
                        navigator.clipboard.writeText(code);
                        setCopied(true);
                      }}
                    >
                      {copied && (
                        <i
                          className={`${"bi bi-check"} ${styles.checkmark}`}
                        ></i>
                      )}
                    </i>
                  </div>
                </div>

                <TextFieldSimple
                  valid={!missingInput}
                  invalidMessage="Required"
                  placeholder="Recipients Email"
                  label="Enter An Email"
                  onSave={(val) => setEmail(val)}
                ></TextFieldSimple>
                <div className={styles.saveBtn}>
                  <Button
                    onClick={
                      !emailed.isSuccess && !emailed.isLoading
                        ? handleEmail
                        : null
                    }
                  >
                    {!emailed.isLoading &&
                      !emailed.isSuccess &&
                      !emailed.isError &&
                      "Email to Recipient"}
                    {emailed.isLoading && (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Loading height="20" width="20"></Loading>Working
                      </div>
                    )}
                    {emailed.isSuccess && (
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <i className="bi bi-check-lg"></i>Sent
                      </div>
                    )}
                    {emailed.isError && "Error creating one"}
                  </Button>
                </div>
              </>
            )}
          </div>
        </ReactModal>
      )}
    </>
  );
}
