// External
import styles from "./SurveyPreview.module.scss";

import { useParams } from "react-router-dom";
import Survey from "components/Survey/Survey";

export const SurveyPreview = () => {
  const { id } = useParams();

  return (
    <div className={styles.page}>{id && <Survey projectId={id} preview />}</div>
  );
};
