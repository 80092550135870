// External
import { TextFieldSimple } from "components/inputs";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import React, { useEffect, useRef, useState } from "react";

//Internal
import styles from "./AddButton.module.scss";
import { Label } from "components/layouts/Label/Label";

export const SurveyURL = ({
  handleOnDrag,

}) => {
  return (
    <div className={styles.elements}>
      <div className={styles.surveyButtonContainer}>
        <Label style={{ padding: "0em" }}>Your Survey URL</Label>
        <div className={styles.surveyLink}>
          <span
            id="ReactionDataButtonLink2"
            onDragStart={(e) => handleOnDrag(e)}
            draggable={true}
            href="{{insertlinkhere}}"
            style={{ cursor: "pointer" }}
          >{`{Survey URL}`}</span>
        </div>
      </div>
    </div>
  );
};
