import Modal from "components/ReactModal/ReactModal";
import styles from "./Export.module.scss";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import Button from "components/Button/Button";
import { TextFieldSimple } from "components/inputs";
import { useState } from "react";
import { Loading } from "components/Loading/Loading";
import { read, writeFile } from "xlsx";

export function Export({
  onClose,
  org,
  filters,
  search,
  sort,
  audienceId,
  audienceFilters,
  audienceName,
  headers,
  onExport,
}) {
  function constructName() {
    let name = audienceName ? audienceName : org.name + "_contacts";
    let filterList = "";
    for (let filter of filters) {
      let sub = "";
      sub += filter.label + ":";
      let list = "";
      if (filter.properties) {
        for (let prop of filter.properties) {
          if (list) list += "_";
          list += prop.label;
        }
        sub += list;
      }

      filterList += (filterList ? "&" : "_with_") + sub;
    }

    name += filterList;
    return name;
  }

  const [settings, setSettings] = useState({
    fileType: "csv",
    name: constructName(),
    locally: true,
    email: false,
  });
  const [exporting, setExporting] = useState(false);

  function editField(field, val) {
    let copy = { ...settings };
    copy[field] = val;
    setSettings(copy);
  }

  function runExport() {
    if (!settings.email && !settings.locally) {
      return;
    }

    const exportData = {
      audienceFilters: audienceFilters ? JSON.stringify(audienceFilters) : "",
      audienceId: audienceId ? audienceId : "",
      filters: filters ? JSON.stringify(filters) : "",
      search: search ? search : "",
      sort: sort,
      settings: { ...settings },
      headers: {
        columns: headers
          .filter((h) => h.enabled && h.id !== "salesforceId")
          .map((h) => {
            return {
              accessor: h.value,
              name: h.label,
            };
          }),
      },
    };

    const options = {
      onSuccess: (data) => {
        console.log(" contacts exported!");
        setExporting(false);

        function downloadByLink(blob, fileName) {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }

        if (settings.locally) {
          if (data.exportContacts.csvString) {
            const csvString = data.exportContacts.csvString;
            if (settings.fileType === "xlsx") {
              const workbook = read(csvString, {
                type: "string",
                raw: true,
              });
              writeFile(workbook, settings.name + ".xlsx");
            } else if (settings.fileType === "csv") {
              const blob = new Blob([csvString], {
                type: "text/csv;charset=utf-8;",
              });
              let fileName = settings.name + "." + settings.fileType;

              downloadByLink(blob, fileName);
            }
          } else if (data.exportContacts.zippedBase64) {
            const binaryString = atob(data.exportContacts.zippedBase64);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes], { type: "application/zip" });
            let fileName = settings.name + ".zip";
            downloadByLink(blob, fileName);
          }
        } else {
          console.log(data.exportContacts.csvString);
        }
      },

      onError: (err) => {
        console.log("Unable to export");
      },
    };

    onExport(exportData, options);

    setExporting(true);
  }

  return (
    <Modal
      show
      onClose={onClose}
      modalStyle={{
        borderRadius: ".5rem",
        height: "400px",
        width: "400px",
      }}
      dark
    >
      <div className={styles.container}>
        <div className={`${styles.header_3}`} style={{ margin: "0" }}>
          Export
        </div>

        <FlexCol gap={"6px"}>
          <div className={styles.subtitle}>File Type</div>

          <FlexRow style={{ width: "fit-content", paddingLeft: "10px" }}>
            <Checkbox
              checked={settings.fileType === "csv"}
              onChange={(e) => editField("fileType", "csv")}
            />
            <div className={styles.option}>csv </div>
          </FlexRow>
          <FlexRow style={{ width: "fit-content", paddingLeft: "10px" }}>
            <Checkbox
              checked={settings.fileType === "xlsx"}
              onChange={(e) => editField("fileType", "xlsx")}
            />
            <div className={styles.option}>xlsx</div>
          </FlexRow>
        </FlexCol>

        <FlexRow align={"flex-end"} gap={".2rem"} start>
          <TextFieldSimple
            label={"File Name"}
            containerStyle={{ width: "250px" }}
            lineTheme
            style={{
              fontSize: ".85rem",
              // textAlign: "right",
              paddingRight: "2px",
            }}
            value={settings.name}
            onChange={(val) => editField("name", val)}
          />
          <div
            className={styles.option}
            style={{ paddingBottom: "2px", fontSize: ".85rem" }}
          >
            {`.${settings.fileType}`}
          </div>
        </FlexRow>

        <FlexCol gap={"6px"}>
          <div className={styles.subtitle}>Delivery</div>

          <FlexRow style={{ width: "fit-content", paddingLeft: "10px" }}>
            <Checkbox
              onChange={(e) => editField("locally", e.target.checked)}
              checked={settings.locally}
            />{" "}
            <div className={styles.option}>
              <i
                className={`bi-download ${
                  settings.locally ? styles.applied : ""
                }`}
              ></i>
              Download to my computer
            </div>
          </FlexRow>
          <FlexRow style={{ width: "fit-content", paddingLeft: "10px" }}>
            <Checkbox
              onChange={(e) => editField("email", e.target.checked)}
              checked={settings.email}
            />
            <div className={styles.option}>
              <i
                className={`bi-send ${settings.email ? styles.applied : ""}`}
              ></i>
              Email me the file
            </div>
          </FlexRow>
        </FlexCol>

        <FlexRow justify={"center"}>
          <Button
            blue
            shadow
            style={{ alignSelf: "center", gap: "10px" }}
            onClick={runExport}
          >
            {exporting ? (
              <>
                <Loading height={25} width={25} /> Exporting
              </>
            ) : (
              <>
                {" "}
                <i className="bi-cloud-download"> </i>Export
              </>
            )}
          </Button>
        </FlexRow>
      </div>
    </Modal>
  );
}
