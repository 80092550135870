// External
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//Internal
import { Loading } from "components/Loading/Loading";
import { ErrorPage } from "pages/error_page/ErrorPage";

import SurveyHandler from "./SurveyHandler";
import { useGetParticipation } from "api/resources/projects/participations";

export const SurveyTaker = () => {
  const { participantId, distId } = useParams();
  const getParticipation = useGetParticipation();
  const [participation, setParticipation] = useState();

  useEffect(() => {
    getParticipation.mutate(
      {
        participationId: participantId,
      },
      {
        onSuccess: (data) => {
          setParticipation(data.getParticipation);
        },
        onError: (data) => {
          console.log("start participation error");
        },
      }
    );
  }, []);

  return (
    <>
      {getParticipation.isError && (
        <ErrorPage
          error={getParticipation.error}
          backup={"Survey Taker - startParticipation"}
          autoSend
        ></ErrorPage>
      )}
      {getParticipation.isLoading && <Loading></Loading>}
      {getParticipation.isSuccess && participation && (
        <SurveyHandler
          participation={participation}
          alreadyTaken={participation.completed}
          distId={distId}
        />
      )}

    </>
  );
};
