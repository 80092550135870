import React, { CSSProperties } from "react";
// import type {UniqueIdentifier} from '@dnd-kit/core';
import { AnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { TreeItem, Props as TreeItemProps } from "./TreeItem";
import { iOS } from "../../utilities";

// interface Props extends TreeItemProps {
//   id: UniqueIdentifier;
// }

const animateLayoutChanges = ({ isSorting, wasDragging }) =>
  isSorting || wasDragging ? false : true;

export function SortableTreeItem({ id, depth, members, onClick, folder, open, edit, setedit, icon, setEditFolder, index, ...props }) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <TreeItem
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      style={style}
      depth={depth}
      ghost={isDragging}
      disableSelection={iOS}
      disableInteraction={isSorting}
      handleProps={{
        ...attributes,
        ...listeners,
        
      }}
      members={members}
      id={id}
      onClick={onClick}
      folder={folder}
      open={open}
      setedit={setedit}
      edit={edit}
      icon={icon}
      setEditFolder={setEditFolder}
      index={index}
      {...props}
    />
  );
}
