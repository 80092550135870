import { useState } from "react";
import { NumChoices } from "components/inputs/input_fields/NumChoices/NumChoices";

export const NumberScaleQuestion = ({
  question,
  color,
  onAnswer,
  onRetract,
  answerMap,
  disable,
}) => {
  const [selected, setSelected] = useState(answerMap[question.id]);

  function onChange(val) {
    if (selected == val) {
      setSelected(null);
      onRetract(question.id);
    } else {
      setSelected(val);
      onAnswer(question.id, val);
    }
  }

  return (
    <NumChoices
      value={0}
      min={Number(question.scaleQuestion?.min)}
      max={Number(question.scaleQuestion?.max)}
      step={Number(question.scaleQuestion?.step)}
      minDescription={question.scaleQuestion.minDescription}
      maxDescription={question.scaleQuestion.maxDescription}
      color={color}
      selected={answerMap[question.id]}
      onChange={onChange}
      disable={disable}
    />
  );
};
