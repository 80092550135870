import React, { useState, useEffect } from "react";
import styles from "./OneOrTheOther.module.scss";

/**
 * a customized checkbox disguised as a switch
 * @param {Boolean} startChecked tells the component to start checked or not
 * @param {Function} handleCheck called when the switch to toggled and returns whether it is checked or not
 * @param {String} id an id that is placed in the input to be able to be referenced by javascript
 * @returns {React.ReactElement} a numberscale clickable component
 */

export const OneOrTheOther = ({
  checked,
  onCheck,
  disable,
  left,
  right,
  style,
  activeColor,
  inactiveColor,
  color,
}) => {
  const handleChange = () => {
    onCheck(!checked);
  };

  return (
    <div className={styles.container} style={style}>
      <div
        className={`${styles.label} ${!checked ? styles.active : ""}`}
        style={
          checked
            ? inactiveColor
              ? { color: inactiveColor }
              : undefined
            : activeColor
            ? { color: activeColor }
            : undefined
        }
      >
        {left}
      </div>
      <label className={styles.switch}>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={disable ? true : false}
        />
        <span
          className={styles.slider}
          style={color ? { backgroundColor: color } : undefined}
        ></span>
      </label>
      <div
        className={`${styles.label} ${checked ? styles.active : ""}`}
        style={
          checked
            ? activeColor
              ? { color: activeColor }
              : undefined
            : inactiveColor
            ? { color: inactiveColor }
            : undefined
        }
      >
        {right}
      </div>
    </div>
  );
};
