import React from "react";
import styles from "./ImageContainer.module.scss";
import profile from "assets/images/blank-profile-picture.png";

function ImageContainer({ src, size, margin }) {
  return (
    <div
      className={styles.imageContainer}
      style={{
        width: size ? size + "0px" : "",
        height: size ? size + "0px" : "",
        marginLeft: margin ? margin + "px" : "",
      }}
    >
      <img
        src={src ? src : profile}
        style={{
          width: size ? size + "0px" : "",
          height: size ? size + "0px" : "",
        }}
      />
    </div>
  );
}

export default ImageContainer;
