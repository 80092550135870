// External
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Internal
import { useCountDeliverySearch } from "api/resources/projects/deliveries";
import { trimDate } from "../distributions/email/Email";
import ContactTable from "pages/contacts/all_contacts/ContactTable";
import Modal from "components/ReactModal/ReactModal";
import { useFetchRole } from "api/resources/organization/roles";
import { Loading } from "components/Loading/Loading";
import { useFetchMyOrg } from "api/resources/organization/organization";
import ContactProfile from "components/Popout/ContactProfile";

export const Deliveries = ({ email, onClose, user }) => {
  const { id } = useParams();
  const [headers, setHeaders] = useState(initHeaders());

  const [contact, setContact] = useState();

  function initHeaders() {
    let heads = [
      {
        id: "status",
        value: "status",
        name: "Status",
        accessor: "status",
        label: "Status",
        // width: 80,
        dataType: "text",
        enabled: true,
        sort: true,
        canSort: true,
        cell_style: (delivery) => (
          <div>{delivery && delivery[0].toUpperCase() + delivery.slice(1)}</div>
        ),
      },
      {
        id: "email",
        value: "email",
        name: "Email",
        // accessor: "email",
        label: "Email",
        // width: 250,
        dataType: "text",
        enabled: true,
        sort: true,
        canSort: true,
        cell_style: null,
        editable: true,
        cell_style: (delivery) => (
          <div>{delivery && delivery?.participation?.contact?.email}</div>
        ),
      },
      {
        id: "date",
        value: "date",
        name: "Date",
        accessor: "date",
        label: "Date",
        // width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        editable: true,
        cell_style: (date) => <div>{date && trimDate(date)}</div>,
      },
      {
        id: "reason",
        value: "reason",
        name: "Reason",
        accessor: "error",
        label: "Reason",
        // width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        editable: true,
        // cell_style: (date) => <div>{date &&  trimDate(date)}</div>,
      },
      {
        id: "opens",
        value: "opens",
        name: "Opens",
        accessor: "opens",
        label: "Opens",
        // width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        editable: true,
        // cell_style: (delivery) => <div>{delivery && delivery?.participation?.contact?.firstName}</div>,
      },
      {
        id: "clicks",
        value: "clicks",
        name: "Clicks",
        accessor: "clicks",
        label: "clicks",
        // width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        editable: true,
        // cell_style: (delivery) => <div>{delivery && delivery?.participation?.contact?.firstName}</div>,
      },
      // {
      //   id: "lastName",
      //   value: "lastName",
      //   name: "Last Name",
      //   // accessor: "lastName",
      //   label: "Last Name",
      //   // width: 100,
      //   dataType: "text",
      //   enabled: true,
      //   sort: false,
      //   canSort: true,
      //   editable: true,
      //   cell_style: (delivery) => <div>{delivery &&  delivery?.participation?.contact?.lastName}</div>,
      // },
    ];
    return heads;
  }

  const [searchString, setSearchString] = useState("");

  const [perPage, setPerPage] = useState(20);
  const [chosenFilters, setChosenFilters] = useState([]);

  const [pageSkip, setPageSkip] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSort, setPageSort] = useState({
    item: "date",
    descend: true,
  });
  const [active, setActive] = useState(email?.sendCount);

  const searchCount = useCountDeliverySearch(
    searchString,
    pageSkip,
    perPage,
    id,
    email.id,
    active + 1 //send count
  );

  const fetchRole = useFetchRole();
  const fetchOrg = useFetchMyOrg();

  useEffect(() => {
    searchCount.refetch();
  }, [pageNumber, pageSort, perPage, searchString]);

  return (
    <>
      {!contact && (
        <Modal
          show
          onClose={onClose}
          modalStyle={{
            borderRadius: "1em",
            height: "95vh",
            width: "95vw",
            maxWidth: "100%",
            overflow: "hidden",
          }}
          dark
        >
          {(fetchRole.isLoading || fetchOrg.isLoading) && <Loading />}
          {fetchRole.isSuccess && fetchOrg.isSuccess && (
            <ContactTable
              headers={headers}
              setHeaders={setHeaders}
              maxDataQuery={searchCount}
              maxDataQueryAccessor={"count"}
              delivery={email?.sendCount}
              currPage={pageNumber}
              setCurrPage={setPageNumber}
              maxItems={perPage}
              onRowClick={setContact}
              sort={pageSort}
              setSort={setPageSort}
              pageSkip={pageSkip}
              setPageSkip={setPageSkip}
              perPage={perPage}
              setPerPage={setPerPage}
              searchString={searchString}
              setSearchString={setSearchString}
              searchPlaceholder={"Search Deliveries..."}
              onClickActionButton={() => setShow(true)}
              hideActionButton={true}
              chosenFilters={chosenFilters}
              setChosenFilters={setChosenFilters}
              orgId={fetchOrg.data.organization?.id}
              organization={fetchOrg.data.organization}
              editFields={false}
              // setEditFields={setEditMode}
              customFields={[]}
              user={user}
              roles={fetchRole.data.role}
              email={email}
              active={active}
              setActive={setActive}
            ></ContactTable>
          )}
        </Modal>
      )}

      {contact && (
        <ContactProfile
          onClose={() => setContact(false)}
          contact={contact?.participation?.contact}
        ></ContactProfile>
      )}
    </>
  );
};
