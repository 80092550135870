import { forEach } from "assets/functions/ArrayFunctions";

export function outputEncoding_noSubs(encoding) {
  try {
    if (!encoding) return null;
    const data = JSON.parse(encoding);

    const entry = document.createElement("div");
    entry.style.padding = "2px 5px";
    entry.style.whiteSpace = "pre-wrap";
    entry.style.overflowWrap = "break-word";

    let currentList = null;

    for (let k = 0; k < data.length; k++) {
      let div = data[k];

      const parentElem = document.createElement(div.list ? "li" : "div");

      if (!div.text) {
        if (div.image) {
          let editBox = document.createElement("div");
          editBox.style.height = div.height + "px";
          editBox.style.width = div.width + "px";

          let image = document.createElement("img");
          image.src = div.src;
          image.id = div.id + "_image";
          image.style.width = div.width + "px";
          image.style.height = div.height + "px";

          editBox.appendChild(image);
          parentElem.appendChild(editBox);
        } else if (div.divider) {
          let hr = document.createElement("hr");
          hr.style.border = "1px solid #e9e9e9";
          hr.style.height = "fit-content";
          parentElem.appendChild(hr);
        } else {
          let span = document.createElement("span");
          span.appendChild(document.createTextNode("\n"));

          div.styles.sort((a, b) => a.from - b.from);
          let fontSizeStyle = div.styles.find((s) => s.code === "fontSize");
          span.style.fontSize = fontSizeStyle ? fontSizeStyle.value : "11pt";
          parentElem.appendChild(span);
        }
      } else {
        let textBoxes = div.text.split("");
        let keys = textBoxes.map((c) => {
          return { char: c, styleIndeces: [] };
        });

        let stylesInOrder = [...div.styles.sort((a, b) => a.from - b.from)];

        // Custom Fields
        if (div.text.includes("{recipient")) {
          let ind = div.text.indexOf("{recipient");
          while (ind > -1) {
            // stylesInOrder.push({  // Make brackets always black?
            //   code: "color",
            //   value: defaultBlack,
            //   from: ind,
            //   to: ind,
            //   cf: true,
            // });

            stylesInOrder.push({
              code: "color",
              value: "#a3a4a8", // Gray 100
              from: ind + 1,
              to: ind + "recipient".length,
              cf: true,
            });

            if (div.text.includes("}", ind)) {
              let closeInd = div.text.indexOf("}", ind);
              let nextOpenInd = div.text.indexOf("{", ind + 1);
              if (nextOpenInd === -1 || closeInd < nextOpenInd) {
                // It closed the one that is open
                stylesInOrder.push({
                  code: "color",
                  value: "#7fcfd3", // Blue 60
                  from: ind + "recipient".length + 2, // account for " " space
                  to: closeInd - 1,
                  cf: true,
                });

                // stylesInOrder.push({
                //   code: "color",
                //   value: defaultBlack,
                //   from: closeInd,
                //   to: closeInd,
                //   cf: true,
                // });
              }
            }

            ind = div.text.indexOf("{recipient", ind + 1);
          }
        }

        if (div.links) {
          for (let link of div.links) {
            stylesInOrder.push({
              ...link,
              link: true,
            });
          }
        }

        // // Links / Buttons
        // if (div.links) {
        //   for (let link of div.links) {
        //     if (link.asButton) {
        //       stylesInOrder.push({
        //         code: "backgroundColor",
        //         value: link.color,
        //         from: link.from,
        //         to: link.to,
        //         button: true,
        //       });

        //       stylesInOrder.push({
        //         code: "color",
        //         value: link.fontColor,
        //         from: link.from,
        //         to: link.to,
        //         button: true,
        //       });
        //     } else {
        //       stylesInOrder.push({
        //         code: "color",
        //         value: link.color,
        //         from: link.from,
        //         to: link.to,
        //         link: true,
        //       });

        //       stylesInOrder.push({
        //         code: "cursor",
        //         value: "pointer",
        //         from: link.from,
        //         to: link.to,
        //         link: true,
        //       });

        //       if (link.underline) {
        //         stylesInOrder.push({
        //           code: "textDecoration",
        //           value: "underline",
        //           from: link.from,
        //           to: link.to,
        //         });
        //       }
        //     }
        //   }
        // }

        forEach(stylesInOrder, (style, ind) => {
          for (let i = style.from; i <= style.to; i++) {
            if (!keys[i]) {
              return null;
            }
            keys[i].styleIndeces.push(ind);
          }
        });

        let blocks = [];
        let current = "";
        for (let key of keys) {
          let stringedStyle = JSON.stringify(key.styleIndeces);
          if (stringedStyle !== current) {
            // start a new span
            blocks.push({ styleIndeces: key.styleIndeces, text: key.char });
          } else {
            // add it
            blocks[blocks.length - 1].text += key.char;
          }
          current = stringedStyle;
        }

        let spans = [];
        for (let span of blocks) {
          let realSpan = document.createElement("span");

          for (let ind of span.styleIndeces) {
            let style = stylesInOrder[ind];

            if (style.link) {
              continue;
            }

            if (style.cf || style.sig) {
              realSpan.style[style.code] = style.value;
            } else if (realSpan.style[style.code]) {
              realSpan.style[style.code] += " " + style.value;
            } else {
              realSpan.style[style.code] = style.value;
            }
          }

          if (span.styleIndeces.some((i) => stylesInOrder[i].link)) {
            let linkInd = span.styleIndeces.find((i) => stylesInOrder[i].link);
            let link = stylesInOrder[linkInd];

            realSpan.style.cursor = "pointer";
            if (link.asButton) {
              realSpan.style.fontWeight = "600";
              realSpan.style.padding = "3px 20px";
              realSpan.style.borderRadius = ".5rem";
              realSpan.style.backgroundColor = link.color;
              realSpan.style.color = link.fontColor;
            } else {
              realSpan.style.color = link.color;
              if (link.underline) {
                realSpan.style.textDecoration = "underline";
              }
            }
          }

          realSpan.appendChild(document.createTextNode(span.text));
          spans.push(realSpan);
        }

        // let spans = [];
        // for (let span of blocks) {
        //   let realSpan = document.createElement("span");

        //   if (span.styleIndeces.some((i) => stylesInOrder[i].button)) {
        //     realSpan = document.createElement("button");
        //     const buttonStyles = {
        //       display: "inline-flex",
        //       border: "none",
        //       outline: "none",
        //       fontWeight: "600",
        //       padding: "3px 20px",
        //       justifyContent: "center",
        //       alignItems: "center",
        //       cursor: "pointer",
        //       borderRadius: ".5rem",
        //     };
        //     for (let s in buttonStyles) {
        //       realSpan.style[s] = buttonStyles[s];
        //     }

        //     // let button = document.createElement("button");
        //     // button.className = styles.embeddedButton;
        //     // button.appendChild(document.createTextNode(span.text));
        //     // realSpan.appendChild(button);
        //   }
        //   realSpan.appendChild(document.createTextNode(span.text));

        //   // if (!editable && linkable && isPotentialLink(span.text)) {
        //   //   let a = document.createElement("a");
        //   //   for (let ind of span.styleIndeces) {
        //   //     let style = stylesInOrder[ind];
        //   //     if (a.style[style.code]) {
        //   //       a.style[style.code] += " " + style.value;
        //   //     } else {
        //   //       a.style[style.code] = style.value;
        //   //     }
        //   //   }

        //   //   a.href = span.text;
        //   //   a.appendChild(document.createTextNode(span.text));
        //   //   realSpan.appendChild(a);
        //   // }

        //   for (let ind of span.styleIndeces) {
        //     let style = stylesInOrder[ind];
        //     if (
        //       style.bp &&
        //       span.styleIndeces.some(
        //         (i) =>
        //           stylesInOrder[i].code === style.code && !stylesInOrder[i].bp
        //       )
        //     ) {
        //       // has another color style - switch to a dashed underline
        //       let there = realSpan.style.textDecoration;
        //       let line = there.includes("line-through") ? "line-through" : "";
        //       let kind = there.includes("underline") ? "solid" : "dotted";
        //       let shouldBe =
        //         line + " underline " + kind + " " + style.value + " ";
        //       realSpan.style.textDecoration = shouldBe;
        //     } else if (style.cf || style.link) {
        //       realSpan.style[style.code] = style.value;
        //     } else if (realSpan.style[style.code]) {
        //       realSpan.style[style.code] += " " + style.value;
        //     } else {
        //       realSpan.style[style.code] = style.value;
        //     }
        //   }
        //   spans.push(realSpan);
        // }

        for (let span of spans) {
          parentElem.appendChild(span);
        }
      }

      if (div.textAlign) {
        parentElem.style.textAlign = div.textAlign;
        if (div.image) {
          parentElem.style.display = "flex";
          let just =
            div.textAlign === "left"
              ? "flex-start"
              : div.textAlign === "center"
              ? "center"
              : "flex-end";
          parentElem.style.justifyContent = just;
        }
      }

      if (div.indent) {
        parentElem.style.marginLeft = div.indent * 20 + "px";
      }

      if (div.list) {
        if (!k || data[k - 1].list !== div.list) {
          let list = document.createElement(div.list);
          list.appendChild(parentElem);
          currentList = list;
          entry.appendChild(list);
        } else {
          currentList.appendChild(parentElem);
        }
      } else {
        entry.appendChild(parentElem);
        currentList = null;
      }
    }

    return entry;
  } catch (err) {
    debugger;
    return null;
  }
}
