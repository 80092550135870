import React, { useState, useEffect, useRef } from "react";
import styles from "./UserProfile2.module.scss";
import {
  useFetchUser,
  useRemoveUserFromOrg,
  useUpdateUserById,
} from "api/resources/organization/users";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import Button from "components/Button/Button";
import { Label } from "components/layouts/Label/Label";
import { Loading } from "components/Loading/Loading";

import ImageEditor from "components/Image Editor/ImageEditor";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import {
  useChangeUserRole,
  useFetchRolesGql,
} from "api/resources/organization/roles";
import DoYouWantToSave from "./DoYouWantToSave";
import { ChangePassword } from "pages/account/accountDetails/AccountDetails";
import { AddField } from "components/CustomFieldsEditor/CustomFieldsEditor";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import EditSingleCustomField from "components/CustomFieldsEditor/EditSingle";
import salesforce from "assets/images/Salesforce.png";
import LinkNewAccount from "pages/account/LinkNewAccount/LinkNewAccount";
import { deleteLinkedAccount } from "api/resources/contacts/contacts";
import UserActivity from "./UserActivity";
import { SigBuilder } from "pages/organization/SigBuilder/SigBuilder";
import { TabBar } from "components/layouts";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { useFetchUserSigs } from "api/resources/projects/distributions";
import { SelectField } from "components/inputs";

const menuItems = [
  {
    id: 0,
    name: "User Details",
    // to: `/organization/${id}/org-details`, d
    icon: "bi bi-card-text",
  },
  {
    id: 1,
    name: "Email Preferences",
    // to: `/organization/${id}/email-preferences`,
    icon: "bi bi-envelope",
  },
  // {
  //   id: 2,
  //   name: "Dashboards",
  //   // to: `/organization/${id}/dashboards`,
  //   icon: "bi bi-speedometer2",
  // },
  {
    id: 3,
    name: "Activity",
    // to: `/organization/${id}/nlp`,
    icon: "bi bi-lightbulb",
  },
  // {
  //   id: 4,
  //   name: "Permissions",
  //   // to: `/organization/${id}/nlp`,
  //   icon: "bi bi-person-lock",
  // },
];

function UserProfile2({
  userId,
  show,
  setShow,
  popout,
  organizationId,
  roles,
  canEdit,
  account,
}) {
  const getUser = useFetchUser(userId);
  const [editImage, setEditImage] = useState(false);
  const [currRole, setCurrRole] = useState(getRoles());
  const [editPswd, setEditPswd] = useState(false);
  const [linkAccount, setLinkAccount] = useState(false);
  const [changedRole, setChangedRole] = useState(false);
  const [saveWords, setSaveWords] = useState("Save Changes");
  const [activeSig, setActiveSig] = useState();
  const [checkRemove, setCheckRemove] = useState(false);
  const [accountMenu, setAccountMenu] = useState();
  const [active, setActive] = useState(0);

  const [add, setAdd] = useState(false);
  const orgRoles = useFetchRolesGql(organizationId);
  const changeRole = useChangeUserRole();
  const removeUser = useRemoveUserFromOrg();
  const orgFields = useFetchCurrOrgsFields();

  function getOptions(options) {
    let response = [];
    for (let option of options) {
      if (currRole?.id != option?.id) {
        response.push({ value: option?.id, label: option?.name });
      }
    }
    return response;
  }

  function handleChangeRole() {
    setSaveWords(
      <>
        Saving <Loading width={20} height={20}></Loading>
      </>
    );
    changeRole.mutate(
      {
        orgId: organizationId,
        userId: userId,
        roleId: currRole?.value,
      },
      {
        onSuccess: (data) => {
          setChangedRole(false);
          setSaveWords("Save Changes");
        },
      }
    );
  }

  function getRoles() {
    let role = roles.find((role) => role?.organizationId === organizationId);
    return role
      ? { value: role?.id, label: role?.name }
      : { value: "", label: "" };
  }

  function handleRemoveUser(userId) {
    removeUser.mutate(
      {
        orgId: organizationId,
        userId: userId,
      },
      {
        onSuccess: () => {
          setShow(false);
        },
      }
    );
  }

  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      setAccountMenu(undefined);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef(null);

  const deleteLink = deleteLinkedAccount();

  function removeLinkedAccount(id) {
    deleteLink.mutate(
      {
        id: id,
      },
      {
        onSuccess: () => {
          getUser.refetch();
        },
      }
    );
  }

  function childrenElements() {
    return (
      <>
        {editPswd && getUser.isSuccess && (
          <ReactModal
            show={editPswd}
            onClose={() => setEditPswd(false)}
            modalStyle={{ borderRadius: "1em" }}
            dark
            // rightStyle
          >
            <ChangePassword
              currUser={getUser.data?.user}
              close={() => setEditPswd(false)}
            />
          </ReactModal>
        )}
        {linkAccount && getUser.isSuccess && (
          <ReactModal
            show={linkAccount}
            onClose={() => setLinkAccount(false)}
            modalStyle={{ borderRadius: "1em" }}
            dark
          >
            <LinkNewAccount
              currUser={getUser.data?.user}
              close={() => setLinkAccount(false)}
            />
          </ReactModal>
        )}
        {checkRemove && getUser.isSuccess && (
          <DoYouWantToSave
            cancelText={"No, do not remove"}
            confirmText={"Yes"}
            prompt={
              "Are you sure you want to remove this user from your organization?"
            }
            navAway={() => setCheckRemove(false)}
            onSave={() => handleRemoveUser(getUser?.data?.user?.id)}
          ></DoYouWantToSave>
        )}
        {getUser.isLoading && <Loading></Loading>}
        {getUser.isSuccess && (
          <>
            <div className={styles.top}>
              <div className={styles.section}>
                <ImageEditor
                  src={getUser.data?.user?.image?.imageURL}
                  canEdit={false}
                  isUser={true}
                  userId={getUser.data?.user?.id}
                  position={
                    getUser.data?.user?.image?.position &&
                    typeof getUser.data?.user?.image?.position === "string"
                      ? JSON.parse(getUser.data?.user?.image?.position)
                      : getUser.data?.user?.image?.position
                  }
                  image={getUser.data?.user?.image}
                  refetch={getUser.refetch}
                  height={50}
                  width={50}
                ></ImageEditor>

                <div className={styles.name}>
                  {getUser.data?.user?.firstName} {getUser.data?.user?.lastName}
                </div>
              </div>
            </div>
            {canEdit && !account && (
              <div
                className={styles.section}
                style={{ position: "absolute", bottom: "1rem", right: "1rem" }}
              >
                <Button red shadow onClick={() => setCheckRemove(true)}>
                  Remove User
                </Button>
              </div>
            )}
            <div className={styles.page}>
              <div className={styles.menu}>
                <TabBar
                  tabBarItems={menuItems}
                  active={active}
                  updateActive={setActive}
                />
              </div>
              {active === 0 && (
                <FlexRow
                  style={{ justifyContent: "center", alignItems: "flex-start" }}
                >
                  <div className={styles.pageSection}>
                    <div className={styles.editImage}>
                      <Label
                        style={{
                          fontWeight: "700",
                          padding: "0em",
                          margin: "0em",
                          letterSpacing: "0",
                        }}
                      >
                        Profile Image
                      </Label>

                      <ImageEditor
                        src={getUser.data?.user?.image?.imageURL}
                        canEdit={canEdit}
                        isUser={true}
                        userId={getUser.data?.user?.id}
                        position={
                          getUser.data?.user?.image?.position &&
                          typeof getUser.data?.user?.image?.position ===
                            "string"
                            ? JSON.parse(getUser.data?.user?.image?.position)
                            : getUser.data?.user?.image?.position
                        }
                        image={getUser.data?.user?.image}
                        refetch={getUser.refetch}
                        setShow={setEditImage}
                        show={editImage}
                        height={200}
                        width={200}
                      ></ImageEditor>

                      <div className={styles.link}>
                        {" "}
                        <i className="bi-image"></i>{" "}
                        <a onClick={() => setEditImage(true)}>
                          Edit Profile Image
                        </a>
                      </div>
                    </div>
                    <div className={styles.editImage}>
                      <Label
                        style={{
                          fontWeight: "700",
                          padding: "0em",
                          margin: "0em",
                          letterSpacing: "0",
                        }}
                      >
                        User Role
                      </Label>

                      {/* { TODO: Make Select Field} */}

                      <CombinedInput
                        label="Role"
                        placeholder={"Role"}
                        value={currRole}
                        icon={"bi-chevron-down"}
                        select
                        options={
                          orgRoles.isSuccess
                            ? getOptions(orgRoles.data.rolesByOrgId)
                            : []
                        }
                        disable={account}
                        optionHelperText="Change"
                        onChange={(val) => {
                          setCurrRole(val);
                          setChangedRole(true);
                        }}
                        style={{
                          backgroundColor: "white",
                          boxShadow:
                            "rgba(0, 0, 0, 0.12) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                          padding: ".5rem",
                        }}
                        shadow
                      ></CombinedInput>

                      {changedRole && (
                        <div style={{ display: "flex", gap: ".5em" }}>
                          <Button
                            shadow
                            seafoam
                            onClick={() => {
                              setCurrRole(getRoles());
                              setChangedRole(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button shadow onClick={() => handleChangeRole()}>
                            {saveWords}
                          </Button>
                        </div>
                      )}
                    </div>

                    <div className={styles.editImage} ref={ref}>
                      <Label
                        style={{
                          fontWeight: "700",
                          padding: "0em",
                          margin: "0em",
                          letterSpacing: "0",
                        }}
                      >
                        Connected Accounts
                      </Label>
                      {getUser.data?.user.linkedAccounts.map((account) => (
                        <div key={account?.id} className={styles.account}>
                          {account.accountType === "Salesforce" && (
                            <CombinedInput
                              select
                              icon={"bi-three-dots"}
                              label={"Account"}
                              value={{
                                value: "salesforece",
                                label: (
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: ".5em",
                                    }}
                                  >
                                    <img src={salesforce} height={30}></img>
                                    Salesforce
                                  </span>
                                ),
                              }}
                              onIconClick={() => setAccountMenu(account?.id)}
                              style={{ backgroundColor: "white" }}
                              shadow
                            />
                          )}
                          {accountMenu === account?.id && (
                            <div className={styles.settingsBox}>
                              <div
                                className={styles.menuItem}
                                onClick={() => removeLinkedAccount(account?.id)}
                              >
                                <i className="bi-file-minus"> </i>Unlink Account
                              </div>
                            </div>
                          )}
                        </div>
                      ))}

                      <div className={styles.link}>
                        <i className="bi-plus"></i>
                        <a onClick={() => setLinkAccount(true)}>
                          Link New Account
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={styles.pageSection}>
                    <div className={styles.editImage}>
                      <Label
                        style={{
                          fontWeight: "700",
                          padding: "0em",
                          margin: "0em",
                          letterSpacing: "0",
                        }}
                      >
                        User Details
                      </Label>

                      {orgFields.isSuccess && (
                        <UserFields
                          user={getUser.data?.user}
                          account={account}
                          refetch={() => {
                            getUser.refetch();
                            orgFields.refetch();
                          }}
                          setEditPswd={setEditPswd}
                          setAdd={setAdd}
                          add={add}
                          orgFields={orgFields.data?.getCurrOrgFields}
                        ></UserFields>
                      )}
                    </div>
                  </div>
                </FlexRow>
              )}

              {active === 1 && (
                <div
                  className={styles.pageSection}
                  style={{ width: "100%", maxWidth: "664px" }}
                >
                  <div
                    className={styles.editImage}
                    style={{ width: "100%", maxWidth: "664px" }}
                  >
                    <Label
                      style={{
                        fontWeight: "700",
                        padding: "0em",
                        margin: "0em",
                        letterSpacing: "0",
                      }}
                    >
                      User Email Signatures
                    </Label>
                    <UserSigs
                      user={getUser.data?.user}
                      setActiveSig={setActiveSig}
                    ></UserSigs>
                  </div>
                </div>
              )}
              {active === 3 && (
                <UserActivity
                  user={getUser.data?.user}
                  orgId={organizationId}
                ></UserActivity>
              )}
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <>
      {popout && (
        <ReactModal
          show={show}
          onClose={() => setShow(false)}
          modalStyle={{
            borderRadius: "1em",
            height: "fit-content",
            width: "95%",
            height: "100%",
            maxWidth: "1200px",
            backgroundColor: "#f9fbfc",
          }}
          dark
        >
          {childrenElements()}
        </ReactModal>
      )}
      {activeSig && (
        <SigBuilder
          orig={activeSig}
          onClose={() => setActiveSig(null)}
          refetch={getUser.refetch}
          userId={userId}
        ></SigBuilder>
      )}
      {!popout && childrenElements()}
    </>
  );
}

export default UserProfile2;

function UserFields({
  user,
  orgFields,
  account,
  refetch,
  setEditPswd,
  setAdd,
  add,
}) {
  const updateUser = useUpdateUserById();
  const [first, setFirst] = useState(user?.firstName);
  const [last, setLast] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email);
  const [createNew, setCreateNew] = useState(false);
  const [saveWords, setSaveWords] = useState("Save Changes");

  const [change, setChange] = useState(false);

  const initFields = () => {
    let columns = [];

    if (user.attribute) {
      for (let field of orgFields) {
        let attributes = user.attribute
          .filter((a) => a.customFieldId === field.id)
          .map((a) => a.name);
        if (attributes.length) {
          columns.push({
            field: field,
            attributes: attributes,
          });
        }
      }
    }
    return columns;
  };

  const [columns, setColumns] = useState(initFields());

  function saveUserFields() {
    setSaveWords(
      <>
        Saving <Loading width={20} height={20}></Loading>
      </>
    );
    let attributeData = [];
    for (let col of columns) {
      for (let att of col.attributes) {
        attributeData.push({
          customFieldId: col.field.id,
          name: att,
        });
      }
    }

    updateUser.mutate(
      {
        data: {
          firstName: first,
          lastName: last,
          email: email,
        },
        id: user.id,
        attributeData: {
          attributes: attributeData,
        },
      },
      {
        onSuccess: (data) => {
          setChange(false);
          refetch();
          setSaveWords("Save Changes");
        },
      }
    );
  }

  function changeField(i, options) {
    let copy = [...columns];
    copy[i].attributes = options;
    setColumns(copy);
  }

  function addField(field) {
    let copy = [...columns];
    copy.push({
      field: field,
      attributes: [],
    });
    setColumns(copy);
    setAdd(false);
    setChange(true);
  }

  function handleNewField(field) {
    addField(field);
    setCreateNew(false);
    if (refetch) {
      refetch();
    }
  }

  return (
    <>
      {add && (
        <AddField
          onClickOut={() => setAdd(false)}
          onAdd={addField}
          options={orgFields.filter(
            (f) => !columns.some((c) => c.field.id === f.id)
          )}
          onCreateNew={() => {
            setCreateNew(true);
            // setChange(true);
            setAdd(false);
          }}
        />
      )}
      {createNew && (
        <EditSingleCustomField
          brandNew
          onClose={() => setCreateNew(false)}
          onSaved={handleNewField}
        />
      )}
      <CombinedInput
        label="First Name"
        placeholder={"First Name"}
        value={first}
        onChange={(val) => {
          setFirst(val);
          setChange(true);
        }}
        // style={{ backgroundColor: "white",  }}
        shadow
      ></CombinedInput>
      <CombinedInput
        label="Last Name"
        placeholder={"Last Name"}
        value={last}
        onChange={(val) => {
          setLast(val);
          setChange(true);
        }}
        // style={{ backgroundColor: "white" }}
        shadow
      ></CombinedInput>
      <CombinedInput
        label="Email"
        placeholder={"Email"}
        value={email}
        onChange={(val) => {
          setEmail(val);
          setChange(true);
        }}
        // style={{ backgroundColor: "white" }}
        shadow
      ></CombinedInput>
      {account && (
        <CombinedInput
          label="Change Password"
          placeholder={"Password"}
          value={"*************"}
          icon="bi-pencil-square"
          onIconClick={() => setEditPswd(true)}
          disableInputOnly
          style={{
            backgroundColor: "white",
            boxShadow:
              "rgba(0, 0, 0, 0.12) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
            padding: ".5rem",
          }}
          shadow
          anyWhereOptions
        ></CombinedInput>
      )}
      {/* {Object.keys(userFields).map((field) => (
        <CombinedInput
          label={field}
          placeholder={field}
          value={userFields[field]}
          onChange={(val) => {
            changeField(field, val);
            setChange(true);
          }}
        ></CombinedInput>

      ))} */}
      {columns.map((column, i) => (
        // <SelectField
        //   selectMultiple
        //   options={column.field.attribute.map((a) => {
        //     return {
        //       label: a.name,
        //       value: a.id,
        //     };
        //   })}
        //   label={column.field.name}
        //   value={column.}
        // />

        <div className={styles.editImage} key={i}>
          <CombinedInput
            label={column.field.name}
            value={column.attributes}
            onChange={(val) => {
              changeField(i, val);
              setChange(true);
            }}
            options={column.field.properties}
            selectMultiple
            search
            style={{
              backgroundColor: "white",
              boxShadow:
                "rgba(0, 0, 0, 0.12) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
            }}
            shadow
          ></CombinedInput>
        </div>
      ))}
      {change && (
        <div style={{ display: "flex", gap: ".5em" }}>
          <Button
            shadow
            seafoam
            onClick={() => {
              setColumns(initFields());
              setFirst(user?.firstName);
              setLast(user?.lastName);
              setEmail(user?.email);
              setChange(false);
            }}
          >
            Cancel
          </Button>
          <Button shadow onClick={() => saveUserFields()}>
            {saveWords}
          </Button>
        </div>
      )}
      <div className={styles.link}>
        <i className="bi-plus"></i>
        <a onClick={() => setAdd(true)}>Add Field</a>
      </div>
    </>
  );
}

function UserSigs({ user, setActiveSig }) {
  const fetchSigs = useFetchUserSigs(user.id);

  return (
    <>
      {fetchSigs.isLoading && <Loading />}
      {fetchSigs.isSuccess && (
        <>
          {fetchSigs.data.sigs.map((sig, i) => (
            <CombinedInput
              key={i}
              label={`${sig?.aliasName}${
                user.activeDistConfigId === sig.id ? "*" : ""
              }`}
              placeholder={"signature"}
              value={sig?.fromAddress}
              icon={"bi-pencil-square"}
              disableInputOnly
              onIconClick={() => setActiveSig(sig)}
              style={{
                backgroundColor: "white",
                boxShadow:
                  "rgba(0, 0, 0, 0.12) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                padding: ".5rem",
              }}
              shadow
            ></CombinedInput>
          ))}
          <Button
            className={styles.link}
            onClick={() => {
              setActiveSig({ id: "" });
            }}
            shadow
          >
            <i className="bi-plus"></i>
            Add Signature
          </Button>
        </>
      )}
    </>
  );
}
{
  /* <div className={styles.settingSection}>
  <div className={styles.headerHolder2}>
    <div style={{ maxWidth: "450px" }}>
      <div className={styles.header2}>Email Signatures</div>
      <div className={styles.description2}>
        This users email signatures. A user can have an infinite amount of
        signatures, but can only have one active at a time.
      </div>
    </div>
    <Button
      shadow
      onClick={() => {
        setActiveSig({
          signature: "",
          aliasName: "",
          fromAddress: "",
          type: "signature",
        });
        setShowSig(true);
      }}
    >
      Create Signature
    </Button>
  </div>

  <UserSignatures
    userId={user.id}
    show={showSig}
    setShow={setShowSig}
    sigs={user.distConfig}
    active={activeSig}
    setActive={setActiveSig}
    activeDistConfigId={user.activeDistConfigId}
  ></UserSignatures>
</div>; */
}
