import styles from "./Content.module.scss";

export default function Content({ content, setContent }) {
  const draggables = [
    {
      label: "Fields",
      icon: (
        <i
          className={`bi bi-person-bounding-box ${styles.contentIcon} ${styles.fieldIcon}`}
        ></i>
      ),
    },
    {
      label: "Link",
      icon: (
        <i
          className={`bi bi-link-45deg ${styles.contentIcon} ${styles.linkIcon}`}
        ></i>
      ),
    },
    { label: "Button", icon: <ButtonIcon />, createIcon: createButton },
    {
      label: "Image",
      icon: (
        <i
          className={`bi bi-image-alt ${styles.contentIcon} ${styles.imageIcon}`}
        ></i>
      ),
    },
    {
      label: "Divider",
      icon: <i className={`bi bi-view-list ${styles.contentIcon}`}></i>,
    },
    {
      label: "Signature",
      icon: (
        <i className={`bi bi-pen ${styles.contentIcon} ${styles.sigIcon}`}></i>
      ),
    },
    {
      label: "Unsub Link",
      icon: <div className={styles.unsub}>Unsubscribe</div>,
      createIcon: createUnsub,
    },
  ];

  function createButton() {
    let div = document.createElement("div");
    div.className = styles.buttonIcon;

    let button = document.createElement("div");
    button.className = styles.mockButton;

    let hand = document.createElement("i");
    hand.className = `bi bi-hand-index-fill ${styles.cursorIcon}`;

    div.appendChild(button);
    div.appendChild(hand);

    return div;
  }

  function createUnsub() {
    let div = document.createElement("div");
    div.className = styles.unsub;
    div.appendChild(document.createTextNode("Unsubscribe"));

    return div;
  }

  function onDragStart(e, opt) {
    let div = document.getElementById("dragger");
    let icon;
    if (opt.createIcon) {
      icon = opt.createIcon();
    } else {
      icon = document.createElement("i");
      icon.className = opt.icon.props.className;
    }
    div.firstChild.appendChild(icon);

    div.style.visibility = "visible";
    div.style.opacity = 0.2;

    e.dataTransfer.setDragImage(div, 40, 100);

    setTimeout(() => {
      div.style.visibility = "hidden";
      div.firstChild.removeChild(icon);
      div.style.opacity = 1;
    }, 1);

    setContent(opt.label);
  }

  function onDragEnd(e) {
    setContent("");
  }

  return (
    <div className={styles.shell}>
      <div className={styles.content}>
        {draggables.map((opt) => (
          <div
            className={`${styles.draggable} ${
              content === opt.label ? styles.selected : ""
            }`}
            draggable="true"
            onDragStart={(e) => onDragStart(e, opt)}
            onDragEnd={onDragEnd}
          >
            <div className={styles.dragGrip}>
              <i className="bi bi-grip-horizontal"></i>
            </div>
            {opt.icon}
            <div className={styles.draggableLabel}>{opt.label}</div>
          </div>
        ))}
      </div>
      <div className={styles.dragger} id="dragger">
        <div className={styles.visible}></div>
      </div>
    </div>
  );
}

export function ButtonIcon({}) {
  return (
    <div className={styles.buttonIcon}>
      <div className={styles.mockButton}></div>
      <i className={`bi bi-hand-index-fill ${styles.cursorIcon} `}></i>{" "}
    </div>
  );
}
