import styles from "../SettingsAccordion.module.scss";
import {
  TableType,
  LineType,
  NoSort,
  HorizBarType,
  VertBarType,
  DoughnutType,
  PieType,
} from "../../QuestionChart";
import { SegmentSettings } from "./Colors/SegmentSettings";
import { combinedQs } from "../../Visualization";
import { splitting } from "../SettingsAccordion";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { NumSliderTool, Switch, Color } from "../Tools";

export const ColorSettings = ({
  viz,
  changeSettingsField,
  labels,
  updateViz,
  visible,
  setVisible,
}) => {
  function updateSettings(newSettings) {
    let newViz = { ...viz };
    newViz.designSettings = newSettings;
    updateViz(newViz);
  }

  function handleBorderRadiusChange(val) {
    changeSettingsField("borderRadius", val);
  }

  function handleBarWidthChange(val) {
    val = val / 10;
    console.log(val);
    changeSettingsField("barPercentage", val);
  }

  const QChart = !viz.dataMethod || viz.dataMethod === "question";

  const combos = [
    {
      type: TableType,
      title: "Table Color",
      icon: "paint-bucket",
    },
    {
      type: LineType,
      title: "Colors",
      icon: "paint-bucket",
    },
    {
      type: VertBarType,
      title: "Bars",
      icon: "bar-chart",
    },
    {
      type: HorizBarType,
      title: "Bars",
      icon: "bar-chart",
    },
    {
      type: DoughnutType,
      title: "Doughnut",
      icon: "paint-bucket",
    },
    {
      type: PieType,
      title: "Pie Chart",
      icon: "pie-chart",
    },
  ];

  const fallBack = {
    title: "Colors",
    icon: "bar-chart",
  };

  const combo = combos.some((c) => c.type === viz.type)
    ? combos.find((c) => c.type === viz.type)
    : fallBack;

  return (
    <>
      <div
        key={viz.id}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        <FlexRow
          onClick={visible ? setVisible : undefined}
          style={{ cursor: "pointer" }}
          className={styles.accordionTitle}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {combo.title}
            <i style={{ fontSize: "1.2em" }} className={`bi-${combo.icon}`}></i>
          </div>
          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>

        {visible && (
          <div className={styles.body} style={{ gap: "10px" }}>
            {viz.type === LineType && !splitting(viz) && (
              <div
                className={styles.body}
                style={{ gap: "10px", paddingTop: "0px", paddingLeft: "10px" }}
              >
                <Color
                  color={viz.designSettings.lineGraphColor}
                  onChange={(val) => changeSettingsField("lineGraphColor", val)}
                >
                  Line Color
                </Color>

                {viz.designSettings.hasUniformPointColor && (
                  <Color
                    color={viz.designSettings.uniformPointColor}
                    onChange={(val) =>
                      changeSettingsField("uniformPointColor", val)
                    }
                  >
                    Point Color
                  </Color>
                )}
                <Switch
                  checked={viz.designSettings.hasUniformPointColor}
                  onCheck={(val) =>
                    changeSettingsField("hasUniformPointColor", val)
                  }
                >
                  Points All Same Color
                </Switch>
              </div>
            )}

            {((QChart && combinedQs(viz).length > 0) || !QChart) &&
              !(
                viz.type === LineType &&
                viz.designSettings.hasUniformPointColor &&
                !splitting(viz)
              ) && (
                <>
                  {viz.type != TableType && (
                    <SegmentSettings
                      viz={viz}
                      changeSettingsField={changeSettingsField}
                      updateSettings={updateSettings}
                      labels={labels}
                    />
                  )}
                </>
              )}
            {viz.designSettings.colors &&
              viz.designSettings.sortData !== NoSort &&
              !splitting(viz) &&
              !(
                viz.type === LineType && viz.designSettings.hasUniformPointColor
              ) && (
                <div
                  className={styles.row}
                  style={{
                    paddingTop: "5px",
                  }}
                >
                  <Switch
                    checked={viz.designSettings.afterSort}
                    onCheck={(val) => changeSettingsField("afterSort", val)}
                  >
                    Apply colors after sorting
                  </Switch>
                </div>
              )}
            {viz.type === TableType && (
              <Color
                color={viz.designSettings.tableColor}
                onChange={(val) => changeSettingsField("tableColor", val)}
                style={{
                  justifyContent: "center",
                  padding: "15px 0px 10px",
                }}
              >
                Table Color
              </Color>
            )}

            {(viz.type === HorizBarType || viz.type === VertBarType) && (
              <NumSliderTool
                val={
                  viz.designSettings.barPercentage
                    ? viz.designSettings.barPercentage * 10
                    : 9
                }
                min={1}
                max={15}
                step={1}
                onChange={handleBarWidthChange}
              >
                Bar Width
              </NumSliderTool>
            )}

            {viz.type != LineType && viz.type != TableType && (
              <>
                <NumSliderTool
                  val={
                    viz.designSettings.borderRadius
                      ? viz.designSettings.borderRadius
                      : 0
                  }
                  min={0}
                  max={20}
                  step={1}
                  onChange={handleBorderRadiusChange}
                >
                  Bar Border Radius
                </NumSliderTool>

                <NumSliderTool
                  val={viz.designSettings.segmentBorder}
                  onChange={(val) => changeSettingsField("segmentBorder", val)}
                  min={0}
                  max={40}
                  step={1}
                >
                  Segment Border
                </NumSliderTool>

                {viz.designSettings.segmentBorder > 0 && (
                  <Color
                    color={
                      viz?.designSettings?.segmentBorderColor
                        ? viz?.designSettings?.segmentBorderColor
                        : "#000000"
                    }
                    onChange={(val) =>
                      changeSettingsField("segmentBorderColor", val)
                    }
                  >
                    Segment Border Color
                  </Color>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
