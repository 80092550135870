import React, { useState } from "react";
import styles from "./CRM.module.scss";
import {
  useFetchOrganizationLinkedAccounts,
  useUpdateOrgSettings,
  useVerifyClient,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { useParams } from "react-router-dom";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import axios from "axios";
import Icon from "components/Icon/Icon";
import { deleteLinkedAccount } from "api/resources/contacts/contacts";
import Modal from "components/ReactModal/ReactModal";
import { TextFieldSimple } from "components/inputs";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import ConnectSalesProcess from "./ConnectSalesProcess";

function CRM({ roles, user }) {
  const { id } = useParams();
  const MyOrg = useFetchOrganizationLinkedAccounts(id);
  return (
    <div className={styles.page}>
      {MyOrg.isLoading && <Loading></Loading>}
      {MyOrg.isSuccess && (
        <CRMContent
          organization={MyOrg.data.organization}
          role={roles}
          user={user}
          refetch={MyOrg.refetch}
        />
      )}
    </div>
  );
}

export default CRM;

const providers = [
  {
    value: "Salesforce",
    label: (
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          width: "150px",
        }}
      >
        <img
          src={require("assets/images/Salesforce.png")}
          style={{ width: "50px" }}
        ></img>
        Salesforce
      </div>
    ),
  },
  {
    value: "HubSpot",
    label: (
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          width: "150px",
        }}
      >
        <img
          src={require("assets/images/hubspot.webp")}
          style={{ width: "50px" }}
        ></img>
        HubSpot
      </div>
    ),
  },
  {
    value: "Pipedrive",
    label: (
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          width: "150px",
        }}
      >
        <img
          src={require("assets/images/pipedrive.png")}
          style={{ width: "50px" }}
        ></img>
        Pipedrive
      </div>
    ),
  },
];

function CRMContent({ organization, role, user, refetch }) {
  const [show, setShow] = useState();

  const deleteLink = deleteLinkedAccount();

  function removeLinkedAccount(id) {
    deleteLink.mutate(
      {
        id: id,
        orgId: organization.id
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  }

  return (
    <>
      {show === "Salesforce" && (
        <ConnectSalesProcess
          organization={organization}
          setShow={setShow}
          show={show}
          user={user}
        ></ConnectSalesProcess>
      )}
      {!show && (
        <div className={styles.linkpage}>
          <div className={styles.linkpagecontent}>
            <FlexRow
              style={{ flexDirection: "column", alignItems: "flex-start" }}
              start
              gap={"2rem"}
            >
              <div className={styles.header_3} style={{ margin: "0" }}>
                {" "}
                Connect Account
              </div>
              <div className={styles.section_label5} style={{ margin: "0" }}>
                {" "}
                Choose an account to connect to.
              </div>
              <div className={styles.accounts}>
                {providers.map((p) => (
                  <>
                    <div className={styles.provider}>
                      {p.label}{" "}
                      {!organization?.linkedAccounts?.find(
                        (l) => l.accountType === p.value
                      ) ? (
                        <>
                          {p.value === "Salesforce" ? (
                            <Button
                              white
                              shadow
                              onClick={() => setShow(p.value)}
                            >
                              <i className="bi-plug"></i> Connect
                            </Button>
                          ) : (
                            <div></div>
                          )}
                        </>
                      ) : (
                        <FlexRow>
                          <Icon iconName={"check"} green></Icon>
                          <div
                            className={styles.section_label5}
                            style={{ margin: "0" }}
                          >
                            Connected
                          </div>
                          <Button
                            red
                            shadow
                            onClick={() =>
                              removeLinkedAccount(
                                organization?.linkedAccounts?.find(
                                  (l) => l.accountType === p.value
                                )?.id
                              )
                            }
                            style={{gap: ".5rem"}}
                          >
                           <i className="bi-outlet"></i> Disconnect
                          </Button>
                        </FlexRow>
                      )}
                    </div>
                  </>
                ))}{" "}
              </div>
            </FlexRow>
          </div>
        </div>
      )}
    </>
  );
}
