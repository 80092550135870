import { useEffect, useState } from "react";
import styles from "./ErrorPage.module.scss";
import { Player } from "@lottiefiles/react-lottie-player";
import { TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { TextArea } from "components/inputs/input_fields/TextArea/TextArea";
import { Loading } from "components/Loading/Loading";
import {
  useSubmitErrorTicket,
  useUpdateErrorTicket,
} from "api/resources/account/errors";
import { forEach } from "assets/functions/ArrayFunctions";

export const ErrorPage = ({ error, backup, autoSend, timeout }) => {
  const [submitted, setSubmitted] = useState(false);
  const [sending, setSending] = useState(false);
  const [troubleSubmitting, setTroubleSubmitting] = useState(false);

  const [ticketId, setTicketId] = useState("");

  const [form, setForm] = useState({
    device: "",
    deviceSpecs: "",
    os: "",
    browser: "",
    location: "",
    description: "",
    other: "",
  });

  const submitErrorTicket = useSubmitErrorTicket();
  const updateTicket = useUpdateErrorTicket();

  function sendTicket() {
    let ticket = { ...form };

    ticket.height = window.innerHeight;
    ticket.width = window.innerWidth;
    ticket.url = window.location.href;

    let displayInfo = {};
    displayInfo.availHeight = window.screen.availHeight;
    displayInfo.availWidth = window.screen.availWidth;
    displayInfo.pixelDepth = window.screen.pixelDepth;

    let browserInfo = {};
    browserInfo.uiPath = window.location.pathname;
    browserInfo.port = window.location.port;
    browserInfo.cookieEnabled = window.navigator.cookieEnabled;
    browserInfo.language = window.navigator.language;
    browserInfo.online = window.navigator.onLine;

    ticket.browserInfo = JSON.stringify(browserInfo);
    ticket.displayInfo = JSON.stringify(displayInfo);

    if (error) {
      ticket.message = "";

      if (
        error.response &&
        error.response.errors &&
        error.response.errors.length
      ) {
        forEach(error?.response?.errors, (e) => {
          ticket.message += ` error: ${e.message}: at ${e.path.map(
            (p) => p + " "
          )}`;
        });
      }

      if (error.request && error.request && error.request.query) {
        ticket.query = error?.request?.query;
        if (error.request.variables) {
          ticket.requestVariables = JSON.stringify(error?.request?.variables);
        }
      }
    } else if (backup) {
      ticket.message = backup;
    }

    if (autoSend) {
      if (!ticketId) {
        // auto sending
        submitErrorTicket.mutate(
          { data: ticket },
          {
            onSuccess: (data) => {
              setTicketId(data.ticket.id);
            },
            onError: (data) => {
              setTroubleSubmitting(true);
            },
          }
        );
      } else {
        // You're finished and submiting
        updateTicket.mutate(
          { data: ticket, ticketId: ticketId },
          {
            onSuccess: (data) => {
              setSubmitted(true);
            },
            onError: (data) => {
              setTroubleSubmitting(true);
            },
          }
        );
        setSending(true);
      }
    } else {
      submitErrorTicket.mutate(
        { data: ticket },
        {
          onSuccess: (data) => {
            setSubmitted(true);
          },
          onError: (data) => {
            setTroubleSubmitting(true);
          },
        }
      );
      setSending(true);
    }
  }

  useEffect(() => {
    if (autoSend) {
      sendTicket();
    }
  }, []);

  function editForm(field, val) {
    let copy = { ...form };
    copy[field] = val;
    setForm(copy);
  }

  function checkMark(boolean, field, val) {
    let copy = { ...form };
    copy[field] = boolean ? val : "";
    setForm(copy);
  }

  const browsers = [
    {
      name: "Chrome",
      icon: "browser-chrome",
    },
    {
      name: "Edge",
      icon: "browser-edge",
    },
    {
      name: "Firefox",
      icon: "browser-firefox",
    },
    {
      name: "Safari",
      icon: "browser-safari",
    },
    // {
    //   name: "Opera",
    //   icon: "",  // no icon
    // },
  ];

  const devices = [
    {
      name: "Computer",
      icon: "laptop",
    },
    {
      name: "Tablet",
      icon: "tablet",
    },
    {
      name: "Phone",
      icon: "phone",
    },
  ];

  const computerOS = [
    {
      name: "Windows",
      icon: "windows",
    },
    {
      name: "MacOS",
      icon: "apple",
    },
    {
      name: "Linux",
      icon: "ubuntu",
    },
    {
      name: "Chrome OS",
      icon: "google",
    },
  ];

  const phoneOS = [
    {
      name: "Android",
      icon: "android",
    },
    {
      name: "iPhone",
      icon: "apple",
    },
  ];

  const tabletOS = [
    {
      name: "Android",
      icon: "android",
    },
    {
      name: "iOS",
      icon: "apple",
    },
    {
      name: "Windows",
      icon: "windows",
    },
    {
      name: "Amazon Fire",
      icon: "amazon",
    },
  ];

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <div className={styles.container}>
        {!timeout && <div style={{ width: "30%" }}>
          <Player
            autoplay
            loop
            src={require("assets/animations/lookingatcomputer.json")}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        </div>}
        {timeout && <Loading></Loading>}
        {timeout && (
          <div>Logging out (Session Timeout)</div>
        )}
        {!timeout && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "7px",
                alignItems: "center",
              }}
            >
              <div>
                <span className={styles.error}>Error code: </span>
                <span className={styles.errorCode}>500</span>
              </div>
              {!submitted && <div>We apologize for the inconvenience</div>}
            </div>

            {/* {error.response.errors.map((e) => (
            <div className={styles.message}>
              {`Message: ${e.message}: at ${e.path.map((p) => p)} `}
            </div>
          ))} */}

            {!troubleSubmitting && (
              <>
                {!submitted && (
                  <>
                    <div className={styles.errorForm}>
                      <div className={styles.formHeader}>
                        Please fill out this form to help us resolve the issue
                      </div>

                      <div className={styles.formSection}>
                        <div className={styles.formSectionTitle}>Device</div>

                        {devices.map((d) => (
                          <div className={styles.formItem}>
                            <Checkbox
                              checked={form.device === d.name}
                              onChange={(e) =>
                                checkMark(e.target.checked, "device", d.name)
                              }
                            />
                            <div className={styles.nameAndIcon}>
                              {d.name}
                              <i
                                className={`bi bi-${d.icon} ${styles.icon}`}
                              ></i>
                            </div>
                          </div>
                        ))}

                        {form.device && (
                          <>
                            <div className={styles.subFormTitle}>
                              Operating System
                            </div>

                            <div className={styles.subForm}>
                              {form.device === "Computer" &&
                                computerOS.map((os) => (
                                  <div className={styles.formItem}>
                                    <Checkbox
                                      checked={form.os === os.name}
                                      onChange={(e) =>
                                        checkMark(
                                          e.target.checked,
                                          "os",
                                          os.name
                                        )
                                      }
                                    />
                                    <div className={styles.nameAndIcon}>
                                      {os.name}
                                      <i
                                        className={`bi bi-${os.icon} ${styles.icon}`}
                                      ></i>
                                    </div>
                                  </div>
                                ))}

                              {form.device === "Tablet" &&
                                tabletOS.map((os) => (
                                  <div className={styles.formItem}>
                                    <Checkbox
                                      checked={form.os === os.name}
                                      onChange={(e) =>
                                        checkMark(
                                          e.target.checked,
                                          "os",
                                          os.name
                                        )
                                      }
                                    />
                                    <div className={styles.nameAndIcon}>
                                      {os.name}
                                      <i
                                        className={`bi bi-${os.icon} ${styles.icon}`}
                                      ></i>
                                    </div>
                                  </div>
                                ))}

                              {form.device === "Phone" &&
                                phoneOS.map((os) => (
                                  <div className={styles.formItem}>
                                    <Checkbox
                                      checked={form.os === os.name}
                                      onChange={(e) =>
                                        checkMark(
                                          e.target.checked,
                                          "os",
                                          os.name
                                        )
                                      }
                                    />
                                    <div className={styles.nameAndIcon}>
                                      {os.name}
                                      <i
                                        className={`bi bi-${os.icon} ${styles.icon}`}
                                      ></i>
                                    </div>
                                  </div>
                                ))}

                              <div
                                className={styles.formItem}
                                style={{
                                  paddingLeft: "0px",
                                  paddingTop: "5px",
                                }}
                              >
                                <span style={{ color: "#a3a4a8" }}>Other</span>
                                <TextFieldSimple
                                  lineTheme
                                  onChange={(val) => editForm("os", val)}
                                  className={styles.textLineTheme}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div
                          className={styles.formItem}
                          // style={{ height: "35px", marginTop: "10px" }}
                          style={{ paddingTop: "10px", paddingLeft: "0px" }}
                        >
                          <TextFieldSimple
                            value={form.deviceSpecs}
                            onChange={(val) => editForm("deviceSpecs", val)}
                            placeholder={"Device Name/Description"}
                            className={styles.textLine}
                          />
                        </div>
                      </div>

                      <div className={styles.formSection}>
                        <div className={styles.formSectionTitle}>Browser</div>

                        {browsers.map((b) => (
                          <div className={styles.formItem}>
                            <Checkbox
                              checked={form.browser === b.name}
                              onChange={(e) =>
                                checkMark(e.target.checked, "browser", b.name)
                              }
                            />
                            <div className={styles.nameAndIcon}>
                              {b.name}
                              <i
                                className={`bi bi-${b.icon} ${styles.icon}`}
                              ></i>
                            </div>
                          </div>
                        ))}
                        <div
                          className={styles.formItem}
                          style={{
                            paddingLeft: "0px",
                            paddingTop: "10px",
                          }}
                        >
                          <span style={{ color: "#a3a4a8" }}>Other</span>
                          <TextFieldSimple
                            lineTheme
                            onChange={(val) => editForm("browser", val)}
                            className={styles.textLineTheme}
                          />
                        </div>
                      </div>

                      <div className={styles.formSection}>
                        <div>
                          <TextFieldSimple
                            value={form.location}
                            onChange={(val) => editForm("location", val)}
                            placeholder={"Where are you located?"}
                            className={styles.textLine}
                          />
                        </div>
                      </div>

                      <div className={styles.formSection}>
                        <div className={styles.formSectionTitle}>
                          Give a little description
                        </div>
                        <TextArea
                          value={form.description}
                          placeholder={
                            " What were you doing? What were you trying to do?"
                          }
                          onSave={(val) => editForm("description", val)}
                          className={styles.textArea}
                        />
                      </div>

                      <div className={styles.formSection}>
                        <TextArea
                          value={form.other}
                          placeholder={"Anything else you'd like us to know"}
                          onSave={(val) => editForm("other", val)}
                          className={`${styles.textArea} ${styles.smallerTextArea}`}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "20px",
                        }}
                      >
                        <Button onClick={sendTicket}>
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            Submit
                            {sending && <Loading height={20} width={20} />}
                          </div>
                        </Button>
                      </div>
                    </div>

                    <div>
                      Or email{" "}
                      <span
                        className={styles.email}
                        onClick={() =>
                          (window.location = "mailto:nhanson@reactiondata.com")
                        }
                      >
                        nhanson@reactiondata.com
                      </span>{" "}
                      for technical support
                    </div>
                  </>
                )}
                {submitted && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1em",
                      alignItems: "center",
                    }}
                  >
                    <div className={styles.thankYou}>
                      Thank you. We are sorry for the error and will work to
                      resolve it quickly.
                    </div>
                    <div style={{ color: "#a3a4a8" }}>- Reaction Data Inc</div>
                  </div>
                )}
              </>
            )}
            {troubleSubmitting && (
              <div className={styles.errorSubmitting}>
                <div>
                  We're sorry, there was another error submitting your form.
                </div>
                <div>
                  Email{" "}
                  <span
                    className={styles.email}
                    onClick={() =>
                      (window.location = "mailto:nhanson@reactiondata.com")
                    }
                  >
                    nhanson@reactiondata.com
                  </span>{" "}
                  for technical support
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
