import React, { useState, useEffect } from "react";
import { TabBar } from "components/layouts";
import styles from "./EditSingle.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import {
  useAddAttribute,
  useCreateCustomField,
  useDeleteAttribute,
  useDeleteCustomField,
  useFetchCustomField,
  useGetAttCounts,
  useUpdateAttName,
  useUpdateCustomField,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import Button from "components/Button/Button";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import IconShopperModal from "pages/results/Charts/Settings/AccordianItems/MetricSettings/IconShopper/IconShopperModal";
import { dataTypes, iconSelectList } from "assets/functions/Variables";
import TextEditorPlain from "components/inputs/input_fields/TextEditorPlain/TextEditorPlain";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";

export default function EditSingleCustomField({
  onClose,
  brandNew,
  field,
  onSaved,
  noModal,
  goback,
}) {
  const fetchField = useFetchCustomField();
  const [theField, setTheField] = useState();
  const [defaultField, setDefault] = useState(false);

  function pullField() {
    fetchField.mutate(
      {
        id: theField ? theField?.id : field?.id,
      },
      {
        onSuccess: (data) => {
          setTheField(data?.field);
          if (data?.field?.generic) {
            setDefault(true);
          }
        },
      }
    );
  }

  useEffect(() => {
    if (!brandNew) {
      pullField();
    }
  }, []);

  const deleteField = useDeleteCustomField();

  function handleDeleteField(field) {
    if (
      window.confirm(
        "Are you sure you want to delete this custom field? It will no longer be visible in your chart filters."
      )
    ) {
      deleteField.mutate(
        {
          id: field.id,
        },
        {
          onSuccess: () => {
            onClose();
            location.reload();
          },
        }
      );
    }
  }

  return (
    <>
      {!noModal && (
        <ReactModal
          show={true}
          onClose={onClose}
          modalStyle={{
            borderRadius: ".5rem",
            width: "1000px",
            height: "95%",
            // maxHeight: "100%"
            overflow: "hidden",
          }}
          dark
        >
          {fetchField.isLoading && !brandNew && <Loading></Loading>}
          {(theField || brandNew) && (
            <Container
              field={theField}
              onSaved={onSaved}
              brandNew={brandNew}
              index={brandNew ? 0 : field?.index}
              deleteField={handleDeleteField}
              onClose={onClose}
              goback={goback}
              defaultField={defaultField}
              refetch={pullField}
            />
          )}
        </ReactModal>
      )}
      {noModal && fetchField.isLoading && <Loading></Loading>}
      {noModal && theField && (
        <Container
          field={theField}
          onSaved={onSaved}
          brandNew={brandNew}
          index={field.index}
          deleteField={handleDeleteField}
          onClose={onClose}
          goback={goback}
          defaultField={defaultField}
          refetch={pullField}
        ></Container>
      )}
    </>
  );
}

function Container({
  field,
  brandNew,
  deleteField,
  index,
  onClose,
  goback,
  defaultField,
  refetch,
}) {
  const initField = () => {
    if (field && !brandNew) {
      let copy = { ...field };
      // if (typeof copy.properties === "string") {
      //   copy.properties = JSON.parse(copy.properties);
      // }
      return copy;
    }
    if (brandNew) {
      return {
        name: "",
        displayName: "",
        properties: [],
        delimiter: "",
        filterable: true,
        attribute: [],
      };
    }
  };

  const [fieldCopy, setFieldCopy] = useState(initField());
  const [attName, setAttName] = useState("");
  const [addAtt, setAddAtt] = useState(false);
  const [adding, setAdding] = useState();
  const [saveStatus, setSaveStatus] = useState(null);
  const [valid, setValid] = useState(true);
  const [changeIcon, setChangeIcon] = useState(false);
  const updateField = useUpdateCustomField();
  const createField = useCreateCustomField();
  const addAttribute = useAddAttribute();
  const deleteAttribute = useDeleteAttribute();
  const updateAtt = useUpdateAttName();

  function makeEdit(copy) {
    if (!valid && copy?.name) {
      setValid(true);
    }

    if (field) {
      // check to see if it is different than before
      if (
        field?.name !== copy?.name ||
        field?.displayName !== copy.displayName ||
        field?.delimiter !== copy?.delimiter ||
        field.filterable !== copy.filterable ||
        field?.multiple != copy?.multiple ||
        field?.unique != copy?.unique ||
        field?.required != copy?.required ||
        field?.defaultValue != copy?.defaultValue ||
        field?.length != copy?.length ||
        field?.description != copy?.description ||
        field?.icon != copy?.icon
      ) {
        if (!saveStatus) {
          setSaveStatus("Save");
        }
      } else {
        setSaveStatus(null);
      }
    } else if (!saveStatus && brandNew) {
      // it is a new field being edited for the first time
      setSaveStatus("Create");
    }

    setFieldCopy(copy);
  }

  const editField = (fieldInField, val) => {
    if (!defaultField) {
      let copy = { ...fieldCopy };
      copy[fieldInField] = val;
      makeEdit(copy);
    }
  };

  function handleAddProp() {
    if (attName && !fieldCopy.attribute.some((a) => a.name === attName)) {
      addAttribute.mutate(
        {
          name: attName,
          customFieldId: field.id,
        },
        {
          onSuccess: (data) => {
            if (data.added) {
              let copy = { ...fieldCopy };
              copy?.attribute.push({ ...data.added });
              makeEdit(copy);

              setAdding(false);
              setAddAtt(false);
            }
          },
          onError: (data) => {
            setAdding(false);
            console.log("Error adding attribute");
          },
        }
      );

      setAdding(true);
      setAttName("");
    }
  }

  function handleDeleteAttribute(attribute) {
    if (
      window.confirm(
        `Are you sure you want to delete ${
          field?.name
        }? It will no longer be attatched to any contacts${
          fieldCopy.filterable ? " nor be visible in chart filters" : ""
        }.`
      )
    ) {
      deleteAttribute.mutate(
        { id: attribute.id },
        {
          onSuccess: (data) => {
            if (data.deleted) {
              let copy = { ...fieldCopy };
              let attInd = copy?.attribute.findIndex((a) =>
                attribute.id ? a.id === attribute.id : a.name === attribute.name
              );
              if (attInd > -1) {
                copy?.attribute.splice(attInd, 1);
                makeEdit(copy);
              }
            }
          },
          onError: (data) => {
            console.log("Error deleting attribute");
          },
        }
      );
    }
  }

  function handleUpdateProp(val, attribute) {
    updateAtt.mutate(
      { id: attribute.id, name: val },
      {
        onSuccess: (data) => {
          if (data.updated) {
            console.log("worked");
            let copy = { ...fieldCopy };

            let att = copy?.attribute.find((a) =>
              attribute.id ? a.id === attribute.id : a.name === attribute.name
            );
            if (att) {
              att.name = val;
              makeEdit(copy);
            }
          }
        },
        onError: (data) => {
          console.log("error updating attribute");
        },
      }
    );
  }

  function handleSaveField() {
    if (!fieldCopy?.name) {
      setValid(false);
      return;
    }

    setSaveStatus(
      <>
        {brandNew ? "Creating" : "Saving"}{" "}
        <Loading height={20} width={20}></Loading>
      </>
    );

    let data = { ...fieldCopy };
    // data.properties = JSON.stringify(data.properties);

    if (brandNew) {
      delete data?.attribute;
      data.properties = "[]";
      createField.mutate(
        {
          data: data,
        },
        {
          onSuccess: (data) => {
            onSaveSuccess();
            window.reload();
            // onClose();
          },
        }
      );
    } else if (field) {
      const id = field.id;
      delete data.id;
      const attributes = data?.attribute;
      delete data?.attribute;

      updateField.mutate(
        {
          id: id,
          data: data,
          attributeData: {
            attributes: attributes,
          },
        },
        {
          onSuccess: () => {
            onSaveSuccess();
          },
        }
      );
    }
  }

  function handleUpdateFilter() {
    updateField.mutate(
      {
        id: fieldCopy?.id,
        data: {
          filterable: !fieldCopy.filterable,
        },
      },
      {
        onSuccess: () => {
          setFieldCopy({ ...fieldCopy, filterable: !fieldCopy.filterable });
        },
      }
    );
  }

  function onSaveSuccess() {
    setSaveStatus(
      <>
        {brandNew ? "Created" : "Saved"} <i className="bi bi-check"></i>
      </>
    );
    setTimeout(() => {
      setSaveStatus(null);
      refetch();
      location.reload();
    }, 2000);
  }

  const [active, setActive] = useState(0);

  function updateActive(id) {
    // localStorage.setItem("contactspage", id);
    setActive(id);
  }

  const menuItems = [
    {
      id: 0,
      name: "Details",
      // to: "/contacts",
      icon: `list-ul`,
    },
    !defaultField && !brandNew
      ? {
          id: 1,
          name: "Column Attributes",
          // to: "/contacts/audiences",
          icon: `person-lines-fill`,
        }
      : undefined,
    // {
    //   id: 2,
    //   name: "Triggers",
    //   // to: "/contacts/audiences",
    //   icon: `rocket-takeoff`,
    // },
    // {
    //   id: 3,
    //   name: "Permissions",
    //   // to: "/contacts/audiences",
    //   icon: `person-lock`,
    // },
  ];

  return (
    <div className={styles.container}>
      {" "}
      <div className={styles.fieldSettings} style={{ width: "100%" }}>
        <div
          className={styles.header_3}
          style={{ margin: "0", color: "black", fontWeight: "400" }}
        >
          <FlexRow start style={{ flexWrap: "wrap" }}>
            {goback && (
              <Button
                onClick={goback}
                style={{ gap: ".5rem", display: "flex", whiteSpace: "nowrap" }}
              >
                <i className="bi-arrow-left"></i> Go Back
              </Button>
            )}
            {`${brandNew ? "New" : "Edit"} Column`}:{" "}
            <div className={styles.name}>
              <i
                className={`bi-${fieldCopy?.icon ? fieldCopy?.icon : "person"}`}
              ></i>
              {fieldCopy?.name}
            </div>
            <div>
              {fieldCopy?.salesforceColumn ? (
                <span
                  className={styles.text_3}
                  style={{
                    margin: "0px",
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                    whiteSpace: "nowrap",
                    marginRight: "1rem",
                  }}
                >
                  <Icon iconName="cloud" blue></Icon> Salesforce Column
                </span>
              ) : (
                ""
              )}
            </div>
          </FlexRow>
        </div>
      </div>
      {!brandNew && (
        <div className={styles.tabs}>
          <TabBar
            tabBarItems={menuItems}
            active={active}
            updateActive={updateActive}
          ></TabBar>
        </div>
      )}
      {active === 0 && (
        <div className={styles.content}>
          <div className={styles.section}>
            <div className={styles.label5}>Column Identification</div>
            <div className={styles.fields}>
              <div className={styles.fieldSettings}>
                <TextFieldSimple
                  label="Column Name*"
                  onChange={(val) => editField("name", val)}
                  placeholder="Type Column Name..."
                  value={fieldCopy?.name}
                  valid={valid}
                  invalidMessage={"Required"}
                  disable={defaultField}
                />
              </div>
              <div className={styles.fieldSettings}>
                <TextFieldSimple
                  label="Alternate Display Name"
                  onChange={(val) => editField("displayName", val)}
                  placeholder="Type Display Name..."
                  value={fieldCopy?.displayName}
                  shadow
                  // disable={defaultField}
                />
              </div>
              <div className={styles.fieldSettings}>
                <TextFieldSimple
                  label="Description"
                  onChange={(val) => editField("description", val)}
                  placeholder="Text shown on hover"
                  value={fieldCopy?.description}
                  shadow
                  // disable={defaultField}
                />
              </div>
              <div className={styles.fieldSettings}>
                <SelectFieldCustom
                  label="Column Icon"
                  onChange={(val) => editField("icon", val?.value)}
                  // placeholder="Type Column Name..."
                  options={iconSelectList}
                  value={
                    iconSelectList.find((i) => i.value === fieldCopy?.icon)
                      ? iconSelectList.find((i) => i.value === fieldCopy?.icon)
                      : iconSelectList.find((i) => i.value === "person")
                  }
                  icon={
                    <i
                      className={`bi-${
                        iconSelectList.find((i) => i.value === fieldCopy?.icon)
                          ? iconSelectList.find(
                              (i) => i.value === fieldCopy?.icon
                            )?.icon
                          : "person"
                      }`}
                      style={{ width: "fit-content" }}
                    ></i>
                  }
                  style={{ backgroundColor: "white" }}
                  // disable={defaultField}
                />

                {changeIcon && (
                  <IconShopperModal
                    onPick={(i) => {
                      setIcon(i);
                    }}
                    onClose={() => setChangeIcon(false)}
                  ></IconShopperModal>
                )}
              </div>{" "}
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.label5}>Data Attribute Settings</div>
            <div className={styles.fields}>
              <div className={styles.fieldSettings}>
                <SelectFieldCustom
                  label="Data Attributes Type"
                  onChange={(val) => editField("dataType", val?.value)}
                  // placeholder="Type Column Name..."
                  options={dataTypes}
                  value={
                    dataTypes.find((i) => i.value === fieldCopy?.dataType)
                      ? dataTypes.find((i) => i.value === fieldCopy?.dataType)
                      : {
                          label: "Text",
                          value: "text",
                        }
                  }
                  style={{ backgroundColor: "white" }}
                  disable={defaultField}
                  icon={
                    <i
                      className={`bi-${
                        dataTypes.find((i) => i.value === fieldCopy?.dataType)
                          ? dataTypes.find(
                              (i) => i.value === fieldCopy?.dataType
                            )?.icon
                          : "text"
                      }`}
                      style={{ width: "fit-content" }}
                    ></i>
                  }
                />
              </div>

              {fieldCopy?.dataType && fieldCopy?.dataType === "boolean" && (
                <BooleanOptions
                  fieldCopy={fieldCopy}
                  editField={editField}
                  defaultField={defaultField}
                  field={field}
                ></BooleanOptions>
              )}

              {(!fieldCopy?.dataType || fieldCopy?.dataType === "text") && (
                <TextOptions
                  fieldCopy={fieldCopy}
                  editField={editField}
                  defaultField={defaultField}
                  field={field}
                ></TextOptions>
              )}

              {fieldCopy?.dataType && fieldCopy?.dataType === "list" && (
                <PickListOptions
                  fieldCopy={fieldCopy}
                  editField={editField}
                  defaultField={defaultField}
                  field={field}
                ></PickListOptions>
              )}
              {fieldCopy?.dataType && fieldCopy?.dataType === "number" && (
                <NumberOptions
                  fieldCopy={fieldCopy}
                  editField={editField}
                  defaultField={defaultField}
                  field={field}
                ></NumberOptions>
              )}
              {fieldCopy?.dataType && fieldCopy?.dataType === "lookup" && (
                <LookupOptions
                  fieldCopy={fieldCopy}
                  editField={editField}
                  defaultField={defaultField}
                  field={field}
                ></LookupOptions>
              )}
              {fieldCopy?.dataType && fieldCopy?.dataType === "currency" && (
                <CurrencyOptions
                  fieldCopy={fieldCopy}
                  editField={editField}
                  defaultField={defaultField}
                  field={field}
                ></CurrencyOptions>
              )}
              {fieldCopy?.dataType && fieldCopy?.dataType === "date/time" && (
                <DateTimeOptions
                  fieldCopy={fieldCopy}
                  editField={editField}
                  defaultField={defaultField}
                  field={field}
                ></DateTimeOptions>
              )}
              {fieldCopy?.dataType && fieldCopy?.dataType === "date" && (
                <DateOptions
                  fieldCopy={fieldCopy}
                  editField={editField}
                  defaultField={defaultField}
                  field={field}
                ></DateOptions>
              )}
              {fieldCopy?.dataType && fieldCopy?.dataType === "time" && (
                <TimeOptions
                  fieldCopy={fieldCopy}
                  editField={editField}
                  defaultField={defaultField}
                  field={field}
                ></TimeOptions>
              )}
              {fieldCopy?.dataType && fieldCopy?.dataType === "phone" && (
                <PhoneOptions
                  fieldCopy={fieldCopy}
                  editField={editField}
                  defaultField={defaultField}
                  field={field}
                ></PhoneOptions>
              )}
              {fieldCopy?.dataType && fieldCopy?.dataType === "email" && (
                <EmailOptions
                  fieldCopy={fieldCopy}
                  editField={editField}
                  defaultField={defaultField}
                  field={field}
                ></EmailOptions>
              )}
              {fieldCopy?.dataType && fieldCopy?.dataType === "url" && (
                <URLOptions
                  fieldCopy={fieldCopy}
                  editField={editField}
                  defaultField={defaultField}
                  field={field}
                ></URLOptions>
              )}
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.label5}>Advanced</div>
            <div className={styles.fields}>
              {/* <div className={styles.fieldSettings}>
                <TextFieldSimple
                  label="Max Value Length"
                  onChange={(val) => editField("length", val)}
                  placeholder="800"
                  value={fieldCopy?.length}
                  shadow
                  type="number"
                  disable={
                    defaultField ||
                    fieldCopy?.dataType === "date" ||
                    fieldCopy?.dataType === "time" ||
                    fieldCopy?.dataType === "date/time" ||
                    fieldCopy?.dataType === "lookup"
                  }
                />
              </div> */}
              {/* <div className={styles.fieldSettings}>
                <TextFieldSimple
                  label="Default Value"
                  onChange={(val) => editField("defaultValue", val)}
                  placeholder="No Default"
                  value={fieldCopy?.defaultValue}
                  shadow
                  disable={defaultField}
                />
              </div> */}
              <div className={styles.fieldSettings}>
                <FlexRow start>
                  <Checkbox
                    checked={fieldCopy?.filterable}
                    onChange={(e) => editField("filterable", e.target.checked)}
                  ></Checkbox>
                  <span className={styles.text_2}>
                    Show as a filter <i className="bi-funnel"></i>
                  </span>
                </FlexRow>
              </div>

              <div className={styles.fieldSettings}>
                <FlexRow start>
                  <Checkbox
                    checked={fieldCopy?.unique}
                    onChange={(e) =>
                      !defaultField
                        ? editField("unique", e.target.checked)
                        : undefined
                    }
                  ></Checkbox>
                  <span className={styles.text_2}>Unique Values</span>
                </FlexRow>
              </div>
              <div className={styles.fieldSettings}>
                <FlexRow start>
                  <Checkbox
                    checked={fieldCopy?.required}
                    onChange={(e) =>
                      !defaultField
                        ? editField("required", e.target.checked)
                        : undefined
                    }
                  ></Checkbox>
                  <span className={styles.text_2}>Required</span>
                </FlexRow>
              </div>
              <div className={styles.fieldSettings}>
                {!brandNew && !defaultField && (
                  <div
                    className={`${styles.link} ${styles.red}`}
                    onClick={() => deleteField(field)}
                    shadow
                    red
                    style={{
                      gap: ".75em",
                      fontWeight: "400",
                      display: "flex",
                      marginTop: "1rem",
                    }}
                  >
                    <i className="bi-trash"></i> Delete Column
                  </div>
                )}
              </div>
            </div>{" "}
          </div>

          {saveStatus && (
            <div className={styles.savebar}>
              <Button
                height={2.4}
                onClick={() => handleSaveField(true)}
                shadow
                blue
              >
                {saveStatus}
              </Button>
            </div>
          )}
        </div>
      )}
      {active === 1 && (
        <>
          <FlexRow style={{ padding: "0 1rem", marginTop: "2rem" }}>
            <span className={styles.label5}>Column Attributes</span>
            {!addAtt && (
              <div
                className={styles.link}
                style={{ padding: "0rem 1rem", fontSize: ".9rem" }}
                onClick={() => setAddAtt(true)}
              >
                Add Attribute
              </div>
            )}
          </FlexRow>
          {addAtt && (
            <div className={styles.addAttContainer}>
              <TextFieldSimple
                label="New Attribute"
                placeholder="New Attribute..."
                value={attName}
                onChange={setAttName}
                onEnter={handleAddProp}
                valid={
                  attName &&
                  !fieldCopy.attribute.some((a) => a.name === attName)
                }
                invalidMessage={"Already exists"}
                autoFocus
              />

              <Button
                onClick={handleAddProp}
                shadow
                style={{ marginBottom: "5px" }}
                disable={
                  attName && fieldCopy.attribute.some((a) => a.name === attName)
                }
              >
                {adding ? (
                  <>
                    <Loading height={25} width={25} />
                    Adding
                  </>
                ) : (
                  <>Add</>
                )}
              </Button>
            </div>
          )}
          <div className={styles.customFieldsBox}>
            {fieldCopy?.attribute?.map((att) => (
              <Attribute
                key={att.id}
                attribute={att}
                onDelete={() => handleDeleteAttribute(att)}
                onEdit={(val) => handleUpdateProp(val, att)}
              ></Attribute>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

function Attribute({ attribute, onDelete, onEdit }) {
  const fetchAttCount = useGetAttCounts(attribute.id);

  return (
    <div className={styles.cField}>
      <TextEditorPlain
        text={attribute.name}
        extraClass={styles.attName}
        editable
        onSave={onEdit}
        onEnter={(e) => e.target.blur()}
      />
      {fetchAttCount.isSuccess && (
        <div className={styles.count}>{fetchAttCount.data.count}</div>
      )}
      <div className={`${styles.otherSide} ${styles.delete}`}>
        <i className="bi bi-x" onClick={onDelete}></i>
      </div>
    </div>
  );
}

function BooleanOptions({ fieldCopy, editField, defaultField, field }) {
  return (
    <>
      {" "}
      <div className={styles.fieldSettings}>
        <div className={styles.text_3}>True or False values</div>
      </div>
      <div className={styles.fieldSettings}>
        <TextFieldSimple
          label="True Value"
          // onChange={(val) => editField("name", val)}
          placeholder="True"
          // value={"Manager"}
        />
        <TextFieldSimple
          label="False Value"
          // onChange={(val) => editField("name", val)}
          placeholder="False"
          // value={"Manager"}
        />
      </div>
      <div className={styles.fieldSettings}>
        <div className={styles.label6} style={{ whiteSpace: "nowrap" }}>
          Input Example:
        </div>

        <FlexRow start gap=".5rem">
          <Checkbox /> <div className={styles.text_2}>False</div>
        </FlexRow>
      </div>
    </>
  );
}

function TextOptions({
  fieldCopy,
  editField,
  defaultField,
  field,
  saveStatus,
}) {
  return (
    <>
      {" "}
      <div className={styles.fieldSettings}>
        <div className={styles.text_3}>
          Users may provide any combination of letters and numbers.
        </div>{" "}
      </div>
      <div className={styles.fieldSettings}>
        <TextFieldSimple
          label="Max Value Length"
          onChange={(val) => editField("length", val)}
          placeholder="800"
          value={fieldCopy?.length}
          shadow
          type="number"
          disable={defaultField}
        />{" "}
      </div>
      <div className={styles.fieldSettings} style={{ marginTop: "1rem" }}>
        <div className={styles.label6} style={{ whiteSpace: "nowrap" }}>
          Input Example:
        </div>

        <TextFieldSimple
          // label="Column Name*"
          // onChange={(val) => editField("name", val)}
          placeholder="Type Column Name..."
          value={"Manager"}
        />
      </div>
    </>
  );
}

function PickListOptions({
  fieldCopy,
  editField,
  defaultField,
  field,
  saveStatus,
}) {
  return (
    <>
      {" "}
      <div className={styles.fieldSettings}>
        <div className={styles.text_3}>
          A dropdown list of attributes that users may choose from.
        </div>{" "}
      </div>
      <div className={styles.fieldSettings}>
        <TextFieldSimple
          label="Max Value Length"
          onChange={(val) => editField("length", val)}
          placeholder="800"
          value={fieldCopy?.length}
          shadow
          type="number"
          disable={defaultField}
        />{" "}
      </div>
      <div className={styles.fieldSettings}>
        <FlexRow start>
          <Checkbox
            checked={fieldCopy?.multiple}
            onChange={(e) => editField("multiple", e.target.checked)}
          ></Checkbox>
          <span className={styles.text_2}>Multiple Values</span>
        </FlexRow>
        {fieldCopy?.multiple && (
          <div className={styles.fieldSettings}>
            <div style={{ width: "120px" }}>
              <TextFieldSimple
                onChange={(val) => editField("delimiter", val)}
                placeholder="None"
                value={fieldCopy?.delimiter ? fieldCopy?.delimiter : ""}
                label="Delimiter"
                shadow
                disable={defaultField}
              />
            </div>
          </div>
        )}
        {/* {field?.multiple != fieldCopy?.multiple && (
          <Button onClick={() => handleSaveField(true)} blue>
            {saveStatus}
          </Button>
        )} */}
      </div>
      <div className={styles.fieldSettings} style={{ marginTop: "1rem" }}>
        <div className={styles.label6} style={{ whiteSpace: "nowrap" }}>
          Input Example:
        </div>

        {!fieldCopy.multiple && (
          <SelectFieldCustom
            // onChange={(val) => editField("dataType", val?.value)}
            // placeholder="Type Column Name..."
            options={[
              { value: "Manager", label: "Manager" },
              { value: "C-Level", label: "C-Level" },
              { value: "Director", label: "Director" },
              { value: "Staff", label: "Staff" },
              { value: "Developer", label: "Developer" },
            ]}
            value={{ value: "Manager", label: "Manager" }}
            //  selectMultiple
            style={{ backgroundColor: "white" }}
            disable={defaultField}
          />
        )}
        {fieldCopy.multiple && (
          <SelectFieldCustom
            // onChange={(val) => editField("dataType", val?.value)}
            // placeholder="Type Column Name..."
            options={[
              { value: "Manager", label: "Manager" },
              { value: "C-Level", label: "C-Level" },
              { value: "Director", label: "Director" },
              { value: "Staff", label: "Staff" },
              { value: "Developer", label: "Developer" },
            ]}
            values={[{ value: "Manager", label: "Manager" }]}
            selectMultiple
            style={{ backgroundColor: "white" }}
            disable={defaultField}
          />
        )}
      </div>
    </>
  );
}

function NumberOptions({
  fieldCopy,
  editField,
  defaultField,
  field,
  saveStatus,
}) {
  return (
    <>
      {" "}
      <div className={styles.fieldSettings}>
        <div className={styles.text_3}>
          A numeric value that can have decimal places.
        </div>{" "}
      </div>
      <div className={styles.fieldSettings}>
        <TextFieldSimple
          type="number"
          placeholder="10"
          value={10}
          label="Maximum Value"
        />

        <TextFieldSimple
          type="number"
          placeholder="10"
          value={10}
          label="Minimum Value"
        />

        <TextFieldSimple
          type="number"
          placeholder="10"
          value={10}
          label="Value Step"
        />
      </div>
      <div className={styles.fieldSettings} style={{ marginTop: "1rem" }}>
        <div className={styles.label6} style={{ whiteSpace: "nowrap" }}>
          Input Example:
        </div>
        <TextFieldSimple type="number" placeholder="10" value={"Manager"} />
      </div>
    </>
  );
}

function LookupOptions({
  fieldCopy,
  editField,
  defaultField,
  field,
  saveStatus,
}) {
  return (
    <>
      {" "}
      <div className={styles.fieldSettings}>
        <div className={styles.text_3}>
          Connect a field from one object to a specific record in another.
        </div>{" "}
      </div>
      <div className={styles.fieldSettings}>
        <SelectFieldCustom
          options={[
            { value: "Manager", label: "Manager" },
            { value: "C-Level", label: "C-Level" },
            { value: "Director", label: "Director" },
            { value: "Staff", label: "Staff" },
            { value: "Developer", label: "Developer" },
            { value: "Contact", label: "Contact" },
          ]}
          value={{ value: "Contact", label: "Contact" }}
          style={{ backgroundColor: "white" }}
          disable={defaultField}
          label="Lookup Object"
        />
      </div>
      <div className={styles.fieldSettings} style={{ marginTop: "1rem" }}>
        <div className={styles.label6} style={{ whiteSpace: "nowrap" }}>
          Input Example:
        </div>

        <SelectFieldCustom
          options={[
            { value: "Manager", label: "Manager" },
            { value: "C-Level", label: "C-Level" },
            { value: "Director", label: "Director" },
            { value: "Staff", label: "Staff" },
            { value: "Developer", label: "Developer" },
          ]}
          value={{ value: "Manager", label: "Manager" }}
          style={{ backgroundColor: "white" }}
          disable={defaultField}
        />
      </div>
    </>
  );
}

function CurrencyOptions({
  fieldCopy,
  editField,
  defaultField,
  field,
  saveStatus,
}) {
  const [value, setValue] = useState("");

  const handleChange = (val) => {
    const inputValue = val;
    const formattedValue = formatCurrency(inputValue);
    setValue(formattedValue);
  };

  return (
    <>
      <div className={styles.fieldSettings}>
        <div className={styles.text_3}>
          Users may provide any combination of letters and numbers.
        </div>
      </div>
      <div className={styles.fieldSettings} style={{ marginTop: "1rem" }}>
        <div className={styles.label6} style={{ whiteSpace: "nowrap" }}>
          Input Example:
        </div>

        <TextFieldSimple
          // label="Column Name*"
          // onChange={(val) => handleChange(val)}
          icon={"currency-dollar"}
          placeholder="0.00"
          // value={value}
          type="currency"
        />
      </div>
    </>
  );
}

function DateTimeOptions({
  fieldCopy,
  editField,
  defaultField,
  field,
  saveStatus,
}) {
  return (
    <>
      {" "}
      <div className={styles.fieldSettings}>
        <div className={styles.text_3}>
          Users may provide any date and/or time.
        </div>
      </div>
      <div className={styles.fieldSettings} style={{ marginTop: "1rem" }}>
        <div className={styles.label6} style={{ whiteSpace: "nowrap" }}>
          Input Example:
        </div>
        <TextFieldSimple
          // label="Column Name*"
          // onChange={(val) => editField("name", val)}
          type="date"
          placeholder="Type Column Name..."
          // value={"Manager"}
        />{" "}
        <TextFieldSimple
          // label="Column Name*"
          // onChange={(val) => editField("name", val)}
          type="time"
          placeholder="Type Column Name..."
          // value={"Manager"}
        />
      </div>
    </>
  );
}

function DateOptions({
  fieldCopy,
  editField,
  defaultField,
  field,
  saveStatus,
}) {
  return (
    <>
      {" "}
      <div className={styles.fieldSettings}>
        <div className={styles.text_3}>Users may provide any date.</div>
      </div>
      <div className={styles.fieldSettings} style={{ marginTop: "1rem" }}>
        <div className={styles.label6} style={{ whiteSpace: "nowrap" }}>
          Input Example:
        </div>
        <TextFieldSimple
          // label="Column Name*"
          // onChange={(val) => editField("name", val)}
          type="date"
          placeholder="Type Column Name..."
          // value={"Manager"}
        />{" "}
      </div>
    </>
  );
}

function TimeOptions({
  fieldCopy,
  editField,
  defaultField,
  field,
  saveStatus,
}) {
  return (
    <>
      {" "}
      <div className={styles.fieldSettings}>
        <div className={styles.text_3}>Users may provide any time.</div>
      </div>
      <div className={styles.fieldSettings} style={{ marginTop: "1rem" }}>
        <div className={styles.label6} style={{ whiteSpace: "nowrap" }}>
          Input Example:
        </div>
        <TextFieldSimple
          // label="Column Name*"
          // onChange={(val) => editField("name", val)}
          type="time"
          placeholder="Type Column Name..."
          // value={"Manager"}
        />
      </div>
    </>
  );
}

function PhoneOptions({
  fieldCopy,
  editField,
  defaultField,
  field,
  saveStatus,
}) {
  return (
    <>
      {" "}
      <div className={styles.fieldSettings}>
        <div className={styles.text_3}>
          Phone number, style can be adjusted.
        </div>
      </div>
      <div className={styles.fieldSettings}>
        <TextFieldSimple
          label="Max Value Length"
          onChange={(val) => editField("length", val)}
          placeholder="800"
          value={fieldCopy?.length}
          shadow
          type="number"
          disable={defaultField}
        />{" "}
      </div>
      <div className={styles.fieldSettings} style={{ marginTop: "1rem" }}>
        <div className={styles.label6} style={{ whiteSpace: "nowrap" }}>
          Input Example:
        </div>

        <TextFieldSimple
          // label="Column Name*"
          // onChange={(val) => editField("name", val)}
          icon="telephone"
          type="phone"
          placeholder="(123) 456-7890 or +1 (234) 567-8901"
          // value={"Manager"}
        />
      </div>
    </>
  );
}

function EmailOptions({
  fieldCopy,
  editField,
  defaultField,
  field,
  saveStatus,
}) {
  return (
    <>
      <div className={styles.fieldSettings}>
        <div className={styles.text_3}>
          Email address syntax and deliverability verified when marked as an
          email data type.
        </div>
      </div>
      <div className={styles.fieldSettings}>
        <TextFieldSimple
          label="Max Value Length"
          onChange={(val) => editField("length", val)}
          placeholder="800"
          value={fieldCopy?.length}
          shadow
          type="number"
          disable={defaultField}
        />{" "}
      </div>
      <div className={styles.fieldSettings} style={{ marginTop: "1rem" }}>
        <div className={styles.label6} style={{ whiteSpace: "nowrap" }}>
          Input Example:
        </div>

        <TextFieldSimple
          // label="Column Name*"
          // onChange={(val) => editField("name", val)}
          icon="at"
          placeholder="jdoe@example.com"
          // value={""}
        />
      </div>
    </>
  );
}

function URLOptions({ fieldCopy, editField, defaultField, field, saveStatus }) {
  return (
    <>
      <div className={styles.fieldSettings}>
        <div className={styles.text_3}>
          A url link that will open a new tab when clicked on.
        </div>
      </div>
      <div className={styles.fieldSettings}>
        <TextFieldSimple
          label="Max Value Length"
          onChange={(val) => editField("length", val)}
          placeholder="800"
          value={fieldCopy?.length}
          shadow
          type="number"
          disable={defaultField}
        />{" "}
      </div>
      <div className={styles.fieldSettings} style={{ marginTop: "1rem" }}>
        <div className={styles.label6} style={{ whiteSpace: "nowrap" }}>
          Input Example:
        </div>

        <TextFieldSimple
          // label="Column Name*"
          // onChange={(val) => editField("name", val)}
          placeholder="https://example.com"
          // value={""}
        />
      </div>
    </>
  );
}
