import { icons } from "./IconShopper";
import styles from "./IconShopperModal.module.scss";
import { useRef, useEffect } from "react";

export default function IconShopperModal({ onPick, onClose }) {
  function onClick(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      onClose();
    }
  }

  useEffect(() => {
    setTimeout(() => document.addEventListener("click", onClick, false), 100);
    return () => {
      document.removeEventListener("click", onClick, false);
    };
  }, []);

  const ref = useRef();

  return (
    <div className={styles.anchor}>
      <div className={styles.catalog} ref={ref}>
        {icons.map((icon) => (
          <div className={styles.icon} onClick={() => onPick(icon)}>
            <i className={`bi bi-${icon}`}></i>
          </div>
        ))}
      </div>
    </div>
  );
}
