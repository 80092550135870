export const ChartColors = [
  "#eb4034", // red
  "#ff6c5c", // light red
  // "#ff8400", // orange
  "#ed9146", // Reaction orange
  "#FFC887", // light orange
  "#F9CB56", // light yellow
  "#fcec00", // yellow
  "#aae08d", // light green
  "#53b568", // green
  "#2A627C", // Reaction Sapphire
  // "#0f9bdb", // royal blue
  "#15bcc7", // Reaction blue,
  // "#7fcfd3", // extra light blue,
  "#52afc7", // slate blue,
  "#d07bdb", // purple
  "#d8b5e7", // light purple,
  "#DDAD7F", // light brown
  "#A25823", // brown,
  // '#FFE5B4', // peach,
  "#c9c9c9", // gray,
];

export const SurveyColors = [
  // "#eb4034", // red
  "#ff6c5c", // light red
  // "#ff8400", // orange
  "#ed9146", // Reaction orange
  "#FFC887", // light orange
  // "#F9CB56", // light yellow
  "#fcec00", // yellow
  "#aae08d", // light green
  "#53b568", // green
  "#2A627C", // Reaction Sapphire
  // "#0f9bdb", // royal blue
  "#15bcc7", // Reaction blue,
  // "#7fcfd3", // extra light blue,
  "#52afc7", // slate blue,
  "#f9fbfc", //Reaction light bluew
  // "#d07bdb", // purple
  // "#d8b5e7", // light purple,
  "#DDAD7F", // light brown
  "#A25823", // brown,
  // '#FFE5B4', // peach,
  "#000000", //black
  "#616565", // dark gray
  "#f5f5f5", // gray,
  "#ffffff", // white
];

export const TextColors = [
  "#050606",
  "#616565",
  "#a3a4a8",
  "#c9c9c9",
  "#d8d9d9",
  "#ffffff",
];

// const oldDefaultColors = [
//   "#2a627c",
//   "#a4c6d0",
//   "#15bcc7",
//   "#b5e1df",
//   "#ed9146",
//   "#edb57e",
//   "#738c91",
//   "#8dabb2",
//   "#616565",
//   "#a3a4a8",
//   "#d8d9d9",
//   "#E9E9E9",
//   "#FFFFFF",
// ];

function hexToRgb(hex) {
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);
  return { r, g, b };
}

function rgbToHex(r, g, b) {
  var hexR = r.toString(16).padStart(2, "0");
  var hexG = g.toString(16).padStart(2, "0");
  var hexB = b.toString(16).padStart(2, "0");
  return "#" + hexR + hexG + hexB;
}

export function getGradientColors(colorOne, colorTwo, colorCount) {
  if (colorCount == 1) {
    return [colorOne];
  }

  var start = hexToRgb(colorOne.slice(1));
  var end = hexToRgb(colorTwo.slice(1));

  var rStep = (end.r - start.r) / (colorCount - 1);
  var gStep = (end.g - start.g) / (colorCount - 1);
  var bStep = (end.b - start.b) / (colorCount - 1);

  var gradientColors = [];
  for (var i = 0; i < colorCount; i++) {
    var r = Math.round(start.r + i * rStep);
    var g = Math.round(start.g + i * gStep);
    var b = Math.round(start.b + i * bStep);
    var hex = rgbToHex(r, g, b);
    gradientColors.push(hex);
  }

  return gradientColors;
}

export function getTheOffWhite(color, colorCount) {
  var rgb = hexToRgb(color.slice(1));

  var rStep = (255 - rgb.r) / colorCount;
  var gStep = (255 - rgb.g) / colorCount;
  var bStep = (255 - rgb.b) / colorCount;

  var ceiling = colorCount - 1; // To get off white shade

  var r = Math.round(rgb.r + ceiling * rStep);
  var g = Math.round(rgb.g + ceiling * gStep);
  var b = Math.round(rgb.b + ceiling * bStep);
  var hex = rgbToHex(r, g, b);
  return hex;
}

export function getColorsFromPalette(palette, length) {
  let colors = JSON.parse(palette.colors);
  if (colors.length === length) {
    return [...colors];
  }
  if (palette.fill) {
    if (palette.anchors) {
      let anchors = JSON.parse(palette.anchors);
      return getColorsFromGradient(anchors, palette.blended, length);
    } else {
      let anchors = colors.map((color) => {
        return {
          color: color,
          portion: 0,
        };
      });
      return getColorsFromGradient(anchors, palette.blended, length);
    }
  }
  // else repeat
  let c = 0;
  let array = [];
  for (let i = 0; i < length; i++) {
    array[i] = colors[c];
    c < colors.length - 1 ? c++ : (c = 0);
  }
  return array;
}

export function getColorsFromGradient(anchors, blended, length) {
  if (anchors.length >= length) {
    let colors = [];
    for (let i = 0; i < length; i++) {
      colors.push(anchors[i].color);
    }
    return colors;
  }

  // else will have to fill

  let data = getGradientLengths(anchors, blended && anchors.length > 1, length);

  let colors = [];
  if (blended && data.length > 1) {
    let pairs = [];
    for (let i = 1; i < data.length; i++) {
      let pair = {
        one: data[i - 1].color,
        two: data[i].color,
        length: data[i - 1].length,
      };
      pairs.push(pair);
    }

    for (let pair of pairs) {
      // pair.length
      let spread = getGradientColors(pair.one, pair.two, pair.length + 2); // in blended, lengths refer to the gaps, or amount between.
      if (!colors.length) {
        colors = [...spread];
      } else {
        spread.shift();
        colors = [...colors, ...spread];
      }
    }
  } else {
    for (let anchor of data) {
      let offWhite = getTheOffWhite(anchor.color, anchor.length);
      let spread = getGradientColors(anchor.color, offWhite, anchor.length);
      if (anchor.reversed) {
        spread.reverse();
      }
      colors = [...colors, ...spread];
    }
  }
  return colors;
}

export function getGradientLengths(anchors, blended, length) {
  let data = [];
  let count = 0;
  let extras = length - anchors.length;

  for (let anchor of anchors) {
    let color = {
      color: anchor.color,
      reversed: anchor.reversed,
    };

    if (blended) {
      color.length = Math.round(extras * (anchor.portion / 100));
    } else {
      color.length = Math.round(length * (anchor.portion / 100));
    }
    count += color.length;
    data.push(color);
  }

  if (blended) {
    count += anchors.length;
  }

  if (count != length) {
    // Left to Right priority
    if (count < length) {
      let index = 0;
      while (count != length) {
        data[index].length++;
        count++;
        if (index + 1 < (blended ? data.length - 1 : data.length)) {
          index++;
        } else {
          index = 0;
        }
      }
    } else {
      let index = blended ? data.length - 2 : data.length - 1;
      while (count != length) {
        if (data[index].length) {
          data[index].length--;
          count--;
        }
        if (index > 0) {
          index--;
        } else {
          index = blended ? data.length - 2 : data.length - 1;
        }
      }
    }
  }

  return data;
}

function littleLighter(color) {
  const amount = 25;
  let rgb = hexToRgb(color.slice(1));

  let r = Math.min(255, rgb.r + amount);
  let g = Math.min(255, rgb.g + amount);
  let b = Math.min(255, rgb.b + amount);

  let lighter = rgbToHex(r, g, b);
  return lighter;
}

function lilLighter(color) {
  const fraction = 0.4;
  let rgb = hexToRgb(color.slice(1));

  // Calculate the lightened RGB values
  const r = rgb.r + Math.round((255 - rgb.r) * fraction);
  const g = rgb.g + Math.round((255 - rgb.g) * fraction);
  const b = rgb.b + Math.round((255 - rgb.b) * fraction);

  let lighter = rgbToHex(r, g, b);
  return lighter;
}

/**
 *
 * @param {*} colors
 * @returns array of colors double the size
 */
export function getComparisonColors(colors) {
  let newSet = [];
  for (let color of colors) {
    newSet.push(color);
    // let comparable = lilLighter(color);
    let comparable = getTheOffWhite(color, 3);
    newSet.push(comparable);
  }

  return newSet;
}
