/**
 * A component to do dynamic uploads when creating a new audience. This will give the requirements for the contacts
 * to be pulled from the database to add to the newly created audience.
 * @returns {React.ReactElement} a component for dynamic uploads
 */

//External
import React, { useState } from "react";

//Internal
import styles from "./DynamicUpload.module.scss";
import { SelectField, TextField, TextFieldSimple } from "components/inputs";
import { Label } from "components/layouts/Label/Label";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";

export const DynamicUpload = ({
  rules,
  setRules,
  apply,
  loading,
  rulesBefore,
}) => {
  const [change, setChange] = useState(false);
  const filterTypes = [
    {
      value: "equals",
      label: "Equals",
    },
    {
      value: "doesnotequal",
      label: "Does Not Equal",
    },
    {
      value: "contains",
      label: "Contains",
    },
    {
      value: "doesnotcontain",
      label: "Does Not Contain",
    },
  ];

  const filterConditions = [
    {
      value: "firstName",
      label: "First Name",
    },
    {
      value: "lastName",
      label: "Last Name",
    },
    {
      value: "email",
      label: "Email",
    },
  ];

  function addrule() {
    let temp = [...rules];
    temp.push({ field: "", condition: "", value: "" });
    setRules(temp);
  }

  function removerule(i) {
    let temp = [...rules];
    temp.splice(i, 1);
    setRules(temp);
  }

  function updateRule(val, type, i) {
    let temp = [...rules];
    temp[i][type] = val;
    setRules(temp);
    setChange(true);
  }

  function handleApply() {
    setChange(false);
    apply(rules);
  }

  return (
    <div className={styles.page}>
      <Label> Or Dynamically Add</Label>
      {rules &&
        rules.map((rule, i) => (
          <div className={styles.rules} key={i}>
            <SelectField
              options={filterConditions}
              value={rule.field}
              onChange={(val) => updateRule(val, "field", i)}
            ></SelectField>
            <SelectField
              options={filterTypes}
              value={rule.condition}
              onChange={(val) => updateRule(val, "condition", i)}
            ></SelectField>
            <TextFieldSimple
              placeholder="Type..."
              value={rule?.value}
              onChange={(val) => updateRule(val, "value", i)}
            ></TextFieldSimple>
            {rules?.length > 1 && (
              <i
                className="bi-dash-circle"
                onClick={() => {
                  removerule(i);
                  setChange(true);
                }}
              ></i>
            )}
          </div>
        ))}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: ".5em",
        }}
      >
        <Button shadow onClick={addrule}>
          {" "}
          + New Rule
        </Button>{" "}
        {change && (
          <div style={{ display: "flex", gap: ".5em" }}>
            <Button shadow onClick={() => {setRules([...rulesBefore]); setChange(false)}}>
              {" "}
              <div style={{ display: "flex", gap: ".5em" }}>
                Undo <i className="bi-arrow-counterclockwise"></i>
              </div>
            </Button>
            <Button shadow onClick={handleApply}>
              {loading ? (
                <>
                  Applying <Loading height={20} width={20}></Loading>
                </>
              ) : (
                "Apply"
              )}
            </Button>
          </div>
        )}
        {!change && loading && (
          <Button shadow onClick={handleApply}>
            {loading ? (
              <>
                Applying <Loading height={20} width={20}></Loading>
              </>
            ) : (
              "Apply"
            )}
          </Button>
        )}
      </div>
    </div>
  );
};
