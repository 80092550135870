import React from "react";
import styles from "./ReportStyles.module.scss";
import { fonts, shadows } from "assets/functions/Variables";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import ColorPickerContainer from "components/inputs/input_fields/ColorPicker/ColorPickerContainer";
import FlexRow from "components/layouts/FlexRow/FlexRow";

function ReportStyles({ report }) {
  const changeSettingsField = (field, val) => {
    // let newViz = { ...viz };
    // newViz.designSettings[field] = val;
    // updateViz(newViz);
  };

  return (
    <div className={styles.body}>
      <div className={styles.label6}>Global Styles</div>

      <ColorPickerContainer
        defaultColor={
          report?.designSettings?.pageBackgroundColor
            ? report?.designSettings?.pageBackgroundColor
            : "#FFFFFF"
        }
        onChange={(val) => changeSettingsField("pageBackgroundColor", val)}
        textColors
        title="Page Background Color"
        style={{
          backgroundColor: "transparent",
          padding: "0",
          margin: "0",
        }}
      ></ColorPickerContainer>

      <CombinedInput
        onChange={(color) =>
          changeSettingsField("globalBorderShadows", color.value)
        }
        value={
          report?.designSettings?.boxShadow
            ? shadows.find((s) => s.value === report?.designSettings?.boxShadow)
            : { value: "", label: "No Shadow" }
        }
        label="Chart Border Shadow"
        icon="bi-chevron-down"
        options={shadows}
        select
        anyWhereOptions
      />

      <NumberInputWithSlider
        val={
          report?.designSettings?.valueTitleSize
            ? report?.designSettings?.valueTitleSize
            : 17
        }
        min={0}
        max={40}
        step={1}
        onChange={(val) => changeSettingsField("globalBorderRadius", val)}
        title="Chart Border Radius"
      ></NumberInputWithSlider>

      <div className={styles.setting}>
        <FlexRow start>
          <div className={styles.label6}>Chart Background Color</div>
          <ToggleSwitch
            startChecked={report?.designSettings?.globalBackgroundColor}
            handleCheck={(val) =>
              changeSettingsField(
                "globalBackgroundColor",
                val ? "#FFFFFF" : false
              )
            }
          ></ToggleSwitch>
        </FlexRow>

        {/* {report?.designSettings?.globalBackgroundColor && ( */}
        <ColorPickerContainer
          defaultColor={
            report?.designSettings?.globalBackgroundColor
              ? report?.designSettings?.globalBackgroundColor
              : "#FFFFFF"
          }
          onChange={(val) => changeSettingsField("globalBackgroundColor", val)}
          textColors
          //   title="Chart Background Color"
          style={{
            backgroundColor: "transparent",
            padding: "0",
            margin: "0",
          }}
        ></ColorPickerContainer>
        {/* )} */}
      </div>

      <CombinedInput
        select
        label="Font Style"
        options={fonts}
        value={
          report?.designSettings?.globalFont
            ? fonts.find((v) => v.value === report?.designSettings?.globalFont)
            : fonts.find((v) => v.value === "Poppins, sans-serif")
        }
        onChange={(v) => changeSettingsField("globalFont", v.value)}
        icon={"bi-chevron-down"}
      />
    </div>
  );
}

export default ReportStyles;
