import React, { useState } from "react";
import styles from "./Circle.module.scss";

const cleanPercentage = (percentage) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100;
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

export const Circle = ({ colour, percentage, dim, strokeWidth }) => {
  const r = dim ? dim / 2 - 4 : 40;
  const x = dim ? dim / 2 : 50;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circ) / 100; // where stroke will start, e.g. from 15% to 100%.

  return (
    <circle
      r={r}
      cx={x}
      cy={x}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={strokeWidth ? strokeWidth : ".3em"}
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
      className={styles.circle}
    ></circle>
  );
};

const Text = ({ percentage, colour, dim, progress }) => {
  return (
    <text
      className={styles.textPercentage}
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={"1.5rem"}
      fill={colour}
      style={{ fontSize: dim ? dim / 4 : "" }}
    >
      {percentage.toFixed(0)}
      {progress ? "%" : ""}
    </text>
  );
};

export const Pie = ({
  percentage,
  colour,
  dim,
  hideText,
  strokeWidth,
  progress,
  backColor,
}) => {
  const pct = cleanPercentage(percentage);

  function getColor() {
    if (percentage < 60) {
      return "#FF8878";
    }
    if (percentage >= 60 && percentage < 90) {
      return "#F2C85C";
    }
    if (percentage >= 90) {
      return "#C1E08D";
    }
  }
  return (
    <svg width={dim ? dim : 100} height={dim ? dim : 100}>
      <g>
        <Circle
          colour={backColor ? backColor : "transparent"}
          dim={dim}
          strokeWidth={strokeWidth}
        />
        <Circle
          colour={colour ? colour : getColor()}
          dim={dim}
          percentage={pct}
          strokeWidth={strokeWidth}
        />
      </g>
      {!hideText && (
        <Text
          percentage={pct}
          colour={colour ? colour : getColor()}
          dim={dim}
          progress={progress}
        />
      )}
    </svg>
  );
};
