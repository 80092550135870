import { useEffect, useRef, useState } from "react";
import styles from "../AnswerTable.module.scss";
import { Loading } from "components/Loading/Loading";
import TableChart from "components/Charts/Table/TableChart";
import { getTitleContainerStyle, getTitleStyle } from "../Table";
import { useGetPivotTable } from "api/resources/projects/visualizations";
import { BackendDataTable } from "../BackendDataTable";
import { combinedQs } from "../../Visualization";
import { AnswerCount, AvgScore, NpsScore, ParticipationCount, Ranking } from "../../QuestionChart";
import { BackendDrillTable } from "../BackendDrillTable";

export function BackendPivotTable({
  viz,
  filters,
  filterSubtitle,
  saveTableSort,
  canSeeContactInfo,
  inEdit,
  height,
  setOutsideData,
  setUpOutsideDataCounter,
  seeData,
  closeSeeData,
  show,
  setShowDrill,
  custom_fields,
}) {
  const [headers, setHeaders] = useState();
  const [dataArray, setDataArray] = useState();
  const [loading, setLoading] = useState();

  function getVizState() {
    return {
      designSettings: JSON.parse(JSON.stringify(viz.designSettings)),
      pivotString: viz.pivotString,
      qIds: combinedQs(viz).map((q) => q.id),
    };
  }

  const ref = useRef(getVizState());

  const style = (value) => <span className={styles.text}>{value}</span>;

  function onSuccess(table) {
    setDataArray(JSON.parse(table.rows));
    let columns = [];
    for (let col of table.columns) {
      columns.push({
        ...col,
        cell_style: style,
      });
    }
    setHeaders(columns);
    setLoading(false);
  }

  const fetchData = useGetPivotTable(viz, filters, onSuccess);

  function sameColumns() {
    const old = ref.current;
    const oldFields = old.designSettings.tablePivotFields;
    const oldOrder = old.designSettings.tablePivotOrder;

    const newFields = viz.designSettings.tablePivotFields;
    const newOrder = viz.designSettings.tablePivotOrder;

    return (
      oldOrder.toString() === newOrder.toString() &&
      JSON.stringify(oldFields) === JSON.stringify(newFields)
    );
  }

  function sameSettings() {
    if (ref.current.pivotString !== viz.pivotString) return false;

    const old = ref.current.designSettings;
    const now = viz.designSettings;

    const list = [
      "split",
      "showUndefined",
      "undefinedLabel",
      "totalRows",
      "totalColumns",
      "scaleByRank",
      "standardScale",
      "zeroHundredScale",
      "showNonParticipating",
    ];

    for (let key of list) {
      if (old[key] !== now[key]) return false;
    }

    return true;
  }

  function sameQs() {
    return (
      ref.current.qIds.toString() ===
      combinedQs(viz)
        .map((q) => q.id)
        .toString()
    );
  }

  useEffect(() => {
    if (ref.current) {
      if (!sameColumns() || !sameSettings() || !sameQs()) {
        fetchData.refetch();
        setLoading(true);
      }
    }

    ref.current = getVizState();
  }, [viz]);

  function getSubtitle() {
    let it = "";

    if (viz.designSettings.split) {
      let answerType = viz.designSettings.answerType;

      if (viz.pivotString === "nps") {
        it = "Passives, Promoters, Detractors";
      } else if (answerType === ParticipationCount) {
        it = "Participation Count";
      } else if (answerType === AnswerCount) {
        it = "Answer Count";
      } else if (answerType === ParticipationCount) {
        it = "Participation Count";
      } else if (answerType === AvgScore) {
        it = "Average Score";
      } else if (answerType === NpsScore) {
        it = "NPS Score";
      }
      if (filterSubtitle) {
        it += " | ";
      }
    }

    if (filterSubtitle) {
      it += filterSubtitle;
    }

    return it;
  }

  const [drill, setDrill] = useState(null);

  function onSegClick(segment, dataset) {
    if (combinedQs(viz).length === 0 || !canSeeContactInfo) {
      // is a new chart with no data
      return;
    }

    if (viz.designSettings.answerType === Ranking) {
      let x = segment;
      segment = dataset;
      dataset = x;
    }

    let setup = {
      segment: segment,
      dataset: dataset,
      split: "",
      pivot: viz.pivotString,
      otherOption: "",
      asRanking:
        viz.designSettings.asRanking && !viz.designSettings.scaleByRank,
    };

    setDrill(setup);
    setShowDrill(true);
  }

  function handleRowClick(obj) {
    var selection = window.getSelection();
    if (!selection.toString()) {
      let segment = viz.designSettings.pivotOptions
        ? obj.option
        : obj[viz.pivotString];
      let dataset = "";
      onSegClick(segment, dataset);
    }
  }

  return (
    <>
      {show && headers && dataArray && !loading ? (
        <TableChart
          initHeaders={headers}
          data={dataArray}
          asChart
          onRowClick={inEdit || !canSeeContactInfo ? undefined : handleRowClick}
          titleStyle={getTitleStyle(viz)}
          tableTitle={viz.designSettings.hasTitle ? viz.title : ""}
          subtitle={
            viz.designSettings.split || filterSubtitle ? getSubtitle() : null
          }
          tableSort={viz.designSettings?.tableSort}
          onSaveSort={saveTableSort}
          inEdit={inEdit}
          titleContainerStyle={getTitleContainerStyle(viz)}
          color={viz.designSettings.tableColor}
          height={height}
          setOutsideData={setOutsideData}
          setUpOutsideDataCounter={setUpOutsideDataCounter}
        />
      ) : (
        <Loading height={height} />
      )}

      {seeData && (
        <>
          <BackendDataTable
            viz={viz}
            filters={filters}
            closeSeeData={closeSeeData}
            custom_fields={custom_fields}
          />
        </>
      )}

      {drill && (
        <BackendDrillTable
          viz={viz}
          filters={filters}
          onClose={() => {
            setDrill(null);
            setShowDrill(false);
          }}
          custom_fields={custom_fields}
          drill={drill}
        />
      )}
    </>
  );
}
