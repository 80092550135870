import React from "react";
import styles from "./Status.module.scss";

function Status({
  children,
  blue,
  gray,
  red,
  sapphire,
  green,
  seafoam,
  yellow,
  orange,
  dullblue,
  style,
  className,
}) {
  return (
    <div
      className={`${styles.status} ${blue && styles.blue} ${
        gray && styles.gray
      } ${red && styles.red} ${sapphire && styles.sapphire} ${
        seafoam && styles.seafoam
      } ${green && styles.green} ${yellow && styles.yellow} ${
        orange && styles.orange
      } ${dullblue && styles.dullblue}  ${className}`}
      style={style}
    >
      {children}
    </div>
  );
}

export default Status;
