import { Label } from "components/layouts/Label/Label";
import styles from "./EditableProgressBar.module.scss";
import styles2 from "../SurveyDesign.module.scss";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import ColorPickerContainer from "components/inputs/input_fields/ColorPicker/ColorPickerContainer";

export default function ProgressSettings({
  settings,
  setSettings,
  numQs,
  numAnswered,
  active,
  setActive,
  close,
}) {
  function changeSetting(setting, value) {
    let tempSettings = { ...settings };
    tempSettings[setting] = value;
    setSettings(tempSettings);
  }

  return (
    <>
      {/* <div
        className={styles.section_label6}
        style={{ margin: "0", width: "fit-content", color: "#15bcc7" }}
      >
        Progress Bar Settings
        <HorizontalBar
          height={2}
          style={{ marginTop: ".1rem" }}
          backgroundColor={"#15bcc7"}
        ></HorizontalBar>
      </div> */}

      <div className={styles2.settingVertical}>
        {/* <div className={styles.label6}>Bar </div> */}
        <div className={styles2.settingVertical}>
          <div className={styles2.settingHorizontal}>
            <ToggleSwitch
              startChecked={settings.showProgBar}
              handleCheck={(val) => changeSetting("showProgBar", val)}
            ></ToggleSwitch>

            <div className={styles.text_3}>Show Progress Bar</div>
          </div>{" "}
          <div className={styles2.settingHorizontal}>
            <ToggleSwitch
              startChecked={settings.showProgQs}
              handleCheck={(val) => changeSetting("showProgQs", val)}
            ></ToggleSwitch>

            <div className={styles.text_3}>Show Question Progress</div>
          </div>{" "}
          <div className={styles2.settingHorizontal}>
            <ToggleSwitch
              startChecked={settings?.progBarSticky}
              handleCheck={(val) => changeSetting("progBarSticky", val)}
            ></ToggleSwitch>

            <div className={styles.text_3}>Sticky Progress Bar</div>
          </div>
        </div>
      </div>

      <div className={styles2.settingVertical}>
        <ColorPickerContainer
          onChange={(color) => changeSetting("progBarColor", color)}
          defaultColor={
            settings.progBarColor ? settings.progBarColor : settings.baseColor
          }
          title="Bar Fill Color"
          //   colors={settings.colorBank}
        />
      </div>
      <div className={styles2.settingVertical}>
        <ColorPickerContainer
          onChange={(color) => changeSetting("progBarEmptyColor", color)}
          defaultColor={
            settings?.progBarEmptyColor
              ? settings.progBarEmptyColor
              : settings?.secondaryColor
              ? settings?.secondaryColor
              : ""
          }
          title="Bar Empty Color"
          //   colors={settings.colorBank}
        />
      </div>

      {settings.showProgQs && (
        <div className={styles2.settingVertical}>
          <ColorPickerContainer
            onChange={(color) => changeSetting("progQsColor", color)}
            defaultColor={
              settings.progQsColor ? settings.progQsColor : "#c0c0c0"
            }
            title="Font Color"
            //   colors={settings.colorBank}
          />
        </div>
      )}
    </>
  );
}
