import styles from "./SurveyCompleted.module.scss";
import Confetti from "react-confetti";
import { useEffect, useState } from "react";
import { SurveyButton } from "components/Survey/SurveyButton/SurveyButton";
import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";

export default function SurveyCompletedInConstruction({
  message,
  returnToSurvey,
  onEdit,
  settings,
  noConfetti,
  canEdit,
}) {
  function getCustomColors() {
    if (settings.confettiColors && settings.confettiColors.length)
      return settings.confettiColors;
    return undefined;
  }

  const [show, setShow] = useState(noConfetti ? false : settings.confetti);

  useEffect(() => {
    if (!noConfetti) {
      if (settings.confetti && !show) {
        setShow(true);
      }
      if (!settings.confetti && show) {
        setShow(false);
      }
      if (settings.confetti && show) {
        setShow(false);
        setTimeout(() => {
          setShow(true);
        }, 200);
      }
    }
  }, [settings.confetti, settings.useOwnColors, settings.confettiColors]);

  return (
    <div
      className={styles.completedContainer}
      style={{
        boxShadow: settings?.finishedBorderShadow
          ? settings?.finishedBorderShadow
          : "",
        borderRadius:
          settings?.finishedBorderRadius || settings?.finishedBorderRadius === 0
            ? settings?.finishedBorderRadius + "rem"
            : "",
        backgroundColor: settings?.finishedBackgroundColor
          ? settings?.finishedBackgroundColor
          : "",
      }}
    >
      <div className={styles.textContainer}>
        <TextEditorTry
          editable={canEdit}
          onSave={(text, code) => onEdit(code)}
          encoding={message ? message : "Thanks so much for your input."}
          defaultStyle={{
            textAlign: "center",
            color: "#616565",
            fontSize: "10pt",
          }}
        />
      </div>
      {settings.confetti && show && (
        <Confetti
          numberOfPieces={150}
          initialVelocityY={{ min: 1, max: 10 }}
          gravity={0.3}
          colors={settings.useOwnColors ? getCustomColors() : undefined}
          recycle={false}
        />
      )}

      {settings.returnable && (
        <SurveyButton
          styleType={settings.buttonStyle}
          color={
            settings.buttonColor ? settings.buttonColor : settings.baseColor
          }
          onClick={returnToSurvey}
          shadow={settings?.buttonShadow}
        >
          <div className={styles.insideButton}>
            <div className={styles.backArrow}>
              <i className="bi bi-arrow-return-left"></i>
            </div>{" "}
            Back to Survey
          </div>
        </SurveyButton>
      )}
      {/* {!settings.returnable && (
        <div className={styles.nonTypicalGoBack} onClick={returnToSurvey}>
          <div className={styles.insideButton}>
            <div className={styles.backArrow}>
              <i className="bi bi-arrow-return-left"></i>
            </div>{" "}
            Back to Survey
          </div>
        </div>
      )} */}
    </div>
  );
}

// Thank you for participating in this survey! <br></br>
//       Go to <a href="https://theresearchcloud.com/">theresearchcloud.com</a> to
//       see the results of the survey you participated in!
