import React, { useEffect, useState } from "react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Label } from "components/layouts/Label/Label";
import styles from "./PageTable.module.scss";
import Button from "components/Button/Button";
import { CSS } from "@dnd-kit/utilities";
import { forEach } from "assets/functions/ArrayFunctions";

export function PageTable({
  numPages,
  currPage,
  setCurrPage,
  settings,
  setSettings,
  allQuestions,
  onDeletePage,
  addPage,
  switchPages,
  status,
}) {
  const initPages = () => {
    let pages = [];
    for (let i = 1; i < numPages + 1; i++) {
      pages.push(i);
    }
    return pages;
  };

  const [pages, setPages] = useState(initPages());
  const [newPage, setNewPage] = useState(true);

  useEffect(() => {
    if (numPages !== pages.length) {
      setPages(initPages());
    }
  }, [numPages]);

  function savePageOrder(order) {
    // updateReport.mutate(
    //   {
    //     data: {
    //       pageOrder: JSON.stringify(order),
    //       isUser: report.userId != null ? true : false,
    //     },
    //     id: report.id,
    //   },
    //   {
    //     onSuccess: () => {
    //       console.log("Order Saved");
    //     },
    //     onError: () => {
    //       console.log("Error Saving Order");
    //     },
    //   }
    // );
  }

  function handleDragEnd({ active, over }) {
    if (!over?.id) {
      return;
    }
    if (over.id === active.id) {
      return;
    }

    switchPages(active.id, over.id);
  }

  return (
    <>
      <div className={styles.headers}>
        <Label style={{ width: "fit-content", paddingLeft: "0" }}>Page</Label>
        <Label style={{ width: "fit-content" }}>#Questions</Label>
      </div>
      <div className={`${styles.pagesTable}`}>
        <table>
          <tbody>
            <DndContext
              // sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            >
              <SortableContext
                id={"Sortable Pages"}
                items={pages}
                strategy={verticalListSortingStrategy}
              >
                {pages.map((page, index) => (
                  <PageRow key={index} page={page} status={status} />
                ))}
              </SortableContext>
            </DndContext>
            <tr onClick={addPage} style={{ cursor: "pointer" }}>
              <td style={{ padding: "10px 40px" }}>
                <div className={styles.addpage}>+ Page</div>
              </td>
            </tr>

            {/* {pages.map((page, index) => (
              <PageRow key={index} page={page} status={status} />
            ))} */}
          </tbody>
        </table>
      </div>
    </>
  );

  function PageRow({ page, status }) {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: page });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      opacity: isDragging ? 0.9 : 1,
      zIndex: isDragging ? 10 : 0,
      width: "100%",
      backgroundColor: currPage === page ? "#E9E9E9" : "",
    };

    const [editName, setEditName] = useState(false);
    // const updateAnalysis = useUpdateAnalysis();

    function editPageName(name) {
      let newSettings = { ...settings };
      if (!("pageSettings" in newSettings)) {
        newSettings.pageSettings = {};
      }
      newSettings.pageSettings[page] = name;
      setSettings(newSettings);
    }

    function getName() {
      if (settings?.pageSettings && page in settings.pageSettings) {
        return settings.pageSettings[page];
      }
      return page;
    }

    function getNumQs() {
      let count = 0;
      forEach(allQuestions, (q) => {
        if (q.pageNumber === page && q.type !== "Description") {
          count++;
        }
      });
      return count;
    }

    return (
      <tr ref={setNodeRef} {...attributes} style={{ ...style }}>
        {!editName && (
          <td className={styles.row}>
            <div
              className={styles.page}
              onClick={currPage === page ? null : () => setCurrPage(page)}
            >
              <div className={styles.name}>{getName()}</div>
              <span>{getNumQs()}</span>
            </div>

            <div className={styles.icons}>
              <i
                className={`bi bi-pencil ${styles.icon}`}
                onClick={() => setEditName(true)}
              ></i>
              {numPages > 1 && (
                <i
                  className={`bi bi-trash3 ${styles.icon}`}
                  onClick={() => onDeletePage(page)}
                ></i>
              )}
              <div className={styles.grid} {...listeners}>
                <i className="bi bi-grip-vertical"></i>
              </div>
            </div>
          </td>
        )}
        {editName && (
          <Input
            onSave={editPageName}
            onExit={() => setEditName(false)}
            initText={getName()}
            saveText="Apply"
          />
        )}
      </tr>
    );
  }

  function Input({ initText, saveText, onExit, onSave }) {
    const [value, setValue] = useState(initText);

    function listenForEnter(e) {
      if (e.key === "Enter" || e.keyCode === 13) {
        onSave(value);
      }
    }

    return (
      <>
        <td>
          <div className={styles.page}>
            <input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className={styles.inputLine}
              onKeyDown={listenForEnter}
            ></input>
          </div>
        </td>
        <td>
          <div className={styles.exitOrSave}>
            <span
              onClick={onExit}
              style={{ fontSize: ".9em", cursor: "pointer" }}
            >
              X
            </span>
            <Button onClick={() => onSave(value)}>{saveText}</Button>
          </div>
        </td>
      </>
    );
  }
}
