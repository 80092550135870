import React from "react";
import styles from "./SurveyDesign.module.scss";
import ColorPickerContainer from "components/inputs/input_fields/ColorPicker/ColorPickerContainer";
import Icon from "components/Icon/Icon";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import {
  ReactionGradientSurveySettings,
  ReactionSimpleSurveySettings,
  ReactionStandardSurveySettings,
} from "assets/functions/Variables";

function Themes({ settings, changeSetting, setSettings }) {
  function changeThemeStandard() {
    setSettings(ReactionStandardSurveySettings);
  }
  function changeThemeSimple() {
    setSettings(ReactionSimpleSurveySettings);
  }
  function changeThemeGradient() {
    setSettings(ReactionGradientSurveySettings);
  }

  return (
    <div className={styles.body}>
      <div
        className={styles.label5}
        style={{ margin: "0", fontWeight: "500", padding: "0 1rem" }}
      >
        Background
      </div>

      <div className={styles.settingVertical}>
        <ColorPickerContainer
          onChange={(color) => changeSetting("backgroundColor", color)}
          defaultColor={
            settings?.backgroundColor ? settings?.backgroundColor : "#f9fbfc"
          }
          title="Background Color"
          //   colors={settings.colorBank}
        />
      </div>
      <div
        className={styles.label5}
        style={{ margin: "0", fontWeight: "500", padding: "0 1rem" }}
      >
        Themes
      </div>
      <div className={styles.settingVertical}>
        <div
          className={styles.label6}
          style={{ margin: "0", fontWeight: "500" }}
        >
          Reaction Standard
        </div>

        <div
          className={`${styles.theme} ${
            settings?.theme === "standard" && styles.active
          }`}
          onClick={
            settings?.theme != "standard" ? changeThemeStandard : undefined
          }
        >
          {settings?.theme === "standard" && (
            <Icon
              iconName={"check-circle"}
              style={{ position: "absolute", right: ".5rem", top: ".5rem" }}
              info
            ></Icon>
          )}
          <img
            src={require("assets/images/reactionlogonew.png")}
            width={80}
          ></img>
          <FlexRow gap={0} start style={{ alignItems: "center" }}>
            {" "}
            <div className={styles.progre}></div>
            <div
              className={styles.section_label7}
              style={{ fontSize: ".4rem", whiteSpace: "nowrap", margin: "0" }}
            >
              Question 1 of 10
            </div>
          </FlexRow>
          <div className={styles.themeq}>
            <div
              className={styles.header7}
              style={{ fontSize: ".4rem", whiteSpace: "nowrap", margin: "0" }}
            >
              Question 1
            </div>
          </div>
          <div className={styles.themeq}>
            <div
              className={styles.header7}
              style={{ fontSize: ".4rem", whiteSpace: "nowrap", margin: "0" }}
            >
              Question 2
            </div>
          </div>
          <div className={styles.themeq}></div>
        </div>
      </div>
      <div className={styles.settingVertical}>
        <div
          className={styles.label6}
          style={{ margin: "0", fontWeight: "500" }}
        >
          Simple
        </div>

        <div
          className={`${styles.theme}  ${
            settings?.theme === "simple" && styles.active
          }`}
          style={{ backgroundColor: "white" }}
          onClick={settings?.theme != "simple" ? changeThemeSimple : undefined}
        >
          {settings?.theme === "simple" && (
            <Icon
              iconName={"check-circle"}
              style={{ position: "absolute", right: ".5rem", top: ".5rem" }}
              info
            ></Icon>
          )}
          <img
            src={require("assets/images/reactionlogonew.png")}
            width={80}
          ></img>
          <FlexRow gap={0} start style={{ alignItems: "center" }}>
            {" "}
            <div className={styles.progre}></div>
            <div
              className={styles.section_label7}
              style={{ fontSize: ".4rem", whiteSpace: "nowrap", margin: "0" }}
            >
              Question 1 of 10
            </div>
          </FlexRow>
          <div className={styles.themen}>
            <div
              className={styles.header7}
              style={{ fontSize: ".4rem", whiteSpace: "nowrap", margin: "0" }}
            >
              Question 1
            </div>
          </div>
          <div className={styles.themen}>
            <div
              className={styles.header7}
              style={{ fontSize: ".4rem", whiteSpace: "nowrap", margin: "0" }}
            >
              Question 2
            </div>
          </div>
          <div className={styles.themen}></div>
        </div>
      </div>
      <div className={styles.settingVertical}>
        <div
          className={styles.label6}
          style={{ margin: "0", fontWeight: "500" }}
        >
          Gradient
        </div>
        <div
          className={`${styles.theme} ${
            settings?.theme === "gradient" && styles.active
          }`}
          style={{
            backgroundColor: "#ffffff",
            backgroundImage:
              "linear-gradient(to bottom right, #ffffff, #7fcfd3)",
          }}
          onClick={
            settings?.theme != "gradient" ? changeThemeGradient : undefined
          }
        >
          {settings?.theme === "gradient" && (
            <Icon
              iconName={"check-circle"}
              style={{ position: "absolute", right: ".5rem", top: ".5rem" }}
              info
            ></Icon>
          )}
          <img
            src={require("assets/images/reactionlogonew.png")}
            width={80}
          ></img>
          <FlexRow gap={0} start style={{ alignItems: "center" }}>
            {" "}
            <div className={styles.progre}></div>
            <div
              className={styles.section_label7}
              style={{ fontSize: ".4rem", whiteSpace: "nowrap", margin: "0" }}
            >
              Question 1 of 10
            </div>
          </FlexRow>
          <div className={styles.themed}>
            <div
              className={styles.header7}
              style={{ fontSize: ".4rem", whiteSpace: "nowrap", margin: "0" }}
            >
              Question 1
            </div>
          </div>
          <div className={styles.themed}>
            <div
              className={styles.header7}
              style={{ fontSize: ".4rem", whiteSpace: "nowrap", margin: "0" }}
            >
              Question 2
            </div>
          </div>
          <div className={styles.themed}></div>
        </div>
        <div className={styles.settingVertical} style={{ padding: "1rem 0" }}>
          <div
            className={styles.label6}
            style={{ margin: "0", fontWeight: "500" }}
          >
            Custom
          </div>

          <div
            className={`${styles.theme} ${
              (settings?.theme === "custom" || !settings?.theme) &&
              styles.active
            }`}
            style={{
              backgroundColor: "#ffffff",
            }}
            onClick={
              settings?.theme != "gradient" ? changeThemeGradient : undefined
            }
          >
            {(settings?.theme === "custom" || !settings?.theme) && (
              <Icon
                iconName={"check-circle"}
                style={{ position: "absolute", right: ".5rem", top: ".5rem" }}
                info
              ></Icon>
            )}
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default Themes;
