import React, { useMemo, useState, useCallback, useEffect } from "react";

import moment from "moment";
import styles from "./ReactionCalendar.module.scss";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { majorHolidays } from "assets/functions/Variables";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "./react-big-calendar.css";
import { useGetDistributionEvents } from "api/resources/projects/distributions";
import { trimDate, trimTime } from "assets/functions/DateFunctions";

function ReactionCalendar({ projectId, title, onClickEvent }) {
  const getEvents = useGetDistributionEvents(projectId);

  return (
    <>
      {getEvents?.isSuccess && (
        <CalendarContainer
          initevents={getEvents?.data?.events}
          title={title}
          onClickEvent={(event) => onClickEvent(event, "event")}
        />
      )}
    </>
  );
}

export default ReactionCalendar;

function CalendarContainer({ initevents, title, onClickEvent }) {
  const localizer = momentLocalizer(moment);

  const DragAndDropCalendar = withDragAndDrop(Calendar);

  const onNavigate = (date) => {
    setViewDate(date);
  };

  const [myEvents, setMyEvents] = useState(
    initevents.map((e) => {
      e.end = new Date(e.end);
      e.start = new Date(e.start);
      return e;
    })
  );
  const [viewDate, setViewDate] = useState(getDefaultDate(myEvents));

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }
      if (allDay && !droppedOnAllDaySlot) {
        event.allDay = false;
      }

      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, allDay: event.allDay }];
      });
    },
    [setMyEvents]
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
    },
    [setMyEvents]
  );

  function getDefaultDate(events) {
    // debugger
    if (events?.length > 0) {
      return events[0]?.start;
    } else {
      return new Date();
    }
  }

  return (
    <div className={styles.calendarContainer}>
      <div className={styles.calendar}>
        <div className={styles.header}>{title}</div>
        <Calendar
          localizer={localizer}
          //   defaultDate={defaultDate}
          //   defaultView={Views.MONTH}
          events={myEvents}
          //   onEventDrop={moveEvent}
          //   onEventResize={resizeEvent}
          popup
          //   resizeable
          onSelectEvent={onClickEvent}
          components={{
            dateCellWrapper: (props) => (
              <CustomDateCellWrapper {...props} viewDate={viewDate} />
            ),
            month: {
              event: CustomEvent,
            },
            week: {
              event: CustomWeekEvent,
            },
            day: {
              event: CustomWeekEvent,
            },
            agenda: {
              event: CustomAgendaEvent,
            },
            // eventWrapper: CustomEventWrapper
          }}
          views={{
            month: true,
            week: true,
            day: true,
            agenda: true,
            work_week: false,
          }}
          //   showAllEvents={true}
          onNavigate={onNavigate}
          date={viewDate}
        />
      </div>
    </div>
  );
}

const CustomEvent = ({ children, event }) => {
  // Custom styling for the event wrapper

  function checkDate(date) {
    let today = new Date();
    return date?.valueOf() < today.valueOf();
  }

  return (
    <div className={checkDate(event?.end) ? styles.eventSent : styles.event}>
      <span>{event?.index}: </span>
      <span>
        {trimTime(event?.start, true)}{" "}
        {event?.end?.valueOf() - event?.start?.valueOf() > 60000 && (
          <>- {trimTime(event?.end, true)}</>
        )}
      </span>
      {/* <span>{event?.index}: {event?.title}</span> */}
    </div>
  );
};

const CustomWeekEvent = ({ children, event }) => {
  // Custom styling for the event wrapper

  function checkDate(date) {
    let today = new Date();
    return date?.valueOf() < today.valueOf();
  }

  return (
    <div
      className={
        checkDate(event?.end) ? styles.weekEventSent : styles.weekEvent
      }
    >
      <span style={{ fontSize: "1em", fontWeight: "500" }}>
        {trimTime(event?.start, true)}{" "}
        {event?.end?.valueOf() - event?.start?.valueOf() > 60000 && (
          <>- {trimTime(event?.end, true)}</>
        )}
      </span>
      <span style={{ fontSize: "1.1em", fontWeight: "700" }}>
        {event?.index}: {event?.title}
      </span>
    </div>
  );
};

const CustomAgendaEvent = ({ children, event }) => {
    // Custom styling for the event wrapper
  
    function checkDate(date) {
      let today = new Date();
      return date?.valueOf() < today.valueOf();
    }
  
    return (
      <div
        className={
          checkDate(event?.end) ? styles.weekEventSent : styles.weekEvent
        }
        style={{cursor: "pointer"}}
      >
        <span style={{ fontSize: "1.1em", fontWeight: "700" }}>
          {event?.index}: {event?.title}
        </span>
      </div>
    );
  };


const CustomDateCellWrapper = ({ children, value, viewDate }) => {
  const [holiday, setHoliday] = useState();
  const isHoliday = (date) => {
    // Define your holiday dates here
    let day = date.getDate();
    let month = date.getMonth();
    let holiday = majorHolidays.find(
      (h) => h.date.getDate() === day && h.date.getMonth() === month
    );
    return holiday ? holiday : "";
  };

  const isWeekend = (date) => {
    let day = date.getDay();
    return day === 0 || day === 6;
  };
  const isToday = (date) => {
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    let today = new Date();
    return (
      today.getDate() === day &&
      today.getMonth() === month &&
      today.getFullYear() === year
    );
  };

  useMemo(() => {
    let holiday = isHoliday(value);
    setHoliday(holiday);
  }, []);

  const isCurrentMonth = (date) => {
    return (
      date.getMonth() === viewDate.getMonth() &&
      date.getFullYear() === viewDate.getFullYear()
    );
  };

  return (
    <div
      className={isCurrentMonth(value) ? styles.cell : styles.cellOut}
      styles={{ borderRight: value.getDay() === 6 ? "none" : "" }}
    >
      {children}
      <div
        className={holiday ? styles.holiday : styles.empty}
        style={{
          backgroundColor: isToday(value)
            ? "#eaf6ff"
            : isWeekend(value)
            ? "#FFEAE7"
            : "",
        }}
      >
        {holiday?.name}
      </div>
    </div>
  );
};
