import React, { useState } from "react";
import styles from "./MFA.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetUserById,
  useUpdateUserById,
} from "api/resources/organization/users";
import Icon from "components/Icon/Icon";
import { Loading } from "components/Loading/Loading";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import reactionLogo from "assets/images/reactionlogonew.png";
import Button from "components/Button/Button";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import { countryphonecodes } from "assets/functions/Variables";
import { useValidateJoinCode } from "api/resources/authentication";

function MFA({ login, userId, finish }) {
  const { id } = useParams();
  const getUser = useGetUserById(id ? id : userId);

  function checkDate(date) {
    if (login) {
      return false;
    }
    if (date) {
      date = new Date(date);
      let now = new Date();
      date.setMinutes(date.getMinutes() + 10);
      if (now <= date) {
        return false;
      }
      return true;
    }
    return false;
  }
  return (
    <div className={styles.page}>
      {getUser.isLoading && <Loading></Loading>}
      {(getUser.isError ||
        checkDate(getUser.data?.getUserById?.MFAVerifiedDate)) &&
        !getUser.isLoading && <div>This page does not exist</div>}
      {getUser.isSuccess &&
        !checkDate(getUser.data?.getUserById?.MFAVerifiedDate) && (
          <Content
            user={getUser.data?.getUserById}
            login={login}
            finish={finish}
          ></Content>
        )}
    </div>
  );
}

export default MFA;

function Content({ user, login, finish }) {
  const updatePhone = useUpdateUserById();
  const [phone, setPhone] = useState(user?.phone);
  const [phoneCode, setPhoneCode] = useState(user?.phoneCode);
  const [editPhone, setEditPhone] = useState(false);
  const [code, setCode] = useState();
  const [loading, setLoading] = useState(false);
  const [validCode, setValidCode] = useState();
  const [MFAVerified, setMFAVerified] = useState(user?.verified); //user?.MFAVerified
  const [invalidCodeMessage, setInvalidCodeMessage] = useState();
  const [newMFA, setNewMFA] = useState(!phone ? true : false);

  if (!user?.verified) {
    updatePhone.mutate(
      {
        id: user?.id,
        data: {
          verified: true,
        },
      },
      {
        onSuccess: () => {
          //send text message with code here
          location.reload();
        },
      }
    );
  }

  const validateCode = useValidateJoinCode();
  function updateUser() {
    updatePhone.mutate(
      {
        id: user?.id,
        data: {
          phone: phone,
          phoneCode: phoneCode ? phoneCode : "+1",
        },
      },
      {
        onSuccess: () => {
          setPhoneCode(phoneCode ? phoneCode : "+1");
          setEditPhone(false);
          setNewMFA(false);
        },
      }
    );
  }

  function validateJoinCode() {
    setLoading(true);
    validateCode.mutate(
      {
        validCodeForUserId: code,
      },
      {
        onSuccess: (data) => {
          setValidCode(data.validCodeForUser);
          setInvalidCodeMessage("Invalid Code");
          if (data.validCodeForUser) {
            updatePhone.mutate(
              {
                id: user?.id,
                data: {
                  MFAVerified: true,
                  MFAVerifiedDate: new Date(),
                },
              },
              {
                onSuccess: () => {
                  setLoading(false);
                  setMFAVerified(true);
                  if (login && !finish) {
                    localStorage.setItem("MFAVerified", true);
                    setTimeout(() => {
                      login();
                    }, 1000);
                  }
                },
              }
            );
          } else {
            setLoading(false);
          }
        },
      }
    );
  }

  const navigate = useNavigate();

  return (
    <>
      <div className={styles.formContainer}>
        <div className={styles.imageContainer}>
          <img src={reactionLogo} />
        </div>
        {(finish || !login) && (
          <>
            <span>
               Account Verification {/*and Multi-Factor setup{" "} */}
              {/* <div className={styles.text_3}>
                Multi-Factor Authentication is now required for all Reaction
                accounts.
              </div> */}
              {/* {newMFA && (
                <div className={styles.text_3}>
                  Add a phone number to setup Multi-Factor Authentication.
                </div>
              )} */}
            </span>
            <FlexRow
              style={{
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "0rem",
              }}
            >
              <FlexRow>
                <FlexRow
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "0rem",
                  }}
                >
                  <div className={styles.text_3}>{user?.email}</div>
                </FlexRow>
                {user?.verified && (
                  <div className={`${styles.header_6} ${styles.verified}`}>
                    Verified{" "}
                    <Icon
                      iconName={"check"}
                      green
                      style={{ fontSize: "1.2rem" }}
                    ></Icon>
                  </div>
                )}
              </FlexRow>
              <FlexRow
                style={{
                  alignItems: editPhone ? "flex-end" : "center",
                }}
              >
                {/* {!editPhone && (
                  <FlexRow
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "0rem",
                    }}
                  >
                    <div className={styles.text_3}>
                      {phoneCode} {phone}{" "}
                      {!MFAVerified && (
                        <Button
                          blue
                          link
                          style={{ fontSize: ".7rem", paddingLeft: ".5rem" }}
                          onClick={() => setEditPhone(true)}
                        >
                          {phone ? "Change Phone Number" : "Add Phone Number"}
                        </Button>
                      )}
                    </div>
                  </FlexRow>
                )} */}

                {/* {editPhone && (
                  <FlexRow style={{ alignItems: "flex-end", gap: ".5rem" }}>
                    <div style={{ width: "100px" }}>
                      <SelectFieldCustom
                        options={countryphonecodes}
                        value={phoneCode ? phoneCode : "+1"}
                        onChange={(f) => setPhoneCode(f.value)}
                        label="Code"
                      ></SelectFieldCustom>
                    </div>

                    <TextFieldSimple
                      id="phoneField"
                      type="tel"
                      value={phone}
                      onChange={setPhone}
                      label="Phone Number"
                    />
                  </FlexRow>
                )} */}
                {/* {user?.verified && !editPhone && !MFAVerified && (
                  <div className={`${styles.header_6} ${styles.pending}`}>
                    Pending{" "}
                    <Icon
                      iconName={"arrow-repeat"}
                      sapphire
                      style={{ fontSize: "1.2rem" }}
                    ></Icon>
                  </div>
                )}
                {user?.verified && !editPhone && MFAVerified && (
                  <div className={`${styles.header_6} ${styles.verified}`}>
                    Verified{" "}
                    <Icon
                      iconName={"check"}
                      green
                      style={{ fontSize: "1.2rem" }}
                    ></Icon>
                  </div>
                )}
                {editPhone && (
                  <Button blue shadow onClick={() => updateUser()}>
                    Save
                  </Button>
                )} */}
              </FlexRow>
            </FlexRow>

            {MFAVerified && (
              <span>
                You are all set! Login to your new account to get the party
                started!
                <br />
                <br />
                <FlexRow
                  style={{
                    justifyContent: "flex-end",
                    width: "100%",
                    marginTop: "1rem",
                  }}
                >
                  <Button blue shadow onClick={() => {navigate("/login"); window.reload();}}>
                    Login
                  </Button>
                </FlexRow>
              </span>
            )}
          </>
        )}
        {/* {((!MFAVerified && phone && !newMFA) || !finish) && (
          <>
            <div className={styles.header_3} style={{ margin: "0" }}>
              Check your phone
            </div>
            <span>
              {login
                ? "We sent a code to your phone number."
                : "We sent you a code to verify your phone number."}{" "}
              <br />
              <br />
              {!loading && (
                <TextFieldSimple
                  label=" Enter the code you recieved below"
                  maxLength="5"
                  value={code}
                  onChange={setCode}
                  valid={validCode}
                  invalidMessage={invalidCodeMessage}
                ></TextFieldSimple>
              )}
              {loading && <Loading height={72}></Loading>}
              <FlexRow
                style={{
                  justifyContent: "flex-end",
                  width: "100%",
                  marginTop: "1rem",
                }}
              >
                <Button
                  blue
                  shadow
                  onClick={validateJoinCode}
                  disable={code?.length != 5}
                >
                  Submit
                </Button>
              </FlexRow>
              <br />
              If you do not recieve a text after 5 minutes click "Resend
              Message" below. <br />
              <br />
              <Button blue link>
                Resend Code
              </Button>
            </span>
          </>
        )} */}
      </div>
    </>
  );
}
