/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";

// GRAPHQL API

export const useFetchContactCount = (id) => {
  const query = gql`
    query Query {
      contactCountByOrgId(id: "${id}")
      audienceCountByOrgId(id: "${id}")
      projectCountByOrgId(id: "${id}")
      userCountByOrgId(id: "${id}")
    }
  `;

  return useGqlQuery(["orgDashCounts:" + id], query, {});
};

export const useValidateEmailAvailable = (email) => {
  const query = gql`
    query Query($email: String!) {
      inUseBy: validateEmailAvailable(email: $email) {
        firstName
        lastName
      }
    }
  `;

  return useGqlQuery(["validate email: " + email], query, { email: email });
};

export const useGetContactsByUnsubscribed = () => {
  const mutation = gql`
    mutation {
      getContactsByUnsubscribed {
        id
        firstName
        lastName
        email
        unsubscribed
        unsubscribedAt
        customField
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("contacts");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useCreateContactGql = () => {
  const mutation = gql`
    mutation CreateContact($data: contactInput!) {
      createContact(data: $data)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("contacts");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useCreateUpload = () => {
  const mutation = gql`
    mutation CreateUpload($data: uploadInput!) {
      createUpload(data: $data) {
        id
        status
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("contacts");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateContactGql = () => {
  const mutation = gql`
    mutation UpdateContact(
      $data: contactUpdate!
      $id: String!
      $attributeData: AttributeUpdate
    ) {
      updateContact(data: $data, id: $id, attributeData: $attributeData) {
        id
        firstName
        lastName
        email
        phone
        prefix
        unsubscribed
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries(["searchContacts"]);
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateContactAttGql = () => {
  const mutation = gql`
    mutation UpdateContact(
      $data: contactUpdate!
      $id: String!
      $attributeData: AttributeUpdate
    ) {
      contact: updateContactSingleAtt(data: $data, id: $id, attributeData: $attributeData) {
        id
        firstName
        lastName
        email
        phone
        prefix
        unsubscribed
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries(["searchContacts"]);
    },
  };

  return useGqlMutation(mutation, options);
};

export const useFetchContact = (id) => {
  const query = gql`
    query Query($id: String!) {
      contact: getContact(id: $id) {
        email
        unsubscribed
        organizationId
        id
        firstName
        customField
        lastName
        participationCount
        unsubscribedSurveys
        prefix
        lastEmailStatus
        attribute {
          id
          name
          customFieldId
        }
      }
    }
  `;

  return useGqlQuery(["contact: " + id], query, {
    id: id,
  });
};

export const useFindContactByEmailGql = () => {
  const mutation = gql`
    mutation Mutation($email: String!) {
      findContactByEmail(email: $email) {
        email
        firstName
        lastName
        phone
        prefix
      }
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useDeleteContactGql = () => {
  const mutation = gql`
    mutation DeleteContact($deleteContactId: String!) {
      deleteContact(id: $deleteContactId)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onSettled: () => {
      queryClient.invalidateQueries(["search"]);
    },
  };

  return useGqlMutation(mutation, options);
};

export const useCreateContactImportGql = () => {
  const mutation = gql`
    mutation CreateContactImport(
      $organization_id: String!
      $user_id: String!
      $clean_contacts: String!
      $bad_contacts: String!
      $duplicates: String!
      $total_warnings: Number!
      $status: String!
      $uploaded_at: String!
      $type: String!
      $audience: String!
    ) {
      createContactimport(
        organization_id: $organization_id
        user_id: $user_id
        clean_contacts: $clean_contacts
        bad_contacts: $bad_contacts
        duplicates: $duplicates
        total_warnings: $total_warnings
        status: $status
        uploaded_at: $uploaded_at
        audience: $audience
        type: $type
      ) {
        id
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("contacts");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useFetchContactImportGql = (id) => {
  const query = gql`
    query {
        getUpload(id: "${id}") {
          id
          organizationId
          totalWarnings
          status
          uploadDate
          audienceId
          type
          appUserId
          appUser {
            firstName
            lastName
          }
          tempcontact {
            id
            firstName
            lastName
            state
            flags
            customField
            email
          }
          num_uploaded
          num_failed
          num_updated
          num_ignored
          num_duplicates
          num_added_to_audience
          progress
          endDate
      }
    }
  `;
  return useGqlQuery(["contactimport", id], query, {});
};

export const useFetchErrorContactsByUpload = (id) => {
  const query = gql`
    query {
      output: getTempContacts(id: "${id}") {
        headers {
          id
          name
          accessor
          canSort
          enabled
          cell_style
          value
          editable
          dataType
        }
        contacts {
          id
          firstName
          lastName
          state
          flags
          customField
          email
        }
      }
    }
  `;
  return useGqlQuery(["temperrorcontactsbyupload" + id, id], query, {});
};

export const useFetchUploadByAudience = (id) => {
  const query = gql`
    query {
      upload: uploadByAudience(id: "${id}") {
          id
          organizationId
          totalWarnings
          status
          uploadDate
          audienceId
          type
          appUserId
          
      }
    }
  `;
  return useGqlQuery(["contactuploadbyaud", id], query, {});
};

export const useFetchContactByUploadId = (
  searchString,
  page,
  perPage,
  sort,
  uploadId
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortSelectionUpload!
      $uploadId: String!
    ) {
      contactsObject: searchContactsByUploadId(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        uploadId: $uploadId
      ) {
        contacts {
          firstName
          lastName
          email
          customField
        }
        count
      }
    }
  `;

  return useGqlQuery(
    ["searchContactUpload" + searchString + page + perPage],
    query,
    {
      search: searchString,
      page: page,
      perPage: perPage,
      sort: sort,
      uploadId: uploadId,
    }
  );
};

export const useFetchContactByUploadIdCount = (
  searchString,
  page,
  perPage,
  sort,
  uploadId
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortSelectionUpload!
      $uploadId: String!
    ) {
      count: searchContactsByUploadIdCount(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        uploadId: $uploadId
      )
    }
  `;

  return useGqlQuery(
    ["searchContactUploadCount" + searchString + page + perPage],
    query,
    {
      search: searchString,
      page: page,
      perPage: perPage,
      sort: sort,
      uploadId: uploadId,
    }
  );
};

export const useSearchUploadByOrg = (
  searchString,
  page,
  perPage,
  sort,
  type
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortSelectionUpload!
      $type: String!
    ) {
      uploads: searchUploadsByOrganization(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        type: $type
      ) {
        id
        organizationId
        badContacts
        duplicateContacts
        totalWarnings
        status
        uploadDate
        audienceId
        type
        appUserId
        appUser {
          id
          firstName
          lastName
          email
        }
        num_uploaded
        num_failed
        num_updated
        num_ignored
        num_duplicates
        progress
        endDate
      }
    }
  `;

  return useGqlQuery(
    ["searchuploads" + searchString + page + perPage + type],
    query,
    {
      search: searchString,
      page: page,
      perPage: perPage,
      sort: sort,
      type: type,
    }
  );
};

export const useCountSearchUploadByOrg = (
  searchString,
  page,
  perPage,
  type
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $type: String!
    ) {
      count: countSearchUploadsByOrganization(
        search: $search
        page: $page
        perPage: $perPage
        type: $type
      )
    }
  `;

  return useGqlQuery(["searchuploadsCount", searchString + type], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    type: type,
  });
};

export const useGetContactUpdates = () => {
  const mutation = gql`
    mutation Mutation(
      $contacts: [String!]!
      $checked: String!
      $orgId: String!
    ) {
      values: getContactUpdateNumbers(
        contacts: $contacts
        checked: $checked
        orgId: $orgId
      ) {
        update
        create
      }
    }
  `;

  return useGqlMutation(mutation, {});
};

export const usefetchContactsFilters = () => {
  const mutation = gql`
    mutation Mutation(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortContact!
      $filters: String!
      $audienceId: String!
      $audienceFilters: String!
    ) {
      contacts: searchContactsFiltersMutation(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        filters: $filters
        audienceId: $audienceId
        audienceFilters: $audienceFilters
      ) {
        count
        contacts {
          id
          firstName
          lastName
          email
          phone
          unsubscribed
          updatedAt
          createdAt
          color
          participationCount
          salesforceId
          lastEmailStatus
          attribute {
            customFieldId
            name
            id
            custom_field {
              id
              name
            }
          }
        }
      }
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useApplyColorContacts = () => {
  const mutation = gql`
    mutation {
      applyColorContacts
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useSearchContactsFiltersCount = (
  searchString,
  filters,
  audienceId,
  audFilters
) => {
  const query = gql`
    query Query(
      $search: String!
      $filters: String!
      $audienceId: String!
      $audienceFilters: String!
    ) {
      count: searchContactsFiltersCount(
        search: $search
        filters: $filters
        audienceId: $audienceId
        audienceFilters: $audienceFilters
      )
    }
  `;

  return useGqlQuery(
    ["searchContactsOGFiltersCount:" + searchString + audienceId],
    query,
    {
      search: searchString,
      filters: filters,
      audienceId: audienceId,
      audienceFilters: audFilters,
    }
  );
};

export const useSearchContact = (searchString, limit, sort) => {
  const query = gql`
    query Query($search: String!, $limit: Float!, $sort: sortContact!) {
      contacts: searchContact(search: $search, limit: $limit, sort: $sort) {
        id
        firstName
        lastName
        email
        customField
        attribute {
          name
          customFieldId
        }
        audience {
          id
        }
      }
    }
  `;

  return useGqlQuery([`search ${limit} contacts: ` + searchString + limit], query, {
    search: searchString,
    limit: limit,
    sort: sort,
  });
};

export const useGetContactsDynamic = (
  rules,
  page,
  perPage,
  sort,
  audienceId
) => {
  const query = gql`
    query Query(
      $rules: RulesInput!
      $page: Float!
      $perPage: Float!
      $sort: sortContact!
      $audienceId: String!
    ) {
      contacts: getContactsDynamic(
        rules: $rules
        page: $page
        perPage: $perPage
        sort: $sort
        audienceId: $audienceId
      ) {
        id
        firstName
        lastName
        email
        new
      }
    }
  `;

  return useGqlQuery(["dynamicContactUploading"], query, {
    rules: rules,
    page: page,
    perPage: perPage,
    sort: sort,
    audienceId: audienceId,
  });
};

export const useAddContactDynamic = () => {
  const mutation = gql`
    mutation Mutation($rules: RulesInput!, $audienceId: String!) {
      success: addContactsDynamic(rules: $rules, audienceId: $audienceId)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(["dynamicContactUploading"]);
    },
  };
  return useGqlMutation(mutation, options);
};

export const useGetContactsDynamicCount = (rules) => {
  const query = gql`
    query Query($rules: RulesInput!) {
      count: getContactsDynamicCount(rules: $rules)
    }
  `;

  return useGqlQuery(["dynamicContactUploadingCount"], query, {
    rules: rules,
  });
};

export const useCheckContactsConnectedSalesforce = (organizationID) => {
  const query = gql`
    query Query($organizationId: String!) {
      count: checkContactsSalesforce(organizationId: $organizationId)
    }
  `;

  return useGqlQuery(["checkcontactssalesforce:" + organizationID], query, {
    organizationId: organizationID,
  });
};

export const useSearchContactsByAudience = (
  searchString,
  page,
  perPage,
  sort,
  id
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortContact!
      $audienceId: String!
    ) {
      contacts: searchContactsByAudience(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        audienceId: $audienceId
      ) {
        firstName
        lastName
        email
        createdAt
        id
        organizationId
        updatedAt
        customField
        participationCount
        unsubscribed
        unsubscribedAt
        unsubscribedSurveys
        color
      }
    }
  `;

  return useGqlQuery(["searchContactsByAudience", searchString], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
    audienceId: id,
  });
};

export const useSearchContactsCountByAudience = (
  searchString,
  page,
  perPage,
  sort,
  id
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortContact!
      $audienceId: String!
    ) {
      count: searchContactsByAudienceCount(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        audienceId: $audienceId
      )
    }
  `;

  return useGqlQuery(["contactsCountsAudience", searchString], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
    audienceId: id,
  });
};

export const useRecieveCSV = () => {
  const mutation = gql`
    mutation Mutation($file: String!) {
      recieveCSV(file: $file)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(["contacts"]);
    },
  };
  return useGqlMutation(mutation, options);
};

export const useUploadContact = () => {
  const mutation = gql`
    mutation Mutation(
      $contactData: contactInput!
      $audienceId: String!
      $upload: uploadUpdate!
    ) {
      transferTempContact(
        contactData: $contactData
        audienceId: $audienceId
        upload: $upload
      )
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(["contacts"]);
    },
  };
  return useGqlMutation(mutation, options);
};

export const deleteLinkedAccount = () => {
  const mutation = gql`
    mutation Mutation($id: String!, $orgId: String!) {
      deleteLinkedAccount(id: $id, orgId: $orgId)
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useAddContact = () => {
  const mutation = gql`
    mutation AddContact(
      $audienceId: String!
      $newColumns: newColumnData!
      $newAttData: newAttData!
      $attributeData: attributeData!
      $contact: contactData!
    ) {
      addContact(
        audienceId: $audienceId
        newColumns: $newColumns
        newAttData: $newAttData
        attributeData: $attributeData
        contact: $contact
      )
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useExportContacts = () => {
  const mutation = gql`
    mutation ExportContacts(
      $headers: headers!
      $settings: exportSettings!
      $audienceFilters: String!
      $audienceId: String!
      $sort: sortContact!
      $filters: String!
      $search: String!
    ) {
      exportContacts(
        headers: $headers
        settings: $settings
        audienceFilters: $audienceFilters
        audienceId: $audienceId
        sort: $sort
        filters: $filters
        search: $search
      ) {
        zippedBase64
        csvString
      }
    }
  `;
  return useGqlMutation(mutation, {});
};
