// External
import React, { useEffect, useState, useMemo } from "react";

// Internal
import {
  usefetchContactsFilters,
  useUpdateContactAttGql,
  useUpdateContactGql,
} from "api/resources/contacts/contacts";
import styles from "./AllContacts.module.scss";
import Modal from "components/ReactModal/ReactModal.jsx";
import { TabPill } from "components/layouts";
import { Manual } from "./NewContacts/manual/Manual";
import { Upload } from "./NewContacts/upload/Upload";
import ContactsSettings from "./ContactsSettings";
import ExternalImport from "../ExternalImport/ExternalImport";
import ContactDetails from "components/Popout/ContactProfile";
import { randomColor } from "components/tables/EditableTable/utils";
import { useFetchOrganizationLinkedAccounts } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import Icon from "components/Icon/Icon";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import ObjectTable from "components/tables/BasicTable/ObjectTable";
import { parseCustomFields } from "assets/functions/ObjectFunctions";
import FieldAdjuster from "./FieldAdjuster";
import ColumnsToDisplay from "./ColumnsToDisplay";
import ImportHow from "./ImportHow";
import { useNavigate } from "react-router-dom";
import EditSingleCustomField from "components/CustomFieldsEditor/EditSingle";
import { AddContact } from "./NewContacts/AddContact/AddContact";
import CreateAudience from "../audiences/CreateAudience";
import { Export } from "./export/Export";

export const AllContacts = ({ user, organization, roles, onExport }) => {
  const MyOrg = useFetchOrganizationLinkedAccounts(organization.id);
  return (
    <div className={styles.pager}>
      {MyOrg.isLoading && <Loading></Loading>}
      {MyOrg.isSuccess && (
        <AllContactsContent
          user={user}
          organization={MyOrg.data?.organization}
          roles={roles}
          customFields={MyOrg.data?.organization?.custom_field}
          onExport={onExport}
        ></AllContactsContent>
      )}
    </div>
  );
};

const AllContactsContent = ({
  user,
  organization,
  roles,
  customFields,
  onExport,
}) => {
  const [activeContact, setActiveContact] = useState();

  const seeContact = (contact) => {
    setActiveContact(contact);
  };

  const obscure = !roles.canSeeContactInfo;
  const [selected, setSelected] = useState([]);
  const [headers, setHeaders] = useState(initHeaders());
  function initHeaders() {
    let heads = [
      // {
      //   id: "salesforceId",
      //   value: "salesforceId",
      //   name: "",
      //   accessor: "salesforceId",
      //   label: "",
      //   // width: 100,
      //   dataType: "text",
      //   enabled: true,
      //   sort: false,
      //   canSort: false,
      //   editable: true,
      //   custom: false,
      //   obscure: obscure,
      //   cell_style: (name, id, i, contact) =>
      //     contact &&
      //     contact?.salesforceId && (
      //       <FlexRow style={{ justifyContent: "center" }}>
      //         <Icon
      //           blue
      //           iconName={"cloud"}
      //           style={{ fontSize: "1.2rem" }}
      //           tooltip={"Synced with Salesforce"}
      //         ></Icon>
      //         {/* <img src={require("assets/images/Salesforce.png")} width={"30px"}></img> */}
      //       </FlexRow>
      //     ),
      // },
      // {
      //   id: "contactName",
      //   value: "contactName",
      //   label: "Contact Name",
      //   // width: 100,
      //   generic: true,
      //   dataType: "text",
      //   enabled: true,
      //   sort: false,
      //   canSort: true,
      //   fullName: true,
      //   custom: false,
      //   icon: "person",
      //   obscure: obscure,
      //   index: 0,
      //   cell_style: (name, id, i, contact) =>
      //     contact && (
      //       <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
      //         <div
      //           className={`${styles.link} ${styles.blueDark}`}
      //           style={{ fontWeight: "500" }}
      //           onClick={() => {
      //             seeContact(contact);
      //           }}
      //         >
      //           {contact && `${contact?.firstName} ${contact?.lastName}`}
      //         </div>
      //       </div>
      //     ),
      // },
      // {
      //   id: "firstName",
      //   value: "firstName",
      //   name: "First Name",
      //   accessor: "firstName",
      //   label: "First Name",
      //   // width: 100,
      //   dataType: "text",
      //   enabled: false,
      //   sort: false,
      //   canSort: true,
      //   editable: true,
      //   custom: false,
      //   obscure: obscure,
      // },
      // {
      //   id: "lastName",
      //   value: "lastName",
      //   name: "Last Name",
      //   accessor: "lastName",
      //   label: "Last Name",
      //   // width: 100,
      //   dataType: "text",
      //   enabled: false,
      //   sort: false,
      //   canSort: true,
      //   editable: true,
      //   custom: false,
      //   obscure: obscure,
      // },
      // {
      //   id: "email",
      //   value: "email",
      //   name: "Email",
      //   accessor: "email",
      //   label: "Email",
      //   // width: 250,
      //   dataType: "text",
      //   enabled: true,
      //   sort: true,
      //   canSort: true,
      //   cell_style: null,
      //   editable: true,
      //   custom: false,
      //   required: true,
      //   unique: true,
      //   icon: "envelope",
      //   obscure: obscure,
      // },
      // {
      //   id: "phone",
      //   value: "phone",
      //   name: "Phone",
      //   accessor: "phone",
      //   label: "Phone",
      //   // width: 250,
      //   dataType: "text",
      //   enabled: true,
      //   sort: true,
      //   canSort: true,
      //   cell_style: null,
      //   editable: true,
      //   custom: false,
      //   icon: "telephone",
      //  obscure: obscure,
      // },
      // {
      //   id: "updatedAt",
      //   value: "updatedAt",
      //   name: "Last Modified",
      //   accessor: "updatedAt",
      //   label: "Last Modified",
      //   // width: 100,
      //   dataType: "text",
      //   enabled: true,
      //   sort: false,
      //   canSort: true,
      //   cell_style: null,
      //   custom: false,
      //   icon: "calendar",
      //   obscure: obscure,
      // },
      // {
      //   id: "unsubscribed",
      //   value: "unsubscribed",
      //   label: "Unsubscribed",
      //   dataType: "text",
      //   enabled: true,
      //   sort: true,
      //   canSort: true,
      //   custom: false,
      //   icon: "person",
      //   obscure: obscure,
      //   properties: [
      //     { value: true, label: "True" },
      //     { value: false, label: "False" },
      //   ],
      //   cell_style: (item) => <div>{item ? "True" : "False"}</div>,
      // },
      // {
      //   id: "participationCount",
      //   value: "participationCount",
      //   name: "Survey Invites",
      //   label: "Survey Invites",
      //   // width: 80,
      //   dataType: "text",
      //   accessor: "participationCount",
      //   enabled: true,
      //   sort: true,
      //   canSort: true,
      //   cell_style: null,
      //   custom: false,
      //   icon: "person",
      //   obscure: obscure,
      //   cell_style: (item) => <div>{!item ? 0 : item}</div>,
      // },
      // {
      //   id: "createdAt",
      //   value: "createdAt",
      //   name: "When Added",
      //   accessor: "createdAt",
      //   label: "When Added",
      //   dataType: "text",
      //   enabled: false,
      //   sort: false,
      //   canSort: true,
      //   cell_style: null,
      //   custom: false,
      //   obscure: obscure,
      //   icon: "calendar",
      // },
    ];

    for (let field of customFields) {
      heads.push({
        id: field?.id,
        value: field?.generic ? field?.name : field?.id,
        label: field?.displayName,
        name: field?.name,
        dataType: field?.dataType,
        enabled: field?.enabled,
        editable: field?.editable,
        custom: true,
        sort: false,
        canSort: false,
        obscure: obscure,
        salesforceColumn: field?.salesforceColumn,
        icon: field?.icon ? field?.icon : "person",
        multiple: field?.multiple,
        delimiter: field?.delimiter,
        generic: field?.generic,
        index: field?.index,
        cell_style: field?.name === "contactName" ? (name, id, i, contact) =>
          contact && (
            <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
              <div
                className={`${styles.link} ${styles.blueDark}`}
                style={{ fontWeight: "500" }}
                onClick={() => {
                  seeContact(contact);
                }}
              >
                {contact && `${contact?.firstName} ${contact?.lastName}`}
              </div>
            </div>
          ) : undefined
      });
    }
    return heads;
  }

  // id: 999999,
  // width: 20,
  // label: "+",
  // disableResizing: true,
  // dataType: "null"

  // function checkFields(contact, field) {
  //   if (contact && "customField" in contact && contact?.customField) {
  //     let fields = JSON.parse(contact.customField);
  //     while (typeof fields === "string") {
  //       fields = JSON.parse(fields);
  //     }

  //     return fields[field.name];
  //   } else {
  //     return "";
  //   }
  // }

  const tabBarItems = [
    {
      id: 0,
      name: "Upload From File",
      // to: `questions`,
    },
    {
      id: 1,
      name: "Manual Entry",
      // to: `design`,
    },
    {
      id: 2,
      name: "External Import",
      // to: `design`,
    },
  ];
  const [sort, setSort] = useState({
    item: "email",
    descend: false,
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSkip, setPageSkip] = useState(0);

  const [perPage, setPerpage] = useState(50);
  const [active, setActive] = useState(0);
  const [chosenFilters, setChosenFilters] = useState([]);

  const [show, setShow] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [maxContacts, setMaxContacts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [adjustFields, setAdjustFields] = useState(false);
  const [displayColumns, setDisplayColumns] = useState(false);
  const [importer, setImporter] = useState(false);
  const [newColumn, setNewColumn] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [createAudience, setCreateAudience] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [first, setFirst] = useState(true);
  // const [refresh, setRef] = useState(false);

  // const getContactCount = useSearchContactsFiltersCount(
  //   searchString,
  //   JSON.stringify(chosenFilters),
  //   "",
  //   "[]"
  // );

  // useEffect(() => {
  //   getContactCount.refetch();
  // }, [chosenFilters]);

  // useEffect(() => {
  //   if (editMode) {
  //     // setHeaders(initEditHeaders());
  //     setHeaders(initHeaders());
  //   } else {
  //     setHeaders(initHeaders());
  //   }
  // }, [editMode]);

  const handleActiveUpdate = (item) => {
    setActive(item);
  };

  const newOptions = [
    {
      onClick: () => setAddContact(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"person-fill"}></Icon> Create a new contact
          </FlexRow>{" "}
          <div className={styles.description2}>
            Create a new contact from scratch with columns.
          </div>
        </div>
      ),
    },
    {
      onClick: () => setImporter(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"people-fill"}></Icon> Import Contacts
          </FlexRow>{" "}
          <div className={styles.description2}>
            Import in bulk with a file or an external account.
          </div>
        </div>
      ),
    },
    // {
    //   onClick: () => console.log(true),
    //   data: (
    //     <div className={styles.bigOption}>
    //       <FlexRow start>
    //         <Icon blue iconName={"table"}></Icon> Add contacts table
    //       </FlexRow>{" "}
    //       <div className={styles.description2}>
    //         Add more than one contact manually a lot easier and faster.
    //       </div>
    //     </div>
    //   ),
    // },
  ];
  const dotOptions = [
    {
      onClick: () => console.log("Import"),
      data: (
        <>
          <Icon sapphire iconName={"cloud-plus"}></Icon> Import
        </>
      ),
    },
    {
      onClick: () => console.log("Export View"),
      data: (
        <>
          <Icon sapphire iconName={"cloud-download"}></Icon> Export View
        </>
      ),
    },
  ];

  const columnOptions = [
    {
      onClick: () => setAdjustFields(!adjustFields),
      data: (
        <>
          <Icon sapphire iconName={"pencil"}></Icon> Edit columns
        </>
      ),
    },
    {
      onClick: () => setDisplayColumns(true),
      data: (
        <>
          <Icon sapphire iconName={"columns"}></Icon> Select columns to display
        </>
      ),
    },
    {
      onClick: () => setNewColumn(true),
      data: (
        <>
          <Icon sapphire iconName={"plus"}></Icon> Create a new column
        </>
      ),
    },
  ];

  function handleSelectPage() {
    let toadd = [];
    let firstIndex = 100;
    for (let c of data) {
      let index = selected.findIndex((i) => i.id === c.id);
      if (index < 0) {
        toadd.push(c);
      } else if (index < firstIndex) {
        firstIndex = index;
      }
    }
    let items = [...selected, ...toadd];
    if (toadd.length === 0) {
      items.splice(firstIndex, data.length);
    }

    setSelected(items);
  }

  const selectedOptions = [
    { onClick: () => handleSelectPage(), data: "Select Page" },
    { onClick: () => setSelected([]), data: "Remove Selected" },
  ];

  const fetchContacts = usefetchContactsFilters();

  useEffect(() => {
    if (first) {
      setLoading(true);
      fetchContacts.mutate(
        {
          search: searchString,
          page: pageSkip,
          perPage: perPage,
          sort: sort,
          filters: JSON.stringify(chosenFilters),
          audienceId: "",
          audienceFilters: "",
        },
        {
          onSuccess: (thedata) => {
            setData(parseCustomFields(thedata?.contacts?.contacts, headers));
            setMaxContacts(thedata?.contacts?.count);
            setLoading(false);
          },
        }
      );
      setFirst(false);
    }
  }, [first]);

  function handleFilterChange(val) {
    setFullLoading(true);
    fetchContacts.mutate(
      {
        search: searchString,
        page: 0,
        perPage: perPage,
        sort: sort,
        filters: JSON.stringify(val),
        audienceId: "",
        audienceFilters: "",
      },
      {
        onSuccess: (thedata) => {
          setData(parseCustomFields(thedata?.contacts?.contacts, headers));
          setChosenFilters(val);
          setPageNumber(1);
          setPageSkip(0);
          setMaxContacts(thedata?.contacts?.count);
          setFullLoading(false);
        },
      }
    );
  }

  function handleSetHeaders(vals) {
    setFullLoading(true);
    fetchContacts.mutate(
      {
        search: searchString,
        page: 0,
        perPage: perPage,
        sort: sort,
        filters: JSON.stringify(chosenFilters),
        audienceId: "",
        audienceFilters: "",
      },
      {
        onSuccess: (thedata) => {
          setData(parseCustomFields(thedata?.contacts?.contacts, vals));
          setHeaders(vals);
          setPageNumber(1);
          setPageSkip(0);
          setMaxContacts(thedata?.contacts?.count);
          setFullLoading(false);
        },
      }
    );
  }

  function handleSearch(val) {
    setFullLoading(true);
    setSearchString(val);
    fetchContacts.mutate(
      {
        search: val,
        page: 0,
        perPage: perPage,
        sort: sort,
        filters: JSON.stringify(chosenFilters),
        audienceId: "",
        audienceFilters: "",
      },
      {
        onSuccess: (thedata) => {
          setData(parseCustomFields(thedata?.contacts?.contacts, headers));
          setPageNumber(1);
          setPageSkip(0);
          setMaxContacts(thedata?.contacts?.count);
          setFullLoading(false);
        },
      }
    );
  }

  function handlePageNum(val) {
    setLoading(true);
    fetchContacts.mutate(
      {
        search: searchString,
        page: (val - 1) * perPage,
        perPage: perPage,
        sort: sort,
        filters: JSON.stringify(chosenFilters),
        audienceId: "",
        audienceFilters: "",
      },
      {
        onSuccess: (thedata) => {
          const newd = parseCustomFields(thedata?.contacts?.contacts, headers);
          setData(newd);
          setData([...data, ...newd]);
          setPageNumber(val);
          setPageSkip((val - 1) * perPage);
          setMaxContacts(thedata?.contacts?.count);
          setLoading(false);
          // setFullLoading(false);
        },
        // onSettled: () => {
        //   setLoading(false);
        // },
      }
    );
  }

  // useEffect(() => {
  //   setFullLoading(true);
  //   fetchContacts.mutate(
  //     {
  //       search: searchString,
  //       page: pageSkip,
  //       perPage: perPage,
  //       sort: sort,
  //       filters: JSON.stringify(chosenFilters),
  //       audienceId: "",
  //       audienceFilters: "",
  //     },
  //     {
  //       onSuccess: (thedata) => {
  //         setData(parseCustomFields(thedata?.contacts?.contacts, headers));

  //         setMaxContacts(thedata?.contacts?.count);
  //         // setLoading(false);
  //         setFullLoading(false);
  //       },
  //     }
  //   );
  // }, [chosenFilters, searchString]);

  const [fullLoading, setFullLoading] = useState(false);

  // useEffect(() => {
  //   setPageNumber(1);
  //   setPageSkip(0);
  // }, [chosenFilters]);

  const navigate = useNavigate();
  const updateContact = useUpdateContactAttGql();

  function saveContact(value, header, rowdata, i) {
    let contactData = {};
    let attributeData = { attributes: [] };
    if (header?.generic === false) {
      let attributes = [];
      if (header?.multiple) {
        attributes = value?.value?.map((a) => {
          return { id: a?.id, name: a?.name, customFieldId: header?.id };
        });
      } else {
        let newAtt = {
          id: value?.id,
          name: value?.value,
          customFieldId: header?.id,
        };
        attributes = [newAtt];
      }
      attributeData = { attributes: attributes };
    }

    if (header?.generic === true) {
      contactData[header?.value] = value?.value;
    }

    updateContact.mutate(
      {
        data: contactData,
        attributeData: attributeData,
        id: rowdata?.id,
      },
      {
        onSuccess: (data) => {
          // setEditName(false);
        },
      }
    );

    // let fields = { ...rowdata };

    // let field = customFields.find((f) => f.name === header.accessor);
    // if (field?.delimiter) {
    //   fields[header.accessor] = "";
    //   for (let option of value) {
    //     if (fields[header.accessor]) {
    //       fields[header.accessor] += field.delimiter;
    //     }
    //     fields[header.accessor] +=
    //       option && typeof option === "object" ? option?.value : option;
    //   }
    // } else {
    //   fields[header.accessor] =
    //     value && typeof value === "object" ? value?.value : value;
    // }

    // let contact = {
    //   firstName: fields.firstName,
    //   lastName: fields.lastName,
    //   unsubscribed:
    //     fields?.unsubscribed && typeof fields.unsubscribed === "object"
    //       ? fields?.unsubscribed?.value
    //       : fields.unsubscribed,
    //   email: fields.email,
    //   id: rowdata.id,
    // };

    // delete fields.firstName;
    // delete fields.lastName;
    // delete fields.email;
    // delete fields.participationCount;
    // delete fields.unsubscribed;
    // delete fields.updatedAt;
    // delete fields.createdAt;
    // delete fields.color;
    // delete fields.customField;
    // delete fields.id;
    // contact.customField = JSON.stringify(fields);
    // let changed = [...changedContacts];
    // let index = changed.findIndex((c) => c.id === rowdata.id);
    // if (index >= 0) {
    //   changed[index] = contact;
    // } else {
    //   changed.push(contact);
    // }
    // setChangedContacts(changed);

    // let tempData = [...data];
    // tempData[i] = { ...rowdata, ...fields, ...contact };
    // setData(tempData);
  }

  return (
    <>
      {/* <div className={styles.settings}>
        <Button onClick={() => setShowSettings(true)} shadow>
          <i className="bi-gear"></i>
        </Button>
      </div> */}

      {/* <ContactTable
        headers={headers}
        setHeaders={setHeaders}
        // maxDataQuery={getContactCount}
        // maxDataQueryAccessor={"count"}
        currPage={pageNumber}
        setCurrPage={setPageNumber}
        maxItems={perPage}
        onRowClick={seeContact}
        sort={sort}
        setSort={setSort}
        pageSkip={pageSkip}
        setPageSkip={setPageSkip}
        perPage={perPage}
        setPerPage={setPerpage}
        searchString={searchString}
        setSearchString={setSearchString}
        searchPlaceholder={"Search Contacts..."}
        onClickActionButton={() => setShow(true)}
        hideActionButton={true}
        chosenFilters={chosenFilters}
        setChosenFilters={setChosenFilters}
        orgId={organization?.id}
        organization={organization}
        editFields={editMode}
        setEditFields={setEditMode}
        customFields={customFields}
        user={user}
        roles={roles}
      ></ContactTable> */}

      <ObjectTable
        data={data}
        headers={headers}
        setHeaders={setHeaders}
        maxPage={
          maxContacts / perPage < 1 ? 1 : Math.ceil(maxContacts / perPage)
        }
        maxItems={maxContacts}
        pageNum={pageNumber}
        setPageNum={handlePageNum}
        perPage={perPage}
        setPerPage={setPerpage}
        sort={sort}
        setSort={setSort}
        // onRowClick={roles.canSeeContactInfo ? seeContact : undefined}
        pageSkip={pageSkip}
        setPageSkip={setPageSkip}
        customFields={customFields}
        user={user}
        roles={roles}
        searchString={searchString}
        setSearchString={handleSearch}
        searchPlaceholder={"Search Contacts..."}
        emptyMessage={"No Contacts"}
        addOptions={newOptions}
        addOptionsHeight={100}
        // dotOptions={dotOptions}
        // dotOptionsHeight={50}
        filters={chosenFilters}
        setFilters={handleFilterChange}
        loading={loading}
        fullLoading={fullLoading}
        select
        selected={selected}
        setSelected={setSelected}
        selectedOptions={selectedOptions}
        selectedOptionsHeight={50}
        allColumns={customFields}
        columnOptions={columnOptions}
        columnOptionsHeight={50}
        exportClick={
          roles.canSeeContactInfo ? () => setShowExport(true) : undefined
        }
        bottomButton={
          <div
            className={styles.link}
            onClick={() => {
              navigate("/previous-imports");
            }}
          >
            View Import Jobs
          </div>
        }
        onCreateAudienceClick={() => setCreateAudience(true)}
        onSaveEditableCell={saveContact}
        // exportOptions={columnOptions}
        // exportOptionsHeight={50}
      ></ObjectTable>

      <Modal
        show={show}
        onClose={() => setShow(false)}
        modalStyle={{
          borderRadius: "1em",
          height: "100%",
          width: "100%",
          maxWidth: "1200px",
        }}
        dark
      >
        <div className={styles.createContactsContainer}>
          <div className={styles.top}>
            <div style={{ maxWidth: "390px", paddingBottom: "10px" }}>
              <div className={styles.header}>Create/Update Contacts</div>
              <div className={styles.desc}>
                Upload a .csv file of your audience. If contact email already
                exists in your organization, then only the fields for that
                contact that you uploaded will be updated.{" "}
                <a>First three headers must be: firstName, lastName, email</a>
              </div>
            </div>
            <div className={styles.pill}>
              <TabPill
                tabBarItems={tabBarItems}
                active={active}
                updateActive={handleActiveUpdate}
                wings
              ></TabPill>
            </div>
          </div>
          {active === 0 && <Upload user={user} />}
          {active === 1 && (
            <div className={styles.manualContainer}>
              <Manual></Manual>
            </div>
          )}
          {active === 2 && (
            <ExternalImport user={user} organization={organization} />
          )}
        </div>
      </Modal>
      <ContactsSettings
        show={showSettings}
        setShow={setShowSettings}
        user={user}
        organizationId={organization?.id}
      ></ContactsSettings>
      {activeContact && (
        <ContactDetails
          onClose={() => {
            setActiveContact(null);
          }}
          contact={activeContact}
          editable={roles?.canCreateEditOrgContacts}
          roles={roles}
        ></ContactDetails>
      )}

      {adjustFields && (
        <FieldAdjuster
          show={adjustFields}
          setShow={setAdjustFields}
          orgId={organization?.id}
          user={user}
          salesforce={organization?.linkedAccounts?.find(
            (a) => a?.accountType === "Salesforce"
          )}
        ></FieldAdjuster>
      )}

      {displayColumns && (
        <ColumnsToDisplay
          show={displayColumns}
          setShow={setDisplayColumns}
          columns={headers}
          setColumns={handleSetHeaders}
        />
      )}

      {createAudience && (
        <Modal
          show={createAudience}
          onClose={() => setCreateAudience(false)}
          modalStyle={{
            borderRadius: ".5rem",
            height: "500px",
            width: "900px",
          }}
          dark
        >
          <CreateAudience
            audience={{}}
            filters={chosenFilters}
            setSettings={setCreateAudience}
          ></CreateAudience>
        </Modal>
      )}

      {importer && (
        <Modal
          show={importer}
          onClose={() => setImporter(false)}
          modalStyle={{
            borderRadius: "1em",
            height: "500px",
            width: "900px",
          }}
          dark
        >
          <ImportHow
            cancel={() => setImporter(false)}
            user={user}
            organization={organization}
            objectColumns={headers}
          ></ImportHow>
        </Modal>
      )}
      {newColumn && (
        <EditSingleCustomField
          onClose={() => setNewColumn(false)}
          field={{}}
          // onSaved={handleSaved}

          brandNew={true}
          // noModal
          // deleteField={handleDeleteField}
        />
      )}
      {addContact && (
        <AddContact onClose={() => setAddContact(false)} org={organization} />
      )}

      {showExport && (
        <Export
          onClose={() => setShowExport(false)}
          org={organization}
          filters={chosenFilters}
          search={searchString}
          sort={sort}
          headers={headers}
          onExport={onExport}
        />
      )}
    </>
  );
};
