/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlMutation, useGqlQuery } from "api/Api";

// GRAPHQL API

export const useFetchSurveyTagsGql = () => {
  const query = gql`
    query Query {
      surveyTagByOrgId {
        id
        label
        value
        description
        displayColor
        project {
          id
          name
          startedAt
        }
      }
    }
  `;

  return useGqlQuery(["tags"], query, {});
};

export const useFetchSurveyTags = (data) => {
  const query = gql`
    query surveyTagsByIds($data: SurveyTagsArray!) {
      tags: surveyTagsByIds(data: $data) {
        id
        label
        value
        description
        displayColor
        project {
          id
          name
          startedAt
          createdAt
          status
          responseCount
        }
      }
    }
  `;

  return useGqlQuery(["tagsByIds: " + data.tagIds.toString()], query, {
    data: data,
  });
};

export const useSearchSurveyTags = (search) => {
  const query = gql`
  query  {
    tags: searchSurveyTags(search: "${search}") {
      description
      displayColor
      id
      label
      project {
        id
        name
        startedAt
      }
    }
  }
  `;

  return useGqlQuery(["searchSurveyTags: " + search], query);
};

export const useGetProjectsFromSurveyTagInTimeFrame = (id, timeFrame) => {
  const query = gql`
    query {
      projects: getProjectsFromSurveyTagInTimeFrame(timeFrame: "${timeFrame}", tagId: "${id}") {
        id
        name
      }
    }
  `;

  return useGqlQuery(["projects from survey tag : " + id + timeFrame], query);
};
