/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2024 natehanson. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson

*/
import React, { useEffect, useState, useRef } from "react";
import styles from "./InteractiveSurveysTable.module.scss";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { Loading } from "components/Loading/Loading";
import Button from "components/Button/Button";
import SurveyProjects from "./SurveyWidgets/SurveyProjects/SurveyProjects";
import {
  useSearchProjectsPages,
  useSearchProjectsPagesCount,
  useSearchSurveys,
} from "api/resources/projects/projects";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import Icon from "components/Icon/Icon";
import { SelectFieldCustom } from "components/inputs";

function InteractiveSurveysTable({
  onRowClick,
  actionButtonText,
  hideActionButton,
  disableActionButton,
  widgetType,
  searchPlaceholder,
  onClickActionButton,
  roles,
  surveys,
  onClickChoose,
  chosen,
}) {
  const [widgetView, setWidgetView] = useState(true);
  const [searchString, setSearchString] = useState("");

  // const [seeMore, setSeeMore] = useState(false);
  // const [chosenStatus, setChosenStatus] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageNum, setPageNum] = useState(
    window.localStorage.getItem("pageNum")
      ? window.localStorage.getItem("pageNum")
      : 1
  );
  const [sort, setSort] = useState({
    item: "createdAt",
    descend: true,
  });

  const recentProjects = useSearchProjectsPages(
    "",
    pageSkip,
    perPage,
    sort,
    "surveys"
  );
  const recentProjectsCount = useSearchProjectsPagesCount(
    "",
    pageSkip,
    perPage,
    sort
  );

  const searchSurveyQuery = useSearchSurveys(searchString, 0, 20, {
    item: "name",
    descend: false,
  });

  useEffect(() => {
    searchSurveyQuery.refetch();
  }, [searchString]);

  useEffect(() => {
    setTimeout(() => {
      recentProjects.refetch();
      recentProjectsCount.refetch();
    }, 250);
  }, [sort]);

  function refetch() {
    recentProjects.refetch();
  }

  return (
    <>
      <div className={styles.tableContainer}>
        {/* The Header*/}

        {/* Recent Surveys*/}

        <div className={`${styles.tableHeader} ${!onClickChoose && styles.moreHeader}`} >
          {!onClickChoose && (
            <div className={styles.header_3} style={{ margin: "0" , color: "black", fontWeight: "400"}}>
              {surveys ? "Surveys" : "Projects"}
            </div>
          )}
          <div
            style={{
              display: "flex",
              gap: "1em",
              flexWrap: "wrap",
              alignItems: "center",
              flexDirection: onClickChoose ? "column" : "",
              width: onClickChoose ? "100%" : "",
            }}
          >
            {onClickChoose && (
              <>
                <div
                  className={styles.header_3}
                  style={{
                    margin: "0",
                    fontWeight: "400",
                    color: "black",
                    marginBottom: "-1rem",
                  }}
                >
                  Surveys
                </div>
                <div
                  className={styles.text_2}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  Copy an existing survey
                </div>
              </>
            )}
            <div
              style={{
                display: "flex",
                gap: "1em",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {!hideActionButton && (
                <Button
                  shadow
                  // blue
                  disable={disableActionButton}
                  onClick={
                    onClickActionButton
                      ? onClickActionButton
                      : () => console.log("click")
                  }
                >
                  {actionButtonText ? actionButtonText : "Click Here "}
                </Button>
              )}

              <div className={styles.searchBox}>
                <NavTextField
                  value={searchString}
                  setValue={(val) => {
                    setSearchString(val);
                  }}
                  placeholder={
                    searchPlaceholder ? searchPlaceholder : "Search..."
                  }
                  items={searchSurveyQuery?.data?.surveys}
                  shadow
                />
                {searchSurveyQuery.isSuccess &&
                  !searchSurveyQuery.isRefetching && (
                    <div className={styles.items}>
                      {searchSurveyQuery?.data?.surveys.map((survey) => (
                        <div
                          className={styles.item}
                          onClick={() => onRowClick(survey)}
                        >
                          <span> {survey?.name}</span>
                          <span> {survey?.status}</span>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>

        {recentProjects.isLoading && <Loading></Loading>}
        {recentProjects.isSuccess && recentProjectsCount.isSuccess && (
          <>
            {widgetType === "survey" && widgetView && (
              <SurveyProjects
                surveys={recentProjects?.data?.surveys}
                onClick={onRowClick}
                draft
                onAdd={onClickActionButton}
                sort={sort}
                setSort={setSort}
                roles={roles}
                refetch={refetch}
                count={recentProjectsCount?.data?.count}
                perPage={perPage}
                setPerPage={setPerPage}
                setPageSkip={setPageSkip}
                pageNum={pageNum}
                setPageNum={setPageNum}
                length={recentProjects?.data?.surveys.length}
                searchString={searchString}
                isCreate={surveys}
                onClickChoose={onClickChoose}
                chosen={chosen}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default InteractiveSurveysTable;

const audienceOptions = [
  { value: "createdAt", label: "Created At" },
  { value: "name", label: "Name" },
  { value: "startedAt", label: "Launched On" },
];

const normaloptions = [
  { value: "createdAt", label: "Created Date" },
  { value: "name", label: "Survey Name" },
  { value: "status", label: "Status" },
  { value: "responseCount", label: "Responses" },
  { value: "startedAt", label: "Launched On" },
  { value: "updatedAt", label: "Modified Date" },
  { value: "custom", label: "Custom Order" },
];

const personoptions = [
  { value: "createdAt", label: "Created Date" },
  { value: "firstName", label: "First Name" },
  { value: "lastName", label: "Last Name" },
  { value: "updatedAt", label: "Modified Date" },
];

export function Sorting({ sort, setSort, audience, person, fields }) {
  const [open, setOpen] = useState(false);
  const [options] = useState(() => {
    if (audience) {
      return audienceOptions;
    }
    if (person) {
      return personoptions;
    }
    return normaloptions;
  });

  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      setOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef(null);

  return (
    <div
      className={styles.sortingContainer}
      onClick={() => setOpen(true)}
      ref={ref}
    >
      <div className={styles.sortingContent}>
        {sort.descend ? (
          <Icon
            iconName={"sort-alpha-down-alt"}
            style={{ fontSize: "1.2rem" }}
            blue
          ></Icon>
        ) : (
          <Icon
            iconName={"sort-alpha-up"}
            style={{ fontSize: "1.2rem" }}
            blue
          ></Icon>
        )}
        <span>
          {" "}
          {fields
            ? fields.find((item) => item.value === sort.item)?.label
            : options.find((item) => item.value === sort.item)?.label}
        </span>
      </div>
      {open && (
        <div className={styles.sortSettings}>
          {/* <div
            className={styles.close}
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
          >
            <i className="bi-x-lg"></i>
          </div> */}
          {/* <div className={styles.header_4}>Sort By</div> */}
          <SelectFieldCustom
            options={fields ? fields : options}
            value={
              fields
                ? fields.find((item) => item.value === sort.item)
                : options.find((item) => item.value === sort.item)
            }
            onChange={(value) => {
              setSort({ ...sort, item: value?.value });
              setOpen(false);
            }}
            style={
              {
                // backgroundColor: "white",
                // border: "2px solid lightgray",
                // borderRadius: ".5em",
              }
            }
            label={"Sort By"}
            select
          />

          <div className={styles.descend}>
            <div
              className={`${styles.descendBox} ${!sort.descend && styles.fill}`}
              onClick={() => setSort({ ...sort, descend: false })}
            >
              Ascending
            </div>
            <div
              className={`${styles.descendBox} ${sort.descend && styles.fill}`}
              onClick={() => setSort({ ...sort, descend: true })}
            >
              Descending
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
