import { useEffect, useState, useRef } from "react";
import styles from "../SettingsAccordion.module.scss";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import {
  DoughnutType,
  HorizBarType,
  LineType,
  NoSort,
  PieType,
} from "../../QuestionChart";
import { emailBars, statBars } from "../../StatChart/StatChart";
import TextEditorPlain from "components/inputs/input_fields/TextEditorPlain/TextEditorPlain";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { ChartLabel } from "../Tools";

export function StatSplitsNPivotsModal({
  viz,
  updateViz,
  close,
  projects,
  custom_fields,
  originalColors,
}) {
  const ref = useRef();

  function clickOut(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      close();
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOut, true);
    return () => {
      document.removeEventListener("click", clickOut, true);
    };
  }, []);

  return (
    <div
      className={styles.body}
      style={{ borderRadius: "1em", minHeight: "200px" }}
      ref={ref}
    >
      <div
        key={viz.id}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div key={"filters"} className={`${styles.title}`}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
              padding: "0rem 1rem",
            }}
          >
            Splits & Pivots{" "}
            <i
              style={{ fontSize: "1.2em" }}
              className="bi-person-list-fill"
            ></i>
          </div>
        </div>
        <span className={styles.xicon} onClick={close}>
          <i className="bi-x-lg"></i>
        </span>
      </div>

      <StatSplitsNPivots
        viz={viz}
        updateViz={updateViz}
        projects={projects}
        custom_fields={custom_fields}
        originalColors={originalColors}
      />
    </div>
  );
}

export function StatSplitsNPivotsAccordian({
  viz,
  updateViz,
  projects,
  custom_fields,
  originalColors,
  visible,
  setVisible,
}) {
  function getPivotCount() {
    let count = 0;
    if (viz.pivotString) {
      count += 1;
    }
    if (viz.designSettings.split) {
      count += 1;
    }
    return count;
  }

  return (
    <div
      key={viz.id}
      className={`${styles.header} ${visible ? styles.headervisible : ""}`}
      onClick={!visible ? setVisible : undefined}
    >
      <FlexRow
        onClick={visible ? setVisible : undefined}
        style={{ cursor: "pointer" }}
        className={styles.accordionTitle}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <span>Splits & Pivots</span>
          <i style={{ fontSize: "1.2em" }} className="bi-person-lines-fill"></i>
          {getPivotCount() > 0 && (
            <HorizontalBar rotate height={15} width={2}></HorizontalBar>
          )}
          {getPivotCount() > 0 && (
            <div className={styles.number}>{getPivotCount()}</div>
          )}
        </div>

        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </FlexRow>

      {visible && (
        <StatSplitsNPivots
          viz={viz}
          updateViz={updateViz}
          projects={projects}
          custom_fields={custom_fields}
          originalColors={originalColors}
        />
      )}
    </div>
  );
}

export function StatSplitsNPivots({
  viz,
  updateViz,
  projects,
  custom_fields,
  originalColors,
}) {
  const [split, setSplit] = useState(viz.designSettings.split);
  const [pivot, setPivot] = useState(viz.pivotString);

  function changeAnswerType(newViz, bar) {
    newViz.designSettings.answerType = bar.value;

    if (newViz.designSettings.autoGenAxisTitles) {
      newViz.designSettings.YAxisTitle = bar.label;
    }

    newViz.designSettings.byPercentOfTotal = false;
    newViz.designSettings.byPercent = false;
    newViz.designSettings.dataLabelPercent.show = false;

    newViz.designSettings.graphMax = null;
    newViz.designSettings.graphMin = null;
  }

  function handleCalculateChange(bar) {
    let newViz = { ...viz };
    changeAnswerType(newViz, bar);
    updateViz(newViz);
  }

  const surveyOptions = [
    { displayName: "survey", name: "survey" },
    { displayName: "survey date", name: "survey date" },
    { displayName: "survey quarter", name: "survey quarter" },
  ];

  const getOptions = () => {
    let options = custom_fields
      .filter((f) => f.filterable !== false)
      .map((f) => {
        return { displayName: f.displayName, name: f.name };
      });

    if (projects.length > 1) {
      options = [...options, ...surveyOptions];
    }
    // if (viz.tagIdsArray.length > 1) {
    //   options.push({ displayName: "survey tag", name: "survey tag" });
    // }

    // options.push({ displayName: "month taken", name: "month taken" });
    options.push({ displayName: "Email Sends", name: "email" });

    return options;
  };

  const [options, setOptions] = useState(getOptions());

  function capitalize(title) {
    return title.charAt(0).toUpperCase() + title.slice(1);
  }

  function resetSegLabels(newViz) {
    if (newViz.designSettings.colors) {
      newViz.designSettings.colors = undefined;
    }

    if (
      originalColors &&
      originalColors.split === newViz.designSettings.split &&
      originalColors.pivot === newViz.pivotString
    ) {
      newViz.designSettings.colors = [...originalColors.colors];
    }

    newViz.designSettings.segLabelReplacements = {};
    newViz.designSettings.axisLabelReplacements = {};
  }

  function checkForAnswerType(newViz) {
    if (
      newViz.pivotString &&
      newViz.designSettings.split &&
      !newViz.designSettings.answerType
    ) {
      const defaultBar = statBars.find((b) => b.value === "participated");
      changeAnswerType(newViz, defaultBar);
    }
  }

  const changePivot = (field) => {
    let newViz = { ...viz };
    newViz.pivotString = field;
    newViz.designSettings.stacked = false;

    if (field) {
      addLegend(newViz);
    } else if (!newViz.designSettings.split) {
      removeLegend(newViz);
    }

    if (newViz.designSettings.autoGenAxisTitles) {
      genStatAxisTitles(newViz);
    }

    if (
      (newViz.type === DoughnutType || newViz.type === PieType) &&
      newViz.designSettings.hasLegend
    ) {
      if (field) {
        newViz.designSettings.legendTitle = capitalize(field);
        newViz.designSettings.hasLegendTitle = true;
      } else {
        newViz.designSettings.hasLegendTitle = false;
        newViz.designSettings.legendTitle = "";
      }
    }

    setPivot(field);
    setSplit(newViz.designSettings.split);
    resetSegLabels(newViz);
    checkForAnswerType(newViz);

    updateViz(newViz);
  };

  function changeSplit(e, field) {
    let newViz = { ...viz };
    if (e.target.checked) {
      newViz.designSettings.split = field;
      addLegend(newViz);
    } else {
      newViz.designSettings.split = "";

      if (!newViz.pivotString) {
        removeLegend(newViz);
      } else {
        addLegend(newViz);
      }

      if (newViz.designSettings.includeOverall)
        newViz.designSettings.includeOverall = false;
    }
    newViz.designSettings.sortData = NoSort;

    if (newViz.designSettings.autoGenAxisTitles) {
      genStatAxisTitles(newViz);
    }

    setSplit(newViz.designSettings.split);
    resetSegLabels(newViz);
    checkForAnswerType(newViz);

    updateViz(newViz);
  }

  function addLegend(newViz) {
    newViz.designSettings.hasLegend = true;
    newViz.designSettings.hasLegendTitle = true;

    if (newViz.designSettings.useComparison) {
      newViz.designSettings.legendTitle = "";
    } else if (newViz.designSettings.split) {
      if (newViz.designSettings.split === "survey quarter") {
        newViz.designSettings.legendTitle = "Quarter";
      } else if (newViz.designSettings.split === "month taken") {
        newViz.designSettings.legendTitle = "Month";
      } else if (newViz.designSettings.split === "hour taken") {
        newViz.designSettings.legendTitle = "Day";
      } else {
        newViz.designSettings.legendTitle = capitalize(
          newViz.designSettings.split
        );
      }
    } else {
      newViz.designSettings.legendTitle = "";
      newViz.designSettings.hasLegendTitle = false;
    }
  }

  function removeLegend(newViz) {
    if (!newViz.designSettings.useComparison) {
      newViz.designSettings.hasLegend = false;
      newViz.designSettings.hasLegendTitle = false;
      newViz.designSettings.legendTitle = "";
    }
  }

  function changeBars(bar, value) {
    let bars = [...viz.designSettings.showBars];

    if (value) {
      let ind = 0;
      for (let stat of statBars) {
        if (stat.value === bar.value) {
          break;
        }
        if (bars.some((b) => b.value === stat.value)) {
          ind++;
        }
      }
      bars.splice(ind, 0, { ...bar });
    } else {
      let ind = bars.findIndex((b) => b.value === bar.value);
      bars.splice(ind, 1);
    }

    let copy = { ...viz };
    copy.designSettings.showBars = bars;
    resetSegLabels(copy);
    updateViz(copy);
  }

  function handleDragOver({ active, over }) {
    if (active.id !== over?.id) {
      let copy = { ...viz };
      let bars = copy.designSettings.showBars;
      let indexOfActive = bars.findIndex((b) => b.value === active.id);
      let indexOfOver = bars.findIndex((b) => b.value === over.id);
      if (indexOfActive > -1 && indexOfOver > -1) {
        let activeBar = bars[indexOfActive];
        let overBar = bars[indexOfOver];
        bars.splice(indexOfActive, 1, overBar);
        bars.splice(indexOfOver, 1, activeBar);
        updateViz(copy);
      }
    }
  }

  const circleKind = viz.type === DoughnutType || viz.type === PieType;

  const splitAndPivot = viz.pivotString && viz.designSettings.split;

  const byEmail =
    viz.pivotString === "email" || viz.designSettings.split === "email";

  function showEmailLineUps() {
    if (projects.length > 1) {
      return true;
    }
    if (
      viz.designSettings.useComparison &&
      (viz.comparison?.dynamics.length || viz.comparison?.projectIds.length)
    ) {
      return true;
    }
    return false;
  }

  return (
    <div className={styles.body} style={{ gap: "10px" }}>
      {!splitAndPivot && (
        <FlexCol>
          <ChartLabel big>
            {`Show a ${
              viz.designSettings.split
                ? "pivot"
                : circleKind
                ? "slice"
                : viz.type === LineType
                ? "line"
                : "bar"
            } for`}
          </ChartLabel>
          <div className={styles.pivots}>
            {statBars.map((bar) => (
              <BarOption
                bar={bar}
                viz={viz}
                changeBars={changeBars}
                updateViz={updateViz}
              />
            ))}
          </div>
        </FlexCol>
      )}

      <FlexCol>
        <ChartLabel big>
          {`Pivot (${viz.type === HorizBarType ? "y" : "x"} axis) by`}
        </ChartLabel>

        <div className={styles.pivots}>
          {options.map((field) => (
            <div
              className={`${styles.item} ${
                field.name === split ? styles.disabled : ""
              }`}
            >
              <Checkbox
                checked={pivot === field.name}
                onChange={(e) =>
                  changePivot(e.target.checked ? field.name : "")
                }
                disabled={field.name === split}
              ></Checkbox>
              <span>{field.displayName}</span>
            </div>
          ))}
        </div>
      </FlexCol>

      {splitAndPivot && (
        <div
          className={`${styles.answerTypesContainer} ${styles.optionsActive}`}
        >
          <ChartLabel big>Calculate</ChartLabel>
          <div className={styles.pivots}>
            {(byEmail
              ? statBars.filter((b) => emailBars.includes(b.value))
              : statBars
            ).map((bar) => {
              let includedBar = viz.designSettings.showBars.find(
                (b) => b.value === bar.value
              );

              return (
                <div className={styles.pivotRadio}>
                  {includedBar ? includedBar.label : bar.label}
                  <input
                    id={bar.value}
                    type="radio"
                    name="answerType"
                    value={bar.value}
                    onChange={() => handleCalculateChange(bar)}
                    checked={viz.designSettings.answerType === bar.value}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* {byEmail && showEmailLineUps() && (
        <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          <Label
            style={{
              fontWeight: "700",
              // width: "fit-content",
              fontSize: ".9em",
            }}
          >
            Line up emails
          </Label>
          <div className={styles.setting2} style={{ paddingLeft: "1.5em" }}>
            <ToggleSwitch
              startChecked={!viz.designSettings.lineUpByName}
              handleCheck={(val) => changeSettingsField("lineUpByName", !val)}
            ></ToggleSwitch>
            <Label
              style={{
                fontWeight: "600",
                width: "fit-content",
                fontSize: ".85em",
              }}
            >
              by name
            </Label>
          </div>

          <div className={styles.setting2} style={{ paddingLeft: "1.5em" }}>
            <ToggleSwitch
              startChecked={!viz.designSettings.lineUpByName}
              handleCheck={(val) => changeSettingsField("lineUpByName", !val)}
            ></ToggleSwitch>
            <Label
              style={{
                fontWeight: "600",
                width: "fit-content",
                fontSize: ".85em",
              }}
            >
              by send
            </Label>
          </div>
        </div>
      )} */}

      <FlexCol>
        <ChartLabel big>Split by</ChartLabel>
        <div className={styles.pivots}>
          {options.map((field) => (
            <div
              className={`${styles.item} ${
                field.name === pivot ? styles.disabled : ""
              }`}
            >
              <Checkbox
                checked={split === field.name}
                onChange={(e) => changeSplit(e, field.name)}
                disabled={field.name === pivot}
              ></Checkbox>
              <span>{field.displayName}</span>
            </div>
          ))}
        </div>
      </FlexCol>
    </div>
  );
}

function BarOption({ bar, viz, changeBars, updateViz }) {
  // const {
  //   attributes,
  //   listeners,
  //   setNodeRef,
  //   transform,
  //   transition,
  //   isDragging,
  // } = useSortable({ id: bar.value });

  // const style = {
  //   transform: CSS.Transform.toString(transform),
  //   transition,
  //   opacity: isDragging ? 0.9 : 1,
  //   zIndex: isDragging ? 10 : 0,
  //   // backgroundColor: highlight ? "#efefef" : show ? "#F9F9F9" : undefined,
  // };

  let includedBar = viz.designSettings.showBars.find(
    (b) => b.value === bar.value
  );

  function changeLabel(label) {
    let ind = viz.designSettings.showBars.findIndex(
      (b) => b.value === bar.value
    );
    let copy = { ...viz };
    copy.designSettings.showBars[ind].label = label ? label : bar.label;
    updateViz(copy);
  }

  const email = viz.pivotString === "email" || viz.split === "email";

  const excluded = email && !emailBars.includes(bar.value);

  return (
    <div
      className={`${styles.bar}`}
      // ref={setNodeRef}
      // {...attributes}
      // style={style}
    >
      <Checkbox
        checked={excluded ? false : includedBar ? true : false}
        onChange={(e) => changeBars(bar, e.target.checked)}
        // color={excluded ? "#eee" : undefined}
        disabled={excluded}
      ></Checkbox>
      {includedBar && !excluded ? (
        <TextEditorPlain
          text={includedBar.label}
          onSave={(val) => changeLabel(val)}
          editable={true}
          onEnter={(e) => e.target.blur()}
          extraClass={styles.barLabelInput}
        />
      ) : (
        <span className={`${styles.barLabel} ${excluded ? styles.grayed : ""}`}>
          {bar.label}
        </span>
      )}

      {/* {includedBar && (
        <div
          className={styles.barGrid}
          {...listeners}
          style={{ cursor: isDragging ? "grabbing" : "grab" }}
        >
          <i
            className="bi bi-list"
            // style={{
            //   color: `${fields[field].show ? "#616565" : "#d8d9d9"}`,
            // }}
          ></i>
        </div>
      )} */}
    </div>
  );
}

export function genStatAxisTitles(newViz) {
  let pivot = newViz.pivotString;

  if (pivot) {
    if (pivot === "survey date") {
      newViz.designSettings.XAxisTitle = "Survey Date";
    } else if (pivot === "survey quarter") {
      newViz.designSettings.XAxisTitle = "Quarter";
    } else if (pivot === "month taken") {
      newViz.designSettings.XAxisTitle = "Month";
    } else if (pivot === "hour taken") {
      newViz.designSettings.XAxisTitle = "Day";
    } else {
      newViz.designSettings.XAxisTitle =
        pivot.charAt(0).toUpperCase() + pivot.slice(1);
    }
  } else {
    newViz.designSettings.XAxisTitle = "";
  }

  if (
    pivot &&
    newViz.designSettings.split &&
    newViz.designSettings.answerType
  ) {
    let answerType = newViz.designSettings.answerType;
    let bar = newViz.designSettings.showBars.find(
      (b) => b.value === answerType
    );
    if (bar) {
      newViz.designSettings.YAxisTitle = bar.label;
    } else {
      newViz.designSettings.YAxisTitle = statBars.find(
        (b) => b.value === answerType
      ).label;
    }
  } else {
    newViz.designSettings.YAxisTitle = "Count";
  }
}
