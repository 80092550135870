import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import styles from "./SurveyResults.module.scss";
import { useFetchQuestionsSimpleGql } from "api/resources/projects/questions";
import { useNavigate, useParams } from "react-router-dom";
import {
  useFetchProjectGql,
  useGetSurveyStats,
  useUpdateProjectDashProperties,
} from "api/resources/projects/projects";
import { Loading } from "components/Loading/Loading";
import Chart from "components/Charts/Chart";
import { defaultColors } from "pages/results/Charts/QuestionChart";
import Button from "components/Button/Button";
import DownloadView from "components/DownloadView/DownloadView";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
// import ReactModal from "components/ReactModal/ReactModal.jsx";
import Table from "pages/results/Charts/Tables/Table";
import { useCopyReport } from "api/resources/organization/reports";
import { routeVariants } from "assets/functions/Variables";
import Visualization from "pages/results/Charts/Visualization";
import { TabBar } from "components/layouts";
import { CustomReport } from "pages/results";

function getStyle() {
  let size = "";
  let thumbnail = false;
  if (thumbnail) {
    size = ".85em";
  } else {
    if (window.innerWidth < 900) {
      size = "14" + "px";
    } else if (window.innerWidth > 1400) {
      size = "16px";
    } else {
      size = "1vw";
    }
  }

  let alignment = "center";
  let align = "";
  alignment === "start"
    ? (align = "left")
    : alignment === "end"
    ? (align = "right")
    : (align = "center");
  return {
    fontSize: size,
    textAlign: align, // broken right now
    maxWidth: thumbnail ? "100%" : "90%",
  };
}

const menuItems = [
  {
    id: 0,
    name: "Questions",
    // to: "messages",
    icon: "list-ol",
  },
  // {
  //   id: 0,
  //   name: "Overview",
  //   // to: "survey-link",
  //   icon: `binoculars`,
  // },

  // {
  //   id: 2,
  //   name: "Question Insights",
  //   // to: `personal-links`,
  //   icon: "lightbulb",
  // },
];

function SurveyResults({ roles, direction, org, user, collapse, collapsed }) {
  const { id, reportid } = useParams();

  const fetchProjectQuery = useFetchProjectGql(id);
  const [active, setActive] = useState(0);

  return (
    <motion.div
      variants={routeVariants}
      custom={{ direction, animate: true }}
      initial="enter"
      animate="center"
      exit="exit"
      className={styles.page}
    >
      <div className={styles.nav}>
        <TabBar
          tabBarItems={menuItems}
          active={active}
          updateActive={setActive}
        />
      </div>
      {reportid === "null" && fetchProjectQuery.isSuccess && (
        <div className={styles.text}>
          {!fetchProjectQuery.data.survey.isProgram
            ? "Survey is a draft. Open the survey and start sending it to people in order to see results on this page!"
            : "Launch your first iteration in order to see the results"}
        </div>
      )}
      {reportid != "null" && (
        <CustomReport
          collapse={collapse}
          basic
          user={user}
        ></CustomReport>
      )}
    </motion.div>
  );
}

function SurveyResult({ roles, direction }) {
  const { id, reportid } = useParams();

  const questionsQuery = useFetchQuestionsSimpleGql(id);
  const statsQuery = useGetSurveyStats(id);
  const fetchCustomFields = useFetchCurrOrgsFields();
  const fetchProjectQuery = useFetchProjectGql(id);

  const [download, setDownload] = useState(false);
  const [show, setShow] = useState(false);
  const [showData, setShowData] = useState([]);
  const [showCopy, setShowCopy] = useState(false);

  const defaultSettings = {
    hasDataLabels: true,
    dataLabelValue: {
      show: true,
      color: "#616565",
      position: "end",
      alignment: "end",
      offset: 0,
      sigFigs: 0,
      fontSize: 14,
    },
    dataLabelLabel: {
      show: false,
    },
    dataLabelPercent: {
      show: false,
    },
    hasTitle: true,
    hasSubtitle: true,
    artificialTitle: true,
    titleFontSize: 18,
    titleAlignment: "center",
    hasLegend: false,
    hasLegendTitle: false,
    legendTitle: "",
    legendPosition: "top",
    legendFontSize: 14,
    legendPointStyle: true,
    legendLabel: true,
    legendValue: false,
    legendPercent: false,
    drawXLines: false,
    drawYLines: false,
    colors: defaultColors,
    solidateSegments: false,
    currPalletId: "default",
    lineWidth: 1,
    pointRadius: 3,
    lineGraphWidth: 3,
    lineGraphColor: "#2A627C",
    uniformPointColor: "#2A627C",
    hasUniformPointColor: true,
    sortData: "none",
    barPercentage: 0.5,
    cutout: 50,
    byPercent: false,
    answerType: "answer count",
    stacked: false,
    hasAxisTitles: false,
    autoGenAxisTitles: true,
    YAxisTitle: "Answer Count",
    XAxisTitle: "Choices",
    axisTitleFontSize: 12,
    tableFields: {
      firstName: { name: "first name", show: true },
      lastName: { name: "last name", show: true },
      email: { name: "email", show: false },
    },
    tableOrder: ["firstName", "lastName", "email"],
    tablePivotFields: {},
    tablePivotOrder: [],
    valueType: "answercount",
    split: "",
    lineTension: 0.3,
    graphMax: null,
    graphMin: null,
    undefinedLabel: "Undefined",
    zoomOut: 0,
    segLabelReplacements: {},
    textboxes: [
      // { top: "10%", left: "5%", text: "Avg Score: 5", color: "gray", backgroundColor: "white" },
      // { top: "10%", left: "95%", text: "Avg Score: 10", color: "gray", backgroundColor: "white" },
    ],
    showUndefined: true,
    showN: false,
    NLabel: "N",
    useTagColor: false,
    showNonParticipating: false,
    afterSort: false,
    hideNonChosen: false,
    dynamic: false,
    userConstraints: [],
    showFilterSubtitle: true,
    sigFigs: 0,
    hideXticks: false,
    hideYticks: true,
    hideTicks: true,
    hideBorder: true,
    borderRadius: 5,
    borderSkipped: false,
  };

  const copyReport = useCopyReport();
  let navigate = useNavigate();
  const routeChange = (path) => {
    // console.log(path);
    navigate(path);
  };

  function analyzeFurther() {
    copyReport.mutate(
      {
        id: reportid,
      },
      {
        onSuccess: (data) => {
          console.log(data);
          localStorage.setItem("activepage", 2);
          localStorage.removeItem("activeprojecttab");
          localStorage.removeItem("activecontactstab");
          localStorage.removeItem("activeorgtab");
          localStorage.removeItem("activeaccounttab");
          localStorage.removeItem("activeresultstab");
          localStorage.removeItem("activeanalysistab");
          localStorage.removeItem("activesurveystab");
          localStorage.removeItem("reportspageitem");
          localStorage.removeItem("activeaudiencetab");
          window.localStorage.removeItem("page1");
          window.localStorage.removeItem("page2");
          window.localStorage.removeItem("page3");
          routeChange("/reports/" + data.copyReport.id);
        },
      }
    );
  }

  //   function getData(tag) {

  //     let final = {};
  //     final.answers = answers;
  //     final.segment = tag;
  //     final.question = question;
  //     setShowData(final);

  //     setShow(true);
  //   }

  return (
    <motion.div
      variants={routeVariants}
      custom={{ direction, animate: true }}
      initial="enter"
      animate="center"
      exit="exit"
    >
      {reportid === "null" && fetchProjectQuery.isSuccess && (
        <div className={styles.text}>
          {!fetchProjectQuery.data.survey.isProgram
            ? "Survey is a draft. Open the survey and start sending it to people in order to see results on this page!"
            : "Launch your first iteration in order to see the results"}
        </div>
      )}
      {reportid != "null" && (
        <>
          {fetchProjectQuery.isSuccess && (
            <div
              className={styles.wantMore}
              style={{ position: window.innerWidth < 560 ? "absolute" : "" }}
            >
              <span>
                Want more? Click <a onClick={analyzeFurther}>Analyze Results</a>{" "}
                to create a custom report of these results and start analyzing
                your data even further.
              </span>
              <div>
                <Button shadow onClick={analyzeFurther}>
                  Analyze Results
                </Button>
              </div>
            </div>
          )}
          <div className={styles.page}>
            <div className={styles.responses}>
              {statsQuery.isLoading && <Loading></Loading>}
              {statsQuery.isSuccess && (
                <div className={styles.surveyStats}>
                  <div className={styles.stats} id="stats">
                    <Chart
                      viz={{
                        type: "verticalbarchart",
                        designSettings: defaultSettings,
                        id: "stats",
                      }}
                      onSegClick={() => console.log("click")}
                      data={{
                        datasets: [
                          {
                            // borderRadius: 10,
                            // borderSkipped: false,

                            data: [
                              statsQuery.data?.stats?.responses,
                              statsQuery.data?.stats?.started -
                                statsQuery.data?.stats?.responses,
                            ],
                            backgroundColor: ["#7FCFD3", "#7FCFD3"],
                          },
                        ],
                        labels: ["Responses", "Abandoned"],
                      }}
                    ></Chart>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  gap: "1em",
                  width: "fit-content",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {" "}
                <div>
                  <Button onClick={() => setDownload(true)} shadow>
                    Download Results
                  </Button>
                </div>
              </div>
            </div>
            {questionsQuery.isSuccess &&
              fetchCustomFields.isSuccess &&
              statsQuery.isSuccess && (
                <DownloadView
                  show={download}
                  setShow={setDownload}
                  survey={{
                    name: statsQuery?.data?.stats?.surveyName,
                    question: questionsQuery.data.questions,
                    id: id,
                  }}
                  custom_fields={fetchCustomFields?.data?.getCurrOrgFields}
                  externalChosenfilter={{}}
                ></DownloadView>
              )}

            <div className={styles.top}>
              {questionsQuery.isLoading && <Loading></Loading>}
              {questionsQuery.isSuccess && fetchProjectQuery.isSuccess && (
                <div className={styles.questionsBox}>
                  <div className={styles.header} style={{ marginLeft: "10%" }}>
                    Question Results
                  </div>
                  <ResultQuestions
                    questions={questionsQuery.data.questions}
                    data={showData}
                    setData={setShowData}
                    show={show}
                    setShow={setShow}
                    projectId={id}
                    project={fetchProjectQuery.data.survey}
                  ></ResultQuestions>
                </div>
              )}
            </div>
            {fetchCustomFields.isSuccess && show && (
              //  <ReactModal
              //   show={show}
              //   onClose={() => setShow(false)}
              //   modalStyle={{
              //     borderRadius: "1em",
              //     // height: "fit-content",
              //     // maxWidth: "600px",
              //     width: "70%",
              //     padding: "1.5em 1em",
              //     gap: "1em",
              //     // overflow: "visible",
              //   }}
              // >
              <Table
                answers={[showData?.answers]}
                onClose={() => setShow(false)}
                viz={{
                  type: "verticalbarchart",
                  designSettings: defaultSettings,
                  id: "stats",
                  question: [showData?.question],
                  projectIdsArray: [id],
                }}
                projects={[{ id: id }]}
                surveyTags={[]}
                canSeeContactInfo
                drill={
                  showData?.segment
                    ? {
                        segment: showData?.segment,
                        dataset: "",
                        split: "",
                        pivot: "",
                        answerType: "",
                        choiceQuestion: showData?.question.choiceQuestion
                          ? true
                          : false,
                        scaleQuestion: showData?.question.scaleQuestion
                          ? true
                          : false,
                      }
                    : null
                }
                titleStyle={getStyle()}
                custom_fields={fetchCustomFields?.data?.getCurrOrgFields}
              />
              // </ReactModal>
            )}
          </div>
        </>
      )}
    </motion.div>
  );
}

export default SurveyResults;

const defaultSettings2 = {
  hasDataLabels: true,
  dataLabelValue: {
    show: true,
    color: "#616565",
    position: "end",
    alignment: "end",
    offset: 0,
    sigFigs: 0,
    fontSize: 14,
  },
  dataLabelLabel: {
    show: false,
  },
  dataLabelPercent: {
    show: false,
  },
  hasTitle: true,
  hasSubtitle: true,
  artificialTitle: true,
  titleSize: 12,
  titleAlignment: "center",
  hasLegend: false,
  hasLegendTitle: false,
  legendTitle: "",
  legendPosition: "right",
  legendFontSize: 14,
  legendPointStyle: true,
  legendLabel: true,
  legendValue: false,
  legendPercent: false,
  drawXLines: false,
  drawYLines: false,
  colors: defaultColors,
  solidateSegments: false,
  currPalletId: "default",
  lineWidth: 1,
  pointRadius: 3,
  lineGraphWidth: 3,
  lineGraphColor: "#2A627C",
  uniformPointColor: "#2A627C",
  hasUniformPointColor: true,
  sortData: "none",
  barPercentage: 0.9,
  cutout: 50,
  byPercent: false,
  answerType: "answer count",
  stacked: false,
  hasAxisTitles: false,
  autoGenAxisTitles: true,
  YAxisTitle: "Answer Count",
  XAxisTitle: "Choices",
  axisTitleFontSize: 12,
  tableFields: {
    firstName: { name: "first name", show: true },
    lastName: { name: "last name", show: true },
    email: { name: "email", show: true },
  },
  tableOrder: ["firstName", "lastName", "email"],
  tablePivotFields: {},
  tablePivotOrder: [],
  valueType: "answercount",
  split: "",
  lineTension: 0.3,
  graphMax: null,
  graphMin: null,
  undefinedLabel: "Undefined",
  zoomOut: 0,
  segLabelReplacements: {},
  textboxes: [
    // { top: "10%", left: "5%", text: "Avg Score: 5", color: "gray", backgroundColor: "white" },
    // { top: "10%", left: "95%", text: "Avg Score: 10", color: "gray", backgroundColor: "white" },
  ],
  showUndefined: true,
  showN: true,
  NLabel: "N",
  useTagColor: false,
  showNonParticipating: false,
  afterSort: false,
  hideNonChosen: false,
  dynamic: false,
  userConstraints: [],
  showFilterSubtitle: true,
  sigFigs: 0,
  hideXticks: true,
  hideYticks: false,
  hideTicks: true,
  hideBorder: true,
  borderRadius: 5,
  borderSkipped: false,
};

function ResultQuestions({
  questions,
  data,
  setData,
  show,
  setShow,
  projectId,
  project,
}) {
  const updateProject = useUpdateProjectDashProperties();
  const [sort, setSort] = useState(orderQuestions(questions));
  const fetchCustomFields = useFetchCurrOrgsFields();

  useEffect(() => {
    if (!project.openedResults) {
      updateProject.mutate({
        updateProjectId: projectId,
        data: {
          updatedProject: true,
        },
      });
    }
  }, []);

  function orderQuestions(Qs) {
    if (Qs) {
      let sortPageIndex = Qs.sort((p1, p2) =>
        p1.pageOrderIndex > p2.pageOrderIndex
          ? 1
          : p1.pageOrderIndex < p2.pageOrderIndex
          ? -1
          : 0
      );

      let sortPages = sortPageIndex.sort((p1, p2) =>
        p1.pageNumber > p2.pageNumber
          ? 1
          : p1.pageNumber < p2.pageNumber
          ? -1
          : 0
      );

      return sortPages;
    } else {
      return [];
    }
  }

  function getSettings(question) {
    let tableFields = {
      firstName: { name: "first name", show: true },
      lastName: { name: "last name", show: true },
      email: { name: "email", show: false },
    };
    tableFields[question.id] = {
      name: question.questionText,
      show: true,
    };

    return {
      hasDataLabels: true,
      dataLabelValue: {
        show: true,
        color: "#616565",
        position: "end",
        alignment: "end",
        offset: 0,
        sigFigs: 0,
        fontSize: 14,
      },
      dataLabelLabel: {
        show: false,
      },
      dataLabelPercent: {
        show: false,
      },
      hasTitle: true,
      hasSubtitle: true,
      artificialTitle: true,
      titleSize: 12,
      titleAlignment: "center",
      hasLegend: false,
      hasLegendTitle: false,
      legendTitle: "",
      legendPosition: "top",
      legendFontSize: 14,
      legendPointStyle: true,
      legendLabel: true,
      legendValue: false,
      legendPercent: false,
      drawXLines: false,
      drawYLines: false,
      colors: defaultColors,
      solidateSegments: false,
      currPalletId: "default",
      lineWidth: 1,
      pointRadius: 3,
      lineGraphWidth: 3,
      lineGraphColor: "#2A627C",
      uniformPointColor: "#2A627C",
      hasUniformPointColor: true,
      sortData: "none",
      barPercentage: 0.9,
      cutout: 50,
      byPercent: false,
      answerType: "answer count",
      stacked: false,
      hasAxisTitles: false,
      autoGenAxisTitles: true,
      YAxisTitle: "Answer Count",
      XAxisTitle: "Choices",
      axisTitleFontSize: 12,
      tableFields: tableFields,
      tableOrder: ["firstName", "lastName", "email", question.id],
      tablePivotFields: {},
      tablePivotOrder: [],
      valueType: "answercount",
      split: "",
      lineTension: 0.3,
      graphMax: null,
      graphMin: null,
      undefinedLabel: "Undefined",
      zoomOut: 0,
      segLabelReplacements: {},
      textboxes: [
        // { top: "10%", left: "5%", text: "Avg Score: 5", color: "gray", backgroundColor: "white" },
        // { top: "10%", left: "95%", text: "Avg Score: 10", color: "gray", backgroundColor: "white" },
      ],
      showUndefined: true,
      showN: false,
      NLabel: "N",
      useTagColor: false,
      showNonParticipating: false,
      afterSort: false,
      hideNonChosen: false,
      dynamic: false,
      userConstraints: [],
      showFilterSubtitle: true,
      sigFigs: 0,
      hideXticks: true,
      hideYticks: false,
      hideTicks: true,
      hideBorder: true,
      borderRadius: 5,
      borderSkipped: false,
    };
  }

  function checkHeight(question) {
    if (question.textQuestion) {
      return "500px";
    }
    if (question.scaleQuestion) {
      return "400px";
    }
    if (question.choiceQuestion) {
      if (question.choiceQuestion.choices.length <= 4) {
        return "300px";
      }
      if (
        question.choiceQuestion.choices.length > 4 &&
        question.choiceQuestion.choices.length <= 8
      ) {
        return "400px";
      }
      return "500px";
    }
    return "500px";
  }

  return (
    <div className={styles.questions}>
      {fetchCustomFields.isSuccess &&
        sort?.map((question, i) => (
          // <ResultQuestion
          //   key={i}
          //   question={question}
          //   index={i + 1}
          //   data={data}
          //   setData={setData}
          //   show={show}
          //   setShow={setShow}
          // />

          <div
            className={styles.question}
            style={{
              width: window.innerWidth < 700 ? "375px" : "",
              height: checkHeight(question),
            }}
          >
            <Visualization
              key={i}
              viz={{
                designSettings: !question.textQuestion
                  ? defaultSettings2
                  : getSettings(question),
                filters: "{}",
                id: i,
                // pivotString: "",
                projectIdsArray: [projectId],
                question: [question],
                tagIdsArray: [],
                title: `Q${i + 1}: ${question?.questionText}`,
                type: !question.textQuestion ? "horizontalbarchart" : "table",
              }}
              // thumbnail
              custom_fields={fetchCustomFields?.data?.getCurrOrgFields}
            />
          </div>
        ))}
    </div>
  );
}
