import { useEffect, useState } from "react";
import styles from "./GradientBuilder.module.scss";

import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { Label } from "components/layouts/Label/Label";
import { AddColorSimple } from "../EditColors/AddColorSimple";
import { GradientColorPicker } from "./GradientColorPicker";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { forEach } from "assets/functions/ArrayFunctions";
import {
  getGradientColors,
  getTheOffWhite,
} from "assets/functions/ColorFunctions";

export default function GradientBuilder({ onExit, onSave, init }) {
  function parseInit() {
    let preColors = init.colors;

    if (init.blended) {
      let array = [];
      let anchorIndex = 0;
      for (let i = 0; i < preColors.length; i++) {
        let one = { color: preColors[i], realInd: i };
        if (
          anchorIndex < init.anchors.length &&
          preColors[i] === init.anchors[anchorIndex].color
        ) {
          one.anchor = true;
          anchorIndex++;
        } else {
          one.anchor = false;
        }
        array.push(one);
      }
      return array;
    }

    let count = 0;
    for (let anchor of init.anchors) {
      anchor.length = Math.round(preColors.length * (anchor.portion / 100));
      count += anchor.length;
    }

    let index = 0;
    while (count != preColors.length) {
      if (count < preColors.length) {
        init.anchors[index].length++;
        count++;
      } else {
        init.anchors[index].length--;
        count--;
      }
      if (index + 1 < init.anchors.length) {
        index++;
      } else {
        index = 0;
      }
    }

    let anchorIndex = 0;
    let array = [];
    for (let i = 0; i < preColors.length; i++) {
      let one = { color: preColors[i], realInd: i };
      if (
        anchorIndex < init.anchors.length &&
        preColors[i] === init.anchors[anchorIndex].color
      ) {
        one.anchor = true;
        one.length = init.anchors[anchorIndex].length;
        if (init.anchors[anchorIndex].reversed) {
          one.reversed = true;
        }
        anchorIndex++;
      } else {
        one.anchor = false;
      }
      array.push(one);
    }

    // get the right realInds for flipped anchors
    for (let i = 0; i < array.length; i++) {
      if (array[i].anchor && array[i].reversed) {
        let startingInd = i - array[i].length + 1;
        for (let k = 0; k < array[i].length; k++) {
          array[i - k].realInd = startingInd + k;
        }
      }
    }

    return array;
  }

  const [colors, setColors] = useState(init ? parseInit() : []);
  // console.log(colors.map((c) => c.color));

  const [startLength, setStartLength] = useState(10);
  const [showStart, setShowStart] = useState(init ? false : true);
  const [blended, setBlended] = useState(init ? init.blended : false);

  const [beforeChange, setBeforeChange] = useState();

  function onStart(color) {
    if (!color) {
      return;
    }

    let offWhite = getTheOffWhite(color, startLength);
    let array = getGradientColors(color, offWhite, startLength);
    let data = array.map((clr, i) => {
      return {
        color: clr,
        anchor: false,
        realInd: i,
      };
    });
    data[0].anchor = true;
    data[0].length = startLength;
    if (blended) {
      data[startLength - 1].anchor = true;
    }
    setColors(data);
    setShowStart(false);
  }

  function onClear() {
    setColors([]);
    setShowStart(true);
  }

  function getRowsOfTen() {
    let rows = [];
    let i = 0;
    while (i < colors.length) {
      let row = [];
      for (let c = 0; c < 10; c++) {
        if (i < colors.length) {
          row.push(colors[i]);
          i++;
        }
      }
      rows.push(row);
    }
    return rows;
  }

  // const [multiple, setMultiple] = useState(false);

  function getSingleToneGradients() {
    let data = beforeChange ? beforeChange : colors;
    let anchors = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].anchor) {
        anchors.push({
          ...data[i],
        });
      }
    }

    let gradients = [];
    let count = 0;
    for (let anchor of anchors) {
      let segment = [];
      for (let i = anchor.realInd; i < count + anchor.length; i++) {
        segment.push(data[i].color);
      }
      gradients.push(segment);
      count += segment.length;
    }

    return gradients;
  }

  function getBlendedGradients() {
    let gradients = [];
    let segment = [];
    let data = beforeChange ? beforeChange : colors;
    for (let i = 0; i < data.length; i++) {
      if (data[i].anchor) {
        segment.push(data[i].color);
        if (i && segment.length) {
          // at the end
          gradients.push(segment);
          // restart new seg
          segment = [data[i].color];
        }
      } else {
        segment.push(data[i].color);
      }
    }

    return gradients;
  }

  function getGradients() {
    if (blended) {
      return getBlendedGradients();
    }
    return getSingleToneGradients();
  }

  function getNormalOrderWithTaggedReversesCopy(data) {
    let copy = [...data];
    for (let i = 0; i < data.length; i++) {
      copy[data[i].realInd] = data[i];
      if (data[i].anchor && data[i].realInd !== i) {
        // if it wasn't in its right spot, it was reversed
        copy[data[i].realInd].reversed = true;
      }
    }
    return copy;
  }

  function getSingleTonedSegments(data) {
    // first get it all in order
    let copy = getNormalOrderWithTaggedReversesCopy(data);

    let segments = [];
    let segment = undefined;

    for (let i = 0; i < copy.length; i++) {
      if (copy[i].anchor) {
        if (segment) {
          segments.push(segment);
          segment = {
            start: copy[i].color,
            length: 1,
            reversed: copy[i].reversed,
          };
        } else {
          segment = {
            start: copy[i].color,
            length: 1,
            reversed: copy[i].reversed,
          };
        }
      } else {
        segment.length++;
      }
    }

    // will be one segment remaining
    segments.push(segment);

    return segments;
  }

  function getBlendedSegments(data) {
    let segments = [];
    let segment = undefined;
    for (let i = 0; i < data.length; i++) {
      if (data[i].anchor) {
        if (segment) {
          segment.length = i - segment.startInd + 1;
          segment.end = data[i].color;
          segments.push(segment);
          segment = { start: data[i].color, startInd: i };
        } else {
          segment = { start: data[i].color, startInd: i };
        }
      }
    }

    return segments;
  }

  function createBlendedGradient(data) {
    let segments = getBlendedSegments(data);
    let newColors = [];
    for (let seg of segments) {
      let spread = getGradientColors(seg.start, seg.end, seg.length);

      for (let i = 0; i < spread.length; i++) {
        // don't include the first anchor, unless its the first of all
        if (!i) {
          if (!newColors.length) {
            newColors.push({ color: spread[i], anchor: true });
          }
        } else {
          newColors.push({
            color: spread[i],
            anchor: i == spread.length - 1,
          });
        }
      }
    }
    forEach(newColors, (c, i) => (c.realInd = i));
    // console.log(newColors);
    setColors(newColors);
  }

  function createSingleTonesGradient(data) {
    let segments = getSingleTonedSegments(data);
    let newColors = [];
    let num = 0;
    for (let seg of segments) {
      let firstColor = seg.start;
      let secondColor = getTheOffWhite(firstColor, seg.length);
      let spread = getGradientColors(firstColor, secondColor, seg.length);

      if (seg.reversed) {
        spread.reverse();
      }

      for (let i = 0; i < spread.length; i++) {
        let it = {
          color: spread[i],
          anchor: seg.reversed ? i == spread.length - 1 : !i,
          realInd: seg.reversed ? num + (spread.length - 1 - i) : num + i,
        };
        if (it.anchor) {
          it.length = seg.length;
        }
        newColors.push(it);
      }
      num += spread.length;
    }
    // console.log(newColors);
    setColors(newColors);
  }

  function createGradient(data) {
    if (blended) {
      createBlendedGradient(data);
    } else {
      createSingleTonesGradient(data);
    }
  }

  function isInReversedSeg(copy, ind) {
    // find it's anchor
    let spot = 0;
    for (let i = ind - 1; i >= 0; i--) {
      if (copy[i].anchor) {
        spot = i;
        break;
      }
    }
    if (copy[spot].reversed) {
      return true;
    }
    return false;
  }

  function addSingleToneAnchor(color, realInd) {
    let copy = getNormalOrderWithTaggedReversesCopy(colors);

    if (copy[realInd].anchor) {
      //changing anchor, no biggie
      copy[realInd].color = color;
    } else if (isInReversedSeg(copy, realInd)) {
      let spot = 0;
      for (let i = realInd - 1; i >= 0; i--) {
        if (copy[i].anchor) {
          spot = i;
          break;
        }
      }
      // I just need to switch those two around;
      let oldAnchorsNewSpot = copy[spot].length - (realInd - spot) + spot;
      let oldAnchor = copy[spot];
      copy[spot] = {
        color: color,
        anchor: true,
        realInd: spot,
        reversed: true,
      };
      copy[oldAnchorsNewSpot] = { ...oldAnchor, realInd: oldAnchorsNewSpot };
    } else {
      copy[realInd] = {
        color: color,
        anchor: true,
        realInd: realInd,
      };
    }

    createGradient(copy);
  }

  function addAnchor(color, ind) {
    if (!beforeChange) {
      setBeforeChange(JSON.parse(JSON.stringify(colors)));
    }
    if (!blended) {
      addSingleToneAnchor(color, ind);
    } else {
      let copy = [...colors];
      copy[ind] = {
        color: color,
        anchor: true,
        realInd: ind,
      };
      createGradient(copy);
    }
  }

  function removeAnchor(ind) {
    let copy = [...colors];
    if (!beforeChange) {
      setBeforeChange(JSON.parse(JSON.stringify(copy)));
    }

    if (!blended) {
      let orderIndex = copy.findIndex((c) => c.realInd === ind);
      if (orderIndex != ind) {
        copy[orderIndex].anchor = false;
      } else {
        copy[ind].anchor = false;
      }
    } else {
      copy[ind].anchor = false;
    }

    createGradient(copy);
  }

  function onApply() {
    setBeforeChange(null);
  }

  function onCancel() {
    if (beforeChange) {
      let copy = [...beforeChange];
      setColors(copy);
      setBeforeChange(null);
    }
  }

  function changeSingleToneSegLength(spot, val) {
    spot = colors[spot].realInd;
    let data = getNormalOrderWithTaggedReversesCopy(colors);

    if (val > 0) {
      data.splice(spot + 1, 0, {
        color: "#ffffff",
        anchor: false,
      }); // color will be changed
    } else {
      data.splice(spot + 1, 1);
    }

    forEach(data, (d, i) => (d.realInd = i));

    createGradient(data);
  }

  function changeBlendedSegLength(spot, val) {
    let data = [...colors];
    if (val > 0) {
      data.splice(spot + 1, 0, { color: "#ffffff", anchor: false }); // add any color in there;
    } else {
      data.splice(spot + 1, 1);
    }
    createGradient(data);
  }

  function changeSegLength(ind, val) {
    let spot = getAnchorSpot(ind);

    if (blended) {
      changeBlendedSegLength(spot, val);
    } else {
      changeSingleToneSegLength(spot, val);
    }
  }

  function checkMultiBase() {
    if (!blended) {
      setBlended(true);
      // make the last an anchor
      // let copy = getNormalOrderWithTaggedReversesCopy();
      let copy = [...colors];
      copy[colors.length - 1].anchor = true;
      if (!copy[0].anchor) {
        // maybe ?
        copy[0].anchor = true;
      }
      setColors(copy);
    }
  }

  function checkSingleBase() {
    if (blended) {
      setBlended(false);
      // remove the last anchor
      let copy = [...colors];
      copy[colors.length - 1].anchor = false;
      setColors(copy);
    }
  }

  useEffect(() => {
    if (!showStart) {
      createGradient(colors);
    }
  }, [blended]);

  function switchSingleTonedSegOrder(spot) {
    let data = [...colors];

    if (data[spot].realInd == spot) {
      // its normal so reverse it
      data[spot].reversed = true;
    } else {
      // put it back
      let end = spot - data[spot].length;
      for (let i = spot; i > end; i--) {
        data[colors[i].realInd] = colors[i];
      }
    }

    createGradient(data);
  }

  function switchBlendedSegOrder(spot) {
    let otherInd = 0;
    for (let i = spot + 1; i < colors.length; i++) {
      if (colors[i].anchor) {
        otherInd = i;
        break;
      }
    }

    let copy = [...colors];
    let x = copy[spot];
    copy[spot] = copy[otherInd];
    copy[otherInd] = x;
    createGradient(copy);
  }

  function switchSegOrder(ind) {
    let spot = getAnchorSpot(ind);
    if (blended) {
      switchBlendedSegOrder(spot);
    } else {
      switchSingleTonedSegOrder(spot);
    }
  }

  function getAnchorSpot(ind) {
    let count = -1;
    let spot = -1;
    for (let i = 0; i < colors.length; i++) {
      if (colors[i].anchor) {
        count++;
        if (count === ind) {
          spot = i;
          break;
        }
      }
    }
    return spot;
  }

  function handleSave() {
    onSave(colors, blended);
  }

  function getTwoAnchors() {
    let them = { one: undefined, two: undefined };
    let data = beforeChange ? beforeChange : colors;
    for (let i = 0; i < data.length; i++) {
      if (data[i].anchor) {
        if (!them.one) {
          them.one = data[i].color;
        } else {
          them.two = data[i].color;
          break;
        }
      }
    }
    return them;
  }

  function getSingleTonesExample() {
    let anchors = getTwoAnchors();
    let offOne = getTheOffWhite(anchors.one, 3);
    let gradient = getGradientColors(anchors.one, offOne, 3);
    if (anchors.two) {
      let offTwo = getTheOffWhite(anchors.two, 3);
      let gradTwo = getGradientColors(anchors.two, offTwo, 3);
      gradient = [...gradient, ...gradTwo];
    } else {
      gradient = [...gradient, ...gradient];
    }
    return gradient;
  }

  function getBlendedExample() {
    let anchors = getTwoAnchors();
    if (anchors.two) {
      return getGradientColors(anchors.one, anchors.two, 6);
    }
    let offWhite = getTheOffWhite(anchors.one, 6);
    return getGradientColors(anchors.one, offWhite, 6);
  }

  const singleToneExample = [
    "#15bcc7",
    "#63d3da",
    "#b1e9ec",
    "#ed9146",
    "#f3b684",
    "#f9dac1",
  ];

  const singleTonesBG = [
    "#7fcfd3",
    "#aadfe2",
    "#d4eff0",
    "#89d188",
    "#b1e1b0",
    "#d8f0d7",
  ];

  const blendedExample = [
    "#15bcc7",
    "#44b2b7",
    "#73a7a7",
    "#a19d98",
    "#d09288",
    "#ff8878",
  ];

  const blendedBG = [
    "#15bcc7",
    "#2cc0ba",
    "#43c4ae",
    "#5bc9a1",
    "#72cd95",
    "#89d188",
  ];

  return (
    <div className={styles.gradientContainer}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <Label
          style={{
            fontWeight: "500",
            fontSize: ".8em",
            textAlign: "center",
            width: "fit-content",
            padding: "0px 10px",
            borderBottom: "2px solid #A4C6D0",
          }}
        >
          Palette
        </Label>

        {showStart && (
          <div style={{ display: "flex", gap: "7px", alignItems: "center" }}>
            <div className={styles.gText}>Start</div>{" "}
            <AddColorSimple onDone={onStart} wording="Start" />
          </div>
        )}

        <div className={styles.palette}>
          {getRowsOfTen().map((row) => (
            <div className={styles.row}>
              {row.map((data) => {
                let ind = data.realInd; //ind + rowNum * 10;

                return (
                  <GradientColorPicker
                    color={data.color}
                    orig={beforeChange ? beforeChange[ind].color : data.color}
                    start={!ind}
                    end={blended ? ind === colors.length - 1 : undefined}
                    anchor={
                      beforeChange ? beforeChange[ind].anchor : data.anchor
                    }
                    addAnchor={(clr) => addAnchor(clr, ind)}
                    removeAnchor={() => removeAnchor(ind)}
                    onApply={onApply}
                    onCancel={onCancel}
                  />
                );
              })}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.gradients}>
        {!showStart && (
          <>
            {getGradients().map((seg, ind) => (
              <div className={styles.segment}>
                <div className={styles.gradient}>
                  <div className={styles.gText}>Gradient:</div>
                  <div
                    style={{
                      display: "flex",
                      gap: "7px",
                      alignItems: "center",
                    }}
                  >
                    <div className={styles.gText}>length:</div>
                    <NumberInput
                      startNumber={seg.length}
                      handleNumberChange={(val) =>
                        changeSegLength(ind, val - seg.length)
                      }
                      max={20}
                      min={blended ? 2 : 1}
                    ></NumberInput>
                  </div>
                  <div
                    className={styles.gText}
                    style={{ cursor: "pointer" }}
                    onClick={
                      seg.length > 1 ? () => switchSegOrder(ind) : undefined
                    }
                  >
                    <i
                      className="bi bi-arrow-repeat"
                      style={{ marginRight: "5px" }}
                    ></i>
                    switch
                  </div>
                </div>
                <div className={styles.exampleGradient}>
                  {seg.map((color) => (
                    <div
                      className={styles.exampleSwatch}
                      style={{ backgroundColor: color }}
                    ></div>
                  ))}
                </div>
              </div>
            ))}

            {colors.length > 0 && (
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              >
                <div
                  className={styles.gText}
                  onClick={onClear}
                  style={{ cursor: "pointer" }}
                >
                  <i className="bi bi-x" style={{ marginRight: "2px" }}></i>
                  Clear
                </div>
              </div>
            )}

            <div className={styles.blendControls}>
              <div className={styles.heading}>How to Handle Gradients</div>
              <div className={styles.setting}>
                <Checkbox
                  checked={!blended}
                  onChange={checkSingleBase}
                  color="#7FCFD3"
                />
                <div>Single Tones</div>
              </div>
              <div className={styles.controlsExample}>
                {getSingleTonesExample().map((c) => (
                  <div
                    className={styles.exampleSwatch}
                    style={{ backgroundColor: c }}
                  ></div>
                ))}
              </div>
              <div className={styles.setting}>
                <Checkbox
                  checked={blended}
                  onChange={checkMultiBase}
                  color="#7FCFD3"
                />
                <div>Blended</div>
              </div>
              <div className={styles.controlsExample}>
                {getBlendedExample().map((c) => (
                  <div
                    className={styles.exampleSwatch}
                    style={{ backgroundColor: c }}
                  ></div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div
          className={styles.gText}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
          onClick={onExit}
        >
          <i
            className="bi bi-arrow-return-left"
            style={{ transform: "rotateX(180deg)" }}
          ></i>
          Exit Builder
        </div>
        {colors.length > 0 && (
          <div className={styles.button} onClick={handleSave}>
            Use Colors
          </div>
        )}
      </div>
    </div>
  );
}
