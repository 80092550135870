import { Label } from "components/layouts/Label/Label";
import styles from "./Confirmation.module.scss";
import Button from "components/Button/Button";
import Modal from "components/ReactModal/ReactModal";
import { Loading } from "components/Loading/Loading";
import {
  emailString,
  getTimeString,
  secondString,
  trimDate,
} from "../../email/Email";
import { maintimezones } from "assets/functions/Variables";
import { Player } from "@lottiefiles/react-lottie-player";

export default function Confirmation({
  onClose,
  email,
  schedule,
  scheduling,
  scheduled,
  byAudience,
  audienceSchedule,
  onConfirm,
  draft,
}) {
  return (
    <Modal
      show
      onClose={onClose}
      modalStyle={{
        borderRadius: "1em",
        maxWidth: byAudience ? "800px" : "600px",
        width: "100%",
        gap: "1em",
        overflow: "visible",
        height: scheduling || scheduled ? "fit-content" : "550px",
      }}
      dark
    >
      {/* {scheduling && <Loading></Loading>} */}
      {scheduling && (
        <div className={styles.scheduled}>
          Scheduling...
          <div>
            <Player
              autoplay
              loop
              src={require("assets/animations/Gears.json")}
              style={{
                height: "120px",
                // width: "3.5rem",
              }}
            />
          </div>
        </div>
      )}
      {scheduled && (
        <div className={styles.scheduled}>
          Scheduled!
          <div>
            <Player
              autoplay
              loop
              src={require("assets/animations/checkmarkblue.json")}
              style={{
                height: "100px",
                // width: "3.5rem",
              }}
            />
          </div>
        </div>
      )}
      {!scheduling && !scheduled && (
        <div className={styles.validateContainer}>
          <div className={styles.valHeader}> {email?.name}</div>
          <div className={styles.valDescription}>
            {schedule
              ? "Review your email details before scheduling your send"
              : "Review your email details before clicking send"}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "1em",
              alignItems: "center",
            }}
          >
            <div className={styles.valSection}>
              <Label style={{ maxWidth: "180px", justifyContent: "end" }}>
                Subject:
              </Label>{" "}
              {email?.subject}
            </div>
            {!byAudience && (
              <div className={styles.valSection}>
                <Label style={{ maxWidth: "180px", justifyContent: "end" }}>
                  Sending To:
                </Label>{" "}
                <div className={styles.auds}>
                  {email?.audience?.map((aud, i) => (
                    <div className={styles.aud} key={i}>
                      {aud?.name}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {byAudience && (
              <div
                className={styles.valSection}
                style={{ alignItems: "start" }}
              >
                <Label style={{ maxWidth: "180px", justifyContent: "end" }}>
                  Sending To:
                </Label>{" "}
                <div className={styles.auds2}>
                  {Object.keys(audienceSchedule)?.map((id, i) => (
                    <div key={i}>
                      <div className={styles.aud}>
                        {audienceSchedule[id]?.name}
                      </div>
                      <div className={styles.audDate}>
                        {trimDate(audienceSchedule[id]?.date)}
                        <span>
                          (
                          {
                            maintimezones.find(
                              (a) =>
                                a.timezone === audienceSchedule[id]?.timezone
                            )?.name
                          }
                          )
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className={styles.valSection}>
              <Label style={{ maxWidth: "180px", justifyContent: "end" }}>
                Sending From:
              </Label>{" "}
              {email?.dynamicFrom ? (
                <div className={styles.blue}>Dynamic From Address</div>
              ) : (
                email?.distConfig?.fromAddress
              )}
            </div>
            {!byAudience && (
              <div className={styles.valSection}>
                <Label style={{ maxWidth: "180px", justifyContent: "end" }}>
                  Sending Time:
                </Label>{" "}
                {!schedule
                  ? "Immediately"
                  : getTimeString(email?.scheduledDate)}
              </div>
            )}
            <div className={styles.valSection}>
              <Label style={{ maxWidth: "180px", justifyContent: "end" }}>
                Email Drip:
              </Label>{" "}
              <div style={{ display: "flex", gap: ".5em" }}>
                <span>
                  {email?.timeDelayCount
                    ? emailString(email?.timeDelayCount)
                    : "1 (email)"}
                </span>
                <span>every</span>
                <span>
                  {email?.timeDelay
                    ? secondString(email?.timeDelay)
                    : "1 (second)"}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.valDescription2}>
            {draft
              ? 'Sending this email will open this survey for participation and change the survey status to "Open".'
              : ""}
          </div>
          <div className={styles.valDescription}>
            {!schedule
              ? "Are you sure you want to send this email?"
              : "Are you sure you want to schedule this email?"}
          </div>
          <div className={styles.valButtons}>
            <Button shadow red onClick={onClose}>
              Cancel
            </Button>{" "}
            <Button shadow onClick={onConfirm}>
              {!schedule ? "Send Email" : "Schedule Email"}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}
