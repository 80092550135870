import React, { useState } from "react";
import styles from "./Dashboards.module.scss";
import {
  useCreateOrgDash,
  useFetchOrganization,
  useUpdateOrgSettings,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { useParams } from "react-router-dom";
import {
  useFetchOrgRolesList,
  useToggleRoleDash,
} from "api/resources/organization/roles";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";

function Dashboards({ roles }) {
  const { id } = useParams();
  const MyOrg = useFetchOrganization(id);

  return (
    <div className={styles.page}>
      {MyOrg.isLoading && <Loading></Loading>}
      {MyOrg.isSuccess && (
        <DashboardContent
          organization={MyOrg.data.organization}
          role={roles}
        ></DashboardContent>
      )}
    </div>
  );
}

export default Dashboards;

function DashboardContent({ organization, role }) {
  const [org, setOrg] = useState({ ...organization });
  const [edited, setEdited] = useState(false);
  const [BtnMsg, setBtnMsg] = useState("Save Changes");
  const [editTime, setEditTime] = useState(false);
  const [editInvoice, setEditInvoice] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [editName, setEditName] = useState(false);
  const updateOrg = useUpdateOrgSettings();
  const createOrgDash = useCreateOrgDash();
  const toggleRoleDash = useToggleRoleDash();
  const orgRoles = useFetchOrgRolesList(org.id);

  function handleCancel() {
    setEditAddress(false);
    setOrg({ ...organization });
  }
  function handleSave() {
    if (role?.canUpdateOrganization) {
      setBtnMsg(
        <div style={{ display: "flex", gap: "5px" }}>
          Saving <Loading height={20} width={20}></Loading>
        </div>
      );
      updateOrg.mutate(
        {
          updateOrganizationId: organization.id,
          data: {
            billingAddress1: org?.billingAddress1,
            billingAddress2: org?.billingAddress2,
            billingCity: org?.billingCity,
            billingCountry: org?.billingCountry,
            billingPostalCode: org?.billingPostalCode,
            billingState: org?.billingState,
            invoiceAddress1: org?.invoiceAddress1,
            invoiceAddress2: org?.invoiceAddress2,
            invoiceCity: org?.invoiceCity,
            invoiceCountry: org?.invoiceCountry,
            invoicePostalCode: org?.invoicePostalCode,
            invoiceState: org?.invoiceState,
          },
        },
        {
          onSuccess: (data) => {
            // console.log("Updated Org: ", data);
            setBtnMsg(
              <div style={{ display: "flex", gap: "5px" }}>
                Saved <i className="bi bi-check-lg"></i>
              </div>
            );
            setTimeout(() => {
              location.reload();
            }, 750);
          },
        }
      );
    }
  }

  function toggleDash(val) {
    if (val && !org.dashboardId) {
      // create one if not there
      createOrgDash.mutate(
        {
          orgId: id,
        },
        {
          onSuccess: (data) => {},
        }
      );
    } else {
      editOrg.mutate(
        {
          data: {
            showDash: val,
          },
          updateOrganizationId: id,
        },

        {
          onSuccess: (data) => {
            // console.log(data);
          },
        }
      );
    }
  }

  function roleDashToggle(r, val) {
    toggleRoleDash.mutate(
      {
        roleId: r.id,
        val: val,
      },
      {
        onSuccess: (data) => {
          console.log("role toggled");
        },
      }
    );
  }

  return (
    <>
      {orgRoles.isSuccess && (
        <>
          {role.canEditRoleDashboards && (
            <div className={styles.editImage}>
              <div className={styles.roleDashControls}>
                <div className={styles.section_label5} style={{ margin: "0" }}>
                  Visible Role Dashboards
                </div>
                {orgRoles.data.roles.map((r, i) => (
                  <div className={styles.dashControl} key={i}>
                    <ToggleSwitch
                      startChecked={r.showDash}
                      handleCheck={(val) => roleDashToggle(r, val)}
                    />
                    <div className={styles.role}>
                      <div
                        className={styles.label}
                        style={r.showDash ? { color: "#2A627C" } : undefined}
                      >
                        {r.name}
                      </div>
                      {r.description && (
                        <div className={styles.roleDescription}>
                          {r.description}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {role.canEditOrgDashboard && (
            <>
              <div className={styles.editImage}>
                <div className={styles.section_label5} style={{ margin: "0" }}>
                  Organization Dashboards
                </div>
                <div className={styles.dashControls}>
                  {role.canEditOrgDashboard && (
                    <div className={styles.orgDashControl}>
                      <div className={styles.dashControl}>
                        <ToggleSwitch
                          startChecked={org.showDash}
                          handleCheck={toggleDash}
                        />
                        <div className={styles.role}>
                          <div
                            className={styles.label}
                            style={
                              org.showDash ? { color: "#2A627C" } : undefined
                            }
                          >
                            {`Visible Organization Dashboard`}
                          </div>
                          <div className={styles.roleDescription}>
                            An identical dashboard view for everyone
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {/* <Modal
        show={editAddress || editInvoice}
        onClose={handleCancel}
        modalStyle={{
          padding: "2em",
          width: "600px",
          height: "100%",
          gap: "1rem",
        }}
        dark
        rightStyle
      >
        {editAddress && (
          <>
            <div className={styles.header_3} style={{ margin: "0" }}>
              Billing Address
            </div>
            <AddressInput
              org={org}
              onChange={onChangeBilling}
              billing
            ></AddressInput>
          </>
        )}
        {editInvoice && (
          <>
            <div className={styles.header_3} style={{ margin: "0" }}>
              Invoice Address
            </div>
            <AddressInput
              org={org}
              onChange={onChangeInvoice}
              invoice
            ></AddressInput>
          </>
        )}
        <div
          style={{
            display: "flex",
            gap: "1em",
            paddingTop: ".5em",
            justifyContent: "flex-end",
          }}
        >
          <Button red height={35} onClick={handleCancel} shadow link>
            Cancel
          </Button>
          <Button height={35} onClick={handleSave} shadow blue>
            {BtnMsg}
          </Button>
        </div>
      </Modal> */}
        </>
      )}
    </>
  );
}
