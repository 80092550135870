import React, { useEffect, useState } from "react";
import styles from "./LaunchNext.module.scss";
import Button from "components/Button/Button";
import { Label } from "components/layouts/Label/Label";
import { SelectField, TextFieldSimple } from "components/inputs";
import { useFetchSurveyTagsGql } from "api/resources/organization/surveytags";
import { SurveyTypes } from "pages/projects/NewSurveySetUp/CreateSurvey";
import { useSearchDistributions } from "api/resources/projects/distributions";
import EmailWidgets from "components/tables/BasicTable/SurveyWidgets/EmailWidgets/EmailWidgets";
import { Loading } from "components/Loading/Loading";
import Survey from "components/Survey/Survey";
import { Email} from "../delivery/distributions/email/Email";
import { Player } from "@lottiefiles/react-lottie-player";
import { useNavigate } from "react-router-dom";
import Scheduler from "../delivery/distributions/email/Scheduler";

const days = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

const months = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

function LaunchNext({ show, setShow, roles, survey, currUser, setHideNav }) {
  const surveyTagsQuery = useFetchSurveyTagsGql();

  const [page, setPage] = useState(1);
  const [pickedTags, setPickedTags] = useState([]);
  const [type, setType] = useState(survey.type);
  const [surveyName, setSurveyName] = useState(initName);
  const [surveyDescription, setSurveyDescription] = useState(initDesc);
  const [validName, setValidName] = useState(true);
  const [active, setActive] = useState();
  const [initSchedule, setInitSchedule] = useState(false);
  const [emails, setEmails] = useState([]);

  function initName() {
    if (survey.sendInterval === "Quarterly") {
      let today = new Date();
      let month = today.getMonth();
      if (month < 3) {
        return `Q1 ${today.getFullYear()} | ${survey.name}`;
      }
      if (month > 2 && month < 6) {
        return `Q2 ${today.getFullYear()} | ${survey.name}`;
      }
      if (month > 5 && month < 9) {
        return `Q3 ${today.getFullYear()} | ${survey.name}`;
      }
      if (month > 8 && month < 12) {
        return `Q4 ${today.getFullYear()} | ${survey.name}`;
      }
    }
    if (survey.sendInterval === "Monthly") {
      let today = new Date();
      return `${months[today.getMonth()]} ${today.getFullYear()} | ${
        survey.name
      }`;
    }
    if (survey.sendInterval === "Weekly") {
      return `Week | ${survey.name}`;
    }
    if (survey.sendInterval === "Daily") {
      return `Day | ${survey.name}`;
    }
    if (survey.sendInterval === "Triggered") {
      return `Send | ${survey.name}`;
    }
  }

  function initDesc() {
    if (survey.sendInterval === "Quarterly") {
      let today = new Date();
      let month = today.getMonth();
      if (month < 3) {
        return `Q1 send in ${today.getFullYear()}`;
      }
      if (month > 2 && month < 6) {
        return `Q2 send in ${today.getFullYear()}`;
      }
      if (month > 5 && month < 9) {
        return `Q3 send in ${today.getFullYear()}`;
      }
      if (month > 8 && month < 12) {
        return `Q4 send in ${today.getFullYear()}`;
      }
    }
    if (survey.sendInterval === "Monthly") {
      let today = new Date();
      return `${months[today.getMonth()]} send in ${today.getFullYear()}`;
    }
    if (survey.sendInterval === "Weekly") {
      return `Week`;
    }
    if (survey.sendInterval === "Daily") {
      return "Day";
    }
    if (survey.sendInterval === "Triggered") {
      return "Send";
    }
  }

  function changeName(val) {
    if (val && !validName) {
      setValidName(true);
    }
    if (val === "") {
      setValidName(false);
    }
    setSurveyName(val);
  }

  function getBack() {
    if (survey.designSettings && typeof survey.designSettings === "string") {
      let settings = JSON.parse(survey?.designSettings);
      return settings.backgroundColor;
    } else {
      return "";
    }
  }

  const [tempSchedule, setSchedule] = useState();

  function handleScheduleChange(val) {
    setSchedule(val.fullText);
    // let tempmails = [...emails];
    // let index = tempmails.findIndex((e) => e.id === active.id);
    // tempmails[index].scheduledDate = val.fullText;
    // setEmails(tempmails);
  }

  function finishSchedule() {
    let tempmails = [...emails];
    let index = tempmails.findIndex((e) => e.id === active.id);
    tempmails[index].status = "Scheduled";
    tempmails[index].scheduledDate = tempSchedule;
    setEmails(tempmails);
    setInitSchedule(false);
    setActive(null);
  }

  const navigate = useNavigate();
  function changeRoute() {
    setHideNav(false);
    localStorage.setItem("activeprojecttab", 1);
    if (survey.isProgram) {
      navigate(`/program/${survey.id}/survey-build`)
    } else {
      navigate(`/project/${survey.id}/survey-build`)
    }

  }

  return (
    <>
      <div
        className={styles.page}
        style={{ backgroundColor: page === 2 ? getBack() : "" }}
      >
        {/* <div className={styles.header}>
          Launch a new iteration of "{survey.name}"
        </div> */}
        <div className={styles.close}>
          <i onClick={() => setShow(false)} className="bi-x-lg"></i>
          </div>
        {page === 1 && (
          <>
            <div className={styles.settingSection} >
              <div className={styles.container}>
                <div className={styles.header}>
                  Choose details of survey iteration
                </div>
                <Player
                  autoplay
                  loop
                  src={require("assets/animations/Pencil.json")}
                  style={{
                    height: "100px",
                    width: "100px",
                    margin: "-30px 0px",
                  }}
                />
              </div>
              <div className={styles.setting}>
                <Label
                  tooltipText={
                    "This is the name of your Iteration. You will be able to identify your survey from this name"
                  }
                  labelIcon={<i className="bi-info-circle"></i>}
                >
                  Survey Name
                </Label>
                <TextFieldSimple
                  valid={validName}
                  invalidMessage="Give your survey a name"
                  value={surveyName}
                  placeholder="Give your survey a name..."
                  onChange={changeName}
                ></TextFieldSimple>
              </div>
              <div className={styles.setting}>
                <Label
                  tooltipText={
                    "This a short description of what your survey will entail."
                  }
                  labelIcon={<i className="bi-info-circle"></i>}
                >
                  Survey Description
                </Label>
                <TextFieldSimple
                  value={surveyDescription}
                  placeholder="Give a description of your survey..."
                  onSave={(value) => setSurveyDescription(value)}
                ></TextFieldSimple>
              </div>
              <div className={styles.setting}>
                <Label
                  tooltipText={
                    "This tag will enable you to easily analyze the results of the survey. For example, you can trend surveys that have the same survey tag."
                  }
                  labelIcon={<i className="bi-info-circle"></i>}
                >
                  Survey Tag
                </Label>
                <div style={{ width: "100%" }}>
                  {surveyTagsQuery.isLoading && (
                    <TextFieldSimple
                      placeholder="Type new tag or select..."
                      disable
                    ></TextFieldSimple>
                  )}
                  {surveyTagsQuery.isSuccess && (
                    <SelectField
                      selectMultiple
                      onChange={(chosenTags) => setPickedTags(chosenTags)}
                      options={surveyTagsQuery?.data?.surveyTagByOrgId.map(
                        (t) => {
                          return { value: t.id, label: t.label };
                        }
                      )}
                      value={pickedTags}
                      placeholder="Type new tag or select..."
                    ></SelectField>
                  )}
                </div>
              </div>
              <div className={styles.setting}>
                <Label
                  tooltipText={
                    "Depending on what survey type you choose, your results will adjust to give you more relevant breakouts and data.s"
                  }
                  labelIcon={<i className="bi-info-circle"></i>}
                >
                  What kind of survey is it?
                </Label>

                <div style={{ width: "100%" }}>
                  <SelectField
                    onChange={(chosen) =>
                      setType(chosen ? chosen.value : undefined)
                    }
                    options={SurveyTypes.map((val) => {
                      return { value: val, label: val };
                    })}
                    value={type ? { value: type, label: type } : undefined}
                    isClearable
                    placeholder="Choose a survey type..."
                  ></SelectField>
                </div>
              </div>
              <div
                className={styles.setting}
                style={{ display: "flex", justifyContent: "end", gap: "1em" }}
              >
                <Button red shadow onClick={() => setShow(false)}>
                  Cancel
                </Button>
                <Button shadow onClick={() => setPage(2)}>
                  Next
                </Button>
              </div>
            </div>
          </>
        )}
        {page === 2 && (
          <div className={styles.settingSection} >
            <div className={styles.container}>
              <div className={styles.header}>
                Confirm the design of the survey
              </div>
              <Player
              speed={.3}
                autoplay
                loop
                src={require("assets/animations/survey.json")}
                style={{
                  height: "70px",
                  width: "70px",
                  margin: "-20px 0px",
                }}
              />
            </div>
            <Survey projectId={survey?.id} preview></Survey>
            <div
              className={styles.setting}
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "1em",
                position: "sticky",
                bottom: "1em",
                width: "100%",
                maxWidth: "650px",
              }}
            >
              <Button shadow seafoam onClick={changeRoute}>
                Edit Survey
              </Button>
              <Button shadow onClick={() => setPage(1)}>
                Back
              </Button>
            
              <Button shadow onClick={() => setPage(3)}>
                Looks Good <i className="bi-hand-thumbs-up"></i>
              </Button>
            </div>
          </div>
        )}
        {page === 3 && (
          <div className={styles.settingSection}>
             <div className={styles.container} style={{gap: "1em"}}>
              <div className={styles.header}>
                Confirm message design
              </div>
              <Player
              speed={.4}
                autoplay
                loop
                src={require("assets/animations/send2.json")}
                style={{
                  height: "200px",
                  width: "200px",
                  margin: "-75px 0px",
                }}
              />
            </div>
            <Messages
              survey={survey}
              setActive={setActive}
              setInitSchedule={setInitSchedule}
              setPage={setPage}
              setEmails={setEmails}
              emails={emails}
            ></Messages>
          </div>
        )}
      </div>
      {active && !initSchedule && (
        <Email
          show={active}
          setShow={setActive}
          email={active}
          // initschedule={initSchedule}
          // initpreview={preview}
          // updateEmail={handleEmailUpdate}
          // createEmail={handleCompose}
          roles={roles}
          userId={currUser.id}
          projectStatus={survey?.status}
          survey={survey}
          iteration
          iterationSchedule={setInitSchedule}
        ></Email>
      )}

      {initSchedule && active && (
        <Scheduler
          currEmail={active}
          showSchedule={initSchedule}
          setShowSchedule={setInitSchedule}
          nextText={"Done"}
          handleScheduleChange={handleScheduleChange}
          setValidateSend={finishSchedule}
          onClose={() => {
            setInitSchedule(false);
            setActive(null);
          }}
        ></Scheduler>
      )}
    </>
  );
}

export default LaunchNext;

function Messages({
  survey,
  setActive,
  setInitSchedule,
  setPage,
  emails,
  setEmails,
}) {
  const [pageSort, setPageSort] = useState({
    item: "index",
    descend: false,
  });
  const [perPage, setPerpage] = useState(0);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchString, setSearchString] = useState("");
  const getDistributions = useSearchDistributions(
    survey.id,
    pageSort,
    pageSkip,
    perPage,
    searchString,
    "email",
    "allemails"
  );

  return (
    <div>
      {getDistributions.isLoading && <Loading></Loading>}
      {getDistributions.isSuccess && (
        <MessagesHandler
          survey={survey}
          setActive={setActive}
          setInitSchedule={setInitSchedule}
          initemails={getDistributions.data.distributions}
          setPage={setPage}
          emails={emails}
          setEmails={setEmails}
        />
      )}
    </div>
  );
}

function MessagesHandler({
  survey,
  setActive,
  setInitSchedule,
  initemails,
  setPage,
  emails,
  setEmails,
}) {
  useEffect(() => {
    setEmails(initemails);
  }, []);

  function handleOnClick(item, where) {
    if (where === "schedule") {
      setInitSchedule(true);
    }
    setActive(item);
  }

  return (
    <>
      {" "}
      <EmailWidgets
        surveys={emails}
        survey={survey}
        onClick={handleOnClick}
        iteration
      ></EmailWidgets>{" "}
      <div
        className={styles.setting}
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "1em",
          width: "100%",
          paddingRight: "3em",
        }}
      >
        <Button shadow onClick={() => setPage(2)}>
          Back
        </Button>
        <Button shadow onClick={() => setPage(3)}>
          Finish
        </Button>
      </div>
    </>
  );
}
