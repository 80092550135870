import styles from "./ChoiceMatrixOption.module.scss";

export function ChoiceMatrixOption({
  choice,
  option,
  selected,
  handleCheck,
  color,
  rank,
  multi,
}) {
  return (
    <div className={styles.checkbox}>
      {!multi && !rank && (
        <label className={styles.container}>
          <input
            type="checkbox"
            value={option}
            checked={selected.includes(choice)}
            onChange={(e) => handleCheck(e.target.checked)}
          />
          <span
            className={styles.checkmark}
            style={selected.includes(choice) ? { backgroundColor: color } : {}}
          ></span>
        </label>
      )}
      {multi && (
        <label className={styles.container}>
          <input
            type="checkbox"
            value={option}
            onChange={(e) => handleCheck(e.target.checked)}
            checked={selected.includes(choice)}
          />
          <span
            className={`${styles.checkmark} ${styles.multi}`}
            style={selected.includes(choice) ? { backgroundColor: color } : {}}
          ></span>
        </label>
      )}
      {rank && (
        <label className={styles.containerRank}>
          <input
            type="checkbox"
            value={option}
            onChange={(e) => handleCheck(e.target.checked)}
            checked={selected.includes(choice)}
          />
          <span
            className={`${styles.checkmarkRank} ${styles.multiRank}`}
            style={selected.includes(choice) ? { backgroundColor: color } : {}}
          >
            <p className={styles.ranking}>{selected.indexOf(choice) + 1}</p>
          </span>
        </label>
      )}
    </div>
  );
}
