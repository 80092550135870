import React, { useState, useEffect } from "react";
import styles from "./Manual.module.scss";
import Editor from "components/tables/EditableTable/App";

export function Manual({ setData, data, customFields, setHeaders, setHeaderMap, checkMap }) {

  const [startColumns, setStartColumns] = useState(
    customFields.map((c) => {
      return {
        ...c,
        id: c.name,
        label: c.displayName,
        accessor: c.name,
        // width: 250,
        dataType: "text",
        options: [],
        enabled: true,
      };
    })
  );

  useEffect(() => {
    if (startColumns) {
      let headMap = {};
      let final = [];
      for (let field of startColumns) {
        if (field.enabled) {
          final.push(field);
          checkMap(field, headMap, customFields);
        }
      }
      setHeaders(final);
      setHeaderMap(headMap);
    }
  }, []);


  return (
    <div className={styles.manualPage}>
      <Editor setList={setData} startColumns={startColumns} startData={data}></Editor>
    </div>
  );
}
