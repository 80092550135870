import React from "react";
import styles from "./Stat.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";

function StatBox({
  title,
  titleStyle,
  value,
  header,
  style,
  small,
  valueStyle,
  outof,
  underline,
  description,
  descriptionStyle,
  details,
  onClickDetails
}) {
  return (
    <div
      className={`${header && styles.headerStyle} ${
        small && styles.smallStyle
      } ${!header && !small ? styles.statContainer : ""}`}
      style={style}
    >
      <div className={styles.title} style={{...titleStyle, ...{ textDecoration: underline ? "underline" : ""}}}>
        {title}
      </div>{" "}
      <div className={styles.value} style={valueStyle}>
        {value} {typeof outof === "string" ? <div className={styles.outof}>{outof}</div> : outof > 0 && <div className={styles.outof}>/{outof}</div>}
      </div>
      <FlexRow>
      <div className={styles.description} style={descriptionStyle}>
        {description}
      </div>
      {details &&<Button link onClick={onClickDetails} style={{fontSize: ".7rem"}}>{details}</Button>}
      </FlexRow>
    </div>
  );
}

export default StatBox;
