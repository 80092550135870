// External
import { TextFieldSimple } from "components/inputs";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import React, { useEffect, useRef, useState } from "react";

//Internal
import styles from "./AddButton.module.scss";
import { Label } from "components/layouts/Label/Label";
import Button from "components/Button/Button";

export const AddButton = ({
  handleOnDrag,
  surveyButtonText,
  setSurveyButtonText,
  surveyLinkText,
  setSurveyLinkText,
  surveyLinkStyle,
  setSurveyLinkStyle,
  linkColor,
  setLinkColor,
  editor,
}) => {
  //eslint-disable-next-line no-unused-vars
  const [edit, setEdit] = useState(false);
  const [chosenButton, setChosenButton] = useState(
    <a
      draggable={true}
      onDragStart={(e) => handleOnDrag(e)}
      style={{
        color: linkColor,
      }}
      href="{{insertlinkhere}}"
    >
      <span
        style={{
          color: linkColor,
        }}
      >
        {surveyLinkText ? surveyLinkText : "Take Survey"}
      </span>
    </a>
  );
  return (
    <div className={styles.elements}>
      <div className={styles.surveyButtonContainer}>
        <div onClick={() => setEdit(!edit)}>
          <Label style={{ padding: "0em" }}>Your Survey Link</Label>
        </div>
        <div className={styles.surveyLink}>
          <span
            id="ReactionDataButtonLink2"
            onDragStart={(e) => handleOnDrag(e)}
            draggable={true}
            href="{{insertlinkhere}}"
            style={{ cursor: "pointer", width: "fit-content" }}
          >{`{Survey Link}`}</span>
          {!edit && (
            <div className={styles.icon}>
              <Button
                onClick={() => setEdit(!edit)}
                style={{ display: "flex:", gap: "1em" }}
                shadow
              >
                Edit <i className="bi-pencil "></i>
              </Button>
            </div>
          )}
          {edit && (
            <div className={styles.edit}>
              <div className={styles.linkSettings}>
                <div className={styles.surveyButtonField}>
                  <TextFieldSimple
                    placeholder="Button Text..."
                    onChange={setSurveyLinkText}
                    value={surveyLinkText}
                    label="Link Text"
                  ></TextFieldSimple>
                  <div className={styles.pickerContainer}>
                    <Label style={{ textTransform: "none" }}>Link Color</Label>
                    <div className={styles.colorPicker}>
                      <ColorPicker
                        defaultColor={linkColor}
                        onChange={(color) => setLinkColor(color)}
                      ></ColorPicker>
                    </div>
                  </div>
                </div>
                <Label
                  tooltipText={
                    "Click on a link/button below to choose style or drag the link/button directly into editor"
                  }
                  labelIcon={<i className="bi bi-question-circle"></i>}
                  style={{ marginTop: ".5em" }}
                >
                  Choose Style
                </Label>
                <div className={styles.linksContainer}>
                  <span
                    style={{
                      color: linkColor,
                      cursor: "pointer",
                    }}
                    draggable={true}
                    onDragStart={(e) => handleOnDrag(e)}
                    onClick={() => {
                      setSurveyLinkStyle({
                        color: linkColor,
                        cursor: "pointer",
                      });
                    }}
                  >
                    Link
                  </span>
                  <span
                    style={{
                      color: "#ed9146",
                      backgroundColor: linkColor,
                      color: "#fff",
                      fontSize: "16px",
                      borderRadius: "5px",
                      padding: ".5em",
                      cursor: "pointer",
                    }}
                    draggable={true}
                    onDragStart={(e) => handleOnDrag(e)}
                    onClick={() => {
                      setSurveyLinkStyle({
                        color: "#ed9146",
                        backgroundColor: linkColor,
                        color: "#fff",
                        fontSize: "16px",
                        borderRadius: "5px",
                        padding: ".5em",
                        cursor: "pointer",
                      });
                    }}
                  >
                    Bubble
                  </span>
                </div>
                <div className={styles.yourbutton}>
                  <Label
                    tooltipText={
                      "This is what your button will look like in the email"
                    }
                    labelIcon={<i className="bi bi-question-circle"></i>}
                    style={{ marginTop: ".5em" }}
                  >
                    Your Button
                  </Label>
                  <div className={styles.chosen}>
                    <span style={surveyLinkStyle}>{surveyLinkText}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
