import React, { useState } from "react";
import styles from "./TextField.module.scss";

/**
 * A simple and customized text field
 * @param {String} value value that is passed in as initial value and/or changeable value for the text field
 * @param {String} placeholder a placeholder for the text field
 * @param {Function} onChange called when the text field changes/is typed in and returns the event which contains the value accessed with : (e.target.value)
 * @param {string} concerning used to update an object through it's property or accessor
 * @param {Function} onSave used to reduce the amount of re-renders to update an object;
 * @returns {React.ReactElement} a simple and customized text field
 */

export const TextFieldSimple = ({
  onChange,
  value,
  placeholder,
  type,
  name,
  password,
  customStyles,
  inactive,
  label,
  concerning,
  onSave,
  disable
}) => {
  // const[val, setVal] = useState(value ? value : "");

  function handleOnChange(e) {
    if (onChange) {
      if (concerning) {
        onChange(e.target.value, concerning);
      } else {
        onChange(e.target.value);
      }
    }
    // setVal(e.target.value);
  }

  function handleFocusOut(e) {
    if (onSave) {
      if (concerning) {
        onSave(e.target.value, concerning);
      } else {
        onSave(e.target.value);
      }
    }
  }

  return (
    <>
      {!inactive && label && <div className={styles.label}>{label}</div>}
      <input
        type={type}
        name={name}
        onChange={handleOnChange}
        onBlur={handleFocusOut}
        className={`${customStyles ? customStyles : styles.textField} ${
          password ? styles.password : ""
        } `}
        value={value}
        placeholder={placeholder}
        disabled={disable}
      ></input>
    </>
  );
};
