import React, { useState } from "react";
import styles from "./AudienceOverview.module.scss";
import { NavBar } from "components/sidebars/NavBar/NavBar";
import Button from "components/Button/Button";
import AudienceSettings from "./AudienceSettings";

function AudienceOverview({ audience, settings, setSettings }) {

  return (
    
        <div className={styles.page}>
          <div className={styles.top}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "2em",
              }}
            >
              <Button seafoam shadow onClick={() => setSettings(true)}>
                <div
                  style={{ display: "flex", gap: ".5em", alignItems: "center" }}
                >
                  <i className="bi bi-gear-fill"></i> Audience Settings
                </div>
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
                gap: "2em",
                flexWrap: "wrap",
              }}
            >
              <div>
                <div className={styles.header}>{audience?.name}</div>
                <div className={styles.description}>
                  {audience?.description}
                </div>
              </div>
              <div className={styles.description}>
                {" "}
                Most Recent Survey Participation:{" "}
              </div>
            </div>
          </div>
          <div style={{paddingLeft: "2em"}}>Audience Overview Page coming soon...</div>
          <a>View Most Recent Upload</a>
        </div>

  );
}

export default AudienceOverview;
