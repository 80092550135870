import React, { useState } from "react";
import styles from "./Billing.module.scss";
import Modal from "components/ReactModal/ReactModal";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import {
  useFetchOrganization,
  useUpdateOrgSettings,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { useParams } from "react-router-dom";
import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import PlanInput from "components/inputs/input_fields/PlanInput/PlanInput";

function Billing({ roles, user }) {
  const { id } = useParams();
  const MyOrg = useFetchOrganization(id);

  return (
    <div className={styles.page}>
      {MyOrg.isLoading && <Loading></Loading>}
      {MyOrg.isSuccess && (
        <BillingContent
          organization={MyOrg.data.organization}
          role={roles}
          user={user}
        ></BillingContent>
      )}
    </div>
  );
}

export default Billing;

function BillingContent({ organization, role, user }) {
  const [org, setOrg] = useState({ ...organization });
  const [BtnMsg, setBtnMsg] = useState("Save Changes");
  const [editInvoice, setEditInvoice] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [editPlan, setEditPlan] = useState(false);
  const [edit, setEdit] = useState(true);
  const updateOrg = useUpdateOrgSettings();

  function onChangeBilling(val, field) {
    let edits = { ...org };
    edits[field] = val;
    setOrg(edits);
  }

  function handleCancel() {
    setEditAddress(false);
    setEditPlan(false);
    setOrg({ ...organization });
    // setEdit(false);
  }
  function handleSave() {
    if (role?.canUpdateOrganization) {
      setBtnMsg(
        <div style={{ display: "flex", gap: "5px" }}>
          Saving <Loading height={20} width={20}></Loading>
        </div>
      );
      updateOrg.mutate(
        {
          updateOrganizationId: organization.id,
          data: {
            billingAddress1: org?.billingAddress1,
            billingAddress2: org?.billingAddress2,
            billingCity: org?.billingCity,
            billingCountry: org?.billingCountry,
            billingPostalCode: org?.billingPostalCode,
            billingState: org?.billingState,
            invoiceAddress1: org?.invoiceAddress1,
            invoiceAddress2: org?.invoiceAddress2,
            invoiceCity: org?.invoiceCity,
            invoiceCountry: org?.invoiceCountry,
            invoicePostalCode: org?.invoicePostalCode,
            invoiceState: org?.invoiceState,
            adminsAllowed: org?.adminsAllowed,
            usersAllowed: org?.usersAllowed,
            surveysAllowed: org?.surveysAllowed,
            responsesAllowed: org?.responsesAllowed,
            contactsAllowed: org?.contactsAllowed,
            reportsAllowed: org?.reportsAllowed,
            planPrice: org?.planPrice,
            planFrequency: org?.planFrequency,
            isLiscense: org?.isLiscense,
          },
        },
        {
          onSuccess: (data) => {
            // console.log("Updated Org: ", data);
            setBtnMsg(
              <div style={{ display: "flex", gap: "5px" }}>
                Saved <i className="bi bi-check-lg"></i>
              </div>
            );
            setTimeout(() => {
              location.reload();
            }, 750);
          },
        }
      );
    }
  }

  return (
    <>
      {/* <div className={styles.section_label5} style={{ margin: "0" }}>
        Billing
      </div>
      <CombinedInput
        value={`${org?.billingAddress1}${
          org?.billingAddress2 ? "," + org?.billingAddress2 : ""
        } ${org?.billingCity}, ${org?.billingState} ${
          org?.billingPostalCode
        } \n ${org?.billingCountry}`}
        placeholder="Billing Address..."
        label="Billing Address"
        shadow
        icon={"bi-pencil"}
        onIconClick={() => setEditAddress(true)}
        style={{ backgroundColor: "white" }}
      />
      <CombinedInput
        value={`${org?.invoiceAddress1}${
          org?.invoiceAddress2 ? "," + org?.invoiceAddress2 : ""
        } ${org?.invoiceCity}, ${org?.invoiceState} ${
          org?.invoicePostalCode
        } \n ${org?.invoiceCountry}`}
        placeholder="Invoice Address..."
        label="Invoice Address"
        shadow
        icon={"bi-pencil"}
        onIconClick={() => setEditInvoice(true)}
        style={{ backgroundColor: "white" }}
      />
      <CombinedInput
        value={""}
        placeholder=" "
        label="Payment Method"
        shadow
        icon={"bi-pencil"}
        // onIconClick={() => setEditInvoice(true)}
        style={{ backgroundColor: "white" }}
      />
      <div className={styles.section_label5} style={{ margin: "0" }}>
        Invoices
      </div> */}{" "}
      <div className={styles.section}>
        <FlexRow>
          <div className={styles.header_3} style={{ fontWeight: "400" }}>
            Plan Information
          </div>
          <Button
            shadow
            style={{ gap: ".5rem" }}
            onClick={() => setEditPlan(true)}
          >
            <i className="bi-pencil"></i> Edit
          </Button>
        </FlexRow>
        <div className={styles.header_6} style={{ fontWeight: "400" }}>
          Custom Plan
        </div>
        <div className={styles.infoTable}>
          <div className={`${styles.infoRow} ${styles.gray} ${styles.top}`}>
            <span>Plan Type</span>
            <span>{organization.isLiscense ? "Liscense" : "Subscription"}</span>
          </div>
          <div className={`${styles.infoRow} ${styles.dark}`}>
            <span>
              {organization.isLiscense ? "Liscense" : "Subscription"} Duration
            </span>
            <span>{organization.planFrequency / 12} year</span>
          </div>

          <div className={`${styles.infoRow} ${styles.gray}`}>
            <span>
              {organization.isLiscense ? "Liscense" : "Subscription"} Price
            </span>
            <span>
              {organization?.planPrice ? organization?.planPrice : "Unlimited"}
            </span>
          </div>
          <div className={`${styles.infoRow} ${styles.dark}`}>
            <span>Admin Users</span>
            <span>
              {organization?.adminsAllowed
                ? organization?.adminsAllowed
                : "Unlimited"}
            </span>
          </div>
          <div className={`${styles.infoRow} ${styles.gray}`}>
            <span>Other Users</span>
            <span>
              {organization?.usersAllowed
                ? organization?.usersAllowed
                : "Unlimited"}
            </span>
          </div>
          <div className={`${styles.infoRow} ${styles.dark}`}>
            <span>Projects</span>
            <span>
              {organization?.surveyAllowed
                ? organization?.surveyAllowed
                : "Unlimited"}
            </span>
          </div>
          <div className={`${styles.infoRow} ${styles.gray}`}>
            <span>Responses per Survey</span>
            <span>
              {organization?.responsesAllowed
                ? organization?.responsesAllowed
                : "Unlimited"}
            </span>
          </div>
          <div className={`${styles.infoRow} ${styles.dark}`}>
            <span>Contacts</span>
            <span>
              {organization?.contactsAllowed
                ? organization?.contactsAllowed
                : "Unlimited"}
            </span>
          </div>
          <div className={`${styles.infoRow} ${styles.gray}`}>
            <span>Analysis Reports</span>
            <span>
              {organization?.reportsAllowed
                ? organization?.reportsAllowed
                : "Unlimited"}
            </span>
          </div>
        </div>
      </div>
      {/* <div className={styles.section_label5} style={{ margin: "0" }}>
        Product Plan
      </div>
      <CombinedInput
        value={
          <FlexRow start>
            <Icon iconName={"star"} yellow></Icon> Custom Plan
          </FlexRow>
        }
        placeholder=" "
        label="Current Plan"
        shadow
        icon={"bi-pencil"}
        onIconClick={() => setEditPlan(true)}
        style={{ backgroundColor: "white" }}
        select
      />
      <div className={styles.section_label5} style={{ margin: "0" }}>
        Add-ons
      </div> */}
      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button red height={35} shadow>
          Cancel Account
        </Button>
      </div> */}
      <Modal
        show={editAddress || editInvoice || editPlan}
        onClose={handleCancel}
        modalStyle={{
          padding: "2em",
          width: "600px",
          height: "100%",
          gap: "1rem",
        }}
        dark
        rightStyle
      >
        {editPlan && (
          <>
            <div className={styles.header_3} style={{ margin: "0" }}>
              Current Plan <span>(Custom)</span>
            </div>
            <PlanInput
              org={org}
              onChange={onChangeBilling}
              billing
              roles={role}
              canEdit={user.isSuperAdmin}
              editing={edit}
            ></PlanInput>
          </>
        )}

        {/* {editAddress && (
          <>
            <div className={styles.header_3} style={{ margin: "0" }}>
              Billing Address
            </div>
            <AddressInput
              org={org}
              onChange={onChangeBilling}
              billing
            ></AddressInput>
          </>
        )}
        {editInvoice && (
          <>
            <div className={styles.header_3} style={{ margin: "0" }}>
              Invoice Address
            </div>
            <AddressInput
              org={org}
              onChange={onChangeInvoice}
              invoice
            ></AddressInput>
          </>
        )} */}
        {user.isSuperAdmin && !edit && (
          <div
            style={{
              display: "flex",
              gap: "1em",
              paddingTop: ".5em",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => setEdit(true)}
              shadow
              blue
              disable={!user.isSuperAdmin}
            >
              Edit Plan
            </Button>
          </div>
        )}
        {user.isSuperAdmin && edit && (
          <div
            style={{
              display: "flex",
              gap: "1em",
              paddingTop: ".5em",
              justifyContent: "center",
            }}
          >
            <Button
              red
              onClick={handleCancel}
              shadow
              link
              disable={!user.isSuperAdmin}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              shadow
              blue
              disable={!user.isSuperAdmin}
            >
              {BtnMsg}
            </Button>
          </div>
        )}
        {!user.isSuperAdmin && (
          <span
            className={styles.text_1}
            style={{ textAlign: "center", marginTop: "2rem" }}
          >
            Talk to your Reaction Account Manager to adjust your plan
          </span>
        )}
      </Modal>
    </>
  );
}
