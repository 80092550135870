// External
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Internal
import {
  // Organization pages
  OrganizationDetails,
  Users,
  UserGroups,
  DistributionSettings,
  Roles,
  AccessGroups,
} from "pages";
import { OrgImages } from "pages/organization/org-images/org-images";
import { useGetCurrentUser } from "api/resources/organization/users";
import { useFetchRole } from "api/resources/organization/roles";
import Billing from "pages/organization/organization_details/Billing";
import Dashboards from "pages/organization/organization_details/Dashboards";
import NLP from "pages/organization/organization_details/NLP";
import EmailPref from "pages/organization/organization_details/EmailPref";
import CRM from "pages/organization/organization_details/CRM";
import SalesforceSettings from "pages/organization/organization_details/SalesforceSettings";

export const OrganizationRoutes = () => {
  // console.log(role)
  const getCurrUser = useGetCurrentUser();
  const getCurrRole = useFetchRole();
  return (
    <>
      {getCurrUser.isSuccess && getCurrRole.isSuccess && (
        <Routes>
          <Route path="/" element={<Navigate to="org-details" />} />
          {getCurrRole.data.role.canViewOrgDetails && (
            <>
              <Route
                path="org-details/*"
                element={<OrganizationDetails role={getCurrRole.data.role} />}
              />

              <Route path="org-images/*" element={<OrgImages />} />

              <Route
                path="billing-product"
                element={
                  <Billing
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data.me}
                  />
                }
              />

              <Route
                path="email-preferences"
                element={
                  <EmailPref
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data.me}
                  />
                }
              />

              <Route
                path="dashboards"
                element={<Dashboards roles={getCurrRole.data.role} />}
              />

              <Route
                path="nlp"
                element={<NLP roles={getCurrRole.data.role} />}
              />

              <Route
                path="contact-management"
                element={
                  <CRM
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data.me}
                  />
                }
              />
              <Route
                path="Salesforce"
                element={
                  <SalesforceSettings
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data.me}
                  />
                }
              />
            </>
          )}
        </Routes>
      )}
    </>
  );
};

export const UserGroupsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="Roles" />} />
      <Route path="Roles/*" element={<Roles />} />
      <Route path="AccessGroups/*" element={<AccessGroups />} />
    </Routes>
  );
};

// export const OrgDetailsRoutes = () => {
//   return (
//     <Routes>
//       <Route path="/" element={<Navigate to="Roles" />} />
//       <Route path="Roles/*" element={<Roles />} />
//       <Route path="AccessGroups/*" element={<AccessGroups />} />
//     </Routes>
//   );
//:id
