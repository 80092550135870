import { useEffect, useState } from "react";
import Button from "components/Button/Button";
import styles from "./EditLogo.module.scss";
import { LogoUpload } from "./LogoUpload";
import FlexRow from "components/layouts/FlexRow/FlexRow";

export default function EditableLogo({
  settings,
  setSettings,
  projectLogo,
  rightCorner,
  onSaveNewImage,
}) {
  const [height, setHeight] = useState(settings.logoSize);

  const [dragging, setDragging] = useState(false);
  const [initialY, setInitialY] = useState(0);
  const [initialX, setInitialX] = useState(0);

  const [maxWidth, setMaxWidth] = useState(636); // max width for largest container size

  const handleMouseDown = (e) => {
    setDragging(true);
    setInitialY(e.clientY);
    setInitialX(e.clientX);
  };

  function finishDragging() {
    if (!dragging) return;

    setDragging(false);
    setInitialY(0);
    setInitialX(0);
    let copy = { ...settings };
    copy.logoSize = height;
    setSettings(copy);
  }

  const handleMouseMove = (e) => {
    if (!dragging) return;

    let logo = document.getElementById("logo");
    if (logo) {
      const width = logo.clientWidth;

      const deltaY = e.clientY - initialY;
      const deltaX = rightCorner ? initialX - e.clientX : e.clientX - initialX;

      let resultY = height + deltaY;
      let resultX = width + deltaX;

      if (resultX >= 50 && resultX < maxWidth && resultY >= 15) {
        setHeight(resultY);
        setInitialY(e.clientY);
        setInitialX(e.clientX);
      }
    }
  };

  function checkMaxWidth() {
    let logoContainer = document.getElementById("logoContainer");
    if (logoContainer) {
      let maxWidth = logoContainer.clientWidth - 32; // - 16 pixels of padding on each side (16 = 1em)
      setMaxWidth(maxWidth);
    }
  }

  useEffect(() => {
    checkMaxWidth();
    window.addEventListener("resize", checkMaxWidth);
    return () => window.removeEventListener("resize", checkMaxWidth);
  }, []);

  const [image, setImage] = useState("");
  const [file, setFile] = useState("");

  const handleChange = (event) => {
    let selectedFile = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    }
  };

  function switchOut() {
    let input = document.getElementById("imageInput");
    if (input) {
      input.click();
    }
  }

  function onUse() {
    onSaveNewImage(file);
    onCancel();
  }

  function onCancel() {
    setImage("");
    setFile("");
  }

  return (
    <>
      <input
        type="file"
        accept="image/*"
        onChange={handleChange}
        style={{ display: "none" }}
        id="imageInput"
      />
      <div className={styles.logoBox}>
        <div
          className={styles.dragBox}
          style={{
            paddingLeft: `${rightCorner ? "7" : "0"}px`,
            paddingRight: `${rightCorner ? "0" : "7"}px`,
          }}
        >
          <div className={styles.changeLogoDiv}>
            <img
              style={{
                // height: `${50}px`,
                width: `${150}px`,
              }}
              src={
                image
                  ? image
                  : projectLogo != null
                  ? projectLogo.imageURL
                  : "/static/media/reactionlogodark.7d895fefebc952458ad6.png"
              }
              alt="survey logo"
              id="logo"
            />
            {/* <div className={styles.logoCover}>
                <div className={styles.edit}>
                  <Button onClick={() => setUploadImg(true)}>Edit</Button>
                </div>
              </div> */}
          </div>
          <div
            className={`${styles.dragHandle}  ${
              rightCorner ? styles.downLeft : styles.downRight
            }`}
            style={
              rightCorner
                ? { left: 0, cursor: "nesw-resize" }
                : { right: 0, cursor: "nwse-resize" }
            }
            onMouseDown={handleMouseDown}
          >
            <i className="bi bi-caret-down-fill"></i>
          </div>
        </div>
        {!image && !file && (
          <Button shadow onClick={switchOut}>
            Change Logo
          </Button>
        )}
        <FlexRow style={{ flexWrap: "wrap" }} start>
          {image && file && (
            <>
              <Button seafoam onClick={switchOut}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                  shadow
                >
                  Switch <i className="bi bi-arrow-down-up"></i>
                </div>
              </Button>
              <Button shadow onClick={onUse}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  Confirm <i className="bi bi-floppy"></i>
                </div>
              </Button>
              <Button red shadow  onClick={onCancel}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  Cancel <i className="bi bi-x"></i>
                </div>
              </Button>
            </>
          )}
        </FlexRow>
      </div>
    </>
  );
}
