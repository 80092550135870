/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";

// GRAPHQL API

export const useGetCurrentUser = () => {
  const query = gql`
    query {
      me {
        id
        firstName
        lastName
        email
        prefix
        organizationId
        isSuperAdmin
        customField
        favoriteReports
        dashboardId
        organization {
          id
          name
          resultAnalysisId
          customFields
          usersAllowed
          adminsAllowed
        }
        roleId
        image {
          id
          imageURL
          position
          scale
          name
          about
        }
        roles {
          id
          name
          description
          organizationId
        }
        linkedAccounts {
          accountType
          id
        }
      }
    }
  `;

  return useGqlQuery(["me"], query, {});
};

export const useGetUsersBySharedReport = (reportId) => {
  const query = gql`
    query ($reportId: String!) {
      response: userBySharedReport(reportId: $reportId) {
        users {
          id
          email
          image {
            imageURL
          }
        }
        userCount
      }
    }
  `;

  return useGqlQuery(["usersBySharedReport: " + reportId], query, {
    reportId: reportId,
  });
};

export const useFetchMyLastSeenReports = (limit) => {
  const query = gql`
    query {
      getLastSeenReports(limit: ${limit}) {
        content
        id
        name
      }
    }
  `;

  return useGqlQuery([limit + "recentReports"], query, {});
};

export const useFetchUser = (id) => {
  const query = gql`
    query {
      user(id: "${id}") {
        id
        firstName
        lastName
        email
        customField
        image {
          id
          name
          about
          imageURL
          position
          scale
        }
        activeDistConfigId
        linkedAccounts {
          id
          accountType
        }
        attribute {
          id
          customFieldId
          name
        }
      }
    }
  `;

  return useGqlQuery(["fetchUser" + id], query, {});
};

export const useFetchUsersByOrgId = (
  searchString,
  page,
  perPage,
  sort,
  orgId
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortUser!
      $orgId: String!
    ) {
      users: userByOrgId(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        orgId: $orgId
      ) {
        users {
          firstName
          lastName
          email
          id
          customField
          dashboardId
          position
          roles {
            id
            name
            description
            organizationId
          }
          image {
            id
            imageURL
            position
            scale
          }
        }
        count
        loggedIn
        loggedOut
        admins
        userCount
      }
    }
  `;

  return useGqlQuery(["usersbyorgids", searchString], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
    orgId: orgId,
  });
};

export const useFetchUsersByOrgIdCount = (
  searchString,
  page,
  perPage,
  sort,
  orgId
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortUser!
      $orgId: String!
    ) {
      count: userByOrgIdCount(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        orgId: $orgId
      )
    }
  `;

  return useGqlQuery(["usersbyorgCount", searchString], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
    orgId: orgId,
  });
};

export const useSearchUsersPages = (searchString, page, perPage, sort) => {
  const query = gql`
    query SearchUsers(
      $sort: sortUser!
      $perPage: Float!
      $page: Float!
      $search: String!
    ) {
      users: searchUsers(
        sort: $sort
        perPage: $perPage
        page: $page
        search: $search
      ) {
        firstName
        lastName
        email
        id
        customField
        dashboardId
        image {
          id
          imageURL
          position
          scale
        }
        roles {
          organizationId
          id
          name
        }
      }
    }
  `;

  return useGqlQuery([`search ${perPage} users: ` + searchString], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
  });
};

export const useFetchUserActivity = (
  searchString,
  page,
  perPage,
  sort,
  id,
  orgId,
  one
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortUser!
      $id: String!
      $orgId: String!
    ) {
      object: getUserActivity(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        id: $id
        orgId: $orgId
      ) {
        activities {
          id
          message
          createdAt
        }
        count
      }
    }
  `;

  return useGqlQuery(
    ["getActivity:" + id + ":" + one + ":" + orgId, searchString],
    query,
    {
      search: searchString,
      page: page,
      perPage: perPage,
      sort: sort,
      id: id,
      orgId: orgId,
    }
  );
};

export const useGetUserById = (id) => {
  const query = gql`
    query GetUserById($userId: String!) {
      getUserById(userId: $userId) {
        id
        firstName
        lastName
        prefix
        email
        verified
        phone
        phoneCode
        MFAVerified
        MFAVerifiedDate
      }
    }
  `;

  return useGqlQuery(["usersName " + id], query, { userId: id });
};

export const useSearchUser = (search) => {
  const query = gql`
    query Query($search: String!) {
      searchUser(search: $search) {
        id
        firstName
        lastName
        prefix
        email
      }
    }
  `;

  return useGqlQuery(["userSearch " + search], query, { search: search });
};

export const useUpdateUser = () => {
  const mutation = gql`
    mutation UpdateUser($data: UserInput!) {
      updateUser(data: $data)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("me");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateUserById = () => {
  const mutation = gql`
    mutation UpdateUser(
      $data: UserInput!
      $id: String!
      $attributeData: AttributeUpdate
    ) {
      updateUserById(data: $data, id: $id, attributeData: $attributeData)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("updateUser");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useRemoveUserFromOrg = () => {
  const mutation = gql`
    mutation Mutation($orgId: String!, $userId: String!) {
      removeUserFromOrg(orgId: $orgId, userId: $userId)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("updateUser");
    },
  };

  return useGqlMutation(mutation, options);
};
