// External
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Internal
import OrganizationReports from "pages/reports/OrganizationReports";
import { useGetCurrentUser } from "api/resources/organization/users";

export const AllReportsRoutes = ({ roles}) => {
  const getCurrUser = useGetCurrentUser();
  return (
    <>
      {getCurrUser.isSuccess && (
        <Routes>
          <Route path="/" element={<Navigate to="home" />} />
          {roles.canViewOrgReports && (
            <Route
              path="/home"
              element={
                <OrganizationReports
                  roles={roles}
                  user={getCurrUser.data?.me}
                  organization={getCurrUser.data?.me?.organization?.find(
                    (org) => org.id === getCurrUser.data?.me.organizationId
                  )}
                  creating
                  type={"Home"}
                  icon="house"
                />
              }
            />
          )}

          {roles.canViewOrgReports && (
            <Route
              path="/favorites"
              element={
                <OrganizationReports
                  roles={roles}
                  user={getCurrUser.data?.me}
                  organization={getCurrUser.data?.me?.organization?.find(
                    (org) => org.id === getCurrUser.data?.me.organizationId
                  )}
                  creating
                  type={"Favorites"}
                  icon="star"
                />
              }
            />
          )}
          {roles.canViewOrgReports && (
            <Route
              path="/myreports"
              element={
                <OrganizationReports
                  roles={roles}
                  user={getCurrUser.data?.me}
                  organization={getCurrUser.data?.me?.organization?.find(
                    (org) => org.id === getCurrUser.data?.me.organizationId
                  )}
                  creating
                  type={"Me"}
                  icon="person"
                />
              }
            />
          )}
          {roles.canViewOrgReports && (
            <Route
              path="/sharedwithme"
              element={
                <OrganizationReports
                  roles={roles}
                  user={getCurrUser.data?.me}
                  organization={getCurrUser.data?.me?.organization?.find(
                    (org) => org.id === getCurrUser.data?.me.organizationId
                  )}
                  creating
                  type={"Shared"}
                  icon="people"
                />
              }
            />
          )}
        </Routes>
      )}
    </>
  );
};
