// External
//eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Internal
import { ProjectRoutes } from "routes";
import styles from "./Project.module.scss";
import { Loading } from "components/Loading/Loading";
import { useFetchProjectGql } from "api/resources/projects/projects";
import { ErrorPage } from "pages";
import { useDownloadReportByAnswer } from "api/resources/projects/results";
import { NavBar } from "components/sidebars/NavBar/NavBar";
import { Page } from "components/layouts/Page/Page";
import Button from "components/Button/Button";
import SurveySettings from "./survey_details/SurveySettings";
import { AnimatePresence } from "framer-motion";
import { TabPill } from "components/layouts";

export const Project = ({
  resultsOnly,
  roles,
  organization,
  user,
  setBeforeNavAway,
  collapse,
  collapsed,
}) => {
  const { id } = useParams();
  const fetchProjectQuery = useFetchProjectGql(id);

  const [showSettings, setShowSettings] = useState(false);
  const [beforeNavAwayInProj, setBeforeNavAwayInProj] = useState();

  function checkBuildRole() {
    if (fetchProjectQuery.data.survey?.organizationId === organization.id) {
      return roles.canCreateEditOrgSurveys;
    } else {
      return true;
    }
  }

  function checkResultsRole(orgId) {
    if (fetchProjectQuery.data.survey?.organizationId === organization.id) {
      return roles.canViewRawResultsForOrg;
    } else {
      return true;
    }
  }

  function checkSendRole(orgId) {
    if (fetchProjectQuery.data.survey?.organizationId === organization.id) {
      return roles.canSendOrgSurveyEmails;
    } else {
      return true;
    }
  }

  function getItems(orgId) {
    return [
      {
        id: 0,
        name: "Details",
        to: `survey-details`,
        icon: "bi bi-list-task",
        iconClassName: "bi bi-list-task",
      },
      {
        id: 1,
        name: checkBuildRole(orgId) ? "Build" : "Preview Survey",
        to: checkBuildRole(orgId)
          ? `/project/${id}/survey-build`
          : "/project/${id}/preview",
        icon: checkBuildRole(orgId) ? "bi bi-hammer" : "",
        iconClassName: checkBuildRole(orgId) ? "bi bi-hammer" : "",
        noChange: !checkBuildRole(orgId),
      },
      checkSendRole(orgId)
        ? {
            id: 2,
            name: "Send",
            to: fetchProjectQuery.data.survey?.activeLink
              ? `/project/${id}/delivery`
              : `/project/${id}/delivery/messages`,
            icon: "bi bi-mailbox",
            iconClassName: "bi bi-mailbox",
          }
        : "",
      checkResultsRole(orgId)
        ? {
            id: 3,
            name: "Results",
            to: `/project/${id}/surveyresults/${fetchProjectQuery.data.survey?.rawReportId}`,
            icon: "bi bi-bar-chart-fill",
            iconClassName: "bi bi-bar-chart-fill",
          }
        : "",
    ];
  }

  const [active, setActive] = useState(
    localStorage.getItem("activeprojecttab")
      ? localStorage.getItem("activeprojecttab")
      : 0
  );
  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = (item, newDirection) => {
    setPage([page + newDirection, newDirection]);
    setActive(item);
  };
  const handleActiveUpdate = (item) => {
    // console.log(item);
    if (!fetchProjectQuery.data.survey?.activeLink) {
      localStorage.setItem("activedelivertab", 1);
    } else {
      localStorage.removeItem("activedelivertab");
    }
    localStorage.removeItem("activesurveybuildtab");
    localStorage.setItem("activeprojecttab", item);
    let newDirection = item < active ? -1 : 1;
    paginate(item, newDirection);

    // localStorage.setItem("activeprojecttab", );
  };

  useEffect(() => {
    setActive(localStorage.getItem("activeprojecttab"));
  }, [localStorage.getItem("activeprojecttab")]);

  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  let [width, setWidth] = useState(getWidth());

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      let width = getWidth();
      setWidth(width);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (resultsOnly) {
      localStorage.setItem("activeprojecttab", 3);
      setActive(3);
      navigate("surveyresults");
    }
  }, []);

  function setUpBothNavAways(obj) {
    setBeforeNavAway(obj);
    setBeforeNavAwayInProj(obj);
  }

  return (
    <>
      {fetchProjectQuery.isLoading && <Loading />}
      {fetchProjectQuery.isError && (
        <ErrorPage error={fetchProjectQuery.error} />
      )}
      {fetchProjectQuery.isSuccess && (
        <div className={styles.page}>
          <div className={styles.navContainer}>
            <NavBar
              menuItems={getItems(fetchProjectQuery.data?.survey)}
              active={active}
              updateActive={handleActiveUpdate}
              beforeNavAway={beforeNavAwayInProj}
              setBeforeNavAway={setUpBothNavAways}
              first
              
            />
            <div className={styles.left}>
              {/* {roles.canCreateEditOrgSurveys && (
                                <Button
                                    shadow
                                    seafoam
                                    style={{
                                        gap: ".5rem",
                                        // display: "flex",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        // fontSize: "1.1rem"
                                    }}
                                    onClick={() => setShowSettings(true)}
                                    disable={!roles.canCreateEditOrgSurveys}
                                    // link
                                >
                                    <i className="bi bi-gear-fill" />
                                    Settings
                                </Button>
                            )} */}
              <NavBar
                menuItems={[
                  {
                    id: 10,
                    name: "Settings",
                    // to: `survey-details`,
                    icon: "bi bi-gear",
                    iconClassName: "bi bi-gear",
                  },
                ]}
                active={active}
                updateActive={() => setShowSettings(true)}
                beforeNavAway={beforeNavAwayInProj}
                setBeforeNavAway={setUpBothNavAways}
                noMobile
              />
              <div
                className={styles.projectName}
                style={{
                  fontSize:
                    window.innerWidth < 1100 && window.innerWidth > 560
                      ? "1.2em"
                      : window.innerWidth < 560
                      ? ".9em"
                      : "",
                  padding: window.innerWidth < 1100 ? "10px" : "",
                }}
              >
                {fetchProjectQuery.data.survey?.name}
              </div>
            </div>
          </div>
          <AnimatePresence custom={{ direction, active }}>
            <ProjectRoutes
              reportid={fetchProjectQuery.data.survey?.resultAnalysisId}
              responseCount={fetchProjectQuery.data.survey?.responseCount}
              setBeforeNavAway={setUpBothNavAways}
              survey={fetchProjectQuery.data?.survey}
              direction={direction}
              active={active}
              setShowSettings={setShowSettings}
              collapse={collapse}
              collapsed={collapsed}
            />
          </AnimatePresence>
          <SurveySettings
            survey={fetchProjectQuery.data?.survey}
            userRoles={roles}
            show={showSettings}
            setShow={setShowSettings}
            user={user}
          ></SurveySettings>
        </div>
      )}
    </>
  );
};
