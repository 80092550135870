import React, { useState, useEffect } from "react";
import {
  SelectField,
  SelectFieldCustom,
  TextFieldSimple,
} from "components/inputs/index.js";
import Button from "components/Button/Button.jsx";
import styles from "./CreateOrganization.module.scss";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox.jsx";
import { useCreateOrgainization } from "api/resources/organization/organization.js";
import Editor from "components/tables/EditableTable/App.jsx";
import { maintimezones } from "assets/functions/Variables.js";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput.jsx";
import { useFetchAllUsers } from "api/resources/organization/share.js";
import ImageEditor from "components/Image Editor/ImageEditor.jsx";
import avatar from "assets/images/blank-profile-picture.png";

const customFields = [
  { label: "position", value: "position" },
  { label: "product", value: "product" },
  { label: "organization", value: "organization" },
  { label: "company", value: "company" },
  { label: "accountmanager", value: "accountmanager" },
];

function CreateOrganization({ show, setShow, refetchN }) {
  const [name, setName] = useState();
  const [timeZone, setTimeZone] = useState({
    timezone: "America/Denver",
    value: "America/Denver",
    offset: 25200,
    display: "GMT-07:00",
    label: "Mountain Time, US & Canada",
  });

  const [subDomain, setSubDomain] = useState();
  const [makeAdmin, setMakeAdmin] = useState(true);
  const [page, setPage] = useState(0);
  const [isLiscense, setIsLiscense] = useState(true);
  const [planFrequency, setPlanFrequency] = useState(24);
  const [planPrice, setPlanPrice] = useState("$30,000");
  const [adminsAllowed, setAdminsAllowed] = useState(2);
  const [usersAllowed, setUsersAllowed] = useState(100);
  const [projectsAllowed, setProjectsAllowed] = useState();
  const [responseAllowed, setResponsesAllowed] = useState();
  const [contactsAllowed, setContactsAllowed] = useState();
  const [reportsAllowed, setReportsAllowed] = useState();
  const [chosenCustomFields, setChosenFields] = useState();
  const [users, setUsers] = useState([]);
  const [chosen, setChosen] = useState([]);

  const createOrg = useCreateOrgainization();

  function createOrganization() {
    let tostringify = [
      ...users,
      ...chosen.map((u) => {
        return { id: u.user.id, email: u.user.email, role: u.role };
      }),
    ];
    let finalUsers = JSON.stringify(tostringify);
    let finalFields = [];
    for (let field of customFields) {
      finalFields.push(field.value);
    }
    createOrg.mutate(
      {
        data: {
          name: name,
          displayName: name,
          timezone: timeZone.value,
          defaultLocale: timeZone.label,
          sendGridName: subDomain,
          subdomain: subDomain,
          customFields: JSON.stringify(finalFields),
          adminsAllowed: adminsAllowed,
          usersAllowed: usersAllowed,
          surveysAllowed: projectsAllowed,
          responsesAllowed: responseAllowed,
          contactsAllowed: contactsAllowed,
          reportsAllowed: reportsAllowed,
          isLiscense: isLiscense,
          planFrequency: planFrequency,
          planPrice: planPrice,
        },
        users: finalUsers,
        meAdmin: makeAdmin,
      },
      {
        onSuccess: () => {
          refetchN();
        },
      }
    );
    setShow(false);
  }

  return (
    <div
      className={styles.page}
      style={{
        height: page === 2 ? "800px" : "",
        maxWidth: page === 2 ? "100%" : "",
      }}
    >
      {page === 0 && (
        <>
          <div className={styles.header_3} style={{ width: "400px" }}>
            Create a New Organization
          </div>

          <TextFieldSimple
            value={name}
            placeholder="Type Organization Name..."
            label="Organization Name*"
            onSave={(value) => setName(value)}
          ></TextFieldSimple>

          <SelectFieldCustom
            options={maintimezones}
            value={timeZone}
            onChange={(zone) => setTimeZone(zone)}
            label="Time Zone*"
            //   disable={userRoles.editSurveyDetails ? false : true}
          ></SelectFieldCustom>

          <TextFieldSimple
            value={subDomain}
            placeholder="Type Domain..."
            label="Domain Name*"
            onChange={(value) => setSubDomain(value)}
          ></TextFieldSimple>

          <SelectFieldCustom
            options={[
              { value: "Liscense", label: "Liscense" },
              { value: "Subscription", label: "Subscription" },
            ]}
            value={
              isLiscense
                ? { value: "Liscense", label: "Liscense" }
                : { value: "Subscription", label: "Subscription" }
            }
            onChange={(value) =>
              setIsLiscense(value.value === "Liscense" ? true : false)
            }
            label="Plan Type"
            //   disable={userRoles.editSurveyDetails ? false : true}
          ></SelectFieldCustom>

          <TextFieldSimple
            value={planFrequency}
            label="Plan Duration"
            onChange={(value) => setPlanFrequency(value)}
          ></TextFieldSimple>

          <TextFieldSimple
            value={planPrice}
            label="Plan Price"
            onChange={(value) => setPlanPrice(value)}
          ></TextFieldSimple>

          <TextFieldSimple
            value={adminsAllowed}
            label="Admin Users"
            onChange={(value) => setPlanPrice(value)}
          ></TextFieldSimple>

          <TextFieldSimple
            value={usersAllowed}
            label="Other Users"
            onChange={(value) => setUsersAllowed(value)}
          ></TextFieldSimple>

          <TextFieldSimple
            value={projectsAllowed}
            label="Projects"
            placeholder={"Unlimited"}
            onChange={(value) => setProjectsAllowed(value)}
          ></TextFieldSimple>
          <TextFieldSimple
            value={responseAllowed}
            label="Responses Per Survey"
            placeholder={"Unlimited"}
            onChange={(value) => setResponsesAllowed(value)}
          ></TextFieldSimple>
          <TextFieldSimple
            value={contactsAllowed}
            label="Contacts"
            placeholder={"Unlimited"}
            onChange={(value) => setContactsAllowed(value)}
          ></TextFieldSimple>
          <TextFieldSimple
            value={reportsAllowed}
            label="Analysis Reports"
            placeholder={"Unlimited"}
            onChange={(value) => setReportsAllowed(value)}
          ></TextFieldSimple>
          {/* <div>
            <TextFieldSimple
              value={sendgrind}
              placeholder="Type New Sengrid Name..."
              label="New Sendgrid Name*"
              onSave={(value) => setSendgrid(value)}
            ></TextFieldSimple>
          </div> */}
        </>
      )}
      {page === 1 && (
        <>
          <SelectField
            // value="Product"
            selectMultiple
            onChange={(values) => setChosenFields(values)}
            options={customFields}
            value={chosenCustomFields}
            label="Organization Custom Fields"
            placeholder="Create or choose custom fields"
            // disable={userRoles.editSurveyDetails ? false : true}
          ></SelectField>
          <div>
            <p className={styles.par}>
              These are the fields that you are allowing your contacts to have.
              These will be able to be seen when creating charts and view the
              results of your surveys.
              <br />
              <br />
              firstName, lastName, and email are fields that already exist. You
              may add more fields above that you believe will be used.
              <br />
              <br />
              Custom fields are updated when you upload contacts. You can also
              edit these in the organization details page whenever you like.
            </p>
          </div>
        </>
      )}

      {page === 2 && (
        <>
          <AddUsers
            makeAdmin={makeAdmin}
            setMakeAdmin={setMakeAdmin}
            setUsers={setUsers}
            chosen={chosen}
            setChosen={setChosen}
          ></AddUsers>
        </>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: page != 0 ? "space-between" : "end",
        }}
      >
        {page != 0 && (
          <div>
            <Button onClick={() => setPage(page - 1)} shadow blue>
              Back
            </Button>
          </div>
        )}
        {page != 2 && (
          <div>
            <Button
              onClick={() => setPage(page + 1)}
              shadow
              disable={!name || !timeZone || !subDomain}
              blue
            >
              Next
            </Button>
          </div>
        )}
        {page === 2 && (
          <div>
            <Button blue shadow  onClick={createOrganization}>
              Finish
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateOrganization;

const roleOptions = [
  { label: "Admin", backgroundColor: "#EBEBEB", value: "Admin" },
  { label: "Viewer", backgroundColor: "#EBEBEB", value: "Viewer" },
  { label: "Editor", backgroundColor: "#EBEBEB", value: "Editor" },
];
function AddUsers({ makeAdmin, setMakeAdmin, setUsers, chosen, setChosen }) {
  const [search, setSearch] = useState("");
  const [userMap, setUserMap] = useState({});

  const [showUser, setShowUser] = useState();
  const getUsers = useFetchAllUsers(search);
  const [startColumns, setStartColumns] = useState([
    {
      id: "password",
      label: "Password",
      accessor: "password",
      dataType: "text",
      options: [],
      enabled: true,
    },
    {
      id: "phone",
      label: "Phone",
      accessor: "phone",
      dataType: "text",
      options: [],
      enabled: true,
    },
    {
      id: "prefix",
      label: "Prefix",
      accessor: "prefix",
      dataType: "text",
      options: [],
      enabled: true,
    },
    {
      id: "position",
      label: "Position",
      accessor: "position",
      dataType: "text",
      options: [],
      enabled: true,
    },
    {
      id: "role",
      label: "Role",
      accessor: "role",
      dataType: "select",
      options: roleOptions,
      enabled: true,
    },
  ]);

  function regulateUsers(users) {
    let map = {};
    let finalUsers = users.map((user) => {
      map[user.id] = user;
      return {
        value: user.id,
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: ".5rem",
              position: "relative",
            }}
          >
            <ImageEditor
              src={user?.image?.imageURL}
              isUser
              height={30}
              width={30}
              noShadow
            ></ImageEditor>{" "}
            {user?.firstName} {user?.lastName}
          </div>
        ),
        role: "Viewer",
        user: user,
      };
    });
    return finalUsers;
  }

  useEffect(() => {
    getUsers.refetch();
  }, [search]);


  return (
    <>
      {" "}
      <div className={styles.header_3} style={{ width: "100%" }}>
        Add Users
      </div>
      <div className={styles.par} style={{ maxWidth: "none" }}>
        These are the users that the organization will start with. You need at
        least one Admin user.
      </div>
      <div className={styles.makeAdmin}>
        <Checkbox
          checked={makeAdmin}
          onChange={(e) => setMakeAdmin(e.target.checked)}
        ></Checkbox>{" "}
        Make me an admin user
      </div>
      <span className={styles.header_4} style={{ marign: "0", width: "100%" }}>
        Create New Users
      </span>
      <div className={styles.adduser}>
        <Editor
          // buttonName={"Create Organization"}
          // customFields={["Password", "Role"]}
          // setList={}
          //   changeButtonStatus={setButtonStatus}
          startColumns={startColumns}
          setList={setUsers}
        ></Editor>
      </div>
      <span className={styles.header_4} style={{ marign: "0", width: "100%" }}>
        Choose Existing Users
      </span>
      {getUsers.isSuccess && (
        <SelectFieldCustom
          options={regulateUsers(getUsers.data?.allUsers)}
          value={chosen}
          selectMultiple
          onChange={(value, remove) => {
            let temp = [...chosen];
            if (remove) {
              let index = temp.findIndex((i) => i.id === value.value);
              temp.splice(index, 1);
            } else {
              temp.push(value);
            }
            setChosen(temp);
          }}
          searchString={search}
          setSearchString={setSearch}
          searchable
          shadow
          style={{ backgroundColor: "white" }}
          placeholder={"Search Users..."}
        ></SelectFieldCustom>
      )}
      <div className={styles.peopleWithAccess} style={{ width: "100%" }}>
        <span
          className={styles.header_4}
          style={{ marign: "0", width: "100%" }}
        >
          Chosen Existing Users Roles
        </span>
        <table>
          <tbody>
            {chosen.length > 0 &&
              chosen.map((user, i) => (
                <tr key={i}>
                  <td>
                    <div className={styles.user}>
                      <img
                        src={user?.imageURL ? user?.imageURL : avatar}
                        className={styles.avatar}
                        alt="user profile image"
                      />
                      <div className={styles.content}>
                        <span className={styles.name}>
                          {user?.user?.firstName} {user?.user?.lastName}
                        </span>
                        <span className={styles.email}>
                          {user?.user?.email}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td
                    style={{
                      color: user.label === "Owner" ? "lightgray" : "",
                      width: "fit-content",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowUser(user?.user?.id);
                    }}
                  >
                    <CombinedInput
                      options={roleOptions}
                      value={user?.role}
                      select
                      onChange={(value) => {
                        let index = chosen?.findIndex(
                          (u) => (u.user.id = user?.user?.id)
                        );
                        chosen[index].role = value.value;
                      }}
                      shadow
                      style={{ backgroundColor: "white", width: "fit-content" }}
                      icon={"bi-chevron-down"}
                      // placeholder={"Search Users..."}
                    ></CombinedInput>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
