/**
 *
 * @param {*} array array to be sorted
 * @param {*} youTellMe a function taking two parameters, a & b, representing two spots in the array in the current order. The function should return a value > 0 if you want a to come after b, else a & b will remain the same.
 * @returns returns a new sorted copy of array
 */
export const getSortedArray = (array, youTellMe) => {
  let copy = [...array];
  let len = copy.length;
  if (!len || len == 1) {
    return copy;
  }

  let sorted = false;
  while (!sorted) {
    sorted = true;
    for (var i = 0; i < len - 1; i++) {
      let direction = youTellMe(copy[i], copy[i + 1]);

      if (direction > 0) {
        // if the value is greater than 0, switch a & b, else remain the same
        sorted = false;

        let temp = copy[i];
        copy[i] = copy[i + 1];
        copy[i + 1] = temp;
      }
    }
  }

  return copy;
};

/**
 *
 * @param {*} array array to be reversed
 * @returns a copy of the array with items in reverse order
 */
export const getReversed = (array) => {
  let copy = [];
  for (let i = array.length - 1; i >= 0; i--) {
    copy.push(array[i]);
  }
  return copy;
};

/**
 *
 * @param {*} array the array you want to loop through
 *  * @param {*} thefunction the function to be run when you loop
 */
export function forEach(array, thefunction) {
  if (!array) {
    debugger;
  }
  for (let i = 0; i < array.length; i++) {
    thefunction(array[i], i);
  }
}

/**
 *
 * @param {*} str the file as a string
 *  @param {*} delimiter delimiter of the csv file
 * @returns turns your csv file as a string into an array
 */
export function csvToArray(str, delimiter = ",") {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  let headers = str.slice(0, str.indexOf("\n")).split(delimiter);
  for (let i = 0; i < headers.length; i++) {
    // headers[i] = headers[i].toLowerCase();
    headers[i] = headers[i].trim();
  }
  //get rid of \r at end of header list
  let lastIndex = headers.length - 1;
  if (headers[lastIndex].includes("\r")) {
    headers[lastIndex] = headers[lastIndex].slice(0, -1);
    //console.log(headers[headers.length - 1]);
  }
  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  let rows = str.slice(str.indexOf("\n") + 1).split("\n");
  //get rid of \r at end of every row
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].includes("\r")) {
      rows[i] = rows[i].slice(0, -1);
    }
  }
  //console.log(rows);

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  // debugger;
  let arr = rows?.map((row) => {
    // debugger;
    let values = [];
    //if firstName string has a comma
    if (row?.includes('"')) {
      if (row[0] === '"') {
        let subs = row.match(/"([^"]+)"/);
        //console.log(subs);
        if (subs) {
          row = row?.slice(subs[0].length + 1, row.length);
        }
        let tempValues = row?.split(delimiter);
        values?.push(subs[1]);
        for (let j = 0; j < tempValues.length; j++) {
          values?.push(tempValues[j]);
        }
      } else {
        //if some other (lastName or email) string has a comma

        while (row.indexOf('"') > -1) {
          if (row[0] === '"') {
            
            let subs = row.match(/"([^"]+)"/);
            //console.log(subs);

            row = row?.slice(subs[0].length + 1, row.length);

            let tempValues = row?.split(delimiter);
            values?.push(subs[1]);
            for (let j = 0; j < tempValues.length; j++) {
              values?.push(tempValues[j]);
            }
          } else {
            let value = row.slice(0, row.indexOf(","));
            row = row.slice(row.indexOf(",") + 1, row.length);
            values?.push(value);
          }
        }
      }
    } else {
      values = row.split(delimiter);
    }
    //const values = row.split(delimiter);
    //console.log("Values:")
    //console.log(values);

    const el = headers.reduce(function (object, header, index) {
      object[header] = values[index];
      return object;
    }, {});
    return el;
  });

  //give line numbers && blank changes array;
  // for (let i = 0; i < arr.length; i++) {
  //   arr[i].record = i + 2;
  // }
  // return the array

  return arr;
}
