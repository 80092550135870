export const basic = [
  ["font", "fontSize"],
  ["fontColor"],
  ["horizontalRule"],
  ["link", "image"],
];
export const complex = [
  ["undo", "redo"],
  ["font", "fontSize", "formatBlock"],
  ["bold", "underline", "italic", "strike", "subscript", "superscript"],
  ["removeFormat"],
  ["fontColor", "hiliteColor"],
  ["outdent", "indent"],
  ["align", "horizontalRule", "list", "table"],
//   ["link", "image", "video"],
//   ["fullScreen", "showBlocks", "codeView"],
//   ["print"],
  ["fields", "addbutton", "addconditions", "addURL"],
  // ["save", "template", "preview"],
];
export const formatting = [
  ["undo", "redo"],
  ["bold", "underline", "italic", "strike", "subscript", "superscript"],
  ["removeFormat"],
  ["outdent", "indent"],
  ["fullScreen", "showBlocks", "codeView"],
  ["preview", "print"],
];

export const buttonList = {
  formatting: formatting,
  basic: basic,
  complex: complex,
};
