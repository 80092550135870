import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import styles from "./Check.module.scss";
import { ChartLabel } from "../ChartLabel/ChartLabel";

export function Check({
  checked,
  onCheck,
  style,
  children,
  tooltip,
  tipStyle,
}) {
  return (
    <div className={styles.container} style={style}>
      <Checkbox
        checked={checked}
        onChange={(e) => onCheck(e.target.checked)}
      ></Checkbox>
      <ChartLabel tooltip={tooltip} tipStyle={tipStyle}>
        {children}
      </ChartLabel>
    </div>
  );
}
