import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";

import styles from "./Loading.module.scss";

/**
 * The loading circle of doom. Designed to be displayed whenever anything is loading. Size is customizable
 * @param {Int} height height of the image
 * @param {Int} width width of the image
 * @param {Boolean} fullScreen boolean value that determines if it is full screen
 *  * @param {String} message message or error to be displayed underneath the loading circle
 * @returns {React.ReactElement} a loading gif/image component
 */

export const Loading = ({ height, width, fullScreen, message }) => {
  return (
    <div className={`${fullScreen && styles.fullScreen}`}>
      <Player
        autoplay
        loop
        src={require("assets/animations/uploading.json")}
        style={{
          height: height ? height : "100px",
          width: width ? width : "200px",
        }}
      />
      {message && <p>{message}</p>}
    </div>
  );
};
