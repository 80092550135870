// import {randomColor} from "./utils";

export default function makeData(startData, startColumns) {
  let data = startData ? startData : [];

  let columns = startColumns
    ? [
      {
        id: "email",
        label: "Email",
        accessor: "email",
        width: 250,
        dataType: "text",
        options: [],
        pointerEvents: "none",
      },
      {
        id: "firstName",
        label: "First Name",
        accessor: "firstName",
        minWidth: 100,
        dataType: "text",
        options: [],
        pointerEvents: "none",
      },
      {
        id: "lastName",
        label: "Last Name",
        accessor: "lastName",
        minWidth: 100,
        dataType: "text",
        options: [],
        pointerEvents: "none",
      },
        ...startColumns,
        {
          id: 999999,
          width: 20,
          label: "+",
          disableResizing: true,
          dataType: "null",
        },
      ]
    : [
        {
          id: "email",
          label: "Email",
          accessor: "email",
          width: 250,
          dataType: "text",
          options: [],
          pointerEvents: "none",
        },
        {
          id: "firstName",
          label: "First Name",
          accessor: "firstName",
          minWidth: 100,
          dataType: "text",
          options: [],
          pointerEvents: "none",
        },
        {
          id: "lastName",
          label: "Last Name",
          accessor: "lastName",
          minWidth: 100,
          dataType: "text",
          options: [],
          pointerEvents: "none",
        },
        {
          id: 999999,
          width: 20,
          label: "+",
          disableResizing: true,
          dataType: "null",
        },
      ];
  return { columns: columns, data: data, skipReset: false };
}
