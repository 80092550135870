import React, { forwardRef, useState, useRef, useEffect } from "react";

import { Action } from "../Action/Action.jsx";
import styles from "./Options.module.scss";

export const Options = forwardRef((props, ref) => {
  const [options, setOptions] = useState(false);
  const ref2 = useRef(null);

  function clickOutside(e) {
    if (ref2.current && !ref2.current?.contains(e.target)) {
      setOptions(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  return (
    <div className={styles.options}>
      <Action
        ref={ref}
        cursor="pointer"
        data-cypress="draggable-handle"
        onClick={(e) => {
          e.stopPropagation();
          setOptions(true);
        }}
        {...props}
      >
        <i className="bi-three-dots-vertical"></i>
      </Action>
      {options && (
        <div className={styles.settingsBox} ref={ref2}>
          <div
            className={styles.menuItem}
            onClick={(e) => {
              e.stopPropagation();
              props?.setEdit(true);
              setOptions(false);
            }}
          >
            <i className="bi-list-ul"></i> Edit Order
          </div>
          {/* {!props?.folder && props?.id && (
            <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <i className="bi-archive" style={{ color: "#7FCFD3" }}></i>{" "}
              Archive Audience
            </div>
          )} */}
          {props?.id && (
            <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                props.onRemove(e);
              }}
            >
              <i className="bi-trash" style={{ color: "#FF8878" }}></i> Delete
              {props?.folder ? " Folder" : " Audience"}
            </div>
          )}
          
          {props?.folder && (
            <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                props.setEditFolder(props?.id);
              }}
            >
              <i className="bi-pencil-square" style={{ color: "#7FCFD3" }}></i>{" "}
              Edit Folder
            </div>
          )}
        </div>
      )}
    </div>
  );
});
