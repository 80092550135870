import {
  useGetQuestionsFromQTagInTimeFrame
} from "api/resources/organization/questiontag";
import { useEffect, useState } from "react";
import styles from "./QuestionTag.module.scss";
import { combinedQs } from "pages/results/Charts/Visualization";

export function QuestionTag({
  tag,
  viz,
  onRemove,
  onTimeFrameChange,
  addToSelection,
  removeFromSelection,
  forComparison,
}) {
  const timeFrames = forComparison
    ? ["Past Year", "Years Previous", "Most Recent", "All But Most Recent"]
    : ["Past Year", "Most Recent"];

  const [tfCopy, setTfCopy] = useState(tag.timeFrame);

  const getQs = useGetQuestionsFromQTagInTimeFrame(tag.id, tag.timeFrame);

  function finishTimeFrameChange(tf, responsibleFor, newQuestions) {
    let copy = { ...tag };

    if (copy.timeFrame === "selection") {
      delete copy.selection;
    }
    copy.timeFrame = tf;
    if (copy.timeFrame === "selection") {
      copy.selection = [];
    }

    onTimeFrameChange(copy, responsibleFor, newQuestions);
  }

  function findNewTimeFrameQs(tf, responsibleFor) {
    if (tf === "selection") {
      finishTimeFrameChange(tf, responsibleFor, []);
    } else {
      getQs.mutate(
        { tagId: tag.id, timeFrame: tf },
        {
          onSuccess: (data) => {
            finishTimeFrameChange(tf, responsibleFor, data.Qs);
          },
        }
      );
    }
  }

  function changeTimeFrame(tf) {
    if (tag.timeFrame === "selection") {
      let all = forComparison ? viz.comparisonQs : combinedQs(viz);
      let responsible = all.filter((q) => tag.selection.includes(q.id));
      findNewTimeFrameQs(tf, responsible);
    } else {
      getQs.mutate(
        { tagId: tag.id, timeFrame: tag.timeFrame },
        {
          onSuccess: (data) => {
            findNewTimeFrameQs(tf, data.Qs);
          },
        }
      );
    }

    setTfCopy(tf);
  }

  function startRemovingQTag() {
    if (tag.timeFrame === "selection") {
      let all = forComparison ? viz.comparisonQs : combinedQs(viz);
      let responsible = all.filter((q) => tag.selection.includes(q.id));
      onRemove(responsible);
    } else {
      getQs.mutate(
        { tagId: tag.id, timeFrame: tag.timeFrame },
        {
          onSuccess: (data) => {
            onRemove(data.Qs);
          },
        }
      );
    }
  }

  return (
    <div className={styles.included}>
      <div className={styles.name}>
        {tag.name}{" "}
        {tag.color && (
          <i className="bi bi-tag-fill" style={{ color: tag.color }}></i>
        )}
        <div className={styles.remove} onClick={startRemovingQTag}>
          <i className="bi bi-x"></i>
        </div>
      </div>

      <div className={styles.selections}>
        <div className={styles.selection}>
          <input
            type="radio"
            value={"All"}
            onChange={() => changeTimeFrame("All")}
            checked={tfCopy === "All"}
            className={styles.timeFrameInput}
          ></input>
          All
        </div>

        <div className={styles.selection}>
          <input
            type="radio"
            value={"selection"}
            onChange={() => changeTimeFrame("selection")}
            checked={tfCopy === "selection"}
            className={styles.timeFrameInput}
          ></input>
          A Selection
        </div>

        {tag.timeFrame === "selection" && (
          <TagProjectSelection
            tag={tag}
            addToSelection={addToSelection}
            removeFromSelection={removeFromSelection}
          />
        )}
      </div>

      <div
        className={styles.timeFrames}
        style={forComparison ? { gap: "2px" } : undefined}
      >
        <div className={styles.timeFrameHeading}>Time Frame</div>
        {timeFrames.map((tf, i) => (
          <>
            <div className={styles.timeFrame}>
              <input
                type="radio"
                value={tf}
                onChange={() => changeTimeFrame(tf)}
                checked={tfCopy === tf}
                className={styles.timeFrameInput}
              ></input>
              <>
                {forComparison && i % 2 ? (
                  <div style={{ paddingLeft: "5px", fontStyle: "italic" }}>
                    {tf}
                  </div>
                ) : (
                  tf
                )}
              </>
            </div>
            {tag.timeFrame === tf && <TagProjects tag={tag} />}
          </>
        ))}
      </div>
    </div>
  );
}

function TagProjects({ tag }) {
  const getQs = useGetQuestionsFromQTagInTimeFrame();

  useEffect(() => {
    getQs.mutate({ tagId: tag.id, timeFrame: tag.timeFrame });
  }, []);

  return (
    <>
      {getQs.isSuccess && (
        <div className={styles.inTimeFrameProjects}>
          {getUniqueProjects(getQs.data.Qs).map((project, i) => (
            <div className={styles.inTimeFrameProj} key={i}>
              {project?.name}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function TagProjectSelection({ tag, addToSelection, removeFromSelection }) {
  const getQs = useGetQuestionsFromQTagInTimeFrame();

  useEffect(() => {
    getQs.mutate({ tagId: tag.id, timeFrame: "All" });
  }, []);

  function hasAll(project) {
    for (let question of project.question) {
      if (!tag.selection.includes(question.id)) {
        return false;
      }
    }
    return true;
  }

  function onCheck(project) {
    if (hasAll(project)) {
      removeFromSelection(project.question);
    } else {
      addToSelection(project.question);
    }
  }

  return (
    <>
      {getQs.isSuccess && (
        <div className={styles.selectionOptions}>
          {getUniqueProjects(getQs.data.Qs).map((project) => (
            <div className={styles.selectionOption}>
              <input
                type="checkbox"
                value={project.id}
                onChange={() => onCheck(project)}
                checked={hasAll(project)}
                className={styles.timeFrameInput}
              ></input>
              {project.name}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function getUniqueProjects(Qs) {
  let projects = [];
  for (let q of Qs) {
    let project = projects.find((p) => p.id === q.project.id);
    if (project) {
      project.question.push(q);
    } else {
      projects.push({
        ...q.project,
        question: [q],
      });
    }
  }
  return projects;
}
