import React from "react";
import styles from "./Stat.module.scss";

function Stat({
  title,
  titleStyle,
  value,
  header,
  style,
  small,
  valueStyle,
  outof,
  underline,
}) {
  return (
    <div
      className={`${header && styles.headerStyle} ${
        small && styles.smallStyle
      } ${!header && !small ? styles.statContainer : ""}`}
      style={style}
    >
      <div className={styles.title} style={{...titleStyle, ...{ textDecoration: underline ? "underline" : ""}}}>
        {title}
      </div>{" "}
      <div className={styles.value} style={valueStyle}>
        {value} {typeof outof === "string" ? <div className={styles.outof}>{outof}</div> : outof > 0 && <div className={styles.outof}>/{outof}</div>}
      </div>
    </div>
  );
}

export default Stat;
