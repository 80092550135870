import React, { useState } from "react";
import styles from "./Billing.module.scss";
import OrgBuckets from "./OrgBuckets/OrgBuckets";

function NLP({ roles }) {
  return (
    <div className={styles.page}>
      <NLPContent role={roles} />
    </div>
  );
}

export default NLP;

function NLPContent({ role }) {
  return (
    <>
      <div className={styles.section_label5} style={{ margin: "0" }}>
        Natural Language Processing Buckets
      </div>

      <OrgBuckets />

      {/* <Modal
        show={editAddress || editInvoice}
        onClose={handleCancel}
        modalStyle={{
          padding: "2em",
          width: "600px",
          height: "100%",
          gap: "1rem",
        }}
        dark
        rightStyle
      >
        {editAddress && (
          <>
            <div className={styles.header_3} style={{ margin: "0" }}>
              Billing Address
            </div>
            <AddressInput
              org={org}
              onChange={onChangeBilling}
              billing
            ></AddressInput>
          </>
        )}
        {editInvoice && (
          <>
            <div className={styles.header_3} style={{ margin: "0" }}>
              Invoice Address
            </div>
            <AddressInput
              org={org}
              onChange={onChangeInvoice}
              invoice
            ></AddressInput>
          </>
        )}
        <div
          style={{
            display: "flex",
            gap: "1em",
            paddingTop: ".5em",
            justifyContent: "flex-end",
          }}
        >
          <Button red height={35} onClick={handleCancel} shadow link>
            Cancel
          </Button>
          <Button height={35} onClick={handleSave} shadow blue>
            {BtnMsg}
          </Button>
        </div>
      </Modal> */}
    </>
  );
}
