import React, { useState, useRef } from "react";
import styles from "./Drip.module.scss";

export function Drip({ email, onChange, disable }) {
  const options = [
    {
      value: 1,
      label: "1 (second)",
      // to: `distributions`,
    },
    {
      value: 2,
      label: "2 (seconds)",
      // to: `stats`,
    },
    {
      value: 3,
      label: "3 (seconds)",
      // to: `stats`,
    },

    {
      value: 4,
      label: "4 (seconds)",
      // to: `stats`,
    },
    {
      value: 5,
      label: "5 (seconds)",
      // to: `stats`,
    },
    {
      value: 30,
      label: "30 (seconds)",
      // to: `stats`,
    },
    {
      value: 60,
      label: "60 (1 min)",
      // to: `stats`,
    },
    {
      value: 120,
      label: "120 (2 min)",
      // to: `stats`,
    },
    {
      value: 300,
      label: "300 (5 min)",
      // to: `stats`,
    },
    {
      value: 600,
      label: "600 (10 min)",
      // to: `stats`,
    },
    {
      value: 1800,
      label: "1800 (30 min)",
      // to: `stats`,
    },
    {
      value: 3600,
      label: "3600 (1 hour)",
      // to: `stats`,
    },
  ];

  const optionsNum = [
    {
      value: 1,
      label: "1 (email)",
      // to: `distributions`,
    },
    {
      value: 2,
      label: "2 (emails)",
      // to: `stats`,
    },
    {
      value: 3,
      label: "3 (emails)",
      // to: `stats`,
    },

    {
      value: 4,
      label: "4 (emails)",
      // to: `stats`,
    },
    {
      value: 5,
      label: "5 (emails)",
      // to: `stats`,
    },
    {
      value: 10,
      label: "10 (emails)",
      // to: `stats`,
    },
    {
      value: 15,
      label: "15 (emails)",
      // to: `stats`,
    },
    {
      value: 20,
      label: "20 (emails)",
      // to: `stats`,
    },
    {
      value: 25,
      label: "25 (eamils)",
      // to: `stats`,
    },
    {
      value: 30,
      label: "30 (emails)",
      // to: `stats`,
    },
    {
      value: 50,
      label: "50 (emails)",
      // to: `stats`,
    },
    {
      value: 100,
      label: "100 (emails)",
      // to: `stats`,
    },
  ];

  return (
    <>
      <div
        className={`${styles.container}`}
        style={{
          pointerEvents: disable ? "none" : "",
        }}
      >
        <div className={styles.label}>
          <div className={styles.label6}>Drip:</div>
          <div className={styles.info}>
            <i className={`bi bi-info-circle`}></i>

            <div className={styles.help}>
              Helps prevent email providers from blocking emails. Spaces out
              your send.
            </div>
          </div>
        </div>

        <div className={styles.config}>
          <select
            onChange={(e) =>
              onChange("timeDelayCount", parseInt(e.target.value))
            }
            value={email.timeDelayCount}
            className={styles.select}
          >
            {optionsNum.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <span>every</span>
          <select
            onChange={(e) => onChange("timeDelay", parseInt(e.target.value))}
            value={email.timeDelay}
            className={styles.select}
          >
            {options.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}
