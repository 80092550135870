import FlexCol from "components/layouts/FlexColumn/FlexCol";
import styles from "./SelectTool.module.scss";
import { ChartLabel } from "../ChartLabel/ChartLabel";
import { SelectField } from "components/inputs";

export function SelectTool({
  options,
  value,
  onChange,
  children,
  style,
  disable,
}) {
  return (
    <FlexCol gap={"5px"} style={style}>
      <ChartLabel>{children}</ChartLabel>
      <SelectField
        options={options}
        value={value}
        onChange={onChange}
        disable={disable}
      />
    </FlexCol>
  );
}
