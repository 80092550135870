import React, { useState, useEffect, useRef } from "react";
import styles from "./Filters.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import { SelectFieldCustom } from "components/inputs";
import Button from "components/Button/Button";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { operators } from "assets/functions/Variables";
import { useFetchCustomField } from "api/resources/organization/organization";

function FilterSimple({
  filters,
  setFilters,
  allColumns,
  title,
  setShow,
  left,
}) {
  const [chosenFilters, setChosenFilters] = useState(filters ? filters : []);
  const [changed, setChanged] = useState(false);

  function changeFilter(filter, index) {
    let temp = [...chosenFilters];
    temp[index] = filter;
    setChosenFilters(temp);
  }

  function applyChanges() {
    setFilters([...chosenFilters]);
    setChanged(false);
  }

  function removeAll() {
    setChosenFilters([]);
    setChanged(true);
    // setFilters([]);
  }

  function addFilter() {
    let temp = [...chosenFilters];

    temp.push({
      column: undefined,
      id: "",
      label: "",
      name: "",
      properties: [],
      operator: 0,
      values: [],
      new: true,
      icon: "",
    });

    setChosenFilters(temp);
  }

  function removeFilter(index) {
    let temp = [...chosenFilters];
    temp.splice(index, 1);

    setChosenFilters(temp);
    setChanged(true);
  }

  return (
    <div className={styles.page}>
      <div
        className={`${styles.filterSimpleSticky} ${styles.label5}`}
        style={{ top: "0", borderTop: "none" }}
      >
        <FlexRow>
          <FlexRow start>
            <i className="bi-sliders"></i>
            {title ? title : "Filters"}
          </FlexRow>

          {!changed && (
            <FlexRow start style={{ width: "fit-content" }}>
              <Icon iconName={"x-lg"} onClick={() => setShow(false)}></Icon>
            </FlexRow>
          )}

          {changed && (
            <Button shadow blue height={2.1} onClick={() => applyChanges()}>
              Apply
            </Button>
          )}
        </FlexRow>
        <FlexRow>
          <span
            className={styles.link}
            style={{ whiteSpace: "nowrap" }}
            onClick={() => addFilter()}
          >
            Add Filter
          </span>

          {chosenFilters?.length > 0 && (
            <span
              className={styles.link}
              style={{ whiteSpace: "nowrap" }}
              onClick={() => removeAll()}
            >
              Remove all
            </span>
          )}
        </FlexRow>
      </div>
      {chosenFilters && chosenFilters?.length > 0 && (
        <div
          className={`${styles.filterSimple} ${styles.label5}`}
          style={{ height: "100%", padding: "0", paddingTop: "1rem" }}
        >
          {chosenFilters &&
            chosenFilters?.map((f, index) => (
              <AFilter
                key={index}
                filter={f}
                changeFilter={(filter) => changeFilter(filter, index)}
                removeFilter={() => removeFilter(index)}
                allColumns={allColumns}
                applyChanges={applyChanges}
                setChanged={setChanged}
                left={left}
              ></AFilter>
            ))}
        </div>
      )}
      {chosenFilters?.length === 0 && (
        <div
          className={`${styles.filterSimple} ${styles.label5}`}
          style={{ height: "100%" }}
        >
          No filters added
        </div>
      )}

      {/* <div className={`${styles.filterSimpleSticky} ${styles.label5}`}>
        <FlexRow>
          {chosenFilters?.length > 0 && (
            <Button shadow onClick={removeAll}>
              Remove all
            </Button>
          )}
          {chosenFilters?.length === 0 && <div></div>}
          <Button blue shadow onClick={applyChanges} disable={!done}>
            Apply
          </Button>
        </FlexRow>
      </div> */}
    </div>
  );
}

export default FilterSimple;

function AFilter({
  filter,
  changeFilter,
  removeFilter,
  allColumns,
  applyChanges,
  setChanged,
  left,
}) {
  const [view, setView] = useState(filter?.new);
  const [filterState, setFilterState] = useState(filter);
  const [column, setColumn] = useState(
    allColumns && allColumns?.length > 0
      ? allColumns.find((c) => c.id === filter.name)
      : {}
  );
  const [done, setDone] = useState(false);

  const fetchField = useFetchCustomField();
  const [theField, setTheField] = useState();
  console.log("THE COLUMN: ", column);
  function pullField() {
    if (column && column?.id) {
      console.log("here");
      fetchField.mutate(
        {
          id: column ? column?.id : "",
        },
        {
          onSuccess: (data) => {
            setTheField(data?.field?.attribute);
          },
        }
      );
    }
  }

  useEffect(() => {
    pullField();
  }, [column]);

  useEffect(() => {
    if (done) {
      // applyChanges();
      setChanged(true);
      setDone(false);
    }
  }, [done]);

  function handleCancel(e) {
    // setFilterState(filter);
    setView(false);
  }

  function handleDone(e) {
    e.stopPropagation();
    let temp = { ...filterState };
    temp.new = false;
    temp.done = true;
    setFilterState(temp);
    changeFilter(temp);
    setView(false);
    setDone(true);
  }

  function handleChangeProp(props, index) {
    let temp = { ...filterState };
    temp.properties = props;
    temp.values = props?.map((p) => {
      if (typeof p === "string") {
        return p;
      } else {
        return p.value;
      }
    });
    setFilterState(temp);
    changeFilter(temp);
  }

  function handleChangeOperator(op) {
    let temp = { ...filterState };
    temp.operator = op?.value;
    setFilterState(temp);
    changeFilter(temp);
  }

  function handleChangeLabel(op) {
    let temp = { ...filterState };
    temp.label = op.label;
    temp.name = op.value;
    temp.id = op.id;
    let newc =
      allColumns && allColumns?.length > 0
        ? allColumns.find((c) => c.id === temp.id)
        : {};
    setColumn(newc);
    setFilterState(temp);
    changeFilter(temp);
  }

  function handleRemove(e) {
    if (e) {
      e.stopPropagation();
    }
    setView(false);
    setChanged(true);

    removeFilter();
  }

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleCancel();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div
      className={`${styles.filterSimple} ${styles.label6}`}
      onClick={() => {
        if (!view) setView(true);
        let temp = { ...filter };
        temp.done = false;
        changeFilter(temp);
      }}
    >
      <div
        className={styles.header}
        style={{ flexDirection: "column", alignItems: "flex-start" }}
      >
        <FlexRow>
          <FlexRow start style={{ width: "fit-content" }}>
            {!filter?.new && column?.icon && (
              <Icon
                blue
                iconName={column?.icon}
                style={{ fontSize: "1.1rem" }}
              ></Icon>
            )}
            {!filter?.new ? column?.label : ""}
          </FlexRow>
          <Icon
            iconName={"x-lg"}
            onClick={(e) => {
              e.stopPropagation();
              handleRemove();
            }}
          ></Icon>
        </FlexRow>
        <FlexRow gap={"0rem"} style={{ alignItems: "flex-start" }}>
          {" "}
          {!filter?.new && (
            <div className={styles.operator}>
              {operators?.find((o) => o.value === filter?.operator)?.label}
            </div>
          )}
          <CombinedInput
            selectMultiple
            value={filter?.properties}
            noSearch
            options={[]}
            style={{ backgroundColor: "transparent" }}
            chosenStyle={{
              backgroundColor: "rgba(21, 187, 199, 0.14)",
              fontSize: ".7rem",
            }}
          />
        </FlexRow>
        {filter?.new && "New Filter"}
      </div>

      {view && (
        <div
          className={styles.editFilter}
          ref={ref}
          style={{ left: left ? ".5rem" : "" }}
        >
          <FlexRow>
            {" "}
            <FlexRow start style={{ width: "fit-content" }}>
              <Icon iconName="pencil" style={{ fontSize: "1.1rem" }}></Icon>
              Edit Filter
            </FlexRow>
            {view && (
              <span
                className={`${styles.link} ${styles.red}`}
                onClick={handleRemove}
              >
                Remove
              </span>
            )}
          </FlexRow>
          <SelectFieldCustom
            label="Column"
            style={{ backgroundColor: "transparent" }}
            onChange={handleChangeLabel}
            value={
              allColumns.find((c) => c.id === filterState?.name)
                ? allColumns.find((c) => c.id === filterState?.name)
                : ""
            }
            options={allColumns}
          ></SelectFieldCustom>
          <SelectFieldCustom
            label="Operator"
            onChange={handleChangeOperator}
            value={operators.find((op) => op.value === filterState?.operator)}
            options={operators}
          ></SelectFieldCustom>
          <SelectFieldCustom
            label="Value"
            onChange={handleChangeProp}
            options={
              theField
                ? theField.map((a) => {
                    return { value: a?.id, label: a?.name };
                  })
                : []
            }
            values={filterState?.properties}
            canCreate
            selectMultiple
            // search
            placeholder={"Add value..."}
            // pushDropdown
            // chosenStyle={{ backgroundColor: "rgba(21, 187, 199, 0.14)" }}
          ></SelectFieldCustom>
          <FlexRow style={{ paddingTop: ".5rem" }}>
            <Button shadow link onClick={(e) => handleCancel(e)}>
              Cancel
            </Button>
            <Button blue shadow onClick={(e) => handleDone(e)}>
              Done
            </Button>
          </FlexRow>
        </div>
      )}
    </div>
  );
}
