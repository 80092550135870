// External
import React, { useState } from "react";
import { motion } from "framer-motion";

// Internal
import { DeliveryRoutes } from "routes";
import styles from "./Delivery.module.scss";
import { routeVariants } from "assets/functions/Variables";
import { TabBar } from "components/layouts";

export const Delivery = ({ survey, direction, onBuildEmail }) => {
  const menuItems = [
    {
      id: 0,
      name: "Survey Link",
      to: "survey-link",
      icon: `link-45deg`,
    },
    {
      id: 1,
      name: "Messages",
      to: "messages",
      icon: "send",
    },
    {
      id: 2,
      name: "Personal Links",
      to: `personal-links`,
      icon: "person-lock",
    },
  ];
  const [active, setActive] = useState(startPage());

  function startPage() {
    if (localStorage.getItem("activedelivertab")) {
      return localStorage.getItem("activedelivertab");
    }
    return 0;
  }
  const handleActiveUpdate = (item) => {
    localStorage.setItem("activedelivertab", item);
    setActive(item);
  };

  function getAnimate() {
    if (!survey.activeLink) {
      if (
        localStorage.getItem("activedelivertab") === 1 ||
        localStorage.getItem("activedelivertab") === "1"
      ) {
        return true;
      }
      return active === 1;
    } else {
      return active === 0;
    }
  }

  return (
    <motion.div
      variants={routeVariants}
      custom={{
        direction,
        animate: getAnimate(),
      }}
      initial="enter"
      animate="center"
      exit="exit"
      // transition={{
      //   x: { type: "spring", stiffness: 300, damping: 30 },
      //   opacity: { duration: 0.2 },
      // }}
      id="projectpage"
      className={styles.page}
    >
      {/* {!survey.isProgram && (
        <Sidebar2
          menuItems={menuItems}
          active={active}
          updateActive={handleActiveUpdate}
          condensed={window.innerWidth < 900 ? true : false}
          flatten
        />
      )} */}

      <div className={styles.pillContainer}>
        <TabBar
          tabBarItems={menuItems}
          active={active}
          updateActive={handleActiveUpdate}
          // condensed={window.innerWidth < 900 ? true : false}
          // flatten
          wings
        />
      </div>

      <DeliveryRoutes survey={survey} onBuildEmail={onBuildEmail} />
      {/* 
      {!survey.isProgram && (
        <div className={styles.moreWays}>
          <h1>More Ways to distribute your survey</h1>
          <div
            style={{
              display: "flex",
              gap: "2em",
              padding: "0em 1em",
              flexWrap: "wrap",
            }}
          >
            <div className={styles.way}>
              <h2 className="bi-link"></h2>
              <h2>Survey Link</h2>A single survey link to send to anyone in any
              way you prefer. Good to share on social media or in form of a QR
              code.
            </div>
            <div className={styles.way}>
              <h2 className="bi-envelope"></h2>
              <h2>Email Message</h2>Send your survey through our email sender
              and track response rates, who has not responded, who opts outs,
              etc.
            </div>
            <div className={styles.way}>
              <h2 className="bi-chat"></h2>
              <h2>Text Message</h2>Send your survey through our text message
              sender and track response rates, who has not responded, who opts
              outs, etc.
            </div>
            <div className={styles.way}>
              <h2 className="bi-person-lock"></h2>
              <h2>Personal Link</h2>A unique survey link to send to anyone in
              any way you prefer. Good to track response rates, who has not
              responded, who opts outs, etc.
            </div>
          </div>
        </div>
      )} */}
    </motion.div>
  );
};
