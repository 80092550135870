// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import styles from "./MultipleChoiceOption.module.scss";

export default function OtherOption({
  label,
  savedOther,
  selected,
  onClick,
  onOtherChange,
  color,
  barDesign,
  isMultiSelect,
  index,
  isRanking,
}) {
  const [hovering, setHovering] = useState(false);

  const [val, setVal] = useState(savedOther ? savedOther : "");

  let colorStyle = { borderColor: color };

  if (selected) {
    colorStyle.backgroundColor = color;
    colorStyle.color = "white";
  } else {
    if (barDesign) {
      colorStyle.backgroundColor = "white";
      colorStyle.color = color;
    } else {
      colorStyle.backgroundColor = "#efefef";
      colorStyle.color = "#efefef";
    }
    if (hovering) {
      if (barDesign) {
        colorStyle.backgroundColor = color;
        colorStyle.color = "white";
      } else {
        colorStyle.backgroundColor = "#d8d9d9";
        colorStyle.color = "#d8d9d9";
      }
    }
  }

  function handleClick(e) {
    if (val) {
      onClick(val);
    } else {
      onClick(label);
      if (e.target.checked) setUpShow();
    }
  }

  function handleOtherChange(newVal) {
    if (selected) {
      onOtherChange(newVal ? newVal : label);
    }
    setVal(newVal ? newVal : label);
  }

  const [show, setShow] = useState(false);

  function setUpShow() {
    setShow(true);
    document.addEventListener("click", closeDownShow, true);
  }

  function closeDownShow() {
    document.removeEventListener("click", closeDownShow, true);
    setShow(false);
  }

  return (
    <>
      {barDesign && (
        <div className={styles.optionContainer}>
          <button
            style={colorStyle}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            onClick={() => onClick(val)}
          >
            <OtherTextEntry
              current={val}
              label={label}
              onChange={handleOtherChange}
              show={show}
            />
          </button>
        </div>
      )}

      {!barDesign && (
        <div className={styles.buttonContainer}>
          {!isMultiSelect && !isRanking && (
            <>
              <label className={styles.container}>
                <input
                  id={"other"}
                  type="checkbox"
                  checked={selected}
                  onChange={handleClick}
                />
                <span
                  className={styles.checkmark}
                  style={colorStyle}
                  onMouseEnter={() => setHovering(true)}
                  onMouseLeave={() => setHovering(false)}
                ></span>
              </label>
              <span className={styles.content}>
                <OtherTextEntry
                  current={val}
                  label={label}
                  onChange={handleOtherChange}
                  show={show}
                />
              </span>
            </>
          )}
          {isMultiSelect && !isRanking && (
            <>
              <label className={styles.container}>
                <input
                  type="checkbox"
                  checked={selected}
                  onChange={handleClick}
                />
                <span
                  className={`${styles.checkmark} ${styles.multi} `}
                  style={colorStyle}
                  onMouseEnter={() => setHovering(true)}
                  onMouseLeave={() => setHovering(false)}
                ></span>
              </label>
              <span className={styles.content}>
                <OtherTextEntry
                  current={val}
                  label={label}
                  onChange={handleOtherChange}
                  show={show}
                />
              </span>
            </>
          )}
          {isRanking && !isMultiSelect && (
            <>
              <label className={styles.containerRank}>
                <input
                  type="checkbox"
                  checked={selected}
                  onChange={handleClick}
                />
                <span
                  className={`${styles.checkmarkRank} ${styles.multiRank} `}
                  style={colorStyle}
                  onMouseEnter={() => setHovering(true)}
                  onMouseLeave={() => setHovering(false)}
                >
                  <p className={styles.ranking}>
                    {index >= 0 ? index + 1 : ""}
                  </p>
                </span>
              </label>
              <div className={styles.content}>
                <OtherTextEntry
                  current={val}
                  label={label}
                  onChange={handleOtherChange}
                  show={show}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

function OtherTextEntry({ current, label, onChange, show }) {
  const [value, setValue] = useState(current);

  return (
    <div className={styles.otherOption}>
      <div
        className={styles.otherLabel}
        style={show ? { display: "none" } : undefined}
      >
        {current ? current : label}
      </div>
      <div
        className={styles.otherEntry}
        style={show ? { display: "flex" } : undefined}
      >
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={(e) => onChange(e.target.value)}
          className={styles.inputLine}
          placeholder={label}
          type="text"
        ></input>
      </div>
    </div>
  );
}
