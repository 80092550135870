import React, { useState, useEffect } from "react";
import styles from "./TextField.module.scss";
import Button from "components/Button/Button";

/**
 * A simple and customized text field
 * @param {String} value value that is passed in as initial value and/or changeable value for the text field
 * @param {String} placeholder a placeholder for the text field
 * @param {Function} onChange called when the text field changes/is typed in and returns the event which contains the value accessed with : (e.target.value)
 * @param {string} concerning used to update an object through it's property or accessor
 * @param {Function} onSave used to reduce the amount of re-renders to update an object;
 * @returns {React.ReactElement} a simple and customized text field
 */
export const TextFieldSimple = ({
  onChange,
  value,
  placeholder,
  type,
  name,
  style,
  copyToClipboard,
  label,
  concerning,
  onSave,
  saveLabel,
  onBlur,
  disable,
  valid,
  validMessage,
  invalidMessage,
  onEnter,
  id,
  autoFocus,
  defaultValue,
  maxLength,
  containerStyle,
  noState,
  icon,
}) => {
  const [val, setVal] = useState(value ? value : defaultValue);
  const [copied, setCopied] = useState(false);
  const [autoCompleteType, setAutoComplete] = useState(checkType);

  useEffect(() => {
    if (value != val) {
      setVal(value);
    }
  }, [value]);

  useEffect(() => {
    if (copied) {
      const timeId = setTimeout(() => {
        // After 3 seconds set Copied value to false
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  function handleOnChange(e) {
    if (onChange) {
      if (concerning) {
        onChange(e.target.value, concerning);
      } else {
        onChange(e.target.value);
      }
    }
    if (!disable) {
      setVal(e.target.value);
    }
  }

  function handleFocusOut(e) {
    if (type === "currency") {
      let value = formatCurrency(e.target.value);
      setVal(value);
      if (onChange) {
        onChange(value);
      }
    }
    if (type === "phone") {
      let value = formatPhoneNumber(e.target.value);
      setVal(value);
      if (onChange) {
        onChange(value);
      }
    }

    if (onBlur && !disable) {
      if (concerning) {
        onBlur(e.target.value, concerning);
      } else {
        onBlur(e.target.value);
      }
    }
  }

  function handleKeyDown(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      if (concerning) {
        onEnter(e.target.value, concerning);
      }
      onEnter(e.target.value);
    }
  }

  // function autoComplete() {
  //   if (type === "email") {
  //     return "email"
  //   }
  //   if (type === "current-")
  // }
  const formatCurrency = (value) => {
    if (!value) return "";
    const numberValue = parseFloat(value.replace(/[^0-9.]/g, ""));
    return numberValue.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatPhoneNumber = (value) => {
    if (!value) return "";
    const phoneNumber = value.replace(/\D/g, ""); // Remove all non-numeric characters

    // Check if it's an international number (starts with +)
    if (value.startsWith("+")) {
      // Format for an international number with country code (e.g., +1 (234) 567-8901)
      if (phoneNumber.length <= 1) return `+${phoneNumber}`;
      if (phoneNumber.length <= 4)
        return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(1)}`;
      if (phoneNumber.length <= 7)
        return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
          1,
          4
        )}) ${phoneNumber.slice(4)}`;
      return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
        1,
        4
      )}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
    }

    // U.S. Format (e.g., (123) 456-7890)
    if (phoneNumber.length <= 3) return phoneNumber;
    if (phoneNumber.length <= 6) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  function checkType() {
    if (type === "currency") {
      return "";
    }
    if (type === "phone") {
      return "tel";
    }

    if (type === "time" || type === "date" || type === "text") {
      return "";
    }
    return type;
  }

  return (
    <div
      style={{
        ...{ display: "flex", flexDirection: "column", width: "100%" },
        // ...{
        //   backgroundColor: editColor && edit && val != initval ? "#FFF7E4" : "",
        // },
        ...containerStyle,
      }}
      
    >
      <div className={styles.labelContainer}>
        {label && <div className={styles.label6}>{label}</div>}
        {valid === true && (
          <div className={styles.validMessage}>
            {validMessage ? validMessage : ""}
          </div>
        )}
        {valid === false && (
          <div className={styles.invalidMessage}>
            {invalidMessage ? invalidMessage : "Invalid"}
          </div>
        )}
        {copied === true && (
          <div className={styles.copied}>Copied To Clipboard!</div>
        )}
      </div>

      <div
        className={`${styles.inputBox}  ${disable && styles.disabled} ${
          valid === false && styles.invalidBorder
        }`}
      >
        {icon ? <i className={`bi-${icon} ${styles.icon}`} /> : <></>}
        <input
          type={type}
          name={name}
          onChange={handleOnChange}
          onBlur={handleFocusOut}
          value={noState ? value : val ? val : ""}
          placeholder={placeholder}
          disabled={disable}
          onKeyDown={onEnter ? handleKeyDown : null}
          id={id}
          style={style}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          maxLength={maxLength}
          autoComplete={autoCompleteType}
        ></input>
        {copyToClipboard && (
          <i
            className="bi bi-clipboard"
            onClick={() => {
              navigator.clipboard.writeText(val);
              setCopied(true);
            }}
          ></i>
        )}
        {onSave && (
          <Button onClick={onSave} blue>
            {saveLabel ? saveLabel : "Save"}
          </Button>
        )}{" "}
      </div>
    </div>
  );
};
