import React, { useEffect, useRef, useState } from "react";

import {
  useAddContactDynamic,
  useGetContactsDynamic,
  useGetContactsDynamicCount,
  useSearchContact,
} from "api/resources/contacts/contacts";
import { Loading } from "components/Loading/Loading";
import { BasicTable } from "components/tables/BasicTable/BasicTable";
import ContactDetails from "components/Popout/ContactProfile";
import { DynamicUpload } from "components/DynamicUpload/DynamicUpload";
import styles from "./AddFromContacts.module.scss";
import InteractiveTable from "components/tables/BasicTable/InteractiveTable";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { Label } from "components/layouts/Label/Label";
import Button from "components/Button/Button";

function AddFromContacts({
  audience,
  setShowGrow,
  onRowClick,
}) {
  const [searchString, setSearchString] = useState("");

  // const addContact = useUpdateAudienceGql();
  const [currPage, setCurrPage] = useState(1);
  const [pageSkip, setPageSkip] = useState(0);
  const [perPage, setPerpage] = useState(15);
  const [sort, setSort] = useState({
    item: "firstName",
    descend: true,
  });

  useEffect(() => {
    searchContacts.refetch();
  }, [searchString]);

  const searchContacts = useSearchContact(searchString, 15, sort);
  const [rules, setrules] = useState([{ field: "", condition: "", value: "" }]);
  const [rulesBefore, setrulesBefore] = useState([
    { field: "", condition: "", value: "" },
  ]);
  const [finalRules, setFinalRules] = useState({ rules: [], contacts: [] });

  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);

  const getContacts = useGetContactsDynamic(
    finalRules,
    pageSkip,
    perPage,
    sort,
    audience?.id
  );

  const getContactsCount = useGetContactsDynamicCount(finalRules);
  const addContacts = useAddContactDynamic(finalRules, audience?.id);

  function clickOutside(e) {
    // debugger;
    if (ref.current && !ref.current?.contains(e.target)) {
      setSearchString("");
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef();

  const columns = [
    {
      id: 3,
      index: 3,
      //   name: "New",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: (val) => (
        <div>
          {val && val?.new ? <span className={styles.new}>new</span> : ""}
        </div>
      ),
    },
    {
      id: 0,
      index: 0,
      name: "Email",
      accessor: "email",
      enabled: true,
      sort: true,
      canSort: true,
      cell_style: null,
    },
    {
      id: 1,
      index: 1,
      name: "First Name",
      accessor: "firstName",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },
    {
      id: 2,
      index: 2,
      name: "Last Name",
      accessor: "lastName",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },

  ];

  //   function addContactToAudience(objectArray, aud) {
  //     const contactIDS = {
  //       contactIds: objectArray,
  //     };
  //     addContact.mutate(
  //       {
  //         data: {
  //           description: aud.contacts.description,
  //           name: aud.contacts.name,
  //         },
  //         updateAudienceId: aud.contacts.id,
  //         contactArray: contactIDS,
  //       },
  //       {
  //         onSuccess: (data) => {
  //           audience.setAudience(data.updateAudience.contacts);
  //           audience.setShowGrow();
  //         },
  //       }
  //     );
  //   }

  function apply() {
    setLoading(true);
    setrulesBefore([...rules]);

    let temp = JSON.parse(JSON.stringify(rules));

    for (let i = 0; i < temp.length; i++) {
      temp[i].condition = temp[i]?.condition?.value;
      temp[i].field = temp[i]?.field?.value;
    }
    let final = { ...finalRules, rules: temp };
    setFinalRules(final);
    setChange(true);

    // let newcontacts = data?.data?.contacts;
    // if (newcontacts && newcontacts.length > 0) {
    //   for (let i = 0; i < newcontacts.length; i++) {
    //     if (
    //       newcontacts[i]?.audience.findIndex(
    //         (a) => a?.id === audience?.id
    //       )
    //     ) {
    //       newcontacts[i].new = true;
    //     }
    //   }
    // }

    // setContacts([...newcontacts]);
  }

  function addContact(id) {
    let temp = { ...finalRules };
    temp.contacts.push(id);
    setFinalRules(temp);
    setChange(true);
  }

  useEffect(() => {
    getContacts.refetch();
    getContactsCount.refetch(0);
    setLoading(false);
  }, [currPage, sort, perPage, finalRules]);

  function save() {
    addContacts.mutate({
      rules: finalRules,
      audienceId: audience?.id,
    }, {
      onSuccess: () => {
        setShowGrow(false);
      }
    });
  }

  return (
    <div className={styles.page}>
      <InteractiveTable
        headers={columns}
        // data={searchProjects.}
        queryData={getContacts}
        queryDataAccessor={"contacts"}
        maxDataQuery={getContactsCount}
        maxDataQueryAccessor={"count"}
        currPage={currPage}
        setCurrPage={setCurrPage}
        // maxData={getProjectCount}
        onRowClick={onRowClick}
        sort={sort}
        setSort={setSort}
        pageSkip={pageSkip}
        setPageSkip={setPageSkip}
        perPage={perPage}
        setPerPage={setPerpage}
        // searchString={searchString}
        // setSearchString={setSearchString}
        actionButtonText={"Save Audience"}
        // tableTitle={"Audience Preview"}
        tableDescription={`Contacts marked as new are contacts that are not currently in your audience but will be added when you click "Save Audience"`}
        searchPlaceholder={"Search Contacts..."}
        onClickActionButton={save}
        hideActionButton={!change}
        hideSearchButton
      ></InteractiveTable>

      <div>
        <div className={styles.search}>
          <Label>Search Contact To Add</Label>
          <NavTextField
            placeholder="Search..."
            value={searchString}
            setValue={setSearchString}
          ></NavTextField>
          <div className={styles.contacts} ref={ref}>
            {searchContacts.isSuccess &&
              searchString != "" &&
              searchContacts?.data?.contacts?.map((contact) => (
                <div
                  className={styles.contact}
                  onClick={() => addContact(contact?.id)}
                >
                  {`${contact?.firstName} ${contact?.lastName}`}{" "}
                  {contact?.email} <span className={styles.type}>Add</span>
                </div>
              ))}
          </div>
        </div>
        <DynamicUpload
          rules={rules}
          setRules={setrules}
          apply={apply}
          loading={loading}
          rulesBefore={rulesBefore}
        ></DynamicUpload>
      </div>
    </div>
  );
}
export default AddFromContacts;
