import Modal from "components/ReactModal/ReactModal";
import styles from "./SearchContactsToAdd.module.scss";
import { TextFieldSimple } from "components/inputs";
import { useState } from "react";
import { useSearchContact } from "api/resources/contacts/contacts";
import { Loading } from "components/Loading/Loading";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import { useAddContactsToAudience } from "api/resources/contacts/audiences";

export function SearchToAdd({ onClose, audienceId, audienceName, onAdded }) {
  const [search, setSearch] = useState("");
  const [chosen, setChosen] = useState([]);
  const [adding, setAdding] = useState(false);

  const searchContacts = useSearchContact(search, 25, {
    item: "firstName",
    descend: false,
  });
  const addContacts = useAddContactsToAudience();

  const label = (
    <div className={styles.label}>
      <i className="bi-search"></i> <div>Search Contacts</div>
    </div>
  );

  function addToChosen(c) {
    let copy = [...chosen];
    copy.push(c);
    setChosen(copy);
  }

  function removeChosen(i) {
    let copy = [...chosen];
    copy.splice(i, 1);
    setChosen(copy);
  }

  function add() {
    if (!adding && audienceId && chosen.length) {
      addContacts.mutate(
        {
          audienceId: audienceId,
          contactIds: {
            ids: chosen.map((c) => c.id),
          },
        },
        {
          onSuccess: (data) => {
            console.log("contacts added!");
            onAdded();
            onClose();
          },
          onError: (data) => {
            console.log("Error adding contacts to audience");
            setAdding(false);
          },
        }
      );
      setAdding(true);
    }
  }

  return (
    <Modal
      onClose={onClose}
      show
      modalStyle={{
        borderRadius: "7px",
        height: "95vh",
      }}
      dark
    >
      <div className={styles.container}>
        <div
          className={`${styles.header_3} ${styles.title}`}
          style={{ margin: "0" }}
        >
          Add To: <span className={styles.audienceName}>{audienceName}</span>
        </div>

        <TextFieldSimple
          value={search}
          label={label}
          onChange={setSearch}
          placeholder={"Search"}
          style={{ fontSize: ".85rem" }}
          autoFocus
        />

        {chosen.length > 0 && (
          <div className={styles.chosenList}>
            {chosen.map((c, i) => (
              <div className={styles.chosen} key={c.id}>
                <span>{`${c.firstName} ${c.lastName}`}</span>{" "}
                <div className={styles.x} onClick={() => removeChosen(i)}>
                  <i className="bi-x"></i>
                </div>
              </div>
            ))}
          </div>
        )}

        {search && (
          <div className={styles.options}>
            {searchContacts.isLoading && <Loading />}
            {searchContacts.isSuccess && (
              <>
                {searchContacts.data.contacts
                  .filter(
                    (c) =>
                      !c.audience.some((a) => a.id === audienceId) &&
                      !chosen.some((cc) => cc.id === c.id)
                  )
                  .map((c) => (
                    <div
                      className={styles.option}
                      onClick={() => addToChosen(c)}
                      key={c.id}
                    >
                      {`${c.firstName} ${c.lastName}`}
                    </div>
                  ))}

                {searchContacts.data.contacts.length == 0 && (
                  <div className={styles.noData}>No Data</div>
                )}
              </>
            )}
          </div>
        )}

        <FlexRow
          justify="center"
          style={!chosen.length ? { visibility: "hidden" } : undefined}
        >
          <Button shadow blue onClick={add}>
            {adding ? (
              <>
                <Loading height={25} width={25} />
                Adding
              </>
            ) : (
              <>Add Contacts</>
            )}
          </Button>
        </FlexRow>
      </div>
    </Modal>
  );
}
