import { Label } from "components/layouts/Label/Label";
import styles from "./MetricSettings.module.scss";
import { useState, useRef } from "react";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { NumSlider } from "components/inputs/input_fields/NumSlider/NumSlider";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { capitalize } from "assets/functions/StringFunctions";

export default function GlobalMetricSettings({
  viz,
  updateViz,
  changeSettings,
  settings,
}) {
  const displayOptions = [
    { icon: "three-dots", value: "row" },
    { icon: "three-dots-vertical", value: "column" },
  ];

  const [spin, setSpin] = useState(false);

  function onSpinClick() {
    setSpin(!spin);

    let curr = settings.iconOrders;
    let update;
    if (curr === "row") {
      update = "row-reverse";
    } else {
      update = "row";
    }
    applyToAll("iconOrder", update);
  }

  function setting(field, backup) {
    if (field in settings) {
      return settings[field];
    }
    return backup;
  }

  function applyToAll(field, val) {
    let copy = { ...settings };
    copy[field + "s"] = val;
    for (let metric of settings.metrics) {
      metric[field] = val;
    }
    let newViz = { ...viz };
    newViz.designSettings = copy;
    updateViz(newViz);
  }

  function changeRadi(val) {
    let copy = { ...settings };
    copy.titleBorderRadii = val;
    for (let metric of settings.metrics) {
      metric.titleBorderRadius = val;
    }
    let newViz = { ...viz };
    newViz.designSettings = copy;
    updateViz(newViz);
  }

  const colorRef = useRef();

  const alignmentOptions = ["left", "center", "right"];

  const anchorOptionsLR = [
    { icon: "start", value: "start" },
    { icon: "center", value: "center" },
    { icon: "end", value: "end" },
  ];

  const anchorOptionsTB = [
    { icon: "top", value: "start" },
    { icon: "middle", value: "center" },
    { icon: "bottom", value: "end" },
  ];

  const positionOptions =
    setting("display", "row") === "row"
      ? [
          { icon: "top", value: "start" },
          { icon: "middle", value: "center" },
          { icon: "bottom", value: "end" },
        ]
      : [
          { icon: "start", value: "start" },
          { icon: "center", value: "center" },
          { icon: "end", value: "end" },
        ];

  const stackOptions = [
    { icon: "text-left", value: "start" },
    { icon: "text-center", value: "center" },
    { icon: "text-right", value: "end" },
  ];

  // setting("display", "row") === "row"
  //   ? [
  //       { icon: "top", value: "start" },
  //       { icon: "middle", value: "center" },
  //       { icon: "bottom", value: "end" },
  //     ]
  //   : [
  //       { icon: "start", value: "start" },
  //       { icon: "center", value: "center" },
  //       { icon: "end", value: "end" },
  //     ];

  function getTopBottom() {
    let it = "anchorTB" in settings ? settings.anchorTB : "center";
    if (it === "start") return "Top";
    if (it === "center") return "Middle";
    if (it === "end") return "Bottom";
  }

  function getLeftRight() {
    let it = "anchorLR" in settings ? settings.anchorLR : "center";
    if (it === "start") return "Left";
    if (it === "center") return "Center";
    if (it === "end") return "Right";
  }

  function getMaxWidth() {
    let container = document.getElementById(viz.id);
    if (container) {
      return container.clientWidth - 5;
    }
    return 100;
  }

  return (
    <div className={styles.col} style={{ gap: "15px", marginTop: "15px" }}>
      {settings.metrics.length > 0 && (
        <div className={styles.globalSettingsHeader}>Global Settings</div>
      )}

      <div
        className={styles.row}
        style={{ marginTop: "10px", gap: "15px", paddingRight: "20px" }}
      >
        <div className={styles.subHeader}>Display</div>

        {displayOptions.map((d) => (
          <div
            className={`${styles.alignmentBtn} ${
              viz.designSettings.display === d.value
                ? styles.chosenAlignment
                : ""
            }`}
            onClick={() => changeSettings("display", d.value)}
          >
            <i className={`bi bi-${d.icon}`}></i>
          </div>
        ))}
      </div>

      <div className={styles.microSetting}>
        <div className={styles.subHeader}>Anchor</div>{" "}
        <div
          className={styles.row}
          style={{ paddingLeft: "20px", gap: "15px" }}
        >
          {anchorOptionsTB.map((a) => (
            <div
              className={`${styles.alignmentBtn} ${
                settings.anchorTB === a.value ? styles.chosenAlignment : ""
              }`}
              onClick={() => changeSettings("anchorTB", a.value)}
            >
              <i className={`bi bi-align-${a.icon}`}></i>
            </div>
          ))}
          <div
            className={styles.microSettingTitle}
            style={{ paddingLeft: "7px" }}
          >
            {getTopBottom()}
          </div>
        </div>
        {!settings.fillSpace && (
          <div
            className={styles.row}
            style={{ paddingLeft: "20px", gap: "15px" }}
          >
            {anchorOptionsLR.map((a) => (
              <div
                className={`${styles.alignmentBtn} ${
                  settings.anchorLR === a.value ? styles.chosenAlignment : ""
                }`}
                onClick={() => changeSettings("anchorLR", a.value)}
              >
                <i className={`bi bi-align-${a.icon}`}></i>
              </div>
            ))}
            <div
              className={styles.microSettingTitle}
              style={{ paddingLeft: "7px" }}
            >
              {getLeftRight()}
            </div>
          </div>
        )}
      </div>

      {settings.display === "column" && (
        <>
          <div className={styles.row} style={{ gap: "15px", width: "100%" }}>
            <div className={styles.subHeader}>Fill Space</div>
            <ToggleSwitch
              startChecked={setting("fillSpace", false)}
              handleCheck={(val) => changeSettings("fillSpace", val)}
            />
          </div>
          {settings.metrics.length > 1 && (
            <>
              {!settings.fillSpace && (
                <div
                  className={styles.row}
                  style={{ gap: "15px", width: "100%" }}
                >
                  <div className={styles.subHeader}>Even Widths</div>
                  <ToggleSwitch
                    startChecked={setting("evenWidths", false)}
                    handleCheck={(val) => changeSettings("evenWidths", val)}
                  />
                </div>
              )}

              {!settings.evenWidths && !settings.fillSpace && (
                <div className={styles.microSetting}>
                  <div className={styles.subHeader}>Stack</div>{" "}
                  <div
                    className={styles.row}
                    style={{ paddingLeft: "20px", gap: "15px" }}
                  >
                    {stackOptions.map((s) => (
                      <div
                        className={`${styles.alignmentBtn} ${
                          settings.stacks === s.value
                            ? styles.chosenAlignment
                            : ""
                        }`}
                        onClick={() => applyToAll("stack", s.value)}
                      >
                        <i className={`bi bi-${s.icon}`}></i>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}

      <div className={styles.microSetting}>
        <div className={styles.subHeader}>Titles</div>

        <div className={styles.row} style={{ gap: "15px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ padding: "0px 5px" }}
          >
            Size
          </div>
          <NumSlider
            value={setting("titleSizes", 12)}
            min={8}
            max={40}
            step={1}
            onChange={(val) => applyToAll("titleSize", val)}
            color={"#2A627C"}
          ></NumSlider>
          <NumberInput
            startNumber={setting("titleSizes", 12)}
            min={8}
            max={40}
            step={1}
            handleNumberChange={(val) => applyToAll("titleSize", val)}
            color={"#2A627C"}
          ></NumberInput>
        </div>

        <div className={styles.row} style={{ gap: "10px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ paddingLeft: "5px" }}
          >
            Color
            <i className="bi bi-fonts"></i>
          </div>

          <ColorPicker
            onChange={(color) => applyToAll("titleColor", color)}
            defaultColor={setting("titleColors", "#050606")}
            colorRef={colorRef}
            textColors
          />
        </div>

        <div className={styles.row} style={{ gap: "10px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ paddingLeft: "5px" }}
          >
            Background Color <i className="bi bi-paint-bucket"></i>
          </div>

          <ColorPicker
            onChange={(color) => applyToAll("titleBackgroundColor", color)}
            defaultColor={setting("titleBackgroundColors", "#ffffff")}
            colorRef={colorRef}
          />
        </div>

        <div className={styles.row} style={{ gap: "15px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ padding: "0px 5px", fontWeight: 600 }}
          >
            Bold
          </div>
          <NumSlider
            value={setting("titleBolds", 0)}
            min={0}
            max={4}
            step={1}
            onChange={(val) => applyToAll("titleBold", val)}
            color={"#2A627C"}
          ></NumSlider>
          <NumberInput
            startNumber={setting("titleBolds", 0)}
            min={0}
            max={4}
            step={1}
            handleNumberChange={(val) => applyToAll("titleBold", val)}
            color={"#2A627C"}
          ></NumberInput>
        </div>

        {/* <div className={styles.row} style={{ gap: "15px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ padding: "0px 5px", fontWeight: 600 }}
          >
            Width
          </div>

          <div className={styles.col} style={{ gap: "7px", flexGrow: '1', border: '1px solid black' }}>
            <div className={styles.row} style={{ gap: "15px" }}>
              <div className={styles.subHeader}>Fit</div>
              <ToggleSwitch
                startChecked={!setting("titleWidth", 0)}
                handleCheck={(val) => applyToAll("titleWidth", 0)}
              />
            </div>
            <div className={styles.row} style={{ gap: "15px" }}>
              <NumSlider
                value={setting("titleWidth", 0)}
                min={0}
                max={getMaxWidth()}
                step={1}
                onChange={(val) => applyToAll("titleWidth", val)}
                color={"#2A627C"}
              ></NumSlider>
              <NumberInput
                startNumber={setting("titleWidths", 0)}
                min={0}
                max={getMaxWidth()}
                step={1}
                handleNumberChange={(val) => applyToAll("titleWidth", val)}
                color={"#2A627C"}
              ></NumberInput>
            </div>
          </div>
        </div> */}

        {setting("titleBackgroundColors", "#ffffff") !== "#ffffff" && (
          <div className={styles.row} style={{ gap: "20px" }}>
            <div
              className={styles.microSettingTitle}
              style={{ paddingLeft: "5px" }}
            >
              Border Curve <div className={styles.mockCurve}></div>
            </div>

            <NumSlider
              value={setting("titleBorderRadii", 0)}
              min={0}
              max={20}
              step={1}
              onChange={changeRadi}
              color={"#2A627C"}
            ></NumSlider>
          </div>
        )}

        <div className={styles.row} style={{ gap: "12px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ paddingLeft: "5px" }}
          >
            Align
          </div>

          {alignmentOptions.map((a) => (
            <div
              className={`${styles.alignmentBtn} ${
                settings.titleAlignments === a ? styles.chosenAlignment : ""
              }`}
              onClick={() => applyToAll("titleAlignment", a)}
            >
              <i className={`bi bi-text-${a}`}></i>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.microSetting}>
        <div className={styles.subHeader}>Values</div>

        <div className={styles.row} style={{ gap: "15px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ padding: "0px 5px" }}
          >
            Size
          </div>
          <NumSlider
            value={setting("valueSizes", 12)}
            min={8}
            max={50}
            step={1}
            onChange={(val) => applyToAll("valueSize", val)}
            color={"#2A627C"}
          ></NumSlider>
          <NumberInput
            startNumber={setting("valueSizes", 12)}
            min={8}
            max={50}
            step={1}
            handleNumberChange={(val) => applyToAll("valueSize", val)}
            color={"#2A627C"}
          ></NumberInput>
        </div>

        <div className={styles.row} style={{ gap: "10px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ paddingLeft: "5px" }}
          >
            Color
            <i className="bi bi-fonts"></i>
          </div>

          <ColorPicker
            onChange={(color) => applyToAll("valueColor", color)}
            defaultColor={setting("valueColors", "#050606")}
            colorRef={colorRef}
            textColors
          />
        </div>

        <div className={styles.row} style={{ gap: "15px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ padding: "0px 5px" }}
          >
            Bold
          </div>
          <NumSlider
            value={setting("valueBolds", 0)}
            min={0}
            max={4}
            step={1}
            onChange={(val) => applyToAll("valueBold", val)}
            color={"#2A627C"}
          ></NumSlider>
          <NumberInput
            startNumber={setting("valueBolds", 0)}
            min={0}
            max={4}
            step={1}
            handleNumberChange={(val) => applyToAll("valueBold", val)}
            color={"#2A627C"}
          ></NumberInput>
        </div>

        <div className={styles.row} style={{ gap: "12px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ paddingLeft: "5px" }}
          >
            Align
          </div>

          {alignmentOptions.map((a) => (
            <div
              className={`${styles.alignmentBtn} ${
                settings.valueAlignments === a ? styles.chosenAlignment : ""
              }`}
              onClick={() => applyToAll("valueAlignment", a)}
            >
              <i className={`bi bi-text-${a}`}></i>
            </div>
          ))}
        </div>

        {settings.metrics.some(
          (m) =>
            m.code === "nps" ||
            m.code === "flywheel" ||
            m.code === "average" ||
            m.code === "responseRate"
        ) && (
          <div
            className={styles.row}
            style={{ gap: "15px", paddingTop: "5px" }}
          >
            <div
              className={styles.microSettingTitle}
              style={{ padding: "0px 5px" }}
            >
              Decimal Digits
            </div>
            <NumberInput
              startNumber={setting("sigFigss", 0)}
              min={0}
              max={3}
              step={1}
              handleNumberChange={(val) => applyToAll("sigFigs", val)}
              color={"#2A627C"}
            ></NumberInput>
          </div>
        )}
      </div>

      {/* <div className={styles.microSetting}>
        <div className={styles.subHeader}>Position</div>{" "}
        <div
          className={styles.row}
          style={{ paddingLeft: "20px", gap: "15px" }}
        >
          {positionOptions.map((p) => (
            <div
              className={`${styles.alignmentBtn} ${
                settings.positions === p.value ? styles.chosenAlignment : ""
              }`}
              onClick={() => applyToAll("position", p.value)}
            >
              <i className={`bi bi-align-${p.icon}`}></i>
            </div>
          ))}
        </div>
      </div> */}

      <div className={styles.row} style={{ gap: "15px" }}>
        <div
          className={styles.subHeader}
          style={{ paddingRight: "10px", textWrap: "nowrap" }}
        >
          Metric Spacing
        </div>
        <NumSlider
          value={setting("spacing", 10)}
          min={0}
          max={100}
          step={1}
          onChange={(val) => changeSettings("spacing", val)}
          color={"#2A627C"}
        ></NumSlider>
        <NumberInput
          startNumber={setting("spacing", 10)}
          min={0}
          max={100}
          step={1}
          handleNumberChange={(val) => changeSettings("spacing", val)}
          color={"#2A627C"}
        ></NumberInput>
      </div>

      <div className={styles.row} style={{ gap: "15px", width: "100%" }}>
        <div className={styles.subHeader}>Show Icons</div>
        <ToggleSwitch
          startChecked={setting("showIcons", false)}
          handleCheck={(val) => applyToAll("showIcon", val)}
        />
      </div>

      {setting("showIcons", false) && (
        <>
          <div
            className={styles.row}
            style={{ gap: "20px", paddingLeft: "20px" }}
          >
            <div className={styles.orderButton} onClick={onSpinClick}>
              <div className={styles.subHeader} style={{ fontSize: ".9em" }}>
                Order
              </div>
              <i
                className={`bi bi-arrow-repeat ${styles.spinIcon} ${
                  spin ? styles.spin : ""
                }`}
              ></i>
            </div>
            <div
              className={styles.smallMetric}
              style={{ flexDirection: setting("iconOrders", "row") }}
            >
              <div className={styles.smallIcon}>
                <i className={`bi bi-check`}></i>
              </div>
              <div
                className={styles.text}
                style={{
                  fontSize: "9pt",
                  ...(setting("iconOrders", "row") === "row-reverse"
                    ? { textAlign: "right" }
                    : {}),
                }}
              >
                <div className={styles.mTitle}>Title</div>
                <div className={styles.mmetric}>Value</div>
              </div>
            </div>
          </div>

          <div
            className={styles.col}
            style={{ paddingLeft: "15px", gap: "5px" }}
          >
            <div className={styles.row} style={{ gap: "10px" }}>
              <div className={styles.microSettingTitle}>
                Color
                <i className="bi bi-info-lg"></i>
              </div>

              <ColorPicker
                defaultColor={setting("iconColors", "#ffffff")}
                onChange={(color) => applyToAll("iconColor", color)}
                colorRef={colorRef}
                textColors
              />
            </div>

            <div className={styles.row} style={{ gap: "10px" }}>
              <div className={styles.microSettingTitle}>
                Background Color <i className="bi bi-paint-bucket"></i>
              </div>

              <ColorPicker
                defaultColor={setting("iconBGColors", "#A4C6D0")}
                onChange={(color) => applyToAll("iconBGColor", color)}
                colorRef={colorRef}
              />
            </div>
          </div>
        </>
      )}

      <div className={styles.microSetting}>
        {setting("showIcons", false) && (
          <>
            <div className={styles.row} style={{ gap: "15px" }}>
              <div
                className={styles.subHeader}
                style={{ paddingRight: "10px", textWrap: "nowrap" }}
              >
                Icon Size
              </div>
              <NumSlider
                value={setting("iconSizes", 12)}
                min={10}
                max={30}
                step={1}
                onChange={(val) => applyToAll("iconSize", val)}
                color={"#2A627C"}
              ></NumSlider>
              <NumberInput
                startNumber={setting("iconSizes", 12)}
                min={10}
                max={30}
                step={1}
                handleNumberChange={(val) => applyToAll("iconSize", val)}
                color={"#2A627C"}
              ></NumberInput>
            </div>

            <div className={styles.row} style={{ gap: "15px" }}>
              <div
                className={styles.subHeader}
                style={{ paddingRight: "10px", textWrap: "nowrap" }}
              >
                Text / Icon Gap
              </div>
              <NumSlider
                value={setting("textIconGaps", 5)}
                min={0}
                max={55}
                step={1}
                onChange={(val) => applyToAll("textIconGap", val)}
                color={"#2A627C"}
              ></NumSlider>
              <NumberInput
                startNumber={setting("textIconGaps", 5)}
                min={0}
                max={55}
                step={1}
                handleNumberChange={(val) => applyToAll("textIconGap", val)}
                color={"#2A627C"}
              ></NumberInput>
            </div>
          </>
        )}

        <div className={styles.row} style={{ gap: "15px" }}>
          <div
            className={styles.subHeader}
            style={{ paddingRight: "10px", textWrap: "nowrap" }}
          >
            Title / Value Gap
          </div>
          <NumSlider
            value={setting("gaps", 0)}
            min={0}
            max={50}
            step={1}
            onChange={(val) => applyToAll("gap", val)}
            color={"#2A627C"}
          ></NumSlider>
          <NumberInput
            startNumber={setting("gaps", 0)}
            min={0}
            max={50}
            step={1}
            handleNumberChange={(val) => applyToAll("gap", val)}
            color={"#2A627C"}
          ></NumberInput>
        </div>
      </div>

      {/* <div
                className={styles.row}
                style={{ gap: "15px", width: "100%" }}
              >
                <Label
                  style={{
                    fontWeight: "500",
                    color: "#8dabb2",
                    width: "fit-content",
                    fontSize: ".8em",
                  }}
                >
                  <span style={{ color: "#a3a4a8" }}>Title</span> Size
                </Label>
                <NumberInput
                  startNumber={settings.mTitleSize}
                  handleNumberChange={(val) =>
                    changeSettings("mTitleSize", val)
                  }
                  max={35}
                  min={8}
                ></NumberInput>
              </div> */}

      {/* <div
                className={styles.row}
                style={{ gap: "15px", width: "100%" }}
              >
                <Label
                  style={{
                    fontWeight: "500",
                    fontSize: ".8em",
                    color: "#8dabb2",
                    width: "fit-content",
                  }}
                >
                  <span style={{ color: "#050606" }}>Value</span> Size
                </Label>
                <NumberInput
                  startNumber={settings.mValueSize}
                  handleNumberChange={(val) =>
                    changeSettings("mValueSize", val)
                  }
                  max={35}
                  min={8}
                ></NumberInput>
              </div> */}
    </div>
  );
}
