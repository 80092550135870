import React, { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styles from "./SortableTree.module.scss";
import {
  Announcements,
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverlay,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  DropAnimation,
  Modifier,
  defaultDropAnimation,
  UniqueIdentifier,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import {
  buildTree,
  flattenTree,
  getProjection,
  getChildCount,
  removeItem,
  removeChildrenOf,
  setProperty,
} from "./utilities";

// import type {FlattenedItem, SensorContext, TreeItems} from './types';
import { sortableTreeKeyboardCoordinates } from "./keyboardCoordinates";
import { SortableTreeItem } from "./components/TreeItem/SortableTreeItem";
import { CSS } from "@dnd-kit/utilities";

// import type { MutableRefObject } from "react";
import Button from "components/Button/Button";
import { useUpdateOrg } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import {
  useCreateAudienceFolder,
  useCreateAudienceGql,
  useDeleteAudienceFolder,
  useDeleteAudienceGql,
  useUpdateAudienceAndFolders,
  useUpdateAudienceFolder,
} from "api/resources/contacts/audiences";
import { useNavigate } from "react-router-dom";
import Modal from "components/ReactModal/ReactModal";
import { maintimezones } from "assets/functions/Variables";
import { SelectField, TextFieldSimple as TextField } from "components/inputs";
import { Label } from "components/layouts/Label/Label";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import IconShopperModal from "pages/results/Charts/Settings/AccordianItems/MetricSettings/IconShopper/IconShopperModal";
// import type {UniqueIdentifier} from '@dnd-kit/core';

// export interface TreeItem {
//   id: UniqueIdentifier;
//   children: TreeItem[];
//   collapsed?: boolean;
// }

// export type TreeItems = TreeItem[];

// export interface FlattenedItem extends TreeItem {
//   parentId: UniqueIdentifier | null;
//   depth: number;
//   index: number;
//   folder: boolean;
//   changed: boolean;
// }

// type SensorContext = MutableRefObject<{
//   items: FlattenedItem[];
//   offset: number;
// }>;

const initialItems = [
  {
    id: "Home",
    children: [],
  },
  {
    id: "Collections",
    children: [
      { id: "Spring", children: [] },
      { id: "Summer", children: [] },
      { id: "Fall", children: [] },
      { id: "Winter", children: [] },
    ],
  },
  {
    id: "About Us",
    children: [],
  },
  {
    id: "My Account",
    children: [
      { id: "Addresses", children: [] },
      { id: "Order History", children: [] },
    ],
  },
];

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimationConfig = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: "ease-out",
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

// interface Props {
//   collapsible?: boolean;
//   defaultItems?: TreeItems;
//   indentationWidth?: number;
//   indicator?: boolean;
//   removable?: boolean;
//   onClick?: any;
//   openId?: string;
// }

export function SortableTree({
  collapsible,
  defaultItems = initialItems,
  indicator = false,
  indentationWidth = 20,
  removable,
  onClick,
  openId,
  showAudienceCreate,
  setShowAudienceCreate,
  showFolderCreate,
  setShowFolderCreate,
}) {
  const [items, setItems] = useState(() => defaultItems);
  const [activeId, setActiveId] = useState(null);
  const [overId, setOverId] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(null);

  const flattenedItems = useMemo(() => {
    return setFlattenedTree(items);
  }, [activeId, items]);

  function setFlattenedTree(array) {
    const flattenedTree = flattenTree(array);
    const collapsedItems = flattenedTree.reduce(
      (acc, { children, collapsed, id, folder }) =>
        collapsed && children?.length ? [...acc, id, folder] : acc,
      []
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    );
  }

  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth
        )
      : null;
  const sensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });
  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth)
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  const sortedIds = useMemo(
    () => flattenedItems.map(({ id }) => id),
    [flattenedItems]
  );
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement("onDragMove", active.id, over?.id);
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement("onDragOver", active.id, over?.id);
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement("onDragEnd", active.id, over?.id);
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  const [edit, setedit] = useState(false);
  const [saveState, setSaveState] = useState("Save");
  const updateOrganization = useUpdateOrg();
  const updateChanged = useUpdateAudienceAndFolders();
  const deleteAudienceQuery = useDeleteAudienceGql();
  const deleteAudienceFolder = useDeleteAudienceFolder();
  const navigate = useNavigate();
  // const createFolder = useCreateAudienceFolder();

  function handleSave(list) {
    setSaveState(
      <div style={{ display: "flex", gap: ".5em" }}>
        Saving <Loading height={20} width={20}></Loading>
      </div>
    );
    const flatList = flattenTree(list);
    let audienceFolderList = list.map((item) => item?.id);
    let toUpdate = [];
    debugger;
    for (let item of flatList) {
      if (item?.changed) {
        toUpdate.push({
          audienceFolderId: item?.parentId,
          id: item?.id,
          folder: item?.folder,
        });
      }
    }
    if (toUpdate.length > 0) {
      updateChanged.mutate(
        {
          data: {
            list: toUpdate,
            name: "updater",
          },
        },
        {
          onSuccess: () => {
            updateOrg(audienceFolderList);
          },
        }
      );
    } else {
      updateOrg(audienceFolderList);
    }
  }

  function updateOrg(audienceFolderList) {
    updateOrganization.mutate(
      {
        data: {
          audienceFolderList: audienceFolderList,
        },
      },
      {
        onSuccess: () => {
          setedit(false);
          setSaveState("Save");
          // setSaveState(
          //   <div style={{ display: "flex", gap: ".5em" }}>
          //     Saved <i className="bi-check"></i>
          //   </div>
          // );
          // setTimeout(() => {
          //   setedit(false);
          //   setSaveState("Save");
          // }, 1500);
        },
      }
    );
  }

  const [audienceName, setAudienceName] = useState("");
  const [icon, setIcon] = useState("folder-fill");
  const [iconColor, setIconColor] = useState("#15bcc7");
  const [changeIcon, setChangeIcon] = useState(false);
  const [audienceDescription, setAudienceDescription] = useState("");
  const [editFolder, setEditFolder] = useState(false);
  const [editAudience, setEditAudience] = useState(false);
  const [timezone, setTimezone] = useState(maintimezones[3]);

  const createAudienceQuery = useCreateAudienceGql();
  const createAudienceFolder = useCreateAudienceFolder();
  const updateAudienceFolder = useUpdateAudienceFolder();

  const handlePostAudience = () => {
    if (showAudienceCreate) {
      const data = {
        name: audienceName,
        description: audienceDescription,
        timeZone: timezone.timezone,
        icon: icon,
        iconColor: iconColor,
      };

      createAudienceQuery.mutate(
        {
          data: data,
        },
        {
          onSuccess: (data) => {
            setAudienceName("");
            setAudienceDescription("");
            setIcon("folder-fill");
            setIconColor("#15bcc7");
            navigate("contacts/audiences/" + data?.audience?.id);
            let item = data?.audience;
            item.changed = false;
            item.folder = false;
            item.collapsed = true;
            let newItems = [item, ...items];
            setItems(newItems);
            let list = setFlattenedTree(newItems);
            let audienceFolderList = list.map((item) => item?.id);
            updateOrg(audienceFolderList);
          },
        }
      );

      setShowAudienceCreate(false);
    }

    if (showFolderCreate) {
      const data = {
        name: audienceName,
        icon: icon,
        iconColor: iconColor,
      };

      createAudienceFolder.mutate(
        {
          data: data,
        },
        {
          onSuccess: (data) => {
            setAudienceName("");
            setAudienceDescription("");
            setIcon("folder-fill");
            setIconColor("#15bcc7");
            setShowFolderCreate(false);
            let item = data?.folder;
            item.changed = false;
            item.folder = true;
            item.collapsed = false;
            let newItems = [item, ...items];
            setItems(newItems);
            let list = setFlattenedTree(newItems);
            let audienceFolderList = list.map((item) => item?.id);
            updateOrg(audienceFolderList);
          },
        }
      );

      setShowAudienceCreate(false);
    }

    if (editFolder) {
      const data = {
        name: audienceName,
        icon: icon,
        iconColor: iconColor,
        id: editFolder?.id,
      };

      updateAudienceFolder.mutate(
        {
          data: data,
        },
        {
          onSuccess: (data) => {
            // let item = data?.folder;
            // item.changed = false;
            // item.folder = true;
            // item.collapsed = false;

            let newItems = [...items];
            newItems[editFolder?.index].name = audienceName;
            newItems[editFolder?.index].icon = icon;
            newItems[editFolder?.index].iconColor = iconColor;
            setItems(newItems);

            setAudienceName("");
            setAudienceDescription("");
            setIcon("folder-fill");
            setIconColor("#15bcc7");
            setEditFolder(false);
          },
        }
      );

      setShowAudienceCreate(false);
    }
  };

  useEffect(() => {
    if (editFolder) {
      setAudienceName(editFolder?.name);
      setIcon(editFolder?.icon);
      setIconColor(editFolder?.color);
    }
  }, [editFolder]);

  return (
    <>
      <DndContext
        accessibility={{ announcements }}
        sensors={sensors}
        collisionDetection={closestCenter}
        measuring={measuring}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        {/* <SortableTreeItem
          key={"all"}
          id={""}
          value={`All Contacts`}
          members={"12343"}
          folder={false}
          depth={0}
          indentationWidth={indentationWidth}
          indicator={indicator}
          collapsed={true}
          onCollapse={false}
          onRemove={undefined}
          onClick={() => navigate("/contacts/all-contacts")}
          open={undefined}
          setedit={setedit}
          edit={edit}
          icon={{ color: undefined, icon: undefined }}
          // setEditFolder={setEditFolder}
        /> */}
        <SortableContext
          items={sortedIds}
          strategy={verticalListSortingStrategy}
        >
          {flattenedItems.map((item, index) => (
            <SortableTreeItem
              key={item?.id}
              id={item?.id}
              value={`${item?.name}`}
              members={item?.members}
              folder={item?.folder}
              depth={
                item?.id === activeId && projected
                  ? projected.depth
                  : item?.depth
              }
              indentationWidth={indentationWidth}
              indicator={indicator}
              collapsed={Boolean(item?.collapsed && item?.children?.length)}
              onCollapse={
                collapsible && item?.children?.length
                  ? () => handleCollapse(item?.id)
                  : undefined
              }
              onRemove={removable ? () => handleRemove(item?.id) : undefined}
              onClick={onClick}
              open={item.id === openId}
              setedit={setedit}
              edit={edit}
              icon={{ icon: item?.icon, color: item?.iconColor }}
              setEditFolder={setEditFolder}
              index={index}
              filters={item?.filters}
              dynamic={item?.dynamic}
            />
          ))}

          {edit && (
            <div className={styles.editOptions}>
              <button
                className={`${styles.button} ${styles.cancel}`}
                onClick={() => {
                  setedit(false);
                  setItems(() => defaultItems);
                }}
              >
                Cancel
              </button>
              <button
                className={styles.button}
                onClick={() => handleSave(items, flattenedItems)}
              >
                {saveState}
              </button>
            </div>
          )}
          {createPortal(
            <DragOverlay
              dropAnimation={dropAnimationConfig}
              modifiers={indicator ? [adjustTranslate] : undefined}
            >
              {activeId && activeItem ? (
                <SortableTreeItem
                  id={activeId}
                  depth={activeItem.depth}
                  clone
                  childCount={getChildCount(items, activeId) + 1}
                  value={activeItem?.name}
                  indentationWidth={indentationWidth}
                  members={activeItem?.members}
                  onClick={undefined}
                  folder={activeItem?.folder}
                  open={activeId === openId}
                  setedit={undefined}
                  edit={undefined}
                  icon={{
                    color: activeItem?.icon,
                    icon: activeItem?.iconColor,
                  }}

                />
              ) : null}
            </DragOverlay>,
            document.body
          )}
        </SortableContext>
      </DndContext>


      <Modal
        show={
          showAudienceCreate || showFolderCreate || editFolder || editAudience
        }
        onClose={() => {
          if (showAudienceCreate) {
            setShowAudienceCreate(false);
            setAudienceName("");
            setAudienceDescription("");
          }
          if (showFolderCreate) {
            setShowFolderCreate(false);
            setAudienceName("");
            setAudienceDescription("");
          }
          if (editFolder) {
            setEditFolder(false);
            setAudienceName("");
            setAudienceDescription("");
          }
          if (editAudience) {
            setEditAudience(false);
            setAudienceName("");
            setAudienceDescription("");
          }
        }}
        modalStyle={{
          padding: "10px",
          height: "100%",
          width: "100%",
          maxWidth: "25rem"
        }}
        dark
        rightStyle
      >
        
        <div className={styles.text}>
          {showFolderCreate && <div className={styles.header_4} style={{marginTop: "0em"}}>Create Folder</div>}
          {showAudienceCreate && <div className={styles.header_4} style={{marginTop: "0em"}}>Create Audience</div>}
          <TextField
            label={
              showAudienceCreate || editAudience
                ? "Audience Name *"
                : "Folder Name *"
            }
            value={audienceName}
            placeholder={
              showAudienceCreate
                ? "Type audience name..."
                : "Type folder name..."
            }
            onSave={(value) => setAudienceName(value)}
          ></TextField>
          {(showAudienceCreate || editAudience) && (
            <TextField
              label="Description"
              value={audienceDescription}
              placeholder="type description..."
              onSave={(value) => setAudienceDescription(value)}
            ></TextField>
          )}
          {showAudienceCreate && (
            <SelectField
              value={timezone}
              label="Time Zone"
              options={maintimezones}
              onChange={(val) => setTimezone(val)}
            ></SelectField>
          )}
          <div className={styles.icon}>
            <Label>Folder Icon: </Label>
            <i
              className={"bi-" + icon}
              style={{ color: iconColor }}
              onClick={() => setChangeIcon(true)}
            ></i>
            <ColorPicker
              defaultColor={iconColor}
              onChange={setIconColor}
            ></ColorPicker>
          </div>
          {changeIcon && (
            <IconShopperModal
              onPick={(i) => {
                setIcon(i);
              }}
              onClose={() => setChangeIcon(false)}
            ></IconShopperModal>
          )}
          <div className={styles.saveBtn}>
            <Button
              link
              onClick={() => {
                setShowAudienceCreate(false);
                setAudienceName("");
                setAudienceDescription("");
              }}
            >
              Cancel
            </Button>
            <Button shadow onClick={handlePostAudience}>
              {showAudienceCreate && "Create Audience"}
              {showFolderCreate && "Create Folder"}
              {editFolder && "Save Changes"}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );

  function handleDragStart({ active: { id: activeId } }) {
    setActiveId(activeId);
    setOverId(activeId);

    const activeItem = flattenedItems.find(({ id }) => id === activeId);

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      });
    }

    document.body.style.setProperty("cursor", "grabbing");
  }

  function handleDragMove({ delta }) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }) {
    setOverId(over?.id ?? null);
  }

  function handleDragEnd({ active, over }) {
    resetState();
    // console.log("here")
    if (projected && over) {
      const { depth, parentId } = projected;
      const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));

      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const parentIndex = clonedItems.findIndex(({ id }) => id === parentId);
      if (!clonedItems[parentIndex]?.folder && depth > 0) {
        // if (confirm("Create a folder for the two audiences?")) {
        //   //create folder
        //   createFolder.mutate({
        //     data: {
        //       name: "New Folder"
        //     }
        //   }, {
        //     onSuccess: ()
        //   })
        //   //update current index
        //   const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
        //   const activeTreeItem = clonedItems[activeIndex];
        //   clonedItems[activeIndex] = {
        //     ...activeTreeItem,
        //     depth,
        //     parentId,
        //     changed: clonedItems[activeIndex]?.parentId != parentId ? true : false,
        //   };
        //   const overTreeItem = clonedItems[overIndex];
        //   clonedItems[overIndex] = {
        //     ...overTreeItem,
        //     depth,
        //     parentId,
        //     changed: clonedItems[activeIndex]?.parentId != parentId ? true : false,
        //   };
        // }
      } else {
        const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
        const activeTreeItem = clonedItems[activeIndex];

        clonedItems[activeIndex] = {
          ...activeTreeItem,
          depth,
          parentId,
          changed:
            clonedItems[activeIndex]?.parentId != parentId ? true : false,
        };

        const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
        const newItems = buildTree(sortedItems);

        setItems(newItems);
      }
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty("cursor", "");
  }

  function handleRemove(id) {
    let text = "";
    let item = flattenedItems.find((f) => f.id === id);
    if (item?.folder) {
      text = `Are you sure you want to delete "${item?.name}" and all its contents?`;
    } else {
      text = `Are you sure you want to delete "${item?.name}"?`;
    }
    if (confirm(text)) {
      if (item?.folder) {
        deleteAudienceFolder.mutate(
          {
            id: id,
          },
          {
            onSuccess: () => {
              setItems((items) => removeItem(items, id));
              if (openId === id) {
                navigate("contacts/audiences/");
              }
            },
          }
        );
      } else {
        deleteAudienceQuery.mutate(
          {
            deleteAudienceId: id,
          },
          {
            onSuccess: () => {
              setItems((items) => removeItem(items, id));
              if (openId === id) {
                navigate("contacts/audiences/");
              }
            },
          }
        );
      }
    }
  }

  function handleCollapse(id) {
    let temp = [...items];
    temp = setProperty(temp, id, "collapsed", (value) => {
      return !value;
    })
    setItems(temp);
    // setItems((items) =>
    //   setProperty(items, id, "collapsed", (value) => {
    //     return !value;
    //   })
    // );
  }

  function getMovementAnnouncement(eventName, activeId, overId) {
    if (overId && projected) {
      if (eventName !== "onDragEnd") {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return;
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          });
        }
      }

      const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
      const overIndex = clonedItems.findIndex(({ id }) => id === overId);
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === "onDragEnd" ? "dropped" : "moved";
      const nestedVerb = eventName === "onDragEnd" ? "dropped" : "nested";

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
        } else {
          let previousSibling = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId = previousSibling.parentId;
            previousSibling = sortedItems.find(({ id }) => id === parentId);
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    }

    return;
  }
}

const adjustTranslate = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};
