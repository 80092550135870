// External
import React, { useState } from "react";

// Internal
import styles from "./Audiences.module.scss";
import { OrgAudiences } from "./OrgAudiences";

export const Audiences = ({ user, organization, roles }) => {
  return (
    <>
      <div className={styles.page}>
        <OrgAudiences
          user={user}
          organization={organization}
          roles={roles}
        />

        
      </div>
    </>
  );
};
