import styles from "./Tips.module.scss";
import { useState, useRef, useEffect } from "react";

export function Tips({ tips, onIgnore, close }) {
  const [show, setShow] = useState(false);
  const [showTitle, setShowTitle] = useState(true);

  const ref = useRef(null);

  function setUp() {
    setShow(true);
    setTimeout(() => document.addEventListener("click", onClick, true), 300);
  }

  function onClick(e) {
    if (!ref.current || !ref.current.contains(e.target)) {
      setShow(false);
      document.removeEventListener("click", onClick, true);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setShowTitle(false);
    }, 1500);
  }, []);

  return (
    <div
      className={styles.indicator}
      onClick={show ? undefined : setUp}
      ref={ref}
      style={
        tips.some((t) => !t.ignore && t.red) ? { backgroundColor: "#FF8878" } : undefined
      }
    >
      <div
        className={`${styles.bpTitle} ${
          showTitle || show ? styles.showTitle : ""
        }`}
      >
        Best Practices
      </div>
      <i className="bi bi-info-circle"></i>

      {show && (
        <div className={styles.list}>
          <div className={styles.exit} onClick={() => setShow(false)}>
            <i className="bi bi-x"></i>
          </div>

          {tips.map((prob, i) => (
            <>
              {!prob.ignore && (
                <div className={styles.problem} key={i}>
                  <div>{prob.title}</div>
                  <div className={styles.divider}>|</div>
                  <div className={styles.ignore} onClick={() => onIgnore(i)}>
                    ignore
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
}
