// External
import React, { useEffect, useState, useRef } from "react";
import styles from "./Users.module.scss";

// Internal
import {
  useFetchUserActivity,
  useFetchUsersByOrgId,
  useRemoveUserFromOrg,
} from "api/resources/organization/users";
import CreateJoinCode from "./CreateJoinCode";
import { useParams } from "react-router-dom";
import UserProfile2 from "components/Popout/UserProfile2";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";
import { Sorting } from "components/tables/BasicTable/InteractiveSurveysTable";
import { Pagination } from "components/tables/BasicTable/Pagination";
import ImageEditor from "components/Image Editor/ImageEditor";
import Icon from "components/Icon/Icon";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { trimDate } from "assets/functions/DateFunctions";
import { ProgressBar } from "components/QuestionConstruction/ProgressBar/ProgressBar";
import DoYouWantToSave from "components/Popout/DoYouWantToSave";

export const Users = ({ organization, roles }) => {
  const { id } = useParams();
  const removeUser = useRemoveUserFromOrg();
  const [showUser, setShowUser] = useState(false);
  const [currUser, setCurrUser] = useState(null);
  const [createCode, setCreateCode] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerpage] = useState(10);
  const [pageSkip, setPageSkip] = useState(0);
  const [checkRemove, setCheckRemove] = useState(false);
  const [sort, setSort] = useState({
    descend: false,
    item: "firstName",
  });

  const getUsers = useFetchUsersByOrgId(
    searchString,
    pageSkip,
    perPage,
    sort,
    id
  );

  const selectUser = (user) => {
    delete user.organizationId;
    setCurrUser(user);
    setShowUser(true);
  };

  useEffect(() => {
    getUsers.refetch();
  }, [pageNumber, sort, perPage, searchString]);

  function handleRemoveUser(id) {
    if (id) {
      removeUser.mutate(
        {
          orgId: organization.id,
          userId: id,
        },
        {
          onSuccess: () => {
            setCheckRemove(false);
            location.reload();
          },
        }
      );
    }
  }

  return (
    <div className={styles.page}>
      {checkRemove && (
        <DoYouWantToSave
          cancelText={"No, do not remove"}
          confirmText={"Yes"}
          prompt={
            "Are you sure you want to remove this user from your organization?"
          }
          navAway={() => setCheckRemove(false)}
          onSave={() => handleRemoveUser(checkRemove)}
        ></DoYouWantToSave>
      )}
      <FlexRow style={{ flexWrap: "wrap", maxWidth: "1250px" }}>
        <div className={styles.header_3}>Manage Users</div>
        <FlexRow style={{ width: "fit-content", flexWrap: "wrap" }}>
          <Button shadow onClick={() => setCreateCode(true)}>
            + Add User
          </Button>
          <NavTextField
            value={searchString}
            setValue={setSearchString}
            placeholder={"Search user..."}
            shadow
          />
        </FlexRow>
      </FlexRow>
      {getUsers.isLoading && <Loading></Loading>}
      {getUsers.isError && <p>Error</p>}
      {getUsers.isSuccess && (
        <>
          <FlexRow
            style={{ width: "100%", maxWidth: "1250px", marginTop: "1.5rem" }}
          >
            <FlexRow
              style={{ width: "100%", flexWrap: "wrap-reverse", rowGap: "0" }}
              start
            >
              <div className={styles.progContainer}>
                <ProgressBar
                  settings={{ showProgBar: true }}
                  numAnswered={getUsers.data?.users?.admins}
                  numQuestions={organization?.adminsAllowed}
                ></ProgressBar>
              </div>
              <span
                className={styles.section_label6}
                style={{ height: "fit-content" }}
              >
                used {getUsers.data?.users?.admins} of{" "}
                {organization?.adminsAllowed
                  ? organization?.adminsAllowed
                  : "Unlimited"}{" "}
                available admin slots
              </span>
            </FlexRow>
            <FlexRow style={{ width: "100%", maxWidth: "15rem" }}>
              <span
                className={styles.header_6}
                style={{ height: "fit-content" }}
              >
                Users logged in
              </span>
              <span
                className={styles.header_6}
                style={{ height: "fit-content" }}
              >
                {getUsers.data?.users?.loggedIn}
              </span>
            </FlexRow>
          </FlexRow>
          <FlexRow
            style={{ width: "100%", maxWidth: "1250px", marginBottom: "1rem" }}
          >
            <FlexRow
              style={{ width: "100%", flexWrap: "wrap-reverse", rowGap: "0" }}
              start
            >
              <div className={styles.progContainer}>
                <ProgressBar
                  settings={{ showProgBar: true }}
                  numAnswered={getUsers.data?.users?.userCount}
                  numQuestions={organization?.usersAllowed}
                ></ProgressBar>
              </div>
              <span
                className={styles.section_label6}
                style={{ height: "fit-content" }}
              >
                used {getUsers.data?.users?.userCount} of{" "}
                {organization?.usersAllowed
                  ? organization?.usersAllowed
                  : "Unlimited"}{" "}
                available user slots
              </span>
            </FlexRow>
            <FlexRow style={{ width: "100%", maxWidth: "15rem" }}>
              <span
                className={styles.header_6}
                style={{ height: "fit-content" }}
              >
                Users logged out
              </span>
              <span
                className={styles.header_6}
                style={{ height: "fit-content" }}
              >
                {getUsers.data?.users?.loggedOut}
              </span>
            </FlexRow>
          </FlexRow>

          <div
            className={styles.sortBox}
            style={{ margin: "1rem 0", maxWidth: "1240px" }}
          >
            <Sorting sort={sort} setSort={setSort} person></Sorting>

            <Pagination
              pageNum={pageNumber}
              setPageNum={setPageNumber}
              maxPage={
                getUsers.data?.users?.count / perPage < 1
                  ? 1
                  : Math.ceil(getUsers.data?.users?.count / perPage)
              }
              length={getUsers.data?.users?.users?.length}
              searchString={searchString}
              perPage={perPage}
              setPerPage={setPerpage}
              setPageSkip={setPageSkip}
            ></Pagination>
          </div>
          {getUsers.isRefetching && <Loading></Loading>}
          {!getUsers.isRefetching && (
            <div className={styles.users}>
              {getUsers.data?.users?.users?.map((user, index) => (
                <User
                  key={index}
                  user={user}
                  index={index}
                  onClick={() => selectUser(user)}
                  orgId={organization?.id}
                  setCheckRemove={setCheckRemove}
                ></User>
              ))}
            </div>
          )}
          <CreateJoinCode
            show={createCode}
            close={() => setCreateCode(false)}
            orgid={id}
          />
          {showUser && roles.canEditOrgUsers && (
            <UserProfile2
              show={showUser}
              setShow={setShowUser}
              userId={currUser?.id}
              orgs={currUser?.organization}
              roles={currUser?.roles}
              organizationId={organization?.id}
              canEdit={roles.canEditOrgUsers}
              popout
            />
          )}
        </>
      )}
    </div>
  );
};

function User({ user, onClick, orgId, setCheckRemove }) {
  function getRoles(user) {
    let role = user?.roles.find((role) => role?.organizationId === orgId);
    return role?.name;
  }

  const getActivity = useFetchUserActivity(
    "",
    0,
    1,
    {
      item: "createdAt",
      descend: true,
    },
    user?.id,
    orgId,
    "one"
  );

  const [options, setOptions] = useState(false);
  const ref2 = useRef(null);

  function clickOutside(e) {
    if (ref2.current && !ref2.current?.contains(e.target)) {
      setOptions(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  return (
    <div className={styles.user}>
      <FlexRow style={{ alignItems: "flex-start" }}>
        <ImageEditor
          src={user?.image?.imageURL}
          canEdit={false}
          isUser={true}
          userId={user?.id}
          position={
            user?.image?.position && typeof user?.image?.position === "string"
              ? JSON.parse(user?.image?.position)
              : user?.image?.position
          }
          image={user?.image}
          // refetch={getUser.refetch}
          height={50}
          width={50}
          noShadow
        ></ImageEditor>
        <div
          className={styles.iconContainer}
          onClick={(e) => {
            e.stopPropagation();
            setOptions(true);
          }}
        >
          <Icon iconName={"three-dots-vertical"}></Icon>
        </div>
        {options && (
          <div
            className={styles.settingsBox}
            ref={ref2}
            style={{ top: ".3rem", right: ".3rem" }}
          >
            <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              <i
                className="bi-layout-text-sidebar-reverse"
                style={{ color: "#A7D5C5" }}
              ></i>{" "}
              View Details
            </div>

            {/* <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <i className="bi-chat" style={{ color: "#A7D5C5" }}></i> Message
            </div> */}

            {/* <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <i className="bi-ban" style={{ color: "#A7D5C5" }}></i> Restrict
              Access
            </div> */}

            <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                setCheckRemove(user.id);
              }}
            >
              <i className="bi-trash" style={{ color: "#FF8878" }}></i> Remove
              User
            </div>
          </div>
        )}
      </FlexRow>
      <FlexRow
        style={{ flexDirection: "column", gap: "0", alignItems: "flex-start" }}
        gap={0}
        start
      >
        <FlexRow start gap="0rem">
          <span
            className={styles.header_4}
            style={{ margin: "0", padding: "0" }}
          >
            {user?.firstName} {user?.lastName}
          </span>
        </FlexRow>
        <span
          className={styles.section_label6}
          style={{ margin: "0", height: "fit-content" }}
        >
          {user?.position}
        </span>
      </FlexRow>
      <HorizontalBar height={2} style={{ margin: "0" }}></HorizontalBar>
      <FlexRow
        style={{ flexDirection: "column", gap: "0", alignItems: "flex-start" }}
        gap={0}
        start
      >
        <span
          className={styles.permission}
          style={{ margin: "0", padding: "0" }}
        >
          Permission Role:
        </span>
        <span className={styles.role}>{getRoles(user)}</span>
      </FlexRow>
      <FlexRow
        style={{ flexDirection: "column", gap: "0", alignItems: "flex-start" }}
        gap={0}
        start
      >
        <span
          className={styles.permission}
          style={{ margin: "0", padding: "0" }}
        >
          Recent Activity:
        </span>

        {getActivity.isSuccess &&
          getActivity.data?.object?.activities.map((a) => (
            <span className={styles.activity}>
              {a.message} {trimDate(a.createdAt, true)}
            </span>
          ))}
      </FlexRow>
    </div>
  );
}
