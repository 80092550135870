import Modal from "components/ReactModal/ReactModal";
import styles from "./BucketBreakdown.module.scss";
import {
  useFetchBucket,
  useUpdateAnswerBucket,
} from "api/resources/organization/buckets";
import { Loading } from "components/Loading/Loading";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { useState, useRef } from "react";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import TextEditorPlain from "components/inputs/input_fields/TextEditorPlain/TextEditorPlain";
import Button from "components/Button/Button";

export default function BucketBreakdown({
  bucketId,
  answerBucket,
  contact,
  onClose,
  answer,
  refetch,
}) {
  const fetchBucket = useFetchBucket(bucketId);
  const updateBucket = useUpdateAnswerBucket();

  const [bucketing, setBucketing] = useState(
    answerBucket ? JSON.parse(JSON.stringify(answerBucket)) : {}
  );
  const [edit, setEdit] = useState(false);
  const [edited, setEdited] = useState(false);
  const [menu, setMenu] = useState(null);
  const [showNew, setShowNew] = useState("");

  const ref = useRef(null);

  function getAccuracy() {
    let val = edit ? bucketing.acc : answerBucket.acc;
    let acc = typeof val === "string" ? Number.parseFloat(val) : val;
    acc *= 100;
    return acc;
  }

  function onCancel() {
    setEdited(false);
    setEdit(false);
    setBucketing(answerBucket ? JSON.parse(JSON.stringify(answerBucket)) : {});
  }

  function onSave() {
    let answerBuckets = JSON.parse(answer.buckets);

    answerBuckets[fetchBucket.data.bucket.name] = bucketing;

    updateBucket.mutate(
      {
        id: answer.id,
        buckets: JSON.stringify(answerBuckets),
      },
      {
        onSuccess: (data) => {
          if (data.updateAnswerBucket) {
            refetch();
            answerBucket.breakdown = { ...bucketing.breakdown };
            answerBucket.acc = bucketing.acc;
            answerBucket.overall = bucketing.overall;
          }
        },
      }
    );

    setEdited(false);
    setEdit(false);
  }

  function editField(field, val) {
    let copy = { ...bucketing };
    copy[field] = val;
    setBucketing(copy);
    setEdited(true);
  }

  function editAcc(val) {
    let num = (val / 100).toFixed(2);
    editField("acc", num);
  }

  function editPiece(prop, ind, newPiece) {
    let copy = { ...bucketing.breakdown };
    copy[prop][ind] = newPiece;
    editField("breakdown", copy);
  }

  function removePiece(prop, ind) {
    let copy = { ...bucketing.breakdown };
    copy[prop].splice(ind, 1);
    editField("breakdown", copy);
  }

  function checkSelect(event) {
    const selection = window.getSelection();
    let container = document.getElementById("selectable answer");
    if (!selection.isCollapsed) {
      let piece = selection.toString();
      if (piece && answer.textAnswer.includes(piece)) {
        const range = selection.getRangeAt(0);
        const rangeDim = range.getBoundingClientRect();
        const dim = container.getBoundingClientRect();

        const tall = dim.bottom - rangeDim.top + 7;

        let obj = {
          selection: piece,
          style: {
            bottom: tall + "px",
          },
        };

        let midpoint = dim.width / 2 + dim.left;
        if (event.clientX > midpoint) {
          obj.style.right = dim.right - event.clientX + "px";
        } else {
          obj.style.left = event.clientX - dim.left + "px";
        }
        setMenu(obj);

        setTimeout(
          () => document.addEventListener("click", onClickListener, false),
          500
        );
      }
    }
  }

  function onClickListener(e) {
    if (ref.current) {
      if (!ref.current.contains(e.target)) {
        setMenu(null);
        document.removeEventListener("click", onClickListener, false);
      }
    } else {
      document.removeEventListener("click", onClickListener, false);
    }
  }

  function addSelectedPiece(prop) {
    if (menu && menu.selection) {
      let copy = { ...bucketing.breakdown };
      if (!copy[prop]) {
        copy[prop] = [];
      }
      copy[prop].push(menu.selection);
      editField("breakdown", copy);
    }

    setMenu(null);
  }

  function onClickAdd(prop) {
    setShowNew(prop);
    setTimeout(() => {
      let newPiece = document.getElementById("new piece for " + prop);
      if (newPiece) {
        newPiece.focus();
      }
    }, 300);
  }

  function addPiece(prop, piece) {
    if (piece) {
      let copy = { ...bucketing.breakdown };
      if (!copy[prop]) {
        copy[prop] = [];
      }
      copy[prop].push(piece);
      editField("breakdown", copy);
      setShowNew("");
    }
    setShowNew("");
  }

  return (
    <>
      <Modal
        onClose={onClose}
        show={true}
        modalStyle={{
          borderRadius: ".75em",
          height: "fit-content",
          overflow: "auto",
          // width: "95%",
          // height: "100%",
          maxWidth: "80vw",
        }}
        dark
      >
        {fetchBucket.isLoading && <Loading />}
        {fetchBucket.isSuccess && (
          <div className={styles.container}>
            <div className={styles.title}>
              {`${fetchBucket.data.bucket.name} - ${
                contact
                  ? contact.firstName + " " + contact.lastName
                  : "Anonymous participant "
              }`}
              {!edit && (
                <div
                  className={styles.edit}
                  onClick={() => setTimeout(() => setEdit(true), 200)}
                >
                  {" "}
                  Edit
                </div>
              )}
            </div>

            <div
              className={styles.stats}
              style={edit ? { gap: "5px" } : undefined}
            >
              {/* <div>{`Overall ${fetchBucket.data.bucket.singular}: ${answerBucket.overall}`}</div> */}
              <div className={styles.stat}>
                <span
                  className={styles.statTitle}
                >{`Overall ${fetchBucket.data.bucket.singular}: `}</span>
                {!edit && (
                  <span className={styles.result}>{answerBucket.overall}</span>
                )}
                {edit && (
                  <div>
                    <CombinedInput
                      value={bucketing.overall}
                      options={fetchBucket.data.bucket.properties}
                      icon={"bi-chevron-compact-down"}
                      select
                      onChange={(val) => editField("overall", val)}
                    />
                  </div>
                )}
              </div>

              <div className={styles.stat}>
                <span className={styles.statTitle}>{`Accuracy: `}</span>
                {!edit && (
                  <span className={styles.result}>{getAccuracy() + "%"}</span>
                )}

                {edit && (
                  <>
                    <div className={styles.percent}>
                      <NumberInput
                        style={{ width: "50px", fontSize: ".9em" }}
                        startNumber={getAccuracy()}
                        step={1}
                        min={0}
                        max={100}
                        borderColor={"#d8d9d9"}
                        handleNumberChange={editAcc}
                      />
                      <span className={styles.percent}>%</span>
                    </div>
                  </>
                )}
              </div>

              <div className={styles.breakdown}>
                <div className={styles.statTitle}>Breakdown:</div>
                <div className={styles.list}>
                  {!edit &&
                    fetchBucket.data.bucket.properties.map((prop) => (
                      <>
                        {answerBucket.breakdown[prop] &&
                          answerBucket.breakdown[prop].length > 0 && (
                            <div className={styles.prop}>
                              <div
                                className={styles.propName}
                              >{`${prop}: `}</div>
                              {answerBucket.breakdown[prop].map((piece) => (
                                <div className={styles.piece}>{piece}</div>
                              ))}
                            </div>
                          )}
                      </>
                    ))}

                  {edit &&
                    fetchBucket.data.bucket.properties.map((prop) => (
                      <div className={styles.prop} style={{ gap: "2px" }}>
                        <div className={styles.propName}>
                          {`${prop}: `}

                          {showNew !== prop &&
                            (!bucketing.breakdown[prop] ||
                              !bucketing.breakdown[prop].length) && (
                              <div
                                className={styles.plusPiece}
                                onClick={() => onClickAdd(prop)}
                              >
                                <i className="bi bi-plus-circle"></i>
                              </div>
                            )}
                        </div>

                        {bucketing.breakdown[prop] && (
                          <>
                            {bucketing.breakdown[prop].map((piece, i) => (
                              <div className={styles.editPiece}>
                                <TextEditorPlain
                                  text={piece}
                                  editable
                                  extraClass={styles.pieceEditor}
                                  onSave={(newPiece) =>
                                    editPiece(prop, i, newPiece)
                                  }
                                  onEnter={(e) => e.target.blur()}
                                />

                                <div
                                  className={styles.removePiece}
                                  onClick={() => removePiece(prop, i)}
                                >
                                  <i className="bi bi-dash-circle"></i>
                                </div>
                              </div>
                            ))}
                            {showNew !== prop &&
                              bucketing.breakdown[prop]?.length > 1 && (
                                <div className={styles.editPiece}>
                                  <div
                                    className={styles.plusPiece}
                                    style={{ paddingLeft: "10px" }}
                                    onClick={() => onClickAdd(prop)}
                                  >
                                    <i className="bi bi-plus-circle"></i>
                                  </div>
                                </div>
                              )}
                          </>
                        )}

                        {showNew === prop && (
                          <div className={styles.editPiece}>
                            <TextEditorPlain
                              text={""}
                              editable
                              extraClass={styles.newPiece}
                              // onSave={(newPiece) => addPiece(prop, newPiece)}
                              onEnter={(e) => e.target.blur()}
                              id={"new piece for " + prop}
                              onLoseFocus={(newPiece) =>
                                addPiece(prop, newPiece)
                              }
                            />
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className={styles.answerSection}>
              <div className={styles.answerHeader}>
                <div className={styles.answerLabel}>Answer</div>
                {edit && (
                  <div className={styles.selectableTip}>{"-  selectable"}</div>
                )}
              </div>

              {!edit && (
                <div className={styles.answer}>{answer.textAnswer}</div>
              )}
              {edit && (
                <div
                  className={styles.selectableAnswer}
                  onMouseUp={checkSelect}
                  // onSelectCapture={checkSelect}
                  id={"selectable answer"}
                >
                  <>
                    {menu && (
                      <div className={styles.menu} style={menu.style} ref={ref}>
                        <div className={styles.addTo}>Add To: </div>
                        {fetchBucket.data.bucket.properties.map((prop) => (
                          <div
                            className={styles.propToAddTo}
                            onClick={() => addSelectedPiece(prop)}
                          >
                            {prop}
                          </div>
                        ))}
                      </div>
                    )}
                    {answer.textAnswer}
                  </>
                </div>
              )}
            </div>

            {edit && (
              <div className={styles.buttons}>
                <Button red onClick={onCancel} >
                  Cancel
                </Button>
                {edited && (
                  <Button onClick={onSave} >
                    Save
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </Modal>
    </>
  );
}
