import React, { useState, useEffect } from "react";
import styles from "./ExternalImport.module.scss";
import ContactTable from "../all_contacts/ContactTable";
import salesforce from "assets/images/Salesforce.png";
import axios from "axios";
import { Loading } from "components/Loading/Loading";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { useFetchAnOrgsFieldsMutation } from "api/resources/organization/organization";
import MergeColumns from "../all_contacts/MergeColumns";
import ColumnMapping from "components/ContactCleaner2.0/ColumnMapping";
import {
  checkMap,
  ContactCleaner,
} from "components/ContactCleaner2.0/ContactCleaner";
import Button from "components/Button/Button";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { forEach } from "assets/functions/ArrayFunctions";
import { shortId } from "components/tables/EditableTable/utils";

const initheads = [
  // {
  //   id: "contactName",
  //   name: "Contact Name",
  //   accessor: "firstName",
  //   enabled: true,
  //   sort: false,
  //   canSort: false,
  //   cell_style: (name, id, i, contact) =>
  //     contact && (
  //       <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
  //         {" "}
  //         <div
  //           className={styles.profileImage}
  //           style={{
  //             backgroundColor: contact?.color ? contact?.color : randomColor(),
  //           }}
  //         >
  //           {" "}
  //           <span>
  //             {contact && contact?.firstName[0] + contact?.lastName[0]}
  //           </span>
  //         </div>
  //         {contact && `${contact?.firstName} ${contact?.lastName}`}
  //       </div>
  //     ),
  //   value: "firstName",
  //   label: "Contact Name",
  //   dataType: "text",
  //   enabled: true,
  //   sort: false,
  //   canSort: true,
  //   fullName: true,
  //   custom: false,
  // },
  {
    id: 1,
    name: "Email",
    accessor: "email",
    enabled: false,
    // sort: true,
    canSort: false,
    cell_style: null,
    value: "email",
    label: "Email",
    dataType: "text",
    sfName: "Email",
    enabled: true,
    sort: false,
    canSort: true,
    fullName: true,
    custom: false,
  },
  {
    id: 2,
    name: "First Name",
    accessor: "firstName",
    enabled: false,
    // sort: true,
    canSort: false,
    cell_style: null,
    value: "firstName",
    label: "First Name",
    dataType: "text",
    sfName: "FirstName",
    enabled: true,
    sort: false,
    canSort: true,
    fullName: true,
    custom: false,
  },
  {
    id: 3,
    name: "Last Name",
    accessor: "lastName",
    enabled: false,
    // sort: true,
    canSort: false,
    cell_style: null,
    value: "lastName",
    label: "Last Name",
    dataType: "text",
    sfName: "LastName",
    enabled: true,
    sort: false,
    canSort: true,
    fullName: true,
    custom: false,
  },
];

function ExternalImport({
  seeContact,
  organization,
  user,
  type,
  audience,
  customFields,
  startImportType,
}) {
  const [headers, setHeaders] = useState(initheads);
  const [externalColumns, setExternalColumns] = useState();
  const [sessionExpired, setSessionExpired] = useState(false);
  const [importing, setImporting] = useState(false);
  const [importType, setImportType] = useState(
    startImportType ? startImportType : false
  );
  const [custom_fields, setCustomFields] = useState();
  const [customFieldsMap, setCustomFieldsMap] = useState({});
  const [headerMap, setHeaderMap] = useState({});
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [maxData, setMaxData] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [dataLoading, setDataLoading] = useState();

  const handleLoginSalesforce = async () => {
    try {
      const url = `${process.env.REACT_APP_SALESFORCE_KEY2}&userID=${user?.id}`;
      const response = await axios.get(url, { withCredentials: true });
      window.location.href = response.data.loginUrl;
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  useEffect(() => {
    if (type === "Salesforce") {
      let contactColumns = [];

      let url = process.env.REACT_APP_SALESFORCE_COLUMNS;
      const config = {
        withCredentials: true,
        headers: {
          accept: "application/json",
        },
        params: {
          organization_id: organization.id,
        },
      };
      let externalCols = [];
      let headMap = {};
      //contact columns
      axios
        .get(url, config)
        .then((response) => {
          if (response?.data && response?.data?.length > 0) {
            for (let head of response.data) {
              let custom = false;
              let og = `${head}`;
              let label = `${head}`;
              if (
                head != "Email" &&
                head != "FirstName" &&
                head != "LastName" &&
                head != "Id"
              ) {
                if (head.includes("__c")) {
                  label = head.replace("__c", "");
                  custom = true;
                }
                let name = head.toLowerCase();
                // if (head === "FirstName" || head === "LastName") {
                //   name = head[0].toLowerCase() + head.slice(1);
                // }
                let header = {
                  id: head,
                  name: label,
                  accessor: head,
                  sfName: og,
                  canSort: false,
                  cell_style: null,
                  value: name,
                  label: label,
                  enabled: false,
                  sort: false,
                  canSort: true,
                  fullName: true,
                  custom: false,
                  account: false,
                  isReaction: false,
                };
                contactColumns.push(header);
                // checkMap(header, headMap, customFields);
              }
            }
            // for (let h of initheads) {
            //   checkMap(h, headMap, customFields);
            // }
            externalCols = response.data;
            let fields = [];
            let fieldsMap = {};
            for (let f of customFields) {
              fields.push({
                id: f.id,
                name: f.name,
                accessor: f.name,
                enabled: true,
                canSort: false,
                cell_style: null,
                sfName: f.name,
                value: f.name,
                label: f.name,
                sort: false,
                canSort: true,
                fullName: true,
                custom: false,
                account: false,
                isReaction: true,
              });
              fieldsMap[f.name] = false;
            }
            setCustomFieldsMap(fieldsMap);
            setCustomFields(fields);
            let finalheaders = [...headers, ...contactColumns];
            let i = 0;
            for (let h of finalheaders) {
              checkMap(h, headMap, customFields, i, finalheaders);
              if (headMap[h?.accessor]?.columns?.length > 0) {
                finalheaders[i].enabled = true;
                fieldsMap[headMap[h?.accessor]?.columns[0]?.value] = true;
              }
              i++;
            }
            setHeaders(finalheaders);
            setExternalColumns([]);
            setHeaderMap(headMap);

            // getAccountCols(url, config, externalCols, contactColumns);
            // }
            //  else {
            //   setHeaders([...headers, ...contactColumns]);
            //   setExternalColumns(externalCols);
            //   // getAccountCols(url, config, externalCols, contactColumns);
            // }
          }
        })
        .catch((error) => {
          console.error(error);
          if (error?.response?.status === 401) {
            setSessionExpired(true);
            setTimeout(() => {
              handleLoginSalesforce();
            }, 1000);
          }
        });
    }
  }, []);

  function getAccountCols(url, config, externalCols, contactColumns) {
    //account columns
    let accountColumns = [];
    url = process.env.REACT_APP_SALESFORCE_COLUMNS_ACCOUNT;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data && response?.data?.length > 0) {
          for (let head of response.data) {
            let custom = false;
            let og = `${head}`;
            if (head != "Email") {
              if (head.includes("__c")) {
                head = head.replace("__c", "");
                // custom = true;
              }
              let name = head[0].toLowerCase() + head.slice(1);
              accountColumns.push({
                id: head,
                name: "Account " + head,
                accessor: "account",
                objectAccessor: og,
                enabled: true,
                canSort: false,
                cell_style: null,
                value: "account " + og,
                label: "Account " + head,
                sfName: "Account." + og,
                enabled: custom ? true : false,
                sort: false,
                canSort: true,
                fullName: true,
                custom: false,
                account: true,
              });
            }
          }

          externalCols = [
            ...externalCols,
            // ...["Account.BillingState"]
            ...accountColumns.map((c) => {
              return c?.sfName;
            }),
          ];
          setHeaders([...headers, ...contactColumns, ...accountColumns]);
          setExternalColumns(externalCols);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  // useEffect(() => {
  //   if (initHeaders && salesforce) {
  //     let headMap = {};
  //     let final = [];
  //     for (let field of initHeaders) {
  //       if (field.enabled) {
  //         final.push(field);
  //         checkMap(field, headMap);
  //       }
  //     }
  //     setHeaders(final);

  //     setHeaderMap(headMap);
  //   }
  // }, []);
  const [sort, setSort] = useState({
    item: "email",
    descend: false,
  });

  function GetSalesforceData() {
    setDataLoading(true);
    const url = process.env.REACT_APP_SALESFORCE_QUERY;
    let params = {};
    let columns = [];
    if (headers) {
      forEach(headers, (h) => {
        if (h.enabled && !h?.isReaction) {
          columns.push(h?.sfName ? h?.sfName : h?.name);
        }
      });
      columns.push("Id");
    }

    if (searchString) {
      params = {
        queries: [{ column: "email", operator: 2, values: [searchString] }],
        columns: columns,
      };
    } else {
      params = {
        // queries: [],
        // columns: ["FirstName", "LastName", "Email", "Id", "Salutation"],
        queries: chosenFilters,
        columns: columns,
      };
      // debugger;
    }

    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        offset: currPage - 1,
        page_size: perPage,
        organization_id: organization.id,
      },
    };
    axios
      .post(url, params, config)
      .then((response) => {
        let found = [];
        for (let row of response?.data?.data) {
          let newrow = {
            ...row,
            account: row.Account,
            email: row.Email,
            firstName: row.FirstName,
            lastName: row.LastName,
            id: row?.Id ? row?.Id : shortId(),
          };
          delete newrow.LastName;
          delete newrow.FirstName;
          delete newrow.Email;
          delete newrow.Account;

          if (newrow?.account) {
            forEach(Object.keys(newrow?.account), (key) => {
              let newkey = key[0].toLowerCase() + key.slice(1);
              newrow["account " + newkey] = newrow?.account[key];
            });
          }
          delete newrow["account attributes"];
          // delete newrow["account name"]
          found.push(newrow);
        }
        setData(found);
        // let newselect = [];
        // for (let c of selected) {
        //   newselect.push(found.find((f) => f?.id === c?.id));
        // }
        // setSelected(newselect);
        // setExternalMax(response?.data?.num_records);
        setMaxData(response?.data?.num_records);
        setDataLoading(false);
      })
      .catch((error) => {
        console.error(error);

        setData([]);
      });
  }

  // useEffect(() => {
  //   if (page === 2) {
  //     setPage((page) => page + 1);
  //   }
  // }, [page]);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSkip, setPageSkip] = useState(0);
  const [perPage, setPerpage] = useState(40);
  const [chosenFilters, setChosenFilters] = useState([]);

  const [show, setShow] = useState(false);
  const [searchString, setSearchString] = useState("");

  function getTitle() {
    return `
    ${organization?.name}
     Contacts`;
  }

  return (
    <>
      {importType != "Sync" && (
        <div className={styles.topheader}>
          <FlexRow start>
            <img src={salesforce} height={40}></img>
            {importType === "Report" && (
              <i className={`bi-file-earmark-person ${styles.icon2}`}></i>
            )}
            {importType === "Select" && (
              <i className={`bi-person-plus ${styles.icon2}`}></i>
            )}
            {importType === "Filters" && (
              <i className={`bi-people ${styles.icon2}`}></i>
            )}
            Import contacts from Salesforce{" "}
            {audience && <> to "{audience?.name}"</>}
          </FlexRow>
        </div>
      )}
      {importType === "Sync" && (
        <div className={styles.topheader}>
          {" "}
          <FlexRow start>
            {" "}
            <img src={salesforce} height={40}></img>
            <i className={`bi-arrow-repeat ${styles.icon2}`}></i>
            Sync contacts between Salesforce and Reaction
            {page === 1 && " / Choose Columns"}
            {page === 2 && " / Choose Who To Sync"}
            {page === 3 && " / Final Preview"}
          </FlexRow>
          {/* <FlexRow start>
                <span className={`${!importType && styles.active}`}>
                  Step 1: Choose how to import
                </span>

                {importType && importType != "Sync" && (
                  <span className={`${importType && styles.active}`}>
                    Step 2: Choose who to import
                  </span>
                )}
                {importType && importType === "Sync" && (
                  <span className={`${importType && styles.active}`}>
                    Step 2: Select columns to sync
                  </span>
                )}
                {importType && importType != "Sync" && (
                  <span>Step 3: Choose where to import with settings</span>
                )}
                {importType && importType != "Sync" && (
                  <span>Step 4: Provide your column mapping</span>
                )}
              </FlexRow> */}
        </div>
      )}

      {/* {importType && (
            <div
              className={styles.header}
              style={{
                paddingBottom: "10px",
              }}
            >
              <span>
                {importType === "Select" &&
                  "Choose the contacts and columns you wish to import. Click on a row to select a contact for import."}
                {importType === "Filters" &&
                  "Choose the contacts and columns you wish to import. Choose filters on the left to filter down your groups, if no filters are applied all the contacts will be imported."}
                {importType === "Sync" &&
                  "Choose the columns you wish to be synced between Reaction and Salesforce. You can change this later as well."}
              </span>
            </div>
          )} */}
      {!importType && (
        <div
          className={styles.header}
          style={{
            padding: "40px 2em",
            paddingBottom: "10px",
            margin: "0px",
            justifyContent: "center",
          }}
        >
          How would you like to import your contacts from salesforce?
        </div>
      )}

      {page === 0 && (
        <div className={styles.createModal}>
          {/* <div
            className={styles.fromScratch}
            onClick={() => {
              setImportType("Sync");
              setPage(1);
            }}
          >
            <i className={`bi-arrow-repeat ${styles.icon}`}></i>
            <div>
              Sync Contacts
              <span>
                According to your Integration settings, sync together contacts
                in your Salesforce account, with contacts in Reaction.{" "}
              </span>
            </div>
          </div> */}
          <div
            className={styles.fromScratch}
            onClick={() => {
              setImportType("Filters");
              setPage(1);
            }}
          >
            <i className={`bi-people ${styles.icon}`}></i>
            <div>
              Create Groups with filters
              <span>Use filters to separate or join groups of contacts</span>
            </div>
          </div>
          <div
            className={styles.fromScratch}
            onClick={() => {
              setImportType("Select");
              setPage(1);
            }}
          >
            <i className={`bi-person-plus ${styles.icon}`}></i>
            <div>
              Search and select individuals
              <span>Choose and search your contacts one at a time</span>
            </div>
          </div>
          <div
            className={styles.fromScratch}
            onClick={() => {
              setImportType("Report");
              setPage(1);
            }}
          >
            <i className={`bi-file-earmark-person ${styles.icon}`}></i>
            <div>
              Import a Salesforce Report
              <span>
                Import the filters for a previously created report in Salesforce
              </span>
            </div>
          </div>
        </div>
      )}

      {/* {importing && (
        <div className={styles.header}>
          <div className={styles.goback} onClick={() => setImporting(false)}>
            <div className={styles.backArrow}>
              <i className="bi bi-arrow-left"></i>
            </div>
            Go Back
          </div>
          
          <span>
            When ready, click the "Submit" button to import the chosen contacts
          </span>
        </div>
      )} */}
      {!externalColumns && (
        <div
          className={styles.header}
          style={{
            padding: "0",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "80px",
            height: "100%",
          }}
        >
          <Loading></Loading>
          {sessionExpired && (
            <span>
              Your session has expired, rerouting you to Salesforce login...
            </span>
          )}
        </div>
      )}

      {importType && importType === "Report" && (
        <SalesReport
          organizationId={organization?.id}
          setChosenFilters={setChosenFilters}
          setImportType={setImportType}
        ></SalesReport>
      )}

      {importType &&
        importType === "Sync" &&
        page === 2 &&
        dataLoading === true && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              minHeight: "100vh",
              width: "100%",
              //   position: "absolute",
              //   top: "20%",
              //   left: "50%",
            }}
          >
            <div
              style={{
                // display: "flex",
                // justifyContent: "center",
                // // alignItems: "center",
                // minHeight: "100vh",
                // width: "100%",
                position: "absolute",
                top: "20%",
                left: "50%",
              }}
            >
              <Loading></Loading>
            </div>
          </div>
        )}
      {importType && page === 1 && !dataLoading && (
        <ColumnMapping
          // data={data}
          headers={headers}
          setHeaders={setHeaders}
          headerMap={headerMap}
          setHeaderMap={setHeaderMap}
          orgId={organization.id}
          importType={"Salesforce"}
          sync
          customFieldsMap={customFieldsMap}
          setCustomFieldsMap={setCustomFieldsMap}
        ></ColumnMapping>
      )}

      {/* {importType && importType === "Sync" && page === 3 && !dataLoading && (
        <ContactCleaner
          user={user}
          audienceId={""}
          salesforce
          salesforceType={importType}
          numImport={maxData}
          audience={{}}
          // toAudience={importFile === "audience"}
          selected={[]}
          // doSelected={doSelected && !importFile}
          doFilter
          setImporting={(val) => {
            // if (doSelected) {
            //   setDoSelected(val);
            // } else {
            //   setImportFile(val);
            // }
            // setExternalImporting(val);
          }}
          initHeaders={headers}
          initData={data}
          organizationId={organization.id}
          customFields={customFields}
          exportPage={page}
          setExportPage={setPage}
          salesHeadMap={headerMap}
        ></ContactCleaner>
      )} */}

      {externalColumns && importType && importType != "Report" && page >= 2 && (
        <div className={styles.contacttabelcontainer}>
          <ContactTable
            headers={headers}
            setHeaders={setHeaders}
            maxDataQuery={{
              isSuccess: true,
              isLoading: false,
              data: { count: 0 },
            }}
            maxDataQueryAccessor={"count"}
            currPage={pageNumber}
            setCurrPage={setPageNumber}
            maxItems={perPage}
            // maxData={getProjectCount}
            // onRowClick={() => nonting}
            sort={sort}
            setSort={setSort}
            pageSkip={pageSkip}
            setPageSkip={setPageSkip}
            perPage={perPage}
            setPerPage={setPerpage}
            searchString={searchString}
            setSearchString={setSearchString}
            // actionButtonText={"Create/Update Contacts"}
            tableTitle={getTitle()}
            searchPlaceholder={"search contacts..."}
            onClickActionButton={() => setShow(true)}
            hideActionButton={true}
            chosenFilters={chosenFilters}
            setChosenFilters={setChosenFilters}
            orgId={organization?.id}
            organization={organization}
            external
            user={user}
            externalColumns={externalColumns}
            setExternalImporting={setImporting}
            externalImporting={importing}
            audience={audience}
            audienceId={audience?.id}
            externalType={importType}
            setExternalType={setImportType}
            customFields={custom_fields}
            setCustomFields
            exportPage={page}
            setExportPage={setPage}
            salesHeadMap={headerMap}
          ></ContactTable>
        </div>
      )}

      {importType && page === 1 && (
        <div className={styles.footer}>
          <Button
            onClick={(e) => {
              if (page - 1 === 0) {
                setImportType(null);
              }
              if (page - 1 === 1) {
                setData([]);
              }
              setPage((page) => page - 1);
            }}
            shadow
            // disable={!file && !salesforce}
            link
          >
            Previous
          </Button>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              // onClick={(e) => {
              //   if (externalType === "Select") {
              //     setDoSelected(true);
              //   } else {
              //     setImportFile(audienceId ? "audience" : true);
              //   }
              // }}
              onClick={() => {
                // if (page + 1 === 2) {
                //   setData(GetSalesforceData());
                // }
                setPage((page) => page + 1);
              }}
              shadow
              blue
              // disable={externalType === "Select" && selected.length === 0}
            >
              {importType === "Sync" && page === 3
                ? "Start Sync Process"
                : page === 3
                ? "Start Import"
                : "Next"}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default ExternalImport;

function SalesReport({ organizationId, setChosenFilters, setImportType }) {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSkip, setPageSkip] = useState(0);
  const [perPage, setPerpage] = useState(40);
  const [searchString, setSearchString] = useState("contacts");
  const [sort, setSort] = useState({
    item: "email",
    descend: false,
  });
  const [data, setData] = useState([]);
  useEffect(() => {
    let url = "http://localhost:70/sf_reports";
    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
      params: {
        offset: pageNumber - 1,
        page_size: perPage,
        search_string: searchString,
        descend: sort?.descend,
      },
    };

    //contact columns
    axios
      .get(url, config)
      .then((response) => {
        console.log(response);
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 401) {
          setSessionExpired(true);
          setTimeout(() => {
            handleLoginSalesforce();
          }, 1000);
        }
      });
  }, [sort, perPage, pageNumber, searchString]);

  function handlerunreport(id) {
    let url = "http://localhost:70/sf_query_report";
    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
      params: {
        id: id,
        organizationId: organizationId,
      },
    };

    //contact columns
    axios
      .get(url, config)
      .then((response) => {
        console.log(response);
        setChosenFilters(response?.data);
        setImportType("Filters");
        // setData(response.data);
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 401) {
          setSessionExpired(true);
          setTimeout(() => {
            handleLoginSalesforce();
          }, 1000);
        }
      });
  }

  return (
    <div className={styles.reportpage}>
      <NavTextField
        value={searchString}
        setValue={setSearchString}
        placeholder={"Search Report..."}
      />

      {data?.map((report, i) => (
        <div
          key={i}
          className={styles.report}
          onClick={() => handlerunreport(report?.Id)}
        >
          {report?.Name}
        </div>
      ))}
    </div>
  );
}
