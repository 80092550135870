//External
import React, { useEffect, useState } from "react";

//Internal
// import Button from "components/Button/Button";
import { DesignSettings } from "./DesignSettings";
import styles from "./SurveyDesign.module.scss";
import companyLogo from "assets/images/reactionlogodark.png";
// import Question from "components/UXQuestions/Question";
import {
  useFetchProjectMaxPage,
  useUpdateProjectDesignSettingsGql,
} from "api/resources/projects/projects";
import { useParams } from "react-router-dom";
import Survey from "components/Survey/Survey";
import { Loading } from "components/Loading/Loading";
import {
  useFetchQuestionCount,
  useGetQuestionsMutationGql,
} from "api/resources/projects/questions";
// import { CustomButton } from "./Alignments";

function SurveyDesign({
  initSettings,
  initInstructions,
  status,
  initFinishedMsg,
  roles,
}) {
  const { id } = useParams();
  const firstSettings = {
    logoSource: companyLogo,
    logoAlign: "left",
    logoSize: "25pt",
    showProgBar: true,
    showProgQs: true,
    showPageProg: true,
    progBarColor: null,
    baseColor: "#edb57e",
    backgroundColor: "#8dabb2",
    colorBank: [],
    buttonColor: null,
    buttonStyle: "fill",
    firstPageInstructions: false,
    surveyInstructions: true,
    multipleChoiceBars: false,
  };

  const [settingsArray, setSettingsArray] = useState([initSettings]);
  const [currSettingInd, setCurrSettingInd] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [instructions, setInstructions] = useState(initInstructions);
  const [finishedMsg, setFinishedMsg] = useState(initFinishedMsg);
  const [currPage, setCurrPage] = useState(1);
  const [answerMap, setAnswerMap] = useState(new Map());
  const [show, setShow] = useState(false);
  const [savedStatus, setSavedStatus] = useState(false);

  const updateSettings = useUpdateProjectDesignSettingsGql(id);
  const fetchQuestionCount = useFetchQuestionCount(id);
  const fetchQuestions = useGetQuestionsMutationGql();
  const fetchMaxPages = useFetchProjectMaxPage(id);

  useEffect(() => {
    fetchQuestions.mutate({
      projectId: id,
      pageNumber: currPage,
    });
  }, [currPage]);
  useEffect(() => {
    fetchQuestions.mutate({
      projectId: id,
      pageNumber: currPage,
    });
  }, []);

  function handleOnPageClick() {
    if (status === "Open") {
      alert(
        "Survey can only be designed when its status is set to 'Closed' or 'Draft'"
      );
    }
  }

  function handleSettingsChange(newSettings) {
    let newArray = [...settingsArray, newSettings];
    setSettingsArray(newArray);
    setCurrSettingInd(settingsArray.length);
  }

  function handleUndo() {
    setCurrSettingInd(currSettingInd - 1);
  }

  function handleRedo() {
    setCurrSettingInd(currSettingInd + 1);
  }

  function handleSettingsSave() {
    console.log(settingsArray[currSettingInd]);
    let setting = settingsArray[currSettingInd];
    let jsonStr = JSON.stringify(setting);
    updateSettings.mutate(
      {
        updateProjectId: id,
        data: {
          designSettings: jsonStr,
          surveyInstruction: instructions,
          finishedMessage: finishedMsg,
        },
      },
      {
        onSuccess: (data) => {
          console.log("Return Value: ", data);
          //   setSavedStatus(
          //     <div>
          //       <i className="bi bi-check-lg"></i>Saved
          //     </div>
          //   );
          setSavedStatus(true);
          setTimeout(() => setSavedStatus(false), 3000);
        },
      }
    );
  }
  console.log(roles.roles.editSurveyDesign);

  function restoreDefault() {
    let newArray = [...settingsArray, firstSettings];
    setSettingsArray(newArray);
    setCurrSettingInd(settingsArray.length);
    // setSettings(firstSettings);
  }

  console.log(fetchQuestions);

  return (
    <>
      <div
        className={styles.pageContainer}
        style={
          settingsArray[currSettingInd]?.backgroundColor
            ? {
                backgroundColor: settingsArray[currSettingInd].backgroundColor,
              }
            : { backgroundColor: "#b5e1df" }
        }
      >
        {(fetchQuestionCount.isError ||
          fetchMaxPages.isError ||
          fetchQuestions.isError) && <div>Error...</div>}
        {(fetchQuestionCount.isLoading ||
          fetchMaxPages.isLoading ||
          fetchQuestions.isLoading) && (
          <div>
            <Loading></Loading>
          </div>
        )}
        {fetchQuestionCount.isSuccess &&
          fetchMaxPages.isSuccess &&
          fetchQuestions.isSuccess && (
            <Survey
              questions={fetchQuestions.data.QuestionByProjectPage.sort(
                (a, b) => (a.pageOrderIndex > b.pageOrderIndex ? 1 : -1)
              )}
              settings={settingsArray[currSettingInd]}
              numQuestions={fetchQuestionCount.data.countQuestions}
              currPage={currPage}
              maxPages={fetchMaxPages.data.project.numPages}
              setCurrPage={setCurrPage}
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              surveyInstructions={instructions}
              setInstructions={setInstructions}
              answerMap={answerMap}
              setAnswerMap={setAnswerMap}
              sectionsEditable
              finishedMsg={finishedMsg}
              setFinishedMsg={setFinishedMsg}
              returnToSurvey
            ></Survey>
          )}
        <div className={styles.settingsContainer}>
          {roles.roles.editSurveyDesign && (
            <DesignSettings
              settings={settingsArray[currSettingInd]}
              setSettings={handleSettingsChange}
              undoAction={handleUndo}
              redoAction={handleRedo}
              showRedo={currSettingInd < settingsArray.length - 1}
              showUndo={currSettingInd > 0}
              onSave={handleSettingsSave}
              restoreDefault={restoreDefault}
              setShowPreview={setShow}
              showPreview={show}
              setFinishedMsg={setFinishedMsg}
              finishedMsg={finishedMsg}
              savedStatus={savedStatus}
            ></DesignSettings>
          )}
        </div>
      </div>
      {show && fetchQuestionCount.isSuccess &&
          fetchMaxPages.isSuccess &&
          fetchQuestions.isSuccess && (
        <div
          className={styles.expandedPreview}
          style={{
            backgroundColor: settingsArray[currSettingInd].backgroundColor,
          }}
        >
          <i className="bi bi-x-lg" onClick={() => setShow(false)}></i>
          <Survey
            questions={fetchQuestions.data?.QuestionByProjectPage.sort((a, b) =>
              a.pageOrderIndex > b.pageOrderIndex ? 1 : -1
            )}
            settings={settingsArray[currSettingInd]}
            numQuestions={fetchQuestionCount.data.countQuestions}
            currPage={currPage}
            maxPages={fetchMaxPages.data.project.numPages}
            setCurrPage={setCurrPage}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
            surveyInstructions={instructions}
            setInstructions={setInstructions}
            answerMap={answerMap}
            setAnswerMap={setAnswerMap}
            status={status}
            finishedMsg={finishedMsg}
          ></Survey>
        </div>
      )}
    </>
  );
}

export default SurveyDesign;
