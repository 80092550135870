// External
import React, { useState, useEffect } from "react"; 
import styles from "./OrgAudiences.module.scss";

// Internal
import {
  useFetchAudienceCount,
  useGetAudienceAndFolders,
} from "api/resources/contacts/audiences";
import { useNavigate } from "react-router-dom";
import InteractiveAudienceTable from "components/tables/BasicTable/InteractiveAudienceTable.jsx";

export const OrgAudiences = ({ roles, user, organization }) => {
  const headers = [
    {
      id: 0,
      index: 0,
      name: "Audience",
      accessor: "name",
      enabled: true,
      sort: true,
      canSort: true,
      cell_style: null,
    },
    {
      id: 1,
      index: 1,
      name: "Members",
      accessor: "members",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },
    {
      id: 2,
      index: 2,
      name: "Modified",
      accessor: "updatedAt",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },
    {
      id: 3,
      index: 3,
      name: "Created",
      accessor: "createdAt",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },
  ];

  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageSort, setPageSort] = useState({
    item: "createdAt",
    descend: true,
  });
  const [sort, setSort] = useState({
    item: "custom",
    descend: true,
  });


  const [searchString, setSearchString] = useState("");
  const getAudienceCount = useFetchAudienceCount(
    searchString,
    pageSkip,
    perPage,
    pageSort
  );
  const searchAudience = useGetAudienceAndFolders("yeet");

  useEffect(() => {
    searchAudience.refetch();
    getAudienceCount.refetch();
  }, [pageNumber, pageSort, perPage]);

  

  let navigate = useNavigate();
  function handleChangePage(item) {
    if (item?.id) {
      navigate("/contacts/audiences/" + item.id);
    } else {
      navigate("/contacts/audiences/");
    }
  }

  return (
    <div className={styles.audiencePage}>
      <InteractiveAudienceTable
        headers={headers}
        queryData={searchAudience}
        queryDataAccessor={"audiences"}
        maxDataQuery={getAudienceCount}
        maxDataQueryAccessor={"count"}
        currPage={pageNumber}
        setCurrPage={setPageNumber}
        maxItems={perPage}
        onRowClick={(item) => handleChangePage(item)}
        sort={sort}
        setSort={setSort}
        setPageSkip={setPageSkip}
        perPage={perPage}
        setPerPage={setPerPage}
        searchString={searchString}
        setSearchString={setSearchString}
        actionButtonText={"Create Audience"}
        widgetType="audiences"
        searchPlaceholder={"Search Audiences..."}
        hideActionButton={!roles.canCreateEditOrgAudiences}
        roles={roles}
        user={user}
        organization={organization}
      ></InteractiveAudienceTable>
    </div>
  );
};
