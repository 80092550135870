import { useState, useEffect } from "react";
import styles from "../QuestionChart.module.scss";
import { useUpdateVisualization } from "api/resources/projects/visualizations";
import { Loading } from "components/Loading/Loading";
import {
  useGetStatChartProjects,
  useGetComparableStatChartProjects,
  useFetchStatChartData,
} from "api/resources/projects/projects";
import { useFetchSurveyTags } from "api/resources/organization/surveytags";
import Chart from "components/Charts/Chart";
import { forEach } from "assets/functions/ArrayFunctions";
import { useFetchColorPaletteById } from "api/resources/organization/colorpalettes";
import {
  getColorsFromGradient,
  getColorsFromPalette,
  getComparisonColors,
} from "assets/functions/ColorFunctions";
import {
  AtoZ,
  High2Low,
  LineType,
  Low2High,
  NoSort,
  ReverseSort,
  ZtoA,
  defaultColors,
} from "../QuestionChart";
import BackendStatDataTable from "../Tables/StatDataTable/BackendStatDataTable";
import { splitting } from "../Settings/SettingsAccordion";
import BackendStatDrillTable from "../Tables/StatDrillTable/BackendStatDrillTable";

export default function BackendStatChart({
  viz,
  setOutsideData,
  setUpOutsideDataCounter,
  setLabelInfo,
  newChart,
  thumbnail,
  custom_fields,
  update,
  height,
  reDraw,
  role,
  seeData,
  closeSeeData,
  filters,
  filterSubtitle,
  idAddOn,
  setVisibleItems,
  editing,
  visibleItems,
  active,
  setCurrViz,
  setShowDrill,
}) {
  const getProjects = useGetStatChartProjects(viz);
  const getComparableProjects = useGetComparableStatChartProjects(viz);
  const getSurveyTags = useFetchSurveyTags({
    tagIds: viz.tagIdsArray,
  });
  const colorPalette = useFetchColorPaletteById(
    viz.designSettings.paletteId ? viz.designSettings.paletteId : ""
  );

  return (
    <>
      {(getProjects.isLoading ||
        getComparableProjects.isLoading ||
        getSurveyTags.isLoading ||
        colorPalette.isLoading) && <Loading height={height}></Loading>}

      {getProjects.isSuccess &&
        getComparableProjects.isSuccess &&
        colorPalette.isSuccess &&
        getSurveyTags.isSuccess && (
          <StatChart
            viz={viz}
            projects={getProjects.data.survey}
            comparisonProjects={getComparableProjects.data.survey}
            setOutsideData={setOutsideData}
            setUpOutsideDataCounter={setUpOutsideDataCounter}
            setLabelInfo={setLabelInfo}
            newChart={newChart}
            custom_fields={custom_fields}
            thumbnail={thumbnail}
            update={update}
            height={height}
            reDraw={reDraw}
            role={role}
            seeData={seeData}
            closeSeeData={closeSeeData}
            idAddOn={idAddOn}
            filters={filters}
            filterSubtitle={filterSubtitle}
            setVisibleItems={setVisibleItems}
            editing={editing}
            visible={visibleItems}
            active={active}
            setCurrViz={setCurrViz}
            visibleItems={visibleItems}
            setShowDrill={setShowDrill}
            palette={colorPalette.data.palette}
            surveyTags={getSurveyTags.data.tags}
          />
        )}
    </>
  );
}

function StatChart({
  viz,
  projects,
  comparisonProjects,
  setOutsideData,
  setUpOutsideDataCounter,
  setLabelInfo,
  newChart,
  thumbnail,
  custom_fields,
  update,
  height,
  reDraw,
  role,
  seeData,
  closeSeeData,
  filters,
  filterSubtitle,
  idAddOn,
  setVisibleItems,
  editing,
  visibleItems,
  active,
  setCurrViz,
  setShowDrill,
  palette,
  surveyTags,
}) {
  const onSuccess = (statData) => {
    let chartData = statData.data.split
      ? compileSplitData(statData.data)
      : compileData(statData.data);

    if (viz.type === LineType) {
      return getLineData(chartData);
    }

    setData(chartData);
    setN(statData.data.N);
  };

  function getKey() {
    let key = "";
    key += projects.map((p) => p.id).toString();
    key += comparisonProjects.map((p) => p.id).toString();
    key += filters;
    key += viz.pivotString;

    const settings = viz.designSettings;
    key += settings.split;
    key += settings.showBars.map((b) => b.value).toString();

    if (settings.byPercent) key += settings.byPercentOfReceived ? "bpr" : "bp";
    key += settings.dataLabelValue.sigFigs;
    if (settings.showUndefined && (settings.split || viz.pivotString)) {
      key += "U";
      if (settings.undefinedLabel) {
        key += settings.undefinedLabel;
      }
    }
    if (settings.includeOverall) key += "io";
    if (settings.onlyLinks) key += "ol";
    if (settings.showNonParticipating) key += "sn";
    key += settings.answerType;
    if (comparisonProjects.length) {
      if (settings.chosenLabel) key += settings.chosenLabel;
      if (settings.comparisonLabel) key += settings.comparisonLabel;
    }

    return key;
  }

  const key = getKey();

  const fetchData = useFetchStatChartData(
    viz,
    projects.map((p) => {
      return { id: p.id, name: p.name, startedAt: p.startedAt };
    }),
    comparisonProjects.map((p) => {
      return { id: p.id, name: p.name, startedAt: p.startedAt };
    }),
    filters,
    key,
    onSuccess
  );

  const [data, setData] = useState(null);
  const [N, setN] = useState();

  const updateViz = useUpdateVisualization();

  const [drill, setDrill] = useState();
  const [chartData, setChartData] = useState(false);

  useEffect(() => {
    if (setUpOutsideDataCounter) {
      if (setOutsideData && data) {
        setOutsideData({ ...data });
      }
    }
  }, [setUpOutsideDataCounter]);

  function comparing() {
    return viz.designSettings.useComparison && comparisonProjects.length;
  }

  function sortData(labels, answerTallies, colors) {
    let labelTallyMap = [];
    forEach(labels, (label, ind) =>
      labelTallyMap.push({
        label: label,
        tally: answerTallies[ind],
        color: colors[ind],
        orig: ind,
        sorted: ind,
      })
    );

    if (
      viz.designSettings?.segLabelReplacements &&
      Object.keys(viz.designSettings.segLabelReplacements).length > 0
    ) {
      forEach(labelTallyMap, (l) => {
        if (l.orig in viz.designSettings?.segLabelReplacements) {
          l.label = viz.designSettings.segLabelReplacements[l.orig];
          l.origLabel = labels[l.orig]; // So you can know what it was originally
          labels[l.orig] = l.label;
        }
      });
    }

    if (viz.designSettings.sortData !== NoSort) {
      if (viz.designSettings.sortData === ReverseSort) {
        labelTallyMap.reverse();
      } else {
        labelTallyMap.sort((a, b) => {
          if (viz.designSettings.sortData === High2Low) {
            return b.tally - a.tally;
          }
          if (viz.designSettings.sortData === Low2High) {
            return a.tally - b.tally;
          }

          if (viz.designSettings.sortData === AtoZ) {
            if (typeof a.label === "number" && typeof b.label === "number") {
              return a.label - b.label;
            }
            if (typeof a.label === "string" && typeof b.label === "string") {
              if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
              }
              if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
              }
              return 0;
            }
            if (typeof a.label === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          if (viz.designSettings.sortData === ZtoA) {
            if (typeof a.label === "number" && typeof b.label === "number") {
              return b.label - a.label;
            }
            if (typeof a.label === "string" && typeof b.label === "string") {
              if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return 1;
              }
              if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return -1;
              }
              return 0;
            }
            if (typeof a.label === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
        });
      }

      forEach(labelTallyMap, (choice, ind) => {
        labels[ind] = choice.label;
        answerTallies[ind] = choice.tally;
        if (viz.designSettings.colors && !viz.designSettings?.afterSort) {
          colors[ind] = choice.color;
        } else {
          choice.color = colors[ind];
        }

        choice.sorted = ind; // For Chart Settings
      });
    }

    if (setLabelInfo) {
      let labelInfo = {
        segLabels: labelTallyMap,
        axisLabels: labelTallyMap,
      };
      setLabelInfo(labelInfo);
    }
  }

  function getDefaultColors(length) {
    let colors = [];
    let c = 0;
    for (let i = 0; i < length; i++) {
      colors[i] = defaultColors[c];
      c < defaultColors.length - 1 ? c++ : (c = 0);
    }
    return colors;
  }

  function getColors(num, data) {
    let compSimple = false;
    let colors = [];
    if (viz.designSettings.colors) {
      let c = 0;
      let copy = viz.designSettings.colors;
      for (let i = 0; i < num; i++) {
        colors[i] = copy[c];
        c < copy.length - 1 ? c++ : (c = 0);
      }
    } else if (viz.designSettings.gradient) {
      let gradient = viz.designSettings.gradient;
      colors = getColorsFromGradient(gradient.anchors, gradient.blended, num);
    } else if (palette) {
      if (comparing() && !(num % 2)) {
        if (viz.pivotString || viz.designSettings.split) {
          let half = num / 2;
          let firstSet = getColorsFromPalette(palette, half);
          colors = getComparisonColors(firstSet);
        } else {
          compSimple = true;
          let actual = data.datasets[0].data.length;
          let origSet = getColorsFromPalette(palette, actual);
          colors = getComparisonColors(origSet);
        }
      } else {
        colors = getColorsFromPalette(palette, num);
      }
    } else {
      colors = getDefaultColors(num);
    }
    return [colors, compSimple];
  }

  function compileData(statData) {
    let labels = statData.axisLabels;
    let tallies = statData.datasets[0].data;

    let [colors] = getColors(labels.length);

    //Survey Tag Colors Display
    if (viz.pivotString === "survey tag" && viz.designSettings?.useTagColor) {
      for (let i = 0; i < labels.length; i++) {
        let tag = surveyTags.find((t) => t.label === labels[i]);
        if (tag?.displayColor) {
          colors[i] = tag.displayColor;
        }
      }
    }

    sortData(labels, tallies, colors);

    let data = {
      labels: labels,
      datasets: [
        {
          data: tallies,
          backgroundColor: colors,
          borderRadius: viz.designSettings.borderRadius
            ? viz.designSettings.borderRadius
            : 0,
          borderSkipped: viz.designSettings.borderSkipped,
        },
      ],
    };

    return data;
  }

  function sortSplitData(data) {
    let segLabels = [];
    forEach(data.datasets, (dataset, i) =>
      segLabels.push({
        label: dataset.label,
        orig: i,
        sorted: i,
        color: dataset.backgroundColor[0],
      })
    );

    // add origLabel
    for (let l of segLabels) {
      if (
        viz.designSettings?.segLabelReplacements &&
        l.orig in viz.designSettings?.segLabelReplacements
      ) {
        l.label = viz.designSettings.segLabelReplacements[l.orig];
        l.origLabel = data.datasets[l.orig].label; // So you can know what it was originally
        data.datasets[l.orig].label = l.label; // Actually change it
      }
    }

    let axisLabels = [];
    if (data.labels) {
      forEach(data.labels, (label, i) =>
        axisLabels.push({
          label: label,
          orig: i,
          sorted: i,
        })
      );

      for (let l of axisLabels) {
        if (
          viz.designSettings?.axisLabelReplacements &&
          l.orig in viz.designSettings?.axisLabelReplacements
        ) {
          l.label = viz.designSettings.axisLabelReplacements[l.orig];
          l.origLabel = data.labels[l.orig]; // So you can know what it was originally
          data.labels[l.orig] = l.label; // Actually change it
        }
      }
    }
    if (setLabelInfo) {
      let labelsInfo = {
        segLabels: segLabels,
        axisLabels: axisLabels,
      };
      setLabelInfo(labelsInfo);
    }

    let labelMap = {};
    for (let i = 0; i < data.labels.length; i++) {
      labelMap[data.labels[i]] = i;
    }

    if (viz.designSettings.sortData !== NoSort) {
      if (viz.designSettings.sortData === ReverseSort) {
        data.labels.reverse();
      } else {
        data.labels.sort((a, b) => {
          if (viz.designSettings.sortData === AtoZ) {
            if (typeof a === "number" && typeof b === "number") {
              return a - b;
            }
            if (typeof a === "string" && typeof b === "string") {
              if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
              }
              return 0;
            }
            if (typeof a === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          if (viz.designSettings.sortData === ZtoA) {
            if (typeof a === "number" && typeof b === "number") {
              return b - a;
            }
            if (typeof a === "string" && typeof b === "string") {
              if (a.toLowerCase() < b.toLowerCase()) {
                return 1;
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return -1;
              }
              return 0;
            }
            if (typeof a === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          return 0;
        });
      }

      // axisLabels.forEach((one, i) => (one.sorted = i));

      let order = data.labels.map((label) => labelMap[label]);

      for (let set of data.datasets) {
        let copy = [];
        forEach(order, (num, ind) => {
          copy[ind] = set.data[num];
        });
        set.data = copy;
      }
      // data.labels = axisLabels.map(l => l.label);
    }
  }

  function bindColorsOnSplitData(data) {
    let [colors, compMany] = getColors(data.datasets.length, data);

    //Survey Tag Colors Display
    if (
      viz.designSettings.split === "survey tag" &&
      viz.designSettings?.useTagColor
    ) {
      for (let i = 0; i < data.datasets.length; i++) {
        let tag = surveyTags.find((t) => t.label === data.datasets[i].label);
        if (tag?.displayColor) {
          colors[i] = tag.displayColor;
        }
      }
    }

    if (compMany) {
      let normal = [];
      let comp = [];
      for (let i = 0; i < colors.length; i += 2) {
        normal.push(colors[i]);
        comp.push(colors[i + 1]);
      }
      data.datasets[0].backgroundColor = normal;
      data.datasets[1].backgroundColor = comp;
    } else {
      for (let i = 0; i < data.datasets.length; i++) {
        data.datasets[i].backgroundColor = [colors[i]];
        data.datasets[i].borderWidth = 0;
      }
    }
  }

  function compileSplitData(statData) {
    let data = {
      labels: statData.axisLabels,
      datasets: statData.datasets,
      stacked: viz.designSettings.stacked,
    };

    bindColorsOnSplitData(data);
    sortSplitData(data);
    return data;
  }

  function getLineData(data) {
    let settings = viz.designSettings;
    //so updates to the settings gets recognized by the useEffect in Chart.jsx
    for (let i = 0; i < data.datasets.length; i++) {
      data.datasets[i].pointRadius = settings.pointRadius;
      data.datasets[i].borderWidth = settings.lineGraphWidth;
      data.datasets[i].pointBorderWidth = 0;
      data.datasets[i].borderRadius = settings.borderRadius
        ? settings.borderRadius
        : 0;
      data.datasets[i].borderSkipped = settings.borderSkipped;
    }

    if (data.datasets.length > 1 || data.datasets[0]?.label) {
      // split
      for (let i = 0; i < data.datasets.length; i++) {
        data.datasets[i].borderColor = data.datasets[i].backgroundColor[0];
      }
    } else {
      data.datasets[0].borderColor = settings.lineGraphColor;
      if (settings.hasUniformPointColor) {
        for (let i = 0; i < data.datasets[0].data.length; i++) {
          data.datasets[0].backgroundColor[i] = settings.uniformPointColor;
        }
      }
    }

    return data;
  }

  function saveData(data) {
    updateViz.mutate({
      id: viz.id,
      data: { data: typeof data != "string" ? JSON.stringify(data) : data },
    });
  }

  function closeDrill() {
    setDrill(null);
    setShowDrill(false);
    setChartData(null);
  }

  function onSegClick(segment, dataset, dataIndex, datasetIndex) {
    if (editing) {
      setVisibleItems("ChartSegments");
      if (!active) {
        setCurrViz(viz);
      }
      return;
    }

    if (!projects.length || !role.canSeeContactInfo) {
      // is a new chart with no data
      return;
    }

    if (
      viz.designSettings?.segLabelReplacements &&
      Object.keys(viz.designSettings.segLabelReplacements).length > 0
    ) {
      let replacement = -1;
      let lookFor = splitting(viz) ? dataset : segment;
      for (let one in viz.designSettings.segLabelReplacements) {
        if (viz.designSettings.segLabelReplacements[one] === lookFor) {
          replacement = parseInt(one);
        }
      }
      if (replacement > -1) {
        // set it up to get the data normally, without sorting or replacements
        let copy = viz.designSettings.segLabelReplacements;
        viz.designSettings.segLabelReplacements = {};
        let sort = viz.designSettings.sortData;
        viz.designSettings.sortData = NoSort;

        // Replace sort and replacements
        viz.designSettings.segLabelReplacements = copy;
        viz.designSettings.sortData = sort;

        if (splitting(viz)) {
          // Splitting: dataset is the replacement - the split
          dataset = data.datasets[replacement].label;
        } else {
          // Pivoting: segment is the replacement - the pivot
          segment = data.labels[replacement];
        }
      }
    }

    if (
      viz.designSettings?.axisLabelReplacements &&
      Object.keys(viz.designSettings.axisLabelReplacements).length > 0
    ) {
      let replace = false;
      for (let one in viz.designSettings.axisLabelReplacements) {
        if (dataIndex === parseInt(one)) {
          replace = true;
        }
      }
      if (replace) {
        // set it up to get the data normally, without sorting or replacements
        let copy = viz.designSettings.axisLabelReplacements;
        viz.designSettings.axisLabelReplacements = {};
        let sort = viz.designSettings.sortData;
        viz.designSettings.sortData = NoSort;

        // Replace sort and replacements
        viz.designSettings.axisLabelReplacements = copy;
        viz.designSettings.sortData = sort;

        segment = data.labels[dataIndex];
      }
    }

    let setup = {
      segment: segment,
      dataset: dataset,
      dataIndex: dataIndex,
      datasetIndex: datasetIndex,
    };

    setDrill(setup);
    setShowDrill(true);
  }

  function getTitle() {
    if (!viz.designSettings.hasTitle) {
      return "";
    }
    if (newChart && !projects.length) {
      return "Sample Data";
    }

    return viz.title;
  }

  const fakeData = {
    labels: ["", "", ""],
    datasets: [
      {
        data: [125, 55, 82],
        backgroundColor: ["#15bcc7", "#dbdbdb", "#ed9146"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <>
      {!drill && !seeData && (
        <>
          <div
            className={styles.chartWithTitle}
            id={viz.id + idAddOn}
            style={thumbnail ? { gap: "0" } : undefined}
          >
            {viz.designSettings.hasTitle && (
              <div
                className={styles.titleContainer}
                id={"title for " + viz.id + idAddOn}
                style={{
                  minHeight: thumbnail ? "25px" : "",
                  alignItems: viz.designSettings.titleAlignment,
                  backgroundColor: viz.designSettings.titleBackgroundColor,
                  borderRadius: viz.designSettings.titleBorderRadius,
                  paddingTop: viz.designSettings.paddingTopTitle,
                  paddingBottom: viz.designSettings.paddingBottomTitle,
                  paddingLeft: viz.designSettings.paddingLeftTitle,
                  paddingRight: viz.designSettings.paddingRightTitle,
                }}
              >
                <div
                  className={styles.title}
                  style={{
                    color: viz.designSettings.titleColor,
                    fontSize: viz.designSettings.valueTitleSize,
                  }}
                >
                  {getTitle()}
                </div>
                {filterSubtitle &&
                  viz.designSettings.hasSubtitle &&
                  projects.length > 0 && (
                    <div className={styles.subtitle}>{filterSubtitle}</div>
                  )}
              </div>
            )}

            {/* {!data && <div className={styles.noData}>No Data</div>} */}

            {fetchData.isLoading || !data ? (
              <>
                <Loading />

                {/* {!data && newChart && (
                  <Chart
                    data={fakeData}
                    onSegClick={() => {}}
                    viz={viz}
                    thumbnail={thumbnail}
                    idAddOn={idAddOn}
                    reDraw={reDraw}
                    update={update}
                    saveData={() => {}}
                    setVisibleItems={setVisibleItems}
                    editing={editing}
                    visible={visibleItems}
                    active={active}
                    setCurrViz={setCurrViz}
                    visibleItems={visibleItems}
                  />
                )} */}
              </>
            ) : (
              <>
                {data && (
                  <>
                    <Chart
                      data={data}
                      onSegClick={onSegClick}
                      viz={viz}
                      thumbnail={thumbnail}
                      idAddOn={idAddOn}
                      reDraw={reDraw}
                      update={update}
                      saveData={saveData}
                      setVisibleItems={setVisibleItems}
                      editing={editing}
                      visible={visibleItems}
                      active={active}
                      setCurrViz={setCurrViz}
                      visibleItems={visibleItems}
                    />
                    {viz.designSettings?.showN && (
                      <div
                        className={styles.answerCount}
                        style={thumbnail ? { fontSize: ".5em" } : undefined}
                      >
                        {viz.designSettings?.NLabel
                          ? viz.designSettings?.NLabel
                          : "N"}{" "}
                        = {N}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}

      {drill && (
        <BackendStatDrillTable
          projects={projects}
          comparisonProjects={comparisonProjects}
          viz={viz}
          inEdit={editing}
          drill={drill}
          onClose={closeDrill}
          filters={filters}
          toggleSpreadsheet
        />
      )}
      {seeData && (
        <BackendStatDataTable
          viz={viz}
          filters={filters}
          custom_fields={custom_fields}
          projects={projects}
          onClose={closeSeeData}
          chartData={chartData ? data : ""}
          setChartData={setChartData}
          toggleSpreadsheet
        />
      )}
    </>
  );
}
