import React, { useEffect, useState } from "react";
import largelogo from "assets/images/reactionlogodark.png";
import smalllogo from "assets/images/circlelogo.png";
import styles from "./SideMenu.module.scss";
import MenuItem from "./MenuItem";
import { useGetCurrentUser } from "api/resources/organization/users";
import { useLogoutRequest } from "api/resources/authentication/logout";
import UserProfile from "../../Popout/UserProfile";
import { useNavigate } from "react-router-dom";
import { useFetchRole } from "api/resources/organization/roles";
import { Loading } from "components/Loading/Loading";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import SwitchOrg, { AddOrg } from "pages/organization/SwitchOrg";
import {
  useFetchOrganizationSideMenu,
  useGetOrgLogo,
} from "api/resources/organization/organization";
import ImageEditor from "components/Image Editor/ImageEditor";
import Icon from "components/Icon/Icon";
import Privacy from "pages/Policies/privacy";
import FlexRow from "components/layouts/FlexRow/FlexRow";

/**
 * @author Nate Hanson
 * @function SideMenu
 **/
/**
 * A sideMenu that is designed to be displayed in every page no matter what. It is collapsable
 * @param user The user that is signed in
 * @param roles the roles that pertain to the user
 * @param organization the organization that the user is signed into
 * @param beforeNavAway if true run a fuction to see if should save before the page is left
 * @param setBeforeNavAway setter function for beforeNavAway
 *
 * @returns {React.ReactElement} a SideMenu component
 */

export const SideMenu = ({
  user,
  roles,
  organization,
  beforeNavAway,
  setBeforeNavAway,
  collapse,
  collapsed,
}) => {
  const [switchOrg, setSwitchOrg] = useState(false);
  const [showAddOrg, setShowAddOrg] = useState(false);
  const [mobileShow, setMobileShow] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const getCurrUser = useGetCurrentUser();
  const getCurrRole = useFetchRole();
  const MyOrg = useFetchOrganizationSideMenu(organization?.id);

  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  let [width, setWidth] = useState(getWidth());

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      let newWidth = getWidth();
      if (newWidth < 560) {
        collapse("mobile");
        localStorage.setItem("sidemenustate", "mobile");
      } else if (newWidth > 560 && newWidth < 1024) {
        collapse("collapsed");
        localStorage.setItem("sidemenustate", "collapsed");
      } else if (newWidth > 1024) {
        collapse("expanded");
        localStorage.setItem("sidemenustate", "expanded");
      }
      setWidth(newWidth);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const url = window.location.pathname.split("/").pop();

  useEffect(() => {
    if (!url) {
      setActiveItem(0);
    } else {
      let id = localStorage.getItem("activepage");
      setActiveItem(id);
    }
  }, [url]);

  const pages = [
    roles?.canViewDashboardTab
      ? {
          id: 0,
          name: "Dashboard",
          to: "/",
          iconClassName: "bi bi-speedometer2",
          onClick: () => handleMenuItemClick(0),
        }
      : "",
    roles?.canViewSurveysTab
      ? {
          id: 1,
          name: "Projects",
          to: `/surveys`,
          iconClassName: "bi bi-card-list",
          onClick: () => handleMenuItemClick(1),
          // subMenus: [
          //   { name: "Delivery", to: "/projects/courses" },
          //   { name: "Survey Build", to: "/projects/videos" },
          //   { name: "Results", to: "/projects/videos" },
          // ],
        }
      : "",
    roles?.canViewAnalyzeTab
      ? {
          id: 2,
          name: "Analyze",
          to: `/reports`,
          iconClassName: "bi bi-clipboard-data",
          onClick: () => handleMenuItemClick(2),
        }
      : "",
    // roles?.canViewContactsTab
    //   ? {
    //       id: 3,
    //       name: "Contacts",
    //       to: `/contacts`,
    //       iconClassName: "bi bi-person",
    //       onClick: () => handleMenuItemClick(3),
    //     }
    //   : "",
    roles?.canViewContactsTab
      ? {
          id: 4,
          name: "Contacts",
          to: `/contacts`,
          iconClassName: "bi bi-people",
          onClick: () => handleMenuItemClick(4),
        }
      : "",
  ];

  const userItems = [
    {
      id: 5,
      name: "Notifications",
      to: "/notifications",
      iconClassName: "bi bi-bell",
      onClick: () => handleMenuItemClick(7),
    },
    // {
    //   id: 6,
    //   name: "Messages",
    //   to: "/messages",
    //   iconClassName: "bi-chat",
    //   onClick: () => handleMenuItemClick(6),
    // },
    {
      id: 7,
      name: "My Account",
      to: "/account",
      iconClassName: "bi-person-circle",
      onClick: () => handleMenuItemClick(7),
    },
  ];

  const orgItems = [
    roles?.canViewOrgTab
      ? {
          id: 8,
          name: "Organization Settings",
          to: `/organization/` + user.organizationId,
          iconClassName: "bi bi-building-gear",
          onClick: () => handleMenuItemClick(5),
        }
      : "",
    // roles?.canViewOrgTab
    //   ? {
    //       id: 9,
    //       name: "Sub-Organizations",
    //       to: `/organization/` + user.organizationId,
    //       iconClassName: "bi bi-buildings",
    //       onClick: () => handleMenuItemClick(5),
    //     }
    //   : "",
    roles?.canViewOrgTab && roles?.canViewOrgUsers
      ? {
          id: 10,
          name: "Users",
          to: `/organization/` + user.organizationId + "/users",
          iconClassName: "bi-person",
          onClick: () => handleMenuItemClick(5),
        }
      : "",
    roles?.canViewOrgTab && roles?.canViewOrgRoles
      ? {
          id: 11,
          name: "User Permissions",
          to: `/organization/` + user.organizationId + "/roles",
          iconClassName: "bi-person-lock",
          onClick: () => handleMenuItemClick(5),
        }
      : "",
    {
      id: 12,
      name: "Running Jobs",
      to: "/tasks",
      iconClassName: "bi-boxes",
      onClick: () => handleMenuItemClick(6),
    },
    {
      id: 13,
      name: "Finished Jobs",
      to: "/tasks-finished",
      iconClassName: "bi-list-check",
      onClick: () => handleMenuItemClick(6),
    },
    user?.organization?.length > 1
      ? {
          id: 14,
          name: "Switch/Join Organization",
          // to: `/organization/` + user.organizationId,
          iconClassName: "bi-arrow-repeat",
          onClick: () => handleMenuItemClick(12),
        }
      : "",
  ];

  const quicklinks = [
    {
      id: 15,
      name: "Support",
      to: "/support",
      iconClassName: "bi-question-circle",
      onClick: () => handleMenuItemClick(6),
    },
  ];

  const adminlinks = [
    // {
    //   id: 16,
    //   name: "Contact Database",
    //   to: "/contact-database",
    //   iconClassName: "bi-person-rolodex",
    //   onClick: () => handleMenuItemClick(16),
    // },
    {
      id: 17,
      name: "Organizations",
      to: "/organizations",
      iconClassName: "bi-buildings",
      onClick: () => handleMenuItemClick(16),
    },
    // {
    //   id: 18,
    //   name: "Support",
    //   to: "/give-support",
    //   iconClassName: "bi-person-raised-hand",
    //   onClick: () => handleMenuItemClick(16),
    // },
  ];

  const [activeItem, setActiveItem] = useState(
    localStorage.getItem("activepage")
  );
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  const [showingUserProf, setShowingUserProf] = useState(false);

  const logoutRequest = useLogoutRequest();

  const [display, setDisplay] = useState();

  function handleMenuItemClick(id, path) {
    if (beforeNavAway) {
      let component = beforeNavAway.getDisplay(() => {
        displayFor(id);
        routeChange(path);
        setDisplay(undefined);
        setBeforeNavAway(undefined);
      });
      setDisplay(component);
    } else {
      displayFor(id);
      routeChange(path);
    }
  }

  function displayFor(id) {
    localStorage.setItem("activepage", id);
    localStorage.removeItem("activeprojecttab");
    localStorage.removeItem("activecontactstab");
    localStorage.removeItem("activeorgtab");
    localStorage.removeItem("activeaccounttab");
    localStorage.removeItem("activeresultstab");
    localStorage.removeItem("activeanalysistab");
    localStorage.removeItem("activesurveystab");
    localStorage.removeItem("reportspageitem");
    localStorage.removeItem("activeaudiencetab");
    localStorage.removeItem("contactspage");
    window.localStorage.removeItem("page1");
    window.localStorage.removeItem("page2");
    window.localStorage.removeItem("page3");
    window.localStorage.removeItem("page");

    if (id === 7) {
      localStorage.setItem("activeaccounttab", 1);
    }
    if (id === 14) {
      setSwitchOrg(true);
      return 0;
    }
    if (collapsed === "mobile") {
      setMobileShow(false);
    }
    setActiveItem(id);
  }

  function handleLogout() {
    logoutRequest.mutate(
      {},
      {
        onSuccess: () => {
          routeChange("/login");
          localStorage.removeItem("reaction_token");
          localStorage.removeItem("sidemenustate");
          localStorage.removeItem("activepage");
          localStorage.removeItem("activeorg");
          localStorage.removeItem("activedelivertab");
          localStorage.removeItem("ReportPage");
          localStorage.removeItem("contactspage");
          window.location.reload();
        },
      }
    );
  }

  function handleToggleExpand() {
    if (collapsed === "collapsed" && width > 1024) {
      localStorage.setItem("sidemenustate", "expanded");
      collapse("expanded");
    } else if (collapsed === "expanded") {
      localStorage.setItem("sidemenustate", "collapsed");
      collapse("collapsed");
    }
  }

  function onGoToAccount() {
    handleMenuItemClick(pages.length);
    localStorage.setItem("activeaccounttab", 0);
    routeChange("/account");
  }

  return (
    <>
      {collapsed === "mobile" && !mobileShow && (
        <div
          className={styles.mobileShowMenu}
          onClick={() => setMobileShow(true)}
        >
          <img
            src={require("../../../assets/images/circlelogo.png")}
            style={{
              width: "1.8rem",
              height: "1.8rem",
            }}
          ></img>
        </div>
      )}
      {((collapsed === "mobile" && mobileShow) || collapsed != "mobile") && (
        <div
          className={`${styles.sideMenu} ${
            collapsed === "collapsed" ? styles.collapsed : ""
          } ${collapsed === "expanded" ? styles.expanded : ""} ${
            collapsed === "mobile" ? styles.mobilecover : ""
          }`}
          style={{ zIndex: collapsed === "mobile" ? "140" : "" }}
        >
          <div
            className={`${
              collapsed != "mobile" ? styles.topMenu : styles.topMenuRow
            } 
        ${collapsed === "collapsed" ? styles.collapsed : ""} 
        ${collapsed === "expanded" ? styles.expanded : ""} 
        ${collapsed === "mobile" ? styles.mobile : ""}`}
          >
            {getCurrUser.isSuccess && (
              <OrgLogo
                collapsed={collapsed != "collapsed" ? false : true}
                width={width}
                orgid={getCurrUser.data.me?.organizationId}
                organization={organization}
                logout={handleLogout}
                handleToggleExpand={handleToggleExpand}
              ></OrgLogo>
            )}
            {collapsed != "collapsed" && MyOrg.isSuccess && (
              <div
                className={styles.section_label7}
                style={{ width: "100%", height: "fit-content" }}
              >
                {" "}
                Signed into {MyOrg.data?.organization?.name}
              </div>
            )}
            {/* {collapsed === "expanded" && (
                <i className="bi-caret-left-fill"></i>
              )}
              {collapsed === "collapsed" && (
                <i className="bi-caret-right-fill"></i>
              )}
              <div className={styles.logo_name}>{organization.name}</div> */}
            {collapsed === "mobile" && (
              <i
                className={`bi-x-lg ${styles.closeMobile}`}
                onClick={(e) => {
                  e.stopPropagation();
                  setMobileShow(false);
                }}
                style={{ zIndex: "140" }}
              ></i>
            )}

            {getCurrUser.isSuccess && getCurrRole.isSuccess && (
              <>
                <div className={styles.section_label7}>
                  {collapsed === "collapsed" ? " " : "General"}
                </div>
                {pages.map(
                  (menuItem, index) =>
                    menuItem != "" && (
                      <MenuItem
                        key={index}
                        menuItem={menuItem}
                        name={menuItem.name}
                        to={menuItem.to}
                        subMenus={menuItem.subMenus || []}
                        iconClassName={menuItem.iconClassName}
                        active={activeItem == menuItem.id}
                        onClick={handleMenuItemClick}
                        collapsed={collapsed != "collapsed" ? false : true}
                      />
                    )
                )}
              </>
            )}

            <div className={styles.section_label7}>
              {collapsed === "collapsed" ? " " : "Organization"}
            </div>
            {orgItems.map(
              (menuItem, index) =>
                menuItem != "" && (
                  <MenuItem
                    key={index}
                    menuItem={menuItem}
                    name={menuItem.name}
                    to={menuItem.to}
                    subMenus={menuItem.subMenus || []}
                    iconClassName={menuItem.iconClassName}
                    active={activeItem == menuItem.id}
                    onClick={handleMenuItemClick}
                    collapsed={collapsed != "collapsed" ? false : true}
                  />
                )
            )}
            <div className={styles.section_label7}>
              {collapsed === "collapsed" ? " " : "Personal"}
            </div>
            {userItems.map((menuItem, index) => (
              <MenuItem
                key={index}
                menuItem={menuItem}
                name={menuItem.name}
                to={menuItem.to}
                subMenus={menuItem.subMenus || []}
                iconClassName={menuItem.iconClassName}
                active={activeItem == menuItem.id}
                onClick={handleMenuItemClick}
                collapsed={collapsed != "collapsed" ? false : true}
              />
            ))}
            {/* {!user.isSuperAdmin && (
              <>
                {" "}
                <div className={styles.section_label7}>
                  {collapsed === "collapsed" ? " " : "Quick Links"}
                </div>
                {quicklinks.map(
                  (menuItem, index) =>
                    menuItem != "" && (
                      <MenuItem
                        key={index}
                        menuItem={menuItem}
                        name={menuItem.name}
                        to={menuItem.to}
                        subMenus={menuItem.subMenus || []}
                        iconClassName={menuItem.iconClassName}
                        active={activeItem == menuItem.id}
                        onClick={handleMenuItemClick}
                        collapsed={collapsed != "collapsed" ? false : true}
                      />
                    )
                )}
              </>
            )} */}

            {user.isSuperAdmin && (
              <>
                {" "}
                <div className={styles.section_label7}>
                  {collapsed === "collapsed" ? " " : "Reaction Admin"}
                </div>
                {adminlinks.map(
                  (menuItem, index) =>
                    menuItem != "" && (
                      <MenuItem
                        key={index}
                        menuItem={menuItem}
                        name={menuItem.name}
                        to={menuItem.to}
                        subMenus={menuItem.subMenus || []}
                        iconClassName={menuItem.iconClassName}
                        active={activeItem == menuItem.id}
                        onClick={handleMenuItemClick}
                        collapsed={collapsed != "collapsed" ? false : true}
                      />
                    )
                )}
              </>
            )}
            {/* {getCurrUser.isSuccess &&
            getCurrUser.data.me?.organization?.length > 1 && (
              <div
                className={styles.switchOrg}
                onClick={() => setSwitchOrg(true)}
              >
                {collapsed === "expanded" && "Switch Organization"}
                {collapsed === "collapsed" && (
                  <div style={{ fontSize: "13px" }}>
                    <i className="bi-arrow-clockwise"></i>
                  </div>
                )}
                {collapsed === "mobile" && (
                  <div style={{ fontSize: "13px" }}>
                    <i className="bi-arrow-clockwise"></i>
                  </div>
                )}
              </div>
            )} */}
          </div>

          <div
            className={`${
              collapsed != "mobile" ? styles.topMenu : styles.topMenuRow
            } 
        ${collapsed === "collapsed" ? styles.collapsed : ""} 
        ${collapsed === "expanded" ? styles.expanded : ""} 
        ${collapsed === "mobile" ? styles.mobile : ""}`}
            style={{ overflow: "hidden", zIndex: 100 }}
          >
            {getCurrUser.isLoading && <Loading height="50px" />}
            {getCurrUser.isError && <div>Error...</div>}
            {getCurrUser.isSuccess && (
              <>
                {showingUserProf && (
                  <UserProfile
                    currUser={getCurrUser}
                    image={getCurrUser.data.me?.image}
                    logout={handleLogout}
                    setShowingUserProf={setShowingUserProf}
                    onGoToAccount={onGoToAccount}
                  />
                )}
                <div
                  className={`${styles.userContainer} ${
                    collapsed === "collapsed" ? styles.closedUser : ""
                  } ${collapsed != "collapsed" ? styles.openUser : ""} ${
                    showingUserProf ? styles.active : ""
                  }`}
                  onClick={() => setShowingUserProf(!showingUserProf)}
                  id="userInfoBtn"
                >
                  <div>
                    <ImageEditor
                      src={getCurrUser.data.me?.image?.imageURL}
                      isUser
                      canEdit={false}
                      position={
                        getCurrUser.data.me?.image?.position
                          ? JSON.parse(getCurrUser.data.me?.image?.position)
                          : { x: 0.5, y: 0.5 }
                      }
                      image={getCurrUser.data.me?.image}
                      height={40}
                      width={40}
                    ></ImageEditor>
                  </div>

                  {collapsed != "collapsed" && (
                    <div className={styles.userInfo}>
                      <span>
                        {getCurrUser.data?.me?.firstName}{" "}
                        {getCurrUser.data?.me?.lastName}
                      </span>
                    </div>
                  )}
                </div>
              </>
            )}

            <div
              className={`${styles.logo} ${
                !(collapsed === "collapsed") ? styles.largeLogo : ""
              } ${collapsed === "collapsed" ? styles.smallLogo : ""}`}
              onClick={handleToggleExpand}
            >
              {!(collapsed === "collapsed") && (
                <img
                  src={require("../../../assets/images/reactionlogonew.png")}
                  style={{
                    width: "fit-content",
                    maxWidth: "100px",
                    // height: collapsed === "collapsed" ? "3rem" : "1.3rem",
                  }}
                ></img>
              )}

              <div className={styles.logo_name}>
                <a>© 2024</a>
              </div>
            </div>
            <FlexRow><div onClick={() => setShowPrivacy(true)} className={styles.link} style={{fontSize: ".8rem"}}>Privacy</div></FlexRow>
            {/* {collapsed != "mobile" && (
            <HorizontalBar
              height="2px"
              width="100%"
              style={{ margin: ".5rem 0em" }}
            ></HorizontalBar>
          )}
          {collapsed != "mobile" && (
            <div className={styles.poweredBy}>
              {collapsed === "expanded" ? <span>Powered by </span> : ""}
              <img
                src={collapsed === "expanded" ? largelogo : smalllogo}
                alt="ReactionData"
                className={styles.image}
                style={{ width: collapsed === "expanded" ? "" : "40px" }}
              />{" "}
            </div>
          )} */}
          </div>
        </div>
      )}
      <ReactModal
        show={showPrivacy}
        onClose={() => setShowPrivacy(false)}
        modalStyle={{
          border: "1rem",
          width: "1200px",
          height: "95%"
        }}
        dark
      >
        <Privacy></Privacy>
      </ReactModal>
      <ReactModal
        show={switchOrg}
        onClose={() => setSwitchOrg(false)}
        modalStyle={{
          zIndex: "130",
          overflow: "visible",
          height: "100%",
          maxWidth: collapsed === "mobile" ? "100%" : "",
          width: "400px",
        }}
        rightStyle
        dark
      >
        <SwitchOrg
          show={switchOrg}
          userRoles={getCurrRole.data.role}
          currentUser={getCurrUser.data.me}
          setShow={setSwitchOrg}
          setShowAddOrg={setShowAddOrg}
        ></SwitchOrg>
      </ReactModal>
      <AddOrg setShow={setShowAddOrg} show={showAddOrg} join></AddOrg>
      {display && <>{display}</>}
    </>
  );
};

function OrgLogo({
  collapsed,
  orgid,
  logout,
  width,
  handleToggleExpand,
  organization,
}) {
  const MyOrg = useFetchOrganizationSideMenu(orgid);
  const getLogo = useGetOrgLogo();
  const [signout, setsignout] = useState(true);

  if (MyOrg.isError) {
    console.log("Sign Out");
    if (signout) {
      setsignout(false);
      logout();
    }
  }

  return (
    <>
      <div
        className={`${styles.logo} ${collapsed ? styles.smallLogo : ""} ${
          !collapsed ? styles.largeLogo : ""
        } ${collapsed ? styles.smallLogo : ""}`}
        onClick={handleToggleExpand}
      >
        {MyOrg.isSuccess &&
          getLogo.isSuccess &&
          getLogo.data?.getOrgLogo?.imageURL &&
          !collapsed && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: !collapsed ? "flex-start" : "center",
                overflow: "hidden",
                maxHeight: !collapsed ? "3.5rem" : "1rem",
                borderRadius: ".5rem",
                // marginLeft: !collapsed ? "-.5rem" : "",
              }}
            >
              <ImageEditor
                src={getLogo.data?.getOrgLogo?.imageURL}
                alt={MyOrg.data?.organization?.name}
                canEdit={false}
                isLogo={true}
                position={
                  getLogo.data.getOrgLogo?.position &&
                  typeof getLogo.data.getOrgLogo?.position === "string"
                    ? JSON.parse(getLogo.data.getOrgLogo?.position)
                    : getLogo.data.getOrgLogo?.position
                }
                image={getLogo.data.getOrgLogo}
                height={collapsed ? 50 : 250}
                width={collapsed ? 50 : 250}
                noShadow
              ></ImageEditor>
            </div>
          )}
        {/* <img src={getLogo.data?.getOrgLogo?.imageURL} ></img> */}
        {MyOrg.isSuccess &&
          getLogo.isSuccess &&
          !getLogo.data?.getOrgLogo?.imageURL &&
          !collapsed && (
            <div className={styles.logo_name}>{organization.name}</div>
          )}
        {MyOrg.isSuccess &&
          getLogo.isSuccess &&
          getLogo.data?.getOrgLogo?.imageURL &&
          collapsed && (
            <Icon iconName={"list"} style={{ fontSize: "1.4rem" }}></Icon>
          )}
      </div>
    </>
  );
}
