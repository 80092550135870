import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";
import styles from "./SurveyLogicQ.module.scss";

export const SurveyLogicSection = ({ section, active, setActiveQ, faded }) => {
  function onClick() {
    if (!active) {
      setActiveQ(section);
    }
  }

  return (
    <div
      className={`${styles.sectionContainer} ${active ? styles.active : ""} ${
        faded ? styles.faded : ""
      }`}
      style={{
        boxShadow: section.sectionSettings?.sectionShadow
          ? section.sectionSettings.sectionShadow
          : "",
        backgroundColor: section.sectionSettings?.hasBackgroundColor
          ? section.sectionSettings?.backgroundColor
          : "transparent",
      }}
      onClick={onClick}
      id={"survey logic q: " + section.id}
    >
      {section.bodyText != "" && (
        <TextEditorTry
          encoding={section.encoding}
          style={{ color: faded ? "#a3a4a8" : undefined, cursor: "pointer" }}
        />
      )}
    </div>
  );
};
