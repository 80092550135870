import React from "react";
import styles from "./SettingToggle.module.scss";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";

function SettingToggle({ onCheck, checked, text, style, textStyle, thick }) {
  return (
    <div className={`${styles.settingContainer} ${thick ? styles.thickContainer : ""}`} style={style}>
      <Checkbox
        checked={checked}
        onChange={(e) => onCheck ? onCheck(e) : ""}
        className={styles.check}
      ></Checkbox>
      <div className={`${styles.setting} ${thick ? styles.thick : ""}`} style={textStyle}>{text ? text : "New Setting"}</div>
    </div>
  );
}

export default SettingToggle;
