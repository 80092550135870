//External
import React from "react";

//Internal
import styles from "./HeaderContainer.module.scss";

/**
 * A headercontainer component that can hold your header with other content
 * @param {String} title string to be displayed
 * @param {String} backPath the path to go back to
 * @returns {React.ReactElement} a header component
 */

export function HeaderContainer({ style, children }) {
  return (
    <div className={styles.headerContainer} style={style}>
      {children}
    </div>
  );
}
