// Externals
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Internals
import styles from "./MenuItem.module.scss";

export const MenuItem = ({
  id,
  label,
  sublabel,
  iconClassName,
  to,
  condensed,
  isActive,
  makeActive,
  flatten,
}) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      className={`${styles.menuItem} ${isActive && styles.activeItem}`}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      onClick={(e) => {
        makeActive(id);
        if (to) {
          navigate(to);
        }
      }}
    >
      {iconClassName && <i className={`bi bi-${iconClassName}`}></i>}
      {!condensed && (
        <div>
          <div className={styles.label}>{label}</div>
          <div className={styles.sublabel}>{sublabel}</div>
        </div>
      )}
      {condensed && show && (
        <div
          className={styles.tooltipContainer}
          style={{
            padding: flatten ? "0em" : "",
            marginTop: flatten ? "90px" : "",
            marginLeft: flatten ? "-20px" : "",
          }}
        >
          <div className={styles.tooltip}>{label}</div>
        </div>
      )}
    </div>
  );
};
