//External
import React, { useState } from "react";
//Internal
import { useLoginRequest } from "api/resources/authentication";
import { TextFieldSimple } from "components/inputs/input_fields/TextFieldSimple/TextFieldSimple";
import styles from "./Login.module.scss";
import reactionLogo from "assets/images/reactionlogonew.png";
import { Header } from "components/layouts";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import MFA from "pages/register/MFA";

/**
 * The login functionality to login the user in
 * @param {Function} setToken sets the current token when called
 * @returns {token} the token that has been set
 */

export function Login({ setToken }) {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };
  const loginUserRequest = useLoginRequest();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [verifyMFA, setVerifyMFA] = useState(false);

  const handleSubmit = (e) => {
    if (email === "") {
      setErrMsg("Email required");
    } else if (password === "") {
      setErrMsg("Password required");
    } else {
      let mfa = localStorage.getItem("MFAVerified");

      loginUserRequest.mutate(
        {
          email: email,
          password: password,
          MFA: mfa === "true" ? true : false,
        },
        {
          onSuccess: (data) => {
            // if (!data?.login?.user?.MFAVerified) {
            //   routeChange("/login/" + data?.login?.user?.id);
            // } else if (mfa === "true" ? false : true) {
            //   setVerifyMFA(data?.login?.user?.id);
            // } else {
            //   setToken(data);
            //   routeChange("/");
            //   localStorage.setItem("activepage", "0");
            //   window.location.reload();
            // }
            if (!data?.login?.user?.verified) {
              setVerifyMFA(data?.login?.user?.id);
            } else {
              setToken(data);
              routeChange("/");
              localStorage.setItem("activepage", "0");
              window.location.reload();
            }
          },
          onError: (err) => {
            let msg = err.response.errors[0].message;
            if (msg === "Invalid Username or password") {
              msg = "Invalid email";
            }
            setErrMsg(msg);
          },
        }
      );
    }
  };

  const handleRequestPasswordChange = (e) => {
    routeChange("/forgotpassword");
  };

  return (
    <div className={styles.page}>
      {verifyMFA && <MFA userId={verifyMFA}></MFA>}
      {!verifyMFA && (
        <>
          <div
            className={styles.splitPageContent}
            style={{ margin: window.innerWidth < 512 ? "0" : "" }}
          >
            <div
              className={styles.formContainer}
              style={{
                backgroundColor: window.innerWidth < 512 ? "transparent" : "",
                boxShadow: window.innerWidth < 512 ? "none" : "",
              }}
            >
              <div className={styles.imageContainer}>
                <img src={reactionLogo} />
              </div>
              <Header title="Welcome!"></Header>
              <span>Sign into your account below</span>

              <div className={styles.form}>
                <div>
                  <TextFieldSimple
                    type="email"
                    onChange={setEmail}
                    placeholder="Email"
                    icon="at"
                    valid={
                      errMsg === "Invalid email" || errMsg === "Email required"
                        ? false
                        : true
                    }
                    invalidMessage={errMsg}
                  />
                </div>
                <div>
                  <TextFieldSimple
                    type="password"
                    onChange={setPassword}
                    placeholder="Password"
                    icon="key"
                    valid={
                      errMsg == "Invalid password" ||
                      errMsg === "Password required"
                        ? false
                        : true
                    }
                    invalidMessage={errMsg}
                    onEnter={handleSubmit}
                  />
                </div>
              </div>
              {window.innerWidth > 512 && (
                <Button link={true} onClick={handleRequestPasswordChange}>
                  Forgot Password?
                </Button>
              )}
              <div className={styles.buttonContainer}>
                <Button
                  blue
                  onClick={handleSubmit}
                  type="submit"
                  shadow
                  style={{ width: window.innerWidth < 512 ? "100%" : "" }}
                >
                  Sign In
                </Button>
              </div>
              {window.innerWidth <= 512 && (
                <Button link={true} onClick={handleRequestPasswordChange}>
                  Forgot Password?
                </Button>
              )}
              {window.innerWidth > 512 && (
                <>
                  <HorizontalBar
                    height="5px"
                    backgroundColor="#E9E9E9"
                    width="50%"
                  />
                  <div className={styles.register}>
                    Request a demo or learn more at{" "}
                    <a href="https://reactiondata.com" className={styles.aLink}>
                      {" "}
                      reactiondata.com
                    </a>
                    .
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                gap: ".5rem",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              Don&apos;t have an account?
              <div
                style={{
                  display: "flex",
                  gap: ".5rem",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {/* <Button
                onClick={() => navigate("/register")}
                type="submit"
                shadow
                sapphire
              >
                Sign Up
              </Button>
              or{" "} */}
                <Button
                  onClick={() => navigate("/register/join-organization")}
                  type="submit"
                  shadow
                  sapphire
                >
                  Join Organization
                </Button>
              </div>
            </div>
            <div
              style={{ display: "flex", gap: ".5rem", alignItems: "center" }}
            ></div>
          </div>
        </>
      )}
    </div>
  );
}

export const useToken = () => {
  const getToken = () => {
    const tokenString = localStorage.getItem("reaction_token");
    if (tokenString != undefined) {
      return JSON.parse(tokenString);
    }
    return null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("reaction_token", JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
};
