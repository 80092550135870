import React from "react";
import styles from "./FlexCol.module.scss";

export default function FlexCol({
  children,
  style,
  onClick,
  className,
  justify,
  align,
  gap,
  id,
}) {
  return (
    <div
      className={`${styles.flexCol} ${className ? className : ""}`}
      id={id}
      style={{
        ...{
          justifyContent: justify ? justify : "",
          gap: gap ? gap : "",
          alignItems: align ? align : "",
        },
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
