// External
import React, { useEffect, useRef, useState } from "react";

//Internal
import styles from "./PersonalLinks.module.scss";
// import {ReactModal} from "components/ReactModal/ReactModal"
import { useParams } from "react-router-dom";
import InteractiveTable from "components/tables/BasicTable/InteractiveTable";
import { PersonalLink } from "./link/PersonalLink";
import {
  useFetchParticipations,
  useFetchParticipationsByProject,
  useFetchParticipationsByProjectCount,
} from "api/resources/projects/participations";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { TextFieldSimple } from "components/inputs";
import ContactProfile from "components/Popout/ContactProfile";
import Button from "components/Button/Button";
import { CSVLink } from "react-csv";
import { Loading } from "components/Loading/Loading";

export const PersonalLinks = ({ roles, userId }) => {
  const [copying, setCopying] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [compose, setCompose] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showNewLink, setShowNewLink] = useState(false);
  const [showContact, setShowContact] = useState();
  const [active, setActive] = useState(null);
  const [data, setData] = useState([]);
  const [download, setDownload] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { id } = useParams();
  const csvref = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [pageSort, setPageSort] = useState({
    item: "started",
    descend: true,
  });
  const [perPage, setPerpage] = useState(15);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchString, setSearchString] = useState("");

  const getDistributions = useFetchParticipationsByProject(
    id,
    pageSort,
    pageSkip,
    perPage,
    searchString
  );
  const getDistributionsCount = useFetchParticipationsByProjectCount(
    id,
    pageSort,
    pageSkip,
    perPage,
    searchString
  );

  const exportParticipations = useFetchParticipations();

  const headers = [
    {
      id: 0,
      index: 0,
      name: "First Name",
      accessor: "contact",
      objectAccessor: "firstName",
      enabled: true,
      sort: false,
      canSort: false,
      obscure: !roles.canSeeContactInfo,
    },
    {
      id: 1,
      index: 1,
      name: "Last Name",
      accessor: "contact",
      objectAccessor: "lastName",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: null,
      obscure: !roles.canSeeContactInfo,
    },
    {
      id: 2,
      index: 2,
      name: "email",
      accessor: "contact",
      objectAccessor: "email",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: null,
      obscure: !roles.canSeeContactInfo,
    },
    {
      id: 3,
      index: 3,
      name: "Opened Link",
      accessor: "started",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: (value) => (
        <span
          className={`${styles.status}
          ${value ? `${styles.delivered}` : ""}
          ${!value ? `${styles.draft}` : ""}`}
        >
          {value ? "True" : "False"}
        </span>
      ),
    },
    {
      id: 4,
      index: 4,
      name: "Link",
      accessor: "link",
      enabled: true,
      sort: true,
      canSort: true,
      cell_style: (value) => (
        <span>
          {value &&
            value.substring(
              value.indexOf('href="') + 6,
              value.lastIndexOf('"')
            )}
        </span>
      ),
    },
  ];

  const headersExport = [
    {
      label: "First Name",
      key: "firstName",
    },
    {
      label: "Last Name",
      key: "lastName",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Link",
      key: "link",
    },
  ];

  useEffect(() => {
    getDistributions.refetch();
  }, [showEmail]);

  useEffect(() => {
    getDistributions.refetch();
  }, [pageNumber, pageSort, perPage, searchString]);

  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setCompose(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function handleRowClick(row) {
    console.log(row);
    setActive(row);
  }

  function exportLinks() {
    // debugger;
    setDownloading(true);
    exportParticipations.mutate(
      {
        projectId: id,
      },
      {
        onSuccess: (data) => {
          console.log(data.participations);
          let final = [];
          for (let part of data.participations) {
            let link = part.link.substring(
              part.link.indexOf('href="') + 6,
              part.link.lastIndexOf('"')
            );
            final.push({
              firstName: part?.contact?.firstName,
              lastName: part?.contact?.lastName,
              email: part?.contact?.email,
              link: link,
            });
          }
          // debugger;
          setDownload(true);
          setData(final);
        },
      }
    );
  }

  useEffect(() => {
    if (download) {
      csvref.current.click();
      setDownload(false);
      setDownloading(false);
    }
  }, [data]);

  return (
    <>
      <div className={styles.page}>
        <InteractiveTable
          headers={headers}
          // data={searchProjects.}
          queryData={getDistributions}
          queryDataAccessor={"participations"}
          maxDataQuery={getDistributionsCount}
          maxDataQueryAccessor={"count"}
          currPage={pageNumber}
          setCurrPage={setPageNumber}
          // maxData={getProjectCount}
          onRowClick={handleRowClick}
          sort={pageSort}
          setSort={setPageSort}
          pageSkip={pageSkip}
          setPageSkip={setPageSkip}
          perPage={perPage}
          setPerPage={setPerpage}
          searchString={searchString}
          setSearchString={setSearchString}
          actionButtonText={
            <div>
              Generate Links <i className="bi bi-link"></i>
            </div>
          }
          tableTitle={`Personal Links`}
          tableDescription={`You can create Personal Links here or they are auto generated on email sends.`}
          // widgetType="link"
          searchPlaceholder={"Search Links..."}
          onClickActionButton={() => setShowNewLink(true)}
          id={id}
          emptyMessage="No links have been generated yet"
        />
        {showNewLink && (
          <PersonalLink
            show={showNewLink}
            setShow={setShowNewLink}
            onCreated={() => {
              setTimeout(() => {
                getDistributionsCount.refetch();
                getDistributions.refetch();
              }, 300);
            }}
          ></PersonalLink>
        )}
        {getDistributions.isSuccess && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "1em",
            }}
          >
            <CSVLink
              data={data ? data : []}
              headers={headersExport}
              //   headers={[]}
              filename={"personallinks.csv"}
              // style={{
              //   height: "40px",
              //   //border: "2px solid black",
              //   alignContent: "center",
              //   display: "flex",
              // }}
              target="_blank"
            >
              <button
                ref={csvref}
                style={{ border: "none", opacity: "0" }}
              ></button>
            </CSVLink>

            <Button
              shadow
              onClick={() =>
                !downloading ? exportLinks() : console.log("Nope!")
              }
            >
              {!downloading && "Export Links"}{" "}
              {downloading && (
                <>
                  Creating Export <Loading height={20} width={20}></Loading>
                </>
              )}
            </Button>
          </div>
        )}
      </div>
      {/* <ViewLink setShow={setActive} participation={active}></ViewLink> */}
      {active && (
        <ReactModal
          show={active && !showContact}
          onClose={() => setActive(null)}
          modalStyle={{
            borderRadius: "1em",
            padding: "1em",
            width: "800px",
            overflow: "visible",
            gap: "1em",
          }}
          dark
        >
          <TextFieldSimple
            value={active.contact.firstName}
            disable
            label="First Name"
          />
          <TextFieldSimple
            value={active.contact.lastName}
            disable
            label="Last Name"
          />
          <TextFieldSimple value={active.contact.email} disable label="Email" />
          <TextFieldSimple
            value={active.link.substring(
              active.link.indexOf('href="') + 6,
              active.link.lastIndexOf('"')
            )}
            disable
            copyToClipboard
            label="Personal Survey Link"
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button shadow onClick={() => setShowContact(true)}>
              View Contact
            </Button>
          </div>
        </ReactModal>
      )}
      {showContact && (
        <ContactProfile
          contact={active?.contact}
          onClose={() => setShowContact(false)}
        ></ContactProfile>
      )}
    </>
  );
};
