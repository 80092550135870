import FlexCol from "components/layouts/FlexColumn/FlexCol";
import styles from "./TextTool.module.scss";
import { ChartLabel } from "../ChartLabel/ChartLabel";
import { TextFieldSimple } from "components/inputs";

export function TextTool({ value, onChange, style, children }) {
  return (
    <FlexCol gap={"5px"} style={style}>
      <ChartLabel>{children}</ChartLabel>
      <TextFieldSimple
        value={value}
        onChange={onChange}
        style={{ fontSize: ".8rem" }}
      ></TextFieldSimple>
    </FlexCol>
  );
}
