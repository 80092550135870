import React, { useState, useEffect } from "react";
import styles from "./ToggleSwitch.module.scss";

/**
 * a customized checkbox disguised as a switch
 * @param {Boolean} startChecked tells the component to start checked or not
 * @param {Function} handleCheck called when the switch to toggled and returns whether it is checked or not
 * @param {String} id an id that is placed in the input to be able to be referenced by javascript
 * @returns {React.ReactElement} a numberscale clickable component
 */

export const ToggleSwitch = ({
  startChecked,
  handleCheck,
  id,
  flip,
  disable,
  removeState,
}) => {
  const [isChecked, setIsChecked] = useState(startChecked);

  useEffect(() => setIsChecked(startChecked), [startChecked]);

  const handleChange = () => {
    setIsChecked(!isChecked);
    if (handleCheck) {
      handleCheck(!isChecked);
    }
  };

  const handleChecked = () => {
    if (!removeState) {
      if (flip) {
        //flip = false;
        return !isChecked;
      }
      return isChecked;
    } else {
      return startChecked;
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <label className={styles.switch}>
        <input
          type="checkbox"
          checked={handleChecked()}
          onChange={handleChange}
          id={id}
          disabled={disable ? true : false}
        />
        <span className={styles.slider}></span>
      </label>
    </div>
  );
};
