import React from "react";
import styles from "./ReportWidgets.module.scss";
import ReportWidget from "./ReportWidget";

function ReportWidgets({
  reports,
  onClick,
  roles,
  user,
  draft,
  onClickSeeMore,
  isOrg,
  refetch,
  organization,
  type
}) {
  return (
    <div className={styles.surveys}>

      {reports.map((report) => (
        <ReportWidget
          key={report.id}
          report={report}
          onClick={onClick}
          user={user}
          roles={roles}
          isOrg={isOrg}
          refetch={refetch}
          organization={organization}
        />
      ))}{" "}
      {draft && (
        <div className={styles.add} onClick={onAdd}>
          <i className="bi bi-plus" style={{ fontSize: "13em" }}></i>
        </div>
      )}
    </div>
  );
}

export default ReportWidgets;
