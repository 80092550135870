/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/
import { gql } from "@apollo/client";

export const NOTIFICATION = gql`
  subscription NotificationSubscription($userId: String!) {
    notificationSubscription(userId: $userId) {
      id
      title
      sender
      senderTitle
      type
      link
      body
      header
      createdAt
    }
  }
`;

export const DISTRIBUTION = gql`
  subscription DistributionSubscription($userId: String!) {
    distributionSubscription(userId: $userId) {
      id
      body
      progress
      current
      total
      itemId
    }
  }
`;

export const REPORTDRAFTS = gql`
  subscription ReportDraftsSubscription($userId: String!, $reportId: String!) {
    task: reportDraftsSubscription(userId: $userId, reportId: $reportId) {
      progress
      itemId
      title
      type
    }
  }
`;
