import React from "react";
import styles from "./SurveyDesign.module.scss";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import ColorPickerContainer from "components/inputs/input_fields/ColorPicker/ColorPickerContainer";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";
import { fonts, shadows } from "assets/functions/Variables";
import { SelectFieldCustom } from "components/inputs";

export function PageStyle({ settings, changeSetting }) {
  return (
    <div className={styles.body}>
      <div
        className={styles.label5}
        style={{ margin: "0", fontWeight: "500", padding: "0 1rem" }}
      >
        Global Styles
      </div>

      <div className={styles.settingVertical}>
        <ColorPickerContainer
          onChange={(color) => changeSetting("baseColor", color)}
          defaultColor={settings.baseColor}
          title="Primary Color"
          //   colors={settings.colorBank}
        />
      </div>
      <div className={styles.settingVertical}>
        <ColorPickerContainer
          onChange={(color) => changeSetting("secondaryColor", color)}
          defaultColor={settings?.secondaryColor}
          title="Secondary Color"
          //   colors={settings.colorBank}
        />
      </div>
      <div className={styles.settingVertical}>
        <ColorPickerContainer
          onChange={(color) => changeSetting("questionTextColor", color)}
          defaultColor={
            settings?.questionTextColor
              ? settings?.questionTextColor
              : "#000000"
          }
          title="Question Text Color"
          //   colors={settings.colorBank}
        />
      </div>

      <div className={styles.settingVertical}>
        <NumberInputWithSlider
          val={
            settings?.questionNameSize || settings?.questionNameSize === 0
              ? settings?.questionNameSize
              : 22
          }
          onChange={(val) => changeSetting("questionNameSize", val)}
          min={0}
          max={30}
          step={1}
          color={"#15bcc7"}
          title="Question Text Size"
        ></NumberInputWithSlider>
      </div>
{/* 
      <div className={styles.settingVertical}>
        <SelectFieldCustom
          // onChange={(color) => changeSetting("answerColor", color)}
          value={{
            label: (
              <div style={{ fontFamily: '"Times New Roman", serif' }}>
                Times New Roman
              </div>
            ),
            value: '"Times New Roman", serif',
          }}
          label="Global Text Font"
          icon="bi-chevron-down"
          //   colors={settings.colorBank}
          options={fonts}
          select
          anyWhereOptions
        />
      </div> */}

      <div className={styles.settingVertical}>
        <NumberInputWithSlider
          val={
            settings?.questionGap || settings?.questionGap === 0
              ? settings?.questionGap
              : 0
          }
          onChange={(val) => changeSetting("questionGap", val)}
          min={0}
          max={50}
          step={1}
          color={"#15bcc7"}
          title="Question Spacing"
        ></NumberInputWithSlider>
      </div>

      <div className={styles.settingVertical}>
        <SelectFieldCustom
          onChange={(color) => changeSetting("boxShadow", color.value)}
          value={
            settings?.boxShadow
              ? shadows.find((s) => s.value === settings?.boxShadow)
              : { value: "", label: "No Shadow" }
          }
          label="Question Border Shadow"
          // icon="bi-chevron-down"
          options={shadows}
          select
          anyWhereOptions
        />
      </div>
      <div className={styles.settingVertical}>
        <div className={styles.label6}>Question Numbers </div>
        <div className={styles.settingHorizontal}>
          <ToggleSwitch
            startChecked={settings?.numbers}
            handleCheck={(val) => changeSetting("numbers", val)}
          ></ToggleSwitch>

          <div className={styles.text_3}>Show Question Numbers</div>
        </div>{" "}
      </div>
      <div className={styles.settingVertical}>
        <div className={styles.label6}>Answer Styles </div>
        <div className={styles.settingHorizontal}>
          <ToggleSwitch
            startChecked={settings?.multipleChoiceBars}
            handleCheck={(val) => changeSetting("multipleChoiceBars", val)}
          ></ToggleSwitch>

          <div className={styles.text_3}>Answer Bars</div>
        </div>{" "}
      </div>
      {settings?.multipleChoiceBars && (
        <div className={styles.settingVertical}>
          <ColorPickerContainer
            onChange={(color) => changeSetting("answerColorActive", color)}
            defaultColor={
              settings?.answerColorActive ? settings?.answerColorActive : "#000000"
            }
            title="Answer Text Color Chosen"
            //   colors={settings.colorBank}
          />
        </div>
      )}
      <div className={styles.settingVertical}>
        <ColorPickerContainer
          onChange={(color) => changeSetting("answerColor", color)}
          defaultColor={
            settings?.answerColor ? settings?.answerColor : "#000000"
          }
          title="Answer Text Color"
          //   colors={settings.colorBank}
        />
      </div>
      <div className={styles.settingVertical}>
        <div className={styles.label6}>Answer Text Size</div>
        <NumberInputWithSlider
          val={
            settings?.answerTextSize || settings?.answerTextSize === 0
              ? settings?.answerTextSize
              : 22
          }
          onChange={(val) => changeSetting("answerTextSize", val)}
          min={0}
          max={4}
          step={1}
          color={"#15bcc7"}
          valueLabel=""
        ></NumberInputWithSlider>
      </div>

      {/* <div className={styles.settingVertical}>
        <div className={styles.label6}>Logo </div>
        <div className={styles.settingVertical} style={{ gap: ".5rem" }}>
          <div className={styles.settingHorizontal}>
            <ToggleSwitch
              startChecked={settings.showLogo}
              handleCheck={(val) => changeSetting("showLogo", val)}
            ></ToggleSwitch>
            <div className={styles.text_3}>Show Logo</div>
          </div>
          {settings.showLogo && (
            <div className={styles.settingHorizontal}>
              <ToggleSwitch
                startChecked={settings.logoOnEveryPage}
                handleCheck={(val) => changeSetting("logoOnEveryPage", val)}
              ></ToggleSwitch>

              <div className={styles.text_3}>Show Logo on every page</div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.settingVertical}>
        <div className={styles.label6}>Progress Bar </div>
        <div className={styles.settingHorizontal}>
          <ToggleSwitch
            startChecked={settings.showProgBar}
            handleCheck={(val) => changeSetting("showProgBar", val)}
          ></ToggleSwitch>

          <div className={styles.text_3}>Show Progress Bar</div>
        </div>
      </div> */}
    </div>
  );
}
