import { useEffect, useState, useRef } from "react";
import styles from "../SettingsAccordion.module.scss";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import {
  AnswerCount,
  AtoZ,
  AvgScore,
  DoughnutType,
  Flywheel,
  HorizBarType,
  LineType,
  NoSort,
  NpsScore,
  ParticipationCount,
  PieType,
  Ranking,
  VertBarType,
  ZtoA,
  splitSomehow,
} from "../../QuestionChart";
import { useIsHealthCatalyst } from "api/resources/organization/organization";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { combinedProjIds, combinedQs } from "../../Visualization";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { ChartLabel, Check, Switch } from "../Tools";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { OneOrTheOther } from "components/inputs/input_fields/OneOrTheOther/OneOrTheOther";

export const SplitsNPivotsAccordion = ({
  viz,
  updateViz,
  visible,
  setVisible,
  changeSettingsField,
  custom_fields,
  originalColors,
}) => {
  function getPivotCount() {
    let count = 0;
    if (viz.pivotString) {
      count += 1;
    }
    if (viz.designSettings.split) {
      count += 1;
    }
    return count;
  }

  return (
    <div
      key={viz.id}
      className={`${styles.header} ${visible ? styles.headervisible : ""}`}
      onClick={!visible ? setVisible : undefined}
    >
      <FlexRow
        onClick={visible ? setVisible : undefined}
        style={{ cursor: "pointer" }}
        className={styles.accordionTitle}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <span>Splits & Pivots</span>
          <i style={{ fontSize: "1.2em" }} className="bi-person-lines-fill"></i>
          {getPivotCount() > 0 && (
            <HorizontalBar rotate height={15} width={2}></HorizontalBar>
          )}
          {getPivotCount() > 0 && (
            <div className={styles.number}>{getPivotCount()}</div>
          )}
        </div>

        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </FlexRow>

      {visible && (
        <SplitsNPivots
          viz={viz}
          updateViz={updateViz}
          changeSettingsField={changeSettingsField}
          custom_fields={custom_fields}
          originalColors={originalColors}
        />
      )}
    </div>
  );
};

export function SplitsNPivotsModal({
  viz,
  updateViz,
  close,
  changeSettingsField,
  custom_fields,
  originalColors,
}) {
  const ref = useRef();

  function clickOut(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      close();
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOut, true);
    return () => {
      document.removeEventListener("click", clickOut, true);
    };
  }, []);

  return (
    <div
      className={styles.body}
      style={{ borderRadius: "1em", minHeight: "200px" }}
      ref={ref}
    >
      <div
        key={viz.id}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div key={"filters"} className={`${styles.title}`}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
              padding: "0rem 1rem",
            }}
          >
            Splits & Pivots{" "}
            <i
              style={{ fontSize: "1.2em" }}
              className="bi-person-list-fill"
            ></i>
          </div>
        </div>
        <span className={styles.xicon} onClick={close}>
          <i className="bi-x-lg"></i>
        </span>
      </div>

      <SplitsNPivots
        viz={viz}
        updateViz={updateViz}
        changeSettingsField={changeSettingsField}
        custom_fields={custom_fields}
        originalColors={originalColors}
      />
    </div>
  );
}

export function SplitsNPivots({
  viz,
  updateViz,
  changeSettingsField,
  custom_fields,
  originalColors,
}) {
  const isHC = useIsHealthCatalyst();

  const [split, setSplit] = useState(viz.designSettings.split);
  const [pivot, setPivot] = useState(viz.pivotString);
  const [overall, setOverall] = useState(
    viz.designSettings.overallLabel ? viz.designSettings.overallLabel : ""
  );

  const allQs = combinedQs(viz);

  function isMCPivot() {
    return allQs[0].choiceQuestion && viz.pivotString;
  }

  function isMCSplit() {
    return (
      allQs[0].choiceQuestion && viz.designSettings.split && !viz.pivotString
    );
  }

  function handleCalculateChange(option) {
    let newViz = { ...viz };
    newViz.designSettings.answerType = option.value;
    if (newViz.designSettings.autoGenAxisTitles) {
      newViz.designSettings.YAxisTitle = option.label;
    }

    if (isMCPivot()) {
      if (option.value === AnswerCount) {
        newViz.designSettings.split = "choices";
        addLegend(newViz);
        setSplit("choices");
      } else {
        newViz.designSettings.split = "";
        removeLegend(newViz);
        setSplit("");
      }
      newViz.designSettings.segLabelReplacements = {};
    }

    if (
      !(option.value === AnswerCount || option.value === ParticipationCount)
    ) {
      newViz.designSettings.dataLabelPercent.show = false;
    }

    newViz.designSettings.graphMax = null;
    newViz.designSettings.graphMin = null;

    updateViz(newViz);
  }

  function couldBeNPS() {
    for (let q of allQs) {
      if (!q.scaleQuestion) {
        return false;
      }
      if (!(q.scaleQuestion.min === 0 && q.scaleQuestion.max === 10)) {
        return false;
      }
    }
    return true;
  }

  const surveyOptions = [
    { displayName: "survey", name: "survey" },
    { displayName: "survey date", name: "survey date" },
    { displayName: "survey quarter", name: "survey quarter" },
  ];

  const getOptions = () => {
    let options = custom_fields
      .filter((f) => f.filterable !== false)
      .map((f) => {
        return { displayName: f.displayName, name: f.name };
      });

    // hide if combining?
    if (combinedProjIds(viz).length > 1) {
      options = [...options, ...surveyOptions];
    }

    // if (viz.tagIdsArray.length > 1) {
    //   options.push({ displayName: "survey tag", name: "survey tag" });
    // }

    options.push({ displayName: "month taken", name: "month taken" });
    options.push({ displayName: "hour taken", name: "hour taken" });
    return options;
  };

  function getBucketOptions() {
    let options = [];
    for (let question of allQs) {
      if (question.textQuestion && question.textQuestion.bucket) {
        for (let bucket of question.textQuestion.bucket) {
          if (!options.includes(bucket.name)) {
            options.push(bucket.name);
          }
        }
      }
    }
    return options;
  }

  const [options, setOptions] = useState(getOptions());

  function capitalize(title) {
    return title.charAt(0).toUpperCase() + title.slice(1);
  }

  function resetSegLabels(newViz) {
    if (newViz.designSettings.colors) {
      newViz.designSettings.colors = undefined;
    }

    if (
      originalColors &&
      originalColors.split === newViz.designSettings.split &&
      originalColors.pivot === newViz.pivotString
    ) {
      newViz.designSettings.colors = [...originalColors.colors];
    }

    newViz.designSettings.segLabelReplacements = {};
    newViz.designSettings.axisLabelReplacements = {};
  }

  const changePivot = (field) => {
    let newViz = { ...viz };
    newViz.pivotString = field;
    // newViz.designSettings.sortData = NoSort;
    newViz.designSettings.stacked = false;

    if (allQs[0].scaleQuestion) {
      if (field) {
        if (field === "nps") {
          viz.designSettings.answerType = AnswerCount;
        } else if (viz.designSettings.answerType === "answer count") {
          if (couldBeNPS()) {
            newViz.designSettings.answerType = NpsScore;
          } else {
            newViz.designSettings.answerType = AvgScore;
          }
          //If you are pivoting to a brand new pivot (just to preserve the default) that's not nps
          newViz.designSettings.dataLabelPercent.show = false;
        }
      } else {
        newViz.designSettings.answerType = "answer count";
        if (newViz.designSettings.split === "nps") {
          // undo the nps split if not pivoting anything
          newViz.designSettings.split = "";
        }
        if (newViz.designSettings.includeOverall)
          newViz.designSettings.includeOverall = false;
      }
      if (
        newViz.designSettings.sortData === AtoZ ||
        newViz.designSettings.sortData === ZtoA
      ) {
        newViz.designSettings.sortData = NoSort;
      }
    }

    if (allQs[0].choiceQuestion) {
      if (field) {
        if (!viz.pivotString && !viz.designSettings.split) {
          // It's Fresh
          if (viz.type === DoughnutType || viz.type === PieType) {
            newViz.designSettings.answerType = ParticipationCount;
          } else {
            newViz.designSettings.split = "choices";
          }
          newViz.designSettings.byPercentOfTotal = false;
        }
      } else {
        newViz.designSettings.split = "";
        newViz.designSettings.answerType = AnswerCount;

        if (viz.type !== DoughnutType && viz.type !== PieType) {
          removeLegend(newViz);
        }
      }
    }
    if (allQs[0].textQuestion) {
      if (field) {
        newViz.designSettings.answerType = ParticipationCount;
      } else {
        newViz.designSettings.answerType = AnswerCount;
        newViz.designSettings.split = "";
      }
      delete newViz.designSettings.pivotingByBucket;
    }

    if (splitSomehow(newViz)) {
      addLegend(newViz);
    }

    if (newViz.designSettings.autoGenAxisTitles) {
      generateAxisTitles(newViz);
    }

    if (
      (newViz.type === DoughnutType || newViz.type === PieType) &&
      newViz.designSettings.hasLegend
    ) {
      if (field && field !== "nps") {
        newViz.designSettings.legendTitle = capitalize(field);
        newViz.designSettings.hasLegendTitle = true;
      } else {
        newViz.designSettings.hasLegendTitle = false;
        newViz.designSettings.legendTitle = "";
      }
    }

    setPivot(field);
    setSplit(newViz.designSettings.split);
    resetSegLabels(newViz);

    updateViz(newViz);
  };

  function bucketPivot(bucket) {
    let newViz = { ...viz };
    newViz.pivotString = bucket;
    newViz.designSettings.answerType = AnswerCount;

    if (bucket) {
      newViz.designSettings.pivotingByBucket = true;
    } else {
      delete newViz.designSettings.pivotingByBucket;
    }

    if (newViz.designSettings.autoGenAxisTitles) {
      if (bucket) {
        newViz.designSettings.XAxisTitle = capitalize(bucket);
        newViz.designSettings.YAxisTitle = "Count";
      } else {
        newViz.designSettings.XAxisTitle = "Responses";
        newViz.designSettings.YAxisTitle = "Answer Count";
      }
    }

    if (
      (newViz.type === DoughnutType || newViz.type === PieType) &&
      newViz.designSettings.hasLegend
    ) {
      if (bucket) {
        newViz.designSettings.legendTitle = capitalize(bucket);
        newViz.designSettings.hasLegendTitle = true;
      } else {
        newViz.designSettings.hasLegendTitle = false;
        newViz.designSettings.legendTitle = "";
      }
    }

    if (!bucket) {
      newViz.designSettings.split = "";
    }

    setPivot(bucket);
    setSplit(newViz.designSettings.split);
    resetSegLabels(newViz);

    updateViz(newViz);
  }

  function bucketSplit(bucket) {
    let newViz = { ...viz };
    newViz.designSettings.split = bucket;
    setSplit(bucket);

    if (bucket) {
      newViz.designSettings.splittingByBucket = true;
    } else {
      delete newViz.designSettings.splittingByBucket;
    }

    if (bucket) {
      addLegend(newViz);
    } else {
      removeLegend(newViz);
    }

    newViz.designSettings.sortData = NoSort;

    resetSegLabels(newViz);
    updateViz(newViz);
  }

  function getScaleOptions() {
    let options = [
      {
        label: "Avg Score",
        value: AvgScore,
      },
      {
        label: "Participation Count",
        value: ParticipationCount,
      },
    ];

    if (allQs[0].scaleQuestion) {
      if (couldBeNPS()) {
        options.unshift({
          label: "NPS",
          value: NpsScore,
        });
      }
    }
    return options;
  }

  function getChoiceOptions() {
    let options = [
      {
        label: "Participation Count",
        value: ParticipationCount,
      },
    ];

    return options;
  }

  function getVertOptions() {
    if (allQs[0].scaleQuestion) {
      return getScaleOptions();
    }
    if (allQs[0].choiceQuestion) {
      return getChoiceOptions();
    }
    return [];
  }

  function changeSplit(e, field) {
    let newViz = { ...viz };
    if (e.target.checked) {
      newViz.designSettings.split = field;
      setSplit(field);
    } else {
      newViz.designSettings.split = "";
      setSplit("");
      if (newViz.designSettings.includeOverall)
        newViz.designSettings.includeOverall = false;
    }

    if (splitSomehow(newViz)) {
      addLegend(newViz);
    } else {
      removeLegend(newViz);
    }

    newViz.designSettings.sortData = NoSort;

    if (newViz.designSettings.autoGenAxisTitles) {
      generateAxisTitles(newViz);
    }

    if (allQs[0].textQuestion) {
      delete newViz.designSettings.splittingByBucket;
    }

    resetSegLabels(newViz);
    updateViz(newViz);
  }

  function splitByChoices(e) {
    let newViz = { ...viz };
    if (e.target.checked) {
      newViz.designSettings.answerType = AnswerCount;
      newViz.designSettings.split = "choices";
      addLegend(newViz);
      setSplit("choices");
      if (newViz.designSettings.scaleByRank) {
        newViz.designSettings.YAxisTitle = "Weighted Total";
      } else {
        newViz.designSettings.YAxisTitle = "Answer Count";
      }
    } else {
      newViz.designSettings.answerType = ParticipationCount;
      newViz.designSettings.split = "";
      removeLegend(newViz);

      newViz.designSettings.YAxisTitle = "Participation Count";
      setSplit("");
    }

    newViz.designSettings.segLabelReplacements = {};
    newViz.designSettings.axisLabelReplacements = {};

    updateViz(newViz);
  }

  function addLegend(newViz) {
    newViz.designSettings.hasLegend = true;
    newViz.designSettings.hasLegendTitle = true;

    if (newViz.designSettings.useComparison) {
      newViz.designSettings.legendTitle = "";
    } else {
      if (newViz.designSettings.split === "nps") {
        newViz.designSettings.legendTitle = "Category";
      } else if (newViz.designSettings.split === "survey quarter") {
        newViz.designSettings.legendTitle = "Quarter";
      } else if (newViz.designSettings.split === "month taken") {
        newViz.designSettings.legendTitle = "Month";
      } else if (newViz.designSettings.split === "hour taken") {
        newViz.designSettings.legendTitle = "Day";
      } else {
        newViz.designSettings.legendTitle = capitalize(
          newViz.designSettings.split
        );
      }
    }
  }

  function removeLegend(newViz) {
    if (!newViz.designSettings.useComparison) {
      newViz.designSettings.hasLegend = false;
      newViz.designSettings.hasLegendTitle = false;
      newViz.designSettings.legendTitle = "";
    }
  }

  const VertDisabled =
    viz.pivotString === "nps" ||
    !viz.pivotString ||
    viz.designSettings.split === "nps";

  const HorizDisabled =
    allQs[0].choiceQuestion &&
    viz.designSettings.split &&
    viz.designSettings.split !== "choices";

  const getXAxis = () => {
    if (viz.pivotString.includes("survey")) {
      return "Survey";
    }
    if (viz.pivotString === "nps") {
      return "NPS";
    }
    return viz.pivotString.charAt(0).toUpperCase() + viz.pivotString.slice(1);
  };

  function showFlywheel() {
    if (isHC.data.isHealthCatalyst) {
      // I need two questions for each survey
      let surveys = {};
      for (let q of allQs) {
        if (
          q.scaleQuestion &&
          q.scaleQuestion.min == 1 &&
          (q.scaleQuestion.max == 6 || q.scaleQuestion.max === 5) &&
          q.questionText.includes("How effective")
        ) {
          if (!(q.projectId in surveys)) {
            surveys[q.projectId] = 0;
          }
          surveys[q.projectId]++;
        }
      }
      if (!Object.keys(surveys).length) {
        return false;
      }

      for (let survey in surveys) {
        if (surveys[survey] !== 2) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  function setUpFlywheel(val) {
    let newViz = { ...viz };
    if (val) {
      newViz.designSettings.answerType = Flywheel;
      newViz.designSettings.YAxisTitle = "Flywheel Score";
      newViz.designSettings.XAxisTitle = "";
    } else {
      newViz.designSettings.answerType = AnswerCount;
      newViz.designSettings.YAxisTitle = "Answer Count";
      newViz.designSettings.XAxisTitle = "Choices";
    }

    newViz.designSettings.segLabelReplacements = {};
    newViz.designSettings.axisLabelReplacements = {};
    newViz.designSettings.graphMax = null;
    newViz.designSettings.graphMin = null;
    newViz.designSettings.split = "";
    newViz.pivotString = "";
    setPivot("");

    updateViz(newViz);
  }

  function trendFlywheel(on, val) {
    let newViz = { ...viz };
    if (on) {
      newViz.pivotString = val;
      setPivot(val);
      newViz.designSettings.graphMax = 14;
    } else {
      newViz.pivotString = "";
      setPivot("");
      newViz.designSettings.graphMax = null;
    }

    updateViz(newViz);
  }

  function couldBeRanking() {
    for (let q of allQs) {
      if (!q.choiceQuestion || !q.choiceQuestion.isRanking) {
        return false;
      }
    }
    return true;
  }

  function toggleAsRanking(val) {
    let newViz = { ...viz };
    newViz.pivotString = "";
    setPivot("");
    newViz.designSettings.split = "";
    setSplit("");

    resetSegLabels(newViz);

    newViz.designSettings.asRanking = val;
    newViz.designSettings.scaleByRank = false;
    newViz.designSettings.hasLegend = val;
    newViz.designSettings.answerType = AnswerCount;
    newViz.designSettings.legendTitle = "Rank";
    newViz.designSettings.hasLegendTitle = true;
    newViz.designSettings.XAxisTitle = "Choices";
    newViz.designSettings.YAxisTitle = "Answer Count";

    updateViz(newViz);
  }

  function toggleScaleByRank(val) {
    let newViz = { ...viz };
    newViz.pivotString = "";
    setPivot("");
    newViz.designSettings.split = "";
    setSplit("");
    newViz.designSettings.YAxisTitle = val ? "Weighted Total" : "Answer Count";
    newViz.designSettings.hasLegend = !val;
    if (!val) {
      newViz.designSettings.legendTitle = "Rank";
      newViz.designSettings.hasLegendTitle = true;
    }

    if (viz.designSettings.option) {
      newViz.designSettings.XAxisTitle =
        viz.designSettings.option + " | " + "Choices";
    } else {
      newViz.designSettings.XAxisTitle = "Choices";
    }
    newViz.designSettings.answerType = AnswerCount;
    newViz.designSettings.scaleByRank = val;

    if (!val && (newViz.type === DoughnutType || newViz.type === PieType)) {
      newViz.type = VertBarType;
    }

    newViz.designSettings.byPercent = !val;
    newViz.designSettings.byPercentOfTotal = false;

    updateViz(newViz);
  }

  function changeRankingAnswerType(option) {
    let newViz = { ...viz };
    newViz.designSettings.answerType = option.value;
    if (newViz.designSettings.option) {
      newViz.designSettings.XAxisTitle =
        newViz.designSettings.option + " | " + option.label;
    } else {
      newViz.designSettings.XAxisTitle = option.label;
    }

    resetSegLabels(newViz);
    updateViz(newViz);
  }

  function toggleStandardScale(val) {
    let newViz = { ...viz };

    newViz.designSettings.standardScale = val;
    if (newViz.designSettings.zeroHundredScale) {
      newViz.designSettings.zeroHundredScale = false;
    }
    newViz.designSettings.byPercent = false;
    updateViz(newViz);
  }

  function toggleZeroHundredScale(val) {
    let newViz = { ...viz };

    newViz.designSettings.zeroHundredScale = val;
    if (newViz.designSettings.standardScale) {
      newViz.designSettings.standardScale = false;
    }
    newViz.designSettings.byPercent = false;
    updateViz(newViz);
  }

  function showBucketSplit() {
    if (pivot) {
      if (getBucketOptions.includes(pivot)) {
        return getBucketOptions.length > 1;
      } else {
        return true;
      }
    }
    return false;
  }

  function toggleFlip(val) {
    let newViz = { ...viz };
    newViz.designSettings.flip = val;
    newViz.designSettings.segLabelReplacements = {};
    newViz.designSettings.axisLabelReplacements = {};
    newViz.designSettings.colors = undefined;
    updateViz(newViz);
  }

  function getMatrixOptions() {
    let all = [];
    for (let q of allQs) {
      if (q.matrixQuestion) {
        for (let option of q.matrixQuestion.options) {
          if (!all.includes(option)) {
            all.push(option);
          }
        }
      }
    }
    return all;
  }

  function changeOption(val) {
    let newViz = { ...viz };
    newViz.designSettings.option = val;
    newViz.designSettings.segLabelReplacements = {};
    newViz.designSettings.axisLabelReplacements = {};
    newViz.designSettings.colors = undefined;

    if (val) {
      newViz.designSettings.hasLegend = newViz.designSettings.split
        ? true
        : false;
    } else {
      newViz.designSettings.hasLegend = true;
      delete newViz.designSettings.includeOverall;
    }

    if (newViz.designSettings.asRanking) {
      newViz.designSettings.scaleByRank = false;
      newViz.designSettings.hasLegend = true;
      newViz.designSettings.answerType = AnswerCount;
      newViz.designSettings.legendTitle = "Rank";
      newViz.designSettings.hasLegendTitle = true;
      newViz.designSettings.XAxisTitle = "Choices";
      newViz.designSettings.YAxisTitle = "Answer Count";
    } else {
      newViz.designSettings.legendTitle = "";
      newViz.designSettings.hasLegendTitle = false;
    }

    if (!val && (newViz.type === DoughnutType || newViz.type === PieType)) {
      newViz.type = VertBarType;
      newViz.designSettings.dataLabelValue.position = "end";
      newViz.designSettings.dataLabelValue.alignment = "end";
      newViz.designSettings.legendPosition = "top";
    }

    newViz.pivotString = "";
    setPivot("");
    newViz.designSettings.split = "";
    setSplit("");

    generateAxisTitles(newViz);

    updateViz(newViz);
  }

  function toggleMatrixScaleByRank(val) {
    let newViz = { ...viz };

    newViz.designSettings.YAxisTitle = val ? "Weighted Total" : "Answer Count";
    newViz.designSettings.matrixScaleByRank = val;

    newViz.designSettings.byPercent = !val;

    updateViz(newViz);
  }

  function splitTextMatrix(val) {
    let newViz = { ...viz };

    newViz.designSettings.hasLegendTitle = val ? true : false;
    newViz.designSettings.legendTitle = val ? capitalize(val) : "Choices";

    newViz.designSettings.XAxisTitle = "Choices";

    newViz.designSettings.split = val;
    setSplit(val);
    newViz.pivotString = "";
    setPivot("");

    updateViz(newViz);
  }

  function pivotTextMatrix(val) {
    let newViz = { ...viz };

    newViz.designSettings.XAxisTitle = val ? capitalize(val) : "Choices";
    newViz.designSettings.hasLegendTitle = false;
    newViz.designSettings.legendTitle = "Choices";

    newViz.designSettings.split = "";
    setSplit("");
    newViz.pivotString = val;
    setPivot(val);

    updateViz(newViz);
  }

  function overallLabelKeyUp(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }

  function showCalculate() {
    if (choiceDoughnut || TextQ) {
      return false;
    }
    if (allQs[0].choiceQuestion) {
      return false;
    }
    if (pivot) {
      return true;
    }
    return false;
  }

  const rankingOptions = [
    {
      label: "Choices",
      value: AnswerCount,
    },
    {
      label: "Ranking",
      value: Ranking,
    },
  ];

  const choiceDoughnut =
    allQs[0].choiceQuestion &&
    (viz.type === DoughnutType || viz.type === PieType);

  const TextQ = allQs[0].textQuestion;

  const isFlywheel = viz.designSettings.answerType === Flywheel;

  const asRanking =
    viz.designSettings.asRanking && !viz.designSettings.scaleByRank;

  const matrix = allQs[0].matrixQuestion;

  return (
    <div className={styles.body} style={{ gap: "10px" }}>
      {isHC.isSuccess && showFlywheel() && (
        <div className={styles.flywheel}>
          <div className={styles.flywheelToggle}>
            <ChartLabel style={{ fontSize: ".7rem" }}>
              Flywheel Score
            </ChartLabel>
            <Checkbox
              checked={viz.designSettings.answerType === Flywheel}
              onChange={(e) => setUpFlywheel(e.target.checked)}
            ></Checkbox>
          </div>

          {isFlywheel && (
            <>
              <FlexCol>
                <ChartLabel>Trend By</ChartLabel>

                <div className={styles.pivots}>
                  {surveyOptions.map((option) => (
                    <div className={`${styles.item}`}>
                      <Checkbox
                        checked={pivot === option.name}
                        onChange={(e) =>
                          trendFlywheel(e.target.checked, option.name)
                        }
                      ></Checkbox>
                      <span>{option.displayName}</span>
                    </div>
                  ))}
                </div>
              </FlexCol>

              <div
                className={styles.setting2}
                // style={{
                //   paddingLeft: "25px",
                // }}
              >
                <ChartLabel>Decimal Digits</ChartLabel>
                <NumberInput
                  startNumber={
                    viz.designSettings?.sigFigs ? viz.designSettings.sigFigs : 0
                  }
                  handleNumberChange={(val) =>
                    changeSettingsField("sigFigs", val)
                  }
                  min={0}
                  max={3}
                ></NumberInput>
              </div>
            </>
          )}
        </div>
      )}

      {!isFlywheel && (
        <>
          {matrix && (
            <>
              {!viz.designSettings.option && !allQs[0].textQuestion && (
                <>
                  <Switch
                    checked={viz.designSettings.flip}
                    onCheck={toggleFlip}
                  >
                    Flip
                  </Switch>

                  {couldBeRanking() && (
                    <>
                      <Switch
                        checked={viz.designSettings.matrixScaleByRank}
                        onCheck={toggleMatrixScaleByRank}
                        tooltip={
                          "Each answer gets scaled by the inverse of its rank"
                        }
                        tipStyle={{ width: "170px" }}
                      >
                        Weight Answers By Rank
                      </Switch>

                      {viz.designSettings.matrixScaleByRank && (
                        <FlexCol gap="10px" style={{ paddingLeft: "20px" }}>
                          <Check
                            checked={viz.designSettings.standardScale}
                            onCheck={toggleStandardScale}
                            tooltip={
                              "Scaled from 1 to n (n being the number of choices - or the ranking limit)"
                            }
                            tipStyle={{ width: "200px", right: "-75px" }}
                          >
                            Standard Scale
                          </Check>

                          <Check
                            checked={viz.designSettings.zeroHundredScale}
                            onCheck={toggleZeroHundredScale}
                            tooltip="Scaled from 0 - 100. (If everyone ranked it as their first choice, the score would be 100"
                            tipStyle={{ width: "225px", right: "-100px" }}
                          >
                            0-100 Scale
                          </Check>
                        </FlexCol>
                      )}
                    </>
                  )}
                </>
              )}

              <FlexCol>
                <ChartLabel big>Focus On</ChartLabel>
                <div className={styles.pivots}>
                  {getMatrixOptions().map((option) => (
                    <div className={`${styles.item} `}>
                      <Checkbox
                        checked={viz.designSettings.option === option}
                        onChange={(e) =>
                          changeOption(e.target.checked ? option : "")
                        }
                      ></Checkbox>
                      <span>{option}</span>
                    </div>
                  ))}
                </div>
              </FlexCol>

              {allQs[0].textQuestion && !viz.designSettings.option && (
                <>
                  <FlexCol>
                    <ChartLabel big>
                      {`Pivot (${
                        viz.type === HorizBarType ? "y" : "x"
                      } axis) by`}
                    </ChartLabel>
                    <div className={styles.pivots}>
                      {options.map((field) => (
                        <div className={`${styles.item} `}>
                          <Checkbox
                            checked={pivot === field.name}
                            onChange={(e) =>
                              pivotTextMatrix(
                                e.target.checked ? field.name : ""
                              )
                            }
                          ></Checkbox>
                          <span>{field.displayName}</span>
                        </div>
                      ))}
                    </div>
                  </FlexCol>
                  <FlexCol>
                    <ChartLabel big>{"Split By"}</ChartLabel>
                    <div className={styles.pivots}>
                      {options.map((field) => (
                        <div className={`${styles.item} `}>
                          <Checkbox
                            checked={split === field.name}
                            onChange={(e) =>
                              splitTextMatrix(
                                e.target.checked ? field.name : ""
                              )
                            }
                          ></Checkbox>
                          <span>{field.displayName}</span>
                        </div>
                      ))}
                    </div>
                  </FlexCol>
                </>
              )}
            </>
          )}
          {(!matrix || viz.designSettings.option) && (
            <>
              {couldBeRanking() && (
                <FlexCol gap={"5px"}>
                  <ChartLabel>Treat as:</ChartLabel>
                  <OneOrTheOther
                    left="Ranking"
                    right="Multiple Choice"
                    activeColor={"#738c91"}
                    checked={!viz.designSettings.asRanking}
                    onCheck={(val) => toggleAsRanking(!val)}
                    style={{ paddingLeft: "5px", fontSize: ".75rem" }}
                  ></OneOrTheOther>
                </FlexCol>
              )}

              {viz.designSettings.asRanking && (
                <>
                  <Switch
                    checked={viz.designSettings.scaleByRank}
                    onCheck={toggleScaleByRank}
                    tooltip={
                      "Each answer gets scaled by the inverse of its rank"
                    }
                    tipStyle={{ width: "170px" }}
                  >
                    Weight Answers By Rank
                  </Switch>

                  {viz.designSettings.scaleByRank && (
                    <FlexCol gap="10px" style={{ paddingLeft: "20px" }}>
                      <Check
                        checked={viz.designSettings.standardScale}
                        onCheck={toggleStandardScale}
                        tooltip={
                          "Scaled from 1 to n (n being the number of choices - or the ranking limit)"
                        }
                        tipStyle={{ width: "200px", right: "-75px" }}
                      >
                        Standard Scale
                      </Check>

                      <Check
                        checked={viz.designSettings.zeroHundredScale}
                        onCheck={toggleZeroHundredScale}
                        tooltip="Scaled from 0 - 100. (If everyone ranked it as their first choice, the score would be 100"
                        tipStyle={{ width: "225px", right: "-100px" }}
                      >
                        0-100 Scale
                      </Check>
                    </FlexCol>
                  )}

                  {!viz.designSettings.scaleByRank && (
                    <div
                      className={`${styles.answerTypesContainer} ${styles.optionsActive} `}
                      style={{ flexDirection: "row", gap: "10px" }}
                    >
                      <ChartLabel>
                        {`${viz.type === HorizBarType ? "Y" : "X"} axis by:`}
                      </ChartLabel>
                      <div
                        className={styles.answerTypeOptions}
                        style={{ flexGrow: "1" }}
                      >
                        {rankingOptions.map((option) => (
                          <div className={styles.pivotRadio}>
                            {option.label}
                            <input
                              id={option.label}
                              type="radio"
                              name="answerType"
                              value={option.value}
                              onChange={() => changeRankingAnswerType(option)}
                              checked={
                                viz.designSettings.answerType === option.value
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}

              {!asRanking && (
                <>
                  <FlexCol
                    className={HorizDisabled ? styles.horizDisabled : ""}
                  >
                    <ChartLabel big>
                      {choiceDoughnut || TextQ
                        ? "Show Participation Count By"
                        : `Pivot (${
                            viz.type === HorizBarType ? "y" : "x"
                          } axis) by`}
                    </ChartLabel>
                    <div className={styles.pivots}>
                      {options.map((field) => (
                        <div
                          className={`${styles.item} ${
                            field.name === split ? styles.disabled : ""
                          }`}
                        >
                          <Checkbox
                            checked={pivot === field.name}
                            onChange={(e) =>
                              changePivot(e.target.checked ? field.name : "")
                            }
                            disabled={HorizDisabled || field.name === split}
                          ></Checkbox>
                          <span>{field.displayName}</span>
                        </div>
                      ))}

                      {couldBeNPS() && (
                        <div
                          className={`${styles.item} ${
                            "nps" === split ? styles.disabled : ""
                          }`}
                        >
                          <Checkbox
                            checked={pivot === "nps"}
                            onChange={(e) =>
                              changePivot(e.target.checked ? "nps" : "")
                            }
                            disabled={"nps" === split}
                          ></Checkbox>
                          <span>passive, promoter, detractor</span>
                        </div>
                      )}
                      {TextQ &&
                        getBucketOptions().map((field) => (
                          <div
                            className={`${styles.item} ${
                              field === split ? styles.disabled : ""
                            }`}
                          >
                            <Checkbox
                              checked={pivot === field}
                              onChange={(e) =>
                                bucketPivot(e.target.checked ? field : "")
                              }
                              disabled={field === split}
                            ></Checkbox>
                            <span>{field}</span>
                          </div>
                        ))}
                    </div>
                  </FlexCol>

                  {showCalculate() && (
                    <div
                      className={`${styles.answerTypesContainer} ${
                        VertDisabled ? "" : styles.optionsActive
                      }`}
                    >
                      <ChartLabel big>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "3px",
                          }}
                        >
                          Calculate
                          {!pivot && (
                            <span
                              style={{
                                color: "#a3a4a8",
                                fontSize: ".7em",
                                paddingLeft: "5px",
                                fontWeight: "200",
                              }}
                            >
                              {"*needs pivot"}
                            </span>
                          )}
                        </div>
                      </ChartLabel>
                      <div className={styles.answerTypeOptions}>
                        {getVertOptions().map((option) => (
                          <div className={styles.pivotRadio}>
                            {option.label}
                            <input
                              id={option.label}
                              type="radio"
                              name="answerType"
                              value={option.value}
                              disabled={VertDisabled}
                              onChange={() => handleCalculateChange(option)}
                              checked={
                                VertDisabled
                                  ? false
                                  : viz.designSettings.answerType ===
                                    option.value
                              }
                              className={VertDisabled ? styles.inActive : null}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {viz.type !== DoughnutType &&
                    viz.type !== PieType &&
                    (TextQ ? pivot : true) && (
                      <>
                        {pivot && split && allQs[0].scaleQuestion && (
                          <div
                            className={styles.item}
                            style={{
                              paddingLeft: "10px",
                            }}
                          >
                            <Checkbox
                              checked={viz.designSettings.includeOverall}
                              onChange={(e) =>
                                changeSettingsField(
                                  "includeOverall",
                                  e.target.checked
                                )
                              }
                              // color={"#7fcfd3"}
                            />
                            <div className={styles.includeOverallLabel}>
                              Include{" "}
                              {viz.designSettings.includeOverall ? (
                                <input
                                  type="text"
                                  className={styles.overallLabelInput}
                                  value={overall}
                                  onBlur={() =>
                                    changeSettingsField("overallLabel", overall)
                                  }
                                  onChange={(e) => setOverall(e.target.value)}
                                  onKeyUp={overallLabelKeyUp}
                                  placeholder="Overall"
                                ></input>
                              ) : (
                                <span>
                                  {viz.designSettings.overallLabel
                                    ? viz.designSettings.overallLabel
                                    : "Overall"}{" "}
                                </span>
                              )}
                            </div>
                          </div>
                        )}

                        <FlexCol>
                          <ChartLabel big>Split by</ChartLabel>

                          <div className={styles.pivots}>
                            {isMCPivot() && (
                              <div className={styles.item}>
                                <Checkbox
                                  checked={split === "choices"}
                                  onChange={(e) => splitByChoices(e)}
                                ></Checkbox>
                                <span>Choices</span>
                              </div>
                            )}

                            {!isMCPivot() &&
                              options.map((field) => (
                                <div
                                  className={`${styles.item} ${
                                    field.name === pivot ? styles.disabled : ""
                                  }`}
                                >
                                  <Checkbox
                                    checked={split === field.name}
                                    onChange={(e) => changeSplit(e, field.name)}
                                    disabled={field.name === pivot}
                                  ></Checkbox>
                                  <span>{field.displayName}</span>
                                </div>
                              ))}

                            {couldBeNPS() && pivot && (
                              <div
                                className={`${styles.item} ${
                                  "nps" === pivot ? styles.disabled : ""
                                }`}
                              >
                                <Checkbox
                                  checked={split === "nps"}
                                  onChange={(e) => changeSplit(e, "nps")}
                                  disabled={"nps" === pivot}
                                ></Checkbox>
                                <span>passive, promoter, detractor</span>
                              </div>
                            )}
                            {TextQ &&
                              getBucketOptions().map((field) => (
                                <div
                                  className={`${styles.item} ${
                                    field === pivot ? styles.disabled : ""
                                  }`}
                                >
                                  <Checkbox
                                    checked={split === field}
                                    onChange={(e) =>
                                      bucketSplit(e.target.checked ? field : "")
                                    }
                                    disabled={field === pivot}
                                  ></Checkbox>
                                  <span>{field}</span>
                                </div>
                              ))}
                          </div>
                        </FlexCol>
                      </>
                    )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export function generateAxisTitles(newViz) {
  let pivot = newViz.pivotString;

  if (pivot) {
    if (pivot === "nps") {
      newViz.designSettings.XAxisTitle = "";
      newViz.designSettings.YAxisTitle = "Count";
      return;
    } else if (pivot === "survey date") {
      newViz.designSettings.XAxisTitle = "Survey Date";
    } else if (pivot === "survey quarter") {
      newViz.designSettings.XAxisTitle = "Quarter";
    } else if (pivot === "month taken") {
      newViz.designSettings.XAxisTitle = "Month";
    } else if (pivot === "hour taken") {
      newViz.designSettings.XAxisTitle = "Day";
    } else {
      newViz.designSettings.XAxisTitle =
        pivot.charAt(0).toUpperCase() + pivot.slice(1);
    }

    let answerType = newViz.designSettings.answerType;

    if (newViz.designSettings.split === "nps") {
      newViz.designSettings.YAxisTitle = "Count";
    } else if (answerType === ParticipationCount) {
      newViz.designSettings.YAxisTitle = "Participation Count";
    } else if (
      newViz.designSettings.asRanking &&
      newViz.designSettings.scaleByRank
    ) {
      newViz.designSettings.YAxisTitle = "Weighted Total";
    } else if (answerType === AnswerCount) {
      newViz.designSettings.YAxisTitle = "Answer Count";
    } else if (answerType === AvgScore) {
      newViz.designSettings.YAxisTitle = "Average Score";
    } else if (answerType === NpsScore) {
      newViz.designSettings.YAxisTitle = "NPS";
    }
  } else {
    newViz.designSettings.XAxisTitle = combinedQs(newViz)[0].textQuestion
      ? "Responses"
      : "Choices";

    if (newViz.designSettings.scaleByRank) {
      newViz.designSettings.YAxisTitle = "Weighted Total";
    } else {
      newViz.designSettings.YAxisTitle = "Answer Count";
    }
  }
  if (newViz.designSettings.option) {
    newViz.designSettings.XAxisTitle =
      newViz.designSettings.option + " | " + newViz.designSettings.XAxisTitle;
  }
}
