// External
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Internal
import styles from "./TabPillItem.module.scss";
import { HorizontalBar } from "../HorizontalBar/HorizontalBar";

export const TabPillItem = ({
  id,
  label,
  to,
  isActive,
  makeActive,
  icon,
  wings,
  beforeNavAway,
  setBeforeNavAway,
  showNumber,
}) => {
  function overflow(label) {
    let result;
    label.length > 9 ? (result = true) : (result = false);
    return result;
  }
  const [display, setDisplay] = useState();

  const navigate = useNavigate();

  function onClick() {
    if (!isActive) {
      if (beforeNavAway) {
        let displayItem = beforeNavAway.getDisplay(() => {
          setBeforeNavAway(undefined);
          setDisplay(undefined);
          makeActive(id);
          if (to) {
            navigate(to);
          }
        });
        setDisplay(displayItem);
      } else {
        makeActive(id);
        if (to) {
          navigate(to);
        }
      }
    }
  }

  return (
    <>
      <li>
        <div
          className={`${styles.tabBarItem} ${
            isActive && wings && styles.activeItemWings
          } ${isActive && !wings && styles.activeItem} ${
            overflow(label) && styles.overflow
          }`}
          onClick={() => onClick()}
        >
          <div className={styles.tab_head}>
            {icon && <i className={`bi-${icon}`}></i>}
            {window.innerWidth >= 700
              ? label
              : window.innerWidth < 700 && icon
              ? ""
              : label}
            {isActive && showNumber && <span className={styles.count}>{showNumber}</span>}
          </div>
          {isActive && wings && <div className={styles.tab_head_bottom}></div>}
          {isActive && wings && <div className={styles.tab_head_bottom2}></div>}
        </div>
      </li>
      {display && (
        <div style={{ position: "absolute", zIndex: "400" }}>{display}</div>
      )}
    </>
  );
};

// // Externals
// import React from "react";
// import { Link } from "react-router-dom";

// // Internals
// import styles from "./TabPillItem.module.scss";

// export const TabPillItem = ({ id, label, to, isActive, makeActive }) => {
//   return (
//     <li>
//       <Link to={to}>
//         <div
//           className={`${styles.tabBarItem} ${isActive && styles.activeItem}`}
//           onClick={() => makeActive(id)}
//         >
//           {label}
//         </div>
//       </Link>
//     </li>
//   );
// };
