import styles from "./Fonts.module.scss";
import { useState, useRef } from "react";

export default function Font({ value, onChange }) {
  const [show, setShow] = useState(false);

  const ref = useRef(null);

  const fonts = [
    { label: "Arial", value: "Arial, sans-serif" },
    { label: "Brush Script MT", value: '"Brush Script MT", cursive' },
    // { label: "Calibri", value: "Calibri, sans-serif" },
    { label: "Century Gothic", value: '"Century Gothic", sans-serif' },
    { label: "Courier New", value: '"Courier New", monospace' },
    { label: "Comic Sans MS", value: '"Comic Sans MS", sans-serif' },
    { label: "Garamond", value: "Garamond, serif" },
    { label: "Georgia", value: "Georgia, serif" },
    { label: "Helvetica", value: "Helvetica, sans-serif" },
    { label: "Impact", value: "Impact, sans-serif" },
    { label: "Lucida Console", value: '"Lucida Console", monospace' },
    { label: "Montserrat", value: "Montserrat, sans-serif" },
    { label: "Palatino Linotype", value: '"Palatino Linotype", serif' },
    { label: "Poppins", value: "Poppins, sans-serif" },
    { label: "PT Sans", value: '"PT Sans", sans-serif' },
    { label: "Questrial", value: "Questrial, sans-serif" },
    { label: "Tahoma", value: "Tahoma, sans-serif" },
    { label: "Times New Roman", value: '"Times New Roman", serif' },
    { label: "Trebuchet", value: "Trebuchet, sans-serif" },
    { label: "Verdana", value: "Verdana, sans-serif" },
  ];

  function onClick(e) {
    if (!ref.current || !ref.current.contains(e.target)) {
      document.removeEventListener("click", onClick, true);
      setShow(false);
    }
  }

  function setUp() {
    setShow(true);
    setTimeout(() => document.addEventListener("click", onClick, true), 300);
  }

  function checkForEnter(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }

  function handleCustom(e) {
    let fontName = e.target.value;
    if (fontName) {
      onPick(fontName);
    }
    e.stopPropagation();
  }

  function onPick(val) {
    setShow(false);
    onChange(val);
  }

  const chosen = fonts.find((f) => f.value === value);

  return (
    <div className={styles.fontSelection} ref={ref}>
      <div className={styles.fontButton} onClick={setUp}>
        {value ? (
          <span style={{ fontFamily: value }}>
            {chosen ? chosen.label : value}
          </span>
        ) : (
          <>Fonts</>
        )}{" "}
        <i className="bi bi-chevron-down"></i>
      </div>

      {show && (
        <div className={styles.fontMenu}>
          <input
            className={styles.customFont}
            type="text"
            placeholder="Type Font Name"
            onKeyDown={checkForEnter}
            onBlur={handleCustom}
          ></input>
          {value && !chosen && (
            <div className={styles.fontOption} style={{ fontFamily: value }}>
              {value}
              <i className={`bi bi-check ${styles.check}`}></i>
            </div>
          )}

          {fonts.map((f) => (
            <div
              className={styles.fontOption}
              style={{ fontFamily: f.value }}
              onClick={() => onPick(f.value)}
            >
              {f.label}
              {chosen && chosen.value === f.value && (
                <i className={`bi bi-check ${styles.check}`}></i>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
