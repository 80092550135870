import React, { useState, useRef } from "react";
import { SketchPicker } from "react-color";
import { Popover } from "@mui/material";
import styles from "./GradientColorPicker.module.scss";
import { ChartColors } from "assets/functions/ColorFunctions";

/**
 * A color picker component
 * @param {Array} colors something
 * @param {String} defaultColor something
 * @param {Function} onChange a function that is called when a color is chosen
 * @returns {React.ReactElement} a simple color picker component
 */

export const GradientColorPicker = ({
  color,
  orig,
  addAnchor,
  removeAnchor,
  start,
  end,
  anchor,
  onApply,
  onCancel,
  disable,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef();

  const handleClose = () => {
    setShowPicker(false);
    onCancel();
  };

  const handleColorChange = (color) => {
    addAnchor(color.hex);
  };

  const handleApply = () => {
    setShowPicker(false);
    onApply();
  };

  return (
    <div style={{ cursor: disable ? "" : "pointer" }}>
      <div
        ref={pickerRef}
        style={{
          backgroundColor: color,
        }}
        className={`${styles.swatch}`}
        onClick={() => {
          if (!disable) {
            setShowPicker(true);
          }
        }}
      ></div>
      <Popover
        open={showPicker}
        anchorEl={pickerRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: { backgroundColor: "transparent", boxShadow: "none" },
        }}
      >
        <div className={styles.sketchPicker}>
          <SketchPicker
            color={color}
            presetColors={ChartColors}
            onChangeComplete={handleColorChange}
          />

          <div className={styles.options}>
            {orig !== color && (
              <>
                <div className={styles.blueButton} onClick={handleApply}>
                  Apply <i className="bi bi-paint-bucket"></i>
                </div>

                <div className={styles.button} onClick={onCancel}>
                  Revert <i className="bi bi-x"></i>
                </div>
              </>
            )}
            {orig === color && anchor && !start && !end && (
              <div className={styles.button} onClick={removeAnchor}>
                Remove From Gradient
              </div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};
