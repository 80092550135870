import React, { useState } from "react";
import styles from "./PlanInput.module.scss";
import CombinedInput from "../CombinedInput/CombinedInput";

function PlanInput({ onChange, org, editing, roles, canEdit }) {
  const planoptions = [
    { value: "Liscense", label: "Liscense" },
    { value: "Subscription", label: "Subscription" },
  ];

  const [isLiscense, setIsLiscense] = useState(org?.isLiscense);
  return (
    <div className={styles.addy}>
      <div className={styles.addy2}>
        <span>Plan Type</span>
        {!editing && <span>{org.isLiscense ? "Liscense" : "Subscription"}</span>}
        {editing && (
          <CombinedInput
            value={org.isLiscense ? "Liscense" : "Subscription"}
            select
            options={planoptions}
            style={{ maxWidth: "200px" }}
            icon={"bi-chevron-down"}
            onChange={(val) => {
              onChange(val.value === "Liscense" ? true : false, "isLiscense");
              setIsLiscense(val.value === "Liscense" ? true : false);
            }}
          ></CombinedInput>
        )}
      </div>
      <div className={styles.addy2}>
        <span>
          {isLiscense ? "Liscense" : "Subscription"} Duration{" "}
          {editing ? "(In Months)" : ""}
        </span>
        {isLiscense && !editing && <span>{org.planFrequency / 12} year</span>}
        {!isLiscense && !editing && <span>Monthly</span>}

        {editing && (
          <CombinedInput
            value={org.planFrequency}
            style={{ maxWidth: "200px", padding: "0rem" }}
            inputStyle={{ padding: ".5rem" }}
            onChange={(val) => {
              onChange(parseInt(val), "planFrequency");
            }}
            type="number"
          ></CombinedInput>
        )}
      </div>
      {canEdit && (
        <>
          <div className={styles.addy2}>
            <span>{isLiscense ? "Liscense" : "Subscription"} Price</span>
            {!editing && <span>{org.planPrice}</span>}

            {editing && (
              <CombinedInput
                value={org.planPrice}
                style={{ maxWidth: "200px", padding: "0rem" }}
                inputStyle={{ padding: ".5rem" }}
                onChange={(val) => {
                  onChange(val, "planPrice");
                }}
              ></CombinedInput>
            )}
          </div>
        </>
      )}

      <div className={styles.addy2}>
        <span>Admin Users</span>
        {!editing && (
          <span>
            {org.adminsAllowed === undefined || org.adminsAllowed === null
              ? "Unlimited"
              : org.adminsAllowed}
          </span>
        )}

        {editing && (
          <CombinedInput
            value={org.adminsAllowed}
            style={{ maxWidth: "200px", padding: "0rem" }}
            inputStyle={{ padding: ".5rem" }}
            onChange={(val) => {
              onChange(parseInt(val), "adminsAllowed");
            }}
            type="number"
          ></CombinedInput>
        )}
      </div>
      <div className={styles.addy2}>
        <span>Other Users</span>
        {!editing && (
          <span>
            {org.usersAllowed === undefined || org.usersAllowed === null
              ? "Unlimited"
              : org.usersAllowed}
          </span>
        )}

        {editing && (
          <CombinedInput
            value={org.usersAllowed}
            style={{ maxWidth: "200px", padding: "0rem" }}
            inputStyle={{ padding: ".5rem" }}
            onChange={(val) => {
              onChange(parseInt(val), "usersAllowed");
            }}
            type="number"
          ></CombinedInput>
        )}
      </div>
      <div className={styles.addy2}>
        <span>Projects</span>
        {!editing && (
          <span>
            {org.surveysAllowed === undefined || org.surveysAllowed === null
              ? "Unlimited"
              : org.surveysAllowed}
          </span>
        )}
        {editing && (
          <CombinedInput
            value={org.surveysAllowed}
            style={{ maxWidth: "200px", padding: "0rem" }}
            inputStyle={{ padding: ".5rem" }}
            onChange={(val) => {
              onChange(parseInt(val), "surveysAllowed");
            }}
            type="number"
          ></CombinedInput>
        )}
      </div>
      <div className={styles.addy2}>
        <span>Responses per Survey</span>
        {!editing && (
          <span>
            {org.responsesAllowed === undefined || org.responsesAllowed === null
              ? "Unlimited"
              : org.responsesAllowed}
          </span>
        )}
        {editing && (
          <CombinedInput
            value={org.responsesAllowed}
            style={{ maxWidth: "200px", padding: "0rem" }}
            inputStyle={{ padding: ".5rem" }}
            onChange={(val) => {
              onChange(parseInt(val), "responsesAllowed");
            }}
            type="number"
          ></CombinedInput>
        )}
      </div>
      <div className={styles.addy2}>
        <span>Contacts</span>
        {!editing && (
          <span>
            {org.contactsAllowed === undefined || org.contactsAllowed === null
              ? "Unlimited"
              : org.contactsAllowed}
          </span>
        )}
        {editing && (
          <CombinedInput
            value={org.contactsAllowed}
            style={{ maxWidth: "200px", padding: "0rem" }}
            inputStyle={{ padding: ".5rem" }}
            onChange={(val) => {
              onChange(parseInt(val), "contactsAllowed");
            }}
            type="number"
          ></CombinedInput>
        )}
      </div>
      <div className={styles.addy2}>
        <span>Analysis Reports</span>
        {!editing && (
          <span>
            {org.reportsAllowed === undefined || org.reportsAllowed === null
              ? "Unlimited"
              : org.reportsAllowed}
          </span>
        )}
        {editing && (
          <CombinedInput
            value={org.reportsAllowed}
            style={{ maxWidth: "200px", padding: "0rem" }}
            inputStyle={{ padding: ".5rem" }}
            onChange={(val) => {
              onChange(parseInt(val), "reportsAllowed");
            }}
            type="number"
          ></CombinedInput>
        )}
      </div>
    </div>
  );
}

export default PlanInput;
