import Modal from "components/ReactModal/ReactModal";
import Triggers from "components/QuestionConstruction/Triggers/Triggers";

function TriggersModal({
  saveQuestion,
  question,
  showTriggers,
  setShowTriggers,
}) {
  return (
    <Modal
      show={showTriggers}
      onClose={() => setShowTriggers(false)}
      modalStyle={{
        width: "fit-content",
        padding: "1rem",
        borderRadius: "1rem",
        overflow: "visible",
      }}
      dark
    >
      {showTriggers && (
        <Triggers question={question} saveQuestion={saveQuestion} />
      )}{" "}
    </Modal>
  );
}

export default TriggersModal;
