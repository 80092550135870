import React, { useEffect, useRef, useState } from "react";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import styles from "./ContactsSettings.module.scss";
import Button from "components/Button/Button";
import {
  useCheckContactsConnectedSalesforce,
  useGetContactsByUnsubscribed,
} from "api/resources/contacts/contacts";
import { CSVLink } from "react-csv";
import {
  useFetchCurrOrgsFields,
  useFetchOrganizationLinkedAccounts,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import salesforce from "assets/images/Salesforce.png";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";

function ContactsSettings({ show, setShow, user, organizationId }) {
  const [data, setData] = useState([]);
  const [download, setDownload] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [headersExport, setHeadersExport] = useState([]);
  const csvref = useRef(null);
  const getContacts = useGetContactsByUnsubscribed();
  const fetchCustomFields = useFetchCurrOrgsFields();
  const MyOrg = useFetchOrganizationLinkedAccounts(organizationId);

  // function objectsToCSV(arr) {
  //   const array = [Object.keys(arr[0])].concat(arr);
  //   return array
  //     .map((row) => {
  //       return Object.values(row)
  //         .map((value) => {
  //           return typeof value === "string" ? JSON.stringify(value) : value;
  //         })
  //         .toString();
  //     })
  //     .join("\n");
  // }

  function exportContacts() {
    setDownloading(true);
    getContacts.mutate(
      {},
      {
        onSuccess: (data) => {
          let result = data?.getContactsByUnsubscribed;
          let headers = [
            {
              label: "id",
              key: "id",
            },

            {
              label: "First Name",
              key: "firstName",
            },
            {
              label: "Last Name",
              key: "lastName",
            },
            {
              label: "Email",
              key: "email",
            },
            {
              label: "unsubscribed",
              key: "unsubscribed",
            },
            {
              label: "unsubscribedAt",
              key: "unsubscribedAt",
            },
          ];

          let fields = fetchCustomFields.data.getCurrOrgFields;
          for (let field of fields) {
            headers.push({ label: field?.name, key: field?.name });
          }
          setHeadersExport(headers);
          // let finalDownload = [];
          for (let i = 0; i < result.length; i++) {
            if (result[i]?.customField) {
              let custom = JSON.parse(result[i]?.customField);
              while (typeof custom === "string") {
                custom = JSON.parse(custom);
              }

              delete result[i].customField;
              result[i] = { ...result[i], ...custom };
            }
          }

          setDownload(true);
          setData(result);

          // let csvContent =
          //   "data:text/csv;charset=utf-8," + objectsToCSV(result);

          // var encodedUri = encodeURI(csvContent);
          // var link = document.createElement("a");
          // link.setAttribute("href", encodedUri);
          // link.setAttribute("download", "unsubscribed_contacts.csv");
          // document.body.appendChild(link); // Required for FF

          // link.click();
        },
      }
    );
  }

  useEffect(() => {
    if (download) {
      csvref.current.click();
      setDownload(false);
      setDownloading(false);
    }
  }, [data]);

  return (
    <ReactModal
      show={show}
      onClose={() => setShow(false)}
      modalStyle={{
        padding: "1rem",
        gap: "1.5em",
        height: "100%",
        width: "500px",
      }}
      dark
      rightStyle
    >
      <div className={styles.header}>Contacts Options</div>

      <Button
        style={{ gap: "1em", width: "fit-content" }}
        shadow
        onClick={() => (!downloading ? exportContacts() : console.log("Nope!"))}
      >
        {!downloading && (
          <>
            <i className="bi bi-cloud-download"></i> Export Unsubscribes
          </>
        )}{" "}
        {downloading && (
          <>
            Creating Export <Loading height={20} width={20}></Loading>
          </>
        )}
        <CSVLink
          data={data ? data : []}
          headers={headersExport}
          filename={"personallinks.csv"}
          target="_blank"
        >
          <button
            ref={csvref}
            style={{
              border: "none",
              opacity: "0",
              maxWidth: "0px",
              height: "0px",
            }}
          ></button>
        </CSVLink>
      </Button>

      {MyOrg.isSuccess &&
        MyOrg.data.organization?.linkedAccounts?.length > 0 &&
        MyOrg.data.organization?.linkedAccounts?.map((account, i) => (
          <div className={styles.setting} key={i}>
            {account.accountType === "Salesforce" && (
              <FlexRow style={{ width: "fit-content" }} gap=".5rem">
                {" "}
                <img src={salesforce} width={30}></img>
                <span className={styles.header_4}>Salesforce</span>
              </FlexRow>
            )}
            {account.accountType === "Salesforce" && (
              <CheckSyncContacts organizationId={organizationId} />
            )}
            {/* <FlexRow style={{ width: "fit-content", paddingLeft: "1rem" }}>
              <ToggleSwitch></ToggleSwitch>
              <span>Sync Contacts with Salesforce</span>
            </FlexRow> */}
          </div>
        ))}
    </ReactModal>
  );
}

export default ContactsSettings;

export function CheckSyncContacts({ organizationId }) {
  const checkContacts = useCheckContactsConnectedSalesforce(organizationId);
  return (
    <>
      {checkContacts.isSuccess && checkContacts?.data?.count > 0 && (
        <>{checkContacts?.data?.count}</>
      )}
    </>
  );
}
