import {
  MultChoice,
  Scale,
  Text,
} from "pages/results/Charts/Settings/AccordianItems/SurveyData/QData";
import styles from "./Matrix.module.scss";
import { useState, useEffect, useLayoutEffect } from "react";
import TextEditorPlain from "components/inputs/input_fields/TextEditorPlain/TextEditorPlain";
import { ChoiceMatrixOption } from "./Choice/ChoiceMatrixOption";
import { ScaleMatrixOption } from "./Scale/ScaleMatrixOption";
import { SliderMatrixOption } from "./Slider/SliderMatrixOption";

export default function Matrix({
  question,
  active,
  saveQuestion,
  designSettings,
  onAnswer,
  onRetract,
  answerMap,
  dependents,
  setUpLogicResolver,
  disable,
}) {
  const matrix = question.matrixQuestion;

  const [dragging, setDragging] = useState(false);

  const [initialX, setInitialX] = useState(0);
  const [immediateWidth, setImmediateWidth] = useState();
  const [tableWidth, setTableWidth] = useState();
  const [tableHeight, setTableHeight] = useState();

  function getHeight() {
    let table = document.getElementById(question.id + "tableB"); // B for builder - so its different than the preview
    if (table) {
      return table.clientHeight + "px";
    } else {
      return "200px";
    }
  }

  const onMouseDown = (e) => {
    let topLeft = document.getElementById(question.id + "topLeftB");
    let table = document.getElementById(question.id + "tableB");

    if (topLeft && table) {
      setImmediateWidth(topLeft.clientWidth);
      setDragging(true);
      setInitialX(e.clientX);
      setTableWidth(table.clientWidth);
      setTableHeight(table.clientHeight);
    }

    if (question.choiceQuestion) {
      let columns = settings.transpose
        ? matrix.options
        : question.choiceQuestion.choices;
      for (let i = 0; i < columns.length; i++) {
        let col = document.getElementById(question.id + "choiceB" + i);
        if (col) {
          col.style.width = "";
        }
      }
    }
  };

  const onDrag = (e) => {
    if (!dragging) return;
    let delta = e.clientX - initialX;

    if (
      immediateWidth + delta >
      50

      // &&
      // immediateWidth + delta < tableWidth / 2
    ) {
      setImmediateWidth((old) => old + delta);
      setInitialX(e.clientX);
    }
  };

  function doneDragging() {
    if (!dragging) return;

    setDragging(false);
    setInitialX(0);
    setImmediateWidth(null);
    setTableHeight(0);
    setTableWidth(0);

    console.log("should be: ", immediateWidth);

    question.matrixQuestion.settings.width = immediateWidth + "px";
    saveQuestion(question);
  }

  function addChoiceToEnd(choice) {
    if (choice) {
      question.choiceQuestion.choices.push(choice);
      saveQuestion(question);
    }
  }

  function editChoice(val, ind) {
    if (dependents) {
      if (question.choiceQuestion.choices[ind] !== val) {
        // check in dependents
        let current = question.choiceQuestion.choices[ind];
        for (let dependent of dependents.dependencies) {
          if (dependent?.keep && dependent.keep.includes(current)) {
            setUpLogicResolver("change this choice on", "edit", question, () =>
              finishEditChoice(val, ind)
            );

            let old = question.choiceQuestion.choices[ind];
            setTimeout(() => {
              question.choiceQuestion.choices[ind] = old;
              saveQuestion(question); // Trigger a re-render on the input
            }, 500);
            question.choiceQuestion.choices[ind] = val;
            saveQuestion(question);

            return;
          }
        }
      }
    }

    finishEditChoice(val, ind);
  }

  function finishEditChoice(val, ind) {
    if (val) {
      question.choiceQuestion.choices[ind] = val;
      saveQuestion(question);
    } else {
      question.choiceQuestion.choices.splice(ind, 1);
      saveQuestion(question);
    }
  }

  function onChoiceTab(e, i) {
    e.target.blur();

    if (i === question.choiceQuestion.choices.length - 1) {
      let input = document.getElementById("new input for choice");
      if (input) {
        input.focus();
      }
    } else {
      let id = question.id + "choiceInput" + (i + 1);
      let input = document.getElementById(id);
      if (input) {
        let target = input;
        let targetOffset = question.choiceQuestion.choices[i + 1].length;
        while (target.childNodes.length) {
          target = target.childNodes[0];
        }
        if (target.nodeName === "#text") {
          const newRange = document.createRange();
          newRange.setStart(target, targetOffset);
          newRange.collapse(true);

          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(newRange);
        } else {
          input.focus();
        }
      }
    }
  }

  function onBrandNewSwitchToNext(e) {
    e.target.blur();
    let id = e.target.id;
    setTimeout(() => {
      let input = document.getElementById(id);
      if (input) {
        input.focus();
      }
    }, 100);
  }

  function onOptionEnter(e, i) {
    e.target.blur();

    if (i === question.matrixQuestion.options.length - 1) {
      let input = document.getElementById("new input for option");
      if (input) {
        input.focus();
      }
    } else {
      let id = question.id + "optionInput" + (i + 1);
      let input = document.getElementById(id);
      if (input) {
        let target = input;
        let targetOffset = question.matrixQuestion.options[i + 1].length;
        while (target.childNodes.length) {
          target = target.childNodes[0];
        }
        if (target.nodeName === "#text") {
          const newRange = document.createRange();
          newRange.setStart(target, targetOffset);
          newRange.collapse(true);

          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(newRange);
        } else {
          input.focus();
        }
      }
    }
  }

  function addOption(val) {
    if (val) {
      question.matrixQuestion.options.push(val);
      saveQuestion(question);
    }
  }

  function editOption(val, i) {
    if (dependents) {
      if (matrix.options[i] !== val) {
        // check in dependents
        let current = matrix.options[i];
        for (let dependent of dependents.dependencies) {
          if (
            dependent?.keepOptions &&
            dependent.keepOptions.includes(current)
          ) {
            setUpLogicResolver("change this option on", "edit", question, () =>
              finishEditOption(val, i)
            );

            let old = matrix.options[i];
            setTimeout(() => {
              question.matrixQuestion.options[i] = old;
              saveQuestion(question); // Trigger a re-render on the input
            }, 500);
            question.matrixQuestion.options[i] = val;
            saveQuestion(question);

            return;
          }
        }
      }
    }

    finishEditOption(val, i);
  }

  function finishEditOption(val, i) {
    if (val) {
      question.matrixQuestion.options[i] = val;
      saveQuestion(question);
    } else {
      question.matrixQuestion.options.splice(i, 1);
      saveQuestion(question);
    }
  }

  function getRange() {
    let range = [];
    for (
      let i = question.scaleQuestion.min;
      i <= question.scaleQuestion.max;
      i += question.scaleQuestion.step
    ) {
      range.push(i);
    }
    return range;
  }

  function getTransposedRange() {
    let range = getRange();
    if (settings.flipNumbers) {
      range.reverse();
    }
    return range;
  }

  function editHeading(val) {
    question.matrixQuestion.heading = val;
    saveQuestion(question);
  }

  function saveTextAnswer(option, val) {
    let data = answerMap[question.id] ? { ...answerMap[question.id] } : {};

    if (val) {
      data[option] = val;
      onAnswer(question.id, data);
    } else {
      delete data[option];

      if (Object.keys(data).length) {
        onAnswer(question.id, data);
      } else {
        onRetract(question.id);
      }
    }
  }

  function handleChoiceCheck(option, choice, val) {
    if (val) {
      if (!answerMap[question.id]) {
        answerMap[question.id] = {};
      }
      if (!answerMap[question.id][option]) {
        answerMap[question.id][option] = [];
      }

      if (
        question.choiceQuestion.isMultiSelect ||
        question.choiceQuestion.isRanking
      ) {
        if (
          question.choiceQuestion.limit ===
          answerMap[question.id][option].length
        ) {
          return;
        }
        answerMap[question.id][option].push(choice);
      } else {
        answerMap[question.id][option] = [choice];
      }

      onAnswer(question.id, answerMap[question.id]);
    } else {
      answerMap[question.id][option] = answerMap[question.id][option].filter(
        (item) => item !== choice
      );

      if (!answerMap[question.id][option].length) {
        delete answerMap[question.id][option];
      }

      if (Object.keys(answerMap[question.id]).length) {
        onAnswer(question.id, answerMap[question.id]);
      } else {
        onRetract(question.id);
      }
    }
  }

  function handleScaleCheck(option, num, val) {
    if (val) {
      if (!answerMap[question.id]) {
        answerMap[question.id] = {};
      }
      answerMap[question.id][option] = num;
      onAnswer(question.id, answerMap[question.id]);
    } else {
      delete answerMap[question.id][option];

      if (Object.keys(answerMap[question.id]).length) {
        onAnswer(question.id, answerMap[question.id]);
      } else {
        onRetract(question.id);
      }
    }
  }

  function handleSliderChange(option, val) {
    if (!answerMap[question.id]) {
      answerMap[question.id] = {};
    }
    answerMap[question.id][option] = val;
    onAnswer(question.id, answerMap[question.id]);
  }

  function adjustCols() {
    if (
      settings.transpose
        ? matrix.options.length
        : question.choiceQuestion.choices.length
    ) {
      let table = document.getElementById(question.id + "tableB");
      let topLeft = document.getElementById(question.id + "topLeftB");
      if (table) {
        let leftOver = table.clientWidth - topLeft.clientWidth;
        if (active) leftOver -= 100; // For + Col

        let portion = leftOver / question.choiceQuestion.choices.length;
        // console.log('tableWidth', table.clientWidth)
        // console.log("portion: ", portion);
        // console.log("topLeft: ", topLeft.clientWidth);
        // console.log("should be", settings.width);

        let columns = settings.transpose
          ? matrix.options
          : question.choiceQuestion.choices;
        for (let i = 0; i < columns.length; i++) {
          let col = document.getElementById(question.id + "choiceB" + i);
          if (col) {
            col.style.width = portion + "px";
          }
        }
      }
    }
  }

  useLayoutEffect(() => {
    if (question.choiceQuestion) {
      // console.log("\nin layout effect");
      adjustCols();
    }
  }, [question, immediateWidth, active]);

  useLayoutEffect(() => {
    if (question.choiceQuestion) {
      setTimeout(() => {
        // console.log("\nfrom active");
        adjustCols();
      }, 250);
    }
  }, [active]);

  const settings = matrix.settings;

  return (
    <>
      <div
        className={`${styles.matrix} ${
          settings.hideLines || settings.hideX ? styles.noX : ""
        }  ${settings.hideLines || settings.hideY ? styles.noY : ""}`}
      >
        <table id={question.id + "tableB"} className={styles.table}>
          <thead>
            {(matrix.type === Scale || matrix.type === "NumberSlider") &&
              (question.scaleQuestion.minDescription ||
                question.scaleQuestion.maxDescription) &&
              !settings.transpose && (
                <tr className={styles.topAbove}>
                  <th></th>
                  <th
                    colSpan={getRange().length}
                    className={styles.minMaxContainer}
                  >
                    <div className={styles.minMax}>
                      <div>{question.scaleQuestion.minDescription}</div>
                      <div>{question.scaleQuestion.maxDescription}</div>
                    </div>
                  </th>
                </tr>
              )}
            <tr>
              <th
                style={
                  immediateWidth
                    ? { width: immediateWidth + "px" }
                    : settings.width
                    ? { width: settings.width }
                    : undefined
                }
                className={styles.topLeft}
                id={question.id + "topLeftB"}
              >
                {active && (
                  <div
                    className={styles.dragBar}
                    onMouseDown={onMouseDown}
                    onMouseUp={doneDragging}
                    onMouseMove={onDrag}
                    onMouseLeave={doneDragging}
                    style={
                      dragging
                        ? { zIndex: "210", height: tableHeight + "px" }
                        : { height: getHeight() }
                    }
                  >
                    {dragging && (
                      <>
                        <div
                          className={`${styles.pullPadding} ${styles.left}`}
                        ></div>
                        <div
                          className={`${styles.pullPadding} ${styles.right}`}
                        ></div>
                      </>
                    )}
                  </div>
                )}
              </th>
              {!settings.transpose && (
                <>
                  {matrix.type === MultChoice && (
                    <>
                      {!settings.transpose && (
                        <>
                          {question.choiceQuestion.choices.map((choice, i) => (
                            <th
                              className={styles.colHeader}
                              key={i}
                              id={question.id + "choiceB" + i}
                            >
                              <TextEditorPlain
                                text={choice}
                                editable={active}
                                id={question.id + "choiceInput" + i}
                                onSave={(val) => editChoice(val, i)}
                                onTab={(e) => onChoiceTab(e, i)}
                                onEnter={(e) => e.target.blur()}
                                onDeleteEmpty={(e) => e.target.blur()}
                                style={{
                                  backgroundColor: active ? "#f5f5f5" : "",
                                  borderRadius: active ? ".5rem" : "",
                                  padding: active ? ".5rem" : "",
                                }}
                              />
                            </th>
                          ))}

                          {active && !dragging && (
                            <th
                              className={styles.colHeader}
                              style={{ minWidth: "100px" }}
                            >
                              <TextEditorPlain
                                key={question.choiceQuestion.choices.length}
                                text={""}
                                placeholder={"+ Column"}
                                editable
                                placeholderStyle={{
                                  color: "#d8d9d9",
                                  padding: ".5rem",
                                }}
                                style={{
                                  backgroundColor: active ? "#f5f5f5" : "",
                                  borderRadius: active ? ".5rem" : "",
                                  padding: active ? ".5rem" : "",
                                }}
                                id={"new input for choice"}
                                onEnter={(e) => e.target.blur()}
                                onSave={addChoiceToEnd}
                                onTab={onBrandNewSwitchToNext}
                              />
                            </th>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {(matrix.type === Scale || matrix.type === "NumberSlider") &&
                    getRange().map((col, i) => (
                      <th id={question.id + "th " + col} key={i}>
                        {settings.hideNumbers ? "" : col}
                      </th>
                    ))}

                  {matrix.type === Text && (
                    <th>
                      {(active || matrix.heading) && (
                        <TextEditorPlain
                          text={matrix.heading}
                          placeholder={"Header"}
                          editable
                          placeholderStyle={{
                            color: "#d8d9d9",
                            textAlign: "center",
                          }}
                          style={{
                            backgroundColor: active ? "#f5f5f5" : "",
                            borderRadius: active ? ".5rem" : "",
                            padding: active ? ".5rem" : "",
                          }}
                          onEnter={(e) => e.target.blur()}
                          onSave={editHeading}
                        />
                      )}
                    </th>
                  )}
                </>
              )}

              {settings.transpose && (
                <>
                  {matrix.options.map((option, i) => (
                    <th
                      className={styles.colHeader}
                      key={i}
                      id={question.id + "choiceB" + i}
                    >
                      <TextEditorPlain
                        text={option}
                        editable={active}
                        id={question.id + "optionInput" + i}
                        onEnter={(e) => e.target.blur()}
                        onSave={(val) => editOption(val, i)}
                        onDeleteEmpty={(e) => e.target.blur()}
                        onTab={(e) => onOptionEnter(e, i)}
                        style={{
                          backgroundColor: active ? "#f5f5f5" : "",
                          borderRadius: active ? ".5rem" : "",
                          padding: active ? ".5rem" : "",
                        }}
                      />
                    </th>
                  ))}

                  {active && (
                    <th
                      className={styles.colHeader}
                      style={{ minWidth: "100px" }}
                    >
                      <TextEditorPlain
                        key={matrix.options.length}
                        text={""}
                        editable={active}
                        placeholder={"+ Column"}
                        placeholderStyle={{
                          color: "#d8d9d9",
                          padding: ".5rem",
                        }}
                        id={"new input for option"}
                        onEnter={(e) => e.target.blur()}
                        onSave={addOption}
                        onTab={onBrandNewSwitchToNext}
                        style={{
                          backgroundColor: active ? "#f5f5f5" : "",
                          borderRadius: active ? ".5rem" : "",
                          padding: active ? ".5rem" : "",
                        }}
                      />
                    </th>
                  )}
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {!settings.transpose && (
              <>
                {matrix.options.map((option, i) => (
                  <tr key={i}>
                    <td className={styles.yAxisLabel}>
                      <TextEditorPlain
                        text={option}
                        editable={active}
                        id={question.id + "optionInput" + i}
                        onEnter={(e) => onOptionEnter(e, i)}
                        onSave={(val) => editOption(val, i)}
                        onDeleteEmpty={(e) => e.target.blur()}
                        style={{
                          backgroundColor: active ? "#f5f5f5" : "",
                          borderRadius: active ? ".5rem" : "",
                          padding: active ? ".5rem" : "",
                        }}
                      />
                    </td>

                    {matrix.type === Text && (
                      <td className={styles.textEntry}>
                        <TextEditorPlain
                          text={
                            answerMap[question.id]
                              ? answerMap[question.id][option]
                              : ""
                          }
                          editable={!disable}
                          onSave={(val) => saveTextAnswer(option, val)}
                          style={{
                            backgroundColor: disable ? "#e9e9e9" : "#f5f5f5",
                            borderRadius: "10px",
                          }}
                          multiLine
                          // style={disable ? {backgroundColor: "#ebebeb"} : undefined}
                        />
                      </td>
                    )}
                    {matrix.type === MultChoice && (
                      <>
                        {question.choiceQuestion.choices.map((choice, ind) => (
                          <td key={ind}>
                            <ChoiceMatrixOption
                              choice={choice}
                              option={option}
                              selected={
                                answerMap[question.id] &&
                                answerMap[question.id][option]
                                  ? answerMap[question.id][option]
                                  : []
                              }
                              handleCheck={(val) =>
                                handleChoiceCheck(option, choice, val)
                              }
                              color={designSettings.baseColor}
                              rank={question.choiceQuestion.isRanking}
                              multi={question.choiceQuestion.isMultiSelect}
                              disable={disable}
                            />
                          </td>
                        ))}
                        {active && <td></td>}
                      </>
                    )}
                    {matrix.type === Scale &&
                      getRange().map((num, ind) => (
                        <td key={ind}>
                          <ScaleMatrixOption
                            num={num}
                            selected={
                              answerMap[question.id] &&
                              option in answerMap[question.id]
                                ? answerMap[question.id][option] === num
                                : false
                            }
                            handleCheck={(val) =>
                              handleScaleCheck(option, num, val)
                            }
                            color={designSettings.baseColor}
                            showNum={matrix.settings.showNumbers}
                            settings={matrix.settings}
                            disable={disable}
                          />
                        </td>
                      ))}

                    {matrix.type === "NumberSlider" && (
                      <SliderMatrixOption
                        value={
                          answerMap[question.id] &&
                          option in answerMap[question.id]
                            ? answerMap[question.id][option]
                            : -1
                        }
                        handleChange={(val) => handleSliderChange(option, val)}
                        color={disable ? "#a3a4a8" : designSettings.baseColor}
                        scaleQ={question.scaleQuestion}
                        span={getRange().length}
                        first={!i}
                        qId={question.id}
                        disable={disable}
                      />
                    )}
                  </tr>
                ))}
                {active && (
                  <tr>
                    <td
                      className={styles.yAxisLabel}
                      style={{ minWidth: "60px" }}
                    >
                      <TextEditorPlain
                        key={matrix.options.length}
                        text={""}
                        editable={active}
                        placeholder={"+ Row"}
                        placeholderStyle={{
                          color: "#d8d9d9",
                          textAlign: "center",
                        }}
                        id={"new input for option"}
                        onEnter={onBrandNewSwitchToNext}
                        onSave={addOption}
                      />
                    </td>
                  </tr>
                )}
              </>
            )}

            {settings.transpose && (
              <>
                {matrix.type === MultChoice && (
                  <>
                    {question.choiceQuestion.choices.map((choice, i) => (
                      <tr key={i}>
                        <td className={styles.yAxisLabel}>
                          <TextEditorPlain
                            text={choice}
                            editable={active}
                            id={question.id + "choiceInput" + i}
                            onSave={(val) => editChoice(val, i)}
                            onEnter={(e) => onChoiceTab(e, i)}
                            onDeleteEmpty={(e) => e.target.blur()}
                          />
                        </td>

                        {matrix.type === MultChoice && (
                          <>
                            {matrix.options.map((option, ind2) => (
                              <td key={ind2}>
                                <ChoiceMatrixOption
                                  choice={choice}
                                  option={option}
                                  selected={
                                    answerMap[question.id] &&
                                    answerMap[question.id][option]
                                      ? answerMap[question.id][option]
                                      : []
                                  }
                                  handleCheck={(val) =>
                                    handleChoiceCheck(option, choice, val)
                                  }
                                  color={designSettings.baseColor}
                                  rank={question.choiceQuestion.isRanking}
                                  multi={question.choiceQuestion.isMultiSelect}
                                  disable={disable}
                                />
                              </td>
                            ))}
                            {active && <td></td>}
                          </>
                        )}
                      </tr>
                    ))}

                    {active && (
                      <tr>
                        <td
                          className={styles.yAxisLabel}
                          style={{ minWidth: "60px" }}
                        >
                          <TextEditorPlain
                            key={question.choiceQuestion.choices.length}
                            text={""}
                            placeholder={"+ Row"}
                            editable
                            placeholderStyle={{
                              color: "#d8d9d9",
                              textAlign: "center",
                            }}
                            id={"new input for choice"}
                            onEnter={onBrandNewSwitchToNext}
                            onSave={addChoiceToEnd}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                )}
                {(matrix.type === Scale || matrix.type === "NumberSlider") &&
                  getTransposedRange().map((num, ind) => (
                    <tr key={ind}>
                      <td>
                        {!settings.hideNumbers && (
                          <div className={styles.yAxisNumber}>{num}</div>
                        )}
                      </td>

                      {matrix.type === Scale &&
                        matrix.options.map((option, i2) => (
                          <td key={i2}>
                            <ScaleMatrixOption
                              num={num}
                              selected={
                                answerMap[question.id] &&
                                option in answerMap[question.id]
                                  ? answerMap[question.id][option] === num
                                  : false
                              }
                              handleCheck={(val) =>
                                handleScaleCheck(option, num, val)
                              }
                              color={designSettings.baseColor}
                              showNum={matrix.settings.showNumbers}
                              disable={disable}
                            />
                          </td>
                        ))}

                      {matrix.type === "NumberSlider" && (
                        <SliderMatrixOption
                          value={
                            answerMap[question.id] &&
                            option in answerMap[question.id]
                              ? answerMap[question.id][option]
                              : -1
                          }
                          handleChange={(val) =>
                            handleSliderChange(option, val)
                          }
                          color={disable ? "#a3a4a8" : designSettings.baseColor}
                          scaleQ={question.scaleQuestion}
                          span={getRange().length}
                          first={!i}
                          qId={question.id}
                          disable={disable}
                        />
                      )}
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
