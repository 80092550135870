import { ChartLabel } from "../ChartLabel/ChartLabel";
import styles from "./Color.module.scss";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";

export function Color({ children, color, onChange, style }) {
  return (
    <div className={styles.container} style={style}>
      <ColorPicker defaultColor={color} onChange={onChange} />
      <ChartLabel>{children}</ChartLabel>
    </div>
  );
}
