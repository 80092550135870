import React, { useEffect, useState } from "react";
import styles from "./AddToAudience.module.scss";
import { Upload } from "../all_contacts/NewContacts/upload/Upload";
import AddFromContacts from "./AddFromContacts";
import {
  useSearchContactsByAudience,
  useSearchContactsCountByAudience,
} from "api/resources/contacts/contacts";
import { ImportedCleaner } from "../all_contacts/NewContacts/upload/ImportedCleaner/ImportedCleaner";
import { TabPill } from "components/layouts";
import ExternalImport from "../ExternalImport/ExternalImport";

function AddToAudience({
  audience,
  setAudience,
  showGrow,
  setShowGrow,
  user,
  initContacts,
  onRowClick,
}) {
  const [active, setActive] = useState(0);
  const [contacts, setContacts] = useState(initContacts ? initContacts : []);
  const [editFlags, setEditFlags] = useState();
  const [sort, setSort] = useState({
    item: "email",
    descend: true,
  });

  const [perPage, setPerPage] = useState(15);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchString, setSearchString] = useState("");

  // const removeContactFromAudience = useRemoveContactFromAudience();

  const getContactCount = useSearchContactsCountByAudience(
    searchString,
    pageSkip,
    perPage,
    sort,
    audience?.id
  );
  const searchContacts = useSearchContactsByAudience(
    searchString,
    pageSkip,
    perPage,
    sort,
    audience?.id
  );

  useEffect(() => {
    getContactCount.refetch();
    searchContacts.refetch();
  }, [searchString, pageSkip, sort, perPage]);

  const menuItems = [
    {
      id: 0,
      name: "Add From File",
    },
    // {
    //   id: 1,
    //   name: "Manully Add",
    //   icon: "person-lock",
    // },
    {
      id: 2,
      name: "Add From Contacts",
    },
    {
      id: 3,
      name: "External Import",
      // to: `design`,
    },
  ];

  const columns = [
    {
      id: 3,
      index: 3,
      //   name: "New",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: (val) => (
        <div>
          {val && val?.new ? <span className={styles.new}>New</span> : ""}
        </div>
      ),
    },
    {
      id: 0,
      index: 0,
      name: "Email",
      accessor: "email",
      enabled: true,
      sort: true,
      canSort: true,
      cell_style: null,
    },
    {
      id: 1,
      index: 1,
      name: "First Name",
      accessor: "firstName",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },
    {
      id: 2,
      index: 2,
      name: "Last Name",
      accessor: "lastName",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },
  ];

  return (
    <div className={styles.page}>
      <div className={styles.top}>
        <div className={styles.goback} onClick={() => setShowGrow(!showGrow)}>
          {" "}
          <div className={styles.backArrow}>
            <i className="bi bi-arrow-left"></i>
          </div>
          Go Back
        </div>
        <div className={styles.audName}>Add To "{audience.name}"</div>
        <TabPill
          tabBarItems={menuItems}
          active={active}
          updateActive={(a) => setActive(a)}
          wings
        />
      </div>
      <div className={styles.uploaderPage}>
        {/* {active === 1 && (
          <div className={styles.manualHolder}>
            <Manual
              audience={audience}
              isAudience={true}
              audienceId={audience.id}
              success={() => setShowGrow(!showGrow)}
            />
          </div>
        )} */}
        {/* {!editFlags && active === 0 &&  (
          <InteractiveTable
            headers={headers}
            // data={searchProjects.}
            queryData={searchContacts}
            queryDataAccessor={"contacts"}
            maxDataQuery={getContactCount}
            maxDataQueryAccessor={"count"}
            currPage={pageNumber}
            setCurrPage={setPageNumber}
            // maxData={getProjectCount}
            onRowClick={onRowClick}
            sort={sortQuery}
            setSort={setSortQuery}
            pageSkip={pageSkip}
            setPageSkip={setPageSkip}
            perPage={perPage}
            setPerPage={setPerPage}
            searchString={searchString}
            setSearchString={setSearchString}
            // actionButtonText={"Add/Update Contacts"}
            // tableTitle={getTitle()}
            // tableDescription={`${audience?.description}`}
            // widgetType="survey"
            searchPlaceholder={"Search Contacts..."}
            // onClickActionButton={() => setShowGrow(true)}
            hideActionButton
          ></InteractiveTable>
        )} */}

        {editFlags && <ImportedCleaner id={editFlags}></ImportedCleaner>}

        {active === 0 && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Upload
              audience={audience}
              isAudience={true}
              audienceId={audience.id}
              success={() => setShowGrow(!showGrow)}
              user={user}
              contacts={contacts}
              setContacts={setContacts}
              setShow={setEditFlags}
            />
          </div>
        )}

        {active === 2 && (
          <AddFromContacts
            audience={audience}
            setShowGrow={() => setShowGrow(!showGrow)}
            setAudience={setAudience}
            contacts={contacts}
            setContacts={setContacts}
            sort={sort}
            onRowClick={onRowClick}
          />
        )}

        {active === 3 && (
          <ExternalImport
            audience={audience}
            setShowGrow={() => setShowGrow(!showGrow)}
            setAudience={setAudience}
            contacts={contacts}
            setContacts={setContacts}
            sort={sort}
            onRowClick={onRowClick}
          />
        )}
      </div>
    </div>
  );
}

export default AddToAudience;
