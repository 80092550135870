import {
  useDeleteAudienceGql,
  useUpdateAudienceGql,
} from "api/resources/contacts/audiences";
import React, { useState } from "react";
import styles from "./AudienceSettings.module.scss";
import { SelectField, TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { maintimezones } from "assets/functions/Variables";
import { Label } from "components/layouts/Label/Label";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import IconShopperModal from "pages/results/Charts/Settings/AccordianItems/MetricSettings/IconShopper/IconShopperModal";

function AudienceSettings({ audience, setSettings }) {
  const deleteAudienceQuery = useDeleteAudienceGql();
  const updateAudience = useUpdateAudienceGql();

  const [name, setName] = useState(audience?.name);
  const [icon, setIcon] = useState(audience?.icon ? audience?.icon : "people-fill");
  const [iconColor, setIconColor] = useState(audience?.iconColor ? audience?.iconColor : "#15bcc7");
  const [changeIcon, setChangeIcon] = useState(false);
  const [description, setDescription] = useState(audience?.description);
  const [saveState, setSaveState] = useState("Save Changes");
  const [timezone, setTimezone] = useState(
    maintimezones.find((t) => t.value === audience?.timeZone)
  );

  let navigate = useNavigate();

  function deleteAudience() {
    if (window.confirm("Are you sure you want to delete this audience?")) {
      deleteAudienceQuery.mutate(
        {
          deleteAudienceId: audience?.id,
        },
        {
          onSuccess: () => {
            navigate("contacts/audiences/");
            location.reload();
          },
        }
      );
    }
  }

  function saveChanges() {
    setSaveState(
      <div style={{ display: "flex", gap: ".5em", width: "fit-content" }}>
        Saving... <Loading height={20} width={20}></Loading>
      </div>
    );
    updateAudience.mutate(
      {
        data: {
          name: name,
          description: description,
          timeZone: timezone?.timezone,
          icon: icon,
          iconColor: iconColor
        },
        id: audience?.id,
      },
      {
        onSuccess: () => {
          setSaveState(
            <div>
              Saved <i className="bi bi-check"></i>
            </div>
          );
          setTimeout(() => {
            setSaveState("Save Changes");
          }, 2000);
        },
      }
    );
  }

  return (
    <div className={styles.settingsPage}>
      <div className={styles.settingsSection}>
        <div className={styles.header}>Audience Settings</div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "500px",
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            <TextFieldSimple
              value={name}
              label="Audience Name"
              onChange={(val) => setName(val)}
              placeholder="Name"
            ></TextFieldSimple>
            <TextFieldSimple
              value={description}
              label="Audience Description"
              onChange={(val) => setDescription(val)}
              placeholder="Description"
            ></TextFieldSimple>
            <SelectField
              value={timezone}
              label="Time Zone"
              options={maintimezones}
              onChange={(val) => setTimezone(val)}
            ></SelectField>
            <div className={styles.icon}>
              <Label>Icon: </Label>
              <i
                className={"bi-" + icon}
                style={{ color: iconColor }}
                onClick={() => setChangeIcon(true)}
              ></i>
              <ColorPicker
                defaultColor={iconColor}
                onChange={setIconColor}
              ></ColorPicker>
            </div>
            {changeIcon && (
            <IconShopperModal
              onPick={(i) => {
                setIcon(i);
              }}
              onClose={() => setChangeIcon(false)}
            ></IconShopperModal>
          )}
          </div>
        </div>
        {/* <HorizontalBar height="2px" backgroundColor="lightgray"></HorizontalBar> */}
        {/* Notification and Permission Settings Coming soon... */}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button shadow red onClick={deleteAudience}>
          {" "}
          Delete Audience
        </Button>
        <div
          style={{
            display: "flex",
            gap: "1em",
            alignItems: "center"
          }}
        >
          <Button link onClick={() => setSettings(false)}>
            Back
          </Button>
          <Button shadow onClick={saveChanges}>
            {saveState}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AudienceSettings;
