import Button from "components/Button/Button";
import styles from "./SettleLogic.module.scss";
import Modal from "components/ReactModal/ReactModal";
import { useState } from "react";
import { Loading } from "components/Loading/Loading";

export default function SettleLogic({
  areYouSure,
  action,
  question,
  questions,
  dependencies,
  onCarryThrough,
  onNevermind,
  allQuestions,
  saveQuestions,
  removeItsOwn,
}) {
  const [removing, setRemoving] = useState(false);

  function getUniqueAffectedList() {
    let list = [];
    if (question && !questions) {
      list = dependencies.find(
        (d) => d.questionId === question.id
      ).dependencies;
    } else if (questions && !question) {
      for (let q of questions) {
        let subList = dependencies.find(
          (d) => d.questionId === q.id
        ).dependencies;
        for (let one of subList) {
          if (!list.find((d) => d.dependentId === one.dependentId)) {
            list.push(one);
          }
        }
      }
    }

    return list;
  }

  function involvedQs() {
    let Qs = [];
    if (question) {
      Qs.push(question);
    }
    if (questions) {
      Qs = [...Qs, ...questions];
    }
    return Qs;
  }

  function removeAssociatedLogic() {
    setRemoving(true);

    let copy = [...allQuestions];

    for (let dependedOn of involvedQs()) {
      let list = dependencies.find((d) => d.questionId === dependedOn.id).dependencies;
      for (let dependent of list) {
        let q = copy.find((q) => q.id === dependent.dependentId);
        for (let i = 0; i < q.conditionGroups.length; i++) {
          let ind = q.conditionGroups[i].conditions.findIndex(
            (c) => c.questionId === dependedOn.id
          );
          while (ind >= 0) {
            q.conditionGroups[i].conditions.splice(ind, 1);
            ind = q.conditionGroups[i].conditions.findIndex(
              (c) => c.questionId === dependedOn.id
            );
          }
        }

        // clean up
        let ind = q.conditionGroups.findIndex((g) => g.conditions.length == 0);
        while (ind >= 0) {
          q.conditionGroups.splice(ind, 1);
          ind = q.conditionGroups.findIndex((g) => g.conditions.length == 0);
        }

        q.changed = true;
      }
    }

    saveQuestions(copy);

    setTimeout(() => {
      setRemoving(false);
      onCarryThrough();
    }, 500);
  }

  function removeItsOwnLogic() {
    setRemoving(true);

    let copy = [...allQuestions];
    for (let qst of involvedQs()) {
      let index = copy.findIndex((q) => q.id === qst.id);
      copy[index].conditionGroups = null;
      copy[index].changed = true;
    }

    saveQuestions(copy);

    setTimeout(() => {
      setRemoving(false);
      onCarryThrough();
    }, 500);
  }

  function upperCaseAction() {
    return action.charAt(0).toUpperCase() + action.slice(1);
  }

  return (
    <Modal
      show={true}
      onClose={onNevermind}
      modalStyle={{ borderRadius: "1em", overflow: "visible" }}
    >
      {" "}
      <div className={styles.modal}>
        <div className={styles.warning}>
          <div className={styles.logo}>
            <i className="bi bi-signpost-split"></i>
          </div>
          <div>This will break the question logic on:</div>
          <div className={styles.questionList}>
            {removeItsOwn &&
              involvedQs().map((q) => <div>{q.questionText}</div>)}
            {!removeItsOwn &&
              getUniqueAffectedList().map((dep) => (
                <div>{dep.dependentName}</div>
              ))}
          </div>
        </div>

        <div className={styles.prompt}>
          {question && (
            <>
              {`Are you sure you would like to ${areYouSure} '${question.questionText}'?`}
            </>
          )}
          {questions && (
            <>{`Are you sure you would like to ${areYouSure} this page?`}</>
          )}
        </div>

        <div className={styles.actions}>
          <Button onClick={onNevermind}>Cancel</Button>
          <Button
            onClick={removeItsOwn ? removeItsOwnLogic : removeAssociatedLogic}
            red
          >
            {removing ? (
              <div
                style={{ display: "flex", gap: "7px", alignItems: "center" }}
              >
                {" "}
                Removing <Loading height={20} width={20} />
              </div>
            ) : (
              <> {`Remove Associated Logic & ${upperCaseAction()}`}</>
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
