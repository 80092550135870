import styles from "./NumSliderTool.module.scss";
import { NumSlider } from "components/inputs/input_fields/NumSlider/NumSlider";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { ChartLabel } from "../ChartLabel/ChartLabel";

export function NumSliderTool({
  onChange,
  val,
  min,
  max,
  step,
  children,
  style,
}) {
  return (
    <div className={styles.container} style={style}>
      <div className={styles.label}>
        <ChartLabel>{children}</ChartLabel>
      </div>
      <div className={styles.settings}>
        <NumberInput
          startNumber={val}
          min={min}
          max={max}
          step={step}
          handleNumberChange={onChange}
          color={"#2A627C"}
          style={{ width: "4rem" }}
        ></NumberInput>
        <NumSlider
          value={val}
          min={min}
          max={max}
          step={step}
          onChange={onChange}
          color={"#2A627C"}
        ></NumSlider>
      </div>
    </div>
  );
}
