// Externals
import React from "react";
import { Link, useNavigate } from "react-router-dom";

// Internals
import styles from "./TabBarItem.module.scss";
import Icon from "components/Icon/Icon";

export const TabBarItem = ({
  id,
  label,
  to,
  isActive,
  makeActive,
  icon,
  iconColor,
}) => {
  let navigate = useNavigate();

  return (
    <>
      <div
        className={`${styles.tabBarItem} ${isActive && styles.activeItem}`}
        onClick={() => {
          makeActive(id);
          if (to) {
            navigate(to);
          }
        }}
      >
        {typeof icon === "string" ? <i className={`bi-${icon}`}></i> : icon}
        {label}
      </div>
    </>
  );
};
