import React, { useEffect, useState } from "react";
import styles from "./ImageEditor.module.scss";
import ImageUploadModal from "components/ImageUploadModal/imageUploadModal";
import AvatarEditor from "react-avatar-editor";
import Button from "components/Button/Button";
import userImage from "assets/images/blank-profile-picture.png";

function ImageEditor({
  src,
  alt,
  canEdit,
  handleFileChange,
  isUser,
  isLogo,
  userId,
  position,
  image,
  refetch,
  show,
  setShow,
  height,
  width,
  noShadow,
  orgId,
  noBack,
  scale
}) {
  
  const [edit, setEdit] = useState(false);
  const [file, setFile] = useState(src);

  function getType(imageLink) {
    if (imageLink) {
      //do imageLink
      return imageLink;
    } else {
      return userImage;
    }
  }

  useEffect(() => {
    dragElement(document.getElementById("mydiv"));
  }, []);

  function dragElement(elmnt) {
    if (canEdit) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(elmnt.id + "header").onmousedown =
          dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  }

  return (
    <>
      <div
        className={styles.container}
        id={"mydiv"}
        style={{
          height: height ? isLogo ? 100 : height : "",
          width: width ?  width :  "",
          pointerEvents: canEdit ? "none" : "",
          boxShadow: noShadow ? "none" : "",
          borderRadius: isLogo ? ".5rem" : "",
          backgroundColor: noBack ? "transparent" : ""
        }}
      >
        {canEdit && (
          <div
            className={styles.edit}
            onClick={setShow ? () => setShow(true) : () => setEdit(true)}
          >
            <Button shadow style={{ gap: ".5em" }}>
              Edit<i className="bi-pencil"></i>
            </Button>
          </div>
        )}
        {!canEdit && <div className={styles.disable}></div>}

        {/* <div className={styles.imageContainer}> */}
        {/* <img src={getType(file)} alt={alt ? alt : "image"} id={"mydiv"} style={{zoom: scale}}/> */}
        {isLogo && file && (
          <AvatarEditor
            image={getType(file)}
            border={-1}
            borderRadius={0}
            // onPositionChange={setPosition}
            position={position ? position : { x: 0.5, y: 0.5 }}
            scale={image?.scale ? image?.scale : 1}
            // style={{ height: height ? height : "", width: width ? width : "" }}
            height={scale ? height : 90}
            width={scale ? width : 240}
          ></AvatarEditor>
        )}

        {isLogo && !file && <div className={styles.alt} style={{backgroundColor: noBack ? "transparent" : ""}}>{alt}</div>}

        {isUser && (
          <AvatarEditor
            image={getType(file)}
            border={-1}
            borderRadius={200}
            // onPositionChange={setPosition}
            position={position ? position : { x: 0.5, y: 0.5 }}
            scale={image?.scale ? image?.scale : 1}
            style={{ height: height ? height : "", width: width ? width : "" }}
          ></AvatarEditor>
        )}
        {/* </div> */}
      </div>
      <ImageUploadModal
        show={show ? show : edit}
        setShow={setShow ? () => setShow(false) : () => setEdit(false)}
        setImage={setFile}
        isLogo={isLogo}
        isUser={isUser}
        userId={userId}
        src={src}
        initPosition={position ? position : { x: 0.5, y: 0.5 }}
        image={image}
        refetch={refetch}
        orgId={orgId}
      ></ImageUploadModal>
    </>
  );
}

export default ImageEditor;
