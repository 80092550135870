// External
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Internal
import { ProjectRoutes } from "routes";
import styles from "./Program.module.scss";
import { Loading } from "components/Loading/Loading";
import { useFetchProjectGql } from "api/resources/projects/projects";
import { ErrorPage } from "pages";
import { useDownloadReportByAnswer } from "api/resources/projects/results";
import { NavBar } from "components/sidebars/NavBar/NavBar";
import { Page } from "components/layouts/Page/Page";
import { PageContent } from "components/layouts/Page/PageContent";
import Button from "components/Button/Button";
import SurveySettings from "./survey_details/SurveySettings";

export const Program = ({
  resultsOnly,
  roles,
  organization,
  user,
  setBeforeNavAway,
}) => {
  const { id } = useParams();
  const fetchProjectQuery = useFetchProjectGql(id);

  const [showSettings, setShowSettings] = useState(false);
  const [beforeNavAwayInProj, setBeforeNavAwayInProj] = useState();
  const [hideNav, setHideNav] = useState(false);

  function checkBuildRole() {
    if (fetchProjectQuery.data.survey.organizationId === organization.id) {
      return roles.canCreateEditOrgSurveys;
    } else {
      return true;
    }
  }

  function checkResultsRole(orgId) {
    if (fetchProjectQuery.data.survey.organizationId === organization.id) {
      return roles.canViewRawResultsForOrg;
    } else {
      return true;
    }
  }

  function checkSendRole(orgId) {
    if (fetchProjectQuery.data.survey.organizationId === organization.id) {
      return roles.canSendOrgSurveyEmails;
    } else {
      return true;
    }
  }

  function getItems(orgId) {
    return [
      {
        id: 0,
        name: "Details",
        to: `survey-details`,
        iconClassName: "bi bi-list-task",
      },
      {
        id: 1,
        name: checkBuildRole(orgId) ? "Build" : "Preview Survey",
        to: checkBuildRole(orgId)
          ? `/program/${id}/survey-build`
          : "/program/${id}/preview",
        iconClassName: checkBuildRole(orgId) ? "bi bi-hammer" : "",
        noChange: !checkBuildRole(orgId),
      },
      checkSendRole(orgId)
        ? {
            id: 2,
            name: "Messages",
            to: `/program/${id}/delivery/messages`,
            iconClassName: "bi-send",
          }
        : "",
        // {
        //   id: 3,
        //   name: "Schedule",
        //   to: "",
        //   iconClassName: "bi-calendar",
        //   // noChange: !checkBuildRole(orgId),
        // },
      checkResultsRole(orgId)
        ? {
            id: 4,
            name: "Results",
            to: `/program/${id}/surveyresults/${fetchProjectQuery.data.survey.rawReportId}`,
            iconClassName: "bi bi-bar-chart-fill",
          }
        : "",

    ];
  }
  const [active, setActive] = useState(
    localStorage.getItem("activeprojecttab")
      ? localStorage.getItem("activeprojecttab")
      : 0
  );

  useEffect(() => {
    setActive(localStorage.getItem("activeprojecttab"));
  }, [localStorage.getItem("activeprojecttab")]);

  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  let [width, setWidth] = useState(getWidth());

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      let width = getWidth();
      setWidth(width);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);
  const handleActiveUpdate = (item) => {
    // console.log(item);
    localStorage.removeItem("activedelivertab");
    localStorage.removeItem("activesurveybuildtab");
    localStorage.setItem("activeprojecttab", item);
    setActive(item);

    // localStorage.setItem("activeprojecttab", );
  };

  let navigate = useNavigate();
  // const routeChange = (row) => {
  //   // console.log(path);
  //   navigate(row.id);
  // };

  useEffect(() => {
    if (resultsOnly) {
      localStorage.setItem("activeprojecttab", 3);
      setActive(3);
      navigate("surveyresults");
    }
  }, []);

  const downloadByAnswer = useDownloadReportByAnswer(id);
  // const organization = useFetchOrganizationDownload(roles.organizationId);
  // const [headers, setHeaders] = useState([]);

  function setUpBothNavAways(obj) {
    setBeforeNavAway(obj);
    setBeforeNavAwayInProj(obj);
  }

  return (
    <>
      {(fetchProjectQuery.isLoading || downloadByAnswer.isLoading) && (
        <Loading />
      )}
      {fetchProjectQuery.isError && (
        <ErrorPage error={fetchProjectQuery.error} />
      )}
      {fetchProjectQuery.isSuccess && downloadByAnswer.isSuccess && (
        <Page>
          {!hideNav && (
             <div className={styles.navContainer}>
             <NavBar
               menuItems={getItems(fetchProjectQuery.data.survey)}
               active={active}
               updateActive={handleActiveUpdate}
               beforeNavAway={beforeNavAwayInProj}
               setBeforeNavAway={setUpBothNavAways}
             />
             <div className={styles.left}>
               <div
                 className={styles.projectName}
                 style={{
                   fontSize:
                     window.innerWidth < 1100 && window.innerWidth > 560
                       ? "1.2em"
                       : window.innerWidth < 560
                       ? ".9em"
                       : "",
                   padding: window.innerWidth < 1100 ? "10px" : "",
                 }}
               >
                 {fetchProjectQuery.data.survey.name}
               </div>
               {roles.canCreateEditOrgSurveys && (
                 <Button
                   shadow
                   seafoam
                   style={{
                     gap: ".5rem",
                     // display: "flex",
                     paddingLeft: "10px",
                     paddingRight: "10px",
                     // fontSize: "1.1rem"
                   }}
                   onClick={() => setShowSettings(true)}
                   disable={!roles.canCreateEditOrgSurveys}
                   // link
                 >
                   <i className="bi bi-gear-fill" />
                   Settings
                 </Button>
               )}
             </div>
           </div>
          )}
          <ProjectRoutes
            reportid={fetchProjectQuery.data.survey.resultAnalysisId}
            responseCount={fetchProjectQuery.data.survey.responseCount}
            setBeforeNavAway={setUpBothNavAways}
            setHideNav={setHideNav}
            survey={fetchProjectQuery.data.survey}
          />
          <SurveySettings
            survey={fetchProjectQuery.data?.survey}
            userRoles={roles}
            show={showSettings}
            setShow={setShowSettings}
            user={user}
          ></SurveySettings>
        </Page>
      )}
    </>
  );
};
