import React, { useState } from "react";
import styles from "./AddressInput.module.scss";
import { SelectFieldCustom } from "../SelectFieldCustom/SelectFieldCustom";
import { TextFieldSimple } from "../TextFieldSimple/TextFieldSimple";
import { countries } from "assets/functions/Variables";

function AddressInput({ onChange, org, billing, invoice }) {
  const [country, setCountry] = useState(
    (
      billing
        ? org?.billingCountry
        : invoice
        ? org?.invoiceCountry
        : org?.country
    )
      ? billing
        ? org?.billingCountry
        : invoice
        ? org?.invoiceCountry
        : org?.country
      : ""
  );
  return (
    <div className={styles.addy}>
      <TextFieldSimple
        label={"Address 1"}
        value={
          billing
            ? org?.billingAddress1
            : invoice
            ? org?.invoiceAddress1
            : org?.address1
        }
        onChange={(val) => onChange(val, "address1")}
      />
      <TextFieldSimple
        label={"Address 2"}
        value={
          billing
            ? org?.billingAddress2
            : invoice
            ? org?.invoiceAddress2
            : org?.address2
        }
        onChange={(val) => onChange(val, "address2")}
      />
      <div className={styles.addy2}>
        <SelectFieldCustom
          label={"Country"}
          value={country}
          onChange={(val) => {
            onChange(val?.value, "country");
            setCountry(val);
          }}
          options={countries}
        ></SelectFieldCustom>
        <TextFieldSimple
          label={"City"}
          value={
            billing ? org?.billingCity : invoice ? org?.invoiceCity : org?.city
          }
          onChange={(val) => onChange(val, "city")}
        />
      </div>
      <div className={styles.addy2}>
        <TextFieldSimple
          label={"State/Province"}
          value={
            billing
              ? org?.billingState
              : invoice
              ? org?.invoiceState
              : org?.state
          }
          onChange={(val) => onChange(val, "state")}
        />
        <TextFieldSimple
          label={"Postal Code"}
          value={
            billing
              ? org?.billingPostalCode
              : invoice
              ? org?.invoicePostalCode
              : org?.postalCode
          }
          onChange={(val) => onChange(val, "postalCode")}
        />
      </div>
    </div>
  );
}

export default AddressInput;
