export function shortId() {
  return "_" + Math.random().toString(36).substring(2, 9);
}

let reactionColors = [
  "#2A627C",
  "#6793A5",
  "#15BCC7",
  "#83D1D4",
  "#8c8f8f",
  "#616565",
  "#5a8181",
  "#66a69c",
  "#ed9146",
  "#ed9f5b",
  "#ca6333",
  "#d27945",
  "#f9cb56",
  "#acdb5d",
  "#ff6652",
  "#ff7361",
  "#fd9843",
]


export function randomColor() {
  return reactionColors[Math.floor(Math.random() * 16)];
}
