import React, { useEffect, useState } from "react";
import styles from "./SectionSettings.module.scss";
import Button from "components/Button/Button";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import ColorPickerContainer from "components/inputs/input_fields/ColorPicker/ColorPickerContainer";
import { shadows } from "assets/functions/Variables";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import { QuestionLogic } from "../QuestionLogic/QuestionLogic";
import designStyles from "../SurveyDesign/SurveyDesign.module.scss";
import { SelectFieldCustom } from "components/inputs";

export default function SectionSettings({
  section,
  onEditQ,
  onEditQs,
  allQuestions,
}) {
  // const sectionTypes = {
  //   FormRegistration: {
  //     label: "Form Registration",
  //     value: "Form Registration",
  //     name: "Form Registration",
  //     icon: <i className="bi bi-list-check"></i>,
  //   },
  //   Description: {
  //     label: "Description",
  //     value: "Description",
  //     name: "Description",
  //     icon: <i className="bi bi-chat-left-text"></i>,
  //   },
  // };

  const [showLogic, setShowLogic] = useState(false);

  function setSetting(field, val) {
    section.sectionSettings[field] = val;
    onEditQ(section);
  }
  const [groups, setGroups] = useState();

  useEffect(() => {
    if (section?.conditionGroups) {
      setGroups(JSON.parse(JSON.stringify(section?.conditionGroups)));
    } else {
      setGroups([]);
    }

    if (showLogic) setShowLogic(false);
  }, [section]);

  return (
    <div className={designStyles.body}>
      <div
        className={styles.label5}
        style={{
          margin: "0",
          width: "fit-content",
          padding: " 0rem 1rem",
          fontWeight: "500",
        }}
      >
        Section Settings
      </div>

      <div className={designStyles.settingVertical}>
        <div className={designStyles.settingHorizontal}>
          <ToggleSwitch
            startChecked={section.sectionSettings?.hasBackgroundColor}
            handleCheck={(val) =>
              setSetting("hasBackgroundColor", val ? "#FFFFFF" : false)
            }
          ></ToggleSwitch>

          <div className={styles.text_3}>Has Background Color</div>
        </div>

        {section.sectionSettings?.hasBackgroundColor && (
          <div className={designStyles.settingVertical}>
            <ColorPickerContainer
              onChange={(color) => setSetting("hasBackgroundColor", color)}
              defaultColor={
                section.sectionSettings?.hasBackgroundColor
                  ? section.sectionSettings?.hasBackgroundColor
                  : "#FFFFFF"
              }
              title="Background Color"
              //   colors={settings.colorBank}
            />
          </div>
        )}
      </div>
      <div className={designStyles.settingVertical}>
        <SelectFieldCustom
          onChange={(color) => setSetting("sectionShadow", color.value)}
          value={
            section.sectionSettings?.sectionShadow
              ? shadows.find(
                  (s) => s.value === section.sectionSettings?.sectionShadow
                )
              : { value: "", label: "No Shadow" }
          }
          label="Section Border Shadow"
          // icon="bi-chevron-down"
          options={shadows}
          select
          anyWhereOptions
        />{" "}
      </div>

      <div
        className={styles.body}
        style={showLogic ? { padding: "0px" } : undefined}
      >
        {!showLogic && (
          <>
            <FlexRow start gap={0}>
              <Icon iconName={"signpost-fill"} seafoam></Icon>
              <div
                className={styles.label5}
                style={{ margin: "0", fontWeight: "500" }}
              >
                Text Logic
              </div>
            </FlexRow>
            {groups && groups.length > 0 && (
              <FlexRow start gap={0}>
                <Icon iconName={"check-circle"} green></Icon>
                <div
                  className={styles.section_label6}
                  style={{ margin: "0", fontWeight: "500" }}
                >
                  Has Logic
                </div>
              </FlexRow>
            )}
            <FlexRow style={{ justifyContent: "center" }} gap={0}>
              <Button shadow onClick={() => setShowLogic(true)}>
                Edit Logic
              </Button>
            </FlexRow>
          </>
        )}
        {showLogic && (
          <QuestionLogic
            question={section}
            onEditQ={onEditQ}
            allQuestions={allQuestions}
            onEditQs={onEditQs}
          />
        )}
      </div>
    </div>
  );
}
