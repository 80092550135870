import React from "react";
import styles from "./StatusItem.module.scss";

function StatusItem({
  children,
  blue,
  gray,
  red,
  sapphire,
  green,
  seafoam,
  yellow,
  orange,
  dullblue,
  style,
  className,
  title,
  icon
}) {
  return (
    <div
      className={`${styles.status} ${blue && styles.blue} ${
        gray && styles.gray
      } ${red && styles.red} ${sapphire && styles.sapphire} ${
        seafoam && styles.seafoam
      } ${green && styles.green} ${yellow && styles.yellow} ${
        orange && styles.orange
      } ${dullblue && styles.dullblue}  ${className}`}
      style={style}
    >
      {icon && <i className={`bi-${icon}`}></i>}
      {title && <span className={styles.title}>{title}</span>}
      {children}
    </div>
  );
}

export default StatusItem;
