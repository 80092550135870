//External
import React, { useEffect, useState } from "react";

//Internal
import styles from "./Section.module.scss";
import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";

/**
 * A component that displays the current section
 * @param {function} section the section being used to display
 * @returns {React.ReactElement} a sectionConstruction component
 */
const Section = ({ section, settings }) => {
  useEffect(() => {
    if (typeof section.sectionSettings === "string") {
      section.sectionSettings = JSON.parse(section.sectionSettings);
    }
  }, []);

  return (
    <div
      className={styles.sectionContainer}
      style={{
        boxShadow: section.sectionSettings?.sectionShadow,
        backgroundColor: section.sectionSettings?.hasBackgroundColor
          ? section.sectionSettings?.backgroundColor
          : "transparent",
      }}
    >
      {section.bodyText != "" && (
        <TextEditorTry encoding={section.encoding} />
      )}
    </div>
  );
};

export default Section;
