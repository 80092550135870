import React, { useEffect, useState } from "react";
import styles from "./ProgramIterations.module.scss";
import {
  useGetProgramSurveys,
  useGetProgramSurveysCount,
} from "api/resources/projects/projects";
import { PaginationContainer } from "components/tables/BasicTable/PaginationContainer";
import { Pagination } from "components/tables/BasicTable/Pagination";
import SurveyProjects from "components/tables/BasicTable/SurveyWidgets/SurveyProjects/SurveyProjects";
import { Sorting } from "components/tables/BasicTable/InteractiveSurveysTable";
import Button from "components/Button/Button";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { BasicTable } from "components/tables/BasicTable/BasicTable";
import { useNavigate } from "react-router-dom";
import { Loading } from "components/Loading/Loading";

const headers = [
  {
    id: 0,
    index: 0,
    name: "Iteration Name",
    accessor: "name",
    enabled: true,
    sort: false,
    canSort: true,
    cell_style: null,
  },
  {
    id: 2,
    index: 2,
    name: "Status",
    accessor: "status",
    enabled: true,
    sort: false,
    canSort: true,
    // cell_style: (value) => (
    //   <span
    //     className={`${styles.status} ${
    //       isOpen(value) ? `${styles.isopen}` : `${styles.isclosed}`
    //     }`}
    //   >
    //     {/* {console.log(e)} */}
    //     {value}{" "}
    //   </span>
    // ),
  },
  {
    id: 3,
    index: 3,
    name: "Responses",
    accessor: "responseCount",
    enabled: true,
    sort: false,
    descend: false,
    canSort: true,
    // cell_style: (value) => <ProjResponseCount projId={value} />,
    cell_style: null,
  },
  {
    id: 4,
    index: 4,
    name: "Launched On",
    accessor: "startedAt",
    enabled: true,
    sort: true,
    canSort: true,
    cell_style: null,
  },
];

function ProgramIterations({ show, setShow, roles, programId, mainSurveyId }) {
  const [perPage, setPerPage] = useState(15);
  const [pageSkip, setPageSkip] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [sort, setSort] = useState({
    item: "startedAt",
    descend: true,
  });
  const [widgetView, setWidgetView] = useState(true);

  const surveys = useGetProgramSurveys(
    programId,
    mainSurveyId,
    pageSkip,
    perPage,
    sort
  );
  const surveysCount = useGetProgramSurveysCount(
    programId,
    mainSurveyId,
    pageSkip,
    perPage,
    sort
  );

  useEffect(() => {
    surveys.refetch();
  }, [pageSkip, perPage, sort, currPage]);

  const navigate = useNavigate();
  function handleRowClick(survey) {
    navigate(`/project/${survey?.id}/survey-details`);
  }

  return (
    <div className={styles.page}>
      {(surveys.isLoading || surveysCount.isLoading) && <Loading></Loading>}
      {show && surveys.isSuccess && surveysCount.isSuccess && (
        <>
          <div className={styles.tableHeader}>
            {widgetView && <Sorting sort={sort} setSort={setSort}></Sorting>}
            <div
              style={{
                display: "flex",
                gap: "1em",
                paddingTop: ".25em",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: ".25em",
                }}
              >
                <div
                  className={`${styles.icon} ${
                    !widgetView ? styles.active : ""
                  }`}
                  onClick={() => setWidgetView(false)}
                >
                  <i className="bi bi-list-ul"></i>
                </div>
                <div
                  className={`${styles.icon} ${
                    widgetView ? styles.active : ""
                  }`}
                  onClick={() => {
                    setWidgetView(true);
                  }}
                >
                  <i className="bi bi-grid"></i>
                </div>
              </div>

              <Button
                shadow
                //   disable={disableActionButton}
                //   onClick={
                //     onClickActionButton
                //       ? onClickActionButton
                //       : () => console.log("click")
                //   }
                onClick={() => setShow(false)}
              >
                Go Back
              </Button>

              <div className={styles.searchBox}>
                <NavTextField
                  value={""}
                  setValue={(val) => {
                    setSearchString(val);
                  }}
                  placeholder={"Search..."}
                  //   items={searchSurveyQuery?.data?.surveys}
                />
                {/* {searchSurveyQuery.isSuccess && !searchSurveyQuery.isRefetching && (
              <div className={styles.items}>
                {searchSurveyQuery?.data?.surveys.map((survey) => (
                  <div
                    className={styles.item}
                    onClick={() => onRowClick(survey)}
                  >
                    <span> {survey?.name}</span>
                    <span> {survey?.status}</span>
                  </div>
                ))}
              </div>
            )} */}
              </div>
            </div>
          </div>
          {widgetView && (
            <SurveyProjects
              surveys={surveys?.data?.surveys}
              iterations
              onClick={handleRowClick}
              // draft
              // onAdd={onClickActionButton}
              // onClickSeeMore={() => {
              //   setChosenStatus("Draft");
              //   setSeeMore(true);
              // }}
              roles={roles}
              refetch={() => surveys.refetch()}
            />
          )}
          {!widgetView && (
            <div style={{ padding: "1em 2em" }}>
              <BasicTable
                initheaders={headers}
                data={surveys?.data?.surveys}
                //   onRowClick={onRowClick}
                sort={sort}
                setSort={setSort}
              ></BasicTable>
            </div>
          )}

          <PaginationContainer
            style={{
              padding: "1em 1em",
              // paddingRight: !widgetView ? "3em" : "",
              maxHeight: "fit-content",
              position: "sticky",
              bottom: "0",
              // justifyContent: widgetView ? "center" : ""
            }}
          >
            <Pagination
              pageNum={currPage}
              setPageNum={setCurrPage}
              maxPage={
                surveysCount?.data?.count / perPage < 1
                  ? 1
                  : Math.ceil(surveysCount?.data?.count / perPage)
              }
              length={surveys?.data?.surveys.length}
              searchString={""}
              perPage={perPage}
              setPerPage={setPerPage}
              setPageSkip={setPageSkip}
            ></Pagination>
          </PaginationContainer>
        </>
      )}
    </div>
  );
}

export default ProgramIterations;
