//External
import React, { useState, useRef } from "react";

//internal
import {
  useRegisterRequest,
  useValidateJoinCode,
  useValidateEmail,
} from "api/resources/authentication";
import styles from "./Register.module.scss";
import reactionLogo from "assets/images/reactionlogonew.png";
import { TextFieldSimple } from "components/inputs/input_fields/TextFieldSimple/TextFieldSimple";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { SelectFieldCustom } from "components/inputs";
import { countryphonecodes } from "assets/functions/Variables";
import { Player } from "@lottiefiles/react-lottie-player";
import { useValidCodeReturnOrg } from "api/resources/authentication/register";

export function Register({ join }) {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  const registerUserRequest = useRegisterRequest();
  const validateCode = useValidateJoinCode();
  const validateCodeOrg = useValidCodeReturnOrg();
  const validateEmail = useValidateEmail();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [prefix, setPrefix] = useState();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [joinCode, setJoinCode] = useState("");
  const [validCode, setValidCode] = useState();
  const [org, setOrg] = useState();
  const [invalidCodeMessage, setInvalidCodeMessage] = useState();
  const [invalidEmailMessage, setInvalidEmailMessage] =
    useState("Reqire an Email");
  const [invalidPasswordMessage, setInvalidPasswordMessage] = useState(
    "Password must be at least 8 characters. Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 special character."
  );
  const [validEmail, setValidEmail] = useState();
  const [validFirstName, setValidFirstName] = useState();
  const [validLastName, setValidLastName] = useState();
  const [validPassword, setValidPassword] = useState();
  const [validPosition, setValidPosition] = useState();
  const [validConfirmPassword, setValidConfirmPassword] = useState();
  const [accountType, setAccountType] = useState(
    join ? "Join Organization" : "Personal"
  );
  const [phoneType, setPhoneType] = useState("+1");
  const [orgNewAccount, setOrgNewAccount] = useState();
  const [validate, setValidate] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(joinCode);
    if (firstName === "") {
      setValidFirstName(false);
    }
    if (lastName === "") {
      setValidLastName(false);
    }
    if (password === "") {
      setValidPassword(false);
    }
    if (email === "" || !email.includes("@")) {
      setValidEmail(false);
    }
    if (joinCode === "") {
      setValidCode(false);
    }
    if (
      validCode &&
      validFirstName &&
      validLastName &&
      validEmail &&
      validPassword &&
      validConfirmPassword
    ) {
      registerUserRequest.mutate(
        {
          data: {
            firstName: firstName,
            lastName: lastName,
            prefix: prefix,
            email: email,
            password: password,
            joinCode: joinCode,
            phone: phone,
            phoneCode: phoneType,
            loggedIn: false,
          },
        },
        {
          onSuccess: (data) => {
            setValidate(true);
            //send email to verify here
          },
        }
      );
    }
  };

  function validateJoinCode() {
    if (accountType === "Join Organization") {
      validateCodeOrg.mutate(
        {
          validCodeForUserId: joinCode,
        },
        {
          onSuccess: (data) => {
            setValidCode(data.organization ? true : false);
            setOrg(data.organization);
            setInvalidCodeMessage("Invalid Code");
          },
          onError: () => {
            setValidCode(false);
            setInvalidCodeMessage("Invalid Code");
          },
        }
      );
    } else {
      validateCode.mutate(
        {
          validCodeForUserId: joinCode,
        },
        {
          onSuccess: (data) => {
            setValidCode(data.validCodeForUser);
            setInvalidCodeMessage("Invalid Code");
          },
        }
      );
    }
  }

  function validateUserEmail() {
    validateEmail.mutate(
      {
        email: email,
      },
      {
        onSuccess: (data) => {
          setValidEmail(data.validateEmail);
          setInvalidEmailMessage("Invalid Email");
        },
      }
    );
  }

  function validateUserPassword(p) {
    //   check if meets length requirement
    //   check if contains number
    //   check if contains capital letter
    //   check if contains lower case letter
    //   check if contains special character
    //   If doesn't meet requirments display message with what is missing
    let message = "";
    let valid = true;
    if (p.length < 8 || p.length > 64) {
      message =
        message +
        "Password must be at least 8 characters and less than 64 characters. ";
      valid = false;
    }
    if (!/[A-Z]/.test(p)) {
      message = message + "Password must contain at least 1 uppercase letter. ";
      valid = false;
    }
    if (!/[a-z]/.test(p)) {
      message = message + "Password must contain at least 1 lowercase letter. ";
      valid = false;
    }
    if (!/[0-9]/.test(p)) {
      message = message + "Password must contain at least 1 number. ";
      valid = false;
    }
    if (!/[" !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~"]/.test(p)) {
      message =
        message + "Password must contain at least 1 special character. ";
      valid = false;
    }
    setInvalidPasswordMessage(message);
    setValidPassword(valid);
  }

  function handleChangeFirstName(name) {
    //e.target.value
    console.log(name);
    setFirstName(name);
    setValidFirstName(true);
  }

  function handleChangeLastName(name) {
    console.log(name);
    setLastName(name);
    setValidLastName(true);
  }

  function handleChangeEmail(email) {
    setEmail(email);
    validateUserEmail();
  }

  function handleChangePhone(phone) {
    setPhone(phone);
  }

  function handleChangePosition(position) {
    setValidPosition(position);
  }

  function handleChangeJoinCode(code) {
    setJoinCode(code);
    // validateJoinCode();
  }

  function handleChangePassword(password) {
    setPassword(password);
    validateUserPassword(password);
  }

  function handleChangeValidatePassword(p) {
    if (p === password) {
      setValidConfirmPassword(true);
    } else {
      setValidConfirmPassword(false);
    }
  }

  const accountTypes = [
    { value: "Personal", label: "Personal" },
    { value: "Business", label: "Business" },
    // { value: "Join Organization", label: "Join Organization" },
    { value: "Academic", label: "Academic" },
  ];

  const player1 = useRef(null);
  const player2 = useRef(null);

  return (
    <div className={styles.page}>
      <div className={styles.splitPageContent}>
        {validate && (
          <div
            className={styles.formContainer}
            style={{ paddingBottom: "4rem" }}
          >
            <div className={styles.imageContainer}>
              <img src={reactionLogo} />
            </div>
            <span>
              <div className={styles.header_3} style={{ margin: "0" }}>
                Check your email
              </div>
              <br />
              We just sent you an email to verify your email address. <br />
              <br />
              If you do not recieve an email after 5 minutes check your junk or
              spam folder. If email is still not found, click "Resend Email"
              below.
            </span>
            <FlexRow>
              <Button blue link>
                Resend Email
              </Button>
            </FlexRow>
          </div>
        )}

        {!validate && (
          <div className={styles.formContainer}>
            {!org && (
              <div className={styles.imageContainer}>
                <img src={reactionLogo} />
              </div>
            )}
            {org && (
              <div className={styles.imageContainer}>
                <img src={org?.image[0]?.imageURL} alt={org?.name} />
              </div>
            )}

            {/* <span>
              Register a new account below <br />{" "}
              <div className={styles.text_3}>
                You must have an invitation code to set up an account
              </div>
            </span> */}

            {accountType === "Join Organization" && !validCode && (
              <>
                <TextFieldSimple
                  label="Organization Join Code"
                  type="text"
                  onChange={handleChangeJoinCode}
                  valid={validCode}
                  invalidMessage={invalidCodeMessage}
                  onSave={handleChangeJoinCode}
                />
                <div className={styles.buttonContainer}>
                  <Button
                    id="submitButton"
                    blue
                    type="submit"
                    shadow
                    width={80}
                    disable={!joinCode}
                    onClick={validateJoinCode}
                  >
                    Continue
                  </Button>
                </div>
              </>
            )}

            {(accountType === "Personal" ||
              accountType === "Business" ||
              accountType === "Academic") &&
              !validCode && (
                <>
                <span>Create a new account</span>
                  <TextFieldSimple
                    label="Invitation Code*"
                    type="text"
                    onChange={handleChangeJoinCode}
                    valid={joinCode && validCode}
                    invalidMessage={invalidCodeMessage}
                    onSave={handleChangeJoinCode}
                  />
                  <div className={styles.buttonContainer}>
                    <Button
                      id="submitButton"
                      blue
                      type="submit"
                      shadow
                      width={80}
                      disable={!joinCode}
                      onClick={validateJoinCode}
                    >
                      Continue
                    </Button>
                  </div>
                </>
              )}

            {validCode && accountType === "Join Organization" && (
              <>
                <span>What account will you be joining with?</span>
                <div style={{ display: "flex", gap: "3em", width: "100%" }}>
                  <div
                    className={`${styles.typeBox} ${
                      orgNewAccount === true ? styles.typeBoxFill : ""
                    }`}
                    onClick={() => setOrgNewAccount(true)}
                  >
                    <div className={styles.check}>
                      {orgNewAccount === true && (
                        <Player
                          autoplay
                          onEvent={(e) => {
                            if (e === "play") {
                              setTimeout(() => {
                                player1?.current?.pause();
                              }, 2000);
                            }
                          }}
                          ref={player1}
                          src={require("assets/animations/checkmarkgreen.json")}
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      )}
                    </div>

                    <h3>New Account</h3>
                    {/* <div
                      className={styles.description2}
                      style={{ padding: "0em", margin: "0em" }}
                    >
                     Create a new account to join with
                    </div> */}
                  </div>
                  <div
                    className={`${styles.typeBox} ${
                      orgNewAccount === false ? styles.typeBoxFill : ""
                    }`}
                    onClick={() => setOrgNewAccount(false)}
                  >
                    <div className={styles.check}>
                      {orgNewAccount === false && (
                        <Player
                          autoplay
                          ref={player2}
                          onEvent={(e) => {
                            if (e === "play") {
                              setTimeout(() => {
                                player2?.current?.pause();
                              }, 2000);
                            }
                          }}
                          src={require("assets/animations/checkmarkgreen.json")}
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      )}
                    </div>

                    <h3>Existing Account</h3>
                    {/* <div
                      className={styles.description2}
                      style={{ padding: "0em", margin: "0em" }}
                    >
                      Use an account that already exists
                    </div> */}
                  </div>
                </div>

                {/* <div className={styles.buttonContainer}>
                  <Button
                    id="submitButton"
                    blue
                    type="submit"
                    shadow
                    width={80}
                    disable={!joinCode}
                    onClick={validateJoinCode}
                  >
                    Continue
                  </Button>
                </div> */}
              </>
            )}

            {validCode && orgNewAccount === false && (
              <>
                {" "}
                <TextFieldSimple
                  id="emailField"
                  type="text"
                  onChange={handleChangeEmail}
                  // placeholder="Email"
                  valid={validEmail}
                  invalidMessage={invalidEmailMessage}
                  label="Email*"
                />{" "}
                <TextFieldSimple
                  type="text"
                  onChange={handleChangePassword}
                  // placeholder="Password"
                  valid={password && validPassword}
                  invalidMessage={invalidPasswordMessage}
                  password
                  label="Password*"
                />
                <div className={styles.buttonContainer}>
                  <Button
                    id="submitButton"
                    blue
                    type="submit"
                    shadow
                    width={80}
                    disable={!validPassword || !password || !validEmail}
                    onClick={handleSubmit}
                  >
                    Join
                  </Button>
                </div>
              </>
            )}

            {accountType != "Join Organization" && validCode && (
              <>
                {" "}
                <span>{accountType} Account Creation</span>
                <SelectFieldCustom
                  options={accountTypes}
                  value={accountType}
                  onChange={(a) => setAccountType(a.value)}
                  label={"What type of account will you be using?"}
                  dropdownStyle={{ height: "7.5rem" }}
                ></SelectFieldCustom>
              </>
            )}

            {validCode &&
              (orgNewAccount === true ||
                accountType != "Join Organization") && (
                <>
                  <TextFieldSimple
                    type="text"
                    onChange={setPrefix}
                    label="Prefix"
                  />
                  <TextFieldSimple
                    type="text"
                    onChange={handleChangeFirstName}
                    label="First Name*"
                    valid={validFirstName}
                    invalidMessage="Require a First Name"
                  />
                  <TextFieldSimple
                    type="text"
                    onChange={handleChangeLastName}
                    label="Last Name*"
                    valid={validLastName}
                    invalidMessage="Require a Last Name"
                  />
                  <TextFieldSimple
                    id="emailField"
                    type="text"
                    onChange={handleChangeEmail}
                    // placeholder="Email"
                    valid={validEmail}
                    invalidMessage={invalidEmailMessage}
                    label="Email*"
                  />

                  <TextFieldSimple
                    id="emailField"
                    type="text"
                    onChange={handleChangeEmail}
                    // placeholder="Email"
                    valid={validEmail}
                    invalidMessage={invalidEmailMessage}
                    label="Confirm Email*"
                  />
                  <FlexRow style={{ alignItems: "flex-end", gap: ".5rem" }}>
                    <div style={{ width: "100px" }}>
                      <SelectFieldCustom
                        options={countryphonecodes}
                        value={phoneType}
                        onChange={(f) => setPhoneType(f.value)}
                        label="Code"
                        // label={"What type of account will you be using?"}
                        // dropdownStyle={{ height: "10rem" }}
                      ></SelectFieldCustom>
                    </div>

                    <TextFieldSimple
                      id="phoneField"
                      type="tel"
                      onChange={handleChangePhone}
                      // placeholder="Email"
                      // valid={validEmail}
                      // invalidMessage={invalidEmailMessage}
                      label="Phone Number"
                    />
                  </FlexRow>
                  {(accountType === "Business" ||
                    accountType === "Join Organization" ||
                    accountType === "Academic") && (
                    <TextFieldSimple
                      id="position"
                      type="text"
                      onChange={handleChangePosition}
                      label="Position Name"
                      valid={validPosition}
                    />
                  )}

                  <TextFieldSimple
                    type="text"
                    onChange={handleChangePassword}
                    // placeholder="Password"
                    valid={password && validPassword}
                    invalidMessage={invalidPasswordMessage}
                    password
                    label="Password*"
                  />

                  <TextFieldSimple
                    // label="Validate Password"
                    type="text"
                    onChange={handleChangeValidatePassword}
                    // placeholder="Confirm Password"
                    valid={validConfirmPassword}
                    invalidMessage={"Passwords must match"}
                    password
                    label="Confirm Password*"
                  />

                  <div className={styles.text_3}>
                    By clicking{" "}
                    {accountType === "Join Organization" ? "Join" : "Continue"},
                    I agree to Reaction <Button link>Terms of Service</Button>{" "}
                    and <Button link>Privacy Statement</Button>
                  </div>
                  <div className={styles.buttonContainer}>
                    <Button
                      id="submitButton"
                      blue
                      type="submit"
                      shadow
                      width={80}
                      disable={
                        !validCode ||
                        !validConfirmPassword ||
                        !joinCode ||
                        !validPassword ||
                        !password ||
                        !validEmail
                      }
                      onClick={handleSubmit}
                    >
                      {accountType === "Join Organization"
                        ? "Join"
                        : "Continue"}
                    </Button>
                  </div>
                </>
              )}
            {/* <div className={styles.buttonContainer}>
            <Button
              id="submitButton"
              blue
              onClick={handleSubmit}
              type="submit"
              shadow
            >
              Register
            </Button>
          </div> */}

            {/* <HorizontalBar height="5px" backgroundColor="#E9E9E9" width="50%" /> */}

            {accountType === "Join Organization" && (
              <div className={styles.register}>
                Don&apos;t have an organization to join? Request a demo at{" "}
                <a href="https://reactiondata.com" className={styles.aLink}>
                  {" "}
                  reactiondata.com
                </a>
                .
              </div>
            )}
            {accountType != "Join Organization" && (
              <div className={styles.register}>
                Want to know more? Request a demo at{" "}
                <a href="https://reactiondata.com" className={styles.aLink}>
                  {" "}
                  reactiondata.com
                </a>
                .
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("reaction_token");
    if (tokenString != undefined) {
      return JSON.parse(tokenString);
    }
    return null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("reaction_token", JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
