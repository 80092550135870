import { TextFieldSimple } from "components/inputs";
import styles from "./ChooseLink.module.scss";
import { useState, useEffect } from "react";
import { useSearchProjectsPages } from "api/resources/projects/projects";
import { Loading } from "components/Loading/Loading";
import { trimDate } from "assets/functions/DateFunctions";
import { useSearchReports } from "api/resources/organization/reports";
import Button from "components/Button/Button";

export default function ChooseLink({ whenDone }) {
  const [survey, setSurvey] = useState(true);
  const [search, setSearch] = useState("");
  const [chosen, setChosen] = useState();

  useEffect(() => {
    setSearch("");
  }, [survey]);

  function onConfirm() {
    const settings = {
      projectId: survey ? chosen.id : "",
      reportId: survey ? "" : chosen.id,
    };
    whenDone(settings);
  }

  return (
    <div className={styles.chooseLink}>
      {!chosen && (
        <>
          <div className={styles.whatFor}>
            <div
              className={`${styles.for} ${
                survey ? styles.active : styles.inactive
              }`}
              onClick={() => setSurvey(true)}
            >
              Surveys
            </div>
            <div
              className={`${styles.for} ${
                survey ? styles.inactive : styles.active
              }`}
              onClick={() => setSurvey(false)}
            >
              Reports
            </div>
          </div>
          <div className={styles.search}>
            <TextFieldSimple
              value={search}
              onChange={(val) => setSearch(val)}
              placeholder="Search"
            />
          </div>
          {survey ? (
            <Surveys search={search} setChosen={setChosen} />
          ) : (
            <Reports search={search} setChosen={setChosen} />
          )}
        </>
      )}
      {chosen && (
        <div className={styles.confirmation}>
          <div className={styles.text}>
            Confirm link for{" "}
            <span className={styles.nameConf}>{"'" + chosen.name + "'"}</span> ?
          </div>
          <div className={styles.preview}>
            <div className={styles.iconHolder}>
              <div className={styles.icon}>
                <i
                  className={
                    survey
                      ? "bi-card-list"
                      : chosen.icon
                      ? `bi-${chosen.icon}`
                      : "bi-clipboard-data"
                  }
                ></i>
              </div>
            </div>
            <div className={styles.ends} style={{ alignItems: "flex-start" }}>
              {!survey && chosen.updatedAt
                ? `Updated: ${trimDate(chosen.updatedAt)}`
                : ""}
            </div>
            <div className={styles.name}>{chosen.name}</div>
            <div className={styles.ends}>
              {survey ? (
                <>
                  <span>
                    {chosen.startedAt ? (
                      `Opened: ${trimDate(chosen.startedAt)}`
                    ) : chosen.status === "Draft" ? (
                      <span className={styles.draft}>Draft</span>
                    ) : (
                      " "
                    )}
                  </span>
                  <span>
                    <span className={styles.responseCount}>
                      {`${chosen.responseCount} `}
                    </span>
                    Responses
                  </span>
                </>
              ) : (
                <>
                  <span>
                    {getPages(chosen)}
                    {getVisuals(chosen)}
                  </span>

                  {chosen.sharedReport.some((s) => s.organizationId) && (
                    <span className={styles.orgWide}>
                      <i className="bi bi-buildings"></i>
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={styles.buttons}>
            <Button onClick={() => setChosen(null)} shadow>
              {" "}
              <div className={styles.inBtn}>
                Cancel<i className="bi bi-x"></i>
              </div>
            </Button>
            <Button blue shadow>
              <div className={styles.inBtn} onClick={onConfirm}>
                Confirm<i className="bi bi-check2"></i>
              </div>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

function Surveys({ search, setChosen }) {
  const fetchSurveys = useSearchProjectsPages(
    search,
    0,
    0,
    {
      item: "startedAt",
      descend: true,
    },
    "surveys"
  );

  return (
    <div className={styles.options}>
      {fetchSurveys.isLoading && <Loading />}
      {fetchSurveys.isSuccess &&
        fetchSurveys.data.surveys.map((s) => (
          <div
            className={styles.option}
            key={s.id}
            onClick={() => setChosen(s)}
          >
            {s.name}

            {s.status !== "Draft" && (
              <div className={styles.launched}>
                <span>
                  {s.startedAt ? `Opened: ${trimDate(s.startedAt)}` : " "}
                </span>
                <span>
                  <span className={styles.responseCount}>
                    {`${s.responseCount} `}
                  </span>
                  Responses
                </span>
              </div>
            )}
            {s.status === "Draft" && <div className={styles.draft}>Draft</div>}
          </div>
        ))}
    </div>
  );
}

function getPages(r) {
  const pages = r.analysis.length;
  let string = pages == 1 ? "Page" : "Pages";
  return (
    <>
      <span className={styles.pages}>{pages}</span> {string}
    </>
  );
}

function getVisuals(r) {
  const vizs = r.analysis.reduce(
    (count, anal) => count + anal.visualization.length,
    0
  );
  let string = vizs == 1 ? "Visual" : "Visuals";

  return (
    <>
      {" "}
      <span className={styles.visuals}>{vizs}</span> {string}
    </>
  );
}

function Reports({ search, setChosen }) {
  const fetchReports = useSearchReports(search);

  return (
    <div className={styles.options}>
      {fetchReports.isLoading && <Loading />}
      {fetchReports.isSuccess &&
        fetchReports.data.reports.map((r) => (
          <div
            className={styles.option}
            key={r.id}
            onClick={() => setChosen(r)}
          >
            {r.name}

            <div className={styles.launched}>
              <span>
                {getPages(r)}
                {getVisuals(r)}
              </span>

              {r.sharedReport.some((s) => s.organizationId) && (
                <div className={styles.orgWide}>
                  <i className="bi bi-buildings"></i>
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}
