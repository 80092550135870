import {
  useCreateAudienceGql,
  useUpdateAudienceGql,
} from "api/resources/contacts/audiences";
import React, { useState } from "react";
import styles from "./CreateAudience.module.scss";
import { SelectField, TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { maintimezones } from "assets/functions/Variables";
import { Label } from "components/layouts/Label/Label";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import IconShopperModal from "pages/results/Charts/Settings/AccordianItems/MetricSettings/IconShopper/IconShopperModal";
import Icon from "components/Icon/Icon";
import FlexRow from "components/layouts/FlexRow/FlexRow";

function CreateAudience({ audience, setSettings, filters }) {
  const [name, setName] = useState(audience?.name);
  const [icon, setIcon] = useState(
    audience?.icon ? audience?.icon : "people-fill"
  );
  const [iconColor, setIconColor] = useState(
    audience?.iconColor ? audience?.iconColor : "#15bcc7"
  );
  const [changeIcon, setChangeIcon] = useState(false);
  const [description, setDescription] = useState(audience?.description);
  const [saveState, setSaveState] = useState("Create");
  const [timezone, setTimezone] = useState(
    maintimezones.find((t) => t.value === audience?.timeZone)
  );

  let navigate = useNavigate();

  const createAudienceQuery = useCreateAudienceGql();

  function saveChanges() {
    setSaveState(
      <div style={{ display: "flex", gap: ".5em", width: "fit-content" }}>
        Saving... <Loading height={20} width={20}></Loading>
      </div>
    );
    const data = {
      name: name,
      description: description,
      timeZone: timezone?.timezone,
      icon: icon,
      iconColor: iconColor,
      filters: filters ? JSON.stringify(filters) : undefined,
      dynamic: filters ? true : false
    };

    createAudienceQuery.mutate(
      {
        data: data,
      },
      {
        onSuccess: (data) => {
          setSaveState(
            <div>
              Saved <i className="bi bi-check"></i>
            </div>
          );
          setTimeout(() => {
            setSaveState("Save Changes");
          }, 2000);

          navigate("/contacts/audiences/" + data?.audience?.id);
        },
      }
    );
  }

  return (
    <div className={styles.settingsPage}>
      <div className={styles.settingsSection}>
        <div className={styles.header}>Audience Settings</div>
        {filters?.length > 0 && (
          <FlexRow start style={{ width: "fit-content" }}>
            <span className={styles.label5}>Current View:</span>
            {filters?.length === 0 && (
              <div
                className={styles.filter}
                onClick={() => setShowFilters(true)}
              >
                Contacts to be added:
              </div>
            )}
            {filters?.length > 0 &&
              filters?.map((filter) => (
                <div
                  className={styles.filter}
                  onClick={() => setShowFilters(true)}
                >
                  {filter?.label}:
                  {filter?.properties?.map((prop, i) => (
                    <div className={styles.prop}>
                      {prop?.label}
                      {i != filter?.properties?.length - 1 ? "," : ""}
                    </div>
                  ))}
                </div>
              ))}{" "}
          </FlexRow>
        )}
      </div>
      <div className={styles.settingsSection} style={{ border: "none" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "500px",
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            <TextFieldSimple
              value={name}
              label="Audience Name *"
              onChange={(val) => setName(val)}
              placeholder="Name"
            ></TextFieldSimple>
            <TextFieldSimple
              value={description}
              label="Audience Description"
              onChange={(val) => setDescription(val)}
              placeholder="Description"
            ></TextFieldSimple>
            <SelectField
              value={timezone}
              label="Time Zone"
              options={maintimezones}
              onChange={(val) => setTimezone(val)}
            ></SelectField>
            <div className={styles.icon}>
              <Label>Icon: </Label>
              <i
                className={"bi-" + icon}
                style={{ color: iconColor }}
                onClick={() => setChangeIcon(true)}
              ></i>
              <ColorPicker
                defaultColor={iconColor}
                onChange={setIconColor}
              ></ColorPicker>
            </div>
            {changeIcon && (
              <IconShopperModal
                onPick={(i) => {
                  setIcon(i);
                }}
                onClose={() => setChangeIcon(false)}
              ></IconShopperModal>
            )}
          </div>
        </div>{" "}
      </div>
      {/* <HorizontalBar height="2px" backgroundColor="lightgray"></HorizontalBar> */}
      {/* Notification and Permission Settings Coming soon... */}
      <div
        className={styles.settingsSection}
        style={{ position: "sticky", bottom: "0" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1em",
              alignItems: "center",
            }}
          >
            <Button link onClick={() => setSettings(false)}>
              Cancel
            </Button>
            <Button shadow onClick={saveChanges} disable={!name} blue>
              {saveState}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateAudience;
