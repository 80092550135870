import React from "react";
import styles from "./ContactActivity.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { trimDate } from "assets/functions/DateFunctions";

const activities = [
  {
    type: "email",
    icon: "envelope-fill",
    name: "Email from Nate Hanson",
    date: new Date(),
  },
  {
    type: "survey-answer",
    icon: "check-square-fill",
    name: "Participated in Beta NPS Survey",
    date: new Date(),
  },
  {
    type: "email",
    icon: "envelope-fill",
    name: "Email from Nate Hanson",
    date: new Date(),
  },
  {
    type: "update",
    icon: "pencil-fill",
    name: "Updated by Nate Hanson",
    date: new Date(),
  },
  {
    type: "create",
    icon: "pencil-fill",
    name: "Created by Jeremy Bikman",
    date: new Date(),
  },
];

function ContactActivity({}) {
  return (
    <div className={styles.page}>

      <div className={styles.activities}>
      <FlexRow> <div className={styles.header_4} style={{margin: "0", marginBottom: "1rem"}}>Activity Timeline</div> </FlexRow>
        {activities.map((activity) => (
          <Activity activity={activity}></Activity>
        ))}
      </div>
      <div className={styles.other}></div>
    </div>
  );
}

export default ContactActivity;

function Activity({ activity }) {
  return (
    <div className={styles.activity}>
      <FlexRow>
        <FlexRow start>
          <div className={styles.activityIcon}>
            <i className={`bi-${activity.icon}`}></i>
          </div>
          <div className={styles.label6}>{activity.name}</div>
        </FlexRow>
        <div className={styles.label6} style={{whiteSpace: "nowrap"}}>{trimDate(activity.date)}</div>
      </FlexRow>
      <div className={styles.activityContent}>
      <div className={styles.label6}>Details</div>
        <div className={styles.content}></div>

      </div>
    </div>
  );
}
