import { Label } from "components/layouts/Label/Label";
import styles from "./MetricSettings.module.scss";
import { useEffect, useState } from "react";
import { SelectField, TextFieldSimple } from "components/inputs";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import IconShopper from "./IconShopper/IconShopper";
import Button from "components/Button/Button";
import { useGetOldQMetric } from "api/resources/account/widget";

export default function Customizer({
  metric,
  onDone,
  onCancel,
  setMetric,
  bigMetric,
  filters,
}) {
  const [showSave, setShowSave] = useState(false);

  function clickOut(event) {
    if (event.target === document.getElementById("outside")) {
      onCancel();
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOut, true);
    return () => {
      document.removeEventListener("click", clickOut, true);
    };
  }, []);

  function handleSave() {
    let m = { ...metric };
    m.complete = true;
    delete metric.options;
    onDone(m);
  }

  function change(field, val) {
    let m = { ...metric };
    m[field] = val;
    setMetric(m);
  }

  return (
    <div className={styles.customMetricBackground} id="outside">
      <div className={styles.customMetricPopout}>
        <i
          className={`${"bi bi-x-lg"} ${styles.customMetricExit}`}
          onClick={onCancel}
        ></i>
        {metric?.custom && (
          <CustomMetric
            metric={metric}
            change={change}
            onSave={handleSave}
            showSave={showSave}
          />
        )}
        {metric?.question && (
          <QuestionMetric
            metric={metric}
            change={change}
            onSave={handleSave}
            showSave={showSave}
            bigMetric={bigMetric}
            filters={filters}
          />
        )}
      </div>
    </div>
  );
}

function CustomMetric({ metric, change, onSave }) {
  return (
    <div className={styles.col} style={{ gap: "15px" }}>
      <div style={{ display: "flex", gap: 30 }}>
        <div className={styles.customMetricDisplay}>
          <div className={styles.smallMetric}>
            <div
              className={styles.icon}
              style={!metric.showIcon ? { visibility: "hidden" } : undefined}
            >
              <i className={`bi bi-${metric.icon}`}></i>
            </div>
            <div className={styles.text} style={{ gap: "5px" }}>
              <div className={styles.mTitle}>
                <TextFieldSimple
                  value={metric.title}
                  onChange={(val) => change("title", val)}
                  style={{ fontWeight: "400", color: "#a3a4a8" }}
                  placeholder="Title"
                />
              </div>
              <div className={styles.mmetric}>
                <TextFieldSimple
                  value={metric.metric}
                  onChange={(val) => change("metric", val)}
                  style={{ color: "#050606" }}
                  placeholder="value"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={styles.customMetricSettings}
          style={{ marginRight: "15px" }}
        >
          <div className={styles.row} style={{ gap: "15px", width: "100%" }}>
            <Label
              style={{
                fontWeight: "500",
                color: "#8dabb2",
                width: "fit-content",
              }}
            >
              Show Icon
            </Label>
            <ToggleSwitch
              startChecked={metric.showIcon}
              handleCheck={(val) => change("showIcon", val)}
            />
          </div>

          {metric.showIcon && (
            <IconShopper onPick={(val) => change("icon", val)} />
          )}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          visibility: metric.title || metric.metric ? "visible" : "hidden",
        }}
      >
        <Button onClick={onSave}>Add</Button>
      </div>
    </div>
  );
}

function QuestionMetric({ metric, change, onSave, bigMetric, filters }) {
  function init() {
    let it = {
      questionIds: [],
      filters: filters ? filters : "{}",
    };
    it[metric.code] = true;

    return it;
  }

  const [setUp, setSetUp] = useState(init());

  const getQMetric = useGetOldQMetric(setUp);

  function onQSelect(questions) {
    let newSetUp = { ...setUp };
    let qs = questions.map((opt) => opt.value)
    newSetUp.questionIds = qs;
    setSetUp(newSetUp);
    change("question", qs);
  }

  function getQOptions() {
    let options = metric?.options.map((q) => {
      return {
        label: (
          <div className={styles.col} style={{ alignItems: "center" }}>
            {q.questionText}{" "}
            <div className={styles.customizerQProjName}>{q.project.name}</div>
          </div>
        ),
        value: q.id,
      };
    });
    return options;
  }

  return (
    <div className={styles.col} style={{ gap: "15px" }}>
      <div style={{ display: "flex", gap: 45 }}>
        <div className={styles.customMetricDisplay}>
          {!bigMetric && (
            <div className={styles.smallMetric}>
              <div
                className={styles.icon}
                style={!metric.showIcon ? { visibility: "hidden" } : undefined}
              >
                <i className={`bi bi-${metric.icon}`}></i>
              </div>
              <div className={styles.text} style={{ gap: "5px" }}>
                <div className={styles.mTitle}>{metric.title}</div>
                <div className={styles.mmetric}>
                  {getQMetric.isSuccess && getQMetric.data.getQMetric !== null
                    ? getQMetric.data.getQMetric
                    : ""}
                </div>
              </div>
            </div>
          )}

          {bigMetric && (
            <div className={styles.bigMetric}>
              <div className={styles.MTitle}>{metric.title}</div>
              {getQMetric.isSuccess && (
                <div className={styles.Mmetric}>
                  {getQMetric.isSuccess && getQMetric.data.getQMetric !== null
                    ? getQMetric.data.getQMetric
                    : ""}
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.customMetricSettings}>
          <div
            className={styles.col}
            style={{ gap: "5px", alignItems: "center" }}
          >
            <Label
              style={{
                fontWeight: "600",
                color: "#8dabb2",
                padding: 0,
                width: "fit-content",
              }}
            >
              Pick your Questions
            </Label>
              
            <SelectField
              onChange={onQSelect}
              // value={metric?.options.find(
              //   (opt) => opt.id === metric?.questionId
              // )}
              options={getQOptions()}
              selectMultiple
            ></SelectField>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          visibility: metric?.question?.length ? "visible" : "hidden",
        }}
      >
        <Button onClick={onSave}>Save</Button>
      </div>
    </div>
  );
}
