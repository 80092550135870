//External
import React, { useState } from "react";

//internal
import styles from "./ForgotPassword.module.scss";
import reactionLogo from "assets/images/reactionlogodark.png";
import technology from "assets/images/laptop.png";
import { TextFieldSimple } from "components/inputs/input_fields/TextFieldSimple/TextFieldSimple";
import { Header } from "components/layouts";
import Button from "components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  useResetPassword,
  useSendResetPassword,
  useValidPassChangeRequest,
} from "api/resources/authentication/forgotpassword";
import { ErrorPage } from "pages/error_page/ErrorPage";
import { Loading } from "components/Loading/Loading";

export function ForgotPassword() {
  const { userId } = useParams();
  const validRequest = useValidPassChangeRequest(userId);
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  console.log(validRequest);

  const sendResetPassword = useSendResetPassword();
  const resetPassword = useResetPassword();

  const [email, setEmail] = useState("");
  const [sentInstructions, setSentInstructions] = useState(false);
  const [password, setPassword] = useState("");
  const [invalidEmailMessage, setInvalidEmailMessage] =
    useState("Reqire an Email");
  const [invalidPasswordMessage, setInvalidPasswordMessage] = useState(
    "Password must be at least 8 characters. Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 special character."
  );
  const [validEmail, setValidEmail] = useState();
  const [validPassword, setValidPassword] = useState();
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email != "" || email.includes("@")) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
    if (email != "" || email.includes("@")) {
      setLoading(true);
      sendResetPassword.mutate(
        {
          email: email,
        },
        {
          onSuccess: (data) => {
       
            if (data.sendResetPassword) {
              setSentInstructions(true);
            } else {
              setValidEmail(false)
              setInvalidEmailMessage("User email does not exist")
            }
            
            setLoading(false);
           
          },
        }
      );
    }
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (validConfirmPassword) {
      resetPassword.mutate(
        {
          password: password,
          id: userId,
        },
        {
          onSuccess: (data) => {
            console.log(data);
            alert("Password Changed");
            routeChange("/");
            //go to login page
          },
        }
      );
    }
  };

  function validateUserPassword(p) {
    //   check if meets length requirement
    //   check if contains number
    //   check if contains capital letter
    //   check if contains lower case letter
    //   check if contains special character
    //   If doesn't meet requirments display message with what is missing
    let message = "";
    let valid = true;
    console.log(p.length);
    if (p.length < 8 || p.length > 64) {
      console.log("print");
      message =
        message +
        "Password must be at least 8 characters and less than 64 characters. ";
      valid = false;
    }
    if (!/[A-Z]/.test(p)) {
      message = message + "Password must contain at least 1 uppercase letter. ";
      valid = false;
    }
    if (!/[a-z]/.test(p)) {
      message = message + "Password must contain at least 1 lowercase letter. ";
      valid = false;
    }
    if (!/[0-9]/.test(p)) {
      message = message + "Password must contain at least 1 number. ";
      valid = false;
    }
    if (!/[" !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~"]/.test(p)) {
      message =
        message + "Password must contain at least 1 special character. ";
      valid = false;
    }
    setInvalidPasswordMessage(message);
    setValidPassword(valid);
  }

  function handleChangeEmail(email) {
    setEmail(email);
    // validateUserEmail();
  }

  function handleChangePassword(password) {
    setPassword(password);
    validateUserPassword(password);
  }

  function handleChangeValidatePassword(p) {
    if (p === password) {
      setValidConfirmPassword(true);
    } else {
      setValidConfirmPassword(false);
    }
  }
  return (
    <>
      {validRequest.isSuccess && (
        <div className={styles.page}>
          <div className={styles.imageContainer}>
            <img src={reactionLogo} />
          </div>
          <div className={styles.splitPageContent}>
            <div className={styles.imageContainer2}>
              <img src={technology} />
            </div>
            <div className={styles.formContainer}>
              {userId === undefined &&
                sentInstructions === false &&
                !loading && (
                  <>
                    <Header title="Welcome!"></Header>
                    <span>
                      To reset your password, please provide your email.
                    </span>

                    
                      <div className={styles.form}>
                        <div>
                          <TextFieldSimple
                            id="emailField"
                            label="Email"
                            type="text"
                            onChange={handleChangeEmail}
                            placeholder="Email"
                            valid={validEmail}
                            invalidMessage={invalidEmailMessage}
                            
                          />
                        </div>

                        <div className={styles.buttonContainer}>
                          <Button
                            id="submitButton"
                            blue
                            onClick={handleSubmit}
                            type="submit"
                            shadow
                          >
                            Send Reset Instructions
                          </Button>
                        </div>
                      </div>
                 
                  </>
                )}
              {loading && <><Loading></Loading> <div className={styles.sending}>Sending Request...</div></>}
              {userId === undefined && sentInstructions === true && (
                <>
                  <Header title="Thank You!"></Header>
                  <span>
                    Please check your email to finish resetting your password.
                  </span>
                  <span>You may now exit this page.</span>
                </>
              )}
              {userId !== undefined &&
                validRequest.data?.User?.requestedPassChange && (
                  <>
                    <Header title="Welcome!"></Header>
                    <span>Please enter your new password</span>

                    <form onSubmit={handleResetPassword}>
                      <div className={styles.form}>
                        <div>
                          <TextFieldSimple
                            label="Password"
                            type="text"
                            onChange={handleChangePassword}
                            placeholder="Password"
                            valid={validPassword}
                            invalidMessage={invalidPasswordMessage}
                            password
                          />
                        </div>
                        <div>
                          <TextFieldSimple
                            label="Confirm Password"
                            type="text"
                            onChange={handleChangeValidatePassword}
                            placeholder="Confirm Password"
                            valid={validConfirmPassword}
                            invalidMessage={"Passwords must match"}
                            password
                          />
                        </div>

                        <div className={styles.buttonContainer}>
                          <Button
                            id="submitButton"
                            blue
                            onClick={handleResetPassword}
                            type="submit"
                            shadow
                          >
                            Reset Password
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              {/* {!validRequest.data?.User?.requestedPassChange && sentInstructions === false && <ErrorPage />} */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("reaction_token");
    if (tokenString != undefined) {
      return JSON.parse(tokenString);
    }
    return null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("reaction_token", JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
