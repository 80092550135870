import styles from './Bold.module.scss';

export function Bold({ bold, onChange }) {
  return (
    <div
      className={`${styles.bold} ${bold ? styles.bolden : ""}`}
      onClick={() => onChange(bold ? "" : "bold")}
    >
      <i className="bi-type-bold"></i>
    </div>
  );
}
