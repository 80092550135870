import React from "react";
import styles from "./LinkNewAccount.module.scss";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import salesforce from "assets/images/Salesforce.png";
import hubspot from "assets/images/hubspot.webp";
import linkedin from "assets/images/LinkedIn_Logo.png";
import google from "assets/images/google.png";
import stripe from "assets/images/stripe.png";
import axios from "axios";

function LinkNewAccount({ currUser }) {
  const handleLoginSalesforce = async () => {
    try {
      const url = `${process.env.REACT_APP_SALESFORCE_KEY}&userID=${currUser.id}`;
      const response = await axios.get(url, { withCredentials: true });
      window.location.href = response.data.loginUrl;
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.header}>Link New Account</div>
      <div className={styles.container}>
        <div className={styles.column}>
          {!currUser?.linkedAccounts?.find(
            (a) => a.accountType === "Salesforce"
          ) && (
            <CombinedInput
              select
              iconReplacement={
                <div
                  className={styles.link}
                  onClick={() => handleLoginSalesforce()}
                >
                  Link Account
                </div>
              }
              label={"Account"}
              value={{
                value: "salesforce",
                label: (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1em",
                    }}
                  >
                    <img src={salesforce} width={40}></img>Salesforce
                  </span>
                ),
              }}
            />
          )}
          <div className={styles.comingSoon}>Coming Soon...</div>
          <CombinedInput
            select
            // iconReplacement={<div className={styles.link}>Link Account</div>}
            label={"Account"}
            value={{
              value: "hubspot",
              label: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1em",
                  }}
                >
                  <img src={hubspot} width={40}></img>HubSpot
                </span>
              ),
            }}
          />
          <CombinedInput
            select
            // iconReplacement={<div className={styles.link}>Link Account</div>}
            label={"Account"}
            value={{
              value: "linkedin",
              label: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1em",
                  }}
                >
                  <img src={linkedin} width={40}></img>LinkedIn
                </span>
              ),
            }}
          />
          <CombinedInput
            select
            // iconReplacement={<div className={styles.link}>Link Account</div>}
            label={"Account"}
            value={{
              value: "google",
              label: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1em",
                  }}
                >
                  <img src={google} width={40}></img>Google
                </span>
              ),
            }}
          />
          <CombinedInput
            select
            // iconReplacement={<div className={styles.link}>Link Account</div>}
            label={"Account"}
            value={{
              value: "stripe",
              label: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1em",
                  }}
                >
                  <img src={stripe} width={40}></img>Stripe
                </span>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default LinkNewAccount;
