import React, { useState, useEffect, useRef } from "react";
import { ColorPicker } from "./ColorPicker";
import styles from "./ColorPicker.module.scss";

function ColorPickerContainer({
  remove,
  colors = [],
  defaultColor = "#ffffff",
  onChange,
  setShow,
  disable,
  colorRef,
  textColors,
  title,
  style
}) {
  const [showPicker, setShowPicker] = useState(false);

//   function clickOutside(e) {
//     console.log(colorRef)
//     if (ref.current && !ref.current?.contains(e.target) ) {
//       setShowPicker(false);
//     }
//   }

//   useEffect(() => {
//     document.addEventListener("click", clickOutside, true);
//     return () => {
//       document.removeEventListener("click", clickOutside, true);
//     };
//   }, []);

  const ref = useRef(null);
  return (
    <div className={styles.container} ref={ref} style={style}> 
      <div className={styles.label6}>{title}</div>
      <div className={styles.picker} onClick={() => setShowPicker(true)}>
        <div className={styles.pickerCon}>
          <ColorPicker
            remove={remove}
            colors={colors}
            defaultColor={defaultColor}
            onChange={onChange}
            setShow={setShow}
            disable={disable}
            colorRef={colorRef}
            anchorRef={ref}
            textColors={textColors}
            // show={showPicker}
            height="1.2rem"
            width="1.2rem"
          ></ColorPicker>
        </div>
        <span>{defaultColor}</span>
      </div>
    </div>
  );
}

export default ColorPickerContainer;
