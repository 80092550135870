import React, { useState, useEffect, useRef } from "react";
import {
  useAudienceQuery,
  useRemoveContactsFromAudience,
  useUpdateAudienceGql,
} from "api/resources/contacts/audiences";
import styles from "./Audience.module.scss";
import AddToAudience from "./AddToAudience";
import AudienceOverview from "./AudienceOverview";
import { CSVLink } from "react-csv";
import { Loading } from "components/Loading/Loading";
import AudienceSettings from "./AudienceSettings";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import {
  usefetchContactsFilters,
  useFetchUploadByAudience,
} from "api/resources/contacts/contacts";
import Button from "components/Button/Button";
import Modal from "components/ReactModal/ReactModal";
import ContactProfile from "components/Popout/ContactProfile";
import UnifinishedUpload from "../all_contacts/NewContacts/upload/UnifinishedUpload";
import { trimDate } from "assets/functions/DateFunctions";
import { randomColor } from "components/tables/EditableTable/utils";
import ObjectTable from "components/tables/BasicTable/ObjectTable";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import { parseCustomFields } from "assets/functions/ObjectFunctions";
import FieldAdjuster from "../all_contacts/FieldAdjuster";
import ColumnsToDisplay from "../all_contacts/ColumnsToDisplay";
import ImportHow from "../all_contacts/ImportHow";
import EditSingleCustomField from "components/CustomFieldsEditor/EditSingle";
import { AddContact } from "../all_contacts/NewContacts/AddContact/AddContact";
import FilterSimple from "../all_contacts/FilterSimple";
import { Export } from "../all_contacts/export/Export";
import { SearchToAdd } from "./SearchContactsToAdd/SearchContactsToAdd";

function Audience({
  user,
  organization,
  audienceId,
  preview,
  roles,
  onExport,
}) {
  const { id } = useParams();
  const audienceQuery = useAudienceQuery(audienceId ? audienceId : id);
  const orgFields = useFetchCurrOrgsFields();

  return (
    <>
      {(audienceQuery.isLoading || orgFields.isLoading) && <Loading></Loading>}
      {audienceQuery.isSuccess && orgFields.isSuccess && (
        <AudienceContainer
          audience={audienceQuery.data?.audience}
          user={user}
          customFields={orgFields?.data?.getCurrOrgFields}
          organization={organization}
          preview={preview}
          roles={roles}
          onExport={onExport}
        ></AudienceContainer>
      )}
    </>
  );
}

export default Audience;

function AudienceContainer({
  audience,
  user,
  customFields,
  organization,
  preview,
  roles,
  onExport,
}) {
  const [showGrow, setShowGrow] = useState(false);
  const [settings, setSettings] = useState(false);
  const [upload, setUpload] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [pageSkip, setPageSkip] = useState(0);
  const [sort, setSort] = useState({
    firstName: false,
    lastName: false,
    email: true,
    descend: false,
    item: "email",
  });
  const [searchString, setSearchString] = useState("");
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [active, setActive] = useState("1");
  const [data, setData] = useState([]);
  const [download, setDownload] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [headersExport, setHeadersExport] = useState(false);
  const csvref = useRef(null);
  const [viewPrev, setViewPrev] = useState(false);
  const [chosenFilters, setChosenFilters] = useState([]);
  const [audienceState, setAudienceState] = useState({
    ...audience,
    filters: audience?.filters ? JSON.parse(audience?.filters) : [],
  });
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [maxContacts, setMaxContacts] = useState(0);
  const [adjustFields, setAdjustFields] = useState(false);
  const [displayColumns, setDisplayColumns] = useState(false);
  const [importer, setImporter] = useState(false);
  const [newColumn, setNewColumn] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [first, setFirst] = useState(true);
  const [changed, setChanged] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [searchToAdd, setSearchToAdd] = useState(false);
  const obscure = !roles?.canSeeContactInfo;

  // When item changes, trigger visibility change
  useEffect(() => {
    if (showFilters) {
      setIsVisible(true);
      setFirst(false);
    } else {
      setIsVisible(false);
    }
  }, [showFilters]);

  // When the animation ends, stop rendering the component
  const handleAnimationEnd = () => {
    if (!isVisible) {
      setShowFilters(false);
    }
  };

  function setAudience(newAudience) {
    audience.contacts = newAudience;
  }

  const seeContact = (contact) => {
    setShowContactDetails(true);
    setCurrentContact(contact);
  };

  function initHeaders() {
    let heads = [
      // {
      //   id: "salesforceId",
      //   value: "salesforceId",
      //   name: "",
      //   accessor: "salesforceId",
      //   label: "",
      //   // width: 100,
      //   dataType: "text",
      //   enabled: true,
      //   sort: false,
      //   canSort: false,
      //   editable: true,
      //   custom: false,
      //   cell_style: (name, id, i, contact) =>
      //     contact &&
      //     contact?.salesforceId && (
      //       <FlexRow style={{ justifyContent: "center" }}>
      //         <Icon
      //           blue
      //           iconName={"cloud"}
      //           style={{ fontSize: "1.2rem" }}
      //           tooltip={"Synced with Salesforce"}
      //         ></Icon>
      //         {/* <img src={require("assets/images/Salesforce.png")} width={"30px"}></img> */}
      //       </FlexRow>
      //     ),
      // },
      // {
      //   id: "contactName",
      //   value: "contactName",
      //   name: "Contact Name",
      //   accessor: "firstName",
      //   label: "Contact Name",
      //   // width: 100,
      //   dataType: "text",
      //   enabled: true,
      //   sort: false,
      //   canSort: true,
      //   fullName: true,
      //   custom: false,
      //   icon: "person",
      //   cell_style: (name, id, i, contact) =>
      //     contact && (
      //       <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
      //         {" "}
      //         <div
      //           className={styles.profileImage}
      //           style={{
      //             backgroundColor: contact?.color
      //               ? contact?.color
      //               : randomColor(),
      //           }}
      //         >
      //           {" "}
      //           <img
      //             src={require("assets/images/blank-profile-picture.png")}
      //             height={"30px"}
      //             width={"30px"}
      //           ></img>
      //         </div>
      //         {contact && `${contact?.firstName} ${contact?.lastName}`}
      //       </div>
      //     ),
      // },
      // {
      //   id: "firstName",
      //   value: "firstName",
      //   name: "First Name",
      //   accessor: "firstName",
      //   label: "First Name",
      //   // width: 100,
      //   dataType: "text",
      //   enabled: false,
      //   sort: false,
      //   canSort: true,
      //   editable: true,
      //   custom: false,
      // },
      // {
      //   id: "lastName",
      //   value: "lastName",
      //   name: "Last Name",
      //   accessor: "lastName",
      //   label: "Last Name",
      //   // width: 100,
      //   dataType: "text",
      //   enabled: false,
      //   sort: false,
      //   canSort: true,
      //   editable: true,
      //   custom: false,
      // },
      // {
      //   id: "email",
      //   value: "email",
      //   name: "Email",
      //   accessor: "email",
      //   label: "Email",
      //   // width: 250,
      //   dataType: "text",
      //   enabled: true,
      //   sort: true,
      //   canSort: true,
      //   cell_style: null,
      //   editable: true,
      //   custom: false,
      //   required: true,
      //   unique: true,
      //   icon: "envelope",
      // },
      // {
      //   id: "phone",
      //   value: "phone",
      //   name: "Phone",
      //   accessor: "phone",
      //   label: "Phone",
      //   // width: 250,
      //   dataType: "text",
      //   enabled: true,
      //   sort: true,
      //   canSort: true,
      //   cell_style: null,
      //   editable: true,
      //   custom: false,
      //   icon: "telephone",
      // },
      // {
      //   id: "updatedAt",
      //   value: "updatedAt",
      //   name: "Last Modified",
      //   accessor: "updatedAt",
      //   label: "Last Modified",
      //   // width: 100,
      //   dataType: "text",
      //   enabled: true,
      //   sort: false,
      //   canSort: true,
      //   cell_style: null,
      //   custom: false,
      //   icon: "calendar",
      // },
      // {
      //   id: "unsubscribed",
      //   value: "unsubscribed",
      //   name: "Unsubscribed",
      //   accessor: "unsubscribed",
      //   label: "Unsubscribed",
      //   dataType: "text",
      //   enabled: true,
      //   sort: true,
      //   canSort: true,
      //   custom: false,
      //   icon: "person",
      //   properties: [
      //     { value: true, label: "True" },
      //     { value: false, label: "False" },
      //   ],
      //   cell_style: (item) => <div>{item ? "True" : "False"}</div>,
      // },
      // {
      //   id: "participationCount",
      //   value: "participationCount",
      //   name: "Survey Invites",
      //   label: "Survey Invites",
      //   // width: 80,
      //   dataType: "text",
      //   accessor: "participationCount",
      //   enabled: true,
      //   sort: true,
      //   canSort: true,
      //   cell_style: null,
      //   custom: false,
      //   icon: "person",
      //   cell_style: (item) => <div>{!item ? 0 : item}</div>,
      // },
      // {
      //   id: "createdAt",
      //   value: "createdAt",
      //   name: "When Added",
      //   accessor: "createdAt",
      //   label: "When Added",
      //   dataType: "text",
      //   enabled: false,
      //   sort: false,
      //   canSort: true,
      //   cell_style: null,
      //   custom: false,
      //   icon: "calendar",
      // },
    ];
    let order = undefined;
    if (audience?.columnOrder) {
      order = JSON.parse(audience?.columnOrder);
    }

    for (let field of customFields) {
      let index = 1000;
      let enabled = field?.enabled;
      // debugger;
      if (order) {
        index = order?.findIndex((c) => c?.id === field?.id);
        enabled = order[index]?.enabled;
      }

      heads.push({
        id: field?.id,
        value: field?.generic ? field?.name : field?.id,
        label: field?.displayName,
        dataType: field?.dataType,
        enabled: enabled,
        editable: field?.editable,
        custom: true,
        sort: false,
        canSort: false,
        obscure: obscure,
        salesforceColumn: field?.salesforceColumn,
        icon: field?.icon ? field?.icon : "person",
        multiple: field?.multiple,
        delimiter: field?.delimiter,
        generic: field?.generic,
        index: index,
        cell_style:
          field?.name === "contactName"
            ? (name, id, i, contact) =>
                contact && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1em",
                    }}
                  >
                    <div
                      className={`${styles.link} ${styles.blueDark}`}
                      style={{ fontWeight: "500" }}
                      onClick={() => {
                        seeContact(contact);
                      }}
                    >
                      {contact && `${contact?.firstName} ${contact?.lastName}`}
                    </div>
                  </div>
                )
            : undefined,
      });
    }

    if (order) {
      const sortedArray = heads.sort(
        (a, b) => order.index - order.index
      );

      return sortedArray;
    } else {
      return heads;
    }
  }

  const [headers, setHeaders] = useState(initHeaders());

  useEffect(() => {
    if (download) {
      csvref.current.click();
      setDownload(false);
      setDownloading(false);
    }
  }, [data]);

  const updateAudience = useUpdateAudienceGql();

  function saveDynamicChanges() {
    updateAudience.mutate(
      {
        data: {
          dynamic:
            audienceState?.filters.length === 0
              ? false
              : audienceState?.dynamic,
          filters: audienceState?.filters
            ? JSON.stringify(audienceState?.filters)
            : undefined,
        },
        id: audience?.id,
      },
      {
        onSuccess: (data) => {
          setChanged(false);
          setAudienceState({
            ...audienceState,
            updatedAt: data?.audience?.updatedAt,
          });
        },
      }
    );
  }

  const newOptions = [
    {
      onClick: () => setAddContact(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"person-fill"}></Icon> Create a new contact
          </FlexRow>{" "}
          <div className={styles.description2}>
            Create a new contact and add it to this audience.
          </div>
        </div>
      ),
    },

    {
      onClick: () => setImporter(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"people-fill"}></Icon> Import Contacts
          </FlexRow>{" "}
          <div className={styles.description2}>
            Import in bulk to the audience with a file or an external account.
          </div>
        </div>
      ),
    },
    // {
    //   onClick: () => console.log(true),
    //   data: (
    //     <div className={styles.bigOption}>
    //       <FlexRow start>
    //         <Icon blue iconName={"table"}></Icon> Add contacts table
    //       </FlexRow>{" "}
    //       <div className={styles.description2}>
    //         Add more than one contact manually and add them to the audience.
    //       </div>
    //     </div>
    //   ),
    // },
    {
      onClick: () => setShowFilters(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"funnel"}></Icon> Add from filter
          </FlexRow>{" "}
          <div className={styles.description2}>
            Add contacts to this audinece from a dynamic filter.
          </div>
        </div>
      ),
    },
    {
      onClick: () => setSearchToAdd(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"search"}></Icon> Search Contacts
          </FlexRow>{" "}
          <div className={styles.description2}>
            Search contacts to add to this audience.
          </div>
        </div>
      ),
    },
  ];
  const dotOptions = [
    {
      onClick: () => console.log("Import"),
      data: (
        <>
          <Icon sapphire iconName={"cloud-plus"}></Icon> Import
        </>
      ),
    },
    {
      onClick: () => console.log("Export View"),
      data: (
        <>
          <Icon sapphire iconName={"cloud-download"}></Icon> Export View
        </>
      ),
    },
  ];

  const columnOptions = [
    {
      onClick: () => setAdjustFields(!adjustFields),
      data: (
        <>
          <Icon sapphire iconName={"pencil"}></Icon> Edit columns
        </>
      ),
    },
    {
      onClick: () => setDisplayColumns(true),
      data: (
        <>
          <Icon sapphire iconName={"columns"}></Icon> Select columns to display
        </>
      ),
    },
  ];

  function handleSelectPage() {
    let toadd = [];
    let firstIndex = 100;
    for (let c of data) {
      let index = selected.findIndex((i) => i.id === c.id);
      if (index < 0) {
        toadd.push(c);
      } else if (index < firstIndex) {
        firstIndex = index;
      }
    }
    let items = [...selected, ...toadd];
    if (toadd.length === 0) {
      items.splice(firstIndex, data.length);
    }

    setSelected(items);
  }

  const selectedOptions = [
    { onClick: () => handleSelectPage(), data: "Select Page" },
    { onClick: () => setSelected([]), data: "Remove Selected" },
  ];

  const fetchContacts = usefetchContactsFilters();
  const [fullLoading, setFullLoading] = useState(false);

  useEffect(() => {
    if (first) {
      setLoading(true);
      fetchContacts.mutate(
        {
          search: searchString,
          page: pageSkip,
          perPage: perPage,
          sort: sort,
          filters: JSON.stringify(chosenFilters),
          audienceId: audienceState?.id,
          audienceFilters: audienceState?.filters
            ? JSON.stringify(audienceState?.filters)
            : "",
        },
        {
          onSuccess: (thedata) => {
            setData(parseCustomFields(thedata?.contacts?.contacts, headers));
            setMaxContacts(thedata?.contacts?.count);
            setLoading(false);
          },
        }
      );
      setFirst(false);
    }
  }, [first]);

  function handleFilterChange(val) {
    setFullLoading(true);
    fetchContacts.mutate(
      {
        search: searchString,
        page: 0,
        perPage: perPage,
        sort: sort,
        filters: JSON.stringify(val),
        audienceId: audienceState?.id,
        audienceFilters: audienceState?.filters
          ? JSON.stringify(audienceState?.filters)
          : "",
      },
      {
        onSuccess: (thedata) => {
          setData(parseCustomFields(thedata?.contacts?.contacts, headers));
          setChosenFilters(val);
          setPageNumber(1);
          setPageSkip(0);
          setMaxContacts(thedata?.contacts?.count);
          setFullLoading(false);
        },
      }
    );
  }

  function handleAudienceFilterChange(val) {
    setFullLoading(true);
    setChanged(true);
    setAudienceState({
      ...audienceState,
      filters: val,
    });
    fetchContacts.mutate(
      {
        search: searchString,
        page: 0,
        perPage: perPage,
        sort: sort,
        filters: JSON.stringify(chosenFilters),
        audienceId: audienceState?.id,
        audienceFilters: JSON.stringify(val),
      },
      {
        onSuccess: (thedata) => {
          setData(parseCustomFields(thedata?.contacts?.contacts, headers));
          setPageNumber(1);
          setPageSkip(0);
          setMaxContacts(thedata?.contacts?.count);
          setFullLoading(false);
        },
      }
    );
  }

  function handleSetHeaders(vals) {
    setFullLoading(true);
    fetchContacts.mutate(
      {
        search: searchString,
        page: 0,
        perPage: perPage,
        sort: sort,
        filters: JSON.stringify(chosenFilters),
        audienceId: audienceState?.id,
        audienceFilters: audienceState?.filters
          ? JSON.stringify(audienceState?.filters)
          : "",
      },
      {
        onSuccess: (thedata) => {
          setData(parseCustomFields(thedata?.contacts?.contacts, vals));
          setHeaders(vals);
          setPageNumber(1);
          setPageSkip(0);
          setMaxContacts(thedata?.contacts?.count);
          setFullLoading(false);
        },
      }
    );
  }

  function handleSearch(val) {
    setFullLoading(true);
    setSearchString(val);
    fetchContacts.mutate(
      {
        search: val,
        page: 0,
        perPage: perPage,
        sort: sort,
        filters: JSON.stringify(chosenFilters),
        audienceId: audienceState?.id,
        audienceFilters: audienceState?.filters
          ? JSON.stringify(audienceState?.filters)
          : "",
      },
      {
        onSuccess: (thedata) => {
          setData(parseCustomFields(thedata?.contacts?.contacts, headers));
          setPageNumber(1);
          setPageSkip(0);
          setMaxContacts(thedata?.contacts?.count);
          setFullLoading(false);
        },
      }
    );
  }

  function handlePageNum(val) {
    setLoading(true);
    fetchContacts.mutate(
      {
        search: searchString,
        page: (val - 1) * perPage,
        perPage: perPage,
        sort: sort,
        filters: JSON.stringify(chosenFilters),
        audienceId: audienceState?.id,
        audienceFilters: audienceState?.filters
          ? JSON.stringify(audienceState?.filters)
          : "",
      },
      {
        onSuccess: (thedata) => {
          const newd = parseCustomFields(thedata?.contacts?.contacts, headers);
          setData(newd);
          setData([...data, ...newd]);
          setPageNumber(val);
          setPageSkip((val - 1) * perPage);
          setMaxContacts(thedata?.contacts?.count);
          setLoading(false);
          // setFullLoading(false);
        },
        // onSettled: () => {
        //   setLoading(false);
        // },
      }
    );
  }

  // function pullContacts() {
  //   setLoading(true);
  //   fetchContacts.mutate(
  //     {
  //       search: searchString,
  //       page: pageSkip,
  //       perPage: perPage,
  //       sort: sort,
  //       filters: JSON.stringify(chosenFilters),
  //       audienceId: audienceState?.id,
  //       audienceFilters: audienceState?.filters
  //         ? JSON.stringify(audienceState?.filters)
  //         : "",
  //     },
  //     {
  //       onSuccess: (data) => {
  //         setData(parseCustomFields(data?.contacts?.contacts));
  //         setMaxContacts(data?.contacts?.count);
  //         setLoading(false);
  //       },
  //     }
  //   );
  // }

  // useEffect(() => {
  //   pullContacts();
  // }, [
  //   chosenFilters,
  //   sort,
  //   pageNumber,
  //   pageSkip,
  //   searchString,
  //   perPage,
  //   audienceState,
  // ]);

  // useEffect(() => {
  //   setPageNumber(1);
  //   setPageSkip(0);
  // }, [chosenFilters]);

  useEffect(() => {
    if (changed) {
      saveDynamicChanges();
    }
  }, [audienceState]);

  const navigate = useNavigate();

  const removeContacts = useRemoveContactsFromAudience();
  const [removing, setRemoving] = useState(false);

  function handleRemoveSelectedFromAudience() {
    if (selected.length > 0) {
      setRemoving(true);
      const contactIds = selected.map((c) => {
        return c?.id;
      });

      removeContacts.mutate(
        {
          id: audience?.id,
          contactArray: {
            contactIds: contactIds,
          },
        },
        {
          onSuccess: () => {
            handleSearch("");
            setSelected([]);
            setRemoving(false);
          },
        }
      );
    }
  }

  function onSaveColumnOrder(columns) {
    const final = JSON.stringify(columns);

    updateAudience.mutate(
      {
        data: {
          columnOrder: final,
        },
        id: audience?.id,
      },
      {
        onSuccess: (data) => {
          setChanged(false);
          setAudienceState({
            ...audienceState,
            updatedAt: data?.audience?.updatedAt,
          });
        },
      }
    );
  }

  return (
    <>
      {showContactDetails && (
        <>
          <div
            className={styles.backArrow}
            onClick={() => setShowContactDetails(false)}
          >
            <i className="bi bi-arrow-left"></i>
          </div>
          <ContactProfile
            onClose={() => setShowContactDetails(false)}
            contact={currentContact}
          ></ContactProfile>
        </>
      )}
      {!upload && (
        <>
          {settings && (
            <Modal
              show={settings}
              onClose={() => setSettings(false)}
              modalStyle={{
                height: "100%",
                width: "fit-content",
              }}
              dark
              rightStyle
            >
              <AudienceSettings
                audience={audience}
                setSettings={setSettings}
              ></AudienceSettings>
            </Modal>
          )}
          {audience && !showGrow && (
            <div className={styles.table}>
              <div
                style={{
                  display: first ? "none" : "",
                  borderRight: isVisible ? "" : "none",
                }}
                className={`${styles.showFilters} ${
                  isVisible ? styles.enter : styles.exit
                }`}
                onAnimationEnd={handleAnimationEnd}
              >
                {showFilters && (
                  <FilterSimple
                    filters={
                      audienceState?.filters ? audienceState?.filters : []
                    }
                    setFilters={handleAudienceFilterChange}
                    allColumns={headers}
                    title="Addition Filters"
                    setShow={setShowFilters}
                    left
                  ></FilterSimple>
                )}
              </div>
              <div className={styles.modalContainer}>
                <div className={styles.nav}>
                  <div
                    style={{
                      display: "flex",
                      gap: "1em",
                      flexWrap: "wrap",
                      flexDirection: "column",
                    }}
                  >
                    <FlexRow start>
                      {" "}
                      {!preview && (
                        <Button
                          shadow
                          seafoam
                          style={{ padding: "10px" }}
                          onClick={() => setSettings(true)}
                        >
                          <i className="bi-gear-fill"></i>
                        </Button>
                      )}
                      <div className={styles.name}> {audience?.name}</div>
                    </FlexRow>
                    {audienceState?.filters?.length > 0 && (
                      <FlexRow start style={{ width: "fit-content" }}>
                        <span className={styles.label5}>Contacts Added:</span>{" "}
                        {audienceState?.filters?.length > 0 &&
                          audienceState?.filters?.map((filter, i) => (
                            <div
                              className={styles.filter}
                              onClick={() => setShowFilters(true)}
                              key={i}
                            >
                              {filter?.label}:
                              {filter?.properties?.map((prop, i) => (
                                <div className={styles.prop} key={i}>
                                  {prop?.label}
                                  {i != filter?.properties?.length - 1
                                    ? ","
                                    : ""}
                                </div>
                              ))}
                            </div>
                          ))}{" "}
                        <Icon
                          iconName={"plus-circle"}
                          onClick={() => setShowFilters(true)}
                        ></Icon>
                      </FlexRow>
                    )}
                  </div>
                  {selected?.length > 0 && (
                    <Button
                      red
                      shadow
                      onClick={handleRemoveSelectedFromAudience}
                    >
                      {!removing ? (
                        <>Remove ({selected?.length}) selected from audience</>
                      ) : (
                        <FlexRow start>
                          Removing... <Loading height={30} width={30}></Loading>
                        </FlexRow>
                      )}
                    </Button>
                  )}
                  <div
                    style={{ display: "flex", gap: "1em", flexWrap: "wrap" }}
                  >
                    <CSVLink
                      data={data ? data : []}
                      headers={headersExport ? headersExport : []}
                      //   headers={[]}
                      filename={audience.name + "-members.csv"}
                      style={{
                        display: "none",
                      }}
                      target="_blank"
                    >
                      <button
                        ref={csvref}
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      ></button>
                    </CSVLink>
                    <FlexRow start style={{ width: "fit-content" }}>
                      {!changed && (
                        <span className={styles.label5}>
                          Saved {trimDate(audienceState?.updatedAt, true)}
                        </span>
                      )}
                      {changed && (
                        <FlexRow start style={{ width: "fit-content" }}>
                          <Loading height={30} width={30}></Loading> Saving...
                        </FlexRow>
                      )}
                      {!preview && (
                        <div className={styles.viewPrev}>
                          <Button
                            onClick={() => setViewPrev(true)}
                            shadow
                            yellow
                          >
                            Uploads
                          </Button>
                          <Uploads
                            audience={audience}
                            upload={upload}
                            setUpload={setUpload}
                            viewPrev={viewPrev}
                            setViewPrev={setViewPrev}
                          ></Uploads>
                        </div>
                      )}
                    </FlexRow>
                  </div>
                </div>
                {active === "0" && (
                  <AudienceOverview
                    audience={audience}
                    settings={settings}
                    setSettings={setSettings}
                  />
                )}

                {/*Members Page */}
                {active == "1" && !upload && (
                  <ObjectTable
                    data={data}
                    headers={headers}
                    setHeaders={setHeaders}
                    maxPage={
                      maxContacts / perPage < 1
                        ? 1
                        : Math.ceil(maxContacts / perPage)
                    }
                    maxItems={maxContacts}
                    pageNum={pageNumber}
                    setPageNum={handlePageNum}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    sort={sort}
                    setSort={setSort}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    customFields={customFields}
                    user={user}
                    roles={roles}
                    searchString={searchString}
                    setSearchString={handleSearch}
                    searchPlaceholder={"Search Contacts..."}
                    emptyMessage={"No Contacts"}
                    addOptions={!preview ? newOptions : undefined}
                    addOptionsHeight={100}
                    filters={chosenFilters}
                    setFilters={handleFilterChange}
                    loading={loading}
                    fullLoading={fullLoading}
                    select
                    selected={selected}
                    setSelected={setSelected}
                    selectedOptions={selectedOptions}
                    selectedOptionsHeight={50}
                    allColumns={customFields}
                    columnOptions={columnOptions}
                    columnOptionsHeight={50}
                    exportClick={
                      roles?.canSeeContactInfo
                        ? () => setShowExport(true)
                        : undefined
                    }
                    bottomButton={
                      <div
                        className={styles.link}
                        onClick={() => {
                          navigate("/previous-imports");
                        }}
                      >
                        View Import Jobs
                      </div>
                    }
                    onCreateAudienceClick={() => setCreateAudience(true)}
                    // onSaveEditableCell={saveContact}
                  ></ObjectTable>
                )}
              </div>
            </div>
          )}

          {showGrow && !settings && (
            <AddToAudience
              audience={audience}
              setAudience={setAudience}
              user={user}
              setShowGrow={setShowGrow}
              showGrow={showGrow}
              // initContacts={searchContacts.data.contacts}
              onRowClick={seeContact}
              headers={headers}
              setUpload={setUpload}
            ></AddToAudience>
          )}

          {adjustFields && (
            <FieldAdjuster
              show={adjustFields}
              setShow={setAdjustFields}
              orgId={organization?.id}
              user={user}
              salesforce={organization?.linkedAccounts?.find(
                (a) => a?.accountType === "Salesforce"
              )}
            ></FieldAdjuster>
          )}

          {displayColumns && (
            <ColumnsToDisplay
              show={displayColumns}
              setShow={setDisplayColumns}
              columns={headers}
              setColumns={handleSetHeaders}
              onSaveColumnOrder={onSaveColumnOrder}
            />
          )}

          {importer && (
            <Modal
              show={importer}
              onClose={() => setImporter(false)}
              modalStyle={{
                borderRadius: "1em",
                height: "500px",
                width: "900px",
              }}
              dark
            >
              <ImportHow
                cancel={() => setImporter(false)}
                user={user}
                organization={organization}
                objectColumns={headers}
                audienceId={audience?.id}
              ></ImportHow>
            </Modal>
          )}

          {newColumn && (
            <EditSingleCustomField
              onClose={() => setNewColumn(false)}
              field={{}}
              // onSaved={handleSaved}
              brandNew={true}
              // noModal
              // deleteField={handleDeleteField}
            />
          )}
          {addContact && (
            <AddContact
              onClose={() => setAddContact(false)}
              org={organization}
              audienceId={audience?.id}
              refetch={() => pullContacts()}
            />
          )}

          {showExport && (
            <Export
              onClose={() => setShowExport(false)}
              org={organization}
              filters={chosenFilters}
              search={searchString}
              sort={sort}
              headers={headers}
              onExport={onExport}
              audienceId={audienceState?.id}
              audienceFilters={audienceState?.filters}
              audienceName={audienceState.name}
            />
          )}

          {searchToAdd && (
            <SearchToAdd
              onClose={() => setSearchToAdd(false)}
              audienceId={audienceState.id}
              audienceName={audienceState.name}
              onAdded={() => pullContacts()}
            />
          )}
        </>
      )}
      {upload && (
        <UnifinishedUpload
          upload={upload}
          setUpload={setUpload}
          audience={audience}
        />
      )}
    </>
  );
}

function Uploads({ audience, upload, setUpload, viewPrev, setViewPrev }) {
  const getUploads = useFetchUploadByAudience(audience?.id);

  return (
    <>
      {viewPrev && (
        <div className={styles.recentUploadsBox}>
          {getUploads.isSuccess &&
            getUploads?.data?.upload?.length > 0 &&
            !upload && (
              <div className={styles.recentUploads}>
                <span>
                  Previous uploads{" "}
                  <i className="bi-x-lg" onClick={() => setViewPrev(false)}></i>
                </span>
                {getUploads?.data?.upload?.map((upload, i) => (
                  <div
                    key={i}
                    className={styles.upload}
                    onClick={() => {
                      setUpload(upload);
                      setViewPrev(false);
                    }}
                  >
                    {trimDate(upload?.uploadDate, true)}
                    <div className={styles.view}>View</div>
                  </div>
                ))}
              </div>
            )}
          {getUploads.isSuccess && getUploads?.data?.upload?.length === 0 && (
            <div
              className={styles.recentUploads}
              style={{ paddingBottom: "1em" }}
            >
              {" "}
              <span>
                No Upload Attempts
                <i className="bi-x-lg" onClick={() => setViewPrev(false)}></i>
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
}
