import React from "react";
import styles from "./SurveyDesign.module.scss";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import ColorPickerContainer from "components/inputs/input_fields/ColorPicker/ColorPickerContainer";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";
import { shadows } from "assets/functions/Variables";
import { SurveyLogoSettings } from "../EditableLogo/SurveyLogoSettings";
import ProgressSettings from "./ProgressSettings/ProgressSettings";
import { SelectFieldCustom } from "components/inputs";

function HeaderSettings({ settings, changeSetting, setSettings, projId }) {
  return (
    <>
      <div className={styles.body}>
        <div className={styles.settingVertical}>
          <div
            className={styles.label5}
            style={{ margin: "0", fontWeight: "500" }}
          >
            Page Header Settings
          </div>{" "}
        </div>

        <div className={styles.settingVertical}>
          <div className={styles.label6}>Page Header</div>
          <div className={styles.settingHorizontal}>
            <ToggleSwitch
              startChecked={settings?.showHeader === false}
              handleCheck={(val) => changeSetting("showHeader", !val)}
            ></ToggleSwitch>

            <div className={styles.text_3}>Hide Page Header</div>
          </div>
          <div className={styles.settingHorizontal}>
            <ToggleSwitch
              startChecked={settings?.headerFirstPageOnly}
              handleCheck={(val) => changeSetting("headerFirstPageOnly", val)}
            ></ToggleSwitch>

            <div className={styles.text_3}>Header on first page only</div>
          </div>

          <div className={styles.settingHorizontal}>
            <ToggleSwitch
              startChecked={settings?.headerBackgroundColor}
              handleCheck={(val) =>
                changeSetting("headerBackgroundColor", val ? "#FFFFFF" : false)
              }
            ></ToggleSwitch>

            <div className={styles.text_3}>Has Background Color</div>
          </div>
          {settings?.headerBackgroundColor && (
            <div className={styles.settingVertical}>
              <ColorPickerContainer
                onChange={(color) =>
                  changeSetting("headerBackgroundColor", color)
                }
                defaultColor={
                  settings?.headerBackgroundColor
                    ? settings?.headerBackgroundColor
                    : "#FFFFFF"
                }
                title="Header Background Color"
                //   colors={settings.colorBank}
              />
            </div>
          )}
        </div>
        <div className={styles.settingVertical}>
         

          <SelectFieldCustom
            onChange={(color) => changeSetting("headerShadow", color.value)}
            value={
              settings?.headerShadow
                ? shadows.find((s) => s.value === settings?.headerShadow)
                : { value: "", label: "No Shadow" }
            }
            label="Header Border Shadow"
            // icon="bi-chevron-down"
            options={shadows}
            select
            anyWhereOptions
          />

          <NumberInputWithSlider
            val={
              settings?.headerRadius || settings?.headerRadius === 0
                ? settings?.headerRadius
                : 1
            }
            onChange={(val) => changeSetting("headerRadius", val)}
            min={0}
            max={4}
            step={0.25}
            color={"#15bcc7"}
            title="Header Border Radius"
          ></NumberInputWithSlider>

          <NumberInputWithSlider
            val={
              settings?.headerGap || settings?.headerGap === 0
                ? settings?.headerGap
                : 1
            }
            onChange={(val) => changeSetting("headerGap", val)}
            min={0}
            max={4}
            step={0.25}
            color={"#15bcc7"}
            title="Logo and Progress Spacing"
          ></NumberInputWithSlider>
        </div>
        <div className={styles.line}></div>
        <div className={styles.settingVertical}>
          <div
            className={styles.label5}
            style={{ margin: "0", fontWeight: "500" }}
          >
            Logo Settings
          </div>{" "}
        </div>
        <SurveyLogoSettings
          settings={settings}
          changeSettingsField={changeSetting}
          setSettings={setSettings}
          projId={projId}
        ></SurveyLogoSettings>
        <div className={styles.line}></div>
        <div className={styles.settingVertical}>
          <div
            className={styles.label5}
            style={{ margin: "0", fontWeight: "500" }}
          >
            Progress Bar Settings
          </div>{" "}
        </div>
        <ProgressSettings
          settings={settings}
          setSettings={(s) => {
            setSettings(s);
          }}
        ></ProgressSettings>
      </div>
    </>
  );
}

export default HeaderSettings;
