// External
import React, { useEffect, useState } from "react";

// Internal
import { UGSidebar } from "components/sidebars/UGSidebar/UGSidebar";
import { SplitHorizontal } from "components/layouts/SplitPage/SplitHorizontal";
import { TextFieldSimple } from "components/inputs";
import styles from "./Roles.module.scss";
import {
  useCreateRolesGql,
  useDeleteRoleGql,
  useFetchRolesGql,
  useUpdateRolesGql,
} from "api/resources/organization/roles";
import PermissionsCategories from "./permissions";
import Button from "components/Button/Button";
// import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
// import Accordion from "components/Accordion/Accordion";
import { useParams } from "react-router-dom";
import SettingToggle from "./SettingToggle";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { Loading } from "components/Loading/Loading";

export const Roles = ({ roles }) => {
  const { id } = useParams();

  const fetchRolesQuery = useFetchRolesGql(id);
  const createRoles = useCreateRolesGql();
  const updateRole = useUpdateRolesGql();
  const deleteRole = useDeleteRoleGql();
  const [currRole, setCurrRole] = useState(null);
  const [saving, setSaving] = useState("Save");
  const [active, setActive] = useState(0);
  const [newRole, setNewRole] = useState(false);
  const [validName, setValidName] = useState(true);
  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  let [width, setWidth] = useState(getWidth());

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      let width = getWidth();
      // if (width < 1024) {xfsetDu
      //   setShowButton(true);
      //   setShow(false);
      // } else if (width > 1024) {
      //   setShowButton(false);
      //   setShow(true);
      // }
      setWidth(width);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const blankStatus = {
    isSuccess: false,
    isLoading: false,
    isError: false,
    msg: "",
  };
  const [updateStatus, setUpdateStatus] = useState(blankStatus);

  const getOrgId = () => {
    return fetchRolesQuery.isSuccess
      ? fetchRolesQuery.data.rolesByOrgId?.length > 0
        ? fetchRolesQuery.data.rolesByOrgId[0].organizationId
        : null
      : null;
  };

  const blankRole = {
    organizationId: getOrgId(),
    name: "",
    description: "",
    isAdmin: false,

    //Dashboard
    canViewDashboardTab: false,
    canEditPersonalDashboard: false,
    canViewPersonalDashboard: false,
    canEditOrgDashboard: false,
    canViewOrgDashboard: false,

    //Surveys
    canViewSurveysTab: false,
    canCreateEditPersonalSurveys: false,
    canCreateEditOrgSurveys: false,
    canSendOrgSurveyEmails: false,
    canViewRawResultsForOrg: false,
    canViewOrgSurveys: false,

    //Analyze
    canViewAnalyzeTab: false,
    canCreateEditPersonalReports: false,
    canCreateAnalyzeFolders: false,
    canShareReports: false,
    canViewOrgReports: false,
    canCreateEditOrgReports: false,
    canShareOrgReports: false,

    //Contacts
    canViewContactsTab: false,
    canCreateEditPersonalContacts: false,
    canViewOrgContacts: false,
    canCreateEditOrgContacts: false,
    canSeeContactInfo: false,

    //Audiences
    canViewAudiencesTab: false,
    canCreateEditPersonalAudiences: false,
    canViewOrgAudiences: false,
    canCreateEditOrgAudiences: false,

    //Organization
    canViewOrgTab: false,
    canEditOrgDetails: false,
    canViewOrgDetails: false,
    canEditOrgUsers: false,
    canViewOrgUsers: false,
    canEditOrgRoles: false,
    canViewOrgRoles: false,
    canEditOrgImages: false,
    canViewOrgImages: false,
  };

  const handleActiveUpdate = (id) => {
    setActive(id);
    for (let i = 0; i < fetchRolesQuery.data.rolesByOrgId.length; i++) {
      if (fetchRolesQuery.data.rolesByOrgId[i].id === id) {
        setCurrRole(fetchRolesQuery.data.rolesByOrgId[i]);
        setSaving("Save");
      }
    }
  };

  function updateUpdateStatus(field, val, field2, val2) {
    let status = { ...blankStatus };
    status[field] = val;
    if (field2) {
      status[field2] = val2;
    }
    setUpdateStatus(status);
  }

  // eslint-disable-next-line no-unused-vars
  const handleNewRole = () => {
    updateUpdateStatus("isLoading", true);
    let userRoles = { ...currRole };
    delete userRoles.id;
    delete userRoles.createdAt;
    delete userRoles.updatedAt;
    delete userRoles.deletedAt;
    delete userRoles.isAdmin;
    delete userRoles.organizationId;
    createRoles.mutate(
      {
        data: userRoles,
      },
      {
        onSuccess: (data) => {
          console.log("Return Value: ", data);
          setNewRole(false);
          // setActive(data.createRole.id);
          setActive(0);
          updateUpdateStatus("isSuccess", true, "msg", "New Role Saved");
          fetchRolesQuery.refetch();
          setCurrRole(null);
          setSaving(
            <div className={styles.saved}>
              <i className="bi bi-check-lg"></i>
              Saved
            </div>
          );
          setTimeout(() => {
            setSaving("Save");
          }, 3000);
        },
        onError: (data) => {
          updateUpdateStatus("isError", true);
        },
      }
    );
  };

  // eslint-disable-next-line no-unused-vars
  const onSave = () => {
    // if (!currRole.isAdmin) {
    updateUpdateStatus("isLoading", true);
    let userRoles = { ...currRole };
    delete userRoles.id;
    delete userRoles.createdAt;
    delete userRoles.updatedAt;
    delete userRoles.deletedAt;
    delete userRoles.isAdmin;
    delete userRoles.organizationId;
    delete userRoles.dashboardId;
    updateRole.mutate(
      {
        updateRoleId: currRole.id,
        data: userRoles,
      },
      {
        onSuccess: (data) => {
          updateUpdateStatus("isSuccess", true, "msg", "Saved");
          setSaving(
            <div className={styles.saved}>
              <i className="bi bi-check-lg"></i>
              Saved
            </div>
          );
          setTimeout(() => {
            setSaving("Save");
          }, 3000);
        },
        onError: (data) => {
          updateUpdateStatus("isError", true);
        },
      }
    );
    // }
  };

  const onDelete = () => {
    updateUpdateStatus("isLoading", true);
    deleteRole.mutate(
      {
        deleteRoleId: currRole.id,
      },
      {
        onSuccess: (data) => {
          console.log("Role Deleted", data);
          setCurrRole(null);
          updateUpdateStatus("isSuccess", true, "msg", "Role Deleted");
        },
        onError: (data) => {
          updateUpdateStatus("isError", true);
        },
      }
    );
  };

  const setUpNewRole = () => {
    setNewRole(true);
    setCurrRole(blankRole);
    setSaving("Save Role");
    setActive(0);
  };

  const handleSave = () => {
    if (isValidName()) {
      setSaving("Saving...");
      if (newRole) {
        handleNewRole();
      } else {
        onSave();
      }
    } else {
      setValidName(false);
    }
  };

  function checkAll(permission, permissions, org_permissions, val) {
    if (!currRole.isAdmin) {
      let role = { ...currRole };
      for (let p of permissions) {
        role[p.db_field] = val;
      }
      for (let p of org_permissions) {
        role[p.db_field] = val;
      }
      role[permission] = val;
      setCurrRole(role);
    } else {
      alert("Admin Role Cannot be changed");
    }
  }

  function changePermission(permission, e) {
    if (!currRole.isAdmin) {
      let role = { ...currRole };
      role[permission] = e.target.checked;
      setCurrRole(role);
    } else {
      alert("Admin Role Cannot be changed");
    }
  }

  const handleDelete = () => {
    if (
      window.confirm(
        "Are you sure you want to delete the " + currRole.name + " role?"
      )
    ) {
      onDelete();
    }
  };

  const updateRoleField = (field, val) => {
    let role = { ...currRole };
    role[field] = val;
    setCurrRole(role);
    if (field === "name") {
      setValidName(true);
    }
  };

  const isValidName = () => {
    let good;
    currRole.name === "" ? (good = false) : (good = true);
    return good;
  };

  // console.log(currRole);
  // console.log(fetchRolesQuery);
  // console.log(blankRole);

  return (
    <div className={styles.page}>
      {fetchRolesQuery.isLoading && <p>Loading...</p>}
      {fetchRolesQuery.isError && <p>Error</p>}
      {fetchRolesQuery.isSuccess && (
        <>
          <div
            className={styles.splitContainer}
            style={{ flexDirection: width < 750 ? "column" : "" }}
          >
            <div style={{ maxWidth: "450px" }}>
              <div className={styles.header2} style={{margin: "0"}}>Permission Roles</div>
              <div className={styles.description2}>
                These are the permission roles of your organization. You can
                decide what access each role provides and then assign each role
                to a user.
              </div>
            </div>
            {/* {console.log(fetchRolesQuery.data.allRoles)} */}
            <UGSidebar
              menuItems={
                fetchRolesQuery.data.rolesByOrgId
                  ? fetchRolesQuery.data.rolesByOrgId
                  : []
              }
              // menuItems={StaticRoles}
              active={active}
              updateActive={handleActiveUpdate}
              onNewClick={setUpNewRole}
              type="Role"
              canEdit={roles.canEditOrgUsers}
            />
            {currRole && (
              <div className={styles.pageContent}>
                <div className={styles.textBox}>
                  <TextFieldSimple
                    name="name"
                    label="Name"
                    placeholder="type here..."
                    invalidMessage="Role Name Required"
                    valid={validName}
                    value={currRole.name}
                    onSave={(val) => updateRoleField("name", val)}
                  ></TextFieldSimple>

                  <TextFieldSimple
                    name="description"
                    label="Description"
                    placeholder="type here..."
                    value={currRole.description}
                    onSave={(val) => updateRoleField("description", val)}
                  ></TextFieldSimple>
                  {!newRole && (
                    <Button
                      className={styles.submitbutton}
                      onClick={handleDelete}
                      shadow
                      red
                      width={"fit-content"}
                      style={{marginTop: "1rem"}}
                    >
                      Delete Role
                    </Button>
                  )}
                </div>
                <div className={styles.accordianContainer}>
                  {PermissionsCategories.map((set) => (
                    <div className={styles.setting}>
                      <div style={{ maxWidth: "450px" }}>
                        <div className={styles.header2}>{set.set_name}</div>
                        <div className={styles.description2}>
                          {set.description}
                        </div>
                      </div>
                      <div className={styles.settingToggles}>
                        <SettingToggle
                          text={set.first}
                          key={set.set_name}
                          checked={currRole[set.db_field]}
                          thick
                          onCheck={(e) => {
                            checkAll(
                              set.db_field,
                              set?.permissions,
                              set?.org_permissions,
                              e.target.checked
                            );
                          }}
                        />
                        <div className={styles.allPermissions}>
                          {set?.permissions.length > 0 && (
                            <div className={styles.permissions}>
                              {set?.org_permissions.length > 0 && (
                                <div className={styles.header}>User</div>
                              )}
                              {set.permissions.map((permission) => (
                                <SettingToggle
                                  text={permission.label}
                                  key={permission.db_field}
                                  checked={currRole[permission.db_field]}
                                  onCheck={(e) =>
                                    changePermission(permission.db_field, e)
                                  }
                                />
                              ))}
                            </div>
                          )}
                          {set?.org_permissions.length > 0 && (
                            <div className={styles.permissions}>
                              {set?.permissions.length > 0 && (
                                <div className={styles.header}>
                                  Organization
                                </div>
                              )}
                              {set.org_permissions.map((permission) => (
                                <SettingToggle
                                  text={permission.label}
                                  key={permission.db_field}
                                  checked={currRole[permission.db_field]}
                                  onCheck={(e) =>
                                    changePermission(permission.db_field, e)
                                  }
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <HorizontalBar
                        height="2px"
                        backgroundColor="lightgray"
                      ></HorizontalBar>
                    </div>
                  ))}
                </div>
                {/* 
                <div className={styles.accordianContainer}>
                  {PermissionsCategories.map((set) => (
                    <Accordion
                      key={set.set_name}
                      name={set.set_name}
                      role={currRole}
                      onCheck={(val) => checkAll(set.permissions, val)}
                    >
                      {set.permissions.map((permission) => (
                        <div key={permission.db_field} className={styles.item}>
                          <Checkbox
                            checked={currRole[permission.db_field]}
                            onChange={(e) =>
                              changePermission(permission.db_field, e)
                            }
                          ></Checkbox>
                          <span>{permission.label}</span>
                        </div>
                      ))}
                    </Accordion>
                  ))}
                </div> */}

                {roles.canEditOrgRoles && (
                  <div className={styles.buttonsDiv}>
                    {newRole && (
                      <Button
                        className={styles.submitbutton}
                        onClick={() => setCurrRole(null)}
                        shadow
                        width={80}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      className={styles.submitbutton}
                      onClick={handleSave}
                      shadow
                      width={100}
                      blue
                    >
                      {saving}
                      {updateStatus.isError && "Error on save"}
                    </Button>
                  </div>
                )}
              </div>
            )}
            {!currRole && (
              <div className={styles.noRoleSelected}>No Role Selected</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
