import {
  MultChoice,
  Scale,
  Text,
} from "pages/results/Charts/Settings/AccordianItems/SurveyData/QData";
import styles from "./MatrixQ.module.scss";
import { useState, useEffect, useLayoutEffect } from "react";
import TextEditorPlain from "components/inputs/input_fields/TextEditorPlain/TextEditorPlain";
import { ChoiceMatrixOption } from "./Choice/ChoiceMatrixOption";
import { ScaleMatrixOption } from "./Scale/ScaleMatrixOption";
import { SliderMatrixOption } from "./Slider/SliderMatrixOption";

export default function MatrixQ({
  question,
  designSettings,
  onAnswer,
  onRetract,
  answerMap,
}) {
  const matrix = question.matrixQuestion;

  function getRange() {
    let range = [];
    for (
      let i = question.scaleQuestion.min;
      i <= question.scaleQuestion.max;
      i += question.scaleQuestion.step
    ) {
      range.push(i);
    }
    return range;
  }

  function saveTextAnswer(option, val) {
    let data = answerMap[question.id] ? { ...answerMap[question.id] } : {};

    if (val) {
      data[option] = val;
      onAnswer(question.id, data);
    } else {
      delete data[option];

      if (Object.keys(data).length) {
        onAnswer(question.id, data);
      } else {
        onRetract(question.id);
      }
    }
  }

  function handleChoiceCheck(option, choice, val) {
    let data = answerMap[question.id] ? { ...answerMap[question.id] } : {};

    if (val) {
      if (!data[option]) {
        data[option] = [];
      }

      if (
        question.choiceQuestion.isMultiSelect ||
        question.choiceQuestion.isRanking
      ) {
        if (question.choiceQuestion.limit === data[option].length) {
          return;
        }
        data[option].push(choice);
      } else {
        data[option] = [choice];
      }

      onAnswer(question.id, data);
    } else {
      data[option] = answerMap[question.id][option].filter(
        (item) => item !== choice
      );

      if (!data[option].length) {
        delete data[option];
      }

      if (Object.keys(data).length) {
        onAnswer(question.id, data);
      } else {
        onRetract(question.id);
      }
    }
  }

  function handleScaleCheck(option, num, val) {
    let data = answerMap[question.id] ? { ...answerMap[question.id] } : {};

    if (val) {
      data[option] = num;
      onAnswer(question.id, data);
    } else {
      delete data[option];

      if (Object.keys(data).length) {
        onAnswer(question.id, data);
      } else {
        onRetract(question.id);
      }
    }
  }

  function handleSliderChange(option, val) {
    let data = answerMap[question.id] ? { ...answerMap[question.id] } : {};

    data[option] = val;
    onAnswer(question.id, data);
  }

  useLayoutEffect(() => {
    if (
      question.choiceQuestion &&
      (settings.transpose
        ? matrix.options.length
        : question.choiceQuestion.choices.length)
    ) {
      // console.log("\nIn Survey");
      let table = document.getElementById(question.id + "table");
      let topLeft = document.getElementById(question.id + "topLeft");
      if (table) {
        let portion =
          (table.clientWidth - topLeft.clientWidth) /
          question.choiceQuestion.choices.length;

        // console.log("portion: ", portion);
        // console.log("topLeft: ", topLeft.clientWidth);
        // console.log("should be", settings.width);

        let columns = settings.transpose
          ? matrix.options
          : question.choiceQuestion.choices;
        for (let i = 0; i < columns.length; i++) {
          let col = document.getElementById(question.id + "choice" + i);
          if (col) {
            col.style.width = portion + "px";
          }
        }
      }
    }
  }, []);

  const settings = question.matrixQuestion.settings;

  return (
    <>
      <div
        className={`${styles.matrix} ${
          settings.hideLines || settings.hideX ? styles.noX : ""
        }  ${settings.hideLines || settings.hideY ? styles.noY : ""}`}
      >
        <table className={styles.table} id={question.id + "table"}>
          <thead>
            {(matrix.type === Scale || matrix.type === "NumberSlider") &&
              (question.scaleQuestion.minDescription ||
                question.scaleQuestion.maxDescription) &&
              !settings.transpose && (
                <tr className={styles.topAbove}>
                  <th></th>
                  <th
                    colSpan={getRange().length}
                    className={styles.minMaxContainer}
                  >
                    <div className={styles.minMax}>
                      <div>{question.scaleQuestion.minDescription}</div>
                      <div>{question.scaleQuestion.maxDescription}</div>
                    </div>
                  </th>
                </tr>
              )}
            <tr>
              <th
                style={settings.width ? { width: settings.width } : undefined}
                id={question.id + "topLeft"}
              ></th>

              {!settings.transpose && (
                <>
                  {matrix.type === MultChoice &&
                    question.choiceQuestion.choices.map((choice, i) => (
                      <th id={question.id + "choice" + i}>
                        <TextEditorPlain text={choice} />
                      </th>
                    ))}

                  {(matrix.type === Scale || matrix.type === "NumberSlider") &&
                    getRange().map((col) => (
                      <th id={question.id + "th " + col}>
                        {settings.hideNumbers ? "" : col}
                      </th>
                    ))}

                  {matrix.type === Text && (
                    <th>
                      {matrix.heading && (
                        <TextEditorPlain text={matrix.heading} />
                      )}
                    </th>
                  )}
                </>
              )}

              {settings.transpose &&
                matrix.options.map((option, i) => (
                  <th id={question.id + "choice" + i}>
                    <TextEditorPlain text={option} />
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {!settings.transpose &&
              matrix.options.map((option, i) => (
                <tr>
                  <td className={styles.yAxisLabel}>
                    <TextEditorPlain text={option} />
                  </td>
                  {matrix.type === Text && (
                    <td className={styles.textEntry}>
                      <TextEditorPlain
                        text={
                          answerMap[question.id]
                            ? answerMap[question.id][option]
                            : ""
                        }
                        editable
                        onSave={(val) => saveTextAnswer(option, val)}
                        style={{
                          backgroundColor: "#f5f5f5",
                          borderRadius: "10px",
                        }}
                        multiLine
                      />
                    </td>
                  )}
                  {matrix.type === MultChoice &&
                    question.choiceQuestion.choices.map((choice) => (
                      <td>
                        <ChoiceMatrixOption
                          choice={choice}
                          option={option}
                          selected={
                            answerMap[question.id] &&
                            answerMap[question.id][option]
                              ? answerMap[question.id][option]
                              : []
                          }
                          handleCheck={(val) =>
                            handleChoiceCheck(option, choice, val)
                          }
                          color={designSettings.baseColor}
                          rank={question.choiceQuestion.isRanking}
                          multi={question.choiceQuestion.isMultiSelect}
                        />
                      </td>
                    ))}
                  {matrix.type === Scale &&
                    getRange().map((num) => (
                      <td>
                        <ScaleMatrixOption
                          num={num}
                          selected={
                            answerMap[question.id] &&
                            option in answerMap[question.id]
                              ? answerMap[question.id][option] === num
                              : false
                          }
                          handleCheck={(val) =>
                            handleScaleCheck(option, num, val)
                          }
                          color={designSettings.baseColor}
                          showNum={matrix.settings.showNumbers}
                          settings={matrix.settings}
                        />
                      </td>
                    ))}

                  {matrix.type === "NumberSlider" && (
                    <SliderMatrixOption
                      value={
                        answerMap[question.id] &&
                        option in answerMap[question.id]
                          ? answerMap[question.id][option]
                          : -1
                      }
                      handleChange={(val) => handleSliderChange(option, val)}
                      color={designSettings.baseColor}
                      scaleQ={question.scaleQuestion}
                      span={getRange().length}
                      first={!i}
                      qId={question.id}
                    />
                  )}
                </tr>
              ))}

            {settings.transpose && (
              <>
                {matrix.type === MultChoice &&
                  question.choiceQuestion.choices.map((choice) => (
                    <tr>
                      <td className={styles.yAxisLabel}>
                        <TextEditorPlain text={choice} />
                      </td>

                      {matrix.options.map((option) => (
                        <td>
                          <ChoiceMatrixOption
                            choice={choice}
                            option={option}
                            selected={
                              answerMap[question.id] &&
                              answerMap[question.id][option]
                                ? answerMap[question.id][option]
                                : []
                            }
                            handleCheck={(val) =>
                              handleChoiceCheck(option, choice, val)
                            }
                            color={designSettings.baseColor}
                            rank={question.choiceQuestion.isRanking}
                            multi={question.choiceQuestion.isMultiSelect}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
