// External
import React, { useState } from "react";
import { ContactCleaner } from "components/ContactCleaner2.0/ContactCleaner";
import {
  useCreateContactGql,
  useCreateContactImportGql,
} from "api/resources/contacts/contacts";
import { useAddAudienceContactsViaUploadGql } from "api/resources/contacts/audiences";

export const Upload = ({
  audience,
  isAudience,
  audienceId,
  success,
  user,
  setShow,
}) => {
  const addContactToAudience = useAddAudienceContactsViaUploadGql();
  // const createManyContact = useCreateManyContactGql();
  const createContactImport = useCreateContactImportGql();
  const createContact = useCreateContactGql();

  function addContacts(fileContents) {
    console.log("Add Contacts");
    class contacts {
      email;
      firstName;
      lastName;
      phone;
      prefix;
    }
    class contactArrayInput {
      contacts = [contacts];
    }

    let cList = new contactArrayInput();
    cList.contacts.push(
      fileContents.finalArray.map((contact) => {
        let c = new contacts();
        c.prefix = contact.prefix ? contact.prefix : "none";
        c.firstName = contact.firstname;
        c.lastName = contact.lastname;
        c.email = contact.email;
        c.company = contact.company ? contact.company : "none";
        c.position = contact.position ? contact.position : "none";
        return c;
      })
    );
    console.log(cList);
    cList.contacts = cList.contacts[1];
    console.log(cList);
    addContactToAudience.mutate(
      {
        contactsList: cList,
        addAudienceContactsViaUploadId: audienceId,
      },
      {
        onSuccess: (data) => {
          console.log(data);
          audience.contacts = data.addAudienceContactsViaUpload.contacts;
          success();
        },
      }
    );
  }

  function uploadContacts(fileContents) {
    console.log("uploadContacts");
    //create each contact to be imported
    let contacts = [];
    class contactInput {
      prefix;
      firstName;
      lastname;
      email;
      company;
      position;
    }
    fileContents.finalArray.map((contact) => {
      let c = new contactInput();
      c.prefix = contact.prefix ? contact.prefix : "none";
      c.firstName = contact.firstname;
      c.lastName = contact.lastname;
      c.email = contact.email;
      c.company = contact.company ? contact.company : "none";
      c.position = contact.position ? contact.position : "none";
      console.log(c);
      createContact.mutate({
        data: c,
      });
      contacts.push({
        c,
      });
    });
  }

  if (isAudience === true) {
    return (
      <>
        <ContactCleaner
          uploadContacts={addContacts}
          user={user}
          audienceId={audienceId}
          buttonName={"Clean & Add"}
        ></ContactCleaner>
        {createContact.isError ||
          (createContactImport.isError && <div>Error...</div>)}
        {addContactToAudience.isLoading && (
          <div>
            Your upload is being processed, we will notify you when it is
            finished. You may leave this page if you so wish
          </div>
        )}
        {createContact.isSuccess && { success }}
      </>
    );
  } else {
    return (
      <>
        <ContactCleaner
          uploadContacts={uploadContacts}
          user={user}
          audienceId={""}
        ></ContactCleaner>
        {createContact.isError ||
          (createContactImport.isError && <div>Error...</div>)}
        {createContact.isLoading ||
          (createContactImport.isLoading && (
            <div>
              Your upload is being processed, we will notify you when it is
              finsished. You may leave this page if you so wish
            </div>
          ))}
        {createContact.isSuccess && (
          <div>
            Your upload finished
            {/* {routeChangePath(
              "/contacts/previous-imports/" +
                createContactImport.data.createContactimport.id
            )} */}
          </div>
        )}
      </>
    );
  }
};
