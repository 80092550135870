import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import styles from "./Switch.module.scss";
import { ChartLabel } from "../ChartLabel/ChartLabel";

export function Switch({
  checked,
  onCheck,
  children,
  style,
  tooltip,
  tipStyle,
}) {
  return (
    <div className={styles.container} style={style}>
      <ToggleSwitch startChecked={checked} handleCheck={onCheck}></ToggleSwitch>
      <ChartLabel tooltip={tooltip} tipStyle={tipStyle}>
        {children}
      </ChartLabel>
    </div>
  );
}
