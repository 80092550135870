import React from "react";
import styles from "./EditableNavigation.module.scss";
import { Label } from "components/layouts/Label/Label";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { SurveyButton } from "components/Survey/SurveyButton/SurveyButton";

function EditableNavigation({
  settings,
  setSettings,
  previousPage,
  nextPage,
  onFinish,
  page,
  maxPage,
  active,
  setActive,
  close,
}) {


  const color = settings.buttonColor
    ? settings.buttonColor
    : settings.baseColor;

  function onBackgroundClick(e) {
    if (!e.target.className.includes("customButton")) {
      setActive("nav");
      document.addEventListener("click", checkForClickOutside, true);
    }
  }

  function checkForClickOutside(e) {
    let container = document.getElementById("nav");
    if (container && !container.contains(e.target)) {
      
      let root = document.getElementById("root");
      if (root && !root.contains(e.target)) {
        // It's a color picker - or some modal like it
        return;
      }

      document.removeEventListener("click", checkForClickOutside, true);
      close();
    }
  }

  return (
    <div
      className={`${styles.navContainer} ${
        active ? styles.activeNavContainer : styles.inactiveNavContainer
      } `}
      id="nav"
    >
      <div
        className={`${styles.pageButtonsContainer} ${
          active ? "" : styles.pageButtonsInactive
        }`}
        onClick={active ? null : onBackgroundClick}
      >
       {!active && <div className={styles.editSettings}>Click to edit</div>}
        {page != 1 && (
          <SurveyButton
            styleType={settings.buttonStyle}
            color={color}
            onClick={previousPage}
            shadow={settings?.buttonShadow}
          >
            BACK
          </SurveyButton>
        )}
        {page != maxPage && (
          <SurveyButton
            styleType={settings.buttonStyle}
            color={color}
            onClick={nextPage}
            shadow={settings?.buttonShadow}
          >
            NEXT
          </SurveyButton>
        )}
        {page === maxPage && (
          <SurveyButton
            styleType={settings.buttonStyle}
            color={color}
            onClick={onFinish}
            shadow={settings?.buttonShadow}
          >
            FINISH
          </SurveyButton>
        )}

        {settings.showPageProg && (
          <span style={{ zIndex: "0" }}>
            {" "}
            PAGE {page} OF {maxPage}
          </span>
        )}
      </div>
      
    </div>
  );
}

export default EditableNavigation;
