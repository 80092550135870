import React, { useState } from "react";
import styles from "./ColumnMapping.module.scss";
import Modal from "components/ReactModal/ReactModal";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { Label } from "components/layouts/Label/Label";
import Button from "components/Button/Button";
import FlexRow from "components/layouts/FlexRow/FlexRow";

function ColumnMapping({
  headers,
  headerMap,
  setHeaderMap,
  importType,
  sync,
  setHeaders,
  customFieldsMap,
  setCustomFieldsMap,
  useModal,
  showMapping,
  setShowMapping,
}) {
  const [activeEdit, setActiveEdit] = useState();
  const [change, setChange] = useState(false);
  //   const fetchCustomFields = useSearchCurrOrgsFieldsNoProps("prefix");
  const fetchCustomFields = useFetchCurrOrgsFields();

  function getFieldOptions() {
    let custom = [];
    for (let f of fetchCustomFields.data?.getCurrOrgFields) {
      if (customFieldsMap && !customFieldsMap[f.name]) {
        custom.push({ id: f.id, value: f.name, label: f.displayName });
      } else if (!customFieldsMap) {
        custom.push({ id: f.id, value: f.name, label: f.displayName });
      }
    }
    // let custom = fetchCustomFields.data?.getCurrOrgFields?.map((f) => {
    //   return { id: f.id, value: f.name, label: f.displayName };
    // });
    // return [
    //   {
    //     id: "firstName",
    //     name: "firstName",
    //     displayName: "First Name",
    //     label: "First Name",
    //     value: "firstName",
    //   },
    //   {
    //     id: "lastName",
    //     name: "lastName",
    //     displayName: "Last Name",
    //     label: "Last Name",
    //     value: "lastName",
    //   },
    //   {
    //     id: "email",
    //     name: "email",
    //     displayName: "Email",
    //     label: "Email",
    //     value: "email",
    //   },
    //   ...custom,
    // ];
    return custom;
  }

  function getCount(heads) {
    let c = 0;
    for (let h of heads) {
      if (h?.enabled) {
        c++;
      }
    }
    return c;
  }

  function updateMap(vals) {
    if (useModal) {
      setChange(true);
    }
    let goahead = true;
    if (
      headerMap[
        activeEdit?.objectAccessor
          ? activeEdit?.accessor +
            " " +
            activeEdit?.objectAccessor?.toLowerCase()
          : activeEdit?.accessor
      ].columns[0]?.salesforceColumn
    ) {
      if (
        !confirm(
          "This column is already synced to a salesforce column, are you sure you wish to change?"
        )
      ) {
        goahead = false;
      }
    }
    if (goahead) {
      let tempcust = {};
      if (customFieldsMap) {
        tempcust = { ...customFieldsMap };
      }
      if (
        setHeaders &&
        headerMap[
          activeEdit?.objectAccessor
            ? activeEdit?.accessor +
              " " +
              activeEdit?.objectAccessor?.toLowerCase()
            : activeEdit?.accessor
        ].columns.length > 0
      ) {
        tempcust[
          headerMap[
            activeEdit?.objectAccessor
              ? activeEdit?.accessor +
                " " +
                activeEdit?.objectAccessor?.toLowerCase()
              : activeEdit?.accessor
          ].columns[0].value
        ] = false;
      }

      headerMap[
        activeEdit?.objectAccessor
          ? activeEdit?.accessor +
            " " +
            activeEdit?.objectAccessor?.toLowerCase()
          : activeEdit?.accessor
      ].columns = vals;
      if (
        setHeaders &&
        headerMap[
          activeEdit?.objectAccessor
            ? activeEdit?.accessor +
              " " +
              activeEdit?.objectAccessor?.toLowerCase()
            : activeEdit?.accessor
        ].columns.length > 0
      ) {
        let newheads = [...headers];
        newheads[
          headerMap[
            activeEdit?.objectAccessor
              ? activeEdit?.accessor +
                " " +
                activeEdit?.objectAccessor?.toLowerCase()
              : activeEdit?.accessor
          ].index
        ].enabled = true;
        newheads[
          headerMap[
            activeEdit?.objectAccessor
              ? activeEdit?.accessor +
                " " +
                activeEdit?.objectAccessor?.toLowerCase()
              : activeEdit?.accessor
          ].index
        ].name =
          headerMap[
            activeEdit?.objectAccessor
              ? activeEdit?.accessor +
                " " +
                activeEdit?.objectAccessor?.toLowerCase()
              : activeEdit?.accessor
          ].columns[0].label;
        setHeaders(newheads);
        tempcust[
          headerMap[
            activeEdit?.objectAccessor
              ? activeEdit?.accessor +
                " " +
                activeEdit?.objectAccessor?.toLowerCase()
              : activeEdit?.accessor
          ].columns[0].value
        ] = true;
        if (setCustomFieldsMap) {
          setCustomFieldsMap(tempcust);
        }
      }
    }
  }

  return (
    <>
      {useModal && (
        <Modal
          show={useModal && showMapping && !activeEdit}
          onClose={() => setShowMapping(false)}
          modalStyle={{
            borderRadius: "1em",
            //   height: importExternal ? "95%" : "800px",
            width: "800px",
            height: "95%",
            //   minWidth: manualEntry ? "fit-content" : "",
            padding: "1em",
            overflow: "visible",
            gap: "1em",
          }}
          dark
        >
          <div className={styles.mappingPage}>
            <span>
              {/* Click "Edit <i className="bi-pencil" style={{ fontSize: ".8em" }}></i>
          " next to the desired column you wish to map.{" "} */}
              {sync
                ? "Columns not mapped will not be synced to Reaction."
                : "Columns not mapped will not be imported."}
            </span>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    {importType === "Salesforce" ? "Salesforce" : "File"}{" "}
                    Columns ({headers.length})
                  </th>
                  <th className={styles.mheader}>
                    {sync ? "Mapped Reaction" : "Reaction"} Columns (
                    {getCount(headers)})
                  </th>
                </tr>
              </thead>
              <tbody>
                {headers?.map((h, i) => (
                  <tr key={i}>
                    <td>
                      <div
                        className={styles.edit}
                        onClick={() => setActiveEdit(h)}
                      >
                        Edit <i className="bi-pencil"></i>
                      </div>
                    </td>
                    <td>{h?.label}</td>
                    <Mapper
                      h={h}
                      headerMap={headerMap}
                      setActiveEdit={setActiveEdit}
                      sync={sync}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <FlexRow style={{ justifyContent: "flex-end" }}>
            <Button
              blue
              shadow
              style={{ width: "fit-content" }}
              link
              onClick={() => setShowMapping(false)}
            >
              Cancel
            </Button>
            <Button
              blue
              shadow
              style={{ width: "fit-content" }}
              disable={!change}
            >
              Save
            </Button>
          </FlexRow>
        </Modal>
      )}
      <Modal
        show={activeEdit}
        onClose={() => setActiveEdit(null)}
        modalStyle={{
          borderRadius: "1em",
          //   height: importExternal ? "95%" : "800px",
          width: "800px",
          height: "fit-content",
          //   minWidth: manualEntry ? "fit-content" : "",
          padding: "1em",
          paddingTop: "1em",
          paddingBottom: "3em",
          overflow: "visible",
          gap: "1em",
        }}
        dark
      >
        {activeEdit && (
          <>
            <div className={styles.header}>
              Column to Map: {activeEdit?.label}
            </div>
            <div className={styles.header2}>
              {" "}
              <Checkbox
                checked={
                  headerMap[
                    activeEdit?.objectAccessor
                      ? activeEdit?.accessor +
                        " " +
                        activeEdit?.objectAccessor?.toLowerCase()
                      : activeEdit?.accessor
                  ]?.new
                }
                onChange={(e) => {
                  if (useModal) {
                    setChange(true);
                  }
                  let map = { ...headerMap };
                  map[
                    activeEdit?.objectAccessor
                      ? activeEdit?.accessor +
                        " " +
                        activeEdit?.objectAccessor?.toLowerCase()
                      : activeEdit?.accessor
                  ].new = e.target.checked;
                  if (setHeaders && e.target.checked) {
                    let newheads = [...headers];
                    newheads[
                      map[
                        activeEdit?.objectAccessor
                          ? activeEdit?.accessor +
                            " " +
                            activeEdit?.objectAccessor?.toLowerCase()
                          : activeEdit?.accessor
                      ].index
                    ].enabled = true;
                    setHeaders(newheads);
                  }

                  setHeaderMap(map);
                }}
              ></Checkbox>{" "}
              Create new Reaction Column from "{activeEdit?.name}" column{" "}
              <Label
                labelIcon={<i className="bi-question-circle"></i>}
                tooltipText={
                  "If toggled on, this column will be created as a Reaction Column. If this column already exists as a Reaction Column, it will not be created"
                }
                style={{ width: "fit-content", padding: "0em", margin: "0em" }}
                tooltipStyle={{ padding: "2em", width: "300px" }}
              ></Label>
            </div>
            {fetchCustomFields.isSuccess && (
              <CombinedInput
                options={getFieldOptions()}
                value={
                  headerMap[
                    activeEdit?.objectAccessor
                      ? activeEdit?.name
                      : activeEdit?.accessor
                  ]?.columns[0]
                }
                select
                label="Select a Reaction Column"
                onChange={(vals) => {
                  updateMap([vals]);
                }}
                icon="bi-chevron-down"
                pushDropdown
                placeholder="No column chosen"
                anyWhereOptions
                disableState
              ></CombinedInput>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button shadow onClick={() => setActiveEdit(null)}>
                Done
              </Button>
            </div>
          </>
        )}
      </Modal>
      {!useModal && (
        <div className={styles.mappingPage}>
          <span>
            {/* Click "Edit <i className="bi-pencil" style={{ fontSize: ".8em" }}></i>
          " next to the desired column you wish to map.{" "} */}
            {sync
              ? "Columns not mapped will not be synced to Reaction. (You can adjust this later too)"
              : "Columns not mapped will not be imported."}
          </span>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>
                  {importType === "Salesforce" ? "Salesforce" : "File"} Columns
                  ({headers.length})
                </th>
                <th className={styles.mheader}>
                  {sync ? "Mapped Reaction" : "Reaction"} Columns (
                  {getCount(headers)})
                </th>
              </tr>
            </thead>
            <tbody>
              {headers?.map((h, i) => (
                <tr key={i}>
                  <td>
                    <div
                      className={styles.edit}
                      onClick={() => setActiveEdit(h)}
                    >
                      Edit <i className="bi-pencil"></i>
                    </div>
                  </td>
                  <td>{h?.label ? h?.label : h?.name}</td>
                  <Mapper
                    h={h}
                    headerMap={headerMap}
                    setActiveEdit={setActiveEdit}
                    sync={sync}
                  />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default ColumnMapping;

function Mapper({ h, headerMap, setActiveEdit, sync }) {
  const mapped =
    headerMap[h.objectAccessor ? h?.name : h.accessor]?.columns?.length > 0 ||
    headerMap[h.objectAccessor ? h?.name : h.accessor]?.new;

  return (
    <td>
      {!mapped ? (
        <div className={styles.mapper}>
          <div
            className={styles.not}
            onClick={() => (setActiveEdit ? setActiveEdit(h) : undefined)}
          >
            Not mapped
          </div>{" "}
          <Label
            labelIcon={<i className="bi-question-circle"></i>}
            // tooltipText={
            //   !sync
            //     ? 'Columns that are not mapped are not imported into Reaction. Click "Edit" to map this field to an existing Reaction Column or choose to create this as a new Reaction Column'
            //     : 'Columns that are not mapped are not sync with Reaction. Click "Edit" to map this field to an existing Reaction Column or choose to create this as a new Reaction Column'
            // }
            style={{ width: "fit-content", padding: "0em", margin: "0em" }}
            tooltipStyle={{ padding: "2em", width: "300px" }}
          ></Label>
        </div>
      ) : (
        <>
          {headerMap[h.objectAccessor ? h?.name : h.accessor]?.columns?.map(
            (map, i) => (
              <span
                key={i}
                onClick={() => (setActiveEdit ? setActiveEdit(h) : undefined)}
                className={styles.link}
                style={{ color: "#616565" }}
              >
                {map?.label}
                {headerMap[h.objectAccessor ? h?.name : h.accessor]?.columns
                  ?.length != 1 &&
                i + 1 !=
                  headerMap[h.objectAccessor ? h?.name : h.accessor]?.columns
                    ?.length
                  ? ", "
                  : ""}
              </span>
            )
          )}
          {headerMap[h.objectAccessor ? h?.name : h.accessor]?.new && (
            <div className={styles.mapper}>
              <div className={styles.new}>
                {/* {h.name} */}
                New Column
              </div>{" "}
              <Label
                labelIcon={<i className="bi-question-circle"></i>}
                tooltipText={
                  "On import, this column will be created as a Reaction Column, you will be able to view this column through the site and also use it as a filter."
                }
                style={{ width: "fit-content", padding: "0em", margin: "0em" }}
                tooltipStyle={{ padding: "2em", width: "300px" }}
              ></Label>
            </div>
          )}
        </>
      )}
    </td>
  );
}
