//External
import React, { useState, useEffect, useRef } from "react";

//Internal
import styles from "./AccountDetails.module.scss";
import {
  useGetCurrentUser,
  useUpdateUser,
} from "api/resources/organization/users";
import { useGetOrgName } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import blankProf from "assets/images/blank-profile-picture.png";
import { TextFieldSimple } from "components/inputs/input_fields/TextFieldSimple/TextFieldSimple";
import Button from "components/Button/Button";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { useUpdatePswd } from "api/resources/authentication/register";
import { useLoginRequest } from "api/resources/authentication/login";
// import { useUpdateEmail } from "api/resources/authentication/register";
import axios from "axios";
import ImageUploadModal from "components/ImageUploadModal/imageUploadModal";
import ImageEditor from "components/Image Editor/ImageEditor";
import UserProfile2 from "components/Popout/UserProfile2";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";

/**
 * A component for the Account Details page. Click on the user in the sidemenu to get here.
 * @returns {AccountDetails} an html account details component
 */
export function AccountDetails({ organization, roles }) {
  const currentUser = useGetCurrentUser();

  return (
    <div className={styles.mainPage}>
      {currentUser.isSuccess && (
        <UserProfile2
          userId={currentUser?.data?.me?.id}
          organizationId={organization?.id}
          roles={currentUser.data?.me?.roles}
          canEdit
          account
        ></UserProfile2>
      )}
     
    </div>
  );
}

/**
 * A companion component for the Account Details page. ChangePassword is a modal that shows upto change the current users password
 * @param {Object} currUser the current user object
 * @param {Function} close a function that closes the modal when called.
 * @returns {AccountDetails} a change password modal html component
 */
export function ChangePassword({ currUser, close }) {
  // States
  const [validCurPswd, setValidCurPswd] = useState(false);
  const [newPassword1, setNewPassword1] = useState();
  const [newPassword2, setNewPassword2] = useState();
  const [entriesMatch, setEntriesMatch] = useState(false);
  const [showPswdConflict, setShowPswdConflict] = useState(false);
  const [newPswd, setNewPswd] = useState();
  const [currPswdIcon, setCurrPswdIcon] = useState();
  const [savedStatus, setSavedStatus] = useState();

  // Queries
  const updatePswd = useUpdatePswd();
  const updateUser = useUpdateUser();
  const loginUserRequest = useLoginRequest();

  // Hooks
  useEffect(() => {
    checkNewPasswords();
  }, [newPassword1, newPassword2]);

  // Functions
  function checkCurrentPassword(val) {
    loginUserRequest.mutate(
      {
        email: currUser.email,
        password: val,
      },
      {
        onSuccess: (data) => {
          if (data.login.user.id === currUser.id) {
            setValidCurPswd(true);
            setCurrPswdIcon(
              <i className={`bi bi-check2 ${styles.checkmark}`}></i>
            );
          } else {
            setValidCurPswd(false);
            setCurrPswdIcon(<i className={`bi bi-x-lg ${styles.error}`}></i>);
          }
        },
        onError: (err) => {
          setValidCurPswd(false);
          setCurrPswdIcon(<i className={`bi bi-x-lg ${styles.error}`}></i>);
        },
      }
    );
  }

  const checkNewPasswords = () => {
    if (newPassword1 !== undefined && newPassword2 !== undefined) {
      if (
        newPassword1 !== "" &&
        newPassword2 !== "" &&
        newPassword1 === newPassword2
      ) {
        setNewPswd(newPassword1);
        setEntriesMatch(true);
        setShowPswdConflict(false);
      } else {
        setEntriesMatch(false);
        setShowPswdConflict(true);
      }
    } else {
      setEntriesMatch(false);
      setShowPswdConflict(false);
    }
  };

  const handleUpdatePswd = () => {
    updateUser.mutate(
      {
        data: { requestedPassChange: true },
      },
      {
        onSuccess: () => {
          updatePswd.mutate(
            {
              userId: currUser.id,
              password: newPswd,
            },
            {
              onSuccess: (data) => {
                setSavedStatus(true);
                setTimeout(() => close(), 1000);
              },
              onError: (data) => {},
            }
          );
        },
      }
    );
  };

  // HTML Component
  return (
    <div className={styles.changePasswordContainer}>
      <div className={styles.label}>Change Password</div>
      <div>
        <div className={styles.checkmarkAndFieldDiv}>
          <div style={{ width: "95%" }}>
            <CombinedInput
              type={"password"}
              onChange={checkCurrentPassword}
              label={"Enter your current password"}

            />
          </div>
          {currPswdIcon}
        </div>
      </div>
      <HorizontalBar width={"95%"} height={"2px"}></HorizontalBar>
      <div>
        <div style={{ width: "95%" }}>
          <CombinedInput
            type={"password"}
            value={newPassword1}
            onChange={(val) => setNewPassword1(val)}
            label={"Enter your new password"}

          />
        </div>
      </div>
      <div>
        <div className={styles.checkmarkAndFieldDiv}>
          <div style={{ width: "95%" }}>
            <CombinedInput
              type={"password"}
              value={newPassword2}
              onChange={(val) => setNewPassword2(val)}
              label={"Re-enter new password"}

            />
          </div>
          {entriesMatch && (
            <i className={`bi bi-check2 ${styles.checkmark}`}></i>
          )}
        </div>
      </div>

      <span
        className={styles.entriesDontMatch}
        style={
          showPswdConflict
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        Entries Don&apos;t Match
      </span>

      <div
        className={styles.pswdSaveDiv}
        style={
          validCurPswd && entriesMatch
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        <div style={{ width: "82px", height: "30px" }}>
          <Button onClick={handleUpdatePswd} shadow>Save</Button>
          {savedStatus && (
            <i className={`bi bi-check2 ${styles.saved}`}>Success!</i>
          )}
        </div>
      </div>
    </div>
  );
}
