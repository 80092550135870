// External
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Internal
import {
  Login,
  Register,
  SurveyTaker,
  SurveyTakerNoPart,
} from "pages";
import { ForgotPassword } from "pages/ForgotPassword/ForgotPassword";
// import { useValidPassChangeRequest } from "api/resources/authentication/forgotpassword";
import Unsubscribe from "pages/unsubscribe/Unsubscribe";
import { SurveyPreview } from "pages/projects/project/survey_build/preview/SurveyPreview";
import MFA from "pages/register/MFA";

export const NonSignInRoutesRoutes = ({ setToken }) => {

  return (
    <>
      <Routes>
        <Route path="/health" element={<h1>I'm Healthy</h1>} />
        <Route path="/" element={<Navigate to="login" />} />
        <Route path="login" element={<Login setToken={setToken} />} />
        <Route path="register" element={<Register setToken={setToken} />} />
        <Route path="register/multisetup/:id" element={<MFA />} />
        <Route path="register/join-organization" element={<Register join/>} />
        <Route path="login/:id" element={<MFA login finish/>} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path="forgotPassword/:userId" element={<ForgotPassword />} />
        <Route
          path=":projectId"
          element={<SurveyTakerNoPart />}
        />
        <Route
          path="take-survey/specific/:projectId/:participantId/:distId"
          element={<SurveyTaker />}
        />
        <Route path="unsubscribe/:id" element={<Unsubscribe />} />
        <Route path="unsubscribe/:id/:deliveryId" element={<Unsubscribe />} />
        <Route path="survey/:id/preview" element={<SurveyPreview />} />
        <Route path="*" element={<Navigate to="login" />} />
      </Routes>
    </>
  );
};
