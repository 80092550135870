import styles from "./Link.module.scss";
import { useState } from "react";
import { SmallCheckbox } from "components/inputs/input_fields/SmallCheckbox/SmallCheckbox";
import { TextColorPicker } from "components/inputs/input_fields/TextEditorTry/TextColorPicker";

export const defaultLink = {
  asButton: false,
  text: "here",
  surveyLink: true,
  unsub: false,
  custom: false,
  mailTo: false,
  color: "#15bcc7",
};

export function Link({
  colorRef,
  onLinkEdit,
  button,
  unsub,
  onSurveyUrl,
  init,
}) {
  const [link, setLink] = useState(
    init
      ? { ...init }
      : {
          ...defaultLink,
          asButton: button ? true : false,
          underline: unsub ? true : false,
          text: unsub ? "unsubscribe" : "here",
        }
  );

  const [surveyUrl, setSurveyUrl] = useState(false);

  function changeToSurveyUrl(val) {
    if (val) {
      onSurveyUrl();
    } else {
      onLinkEdit(link);
    }
    setSurveyUrl(val);
  }

  function updateLink(copy) {
    onLinkEdit(copy);
    setLink(copy);

    if (surveyUrl) {
      setSurveyUrl(false);
    }
  }

  function changeField(field, val) {
    let copy = { ...link };
    copy[field] = val;
    updateLink(copy);
  }

  function changeToLink() {
    let copy = { ...link };
    copy.asButton = false;
    updateLink(copy);
  }

  function changeToButton() {
    let copy = { ...link };
    copy.asButton = true;
    if (!copy.fontColor) {
      copy.fontColor = "#ffffff";
    }
    updateLink(copy);
  }

  function makeSurveyLink(val) {
    let copy = {
      ...link,
      surveyLink: val,
      unsub: false,
      custom: false,
      mailTo: false,
    };

    if (
      val &&
      (copy.text === "unsubscribe" ||
        copy.text === "custom" ||
        copy.text === "email")
    ) {
      copy.text = "here";
    }

    updateLink(copy);
  }

  function makeUnsub(val) {
    let copy = {
      ...link,
      surveyLink: false,
      unsub: val,
      custom: false,
      mailTo: false,
    };

    if (
      val &&
      (copy.text === "here" || copy.text === "custom" || copy.text === "email")
    ) {
      copy.text = "unsubscribe";
    }

    updateLink(copy);
  }

  function makeCustom(val) {
    let copy = {
      ...link,
      surveyLink: false,
      unsub: false,
      custom: val,
      mailTo: false,
    };

    if (
      val &&
      (copy.text === "unsubscribe" ||
        copy.text === "here" ||
        copy.text === "email")
    ) {
      copy.text = "custom";
    }

    if (!("url" in copy)) {
      copy.url = "http://";
    }

    updateLink(copy);
  }

  function makeMailTo(val) {
    let copy = {
      ...link,
      surveyLink: false,
      unsub: false,
      custom: false,
      mailTo: val,
    };

    if (
      val &&
      (copy.text === "unsubscribe" ||
        copy.text === "here" ||
        copy.text === "custom")
    ) {
      copy.text = "email";
    }

    if (!("email" in copy)) {
      copy.email = "";
    }

    updateLink(copy);
  }

  function onKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }
  }

  function onURLKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    } else if (e.key === " ") {
      e.preventDefault();
    }
  }

  return (
    <div className={styles.linkBuilder}>
      <div className={styles.subSection}>
        <div className={styles.linkCheck}>
          <SmallCheckbox
            checked={!surveyUrl && !link.asButton}
            onChange={changeToLink}
          />
          <div
            className={`${styles.checkLabel} ${
              !surveyUrl && link.asButton ? "" : styles.chosenCheckLabel
            } `}
          >
            Link
          </div>
        </div>

        <div className={styles.linkCheck}>
          <SmallCheckbox
            checked={!surveyUrl && link.asButton}
            onChange={changeToButton}
          />
          <div
            className={`${styles.checkLabel} ${
              !surveyUrl && link.asButton ? styles.chosenCheckLabel : ""
            } `}
          >
            Button
          </div>
        </div>

        {!button && !unsub && onSurveyUrl && (
          <div className={styles.linkCheck}>
            <SmallCheckbox checked={surveyUrl} onChange={changeToSurveyUrl} />
            <div
              className={`${styles.checkLabel} ${
                surveyUrl ? styles.chosenCheckLabel : ""
              } `}
            >
              Survey URL
            </div>
          </div>
        )}
      </div>

      {!surveyUrl && (
        <>
          <div className={styles.textInputHolder}>
            <div className={styles.textInputLabel}>Text</div>
            <input
              type="text"
              className={styles.textInput}
              placeholder="here"
              value={link.text}
              onChange={(e) => changeField("text", e.target.value)}
              style={{
                color: link.asButton ? undefined : link.color,
                textDecoration: link.underline ? "underline" : "",
              }}
              onKeyDown={onKeyDown}
            ></input>
          </div>

          <div className={styles.subSection}>
            {!link.asButton && (
              <div className={styles.linkCheck}>
                <div className={styles.colorLabel}>
                  Color <i className="bi bi-paint-bucket"></i>
                </div>
                <TextColorPicker
                  colorRef={colorRef}
                  color={link.color}
                  onChange={(color) => changeField("color", color)}
                />
              </div>
            )}

            {link.asButton && (
              <>
                <div className={styles.linkCheck}>
                  <div className={styles.colorLabel}>
                    Button Color <i className="bi bi-paint-bucket"></i>
                  </div>
                  <TextColorPicker
                    colorRef={colorRef}
                    color={link.color}
                    onChange={(color) => changeField("color", color)}
                  />
                </div>
                <div className={styles.linkCheck}>
                  <div className={styles.colorLabel}>
                    Font Color <i className="bi bi-paint-bucket"></i>
                  </div>
                  <TextColorPicker
                    colorRef={colorRef}
                    color={link.fontColor}
                    onChange={(color) => changeField("fontColor", color)}
                  />
                </div>
              </>
            )}

            {!link.asButton && (
              <div className={styles.linkCheck}>
                <SmallCheckbox
                  checked={link.underline}
                  onChange={(val) => changeField("underline", val)}
                />
                <div
                  className={`${styles.checkLabel} ${
                    link.underline ? styles.chosenCheckLabel : ""
                  } `}
                  style={{ textDecoration: "underline", fontSize: ".75rem" }}
                >
                  U
                </div>
              </div>
            )}
          </div>

          <div className={styles.subSection}>
            <div className={styles.linkCheck}>
              <SmallCheckbox
                checked={link.surveyLink}
                onChange={makeSurveyLink}
              />
              <div
                className={`${styles.checkLabel} ${
                  link.surveyLink ? styles.chosenCheckLabel : ""
                } `}
              >
                Link to Survey
              </div>
            </div>

            <div className={styles.linkCheck}>
              <SmallCheckbox checked={link.unsub} onChange={makeUnsub} />
              <div
                className={`${styles.checkLabel} ${
                  link.unsub ? styles.chosenCheckLabel : ""
                } `}
              >
                Link to Unsubscribe
              </div>
            </div>

            <div className={styles.linkCheck}>
              <SmallCheckbox checked={link.mailTo} onChange={makeMailTo} />
              <div
                className={`${styles.checkLabel} ${
                  link.mailTo ? styles.chosenCheckLabel : ""
                } `}
              >
                Link to email
              </div>
            </div>

            <div className={styles.linkCheck}>
              <SmallCheckbox checked={link.custom} onChange={makeCustom} />
              <div
                className={`${styles.checkLabel} ${
                  link.custom ? styles.chosenCheckLabel : ""
                } `}
              >
                Custom URL
              </div>
            </div>
          </div>

          {link.custom && (
            <div className={styles.textInputHolder}>
              <div className={styles.textInputLabel}>URL</div>
              <input
                type="text"
                className={`${styles.textInput} ${styles.urlInput}`}
                placeholder="http://"
                value={link.url}
                onChange={(e) => changeField("url", e.target.value)}
                onKeyDown={onURLKeyDown}
              ></input>
            </div>
          )}

          {link.mailTo && (
            <div className={styles.textInputHolder}>
              <div className={styles.textInputLabel}>Email To:</div>
              <input
                type="text"
                className={`${styles.textInput} ${styles.urlInput}`}
                placeholder="address"
                value={link.email}
                onChange={(e) => changeField("email", e.target.value)}
                onKeyDown={onURLKeyDown}
              ></input>
            </div>
          )}
        </>
      )}
    </div>
  );
}
