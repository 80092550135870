//External
import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

//Internal
import styles from "./UserProfile.module.scss";
import ImageEditor from "components/Image Editor/ImageEditor";

/**
 * A component that displays the users avatar, name and email, along with a 'My Account' nav button and a 'Sign Out' button in a popout
 * @param {function} setShowingUserProf function to handle closing the popout
 * @param {Object} currUser has the current user's information used to display name and email
 * @param {Object/string} avatar source for the user's avatar image
 * @param {function} logout function to call for sign out button
 * @returns A user profile popout
 */
export const UserProfile = (props) => {
  const { setShowingUserProf, currUser, image, logout, onGoToAccount } = props;

  const ref = useRef();

  const handleClickOutside = (event) => {
    // let sideMenuButton = document.getElementById("userInfoBtn");
    if (
      ref.current &&
      !ref.current?.contains(event.target)
      // && !(
      //   event.target == sideMenuButton ||
      //   Array.from(sideMenuButton.children).includes(event.target)
      // )
    ) {
      setShowingUserProf(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClick = () => {
    //debugger;
    setShowingUserProf(false);
    onGoToAccount();
  };

  return (
    <div
      className={styles.container}
      ref={ref}
      style={{
        bottom: window.innerWidth < 560 ? "0" : "",
        top: window.innerWidth < 560 ? "-15.2em" : "",
        right: window.innerWidth < 560 ? "10px" : "",
      }}
    >
      <div className={styles.userProfile}>

          <ImageEditor
            src={image?.imageURL}
            isUser
            canEdit={false}
            position={
              image?.position ? JSON.parse(image?.position) : { x: 0.5, y: 0.5 }
            }
            image={image}
            height={50}
            width={50}
          ></ImageEditor>

        <div className={styles.nameContainer}>
          <div className={styles.name}>
            {currUser.data?.me?.firstName} {currUser.data?.me?.lastName}
          </div>
          <div className={styles.email}>{currUser.data?.me?.email}</div>
        </div>
      </div>
      {/* <div className={styles.divider}></div> */}
      <div onClick={handleClick}>
        <div className={styles.myAccountDiv}>My Account</div>
      </div>
      {/* <div className={styles.divider}></div> */}
      <div onMouseDown={logout} className={styles.myAccountDiv}>
        Sign Out
      </div>
    </div>
  );
};

export default UserProfile;

// const UserProfile = React.forwardRef((props, ref) => {
//   const {setShowingUserProf, currUser, avatar, logout} = props;

//   const handleClickOutside = (event) => {
//     if (ref.current && !ref.current?.contains(event.target)) {
//       setShowingUserProf(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("click", handleClickOutside, true);
//     return () => {
//       document.removeEventListener("click", handleClickOutside, true);
//     };
//   }, []);

//   function clearSideMenu() {
//     localStorage.removeItem("activeprojecttab");
//     localStorage.removeItem("activecontactstab");
//     localStorage.removeItem("activeorgtab");
//     localStorage.removeItem("activepage")
//     localStorage.removeItem("activeaccounttab");
//   }

//   return (
//     <div className={styles.container} ref={ref}>
//       <div className={styles.userProfile}>
//         <div className={styles.avatarHolder}>
//           <img src={avatar} alt="user profile image" />
//         </div>

//         <div>
//           <span className={styles.name}>
//             {currUser.data?.me?.firstName} {currUser.data?.me?.lastName}
//           </span>
//           <br />
//           <span className={styles.email}>{currUser.data?.me?.email}</span>
//         </div>
//       </div>
//       {/* <div className={styles.divider}></div> */}
//       <NavLink to={"/account/details"} onClick={clearSideMenu()}>
//         <div className={styles.myAccountDiv}>MyAccount</div>
//       </NavLink>
//       {/* <div className={styles.divider}></div> */}
//       <div onMouseDown={logout} className={styles.myAccountDiv}>
//         Sign Out
//       </div>
//     </div>
//   );
// });

// UserProfile.displayName = 'UserProfile';

// export default UserProfile;
