import React, { useEffect, useState } from "react";
import styles from "./Spreadsheet.module.scss";
import { ReactGrid } from "components/inputs/input_fields/reactgrid";

function Spreadsheet({
  dataArray,
  headers,
  sort,
  handleSort,
  onSelectionChanged,
}) {
  const gray110 = "#616565";
  const gray100 = "#d8d9d9";
  function getRows(data, initcolumns) {
    let columns = [];
    if (initcolumns) {
      columns = initcolumns;
    } else {
      columns = [
        {
          type: "header",
          text: "",
          accessor: "num",
          columnId: "num",
          width: 40,
          resizable: true,
          textSize: 3,
        },
      ];

      //   debugger;
      for (let header of headers) {
        let textSize = header.name.length;
        if (textSize < 10) {
          textSize = 12;
        }
        if (textSize > 18) {
          textSize = 18;
        }
        columns.push({
          columnId: header.accessor,
          width: textSize * 8,
          resizable: true,
          textSize: textSize,
          reorderable: true,
          type: "header",
          text: header.name,
          accessor: header.accessor,
        });
      }
    }

    for (let j = 0; j < 15; j++) {
      if (!initcolumns) {
        columns.push({
          columnId: `column:${j}`,
          width: 100,
          resizable: true,
          textSize: 0,
          type: "header",
          text: ``,
          accessor: ``,
        });
      }
    }

    let rows = [
      {
        rowId: "header",
        cells: [...columns],
      },
    ];

    let blankrow = [];
    // for (let j = 0; j < 15; j++) {
    //   blankrow.push({ type: "text", text: "" });
    // }

    let i = 1;
    for (let row of data) {
      let cell_row = [
        // { type: "text", text: `${i}` }
      ];
      let headerIndex = 0;
      for (let header of columns) {
        // debugger;
        let text = `${row[header.accessor]}`;
        if (headerIndex === 0) {
          text = `${i}`;
        } else if (!row[header.accessor]) {
          text = "";
        }
        // let textSize = columns[headerIndex].textSize;
        // let newTextSize = text.length;
        // debugger;
        if (text.length > columns[headerIndex].textSize) {
          let textSize = text.length;
          if (textSize < 10) {
            textSize = 12;
          }
          if (textSize > 18) {
            textSize = 18;
          }
          columns[headerIndex].width = textSize * 9;
          columns[headerIndex].textSize = text.length;
        }

        if (header.cell_style) {
          cell_row.push({
            type: "text",
            text: `${header.cell_style(row[header.accessor])}`,
          });
        } else {
          cell_row.push({ type: "text", text: text });
        }
        if (i === 1) {
          blankrow.push({ type: "text", text: "" });
        }
        headerIndex++;
      }
      // for (let j = 0; j < 15; j++) {
      //   cell_row.push({
      //     type: "text",
      //     text: "",
      //   });
      // }
      rows.push({ rowId: i, reorderable: true, cells: cell_row });
      i++;
    }

    for (let j = 0; j < headers.length + 16; j++) {
      rows.push({ rowId: i, reorderable: true, cells: blankrow });
      i++;
    }
    // debugger;
    return { rows, columns };
  }

  // a helper function used to reorder arbitrary arrays
  const reorderArray = (arr, idxs, to) => {
    const movedElements = arr.filter((_, idx) => idxs.includes(idx));
    const targetIdx =
      Math.min(...idxs) < to
        ? (to += 1)
        : (to -= idxs.filter((idx) => idx < to).length);
    const leftSide = arr.filter(
      (_, idx) => idx < targetIdx && !idxs.includes(idx)
    );
    const rightSide = arr.filter(
      (_, idx) => idx >= targetIdx && !idxs.includes(idx)
    );

    return [...leftSide, ...movedElements, ...rightSide];
  };

  const handleColumnsReorder = (targetColumnId, columnIds) => {
    const to = columns.findIndex(
      (column) => column.columnId === targetColumnId
    );

    const columnIdxs = columnIds.map((columnId) =>
      columns.findIndex((c) => c.columnId === columnId)
    );

    let array = reorderArray(columns, columnIdxs, to);

    setColumns(array);
    let data = getRows(dataArray, array);
    setRows(data.rows);
  };

  // const handleRowsReorder = (targetRowId, rowIds) => {
  //   setRows((prevPeople) => {
  //     const to = dataArray.findIndex((person) => person.id === targetRowId);
  //     const rowsIds = rowIds.map((id) =>
  //       dataArray.findIndex((person) => person.id === id)
  //     );
  //     return reorderArray(prevPeople, rowsIds, to);
  //   });
  // };

  // const handleCanReorderRows = (targetRowId, rowIds) => {
  //   return targetRowId !== "header";
  // };

  useEffect(() => {
    let data = getRows(dataArray);
    setColumns(data.columns);
    setRows(data.rows);
    sortCarets(data.columns);
  }, [dataArray]);

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  function resizeCols(prevColumns, ci, width) {
    const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
    const resizedColumn = prevColumns[columnIndex];
    const updatedColumn = { ...resizedColumn, width };
    prevColumns[columnIndex] = updatedColumn;
    return [...prevColumns];
  }

  const handleColumnResize = (ci, width) => {
    let newCols = resizeCols(columns, ci, width);
    sortCarets(newCols);
    setColumns(newCols);
  };

  function sortCarets(columns) {
    let firstEl = document.getElementById("num-0");
    firstEl.style.width = `40px`;

    for (let i = 0; i < columns.length - 1; i++) {
      let col = columns[i + 1];
      let columnEl = document.getElementById(`${col.accessor}-${i + 1}`);
      if (columnEl) {
        if (col.width <= 40) {
          columnEl.style.width = `40px`;
        } else {
          columnEl.style.width = `${col.width}px`;
        }
      }
    }
  }

  function getSortCaret(col) {
    let index = sort.findIndex((s) => s.col === col.accessor);
    const has = index >= 0;
    let color = has ? gray110 : gray100;
    let icon = has
      ? sort[index].sort === "down"
        ? "bi-caret-up-fill"
        : "bi-caret-down-fill"
      : "bi-dash";

    return (
      <i
        className={`bi ${icon} ${styles.sortTriangle} `}
        style={{ color: color }}
      >
        {has && sort.length > 1 ? index + 1 : ""}
      </i>
    );
  }

  function handleFocusLocation(focus) {
    if (onSelectionChanged) {
      let index = rows.findIndex((r) => r.rowId === focus.rowId);
      let row = rows[index];
      let colIndex = columns.findIndex((c) => c.columnId === focus.columnId);
      let value = row.cells[colIndex].text;
      onSelectionChanged(value);
    }
  }

  return (
    <div className={styles.reactGrid}>
      <div className={styles.buttons} id={"sort-buttons"}>
        <div className={styles.sortButton} id={"num-0"}>
          {" "}
          {/* <i className="bi-caret-down-fill"></i> */}
        </div>
        {headers.map((head, i) => (
          <div
            className={styles.sortButton}
            id={`${head.accessor}-${i + 1}`}
            onClick={() => handleSort(head.accessor)}
          >
            {" "}
            {getSortCaret(head)}
          </div>
        ))}
      </div>

      <ReactGrid
        rows={rows}
        columns={columns}
        onColumnResized={handleColumnResize}
        enableRangeSelection
        enableColumnSelection
        enableRowSelection
        stickyTopRows={1}
        stickyLeftColumns={1}
        onColumnsReordered={handleColumnsReorder}
        // onSelectionChanged={onSelectionChanged}
        // onSelectionChanging={onSelectionChanged}
        // onContextMenu={onSelectionChanged}
        onFocusLocationChanged={handleFocusLocation}
        // onCellsChanged={onSelectionChanged}
        // onFocusLocationChanging={onSelectionChanged}
        // onRowsReordered={handleRowsReorder}
        // canReorderRows={handleCanReorderRows}
      />
    </div>
  );
}

export default Spreadsheet;
