import { useFetchSurveyTags } from "api/resources/organization/surveytags";
import {
  useGetFilterableAnswers,
  useGetToLastAnswers,
} from "api/resources/projects/answers";
import { useFetchProjectsByIdsGql } from "api/resources/projects/projects";
import { useState, useEffect, useLayoutEffect } from "react";
import Table from "./Tables/Table";
import { Loading } from "components/Loading/Loading";
import { useUpdateVisualization } from "api/resources/projects/visualizations";
import { Ranking } from "./QuestionChart";
import { combinedProjIds, combinedQs } from "./Visualization";
import ToLastTable from "./Tables/ToLastTable";
import PivotTable from "./Tables/PivotTable/PivotTable";
import { BackendPivotTable } from "./Tables/PivotTable/BackendPivotTable";
import { AnswerTable } from "./Tables/AnswerTable";

export const defaultTableSettings = {
  hasTitle: true,
  hasSubtitle: true,
  artificialTitle: true,
  titleSize: null,
  titleAlignment: "center",
  titleBackgroundColor: "#FFFFFF",
  titleColor: "",
  titleBorderRadius: 0,
  byPercent: false, // ?
  tableFields: {
    firstName: { name: "first name", show: true },
    lastName: { name: "last name", show: true },
    email: { name: "email", show: false },
  },
  tableOrder: ["firstName", "lastName", "email"],
  tablePivotFields: {},
  tablePivotOrder: [],
  split: "",
  undefinedLabel: "Undefined",
  zoomOut: 0,
  textboxes: [],
  showUndefined: true,
  showN: false,
  NLabel: "N",
  showNonParticipating: false,
  hideNonChosen: false,
  dynamic: false,
  userConstraints: [],
  showFilterSubtitle: true,
  sigFigs: 0,
  hideBorder: false, //?
  containerShadow: true,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  syncPadding: true,
  paddingTopTitle: 0,
  paddingBottomTitle: 0,
  paddingLeftTitle: 0,
  paddingRightTitle: 0,
  syncTitlePadding: true,
  tableColor: "#7fcfd3",
  separateBySurvey: false,
};

export default function TableViz({
  viz,
  inEdit,
  newChart,
  custom_fields,
  height,
  role,
  seeData,
  closeSeeData,
  setOutsideData,
  setUpOutsideDataCounter,
  setVizCopy,
  filters,
  filterSubtitle,
  reDraw,
  setShowDrill,
  onQuickSave,
}) {
  const projIds = combinedProjIds(viz);
  const updateViz = useUpdateVisualization();

  useLayoutEffect(() => {
    if (viz.dynamicQs) {
      // Meant for a table with Dynamic Qs AND time frame has since changed;

      // check for any needing added
      const vizQs = combinedQs(viz);

      for (let q of vizQs) {
        //if isn't included
        if (!isInTable(viz, q)) {
          addQToTable(viz, q);
        }
      }

      // let sincePassed = getSincePassedQIds(viz);
      // for (let q of sincePassed) {
      //   removeQFromTable(viz, q);
      // }
    }
  }, [viz]);

  const [show, setShow] = useState(true);

  useEffect(() => {
    if (reDraw) {
      setShow(false);
      setTimeout(() => setShow(true), 325);
    }
  }, [reDraw]);

  function saveTableSort(sort) {
    let copy = { ...viz };
    copy.designSettings.tableSort = sort;
    updateViz.mutate(
      {
        id: viz.id,
        data: {
          designSettings: JSON.stringify(copy.designSettings),
        },
      },
      {
        onSuccess: (data) => {
          if (onQuickSave) {
            onQuickSave();
          }
        },
      }
    );
    setVizCopy(copy);
  }

  const toLast = viz.designSettings.toLast && projIds.length === 1;

  const pivot = viz.pivotString || viz.designSettings.pivotOptions;

  return (
    <>
      {!toLast && (
        <>
          {pivot ? (
            <>
              {/* <PivotTable
                viz={viz}
                inEdit={inEdit}
                custom_fields={custom_fields}
                height={height}
                canSeeContactInfo={role.canSeeContactInfo}
                seeData={seeData}
                closeSeeData={closeSeeData}
                setOutsideData={setOutsideData}
                setUpOutsideDataCounter={setUpOutsideDataCounter}
                filters={filters}
                filterSubtitle={filterSubtitle}
                saveTableSort={saveTableSort}
                show={show}
                setShowDrill={setShowDrill}
              /> */}
              <BackendPivotTable
                viz={viz}
                saveTableSort={saveTableSort}
                filters={filters}
                inEdit={inEdit}
                height={height}
                canSeeContactInfo={role.canSeeContactInfo}
                seeData={seeData}
                closeSeeData={closeSeeData}
                setOutsideData={setOutsideData}
                setUpOutsideDataCounter={setUpOutsideDataCounter}
                filterSubtitle={filterSubtitle}
                show={show}
                setShowDrill={setShowDrill}
                custom_fields={custom_fields}
              />
            </>
          ) : (
            <AnswerTable
              viz={viz}
              inEdit={inEdit}
              newChart={newChart}
              custom_fields={custom_fields}
              height={height}
              role={role}
              seeData={seeData}
              closeSeeData={closeSeeData}
              setOutsideData={setOutsideData}
              setUpOutsideDataCounter={setUpOutsideDataCounter}
              filters={filters}
              filterSubtitle={filterSubtitle}
              saveTableSort={saveTableSort}
              show={show}
              setShowDrill={setShowDrill}
            />
          )}
        </>
      )}
      {toLast && show && (
        <ToLast
          viz={viz}
          inEdit={inEdit}
          newChart={newChart}
          custom_fields={custom_fields}
          height={height}
          role={role}
          seeData={seeData}
          closeSeeData={closeSeeData}
          setOutsideData={setOutsideData}
          setUpOutsideDataCounter={setUpOutsideDataCounter}
          filters={filters}
          filterSubtitle={filterSubtitle}
          saveTableSort={saveTableSort}
          setShowDrill={setShowDrill}
        />
      )}
    </>
  );
}

function ToLast({
  viz,
  inEdit,
  newChart,
  custom_fields,
  height,
  role,
  seeData,
  closeSeeData,
  setOutsideData,
  setUpOutsideDataCounter,
  filters,
  filterSubtitle,
  saveTableSort,
  setShowDrill,
}) {
  const vizQs = combinedQs(viz);

  const columns = { names: [] };

  let fields = viz.designSettings.toLastFields;
  for (let key in fields) {
    let col = fields[key];
    if (
      col.show &&
      !col.project &&
      key !== "firstName" &&
      key !== "lastName" &&
      key !== "email"
    ) {
      columns.names.push(key);
    }
  }

  const fetchToLastAnswers = useGetToLastAnswers(
    vizQs.map((q) => q.id),
    filters,
    columns,
    viz.designSettings?.dynamic
      ? viz.designSettings.userConstraints
      : undefined,
    null
  );

  const getProjects = useFetchProjectsByIdsGql(
    {
      projectIds: combinedProjIds(viz),
    },
    viz.id
  );

  function getSeeDataAnswers() {
    let map = {};
    for (let q of vizQs) {
      map[q.id] = true;
    }
    return fetchToLastAnswers.data?.toLast.answers.filter(
      (a) => map[a.questionId]
    );
  }

  return (
    <>
      {getProjects.isLoading && <Loading height={height}></Loading>}
      {getProjects.isSuccess && (
        <>
          {!seeData && (
            <ToLastTable
              data={fetchToLastAnswers.data?.toLast}
              viz={viz}
              canSeeContactInfo={role.canSeeContactInfo}
              inEdit={inEdit}
              newChart={newChart}
              filterSubtitle={filterSubtitle}
              onSaveSort={saveTableSort}
              height={height}
              setOutsideData={setOutsideData}
              setUpOutsideDataCounter={setUpOutsideDataCounter}
              custom_fields={custom_fields}
              refetch={() => fetchToLastAnswers.refetch()}
              setShowDrill={setShowDrill}
            />
          )}

          {seeData && (
            <Table
              answers={getSeeDataAnswers()}
              filters={filters}
              onClose={closeSeeData}
              viz={viz}
              projects={getProjects.data?.survey}
              custom_fields={custom_fields}
              togglespreadsheet
            />
          )}
        </>
      )}
    </>
  );
}

function isInTable(viz, question) {
  if (viz.designSettings?.separateBySurvey) {
    let questionOnes = viz.designSettings.tableOrder.filter(
      (key) => viz.designSettings.tableFields[key].contains
    );
    for (let key of questionOnes) {
      if (viz.designSettings.tableFields[key].contains.includes(question.id)) {
        return true;
      }
    }
  } else {
    if (viz.designSettings.tableOrder.includes(question.id)) {
      return true;
    }
  }

  if (viz.designSettings.tablePivotFields[question.id]) {
    return true;
  }
  return false;
}

function getSincePassedQIds(viz) {
  // need to gather all included q Ids
  let included = [];
  if (viz.designSettings?.separateBySurvey) {
    for (let key of viz.designSettings.tableOrder) {
      let contained = viz.designSettings.tableFields[key].contains;
      if (contained) {
        included = [...included, ...contained];
      }
    }
  } else {
    // It's old or it's by id (not separating)
    for (let key of viz.designSettings.tableOrder) {
      if (viz.designSettings.tableFields[key].project) {
        included.push(key);
      }
    }
  }

  // sort through which ones aren't included.
  const vizQs = combinedQs(viz);
  let sincePassed = vizQs.filter((q) => !included.includes(q.id));
  return sincePassed;
}

export function addQToTable(viz, question) {
  if (isInTable(viz, question)) {
    return;
  }

  if (viz.designSettings?.separateBySurvey) {
    if (
      combinedProjIds(viz).length > 1 &&
      !viz.designSettings.tableFields.survey
    ) {
      viz.designSettings.tableFields.survey = {
        show: true,
        name: "Survey",
      };
      let startInd = viz.designSettings.tableOrder.findIndex(
        (a) => viz.designSettings.tableFields[a].contains
      );
      viz.designSettings.tableOrder.splice(startInd, 0, "survey");
    }

    if (!(question.questionText in viz.designSettings.tableFields)) {
      viz.designSettings.tableFields[question.questionText] = {
        show: true,
        name: question.questionText,
        contains: [question.id],
      };
      viz.designSettings.tableOrder.push(question.questionText);
    } else {
      viz.designSettings.tableFields[question.questionText]?.contains.push(
        question.id
      );
    }

    if (question.textQuestion && question.textQuestion.bucket) {
      for (let bucket of question.textQuestion.bucket) {
        if (!(bucket.id in viz.designSettings.tableFields)) {
          viz.designSettings.tableFields[bucket.id] = {
            name: bucket.name,
            show: true,
            isBucket: true,
          };
          viz.designSettings.tableOrder.push(bucket.id);
        }
      }
    }
  } else {
    // It's by id
    viz.designSettings.tableFields[question.id] = {
      show: true,
      name: question.questionText,
      project: question.projectId,
    };
    viz.designSettings.tableOrder.push(question.id);

    if (question.textQuestion && question.textQuestion.bucket) {
      for (let bucket of question.textQuestion.bucket) {
        if (
          !(bucket.id + "_" + question.id in viz.designSettings.tableFields)
        ) {
          viz.designSettings.tableFields[bucket.id + "_" + question.id] = {
            name: bucket.name,
            show: true,
            isBucket: true,
            project: question.projectId,
          };
          viz.designSettings.tableOrder.push(bucket.id + "_" + question.id);
        }
      }
    }
  }

  if (viz.pivotString) {
    if (hasMatrix(viz)) {
      viz.pivotString = "";
      viz.designSettings.split = "";
      viz.designSettings.tablePivotFields = {};
      viz.designSettings.tablePivotOrder = [];
    } else if (allScale(viz) && viz.designSettings.split === "question") {
      viz.designSettings.tablePivotFields[question.id] = {
        show: true,
        name: question.questionText,
        project: question.projectId,
      };
      viz.designSettings.tablePivotOrder.push(question.id);
    } else if (!allSameType(viz)) {
      createPivotColumnsForACombinationOfQs(viz);
    }
  }

  if (viz.designSettings.pivotOptions) {
    createPivotColumnsForACombinationOfQs(viz);
  }

  if (viz.designSettings.toLast) {
    let inSameSurvey = true;
    for (let q of combinedQs(viz)) {
      if (q.projectId !== question.projectId) {
        inSameSurvey = false;
      }
    }

    if (inSameSurvey) {
      viz.designSettings.toLastFields[question.id] = {
        name: question.questionText,
        show: true,
        project: question.projectId,
      };
      viz.designSettings.toLastFields["last" + question.id] = {
        name: "Last - " + question.questionText,
        show: true,
      };

      viz.designSettings.toLastOrder.push(question.id);
      viz.designSettings.toLastOrder.push("last" + question.id);

      if (question.textQuestion && question.textQuestion.bucket) {
        for (let bucket of question.textQuestion.bucket) {
          if (!(bucket.id in viz.designSettings.toLastFields)) {
            viz.designSettings.toLastFields[bucket.id] = {
              name: bucket.name,
              show: true,
              isBucket: true,
            };
            viz.designSettings.toLastFields["last" + bucket.id] = {
              name: "Last - " + bucket.name,
              show: true,
              isBucket: true,
            };
            viz.designSettings.toLastOrder.push(bucket.id);
            viz.designSettings.toLastOrder.push("last" + bucket.id);
          }
        }
      }
    } else {
      viz.designSettings.toLast = false;
      delete viz.designSettings.toLastOrder;
      delete viz.designSettings.toLastFields;
      delete viz.designSettings.toLastFilter;
    }
  }
}

export function removeQFromTable(viz, removedQ) {
  let all = combinedQs(viz);
  if (all.some((q) => q.id === removedQ.id)) {
    // still exits by some other input
    return;
  }

  if (viz.designSettings?.separateBySurvey) {
    if (
      combinedProjIds(viz).length < 2 &&
      viz.designSettings.tableFields.survey
    ) {
      let ind = viz.designSettings.tableOrder.indexOf("survey");
      if (ind > -1) {
        viz.designSettings.tableOrder.splice(ind, 1);
      }
      delete viz.designSettings.tableFields.survey;
    }

    // Remove Q from whatever column contained in
    let containedInKey = "";
    for (let key of viz.designSettings.tableOrder) {
      if (
        "contains" in viz.designSettings.tableFields[key] &&
        viz.designSettings.tableFields[key].contains.includes(removedQ.id)
      ) {
        containedInKey = key;
        break;
      }
    }

    let ind = viz.designSettings.tableFields[containedInKey].contains.indexOf(
      removedQ.id
    );
    viz.designSettings.tableFields[containedInKey].contains.splice(ind, 1);

    if (!viz.designSettings.tableFields[containedInKey].contains.length) {
      ind = viz.designSettings.tableOrder.indexOf(containedInKey);
      viz.designSettings.tableOrder.splice(ind, 1);
      delete viz.designSettings.tableFields[containedInKey];
    }

    // Same for buckets
    if (removedQ.textQuestion && removedQ.textQuestion.bucket) {
      for (let bucket of removedQ.textQuestion.bucket) {
        if (
          !all.some(
            (q) =>
              q.textQuestion &&
              q.textQuestion.bucket &&
              q.textQuestion.bucket.some((b) => b.id === bucket.id)
          )
        ) {
          // No more Q's with this bucket
          delete viz.designSettings.tableFields[bucket.id];
          ind = viz.designSettings.tableOrder.indexOf(bucket.id);
          if (ind > -1) viz.designSettings.tableOrder.splice(ind, 1);
        }
      }
    }
  } else {
    // It's old or it's by each id (not separating);
    delete viz.designSettings.tableFields[removedQ.id];
    let ind = viz.designSettings.tableOrder.indexOf(removedQ.id);
    if (ind > -1) {
      viz.designSettings.tableOrder.splice(ind, 1);
    }

    if (removedQ.textQuestion && removedQ.textQuestion.bucket) {
      for (let bucket of removedQ.textQuestion.bucket) {
        delete viz.designSettings.tableFields[bucket.id + "_" + removedQ.id];
        ind = viz.designSettings.tableOrder.indexOf(
          bucket.id + "_" + removedQ.id
        );
        if (ind > -1) viz.designSettings.tableOrder.splice(ind, 1);
      }
    }
  }

  if (viz.pivotString) {
    if (allScale(viz) && viz.designSettings.split === "question") {
      delete viz.designSettings.tablePivotFields[removedQ.id];
      let ind = viz.designSettings.tablePivotOrder.indexOf(removedQ.id);
      viz.designSettings.tablePivotOrder.splice(ind, 1);
    } else if (!allSameType(viz)) {
      createPivotColumnsForACombinationOfQs(viz);
    }
  }

  if (viz.designSettings.pivotOptions) {
    createPivotColumnsForACombinationOfQs(viz);
  }

  if (viz.designSettings.toLast) {
    delete viz.designSettings.toLastFields[removedQ.id];
    delete viz.designSettings.toLastFields["last" + removedQ.id];
    let ind = viz.designSettings.toLastOrder.indexOf(removedQ.id);
    viz.designSettings.toLastOrder.splice(ind, 1);
    ind = viz.designSettings.toLastOrder.indexOf("last" + removedQ.id);
    viz.designSettings.toLastOrder.splice(ind, 1);

    if (removedQ.textQuestion && removedQ.textQuestion.bucket) {
      for (let bucket of removedQ.textQuestion.bucket) {
        if (
          !all.some(
            (q) =>
              q.textQuestion &&
              q.textQuestion.bucket &&
              q.textQuestion.bucket.some((b) => b.id === bucket.id)
          )
        ) {
          delete viz.designSettings.toLastFields[bucket.id];
          delete viz.designSettings.toLastFields["last" + bucket.id];
          let ind = viz.designSettings.toLastOrder.indexOf(bucket.id);
          if (ind > -1) viz.designSettings.toLastOrder.splice(ind, 1);
          ind = viz.designSettings.toLastOrder.indexOf("last" + bucket.id);
          if (ind > -1) viz.designSettings.toLastOrder.splice(ind, 1);
        }
      }
    }

    if (!all.length) {
      viz.designSettings.toLast = false;
      delete viz.designSettings.toLastFields;
      delete viz.designSettings.toLastOrder;
      delete viz.designSettings.toLastFilter;
    }
  }
}

function allScale(viz) {
  for (let q of combinedQs(viz)) {
    if (q.type !== "NumberScale") {
      return false;
    }
  }
  return true;
}

function hasMatrix(viz) {
  for (let q of combinedQs(viz)) {
    if (q.type === "Matrix") {
      return true;
    }
  }
  return false;
}

function allSameType(viz) {
  let allQs = combinedQs(viz);
  if (!allQs.length) {
    return true;
  }
  let type = allQs[0].type;
  for (let q of allQs) {
    if (q.type !== type) {
      return false;
    }
  }
  return true;
}

export function createPivotColumnsForACombinationOfQs(viz) {
  const allQs = combinedQs(viz);
  // copied from TableSettings.jsx in changePivot

  let settings = viz.designSettings;
  settings.tablePivotFields = {
    participationCount: { name: "Participation Count", show: true },
  };
  if (viz.pivotString) {
    settings.tablePivotFields[viz.pivotString] = {
      name: viz.pivotString,
      show: true,
    };
    settings.tablePivotOrder = [viz.pivotString];
  }
  if (settings.pivotOptions) {
    settings.tablePivotFields.option = {
      name: "options",
      show: true,
    };
    settings.tablePivotOrder = ["option"];
  }

  let scale = false;
  let nps = false;
  let choice = false;
  for (let q of allQs) {
    if (q.scaleQuestion) {
      scale = true;
      if (q.scaleQuestion.min === 0 && q.scaleQuestion.max === 10) {
        nps = true;
      }
    } else if (q.choiceQuestion) {
      choice = true;
    }
  }

  if (scale) {
    settings.tablePivotFields.avgScore = {
      name: "Avg Score",
      show: true,
    };
    settings.tablePivotOrder.push("avgScore");

    if (nps) {
      settings.tablePivotFields.nps = { name: "NPS", show: true };
      settings.tablePivotFields.promoters = {
        name: "Promoters",
        show: true,
      };
      settings.tablePivotFields.passives = {
        name: "Passives",
        show: true,
      };
      settings.tablePivotFields.detractors = {
        name: "Detractors",
        show: true,
      };

      settings.tablePivotOrder = [
        ...settings.tablePivotOrder,
        ...["nps", "promoters", "passives", "detractors"],
      ];
    }
  }

  if (choice) {
    let choices = [];
    let otherLabel = "";
    for (let q of allQs) {
      if (q.choiceQuestion) {
        for (let choice of q.choiceQuestion.choices) {
          let lowered = choice.toLowerCase();
          if (!choices.includes(lowered)) {
            choices.push(lowered);
          }
        }
        if (q.choiceQuestion.hasOtherOption && !otherLabel) {
          otherLabel = q.choiceQuestion.otherOptionLabel;
        }
      }
    }

    for (let choice of choices) {
      settings.tablePivotFields[choice] = { name: choice, show: true };
      settings.tablePivotOrder.push(choice);
    }

    if (otherLabel) {
      settings.tablePivotFields.otherOption = { name: otherLabel, show: true };
      settings.tablePivotOrder.push("otherOption");
    }
  }

  settings.tablePivotOrder.push("participationCount");

  if (settings.totalRows) {
    settings.tablePivotFields.totalRows = {
      name: "Total",
      show: true,
    };
    settings.tablePivotOrder.push("totalRows");
  }
}
