import {
  useCreateColorPalette,
  useFetchColorPaletteById,
  useFetchColorPalettes,
  useFetchReactionPalette,
  useUpdateColorPalette,
} from "api/resources/organization/colorpalettes";
import styles from "./PalettePicker.module.scss";
import { useEffect, useRef, useState } from "react";
import PaletteEditor from "../../../../../../components/ColorPalette/PaletteEditor";

export default function PalettePicker({ viz, onChange }) {
  const [show, setShow] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [edit, setEdit] = useState(false);

  const currPalette = useFetchColorPaletteById(
    viz.designSettings?.paletteId ? viz.designSettings.paletteId : ""
  );

  const createPalette = useCreateColorPalette();
  const savePalette = useUpdateColorPalette();

  const usingPalette =
    !viz.designSettings.colors && !viz.designSettings?.gradient;

  function onNevermind() {
    setShow(true);
    setCreateNew(false);
  }

  function setOnChosen(palette) {
    setShow(false);
    onChange(palette);
  }

  function onCreatePalette(palette) {
    createPalette.mutate(
      {
        data: palette,
      },
      {
        onSuccess: (data) => {
          onChange(data.createColorPallet);
        },
      }
    );
    setCreateNew(false);
  }

  function onSavePalette(palette) {
    savePalette.mutate(
      { data: palette, id: currPalette.data.palette.id },
      {
        onSuccess: (data) => {
          console.log("palette saved");
          currPalette.refetch();
        },
      }
    );
    setEdit(false);
  }

  return (
    <>
      <div className={styles.palettePicker}>
        {currPalette.isSuccess && (
          <div
            className={`${styles.currentPalette} ${
              show ? styles.activePalette : ""
            }`}
            onClick={() => setShow(!show)}
          >
            {usingPalette && currPalette.data.palette && (
              <div className={styles.nameNColors}>
                <div className={styles.paletteName}>
                  {currPalette.data.palette?.name}
                </div>

                <div className={styles.currPaletteColors}>
                  {JSON.parse(currPalette.data.palette.colors).map((color) => (
                    <div
                      className={styles.colorSwatch}
                      style={{ backgroundColor: color }}
                    ></div>
                  ))}
                </div>
              </div>
            )}
            {!usingPalette && (
              <div className={styles.paletteName}>{`Using Custom ${
                viz.designSettings.gradient ? "Gradient" : "Colors"
              } `}</div>
            )}

            <div className={styles.select}>
              <i className="bi bi-chevron-down"></i>
            </div>
          </div>
        )}

        {show && (
          <SelectPalette
            usingPalette={usingPalette}
            currPalette={currPalette.data.palette}
            close={() => setShow(false)}
            onSelect={setOnChosen}
            createNew={() => {
              setShow(false);
              setCreateNew(true);
            }}
          />
        )}

        {createNew && (
          <PaletteEditor onCancel={onNevermind} onCreate={onCreatePalette} />
        )}
        {edit && (
          <PaletteEditor
            onCancel={() => setEdit(false)}
            onSave={onSavePalette}
            initPalette={currPalette.data.palette}
          />
        )}
        {usingPalette && !show && (
          <div className={styles.editPalette} onClick={() => setEdit(true)}>
            Edit Palette
          </div>
        )}
      </div>
    </>
  );
}

function SelectPalette({
  usingPalette,
  currPalette,
  close,
  onSelect,
  createNew,
}) {
  const colorPalettes = useFetchColorPalettes();
  const reaction = useFetchReactionPalette();

  function onClickOut(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      close();
      document.removeEventListener("click", onClickOut, false);
    }
  }

  useEffect(() => {
    setTimeout(
      () => document.addEventListener("click", onClickOut, false),
      250
    );
    return () => document.removeEventListener("click", onClickOut, false);
  }, []);

  const ref = useRef();

  return (
    <div className={styles.optionsAnchor}>
      <div className={styles.options} ref={ref}>
        {colorPalettes.isSuccess && (
          <>
            {!usingPalette && (
              <div
                className={`${styles.option} ${styles.currentOption}`}
                onClick={() => onSelect(currPalette)}
              >
                <div className={styles.currentOptionName}>
                  <div className={styles.optionName}>{currPalette.name}</div>
                  <div className={styles.indicator}>Current</div>
                </div>
                <div className={styles.optionColors}>
                  {JSON.parse(currPalette.colors).map((color) => (
                    <div
                      className={styles.colorSwatch}
                      style={{ backgroundColor: color }}
                    ></div>
                  ))}
                </div>
              </div>
            )}

            {colorPalettes.data.colorPalettes.map((palette) => {
              if (palette.id !== currPalette.id) {
                return (
                  <div
                    className={styles.option}
                    onClick={() => onSelect(palette)}
                  >
                    <div className={styles.optionName}>
                      <>
                        {palette.name}
                        {palette.isDefault && (
                          <div className={styles.default}>Default</div>
                        )}
                      </>
                    </div>
                    <div className={styles.optionColors}>
                      {JSON.parse(palette.colors).map((color) => (
                        <div
                          className={styles.colorSwatch}
                          style={{ backgroundColor: color }}
                        ></div>
                      ))}
                    </div>
                  </div>
                );
              }
            })}

            {reaction.isSuccess &&
              currPalette.id !== reaction.data.palette.id && (
                <div
                  className={styles.option}
                  onClick={() => onSelect(reaction.data.palette)}
                >
                  <div className={styles.optionName}>
                    {reaction.data.palette.name}
                    {!colorPalettes.data.colorPalettes.some(
                      (p) => p.isDefault
                    ) && <div className={styles.default}>Default</div>}
                  </div>
                  <div className={styles.optionColors}>
                    {JSON.parse(reaction.data.palette.colors).map((color) => (
                      <div
                        className={styles.colorSwatch}
                        style={{ backgroundColor: color }}
                      ></div>
                    ))}
                  </div>
                </div>
              )}

            <div
              className={`${styles.createNew} ${styles.option} `}
              onClick={createNew}
            >
              Create New Palette
            </div>
          </>
        )}
      </div>
    </div>
  );
}
