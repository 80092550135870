/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/
// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";

export const useSearchDeliveries = () => {
  const mutation = gql`
    mutation Mutation(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortSelectionDelivery!
      $projectId: String!
      $distId: String!
      $send: Float!
    ) {
      deliveries: searchDeliveries(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        projectId: $projectId
        distId: $distId
        send: $send
      ) {
        id
        status
        message
        error
        opens
        clicks
        date
        distribution {
          subject
          scheduledDate
          index
          status
        }
        participation {
          contact {
            id
            email
            firstName
            lastName
            createdAt
            customField
          }
        }
      }
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useCountDeliverySearch = (
  searchString,
  page,
  perPage,
  projectId,
  distId,
  send
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $projectId: String!
      $distId: String!
      $send: Float!
    ) {
      count: countSearchDeliveries(
        search: $search
        page: $page
        perPage: $perPage
        projectId: $projectId
        distId: $distId
        send: $send
      )
    }
  `;

  return useGqlQuery(
    ["countdeliveriessearch:" + distId + send, searchString],
    query,
    {
      search: searchString,
      page: page,
      perPage: perPage,
      projectId: projectId,
      distId: distId,
      send: send,
    }
  );
};

export const useCancelSelectedSends = () => {
  const mutation = gql`
    mutation Mutation($deliveries: String!, $distId: String!) {
      cancelDeliveries(deliveries: $deliveries, distId: $distId)
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useGetDeliveries = (id) => {
  console.log(id);
  const query = gql`
    query {
      count: getEmailMessages(
        id: "${id}"
      ) {
        id
      }
    }
  `;

  return useGqlQuery(["getEmailMessages:" + id], query);
};

export const useUnsubFromDelivery = () => {
  const mutation = gql`
    mutation UnsubFromDelivery($val: Boolean!, $id: String!) {
      unsubFromDelivery(val: $val, id: $id)
    }
  `;

  return useGqlMutation(mutation);
};
