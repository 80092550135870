import styles from './SmallCheckbox.module.scss';

export function SmallCheckbox({ checked, onChange }) {
  return (
    <label
      className={`${styles.smallCheckbox} ${checked ? styles.checked : ""}`}
    >
      <input
        type="checkbox"
        onChange={(e) => onChange(e.target.checked)}
        checked={checked}
        className={styles.input}
      />
    </label>
  );
}