// External
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Loading } from "components/Loading/Loading";
import {
  useCreateAnonParticipation,
  useGetParticipation,
} from "api/resources/projects/participations";
import { ErrorPage } from "pages/error_page/ErrorPage";
import SurveyHandler from "./SurveyHandler";
import { useFetchProjectLink } from "api/resources/projects/projects";

export const SurveyTakerNoPart = () => {
  const { projectId } = useParams();
  const getSurvey = useFetchProjectLink(projectId);

  return (
    <>
      {getSurvey.isError && (
        <ErrorPage
          error={getSurvey.error}
          backup={"Survey Taker No Part - getDistributionStatus"}
          autoSend
        ></ErrorPage>
      )}
      {getSurvey.isLoading && <Loading></Loading>}
      {getSurvey.isSuccess && (
        <ParticipantLoader
          noLongerActive={!getSurvey.data?.survey?.activeLink}
          closed={getSurvey.data?.survey?.status != "Open"}
        />
      )}
    </>
  );
};

function ParticipantLoader({ noLongerActive, closed }) {
  const { projectId } = useParams();
  const [participation, setParticipation] = useState("");

  const createParticipation = useCreateAnonParticipation();
  const getParticipation = useGetParticipation();

  
  useEffect(() => {
    if (!closed && !noLongerActive) {
      // Don't create a participation if the link is inactive or if the project is closed

      if (
        !localStorage.getItem("finishedSurvey") ||
        localStorage.getItem("finishedSurvey") !== projectId
      ) {
        if (
          !localStorage.getItem("startedSurvey") ||
          localStorage.getItem("startedSurvey") !== projectId
        ) {
          // You haven't started, make a participation

          createParticipation.mutate(
            {
              projectId: projectId,
            },
            {
              onSuccess: (data) => {
                // console.log("participation created!", data);
                setParticipation(data.part);
                localStorage.setItem("startedSurvey", projectId);
                localStorage.setItem(
                  "participation for " + projectId,
                  data.part.id
                );
              },
              onError: (data) => {},
            }
          );
        } else {
          // You started but did not finish. Get the participationId and answers.
          let id = localStorage.getItem("participation for " + projectId);
          getParticipation.mutate(
            {
              participationId: id,
            },
            {
              onSuccess: (data) => {
                setParticipation(data.getParticipation);
              },
              onError: (data) => {
                console.log("resume participation error");
              },
            }
          );
        }
      }
      // Else you've already taken it.
    }
  }, []);

  function onFinish() {
    localStorage.setItem("finishedSurvey", projectId);
    localStorage.removeItem("participation for " + projectId);
    localStorage.removeItem("startedSurvey");
  }

  // Add isLoading

  return (
    <>
      {getParticipation.isError && (
        <ErrorPage
          error={getParticipation.error}
          backup={"Survey Taker No Part - getParticipation"}
          autoSend
        ></ErrorPage>
      )}

      {createParticipation.isError && (
        <ErrorPage
          error={createParticipation.error}
          backup={"Survey Taker No Part - createParticipation"}
          autoSend
        ></ErrorPage>
      )}

      {(closed ||
        noLongerActive ||
        participation ||
        localStorage.getItem("finishedSurvey") === projectId) && (
        <SurveyHandler
          participation={participation}
          onFinished={onFinish}
          alreadyTaken={localStorage.getItem("finishedSurvey") === projectId}
          noLongerActive={noLongerActive}
        />
      )}
    </>
  );
}
