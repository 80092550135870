/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2024 Reaction Data Inc. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: brodyspencer

*/
import { useState } from "react";
import { EditColors } from "components/ColorPalette/EditColors/EditColors";
import { TextFieldSimple } from "components/inputs";
import { Label } from "components/layouts/Label/Label";
import Button from "components/Button/Button";
import styles from "./PaletteEditor.module.scss";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import GradientBuilder from "./GradientBuilder/GradientBuilder";
import Modal from "components/ReactModal/ReactModal";
import {
  getGradientColors,
  getTheOffWhite,
} from "assets/functions/ColorFunctions";

export default function PaletteEditor({
  onCancel,
  initPalette,
  onSave,
  onCreate,
  onDelete,
}) {
  const [buildGradient, setBuildGradient] = useState(false);

  const [palette, setPalette] = useState({
    name: initPalette ? initPalette.name : "",
    colors: initPalette ? JSON.parse(initPalette.colors) : [],
    isDefault: initPalette ? initPalette.isDefault : false,
    fill: initPalette ? initPalette.fill : true,
    blended: initPalette ? initPalette.blended : false,
    anchors:
      initPalette && initPalette.anchors
        ? JSON.parse(initPalette.anchors)
        : null,
  });

  function handleSave() {
    let it = {
      name: palette.name,
      colors: JSON.stringify(palette.colors),
      fill: palette.fill,
      isDefault: palette.isDefault,
      blended: palette.blended,
      anchors: palette.anchors ? JSON.stringify(palette.anchors) : null,
    };
    if (onSave) {
      onSave(it);
    }
    if (onCreate) {
      onCreate(it);
    }
  }

  function changeField(field, val) {
    let copy = { ...palette };
    copy[field] = val;
    setPalette(copy);
  }

  function changeFill() {
    setPalette((copy) => {
      return { ...copy, fill: !copy.fill };
    });
  }

  function checkAuto() {
    if (!palette.fill) {
      changeFill();
    }
  }

  function checkRepeat() {
    if (palette.fill) {
      changeFill();
    }
  }

  function changeBlended() {
    setPalette((copy) => {
      return { ...copy, blended: !copy.blended };
    });
  }

  function checkSingleToned() {
    if (palette.blended) {
      changeBlended();
    }
  }

  function checkBlended() {
    if (!palette.blended) {
      changeBlended();
    }
  }

  function onClear() {
    let copy = { ...palette };
    copy.colors = [];
    copy.anchors = null;
    setPalette(copy);
  }

  const exampleGradient = [
    "#15bcc7",
    "#3cc7d0",
    "#63d2da",
    "#8adee3",
    "#b1e9ed",
    "#d8f4f6",
  ];

  function findMinLength(anchors) {
    let min = 1000;
    for (let anchor of anchors) {
      if (anchor.length < min) {
        min = anchor.length;
      }
    }
    return min;
  }

  function onGradientImport(data, blended) {
    let copy = { ...palette };
    copy.colors = data.map((d) => d.color);
    copy.fill = true;
    copy.blended = blended;

    let anchors = [];
    if (blended) {
      // count gap proportion
      let extras = 0;
      let anchor = { ...data[0], length: 0 };
      for (let i = 1; i < data.length; i++) {
        if (data[i].anchor) {
          anchors.push(anchor);
          anchor = { ...data[i], length: 0 };
        } else {
          extras++;
          anchor.length++;
        }
      }

      for (let anchor of anchors) {
        anchor.portion = Math.round((anchor.length / extras) * 100);
      }

      anchors.push({ ...data[data.length - 1], portion: 0 });
    } else {
      // get reversed (lengths included already)
      for (let i = 0; i < data.length; i++) {
        if (data[i].anchor) {
          let anchor = { ...data[i] };
          anchor.reversed = anchor.realInd != i;
          anchors.push(anchor);
        }
      }

      for (let anchor of anchors) {
        anchor.portion = Math.round((anchor.length / data.length) * 100);
      }
    }

    for (let anchor of anchors) {
      delete anchor.realInd;
      delete anchor.length;
      delete anchor.anchor;
    }

    copy.anchors = anchors;
    setPalette(copy);
    setBuildGradient(false);
  }

  function changeColors(colors) {
    let copy = { ...palette };
    copy.colors = colors;
    setPalette(copy);
  }

  function confirmDelete() {
    if (window.confirm(`Are you sure you want to delete this palette?`)) {
      onDelete();
    }
  }

  function getSingleTonesExample() {
    let colorOne = palette.colors[0];
    let offOne = getTheOffWhite(colorOne, 3);
    let gradient = getGradientColors(colorOne, offOne, 3);

    if (palette.colors.length > 1) {
      let offTwo = getTheOffWhite(palette.colors[1], 3);
      let gradTwo = getGradientColors(palette.colors[1], offTwo, 3);
      gradient = [...gradient, ...gradTwo];
    } else {
      gradient = [...gradient, ...gradient];
    }
    return gradient;
  }

  function getBlendedExample() {
    // const blended = [
    //   "#7fcfd3",
    //   "#81cfc4",
    //   "#83d0b5",
    //   "#85d0a6",
    //   "#87d197",
    //   "#89d188",
    // ];

    // const blendedBP = [
    //   "#7fcfd3",
    //   "#90c8d7",
    //   "#a1c0dc",
    //   "#b2b9e0",
    //   "#c3b1e5",
    //   "#d4aae9",
    // ];
    if (palette.colors.length > 1) {
      return getGradientColors(palette.colors[0], palette.colors[1], 6);
    }
    let offWhite = getTheOffWhite(palette.colors[0], 6);
    return getGradientColors(palette.colors[0], offWhite, 6);
  }

  function getRowsOfTen() {
    let rows = [];
    let i = 0;
    while (i < palette.colors.length) {
      let row = [];
      for (let c = 0; c < 10; c++) {
        if (i < palette.colors.length) {
          row.push(palette.colors[i]);
          i++;
        }
      }
      rows.push(row);
    }
    return rows;
  }

  function getGradientInfo() {
    let init = {
      colors: [...palette.colors],
      anchors: [...palette.anchors],
      blended: palette.blended,
    };
    return init;
  }

  return (
    <Modal
      show={true}
      onClose={onCancel}
      modalStyle={{
        borderRadius: ".5em",
        padding: "2rem",
        overflow: "visible",
        // height: "100%"
      }}
      dark
      // rightStyle
    >
      {!buildGradient && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            alignItems: "center",
            paddingBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Label
              style={{
                fontWeight: "500",
                fontSize: ".8em",
                textAlign: "center",
                width: "fit-content",
                paddingLeft: "0px",
              }}
            >
              Palette Name
              {!palette.name && (
                <i
                  className="bi bi-asterisk"
                  style={{
                    fontSize: ".2em",
                    color: "#FF8878",
                    paddingLeft: "2px",
                  }}
                ></i>
              )}
            </Label>
            <div style={{ flexGrow: 1 }}>
              <TextFieldSimple
                lineTheme
                style={{ fontSize: ".9em", textAlign: "center" }}
                value={palette.name}
                onChange={(val) => changeField("name", val)}
              ></TextFieldSimple>
            </div>
          </div>

          <div
            className={styles.buildGradient}
            onClick={() => setBuildGradient(true)}
          >
            <div>Build Gradient</div>
            <div className={styles.exampleGradient}>
              {exampleGradient.map((g) => (
                <div
                  className={styles.gradientColor}
                  style={{ backgroundColor: g }}
                ></div>
              ))}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <Label
              style={{
                fontWeight: "500",
                fontSize: ".8em",
                textAlign: "center",
                width: "fit-content",
                padding: "0px 10px",
                borderBottom: "2px solid #A4C6D0",
              }}
            >
              Palette
            </Label>

            {!palette.anchors && (
              <EditColors
                colors={palette.colors}
                changeColors={changeColors}
              ></EditColors>
            )}

            {palette.anchors && (
              <div className={styles.plainPalette}>
                {getRowsOfTen().map((row) => (
                  <div className={styles.row}>
                    {row.map((color) => (
                      <div
                        className={styles.plainColor}
                        style={{ backgroundColor: color }}
                      ></div>
                    ))}
                  </div>
                ))}
              </div>
            )}

            <div
              className={styles.clear}
              style={{
                cursor: "pointer",
                visibility: palette.colors.length > 0 ? "visible" : "hidden",
              }}
              onClick={onClear}
            >
              <i className="bi bi-x" style={{ marginRight: "5px" }}></i>
              Clear
            </div>
          </div>

          <div className={styles.defaultSettings}>
            <Label
              style={{
                width: "fit-content",
                paddingLeft: "0px",
                fontWeight: "400",
                textTransform: "none",
                letterSpacing: "normal",
              }}
              tooltipText="These settings will be automatically applied to charts, unless otherwise overridden by a chart's settings"
              labelIcon={<i className="bi bi-info-circle"></i>}
            >
              Default Settings
            </Label>

            <div
              style={{
                display: "flex",
                gap: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  flexGrow: 1,
                }}
              >
                <div className={styles.setting}>
                  <Checkbox
                    checked={palette.fill}
                    onChange={checkAuto}
                    color="#7FCFD3"
                  />
                  <Label
                    style={{
                      color: "#A4C6D0",
                      width: "fit-content",
                      paddingLeft: "0px",
                      fontWeight: "400",
                      textTransform: "none",
                      letterSpacing: "normal",
                    }}
                    tooltipText={
                      palette.anchors
                        ? "Charts will mimic the gradient in the palette"
                        : "A gradient will be applied on charts with bars, slices, lines, etc, that exceed the number of colors in the palette"
                    }
                    tooltipStyle={{ color: "#738c91" }}
                    labelIcon={<i className="bi bi-info-circle"></i>}
                  >
                    Auto Fill
                  </Label>
                </div>

                {palette.fill &&
                  !palette.anchors &&
                  palette.colors.length > 0 && (
                    <div className={styles.subSettings}>
                      <div className={styles.fillSetting}>
                        <div className={styles.setting}>
                          <Checkbox
                            checked={!palette.blended}
                            onChange={checkSingleToned}
                            color="#7FCFD3"
                          />
                          <Label
                            style={{
                              color: "#A4C6D0",
                              width: "fit-content",
                              paddingLeft: "0px",
                              fontWeight: "400",
                              textTransform: "none",
                              letterSpacing: "normal",
                            }}
                          >
                            Single Tones
                          </Label>
                        </div>

                        <div className={styles.fillExampleGradient}>
                          {getSingleTonesExample().map((color) => (
                            <div
                              className={styles.gradientColor}
                              style={{ backgroundColor: color }}
                            ></div>
                          ))}
                        </div>
                      </div>

                      <div className={styles.fillSetting}>
                        <div className={styles.setting}>
                          <Checkbox
                            checked={palette.blended}
                            onChange={checkBlended}
                            color="#7FCFD3"
                          />
                          <Label
                            style={{
                              color: "#A4C6D0",
                              width: "fit-content",
                              paddingLeft: "0px",
                              fontWeight: "400",
                              textTransform: "none",
                              letterSpacing: "normal",
                            }}
                          >
                            Blended
                          </Label>
                        </div>
                        <div className={styles.fillExampleGradient}>
                          {getBlendedExample().map((color) => (
                            <div
                              className={styles.gradientColor}
                              style={{ backgroundColor: color }}
                            ></div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                <div className={styles.setting}>
                  <Checkbox
                    checked={!palette.fill}
                    onChange={checkRepeat}
                    color="#7FCFD3"
                  />
                  <Label
                    style={{
                      color: "#A4C6D0",
                      width: "fit-content",
                      paddingLeft: "0px",
                      fontWeight: "400",
                      textTransform: "none",
                      letterSpacing: "normal",
                    }}
                    tooltipText="Colors will repeat in a chart to fill extra bars, slices, lines, etc."
                    tooltipStyle={{ color: "#738c91" }}
                    labelIcon={<i className="bi bi-info-circle"></i>}
                  >
                    Repeat
                  </Label>
                </div>
              </div>

              <div
                className={styles.setting}
                style={{ alignSelf: "flex-start", flexGrow: 1 }}
              >
                <Checkbox
                  color="#7FCFD3"
                  checked={palette.isDefault}
                  onChange={(e) => changeField("isDefault", e.target.checked)}
                />
                <Label
                  style={{
                    color: "#A4C6D0",
                    width: "fit-content",
                    paddingLeft: "0px",
                    fontWeight: "400",
                    textTransform: "none",
                    letterSpacing: "normal",
                  }}
                  tooltipText="Make this palette be the organization default for all charts"
                  tooltipStyle={{ color: "#738c91" }}
                  labelIcon={<i className="bi bi-info-circle"></i>}
                >
                  Make Default
                </Label>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            {onDelete && (
              <Button shadow width={80} red onClick={confirmDelete}>
                Delete
              </Button>
            )}
            <Button
              shadow
              red={!onDelete}
              onClick={onCancel}
            >
              Cancel
            </Button>
            {palette.name && palette.colors.length > 0 && (
              <>
                {onSave && (
                  <Button shadow blue onClick={handleSave}>
                    Save
                  </Button>
                )}
                {onCreate && (
                  <Button shadow blue onClick={handleSave}>
                    Create
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      )}

      {buildGradient && (
        <GradientBuilder
          onExit={() => setBuildGradient(false)}
          onSave={onGradientImport}
          init={palette.anchors ? getGradientInfo() : undefined}
        />
      )}
    </Modal>
  );
}
