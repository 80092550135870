import React, { useState } from "react";
import { NumChoices } from "components/inputs/input_fields/NumChoices/NumChoices";
import styles from "./NumberScale.module.scss";

export default function NumberScale({
  question,
  onAnswered,
  onRetract,
  color,
  startVal,
}) {
  const [selected, setSelected] = useState(startVal);

  function onChange(val) {
    if (selected == val) {
      setSelected(null);
      onRetract(question.id);
    } else {
      setSelected(val);
      onAnswered(question.id, val);
    }
  }

  return (
    <NumChoices
      selected={selected}
      min={Number(question.scaleQuestion?.min)}
      max={Number(question.scaleQuestion?.max)}
      step={Number(question.scaleQuestion?.step)}
      minDescription={question.scaleQuestion.minDescription}
      maxDescription={question.scaleQuestion.maxDescription}
      color={color}
      onChange={onChange}
    />
  );
}
