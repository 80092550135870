import React, { useEffect, useRef, useState } from "react";
import styles from "./TriggerMessage.module.scss";

/**
 * An text area input designed for text questions
 * @param {Int} value something
 * @param {Int} placeholder something
 * @param {Int} onSave something
 * @param {Int} autosave something
 * @param {Function} active something
 * @param {Function} disabled something
 * @param {string} color the color for the border when focused
 * @returns {React.ReactElement} a customized text area input
 */

export const TriggerMessage = ({ trigger, onEdit, custom_fields }) => {
  const [val, setVal] = useState(trigger.message);
  const ref = useRef();

  const keyWords = ["recipient", "participant", "answer"];

  const fields = [
    "first name",
    "last name",
    "email",
    ...custom_fields.map((f) => f.name),
  ];

  function appendBracket(container, bracket) {
    let it = document.createElement("span");
    it.classList.add(styles.bracket);
    it.appendChild(document.createTextNode(bracket));
    container.appendChild(it);
  }

  function appendKey(container, key) {
    appendBracket(container, "{");
    let it = document.createElement("span");
    it.classList.add(styles[key]);
    it.appendChild(document.createTextNode(key));
    container.appendChild(it);
  }

  function getParsedContent() {
    // let container = document.getElementById("parseTarget");
    let container = ref?.current;
    if (!container) {
      return;
    }
    container.innerHTML = ""; // Clear old

    let lastInd = 0;
    let subInd = val.indexOf("{");
    let endSubInd = val.indexOf("}");

    while (subInd > -1 && endSubInd > -1) {
      if (subInd > 0) {
        let firstPart = val.substring(lastInd, subInd);
        container.appendChild(document.createTextNode(firstPart));
      }

      let sub = val.substring(subInd + 1, endSubInd);

      let attribute = "";
      if (sub.includes("participant")) {
        appendKey(container, "participant");
        attribute = sub.substring(12);
      } else if (sub.includes("recipient")) {
        appendKey(container, "recipient");
        attribute = sub.substring(10);
      } else if (sub.includes("answer")) {
        appendKey(container, "answer");
        appendBracket(container, "}");
      }

      if (attribute) {
        let att = document.createElement("span");
        if (
          attribute === "name" ||
          attribute === "email" ||
          custom_fields.find((c) => c.name === attribute)
        ) {
          att.classList.add(styles.attribute);
        }
        att.appendChild(document.createTextNode(" " + attribute));
        container.appendChild(att);
        appendBracket(container, "}");
      }

      lastInd = endSubInd + 1;
      subInd = val.indexOf("{", lastInd);
      endSubInd = val.indexOf("}", lastInd);
    }

    let lastPart = document.createTextNode(val.substring(lastInd));
    container.appendChild(lastPart);
  }

  useEffect(() => {
    getParsedContent();
  }, [val]);

  function onMessageChange(e) {
    setVal(e.target.innerText);
    onEdit(e.target.innerText);
  }

  return (
    <div className={styles.container}>
      {/* <div className={styles.explanation}>
        {`text inside { } will be substituted with the corresponding field`}
      </div> */}
      <div className={styles.explanation}>
        <div>
          Dynamic text options:
          <span style={{ color: "#2A627C" }}>{` {`}</span>
          <span style={{ color: "#738c91" }}>{`recipient `}</span>
          or
          <span style={{ color: "#738c91" }}>{` participant `}</span>
          <span style={{ color: "#15bcc7" }}>name</span>
          {` or `}
          <span style={{ color: "#15bcc7" }}>custom field</span>
          <span style={{ color: "#2A627C" }}>{`} `}</span>or
          <span style={{ color: "#2A627C" }}>{` {answer}`}</span>.
        </div>
      </div>

      <div className={styles.entryBox}>
        <div
          className={styles.parsedContent}
          // id="parseTarget"
          ref={ref}
          contentEditable={true}
          onBlur={onMessageChange}
        ></div>
      </div>
    </div>
  );
};
