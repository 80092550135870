import React, { useEffect, useLayoutEffect } from "react";
import styles from "./SurveyPath.module.scss";

function SurveyPath({ paths }) {
  useLayoutEffect(() => {
    if (paths && paths.length > 0) {
      let xy1 = {
        m_x: 0,
        m_y: 0,
      };

      let xy2 = {
        m_x: 200,
        m_y: 0,
      };

      for (let i = 0; i < paths.length - 1; i++) {
        // debugger;
        // let path = paths[i];
        // if (i != 0) {
        //   xy1 = {
        //     m_x: xy2.m_x,
        //     m_y: xy2.m_y,
        //   };
        //   if (i % 2 === 0) {
        //     xy2 = {
        //       m_x: xy2.m_x + 150,
        //       m_y: 0,
        //     };
        //   } else {
        //     xy2 = {
        //       m_x: xy2.m_x + 150,
        //       m_y: 0,
        //     };
        //   }
        // }

        var dotA = document.getElementById(`dot${i}`);
        if (i % 2 === 1) {
          // dotA.style.marginTop = "100px"
        }
        xy1 = {
          m_x: dotA.offsetLeft,
          m_y: dotA.offsetTop,
        };
        // dotA.style.left = dotA.offsetLeft + "px";
        // dotA.style.top = dotA.offsetTop + "px";

        var dotB = document.getElementById(`dot${i + 1}`);
        xy2 = {
          m_x: dotB.offsetLeft,
          m_y: dotB.offsetTop,
        };
        // dotB.style.left = dotB.offsetLeft + "px";
        // dotB.style.top = dotB.offsetTop + "px";

        var line = document.getElementById(`line${i}`);

        var distance = Math.hypot(xy2.m_x - xy1.m_x, xy2.m_y - xy1.m_y);

        line.style.width = distance + "px";
        line.style.left = xy1.m_x + 7 + "px";
        line.style.top = xy1.m_y + 8 + "px";

        var angle =
          ((Math.atan2(xy1.m_x - xy2.m_x, xy2.m_y - xy1.m_y) + Math.PI / 2.0) *
            180) /
          Math.PI;
        line.style.transform = "rotate(" + angle + "deg)";
        line.style.display = "block";
      }
    }
  }, [window.innerWidth]);
  return (
    <div className={styles.pathContainer}>
      {paths.map((path, i) => (
        <React.Fragment key={i}>
          <div
            id={`dot${i}`}
            className={`${styles.dot} ${!path.done && styles.hollow}`}
            style={{pointerEvents:  i > 0 && !paths[i - 1].done ? "none" : "" }}
            onClick={
              i > 0 && !paths[i - 1].done ? undefined : path.to
            }
          >
            <div className={styles.name}>
              <i className={path.icon}></i>
              {path.name}
            </div>
          </div>
          {i != paths.length && (
            <div
              id={`line${i}`}
              className={`${!path.done && styles.hollowLine} ${
                path.done && styles.line
              } `}
            ></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default SurveyPath;
