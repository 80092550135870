import styles from "../SettingsAccordion.module.scss";
import {
  TableType,
  High2Low,
  Low2High,
  NoSort,
  NumUp,
  NumDown,
  AtoZ,
  ZtoA,
  ReverseSort,
  splitSomehow,
  HorizBarType,
} from "../../QuestionChart";
import { forEach } from "assets/functions/ArrayFunctions";
import { splitting } from "../SettingsAccordion";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { ChartLabel } from "../Tools";

//custom accordian
export const SortingSettings = ({
  viz,
  title,
  changeSettingsField,
  labels,
  visible,
  setVisible,
}) => {
  function getShrinkScale() {
    let min = 10000;
    let max = -100;
    if (viz?.question) {
      forEach(viz?.question, (q) => {
        if (q.scaleQuestion.min < min) {
          min = q.scaleQuestion.min;
        }
        if (q.scaleQuestion.max > max) {
          max = q.scaleQuestion.max;
        }
      });
    }
    return max + " - " + min;
  }

  function getGrowScale() {
    let min = 10000;
    let max = -100;
    if (viz?.question) {
      forEach(viz?.question, (q) => {
        if (q.scaleQuestion.min < min) {
          min = q.scaleQuestion.min;
        }
        if (q.scaleQuestion.max > max) {
          max = q.scaleQuestion.max;
        }
      });
    }
    return min + " - " + max;
  }

  function getAxisSortOptions() {
    let numberLabels = false;
    let mixedLabels = false;
    let stringLabels = false;

    let type;

    for (let opt of labels.axisLabels) {
      if (!type) {
        type = typeof opt.label;
      } else if (type !== typeof opt.label) {
        mixedLabels = true;
        break;
      }
    }

    if (!mixedLabels) {
      if (type === "number") {
        numberLabels = true;
      } else {
        stringLabels = true;
      }
    }

    const sortByAxisLabel = {
      heading: "By Axis Label",
      items: [
        {
          label: numberLabels
            ? `Ascending (${getGrowScale()})`
            : mixedLabels
            ? "Ascending (1,2,3... A - Z)"
            : "Ascending (A - Z)",
          value: AtoZ,
        },
        {
          label: numberLabels
            ? `Descending (${getShrinkScale()})`
            : mixedLabels
            ? "Descending(3,2,1... Z - A)"
            : "Descending (Z - A)",
          value: ZtoA,
        },
      ],
    };
    return sortByAxisLabel;
  }

  const sortByAnswerCount = {
    heading: "By Value",
    items: [
      {
        label: (
          <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
            Ascending
            <i className="bi bi-bar-chart-fill"></i>
          </div>
        ),
        value: Low2High,
      },
      {
        label: (
          <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
            Descending{" "}
            <div style={{ transform: "rotateY(180deg)" }}>
              <i className="bi bi-bar-chart-fill"></i>
            </div>
          </div>
        ),
        value: High2Low,
      },
    ],
  };

  function getSortOptions() {
    let options = [getAxisSortOptions()];
    if (!splitting(viz)) {
      options.push(sortByAnswerCount);
    }
    return options;
  }

  const sortDataOptions = getSortOptions();

  function sortingByLabel() {
    return (
      viz.designSettings.sortData === AtoZ ||
      viz.designSettings.sortData === ZtoA
    );
  }

  return (
    <>
      <div
        key={"datalabels"}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        <FlexRow
          onClick={visible ? setVisible : undefined}
          style={{ cursor: "pointer" }}
          className={styles.accordionTitle}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {title}{" "}
            <i
              style={{ fontSize: "1.2em" }}
              className="bi bi-sort-alpha-down"
            ></i>
          </div>

          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>
        {visible && labels && (
          <div className={styles.body} style={{ gap: "8px" }}>
            {sortDataOptions.map((option) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                <ChartLabel>{option.heading}</ChartLabel>
                <div className={styles.sortOptions}>
                  {option.items.map((item) => (
                    <div className={styles.radioLabel}>
                      <ChartLabel>{item.label}</ChartLabel>

                      <input
                        id={item.value}
                        type="radio"
                        name="sort"
                        value={item.value}
                        onChange={() =>
                          changeSettingsField("sortData", item.value)
                        }
                        checked={viz.designSettings.sortData === item.value}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}

            <div
              className={styles.radioLabel}
              style={{ paddingLeft: "0px", paddingTop: "5px" }}
            >
              <ChartLabel>{`No Sort (Original)`}</ChartLabel>
              <input
                id="None"
                type="radio"
                name="sort"
                value="none"
                onChange={() => changeSettingsField("sortData", NoSort)}
                checked={viz.designSettings.sortData === NoSort}
              />
            </div>

            <div className={styles.radioLabel} style={{ paddingLeft: "0px" }}>
              <ChartLabel>
                Reverse Original{" "}
                <i
                  className="bi bi-arrow-left-right"
                  style={{ marginLeft: "5px" }}
                ></i>
              </ChartLabel>
              <input
                id="None"
                type="radio"
                name="sort"
                value="none"
                onChange={() => changeSettingsField("sortData", ReverseSort)}
                checked={viz.designSettings.sortData === ReverseSort}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
