import React, { useEffect, useState } from "react";
import {
  useFetchAudContactsGql,
  useFetchDistConfigs,
} from "api/resources/projects/distributions";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import { SelectSignature } from "components/inputs/input_fields/SelectSignature/SelectSignature";
import { Loading } from "components/Loading/Loading";

import { useNavigate } from "react-router-dom";
import styles from "./tofrom.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { Label } from "components/layouts/Label/Label";

export function ToFrom({ email, setEmail, show, setShow, roles }) {
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(50);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageSort, setPageSort] = useState({
    item: "updatedAt",
    descend: true,
  });

  const getAudiences = useFetchAudContactsGql(
    pageSkip,
    perPage,
    pageSort,
    search
  );
  useEffect(() => {
    getAudiences.refetch();
  }, [search]);
  const getDistConfigs = useFetchDistConfigs();

  function handleSigChange(sig) {
    let newEmail = { ...email };
    newEmail.sender = sig.fromAddress;
    newEmail.distConfig = sig;
    setEmail(newEmail);
  }

  function handleFromTypeChange(isDynamic) {
    let newEmail = { ...email };
    newEmail.dynamicFrom = isDynamic;
    setEmail(newEmail);
  }

  function handleReplyToChange(val) {
    let newEmail = { ...email };
    newEmail.replyTo = val;
    setEmail(newEmail);
  }

  function handleDynamicChange(conditions) {
    let newEmail = { ...email };
    newEmail.dynamicConditions = JSON.stringify(conditions);
    setEmail(newEmail);
  }

  function handleAudienceChange(audience, remove) {
    let newEmail = { ...email };
    if (remove) {
      let newValues = [...newEmail.audience];
      let index = newValues.indexOf(audience);
      newValues.splice(index, 1);
      newEmail.audience = newValues;
    } else {
      if (newEmail.audience) {
        newEmail.audience.push(audience);
      } else {
        newEmail.audience = [audience];
      }
    }
    // updateEmail(newEmail); //may or may not need
    setEmail(newEmail);
  }

  let navigate = useNavigate();
  const routeChange = (path) => {
    // alert(path);
    navigate(path);
  };

  return (
    <>
      {getDistConfigs.isLoading && <Loading height={20} width={20} />}
      {getDistConfigs.isError && <div>...error</div>}
      {getDistConfigs.isSuccess && (
        <div className={styles.from}>
          <Label style={{ width: "80px", justifyContent: "end" }}>From:</Label>
          {/* <TextFieldSimple placeholder={"From..."} /> */}

          <SelectSignature
            startSig={email.distConfig}
            signatureList={getDistConfigs.data.signatures}
            startCondition={email?.dynamicConditions}
            isDynamic={email?.dynamicFrom}
            onTypeChange={handleFromTypeChange}
            onChange={handleSigChange}
            onDynamicChange={handleDynamicChange}
            placeholder="Choose..."
          ></SelectSignature>
        </div>
      )}
      <div
        className={styles.to}
        style={{ paddingLeft: !email?.dynamicFrom ? "" : ".5em" }}
      >
        <Label style={{ width: "80px", justifyContent: "end" }}>To:</Label>
        {getAudiences.isLoading && <Loading height={20} width={20} />}
        {getAudiences.isError && <div>...error</div>}
        {getAudiences.isSuccess && (
          <SelectFieldCustom
            options={getAudiences.data.items}
            values={email.audience}
            onChange={(aud, rem) => handleAudienceChange(aud, rem)}
            selectMultiple
            searchable
            placeholder="Choose Audiences..."
            newOptionText="Create New Audience"
            onNewOptionClick={() => routeChange("/audiences")}
            onItemClick={
              roles.canSeeContactInfo ? (item) => setShow(item) : undefined
            }
            containerStyle={{ fontSize: ".8em", paddingLeft: ".2em" }}
            searchString={search}
            setSearchString={setSearch}
          ></SelectFieldCustom>
        )}

        {!email?.dynamicFrom && (
          <div className={styles.from}>
            <Label
              style={{
                width: "80px",
                justifyContent: "end",
                whiteSpace: "nowrap",
              }}
            >
              Reply To:
            </Label>
            <TextFieldSimple
              style={{ height: "35px", fontSize: ".8em" }}
              value={email?.replyTo ? email?.replyTo : email?.sender}
              onChange={handleReplyToChange}
            ></TextFieldSimple>
          </div>
        )}
      </div>
    </>
  );
}
