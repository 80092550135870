import React, { useEffect, useState } from "react";
import styles from "./SurveyDetails2.module.scss";
import {
  useFetchParticipationDates,
  useFetchProgramRecent,
  useFetchProjectGql,
  useGetSurveyStats,
} from "api/resources/projects/projects";
import { useNavigate, useParams } from "react-router-dom";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import Button from "components/Button/Button";
import Stat from "components/tables/BasicTable/SurveyWidgets/Stat";
import { BasicTable } from "components/tables/BasicTable/BasicTable";
import SurveySettings from "./SurveySettings";
import {
  useFetchDistributionsCountGql,
  useFetchDistributionsSimpleGql,
} from "api/resources/projects/distributions";
import { useFetchQuestionsGql } from "api/resources/projects/questions";
import { trimDate } from "assets/functions/DateFunctions";
import ProgramIterations from "./ProgramIterations";
import LaunchNext from "./LaunchNext";
import QuestionChart, {
  defaultColors,
} from "pages/results/Charts/QuestionChart";
import Chart from "components/Charts/Chart";
import { SelectFieldCustom } from "components/inputs";
import SurveyCheckList from "./SurveyCheckList";
import { Pie } from "components/inputs/input_fields/CircleProgress/Circle";
import { calculateSurveyHealth } from "assets/functions/SurveyHealth";
import { routeVariants, surveydetailsviz } from "assets/functions/Variables";
import { motion } from "framer-motion";
import Section from "components/layouts/Section/Section";
import { Loading } from "components/Loading Rectangle/Loading";
import { Loading as Loading2 } from "components/Loading/Loading";
import SurveyPath from "components/SurveyPath/SurveyPath";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { BarChartVertical } from "components/Charts/BarChartVertical/BarChartVertical";
import DownloadView from "components/DownloadView/DownloadView";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";

const headersDraft = [
  {
    id: 2,
    index: 2,
    name: "Question",
    label: "Question",
    value: "questionText",
    enabled: true,
    sort: false,
    canSort: false,
    width: 200,
  },
  {
    id: 3,
    index: 3,
    name: "Type",
    label: "Type",
    value: "type",
    enabled: true,
    sort: false,
    descend: false,
    canSort: false,
    cell_style: null,
  },
  {
    id: 4,
    index: 4,
    name: "When Added",
    label: "When Added",
    value: "createdAt",
    enabled: true,
    sort: true,
    canSort: false,
    cell_style: null,
  },
  {
    id: 5,
    index: 4,
    name: "Last Update",
    label: "Last Update",
    value: "updatedAt",
    enabled: true,
    sort: true,
    canSort: false,
    cell_style: null,
  },
  {
    id: 5,
    index: 4,
    name: "",
    label: "",
    value: "conditionGroups",
    enabled: true,
    sort: true,
    canSort: false,
    cell_style: (value) => (
      <div className={styles.tableIcon}>
        {value && <i className="bi bi-signpost-2-fill"></i>}
      </div>
    ),
  },
];

const headersOpen = [
  {
    id: 2,
    index: 2,
    name: "Question",
    label: "Question",
    value: "questionText",
    enabled: true,
    sort: false,
    canSort: false,
    width: 200,
  },
  {
    id: 3,
    index: 3,
    name: "Type",
    label: "Type",
    value: "type",
    enabled: true,
    sort: false,
    descend: false,
    canSort: false,
    cell_style: null,
  },
  {
    id: 3,
    index: 3,
    name: "",
    value: "conditionGroups",
    enabled: true,
    sort: true,
    canSort: false,
    cell_style: (value) => (
      <div className={styles.tableIcon}>
        {value && <i className="bi bi-signpost-2-fill"></i>}
      </div>
    ),
  },
  {
    id: 3,
    index: 3,
    name: "Responses",
    label: "Responses",
    value: "responses",
    // objectAccessor: "length",
    enabled: true,
    sort: true,
    canSort: false,
    // cell_style: (value) => (
    //   <div className={styles.tableIcon}>
    //     {value && <i className="bi bi-signpost-2-fill"></i>}
    //   </div>
    // ),
  },
];

function SurveyDetails2({ userRoles, currUser, setHideNav, direction }) {
  const { id } = useParams();
  const fetchProjectQuery = useFetchProjectGql(id);
  const getQuestions = useFetchQuestionsGql(id);
  const getCustomFields = useFetchCurrOrgsFields();
  const [showSettings, setShowSettings] = useState(false);
  const [showLaunch, setShowLaunch] = useState(false);
  const emailHeaders = [
    {
      id: 1,
      index: 1,
      name: "Type",
      label: "Type",
      value: "messageType",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: null,
      cell_style: (value) => (
        <div style={{ display: "flex", gap: ".5em", alignItems: "center" }}>
          {value === "email" ? (
            <>
              <i className={`bi bi-envelope`}></i> Email
            </>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      id: 2,
      index: 2,
      name: "Name",
      label: "Name",
      value: "name",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: null,
      // cell_style: (value) => (
      //   <div style={{ display: "flex", gap: ".5em", alignItems: "center" }}>
      //     <i className={`bi bi-${value?.icon}`}></i> {value?.name}
      //   </div>
      // ),
    },
    {
      id: 3,
      index: 3,
      name: "Subject",
      label: "Subject",
      value: "subject",
      enabled: true,
      sort: false,
      descend: false,
      canSort: false,
      cell_style: null,
    },
    {
      id: 4,
      index: 4,
      name: "Status",
      label: "Status",
      value: "status",
      enabled: true,
      sort: true,
      canSort: false,
      cell_style: (value) => (
        <span
          className={`${styles.statusStyle} 
        ${value === "Sent" ? `${styles.delivered}` : ""} 
        ${value === "Draft" ? `${styles.draft}` : ""}
        ${value === "Scheduled" ? `${styles.scheduled}` : ""}
        ${value === "Template" ? `${styles.active}` : ""}
        ${value === "Active" ? `${styles.active}` : ""}
        ${value === "Inactive" ? `${styles.inactive}` : ""}`}
        >
          {value}{" "}
        </span>
      ),
    },
    {
      id: 5,
      index: 4,
      name: "Date",
      label: "Date",
      value: "scheduledDate",
      enabled: true,
      sort: true,
      canSort: false,
      cell_style: (value) => <span>{trimDate(value, true)}</span>,
    },
  ];

  const navigate = useNavigate();

  const [pageSortDist, setPageSort] = useState({
    item: "index",
    descend: false,
  });
  const [perPageDist, setPerpage] = useState(10);
  const [pageSkipDist, setPageSkip] = useState(0);
  const [searchStringDist, setSearchString] = useState("");
  const [showIterations, setShowIterations] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [resultsPast, setResultsPast] = useState(100);
  const [resultsDate, setResultsDate] = useState(getDate(resultsPast));

  const getDistributions = useFetchDistributionsSimpleGql(
    id,
    pageSortDist,
    pageSkipDist,
    perPageDist,
    searchStringDist
  );
  const getDistributionsCount = useFetchDistributionsCountGql(
    id,
    pageSortDist,
    pageSkipDist,
    perPageDist,
    searchStringDist
  );

  const surveyStats = useGetSurveyStats(id, resultsDate);

  useEffect(() => {
    surveyStats.refetch();
  }, [resultsDate]);

  function getDate(past) {
    let date = new Date();
    date.setMonth(date.getMonth() - past);
    return date;
  }

  useEffect(() => {
    getDistributions.refetch();
    getDistributionsCount.refetch();
  }, [pageSkipDist, pageSortDist, searchStringDist]);

  function checkMethods(dists) {
    if (!dists) {
      return false;
    }
    let methods = [];

    for (let dist of dists) {
      if (methods?.length === 0) {
        methods.push(dist.messageType);
      } else if (methods.indexOf(dist.messageType) < 0) {
        methods.push(dist.messageType);
      }
    }

    return (
      <div style={{ display: "flex", gap: "7px" }}>
        {methods.map((method, i) => (
          <div
            className={`${styles.method} ${
              method === "email" && styles.email
            } ${method === "sms" && styles.sms}
            ${method === "link" && styles.link}
            ${method === "personal-link" && styles.personalLink}`}
            key={i}
          >
            {method === "email" && <i className="bi-envelope"></i>}
            {method === "sms" && <i className="bi-sms"></i>}
            {method === "link" && <i className="bi-link-45deg"></i>}
            {method === "personal-link" && <i className="bi-person"></i>}
            {method}
          </div>
        ))}
        {methods.length === 0 && "None"}
      </div>
    );
  }

  function getPath() {
    return [
      {
        name: "Build Survey",
        to: navigateToBuild,
        icon: "bi-hammer",
        done: getQuestions.data?.QuestionByProjectID?.length > 0,
      },
      {
        name: "Distribute Links",
        to: navigateToDelivery,
        icon: "bi-mailbox",
        done:
          getQuestions.data?.QuestionByProjectID?.length > 0 &&
          getDistributionsCount.data.count > 0,
      },
      {
        name: "Analyze Results",
        to: navigateToResults,
        icon: "bi-bar-chart",
      },
    ];
  }

  function navigateToBuild() {
    localStorage.setItem("activeprojecttab", 1);

    if (fetchProjectQuery.data.survey.isProgram) {
      navigate(`/program/${id}/survey-build`);
    } else {
      navigate(`/project/${id}/survey-build`);
    }
  }

  function navigateToResults() {
    localStorage.setItem("activeprojecttab", 4);
    if (fetchProjectQuery.data.survey.isProgram) {
      navigate(
        `/program/${id}/surveyresults/${fetchProjectQuery.data.survey.rawReportId}`
      );
    } else {
      navigate(
        `/project/${id}/surveyresults/${fetchProjectQuery.data.survey.rawReportId}`
      );
    }
  }

  function navigateToDelivery() {
    localStorage.setItem("activeprojecttab", 2);
    localStorage.setItem("activedelivertab", 1);
    if (fetchProjectQuery.data.survey.isProgram) {
      navigate(`/program/${id}/delivery/messages`);
    } else {
      navigate(`/project/${id}/delivery/messages`);
    }
  }

  const resultsDatOptions = [
    { label: "All Time", value: 100 },
    { label: "6m", value: 6 },
    { label: "9m", value: 9 },
    { label: "12m", value: 12 },
    { label: "18m", value: 18 },
    { label: "24m", value: 24 },
  ];

  return (
    <>
      <motion.div
        variants={routeVariants}
        custom={{ direction, animate: true }}
        initial="enter"
        animate="center"
        exit="exit"
        className={styles.page}
      >
        {(fetchProjectQuery.isLoading ||
          getDistributionsCount.isLoading ||
          getQuestions.isLoading ||
          getDistributions.isLoading) && <Loading2></Loading2>}
        {showIterations && fetchProjectQuery.isSuccess && (
          <ProgramIterations
            show={showIterations}
            setShow={setShowIterations}
            mainSurveyId={fetchProjectQuery.data.survey.id}
            programId={fetchProjectQuery.data.survey.programId}
            roles={userRoles}
          ></ProgramIterations>
        )}
        {showLaunch && fetchProjectQuery.isSuccess && (
          <LaunchNext
            show={showLaunch}
            setShow={(val) => {
              setShowLaunch(val);
              setHideNav(val);
            }}
            roles={userRoles}
            survey={fetchProjectQuery.data.survey}
            currUser={currUser}
            setHideNav={setHideNav}
          ></LaunchNext>
        )}

        {!showIterations &&
          fetchProjectQuery.isSuccess &&
          getDistributions.isSuccess &&
          getQuestions.isSuccess &&
          getDistributionsCount.isSuccess && (
            <div
              className={styles.detailsContainer}
              style={{
                borderRadius: "1em",
                position: showIterations || showLaunch ? "fixed" : "",
                zIndex: showIterations || showLaunch ? "0" : "",
                overflow: showIterations || showLaunch ? "hidden" : "",
              }}
            >
              {showResults && getCustomFields.isSuccess && (
                <DownloadView
                  show={showResults}
                  setShow={setShowResults}
                  survey={fetchProjectQuery.data.survey}
                  custom_fields={getCustomFields.data.getCurrOrgFields}
                  // externalChosenFilter={[]}
                ></DownloadView>
              )}
              <div className={styles.detailsLeft}>
                {!fetchProjectQuery.data.survey.openedResults &&
                  getDistributionsCount.isSuccess &&
                  getQuestions.isSuccess &&
                  fetchProjectQuery.data.survey.responseCount === 0 && (
                    <Section childrenStyle={{ paddingTop: "2rem" }}>
                      <SurveyPath paths={getPath()}></SurveyPath>
                    </Section>
                  )}
                <FlexRow
                  style={{ flexWrap: window.innerWidth < 800 ? "wrap" : "" }}
                  start
                >
                  <Section>
                    <div
                      className={`${styles.header_4} `}
                      style={{ margin: "0" }}
                    >
                      Project Type
                    </div>
                    <div
                      className={`${styles.header_4} ${styles.dullblue2}`}
                      style={{ margin: "0" }}
                    >
                      {fetchProjectQuery.data.survey.projectType}
                    </div>
                    <FlexRow>
                      {" "}
                      <div
                        className={styles.section_label7}
                        style={{ margin: "0", fontWeight: "400" }}
                      >
                        This project is a survey
                      </div>
                      {/* <Button link style={{ fontSize: ".7rem" }}>
                        Change
                      </Button> */}
                    </FlexRow>
                  </Section>
                  {(fetchProjectQuery.data.survey.projectType === "Survey" ||
                    fetchProjectQuery.data.survey.projectType ===
                      "Survey Program") && (
                    <Section>
                      {" "}
                      <div className={styles.header_4} style={{ margin: "0" }}>
                        Survey Status
                      </div>
                      <div
                        className={`${styles.header_4} ${
                          fetchProjectQuery.data.survey.status === "Draft" &&
                          styles.yellow
                        } ${
                          fetchProjectQuery.data.survey.status === "Closed" &&
                          styles.orange
                        } ${
                          fetchProjectQuery.data.survey.status === "Open" &&
                          styles.green
                        }`}
                        style={{ margin: "0" }}
                      >
                        {fetchProjectQuery.data.survey.status}
                      </div>
                      <FlexRow>
                        {" "}
                        <div
                          className={styles.section_label7}
                          style={{ margin: "0", fontWeight: "400" }}
                        >
                          If the status is not “Open”, survey cannot be taken
                        </div>
                        <Button
                          link
                          style={{ fontSize: ".7rem" }}
                          onClick={() => setShowSettings(true)}
                        >
                          Change
                        </Button>
                      </FlexRow>
                    </Section>
                  )}
                  <Section>
                    <div className={styles.header_4} style={{ margin: "0" }}>
                      Survey Type
                    </div>
                    <div
                      className={`${styles.header_4} ${styles.sapphire}`}
                      style={{ margin: "0" }}
                    >
                      {fetchProjectQuery.data.survey.type}
                    </div>
                    <FlexRow>
                      {" "}
                      <div
                        className={styles.section_label7}
                        style={{ margin: "0", fontWeight: "400" }}
                      >
                        Determines in house suggestions and layout
                      </div>
                      <Button
                        link
                        style={{ fontSize: ".7rem" }}
                        onClick={() => setShowSettings(true)}
                      >
                        Change
                      </Button>
                    </FlexRow>
                  </Section>
                </FlexRow>
                {fetchProjectQuery.isSuccess &&
                  (fetchProjectQuery.data.survey.status === "Open" ||
                    fetchProjectQuery.data.survey.status === "Closed") && (
                    <FlexRow
                      style={{ height: "310px", alignItems: "flex-start" }}
                    >
                      <Section
                        childrenStyle={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                          maxWidth: "390px",
                          height: "100%",
                          aligntItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <FlexRow>
                          <div
                            className={`${styles.header_4}`}
                            style={{ margin: "0" }}
                          >
                            Survey Results
                          </div>
                          {/* <CombinedInput
                            select
                            icon={"bi-chevron-down"}
                            style={{
                              width: "fit-content",
                              backgroundColor: "white",
                              border: "1px solid lightgray",
                              height: "30px",
                            }}
                            value={resultsDatOptions.find(
                              (v) => v.value === resultsPast
                            )}
                            options={resultsDatOptions}
                            onChange={(v) => {
                              setResultsPast(v.value);
                              setResultsDate(getDate(v.value));
                            }}
                          ></CombinedInput> */}
                          {/* <Button
                            link
                            onClick={() => {
                              navigate(
                                `/project/${fetchProjectQuery.data.survey.id}/surveyresults/${fetchProjectQuery.data.survey.rawReportId}`
                              );
                              localStorage.setItem("activeprojecttab", "3");
                            }}
                          >
                            View Results
                          </Button> */}
                        </FlexRow>
                        <FlexRow
                          style={{ width: "100%", justifyContent: "center" }}
                          gap={"2rem"}
                        >
                          <FlexRow
                            style={{
                              flexDirection: "column",
                              alignItems: "flex-start",
                              width: "fit-content",
                            }}
                            start
                          >
                            {surveyStats.isLoading && (
                              <Loading width={80} height={50}></Loading>
                            )}
                            {surveyStats.isSuccess && (
                              <Stat
                                title={"Responses"}
                                // outof={audcount(fetchProjectQuery.data.survey)}
                                value={surveyStats.data.stats.responses}
                                header
                                titleStyle={{
                                  fontSize: ".9rem",
                                }}
                              />
                            )}
                            {surveyStats.isLoading && (
                              <Loading width={80} height={50}></Loading>
                            )}
                            {surveyStats.isSuccess && (
                              <Stat
                                title={"Response Rate"}
                                // outof={audcount(fetchProjectQuery.data.survey)}
                                value={
                                  <div>
                                    {(
                                      (surveyStats.data.stats.responses /
                                        surveyStats.data.stats
                                          .participationCount) *
                                      100
                                    ).toFixed(2)}
                                    %
                                  </div>
                                }
                                header
                                titleStyle={{
                                  fontSize: ".9rem",
                                }}
                              />
                            )}
                            {surveyStats.isLoading && (
                              <Loading width={80} height={50}></Loading>
                            )}
                            {surveyStats.isSuccess && (
                              <Stat
                                title={"Audience Size"}
                                value={
                                  surveyStats.data.stats.participationCount
                                }
                                header
                                titleStyle={{
                                  fontSize: ".9rem",
                                }}
                              />
                            )}
                            <Button
                              blue
                              shadow
                              style={{ whiteSpace: "nowrap" }}
                              width={170}
                              onClick={() => setShowResults(true)}
                            >
                              Download Results
                            </Button>
                          </FlexRow>
                          <FlexRow
                            style={{
                              flexDirection: "column",
                              alignItems: "flex-start",
                              width: "fit-content",
                            }}
                            start
                          >
                            {/* {fetchProjectQuery.data.survey.type === "NPS" && (
                          <>
                            {surveyStats.isLoading && (
                              <Loading width={80} height={80}></Loading>
                            )}
                            {surveyStats.isSuccess && (
                              <Stat
                                title={"NPS Score"}
                                // outof={audcount(fetchProjectQuery.data.survey)}
                                value={
                                  fetchProjectQuery.data.survey.responseCount
                                }
                                header
                                titleStyle={{
                                  fontWeight: "300",
                                  fontSize: ".9rem",
                                }}
                              />
                            )}

                            {surveyStats.isLoading && (
                              <Loading width={80} height={80}></Loading>
                            )}
                            {surveyStats.isSuccess && (
                              <Stat
                                title={"Comments"}
                                // outof={audcount(fetchProjectQuery.data.survey)}
                                value={
                                  <div>
                                    {(
                                      (fetchProjectQuery.data.survey
                                        .responseCount /
                                        surveyStats.data.stats
                                          .participationCount) *
                                      100
                                    ).toFixed(2)}
                                    %
                                  </div>
                                }
                                header
                                titleStyle={{
                                  fontWeight: "300",
                                  fontSize: ".9rem",
                                }}
                              />
                            )}
                          </>
                        )} */}
                            {surveyStats.isLoading && (
                              <Loading width={80} height={50}></Loading>
                            )}
                            {surveyStats.isSuccess && (
                              <Stat
                                title={"Clicked Link"}
                                value={surveyStats.data?.stats?.started}
                                header
                                titleStyle={{
                                  fontSize: ".9rem",
                                }}
                              />
                            )}
                            {surveyStats.isLoading && (
                              <Loading width={80} height={50}></Loading>
                            )}
                            {surveyStats.isSuccess && (
                              <Stat
                                title={"Did Not Finish"}
                                value={surveyStats.data?.stats?.abandoned}
                                header
                                titleStyle={{
                                  fontSize: ".9rem",
                                }}
                              />
                            )}
                            {surveyStats.isLoading && (
                              <Loading width={80} height={50}></Loading>
                            )}
                            {surveyStats.isSuccess &&
                              getDistributions.isSuccess && (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     flexDirection: "column",
                                //     gap: ".5em",
                                //     marginTop: ".5em",
                                //   }}
                                // >
                                //   <div className={styles.headerDescription}>
                                //     {checkMethods(
                                //       getDistributions.data.distributions
                                //     )}
                                //   </div>
                                <Stat
                                  title="Clicked Finish"
                                  header
                                  titleStyle={{
                                    fontSize: ".9rem",
                                  }}
                                  value={surveyStats.data?.stats?.completed}
                                ></Stat>
                                // </div>
                              )}

                            <Button
                              blue
                              shadow
                              width={150}
                              onClick={() => {
                                navigate(
                                  `/project/${fetchProjectQuery.data.survey.id}/surveyresults/${fetchProjectQuery.data.survey.rawReportId}`
                                );
                                localStorage.setItem("activeprojecttab", "3");
                              }}
                            >
                              View Results
                            </Button>
                          </FlexRow>
                        </FlexRow>
                      </Section>
                      <Section childrenStyle={{ height: "100%" }}>
                        <FlexRow>
                          <div
                            className={`${styles.header_4}`}
                            style={{ margin: "0" }}
                          >
                            Response Dates
                          </div>
                          <Button
                            link
                            onClick={() => {
                              navigate(
                                `/project/${fetchProjectQuery.data.survey.id}/surveyresults/${fetchProjectQuery.data.survey.rawReportId}`
                              );
                              localStorage.setItem("activeprojecttab", "3");
                            }}
                          >
                            View Results
                          </Button>
                        </FlexRow>
                        <HourChart
                          viz={surveydetailsviz}
                          filters={"[]"}
                          role={userRoles}
                          id={fetchProjectQuery.data?.survey?.id}
                        />
                      </Section>
                    </FlexRow>
                  )}
                {getQuestions.isLoading && <Loading></Loading>}
                {getQuestions.isSuccess &&
                  getQuestions.data?.QuestionByProjectID.length > 0 && (
                    <>
                      <div className={styles.link2}>
                        <span onClick={() => navigateToBuild()}>
                          {" "}
                          Questions
                        </span>
                      </div>
                      <Section
                        childrenStyle={{
                          padding: "0px",
                          marginTop: "5px",
                          overflow: "hidden",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        {fetchProjectQuery.isSuccess && (
                          <BasicTable
                            initheaders={
                              fetchProjectQuery.data.survey.status === "Draft"
                                ? headersDraft
                                : headersOpen
                            }
                            data={getQuestions.data?.QuestionByProjectID}
                            onRowClick={() => console.log("Click!")}
                            rowNumbers
                            emptyMessage={
                              <span>
                                You have no questions:{" "}
                                <a className={styles.highlight}>
                                  Create Questions
                                </a>
                              </span>
                            }
                            id="details"
                            moveableColumns
                            noRowAction
                          ></BasicTable>
                        )}
                      </Section>
                    </>
                  )}
                {getQuestions.isSuccess &&
                  getQuestions.data?.QuestionByProjectID.length === 0 && (
                    <>
                      <div className={styles.link2}>
                        <span onClick={() => navigateToBuild()}>
                          {" "}
                          Questions
                        </span>
                      </div>
                      <div className={styles.text_1}>
                        You have no questions yet...
                      </div>
                    </>
                  )}
                {getDistributions.isLoading && <Loading></Loading>}
                {getDistributions.isSuccess &&
                  getDistributions?.data?.distributions.length > 0 && (
                    <>
                      {" "}
                      <div className={styles.link2}>
                        <span onClick={() => navigateToDelivery()}>
                          Messages
                        </span>
                      </div>
                      <Section
                        title={
                          <div
                            className={styles.link2}
                            onClick={() => navigateToDelivery()}
                          >
                            Messages
                          </div>
                        }
                        icon="bi-send"
                        childrenStyle={{
                          padding: "0px",
                          marginTop: "5px",
                          overflow: "hidden",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <BasicTable
                          initheaders={emailHeaders}
                          data={getDistributions?.data?.distributions}
                          // onRowClick={() => console.log("Click!")}
                          noRowAction
                          rowNumbers
                          emptyMessage={
                            "No Distribution Methods. Any distributions or messages created will go here "
                          }
                        ></BasicTable>
                      </Section>
                    </>
                  )}
              </div>

              <SurveySettings
                survey={fetchProjectQuery.data?.survey}
                userRoles={userRoles}
                show={showSettings}
                setShow={setShowSettings}
                user={currUser}
              ></SurveySettings>
            </div>
          )}
      </motion.div>
    </>
  );
}

export default SurveyDetails2;

function SurveyHealth({ survey, questions, distributions }) {
  let health = calculateSurveyHealth(survey, questions);

  return (
    <>
      <div style={{ display: "flex", gap: "2em" }}>
        <SurveyCheckList
          survey={survey}
          questionCount={questions.length}
          distributionCount={distributions.length}
        ></SurveyCheckList>
        <Stat
          title="Survey Health"
          value={
            <div>
              <Pie percentage={health.health}></Pie>
            </div>
          }
          header
          titleStyle={{ fontSize: ".9em", fontWeight: "300" }}
          valueStyle={{
            fontSize: "1em",
          }}
          style={{ alignItems: "center" }}
        />
      </div>
      {health.tips.length > 0 && (
        <div className={styles.tip}>
          <span>
            <a>Tip:</a>

            {health.tips[0]}
          </span>
        </div>
      )}
    </>
  );
}

const defaultSettingsProgram = {
  hasDataLabels: true,
  dataLabelValue: {
    show: true,
    color: "#616565",
    position: "end",
    alignment: "end",
    offset: 0,
    sigFigs: 0,
    fontSize: 14,
  },
  dataLabelLabel: {
    show: false,
  },
  dataLabelPercent: {
    show: false,
  },
  hasTitle: true,
  hasSubtitle: true,
  artificialTitle: true,
  titleFontSize: 18,
  titleAlignment: "center",
  hasLegend: false,
  hasLegendTitle: false,
  legendTitle: "",
  legendPosition: "top",
  legendFontSize: 14,
  legendPointStyle: true,
  legendLabel: true,
  legendValue: false,
  legendPercent: false,
  drawXLines: false,
  drawYLines: false,
  colors: defaultColors,
  solidateSegments: false,
  currPalletId: "default",
  lineWidth: 1,
  pointRadius: 3,
  lineGraphWidth: 3,
  lineGraphColor: "#2A627C",
  uniformPointColor: "#2A627C",
  hasUniformPointColor: true,
  sortData: "none",
  barPercentage: 0.5,
  cutout: 50,
  byPercent: false,
  answerType: "answer count",
  stacked: false,
  hasAxisTitles: false,
  autoGenAxisTitles: true,
  YAxisTitle: "Answer Count",
  XAxisTitle: "Choices",
  axisTitleFontSize: 12,
  tableFields: {
    firstName: { name: "first name", show: true },
    lastName: { name: "last name", show: true },
    email: { name: "email", show: false },
  },
  tableOrder: ["firstName", "lastName", "email"],
  tablePivotFields: {},
  tablePivotOrder: [],
  valueType: "answercount",
  split: "",
  lineTension: 0.3,
  graphMax: null,
  graphMin: null,
  undefinedLabel: "Undefined",
  zoomOut: 5,
  segLabelReplacements: {},
  textboxes: [
    // { top: "10%", left: "5%", text: "Avg Score: 5", color: "gray", backgroundColor: "white" },
    // { top: "10%", left: "95%", text: "Avg Score: 10", color: "gray", backgroundColor: "white" },
  ],
  showUndefined: true,
  showN: false,
  NLabel: "N",
  useTagColor: false,
  showNonParticipating: false,
  afterSort: false,
  hideNonChosen: false,
  dynamic: false,
  userConstraints: [],
  showFilterSubtitle: true,
  sigFigs: 0,
  hideXticks: false,
  hideYticks: true,
  hideTicks: true,
  hideBorder: true,
  borderRadius: 5,
  borderSkipped: false,
};

function MostRecent({ id, survey }) {
  const navigate = useNavigate();
  const options = [
    {
      id: 0,
      name: "Response Count",
      value: "responses",
    },
    {
      id: 0,
      name: "Audience Size",
      value: "audiences",
    },
    survey.type === "NPS" && {
      id: 1,
      name: "NPS Score",
      value: "NPS",
    },
  ];

  const [chart, setChart] = useState({
    id: 0,
    name: "Response Count",
    value: "responses",
  });

  const mostRecent = useFetchProgramRecent(id, survey?.id, chart.value, true);

  useEffect(() => {
    if (chart.value === "NPS") {
      mostRecent.refetch();
    }
  }, [chart]);

  function checkdata() {
    if (chart.value === "responses") {
      return mostRecent.data.data.responses;
    }
    if (chart.value === "NPS") {
      return mostRecent.data.data.npsScores;
    }
    if (chart.value === "audiences") {
      return mostRecent.data.data.audiences;
    }
  }
  return (
    <>
      <Section
        title={
          <div
            className={styles.link2}
            onClick={() => {
              localStorage.setItem("activeprojecttab", 4);
              if (survey.isProgram) {
                navigate(
                  `/program/${survey.id}/surveyresults/${survey.rawReportId}`
                );
              } else {
                navigate(
                  `/project/${survey.id}/surveyresults/${survey.rawReportId}`
                );
              }
            }}
          >
            Program Results
          </div>
        }
        icon="bi-bar-chart-fill"
      >
        <div className={styles.resultsOverview}>
          {(mostRecent.isLoading || mostRecent.isRefetching) && (
            <div style={{ width: "100%" }}>
              <Loading></Loading>
            </div>
          )}
          {mostRecent.isSuccess && !mostRecent.isRefetching && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div className={styles.select}>
                <div className={styles.resultStat}>
                  {(mostRecent.isLoading || mostRecent.isRefetching) && (
                    <Loading height={60} width={60}></Loading>
                  )}
                  {mostRecent.isSuccess && !mostRecent.isRefetching && (
                    <Stat
                      title={
                        chart.value === "NPS"
                          ? "Overall NPS Score"
                          : "Avg Response Count"
                      }
                      value={
                        chart.value === "NPS"
                          ? mostRecent.data?.data?.programScore
                          : Math.round(mostRecent.data?.data?.avgResponse)
                      }
                    ></Stat>
                  )}
                </div>
                <div>
                  <SelectFieldCustom
                    options={options}
                    value={chart}
                    onChange={(val) => setChart(val)}
                    className={styles.selectField}
                  ></SelectFieldCustom>
                </div>
              </div>
              <Chart
                viz={{
                  type: "linechart",
                  designSettings: defaultSettingsProgram,
                  id: survey.id,
                }}
                onSegClick={() => console.log("click")}
                data={{
                  datasets: [
                    {
                      // borderRadius: 10,
                      // borderSkipped: false,
                      // borderColor: "#7FCFD3",
                      data: checkdata(),
                      backgroundColor: [
                        "#7FCFD3",
                        "#7FCFD3",
                        "#7FCFD3",
                        "#7FCFD3",
                        "#7FCFD3",
                        "#7FCFD3",
                        "#7FCFD3",
                        "#7FCFD3",
                      ],
                    },
                  ],
                  labels: mostRecent.data.data.labels,
                }}
              ></Chart>
            </div>
          )}
        </div>
      </Section>
      <Section
        title={
          <div
            className={styles.link2}
            onClick={() => {
              localStorage.setItem("activeprojecttab", 4);
              if (survey.isProgram) {
                navigate(
                  `/program/${survey.id}/surveyresults/${survey.rawReportId}`
                );
              } else {
                navigate(
                  `/project/${survey.id}/surveyresults/${survey.rawReportId}`
                );
              }
            }}
          >
            Most Recent Results{" "}
            <span className={styles.small}>
              {mostRecent.isSuccess && !mostRecent.isRefetching && (
                <>({mostRecent.data.data.newestSurvey.name})</>
              )}
            </span>
          </div>
        }
        icon="bi-bar-chart-fill"
      >
        <div>
          <div className={styles.resultStats2}>
            {(mostRecent.isLoading || mostRecent.isRefetching) && (
              <Loading width={80} height={80}></Loading>
            )}
            {mostRecent.isSuccess && !mostRecent.isRefetching && (
              <Stat
                title={"Responses"}
                // outof={audcount(fetchProjectQuery.data.survey)}
                value={mostRecent.data.data.newestSurvey.responseCount}
                header
                titleStyle={{ fontWeight: "300" }}
                // small
                style={{ alignItems: "center" }}
              />
            )}
            <HorizontalBar width={2} height={60} rotate />
            {(mostRecent.isLoading || mostRecent.isRefetching) && (
              <Loading width={80} height={80}></Loading>
            )}
            {mostRecent.isSuccess && !mostRecent.isRefetching && (
              <Stat
                title={"Response Rate"}
                // outof={audcount(fetchProjectQuery.data.survey)}
                value={
                  <div>
                    {(
                      (mostRecent.data.data.newestSurvey.responseCount /
                        mostRecent.data.data.newestSurvey.participations
                          .length) *
                      100
                    ).toFixed(2)}
                    %
                  </div>
                }
                header
                titleStyle={{ fontWeight: "300" }}
                style={{ alignItems: "center", minWidth: "100px" }}
                // small
              />
            )}
            <HorizontalBar width={2} height={60} rotate />
            {(mostRecent.isLoading || mostRecent.isRefetching) && (
              <Loading width={80} height={80}></Loading>
            )}
            {mostRecent.isSuccess && !mostRecent.isRefetching && (
              <Stat
                title={"Audience Size"}
                value={mostRecent.data.data.newestSurvey.participations.length}
                header
                titleStyle={{ fontWeight: "300" }}
                style={{ alignItems: "center", minWidth: "100px" }}
              />
            )}
            <Button
              link
              onClick={() => {
                localStorage.setItem("activeprojecttab", 4);
                if (survey.isProgram) {
                  navigate(
                    `/program/${survey.id}/surveyresults/${survey.rawReportId}`
                  );
                } else {
                  navigate(
                    `/project/${survey.id}/surveyresults/${survey.rawReportId}`
                  );
                }
              }}
            >
              View Results
            </Button>
          </div>
        </div>
      </Section>
    </>
  );
}

function HourChart({ viz, role, filters, id }) {
  const getDates = useFetchParticipationDates(id);
  return (
    <>
      {getDates.isSuccess && (
        <BarChartVertical
          settings={viz.designSettings}
          filters={filters}
          role={role}
          data={getDates.data.datasets}
          onSegClick={() => undefined}
        />
      )}
    </>
  );
}
