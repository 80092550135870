import React, { useState } from "react";
import styles from "./AudienceWidgets.module.scss";
import { SortableTree } from "./SortableTree.jsx";
import { Sorting } from "../../InteractiveSurveysTable";
import Button from "components/Button/Button";

const initialTree = [
  {
    id: "1",
    name: "Item 1",
    children: [{ id: "1-1", name: "Item 1-1", children: [] }],
  },
  { id: "2", name: "Item 2", children: [] },
];

function AudienceWidgets({
  surveys,
  onClick,
  draft,
  onAdd,
  onClickSeeMore,
  id,
  setShowAudienceCreate,
  showAudienceCreate,
  setShowFolderCreate,
  showFolderCreate,
  sort,
  setSort,
  disableActionButton
}) {
  return (
    <div className={styles.surveys}>
      <div className={styles.top} style={{position: window.innerWidth < 512 ? "static" : ""}}>
        <Sorting sort={sort} setSort={setSort}></Sorting>
        <div className={styles.buttons}>
        <Button
          // shadow
          disable={disableActionButton}
          onClick={() => setShowFolderCreate(true)}
          style={{ gap: ".5em" }}
          shadow
        >
          <i className="bi-folder-fill"></i> + New Folder
        </Button>
        <Button
          shadow
          disable={disableActionButton}
          onClick={() => setShowAudienceCreate(true)}
          style={{ gap: ".5em" }}
        >
          <i className="bi-people-fill"></i> + New Audience
        </Button>
        </div>
      </div>
      <SortableTree
        collapsible
        indicator
        removable
        defaultItems={surveys}
        onClick={onClick}
        openId={id}
        showAudienceCreate={showAudienceCreate}
        setShowAudienceCreate={setShowAudienceCreate}
        showFolderCreate={showFolderCreate}
        setShowFolderCreate={setShowFolderCreate}
      />
      {/* {surveys.map((survey) => (
        <AudienceWidget
          key={survey.id}
          audience={survey}
          onClick={onClick}
          active={survey.id === id}
        />
      ))} */}
      {draft && (
        <div className={styles.add} onClick={onAdd}>
          <i className="bi bi-plus" style={{ fontSize: "13em" }}></i>
        </div>
      )}
      {surveys?.length === 10 && (
        <div className={styles.more}>
          <span onClick={onClickSeeMore}>See More</span>
        </div>
      )}
    </div>
  );
}

export default AudienceWidgets;
