import React, { useEffect, useState } from "react";
import styles from "./File.module.scss";
import { NumSlider } from "components/inputs/input_fields/NumSlider/NumSlider";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { Label } from "components/layouts/Label/Label";

function NumberInputWithSlider({
  onChange,
  val,
  min,
  max,
  step,
  color,
  title,
}) {
  return (
    <div className={styles.setting} style={{ alignItems: "start" }}>
      <div className={styles.label6}
      >
        {title}
      </div>
      <div className={styles.setting2}>
        <NumberInput
          startNumber={val}
          min={min}
          max={max}
          step={step}
          handleNumberChange={onChange}
          color={color ? color : "#2A627C"}
          style={{width: "4rem"}}
        ></NumberInput>
        <div style={{ width: "100%" }}>
          <NumSlider
            value={val}
            min={min}
            max={max}
            step={step}
            onChange={onChange}
            color={color ? color : "#2A627C"}
            valueLabel=""
          ></NumSlider>
        </div>
      </div>
    </div>
  );
}

export default NumberInputWithSlider;
