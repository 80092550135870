/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import { gql } from "graphql-request";
import { useGqlMutation } from "api/Api";


export const useLoginRequest = () => {
  const mutation = gql`
  mutation Login($password: String!, $email: String!, $MFA: Boolean!) {
    login(password: $password, email: $email, MFA: $MFA) {
      user {
        prefix
        firstName
        lastName
        organizationId
        id
        MFAVerified
        verified
      }
    }
  }
  `;

  return useGqlMutation(mutation);
};

export const useNewLoginRequest = () => {
  const mutation = gql`
  mutation LoginOrg($orgid: String!) {
    loginOrg(orgid: $orgid) {
      user {
        prefix
        firstName
        lastName
        organizationId
        id
      }
    }
  }
  `;

  return useGqlMutation(mutation);
};
