export const PermissionCategories = [
  {
    set_name: "Dashboard",
    description:
      "  Customize a dashboard for your team to see when they log in.",
    first: "Can View Dashboard Tab",
    db_field: "canViewDashboardTab",
    permissions: [
      {
        label: "Can Edit Personal Dashboard",
        db_field: "canEditPersonalDashboard",
      },
      {
        label: "Can View Personal Dashboard",
        db_field: "canViewPersonalDashboard",
      },
    ],
    org_permissions: [
      {
        label: "Can Edit Organization Dashboard",
        db_field: "canEditOrgDashboard",
      },
      {
        label: "Can View Organization Dashboard",
        db_field: "canViewOrgDashboard",
      },
      {
        label: "Can Edit Other People's Dashboard",
        db_field: "canEditOthersDashboards",
      },
      { label: "Can Edit Role Dashboards", db_field: "canEditRoleDashboards" },
    ],
  },
  {
    set_name: "Surveys",
    description: "Give Access Rights to surveys",
    first: "Can View Surveys Tab",
    db_field: "canViewSurveysTab",
    permissions: [
      {
        label: "Can Create/Edit Personal Surveys",
        db_field: "canCreateEditPersonalSurveys",
      },
    ],
    org_permissions: [
      {
        label: "Can Create/Edit Surveys For Organization",
        db_field: "canCreateEditOrgSurveys",
      },
      { label: "Can View Organization Surveys", db_field: "canViewOrgSurveys" },
      {
        label: "Can Send Survey Emails For The Organization",
        db_field: "canSendOrgSurveyEmails",
      },
      {
        label: "Can View Raw Results For Organization Surveys",
        db_field: "canViewRawResultsForOrg",
      },
    ],
  },
  // {
  //   set_name: "Survey Build",
  //   description: "",
  //   first: "",
  //   permissions: [
  //     { label: "See Build Tab", db_field: "seeSurveyBuildPage" },
  //     { label: "Can Edit Survey Design", db_field: "editSurveyDesign" },
  //     { label: "See Design Tab", db_field: "seeSurveyDesignPage" },
  //     { label: "See Questions", db_field: "seeSurveyQuestions" },
  //     { label: "Edit Questions", db_field: "editSurveyQuestions" },
  //   ],
  //   org_permissions: [
  //     { label: "Can Edit Organization Dashboard", db_field: "seeDashboard" },
  //     { label: "Can View Organization Dashboard", db_field: "editDashboard" },
  //   ]
  // },
  // {
  //   set_name: "Send Survey",
  //   description: "",
  //   first: "",
  //   permissions: [
  //     { label: "See Send Tab", db_field: "seeSurveySendPage" },
  //     { label: "Can Compose Send", db_field: "canComposeSurveyDistribution" },
  //     { label: "See Send Stats", db_field: "seeSurveyDistributionStats" },
  //     { label: "See Deliveries", db_field: "seeSurveyDeliveries" },
  //     { label: "Send from org email", db_field: "sendFromOrgEmail" },
  //     { label: "Send survey", db_field: "sendSurvey" },
  //   ],
  //   org_permissions: [
  //     { label: "Can Edit Organization Dashboard", db_field: "seeDashboard" },
  //     { label: "Can View Organization Dashboard", db_field: "editDashboard" },
  //   ]
  // },
  {
    set_name: "Analyze",
    description: "Allow your users to analyze further survey results. ",
    first: "Can View Analyze Tab",
    db_field: "canViewAnalyzeTab",
    permissions: [
      {
        label: "Can Create/Edit Personal Reports",
        db_field: "canCreateEditPersonalReports",
      },
      {
        label: "Can Create Analyze Folders",
        db_field: "canCreateAnalyzeFolders",
      },
      { label: "Can Share Reports", db_field: "canShareOrgReports" },
      // { label: "Edit Charts", db_field: "editVisualization" },
      // { label: "Save Charts", db_field: "saveVisualization" },
    ],
    org_permissions: [
      { label: "Can View Organization Reports", db_field: "canViewOrgReports" },
      {
        label: "Can Create/Edit Organization Reports",
        db_field: "canCreateEditOrgReports",
      },
      {
        label: "Can Share Organization Reports",
        db_field: "canShareOrgReports",
      },
    ],
  },
  {
    set_name: "Contacts",
    description:
      "Contacts are the people you are sending your surveys too. You can decide the access users can have with contacts.",
    first: "Can See Contacts Tab",
    db_field: "canViewContactsTab",
    permissions: [
      {
        label: "Can Create/Edit Personal Contacts",
        db_field: "canCreateEditPersonalContacts",
      },
    ],
    org_permissions: [
      {
        label: "Can View Organization Contacts",
        db_field: "canViewOrgContacts",
      },
      {
        label: "Can Create/Edit Organization Contacts",
        db_field: "canCreateEditOrgContacts",
      },
      { label: "Can See Contact Information", db_field: "canSeeContactInfo" },
    ],
  },
  {
    set_name: "Audiences",
    description:
      "Audiences are groups of contacts. These make it easy to group contacts together and send emails to those groups in bulk",
    first: "Can See Audiences Tab",
    db_field: "canViewAudiencesTab",
    permissions: [
      {
        label: "Can Create/Edit Personal Audiences",
        db_field: "canCreateEditPersonalAudiences",
      },
    ],
    org_permissions: [
      {
        label: "Can View Organization Audiences",
        db_field: "canViewOrgAudiences",
      },
      {
        label: "Can Create/Edit Organization Audiences",
        db_field: "canCreateEditOrgAudiences",
      },
    ],
  },

  {
    set_name: "Organization",
    description: "",
    first: "Can View Your Organzations Tab",
    db_field: "canViewOrgTab",
    permissions: [],
    org_permissions: [
      { label: "Can Edit Organization Details", db_field: "canEditOrgDetails" },
      { label: "Can View Organization Details", db_field: "canViewOrgDetails" },

      { label: "Can Edit Organization Users", db_field: "canEditOrgUsers" },
      { label: "Can View Organization Users", db_field: "canViewOrgUsers" },

      { label: "Can Edit Organization Roles", db_field: "canEditOrgRoles" },
      { label: "Can View Organization Roles", db_field: "canViewOrgRoles" },

      { label: "Can Edit Organization Images", db_field: "canEditOrgImages" },
      { label: "Can View Organization Images", db_field: "canViewOrgImages" },
    ],
  },
];

export default PermissionCategories;
