import ceffort from './ceffortimage.png';
import csat from './Csatimage.png';
import nps from './nps.jpg'

const images = {
  ceffort,
  csat,
  nps
};

export default images;