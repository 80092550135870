import styles from "./ChartLabel.module.scss";

export function ChartLabel({ children, tooltip, tipStyle, style, big }) {
  return (
    <div className={`${styles.thelabel}`} style={style}>
      {children && (
        <div className={`${styles.children} ${big ? styles.big : ""}`}>
          {children}
        </div>
      )}
      {tooltip && (
        <div className={styles.icon}>
          <i className="bi-info-circle"></i>
          <div className={styles.tooltip} style={tipStyle}>
            {tooltip}
          </div>
        </div>
      )}
    </div>
  );
}
