import styles from "../MetricSettings.module.scss";
import { useEffect, useRef, useState } from "react";
import {
  useFetchStartedSurveys,
  useFetchStartedSurveysForMetricType,
  useSearchPrograms,
} from "api/resources/projects/projects";
import { useSearchSurveyTags } from "api/resources/organization/surveytags";

export default function AddToSingleMetric({
  onSave,
  viz,
  tiedIds,
  metric,
  close,
}) {
  const [searchString, setSearchString] = useState("");

  const getAllProjects = useFetchStartedSurveysForMetricType(metric.code);
  const searchPrograms = useSearchPrograms(searchString);
  const searchTags = useSearchSurveyTags(searchString);

  // const [active, setActive] = useState(false);
  
  function getProjectOptions() {
    let included = [];
    let tied = [];
    let notIncluded = [];
    for (let survey of getAllProjects.data.surveys) {
      if (!metric.projects.some((p) => p.id === survey.id)) {
        if (viz.projectIdsArray.includes(survey.id)) {
          included.push(survey);
        } else if (tiedIds && tiedIds.includes(survey.id)) {
          tied.push(survey);
        } else {
          notIncluded.push(survey);
        }
      }
    }

    return [...included, ...tied, ...notIncluded];
  }

  function addProject(survey) {
    let copy = { ...metric };
    copy.projects.push({
      id: survey.id,
      name: survey.name,
    });
    for (let q of survey.question) {
      copy.questions.push({
        id: q.id,
        name: q.questionText,
        projectId: survey.id,
      });
    }

    onSave(copy);
  }

  function addProgram(program) {
    let copy = { ...metric };
    if (!copy.dynamic) {
      copy.dynamic = [];
    }
    copy.dynamic.push({
      id: program.id,
      name: program.mainProject.name,
      timeFrame: "All",
      type: "program",
    });

    onSave(copy);
  }

  function addTag(tag) {
    let copy = { ...metric };
    if (!copy.dynamic) {
      copy.dynamic = [];
    }
    copy.dynamic.push({
      id: tag.id,
      name: tag.label,
      timeFrame: "All",
      type: "surveyTag",
    });

    onSave(copy);
  }

  function notIncludedPrograms() {
    if (metric.dynamic) {
      return searchPrograms.data.programs.filter(
        (program) =>
          !metric.dynamic.some(
            (d) => d.id === program.id && d.type === "program"
          )
      );
    }
    return searchPrograms.data.programs;
  }

  function notIncludedTags() {
    if (metric.dynamic) {
      return searchTags.data.tags.filter(
        (tag) =>
          !metric.dynamic.some((d) => d.id === tag.id && d.type === "surveyTag")
      );
    }
    return searchTags.data.tags;
  }

  const hasData =
    metric.projects.length || (metric.dynamic && metric.dynamic.length)
      ? true
      : false;

  return (
    <div className={styles.addToSingleContainer}>
      {hasData ? (
        <div className={`${styles.dropDownListTitle} ${styles.addSurvey}`}>
          Add
          <div className={styles.cancelFor} onClick={close}>
            <i className="bi bi-arrow-return-left"></i>
          </div>
        </div>
      ) : (
        <>
          <div className={`${styles.dropDownListTitle} ${styles.forSurveys}`}>
            For
          </div>
        </>
      )}

      {/* <div
                className={`${styles.dropDownListTitle} ${styles.forSurveys}`}
              >
                For
              </div> */}
      <div className={styles.searchFor} style={{ paddingBottom: "12px" }}>
        <div className={styles.whatSearchingFor}>Survey Tag / Program</div>
        <input
          type="text"
          onChange={(e) => setSearchString(e.target.value)}
          className={styles.search}
          value={searchString}
          placeholder=""
          // onFocus={() => setActive(true)}
          // onBlur={() => setTimeout(() => setActive(false), 250)}   // too much room?
        ></input>
      </div>

      {searchString && (
        <>
          {searchPrograms.isSuccess && (
            <div className={styles.programs}>
              {notIncludedPrograms().map((program) => (
                <div
                  className={styles.programOption}
                  onClick={() => addProgram(program)}
                >
                  {program.mainProject.name}{" "}
                  <div
                    className={`${styles.indicator} ${styles.programIndicator}`}
                  >
                    Program
                  </div>
                </div>
              ))}
            </div>
          )}
          {searchTags.isSuccess && (
            <div className={styles.programs}>
              {notIncludedTags().map((tag) => (
                <div
                  className={styles.programOption}
                  onClick={() => addTag(tag)}
                >
                  {tag.label}{" "}
                  <div className={`${styles.indicator} ${styles.tagIndicator}`}>
                    Tag
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}

      <div
        className={styles.whatSearchingFor}
        style={{
          fontSize: ".9em",
          borderBottom: "2px solid #a4c6d055",
        }}
      >
        Survey
      </div>

      {getAllProjects.isSuccess &&
        getProjectOptions().map((survey) => (
          <div
            className={styles.surveyOption}
            onClick={() => addProject(survey)}
          >
            {survey.name}
          </div>
        ))}
    </div>
  );
}
