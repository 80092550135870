//External
import React, { useEffect, useState } from "react";

//Internal
import styles from "./Section.module.scss";

import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";

/**
 * A component that displays the current section
 * @param {function} section the section being used to display
 * @returns {React.ReactElement} a sectionConstruction component
 */
const Section = ({
  section,
  active,
  saveSection,
  designSettings,
  roles,
  status,
}) => {
  useEffect(() => {
    if (typeof section.sectionSettings === "string") {
      section.sectionSettings = JSON.parse(section.sectionSettings);
    }
  }, []);
  function handleSave(text, code) {
    section.bodyText = text;
    section.encoding = code;
    saveSection(section);
  }

  return (
    <>
      <TextEditorTry
        editable={active && roles.editSurveyQuestions}
        encoding={section.encoding}
        placeholder={"Enter text..."}
        defaultStyle={{ fontSize: "16pt" }}
        onSave={handleSave}
        height={active && roles.editSurveyQuestions ? "100px" : ""}
        style={{
          backgroundColor: active && roles.editSurveyQuestions ? "#E9E9E9" : "",
        }}
        showToolbar={active && roles.editSurveyQuestions}
        autoFocus={active}
      />
    </>
  );
};

export default Section;
