import React from "react";
import styles from "./Importer.module.scss";
import { Loading } from "components/Loading/Loading";
import { useParams } from "react-router-dom";
import ExternalImport from "../ExternalImport/ExternalImport";
import { useAudienceQuery } from "api/resources/contacts/audiences";
import { ContactCleaner } from "components/ContactCleaner2.0/ContactCleaner";
import { Manual } from "./NewContacts/manual/Manual";
import { useFetchOrgFieldsNoProps } from "api/resources/organization/organization";

function Importer({
  manual,
  csv,
  excel,
  salesforce,
  user,
  organization,
  refetchTasks,
}) {
  const { id } = useParams();

  return (
    <div className={styles.page}>
      {!id && (
        <ImportContent
          user={user}
          organization={organization}
          manual={manual}
          csv={csv}
          excel={excel}
          salesforce={salesforce}
          refetchTasks={refetchTasks}
        ></ImportContent>
      )}
      {id && (
        <ImportInAudience
          id={id}
          user={user}
          organization={organization}
          manual={manual}
          csv={csv}
          excel={excel}
          salesforce={salesforce}
          refetchTasks={refetchTasks}
        ></ImportInAudience>
      )}
    </div>
  );
}

export default Importer;

function ImportContent({
  id,
  audience,
  user,
  salesforce,
  csv,
  excel,
  manual,
  organization,
  refetchTasks
}) {
  const fetchCustomFields = useFetchOrgFieldsNoProps(organization?.id);
  return (
    <>
      {salesforce && fetchCustomFields.isSuccess && (
        <ExternalImport
          organization={organization}
          user={user}
          type={"Salesforce"}
          audience={audience ? audience : undefined}
          customFields={fetchCustomFields?.data?.getOrgFields}
          refetchTasks={refetchTasks}
        />
      )}
      {(csv || excel || manual) && fetchCustomFields.isSuccess && (
        <ContactCleaner
          user={user}
          audienceId={id ? id : ""}
          salesforce={salesforce}
          audience={audience ? audience : undefined}
          organizationId={organization?.id}
          customFields={fetchCustomFields?.data?.getOrgFields}
          toAudience={audience ? "audience" : undefined}
          manual={manual}
          refetchTasks={refetchTasks}
        ></ContactCleaner>
      )}
      {/* {manual && fetchCustomFields.isSuccess && (
        <Manual
          isAudience={id ? true : false}
          audienceId={id ? id : ""}
          customFields={fetchCustomFields?.data?.getOrgFields}
        ></Manual>
      )} */}
    </>
  );
}

function ImportInAudience({
  id,
  organization,
  user,
  salesforce,
  csv,
  excel,
  manual,
  refetchTasks
}) {
  const getAudience = useAudienceQuery(id);
  return (
    <>
      {getAudience.isLoading && <Loading></Loading>}
      {getAudience.isSuccess && (
        <ImportContent
          user={user}
          organization={organization}
          manual={manual}
          csv={csv}
          excel={excel}
          salesforce={salesforce}
          audience={getAudience?.data?.audience}
          id={id}
          refetchTasks={refetchTasks}
        ></ImportContent>
      )}
    </>
  );
}
