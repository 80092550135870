import styles from "./NumberSlider.module.scss";
import SurveyNumSlider from "components/inputs/input_fields/SurveyNumSlider/SurveyNumSlider";

export default function NumberSlider({
  question,
  onAnswered,
  color,
  value,
}) {

  function onChange(val) {
    onAnswered(question.id, val);
  }

  return (
    <SurveyNumSlider
      value={value}
      scaleQ={question.scaleQuestion}
      onChange={onChange}
      color={color}
    />
  );
}
