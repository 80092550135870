import React, { useState } from "react";

import { useDataUploader } from "api/resources/projects/projects";
import { useParams } from "react-router-dom";
import { useCSVReader } from "react-papaparse";
import styles from "./dataUploader.module.scss";
import Button from "components/Button/Button";
import { useFetchQuestionsGql } from "api/resources/projects/questions";
import { Loading } from "components/Loading/Loading";
import { ErrorPage } from "pages";
import {
  useFetchOrganization,
  useUpdateOrgSettings,
} from "api/resources/organization/organization";
import { useFetchRole } from "api/resources/organization/roles";
import { ProgressBar } from "components/QuestionConstruction/ProgressBar/ProgressBar";
import { forEach } from "assets/functions/ArrayFunctions";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { sendinternvals } from "assets/functions/Variables";

export default function CSVReader({ currUser, progress }) {
  const { id } = useParams();
  const uploadData = useDataUploader();
  const { CSVReader } = useCSVReader();
  const [data, setData] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [itervals, setItervals] = useState(1);
  const [max, setMax] = useState(100);
  const [min, setMin] = useState(50);
  const [clickedLink, setClinkedLink] = useState(50);
  const [finished, setFinished] = useState(90);
  const [delivered, setDelivered] = useState(100);
  const [sendInterval, setSendInterval] = useState("quarterly");
  const [advanced, setAdvanced] = useState(true);
  const [simulate, setSimulate] = useState(true);
  const [loading, setLoading] = useState(false);
  const getQuestions = useFetchQuestionsGql(id);
  const getCurrRole = useFetchRole();
  const MyOrg = useFetchOrganization(currUser.organizationId);
  const updateOrgFields = useUpdateOrgSettings();

  const handleUploadData = async (e) => {
    setLoading(true);
    let orgFields = JSON.parse(MyOrg.data.organization.customFields);
    let newOrgFields = false;
    let inputArray = e;
    class contactUpload {
      email;
      firstName;
      lastName;
      prefix;
      gender;
      location;
      provider;
      product;
      position;
      company;
    }
    class participationUpload {
      abandoned;
      completed;
      projectId;
      started;
      startedAt;
      finishedAt;
      createdAt;
      distIdUsed;
      distIdSend;
    }
    class answerUpload {
      questionId;
      answer;
      questionType;
    }
    class uploadDataObject {
      contactUpload;
      participationUpload;
      answerUploadArray;
    }
    let uploadDataObjectArray = [];
    let count = 0;
    inputArray.map((input) => {
      count += 1;
      let c = new contactUpload();
      let p = new participationUpload();
      let u = new uploadDataObject();
      u.answerUploadArray = [];
      p.abandoned = false;
      p.started = true;
      p.completed = true;
      p.startedAt = undefined;
      p.finishedAt = undefined;
      p.distIdUsed = "";
      p.distIdSend = 1;
      p.projectId = id;
      p.createdAt = undefined;
      p.link = "";
      c.customField = "";
      c.firstName = "";
      c.lastName = "";
      c.email = "";
      forEach(Object.keys(input), (field) => {
        if (
          !getQuestions.data.QuestionByProjectID.find(
            (question) => question.questionText === field
          )
        ) {
          if (field != "email" && field != "firstName" && field != "lastName") {
            if (field === "startedAt" && input[field]) {
              p.startedAt = new Date(input[field]);
            } else if (field === "finishedAt" && input[field]) {
              p.finishedAt = new Date(input[field]);
            }
            if (field === "createdAt" && input[field]) {
              p.createdAt = new Date(input[field]);
            }
            if (field === "distIdSend" && input[field]) {
              p.distIdSend = parseInt(input[field]);
            }
            if (field === "distIdUsed" && input[field]) {
              p.distIdUsed = input[field];
            }

            if (
              input[field] === "" ||
              input[field] === undefined ||
              input[field] === null
            ) {
              delete input[field];
            }
          } else {
            let email = new RegExp("email");
            let first = new RegExp("first[s_.]?name");
            let last = new RegExp("last[s_.]?name");
            if (email.test(field.toLowerCase())) {
              c["email"] = input[field];
            } else if (first.test(field.toLowerCase())) {
              c["firstName"] = input[field];
            } else if (last.test(field.toLowerCase())) {
              c["lastName"] = input[field];
            } else {
              c[field] = input[field];
            }
          }
        }
      });

      let matrixqs = {};
      for (var propt in input) {
        let realheader = propt.split("_1").join("");
        for (let i = 2; i < 10; i++) {
          realheader = realheader.split(`_${i}`).join("");
        }

        for (var question of getQuestions.data.QuestionByProjectID) {
          if (question.type != "Matrix") {
            if (
              realheader.trim().toLowerCase() ===
              question.questionText.trim().toLowerCase()
            ) {
              let a = new answerUpload();
              a.answer = input[propt];
              a.questionId = question.id;
              a.questionType = question.type;
              u.answerUploadArray.push(a);
            }
          } else {
            if (!(question.questionText in matrixqs)) {
              matrixqs[question.questionText] = {
                answer: {},
                type: question?.type,
                id: question?.id,
                name: question?.name,
              };
            }
            for (let row of question?.matrixQuestion?.options) {
              let modheader = realheader.trim().toLowerCase();
              let qname =
                question.questionText.trim().toLowerCase() +
                ": " +
                row.trim().toLowerCase();
              if (modheader === qname) {
                if (!(row in matrixqs[question.questionText].answer)) {
                  matrixqs[question.questionText].answer[row] = [input[propt]];
                } else {
                  matrixqs[question.questionText].answer[row].push(
                    input[propt]
                  );
                }
              }
            }
          }
        }
      }
      let mkeys = Object.keys(matrixqs);
      if (mkeys?.length > 0) {
        for (let key of mkeys) {
          let q = matrixqs[key];
          let a = new answerUpload();
          a.questionId = q.id;
          a.questionType = q.type;
          a.answer = JSON.stringify(q.answer);
          u.answerUploadArray.push(a);
        }
      }
      u.contactUpload = c;
      u.participationUpload = p;
      uploadDataObjectArray.push(u);
    });

    uploadData.mutate(
      {
        data: {
          uploadDataObject: uploadDataObjectArray,
          createsendStats: simulate,
          maxPerIteration: max,
          minPerIteration: min,
          totalIterations: itervals,
          sendInterval: sendInterval,
          startDate: startDate,
          percentageDelivered: delivered,
        },
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          setData([]);
        },
      }
    );

    uploadDataObjectArray = [];
    if (newOrgFields) {
      updateOrgFields.mutate({
        data: { customFields: JSON.stringify(orgFields) },
        updateOrganizationId: currUser.organizationId,
      });
    }
  };
  return (
    <>
      {(getQuestions.isLoading || loading) && <Loading />}
      {getQuestions.isError && <ErrorPage />}
      {currUser &&
        MyOrg.isSuccess &&
        getCurrRole.isSuccess &&
        getCurrRole.data.role.canUseDataUploader &&
        getQuestions.isSuccess && (
          <CSVReader
            config={{ header: true }}
            onUploadAccepted={(results) => {
              setData(results.data);
            }}
          >
            {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
            }) => (
              <>
                {!progress && (
                  <>
                    <div className={styles.csvContainer}>
                      <div className={styles.header}>
                        Import External Results
                      </div>

                      <div className={styles.csvReader}>
                        <Button type="button" {...getRootProps()} shadow style={{whiteSpace: "nowrap"}}>
                          Choose File
                        </Button>
                        {acceptedFile && (
                          <>
                            <div className={styles.acceptedFile}>
                              {acceptedFile && acceptedFile.name}
                            </div>
                            <Button gray {...getRemoveFileProps()} shadow>
                              Remove
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                    <FlexRow start style={{ flexDirection: "column" }}>
                      <div className={styles.guide}>
                        <p>
                          Only import .csv files. All other files will fail.
                          <br /> Make sure to have an identifier field such as
                          "Email" or "Phone".
                          <br /> Question headers must be the the specific
                          question title. Ex: Why did you give us that score?
                        </p>
                      </div>

                      <FlexRow
                        style={{
                          justifyContent: "center",
                          alignItems: "flex-start",
                        }}
                        gap={"2rem"}
                      >
                        <div
                          className={styles.guide}
                          style={{
                            border: "none",
                            padding: "0px",
                            gap: ".5rem",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {/* <div className={styles.header_5}>Settings</div> */}

                          <FlexRow start>
                            <div
                              className={styles.header_5}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Advanced Settings
                            </div>
                            <ToggleSwitch
                              startChecked={advanced}
                              handleCheck={setAdvanced}
                            ></ToggleSwitch>
                          </FlexRow>
                          {advanced && (
                            <>
                              <NumberInputWithSlider
                                max={100}
                                min={0}
                                step={1}
                                val={finished}
                                onChange={setFinished}
                                title={"% Did Not Finish"}
                              ></NumberInputWithSlider>
                              <NumberInputWithSlider
                                max={100}
                                min={0}
                                step={1}
                                val={clickedLink}
                                onChange={setClinkedLink}
                                title={"% Clicked Link"}
                              ></NumberInputWithSlider>
                            </>
                          )}
                        </div>
                        <div
                          className={styles.guide}
                          style={{
                            border: "none",
                            padding: "0px",
                            gap: ".5rem",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {/* <div className={styles.header_5}>Settings</div> */}

                          <FlexRow start>
                            <div
                              className={styles.header_5}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Simulate Deliveries
                            </div>
                            <ToggleSwitch
                              startChecked={simulate}
                              handleCheck={setSimulate}
                            ></ToggleSwitch>
                          </FlexRow>
                          {simulate && (
                            <>
                              <CombinedInput
                                value={startDate}
                                onChange={(e) => setStartDate(e)}
                                placeholder={"Never"}
                                date
                                icon={"bi-chevron-down"}
                                label="Start Date"
                                style={{ minWidth: "300px" }}
                                anyWhereOptions
                              />
                              <CombinedInput
                                value={
                                  sendInterval
                                    ? sendinternvals.find(
                                        (s) => s.value === sendInterval
                                      )
                                    : { value: "quarterly", label: "Quarterly" }
                                }
                                onChange={(e) => setSendInterval(e.value)}
                                placeholder={"Send Interval"}
                                options={sendinternvals}
                                select
                                icon={"bi-chevron-down"}
                                label={"Send Interval"}
                              />
                              <NumberInputWithSlider
                                max={10000}
                                min={1}
                                step={1}
                                val={itervals}
                                onChange={setItervals}
                                title={"Send Iterations"}
                              ></NumberInputWithSlider>
                              <NumberInputWithSlider
                                max={10000}
                                min={0}
                                step={1}
                                val={min}
                                onChange={setMin}
                                title={"Iteration Results Min"}
                              ></NumberInputWithSlider>
                              <NumberInputWithSlider
                                max={10000}
                                min={0}
                                step={1}
                                val={max}
                                onChange={setMax}
                                title={"Iteration Results Max"}
                              ></NumberInputWithSlider>
                              <NumberInputWithSlider
                                max={100}
                                min={0}
                                step={1}
                                val={delivered}
                                onChange={setDelivered}
                                title={"Percentage Delivered"}
                              ></NumberInputWithSlider>
                            </>
                          )}
                        </div>
                      </FlexRow>
                    </FlexRow>
                    <FlexRow
                      style={{ width: "100%", justifyContent: "flex-end", marginTop: "2rem" }}
                    >
                      {acceptedFile && (
                        <Button
                          type="button"
                          className={styles.uploadButton}
                          onClick={() => handleUploadData(data)}
                          shadow
                          blue
                        >
                          Submit {data && <>{data.length}</>} Results
                        </Button>
                      )}
                    </FlexRow>
                  </>
                )}
              </>
            )}
          </CSVReader>
        )}
      {progress && (
        <div className={styles.progressContainer}>
          <span>Uploading Results...</span>
          <ProgressBar
            numQuestions={progress.total}
            numAnswered={progress.current}
            settings={{ showProgBar: true }}
          ></ProgressBar>
        </div>
      )}
    </>
  );
}

/*


*/
